interface Records {
    userID: string;
    email: string;
    name: string;
    phone: string;
    action: string;
}

const records: Records[] = [
    {
        userID: "001",
        email: "acb@gmail.com",
        name: 'Lê văn A',
        phone: '08872384',
        action: '',
    }
];

export { records };
