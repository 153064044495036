import { Button, Modal, Toast, ToastContainer } from "react-bootstrap";
import "./Styles.css"
import AudioPlayer from "react-h5-audio-player";


interface ModalTimeOutTokenProps {
  show: boolean;
  handleClose: () => void;
}

const ModalTimeOutToken = ({
  show,
  handleClose
}: ModalTimeOutTokenProps) => {
  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-timeout-token"
      >
        <Modal.Body>
          <div className="p-2 pb-0">
            <div className="box-payment-success">
              <p className="text-notification-payment-success text-center font-weight-600">
                Hi there
              </p>
              <p className="text-notification-payment-success text-center font-weight-600">To reorder, just scan the QR <br /> code again for accurate <br /> processing.</p>
              <p className="text-notification-payment-success text-center mb-0 font-weight-600">
                Sorry for any <br />inconvenience! 😊
              </p>
              <p className="text-notification-payment-success text-center mb-0 font-weight-400 mt-2">
                <p className="mb-0 d-inline">Empowered by</p> <p className="mb-0 d-inline text-underline">BriJack POS</p>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>

  );
};

export default ModalTimeOutToken;
