import { Accordion, Form, Modal } from "react-bootstrap";
import "./Styles.css"
import { handClick, leaf1, leaf2, trashBlack, trashRed, trashWhite } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import { EditFloorName } from "../../../../api/apiOwner/FloorApi";



interface ModalConfirmAccountDefaultProps {
    show: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
}

const ModalConfirmAccountDefault = ({
    show,
    handleClose,
    handleSubmit
}: ModalConfirmAccountDefaultProps) => {

    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer modal-clock-in-out modal-edit-name-table">
                <Modal.Dialog>
                    <Modal.Body>
                        <div className="position-relative px-3 d-flex align-items-center flex-column">
                            <div className="text-center">
                                <p className="text-delete-modal-customer mb-1 text-center">Confirm account change?</p>
                                {/* <p className="text-center text-danger text-italic mb-1">{messageError}</p> */}
                            </div>
                            <div className=" border-none text-add-new-payment-content mb-0">
                                Are you sure you want to change your Strip account
                            </div>
                            <div className="px-3 w-100">
                                <div className="d-flex justify-content-between mt-3">
                                    <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>
                                        Cancel
                                    </div>
                                    <div className="btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={handleSubmit}>
                                        Save
                                    </div>
                                </div>
                            </div>
                            <img src={leaf1} alt="" className="img-leaf1-edit-name-table" />
                            <img src={leaf2} alt="" className="img-leaf2-edit-name-table" />
                        </div>
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalConfirmAccountDefault;
