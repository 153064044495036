import Sheet from "react-modal-sheet";
import { arrowLeft } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import "./Styles.css";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptionsMode, loadStripe } from "@stripe/stripe-js";
import CheckoutPage from "./CheckoutPage";
import { gstripeKeyOwner } from "../../../../api/apiOwner/StripeOwnerApi";
import React from "react";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalAddVisaCardProps {
  show: boolean;
  order_id?: string;
  totalAmount: number;
  custommerId: string | null;
  table_id: string | null;
  tax: number;
  TipPrice: number;
  feeStripe: boolean;
  setShowLoading: (load: boolean) => void;
  staffId: string;
  voucherCode: string;
  voucherId: string;
  discount: number;
  handleClose: (value: boolean) => void;
  clearToken: () => void;
  setStatusPayVisa: (status: boolean) => void;
  setShowPaymentStatus: (status: boolean) => void;
  setPaymentMethodNameShowBill: (name: string) => void;
}
const ModalApplePay = ({
  show,
  totalAmount = 0,
  handleClose,
  TipPrice = 0,
  custommerId = "",
  feeStripe = false,
  setShowLoading,
  table_id = "",
  tax = 0,
  staffId,
  voucherCode = "",
  voucherId = "",
  discount = 0,
  order_id = "",
  clearToken,
  setStatusPayVisa,
  setShowPaymentStatus,
  setPaymentMethodNameShowBill,
}: ModalAddVisaCardProps) => {
  const ownerID = Number(localStorage.getItem("ownerID"));
  const languageUI = getLanguageCheckIn();
  const [publishKey, setpublishKey] = useState<string>("");
  const [options, setOptions] = useState<StripeElementsOptionsMode>();

  const gKeyStripe = async () => {
    setShowLoading(true);
    const req = await gstripeKeyOwner(ownerID);
    if (Number(req?.status) === 1) {
      setpublishKey(req?.data?.publishKey ?? "");
    }
    setShowLoading(false);
  };

  useEffect(() => {
    if (show) {
      console.log(Math.round(totalAmount * 100));

      setOptions({
        mode: "payment",
        amount: Math.round(totalAmount * 100),
        currency: "usd",
      });
      gKeyStripe();
    }
  }, [show]);

  return (
    <div>
      <Sheet
        // ref={ref}
        isOpen={show}
        onClose={() => handleClose?.(false)}
        snapPoints={[1000]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <div className="d-flex justify-content-between align-items-center px-3">
            <div>
              <img
                src={arrowLeft}
                alt=""
                onClick={() => handleClose?.(false)}
              />
            </div>
            <p className="text-select-name-employee mb-0">
              {languageUI.PayWith}
            </p>
            <div>
              <img
                src={arrowLeft}
                alt=""
                className="opacity-0 visible-hidden"
              />
            </div>
          </div>
          <Sheet.Content>
            <div className="mt-3">
              <Elements stripe={loadStripe(publishKey)} options={options}>
                <CheckoutPage
                  totalAmount={totalAmount}
                  ownerID={ownerID}
                  custommerId={custommerId}
                  table_id={table_id}
                  tax={tax}
                  TipPrice={TipPrice}
                  feeStripe={feeStripe}
                  setShowLoading={setShowLoading}
                  staffId={staffId}
                  voucherCode={voucherCode}
                  voucherId={voucherId}
                  discount={discount}
                  order_id={order_id}
                  clearToken={clearToken}
                  setStatusPayVisa={setStatusPayVisa}
                  setShowPaymentStatus={setShowPaymentStatus}
                  setPaymentMethodNameShowBill={setPaymentMethodNameShowBill}
                />
              </Elements>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          className="bg-bg-backdrop"
          onTap={() => handleClose?.(false)}
        />
      </Sheet>
    </div>
  );
};

export default ModalApplePay;
