
import "./Styles.css";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLanguageCheckIn } from "../../../Services/languageCheckin";
import * as orderConfilmApi from "../../../api/apiCheckin/orderConfilmApi";
import ModalLoading from "../../../components/ModalLoading";
import { FormatDolla } from "../../../utils/format";
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { ClickAwayListener, Tooltip } from "@mui/material";

const MyBillOnlineDetail = () => {
    const param = useParams()
    const languageUI = getLanguageCheckIn();
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const [dataDetailBill, setDataDetailBill] = useState<orderConfilmApi.IDetailBill>()
    const [openToolTip, setOpenToolTip] = useState(false);

    const gDetailBill = async (ownerId: number, orderId: number) => {
        const result = await orderConfilmApi.gDetailBill(ownerId, orderId)
        if (result.data) {
            setDataDetailBill(result.data)
        }
    }
    const handleTooltipClose = () => {
        setOpenToolTip(false);
      };
    useEffect(() => {
        gDetailBill(Number(param?.ownerID), Number(param?.orderId))
    }, [])
    return (

        <>
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <div className="billInfomation">
                <header className="w-100 custom-header-choose-menu d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center gap-1" >
                        <p className="mb-0 text-order-more-detail">{languageUI.InvoiceDetails}</p>
                    </div>
                </header>

                <div className="account-pages d-flex  custom-pading-header bg-white " style={{ minHeight: "100vh" }}>
                    <div className=" rounded-3 overflow-hidden w-100">

                        <div className="p-3 custom-h-my-bill-detail">
                            <div className="pb-2 border-bottom">
                                <div className="d-flex justify-content-between mb-1">
                                    <span className="text-title">{languageUI.Invoicenumber}</span>
                                    <span className="text-content text-4A4A6A ">{dataDetailBill?.id}</span>
                                </div>
                                <div className="d-flex justify-content-between mb-1 ">
                                    <span className="text-title">{languageUI.Printdatetime}</span>
                                    <span className="text-content text-4A4A6A">{dataDetailBill?.time_order}</span>
                                </div>
                                <div className="d-flex justify-content-between mb-1 ">
                                    <span className="text-title">{languageUI.Customer}</span>
                                    <span className="text-content text-4A4A6A">{dataDetailBill?.custommer_name}</span>
                                </div>

                            </div>
                            {dataDetailBill?.listFood.map((item, i) => (
                                <div className="pb-2 border-bottom" key={i}>

                                    <div className="d-flex justify-content-between pt-2 gap-2" >
                                        <span className="name-food ">{item?.name}</span>
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="d-flex align-items-end gap-2px ">
                                                <div className="text-price-food-2 mb-2px">{item?.quantity}</div>
                                                <div className="text-x">
                                                    x
                                                </div>
                                                <div className="text-price-food-2 mb-2px">
                                                    {FormatDolla(Number(item?.price))}$
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {item?.notefood_name && (
                                        <div className="d-flex align-items-center gap-2">
                                            <div className="bg-F6F9F8 mt-1">
                                                <p className="mb-0 text-12B064">{item?.notefood_name}</p>
                                            </div>
                                        </div>
                                    )}
                                    {item?.side_name && (
                                        <div className="mb-2">
                                            <div className="d-flex gap-1 mt-1"><p className="mb-0 text-choose-a-side text-666687">+ {item?.side_name}</p> <p className="mb-0 text-choose-a-side text-12B064">(Free x1)</p></div>
                                        </div>
                                    )}

                                    {item?.note && (
                                        <p className="text-note-information-card-items mb-0">{item?.note}</p>
                                    )}
                                </div>
                            ))}
                            <div className="py-3">
                                <div className="d-flex justify-content-between mb-2">
                                    <span className="text-subtitle">{languageUI.ItemsTotal}</span>
                                    <div className="d-flex gap-2px">
                                        <div className="text-dollar-2">$</div>
                                        <div className="text-price-food-2">{FormatDolla(Number(dataDetailBill?.sub_total))}</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <span className="text-subtitle">{languageUI.Discount}</span>
                                    <div className="d-flex gap-2px">
                                        <div className="text-dollar-2">$</div>
                                        <div className="text-price-food-2">{FormatDolla(Number(dataDetailBill?.discount))}</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <span className="text-subtitle">Tip</span>
                                    <div className="d-flex gap-2px">
                                        <div className="text-dollar-2">$</div>
                                        <div className="text-price-food-2">{FormatDolla(Number(dataDetailBill?.tip_amount))}</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between mb-2">
                                    <span className="text-subtitle">Tax</span>
                                    <div className="d-flex gap-2px">
                                        <div className="text-dollar-2">$</div>
                                        <div className="text-price-food-2">{FormatDolla(Number(dataDetailBill?.vat_amount))}</div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <div className="tooltip-payment-web-checkin">
                          <Tooltip
                            className=""
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={handleTooltipClose}
                            open={openToolTip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="right"
                            title={languageUI.TheconveniencefeeischargedpertransactionbyathirdpartyvendorWedonotretainanyportionofthisfee}
                          >
                            <div className="d-flex gap-1 align-items-end w-fit" onClick={() => setOpenToolTip(true)}>
                              <div className="name-title-price">{languageUI.Conveniencefee}</div>
                              <HelpOutlineSharpIcon className="" />
                            </div>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                      <div className="d-flex gap-2px">
                        <div className="text-dollar-3">$</div>
                        <div className="text-price-food-3">{FormatDolla(dataDetailBill?.feePrice)}</div>
                      </div>
                    </div>
                                <div className="d-flex justify-content-between">
                                    <span className="text-content text-4A4A6A font-bold">{languageUI.Total}</span>
                                    <div className="d-flex gap-2px ">
                                        <div className="text-FFB080 ">$</div>
                                        <div className=" text-content text-4A4A6A font-bold">{FormatDolla(Number(dataDetailBill?.total_order))}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MyBillOnlineDetail;
