import * as Request from "../Request";
import { Devices } from "./devicePos";

export const ListPackage = async () => {
  return await Request.fetchAPI("admin/gListPacked.php", "GET", {})
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const Payment = async (ownerId: any, packageId: any) => {
  return await Request.fetchAPI("stripe/payment.php", "POST", {
    owner_id: ownerId,
    packed_id: packageId,
  })
    .then((res: ResultData<IListPackage[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        returnUrl: "",
      };
    });
};

export const CheckPasscode = async (passcode: string) => {
  return await Request.fetchAPI("admin/check_exist_passcode.php", "POST", {
    passcode,
  })
    .then((res: NewOwnerType) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const AddOwner = async (
  UserIdStore: number,
  ContactPerson: string,
  StoreName: string,
  CellPhone: string,
  Address: string,
  EmailAddress: string,
  SuitOfUnit: string,
  City: string,
  // BusinessType: number,
  States: string,
  OwnerPassCode: string,
  Role: number,
  Package: number,
  PaymentStatus: number,
  IdCard: string,
  startDateFormatRef: string,
  CVC: any,
  password: string,
  detail_address: string,
  lat: number,
  lng: number,
  timeZone: number = 1,
  restaurantPhone: string,
  zipCode: number | string = "",
  tokenpayment: string = "",
  lastCart: string | number = "",
  shippingAddress: string = "",
  suitShipping: string = "",
  totalPrice: number = 0,
  ListDevices: Devices[] = []
) => {
  return await Request.fetchAPI("admin/addOwner.php", "POST", {
    admin_id: UserIdStore,
    contact_person: ContactPerson,
    store_name: StoreName,
    cell_phone: CellPhone,
    address: Address,
    email: EmailAddress,
    suit_of_unit: SuitOfUnit,
    city: City,
    // business_type: BusinessType,
    states: States,
    passCode: OwnerPassCode,
    role_owner: Role,
    packed_id: Package,
    payment_status: PaymentStatus,
    id_card: IdCard,
    date_card: startDateFormatRef,
    CVC: CVC,
    timeZone,
    zipCode,
    password,
    tokenpayment,
    lastCart,
    detail_address,
    lat,
    lng,
    restaurantPhone,
    shippingAddress,
    suitShipping,
    ListDevices: JSON.stringify(ListDevices),
    totalPrice,
  })
    .then((res: NewOwnerType) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const AddOwnerinAdmin = async (
  UserIdStore: number,
  ContactPerson: string,
  StoreName: string,
  CellPhone: string,
  Address: string,
  EmailAddress: string,
  SuitOfUnit: string,
  City: string,
  // BusinessType: number,
  States: string,
  OwnerPassCode: string,
  Role: number,
  Package: number,
  PaymentStatus: number,
  IdCard: string,
  startDateFormatRef: string,
  CVC: any,
  password: string,
  detail_address: string,
  lat: number,
  lng: number,
  timeZone: number = 1,
  zipCode: number | string = "",
  tokenpayment: string = "",
  lastCart: string | number = ""
) => {
  return await Request.fetchAPI("admin/addOwnerInAdmin.php", "POST", {
    admin_id: UserIdStore,
    contact_person: ContactPerson,
    store_name: StoreName,
    cell_phone: CellPhone,
    address: Address,
    email: EmailAddress,
    suit_of_unit: SuitOfUnit,
    city: City,
    // business_type: BusinessType,
    states: States,
    passCode: OwnerPassCode,
    role_owner: Role,
    packed_id: Package,
    payment_status: PaymentStatus,
    id_card: IdCard,
    date_card: startDateFormatRef,
    CVC: CVC,
    timeZone,
    zipCode,
    password,
    tokenpayment,
    lastCart,
    detail_address,
    lat,
    lng,
  })
    .then((res: NewOwnerType) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export interface IListPackage {
  id?: number;
  namePackage?: string;
  type?: string;
  label?: string;
}

interface NewOwnerType {
  status: any;
  message: string;
  data: NewOnwerData;
}

interface NewOnwerData {
  owner_id: number;
  payment_status: any;
}

interface ResultData<T> {
  status?: number;
  message?: string;
  data?: T;
  returnUrl?: string;
}

export interface ResultFeedbackAdmin<T> {
  status?: number;
  message?: string;
  data?: T;
  total: number;
}
export interface InfoFeedBackItem {
  id: number;
  owner_id: number;
  content: string;
  store_name: string;
  customer_name: string;
  cellphoone: string;
  email: string;
  address: string;
  suit_of_unit: string;
  store_city: string;
  store_state: string;
  zipcode: string;
  store_phone: string;
  time_feedback: string;
  listImg: ListImg[];
}
export interface ListImg {
  id: number;
  filename: string;
}

export const gHistoryFeedBackAdmin = async (
  limit: number,
  page_number: number
) => {
  return await Request.fetchAPI(`/admin/gHistoryFeedBack.php`, "POST", {
    limit,
    page_number,
  })
    .then((res: ResultFeedbackAdmin<InfoFeedBackItem[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        total: 0,
      };
    });
};
