import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import {
  FemaleOutlinedIcon,
  CalendarTodayOutlinedIcon,
  CallOutlinedIcon,
  Return,
  start,
  arrowDouble,
  MaleIcon,
} from "../../../../../../components/Image";
import ContentOderHistory from "../ContentOderHistory";
import Dropzone from "./Dropzone";
import "./Styles.css";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../../../components/ModalLoading";
import * as customerApi from "../../../../../../api/apiOwner/customerApi";
import { getLanguageUI } from "../../../../../../Services/languageUI";
import React from "react";
import * as SettingPrinterApi from "../../../../../../api/apiOwner/SettingPrinterApi";
interface drawerHistory {
  showDrawerHistory: boolean;
  customer_id: string;
  dismisDrawerHistory: () => void;
}

const HistoryOder: React.FC<drawerHistory> = ({
  showDrawerHistory,
  customer_id = "",
  dismisDrawerHistory,
}) => {
  const languageUI = getLanguageUI().customerPage;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [infoCustomer, setInfoCustomer] = useState<customerApi.InfoCustomer>();
  const [historyOrder, setHistoryOrder] = useState<customerApi.HistoryOrder[]>(
    []
  );
  const [listPrinterName, setListPrinterName] = useState<
    SettingPrinterApi.ListPrinterByCatalog[]
  >([]);
  const handleUpload = () => {
    // setAvatarUrl(url);
  };
  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await SettingPrinterApi.gListNamePrinterElse(
        UserIdStore,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  const gInfoCustomer = async () => {
    if (UserIdStore && customer_id !== "") {
      setShowLoading(true);
      const req = await customerApi.gHistoryOrderCustomer(
        UserIdStore,
        customer_id
      );
      if (Number(req?.status) === 1) {
        setInfoCustomer(req?.info);
        setHistoryOrder(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        dismisDrawerHistory();
      }
    }
  };

  useEffect(() => {
    if (showDrawerHistory) {
      gInfoCustomer();
      gListPrinter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDrawerHistory]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Drawer
        className="custom-btn-close custom-w-drawer-history"
        anchor="right"
        onClose={dismisDrawerHistory}
        open={showDrawerHistory}
      >
        <div className="padding-drawer-History h-100vh ">
          <div className="flex">
            <div className="dropzone mr-6">
              <Dropzone onUpload={handleUpload} />
              {avatarUrl && (
                <div className="avatar-container">
                  <img src={avatarUrl} alt="Avatar" className="avatar" />
                </div>
              )}
            </div>
            <div className="flex flex-column ">
              <span className="text-8 fw-600 font-urbansist text-212134 mb-4">
                {infoCustomer?.name}
              </span>
              <span
                className={`text-18px fw-600 font-urbansist ${
                  Number(infoCustomer?.gender) === 1
                    ? "text-FF2CD0"
                    : "text-269AFF"
                } flex items-center mb-4`}
              >
                {Number(infoCustomer?.gender) === 1 ? (
                  <FemaleOutlinedIcon className="mr-2" />
                ) : (
                  <MaleIcon className="mr-2" />
                )}
                {`${
                  Number(infoCustomer?.gender) === 1
                    ? languageUI.female
                    : infoCustomer?.gender === "2"
                    ? languageUI.male
                    : languageUI.male
                }`}
              </span>
              <span className="text-18px fw-600 font-urbansist text-666687 flex items-center mb-4">
                <CalendarTodayOutlinedIcon className="mr-2 " />
                {infoCustomer?.birthday ? infoCustomer?.birthday : "--"}
              </span>
              <span className="text-18px fw-600 font-urbansist text-666687 flex items-center">
                <CallOutlinedIcon className="mr-2" />
                {infoCustomer?.phone ? infoCustomer?.phone : "--"}
              </span>
            </div>
          </div>
          <div className="flex w-100 gap-2 mt-8">
            <div className="border-DCDCE4 radius-3 flex  p-2 w-50">
              <div className="mr-4">
                <img src={start} alt="" />
              </div>
              <div className="flex flex-column">
                <span className="text-18px fw-600 font-urbansist text-FAA300">
                  {languageUI.point}
                </span>
                <span className="text-18px fw-600 font-urbansist text-212134">
                  {infoCustomer?.points ? infoCustomer?.points : 0}
                </span>
              </div>
            </div>
            <div className="border-DCDCE4 radius-3 flex  p-2 w-50">
              <div className="mr-4">
                <img src={Return} alt="" />
              </div>
              <div className="flex flex-column">
                <span className="text-18px fw-600 font-urbansist text-FAA300">
                  {languageUI.returnVisits}
                </span>
                <span className="text-18px fw-600 font-urbansist text-212134">
                  {infoCustomer?.timeVisit ? infoCustomer?.timeVisit : 0}
                </span>
              </div>
            </div>
          </div>
          <div className="text-8 fw-600 font-urbansist text-212134 py-8">
            {languageUI.orderHistory}
          </div>
          <div className="radius-4 border">
            <table className="table custom-m-b">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-13 text-18px text-12B064 fw-600 font-urbansist"
                  >
                    {languageUI.date}
                  </th>
                  <th scope="col" className="w-13">
                    <div className="flex  items-center">
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        {languageUI.time}
                      </span>
                      <img src={arrowDouble} alt="" />
                    </div>
                  </th>

                  <th
                    scope="col"
                    className="w-13 text-18px text-12B064 fw-600 font-urbansist"
                  >
                    {languageUI.order}
                  </th>
                  <th
                    scope="col"
                    className="w-13 text-18px text-12B064 fw-600 font-urbansist"
                  >
                    {languageUI.table}
                  </th>
                  <th scope="col" className="w-13">
                    <div className="flex  items-center">
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        {languageUI.bills}
                      </span>
                      <img src={arrowDouble} alt="" />
                    </div>
                  </th>
                  <th scope="col" className="w-13">
                    <div className="flex  items-center">
                      <span className="mr-2 text-18px text-12B064 fw-600 font-urbansist">
                        Tips
                      </span>
                      <img src={arrowDouble} alt="" />
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="w-13 text-18px text-12B064 fw-600 font-urbansist"
                  >
                    {languageUI.staff}
                  </th>
                  <th className="w-9"></th>
                </tr>
              </thead>
              <tbody className="t-body-table-history-order">
                {historyOrder.map((item) => (
                  <ContentOderHistory
                    date={item?.dateorder}
                    time={item?.timeorder}
                    order={item?.id}
                    table={item?.table_id}
                    bill={item?.price}
                    tip={item?.tip_amount}
                    staff={item?.id ? item?.staff_name : "--"}
                    listPrinterName={listPrinterName}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default HistoryOder;
