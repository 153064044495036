import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import "../../Styles.css";
import ModalAddPrinter from "./component/ModalAddPrinter/ModalAddPrinter";
import { useEffect, useState } from "react";
import ModalChoosePrinter from "./component/ModalChoosePrinter/ModalChoosePrinter";
import ModalConfirmDelete from "./component/ModalConfirmDelete/ModalConfirmDelete";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as SettingPrinterApi from "../../../../api/apiOwner/SettingPrinterApi";
import ModalLoading from "../../../../components/ModalLoading";
import { getLanguageUI } from "../../../../Services/languageUI";

const ConnectPrinter = () => {
  const languageUI = getLanguageUI().setting;
  const [showModalAddPrint, setShowModalAddPrint] = useState(false);
  const [showModalChoosePrinter, setShowModalChoosePrinter] = useState(false);
  const [showModalDeletePrinter, setShowModalDeletePrinter] = useState(false);
  const [showModalDeletePrinterConfig, setShowModalDeletePrinterConfig] =
    useState(false);
  const [listPrinterConnect, setListPrinterConnect] = useState<
    SettingPrinterApi.ListPrinterConnect[]
  >([]); //danh sách máy in đang connect
  const [listCatalogPos, setListCatalogPos] = useState<
    SettingPrinterApi.ListPrinterCatalog[]
  >([]); //danh sách danh mục cấu hình kèm máy in
  const [catalogId, setCatalogId] = useState<string>(""); //id danh mục đang chọn
  const [configId, setconfigId] = useState<string>(""); //id cấu hình đang chọn
  const [PrinterId, setPrinterId] = useState<string>(""); //id máy in đang chọn
  const [printerName, setPrinterName] = useState<string>(""); //tên máy in đang chọn
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [showLoading, setShowLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listPrinterWindow, setListPrinterWindow] = useState<string[]>([]);

  const dismisModalAddPrint = (status?: boolean) => {
    setShowModalAddPrint(false);
    if (status) {
      gListPrinterOwner();
    }
  };
  const dismisModalChoosePrinter = (status?: boolean) => {
    setShowModalChoosePrinter(false);
    if (status) {
      gListPrinterByCatalog();
    }
  };
  const dismisModalDeletePrinter = () => {
    setShowModalDeletePrinter(false);
  };
  const dismisModalDeletePrinterConfig = () => {
    setShowModalDeletePrinterConfig(false);
  };

  //lấy danh sách các máy in đang connect
  const gListPrinterOwner = async () => {
    const req = await SettingPrinterApi.gListPrinterConnecting(
      UserIdStore,
      pos_id
    );
    if (Number(req?.status) === 1) {
      setListPrinterConnect(req?.data ?? []);
    } else {
      setListPrinterConnect([]);
    }
  };
  //lấy danh sách các máy theo danh mục
  const gListPrinterByCatalog = async () => {
    const req = await SettingPrinterApi.gListPrinterConnectingByOwner(
      UserIdStore
    );
    if (Number(req?.status) === 1) {
      setListCatalogPos(req?.data ?? []);
    } else {
      setListCatalogPos([]);
    }
  };
  //xóa máy in ở danh mục
  const DeletePrintConfig = async () => {
    if (configId !== "") {
      setShowLoading(true);
      const req = await SettingPrinterApi.DelPrinterToCatalog(
        UserIdStore,
        configId
      );
      if (Number(req?.status) === 1) {
        setconfigId("");
        setShowLoading(false);
        gListPrinterByCatalog();
        dismisModalDeletePrinterConfig();
      } else {
        // setconfigId('')
        setShowLoading(false);
        setMessageError(req?.message ?? "fail");
      }
    } else {
      setMessageError("Printer not found");
    }
  };
  //xóa máy in connect
  const DeletePrintConnect = async () => {
    if (PrinterId !== "") {
      setShowLoading(true);
      const req = await SettingPrinterApi.DelPrinterConnect(
        UserIdStore,
        PrinterId
      );
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        setPrinterId("");
        gListPrinterOwner();
        gListPrinterByCatalog();
        dismisModalDeletePrinter();
      } else {
        setShowLoading(false);
        setMessageError(req?.message ?? "fail");
        // setPrinterId('')
      }
    } else {
      setMessageError("Printer not found");
    }
  };
  useEffect(() => {
    // gListPrinterWindow()
    gListPrinterOwner();
    gListPrinterByCatalog();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="px-5 h-body-connect-printer ">
        <div className="">
          <p className="text-title-tab-setting text-black text-30px">
            {languageUI.avaiablePrinter}
          </p>
          <div className="d-flex flex-wrap gap-4 ps-4 mt-3">
            {listPrinterConnect.length > 0 &&
              listPrinterConnect.map((item, i) => (
                <div className="w-fit position-relative" key={`printer${i}`}>
                  <div className="box-icon-printer">
                    <LocalPrintshopIcon className="icon-printer" />
                  </div>
                  <div className="delete-printer-added ">
                    <CloseIcon
                      onClick={() => {
                        setPrinterId(item?.id);
                        setPrinterName(item?.printer_name);
                        setShowModalDeletePrinter(true);
                      }}
                      className="text-white"
                    />
                  </div>
                  <p className="text-title-tab-setting text-center mt-1 mb-0">
                    {item?.printer_name}
                  </p>
                  {item?.listType.map((item, index) => (
                    <p
                      className="font-noraml font-bold text-4a4a6a m-0"
                      key={index}
                    >
                      - {item.print_type}
                    </p>
                  ))}
                </div>
              ))}

            <div
              className="box-icon-add-printer position-relative d-flex justify-content-center align-items-center bg-transparent"
              onClick={() => {
                setShowModalAddPrint(true);
              }}
            >
              <LocalPrintshopIcon className="icon-add-printer opacity-0" />
              <AddIcon className="icon-printer text-primary position-absolute" />
            </div>
          </div>
          {/* <div className=''>
            {listCatalogPos.length > 0 && listCatalogPos.map((catalog, j) => (
              <div className='w-100 flex-shrilk-0' key={`catalog${j}`}>
                <p className="text-title-tab-setting text-black text-30px mt-3" >{catalog?.catalog_name}</p>
                <div className='d-flex flex-wrap gap-4 ps-4 mt-3'>
                  {catalog?.listPrinter?.length > 0 && catalog?.listPrinter?.map((row, index) => (
                    <div className='w-fit position-relative' key={`row${index}`}>
                      <div className='box-icon-printer'>
                        <LocalPrintshopIcon className='icon-printer' />
                      </div>
                      <div className='delete-printer-added '>
                        <CloseIcon onClick={() => { setconfigId(row?.id); setPrinterName(row?.printer_name); setShowModalDeletePrinterConfig(true); }} className='text-white' />
                      </div>
                      <p className='text-title-tab-setting text-center mt-1'>{row?.printer_name}</p>
                    </div>
                  ))}

                  <div className='box-icon-add-printer position-relative d-flex justify-content-center align-items-center bg-transparent' onClick={() => { setShowModalChoosePrinter(true); setCatalogId(catalog?.id) }}>
                    <LocalPrintshopIcon className='icon-add-printer opacity-0' />
                    <AddIcon className='icon-printer text-primary position-absolute' />
                  </div>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
      <ModalAddPrinter
        handleClose={dismisModalAddPrint}
        show={showModalAddPrint}
        owner_id={UserIdStore}
        setShowLoading={setShowLoading}
        listPrinterWindow={listPrinterWindow}
        listPrinterConnect={listPrinterConnect}
      />
      <ModalChoosePrinter
        handleClose={dismisModalChoosePrinter}
        show={showModalChoosePrinter}
        catalogId={catalogId}
        owner_id={UserIdStore}
        setShowLoading={setShowLoading}
      />
      <ModalConfirmDelete
        handleClose={dismisModalDeletePrinterConfig}
        show={showModalDeletePrinterConfig}
        submitConfilm={DeletePrintConfig}
        printerName={printerName}
        messageError={messageError}
      />
      <ModalConfirmDelete
        handleClose={dismisModalDeletePrinter}
        show={showModalDeletePrinter}
        submitConfilm={DeletePrintConnect}
        printerName={printerName}
        messageError={messageError}
      />
      <ModalLoading show={showLoading} onHide={() => setShowLoading(false)} />
    </div>
  );
};
export default ConnectPrinter;
