import { Form, InputGroup, Modal } from "react-bootstrap";
import "../Styles.css"
import { useEffect, useState } from "react";
import { leaf1, leaf2, minusGreen, plusGreen } from "../../../../../components/ImgExport";
import { CreateVoucherByOwner } from "../../../../../api/apiOwner/voucherApi";
import { getLanguageUI } from "../../../../../Services/languageUI";
import { Checkbox, FormControlLabel } from "@mui/material";



interface ModalCreateVoucherProps {
    show: boolean;
    UserIdStore: number | null;
    setShowLoading: (e: boolean) => void;
    HandleCreatSuccess: () => void;
    handleClose: () => void;
}

const ModalCreateVoucher = ({
    show,
    UserIdStore,
    setShowLoading,
    HandleCreatSuccess,
    handleClose,
}: ModalCreateVoucherProps) => {
    const languageUI = getLanguageUI().voucherPage
    const [voucherName, setVoucherName] = useState<string>('')
    const [voucherType, setVoucherType] = useState<number>(1)
    const [DiscountPercent, setDiscountPercent] = useState<number>(0)
    const [discountAmount, setDiscountAmount] = useState<number>(0)
    const [monthNumber, setMonthNumber] = useState<number | string>('')
    const [messageError, setMessageError] = useState<string>('')
    const [codeName, setCodeName] = useState<string>('')
    const [checked, setChecked] = useState<number>(0)
    const resetForm = () => {
        setVoucherName('')
        setVoucherType(1)
        setVoucherType(1)
        setMessageError('')
        setDiscountPercent(0)
        setDiscountAmount(0)
        setCodeName('')
        setChecked(0)
    }
    const SaveVoucher = async () => {
        var discount_value = voucherType === 1 ? DiscountPercent : discountAmount;
        if (!UserIdStore) {
            setMessageError(languageUI.errOwnerNotFound)
        } else
            if (voucherName === '') {
                setMessageError(languageUI.errVoucherName);
            } else if (discount_value <= 0) {
                setMessageError(languageUI.errCoupon)
            } 
            // else if (Number(monthNumber) <= 0) {
            //     setMessageError(languageUI.pleaseEnterExpire)
            // } 
            else {
                setMessageError('')
                setShowLoading(true)
                var expiryMonth = Number(monthNumber) <= 0?0:Number(monthNumber);
                const req = await CreateVoucherByOwner(UserIdStore, voucherName, voucherType, discount_value, expiryMonth, checked, codeName)
                if (Number(req?.status) === 1) {
                    HandleCreatSuccess();
                } else {
                    setShowLoading(false);
                    setMessageError(req?.message ?? "Fail")
                }
            }
    }
    useEffect(() => {
        if (!show) {
            resetForm()
        }

    }, [show])
    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer modal-import-inventory">
                <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
                    <Modal.Body>
                        <div className="px-48px">
                            <p className="text-delete-modal-customer mb-0 text-center">{languageUI.pleaseEnterExpire}</p>
                            <div className="mt-6">
                                <p className="text-add-table-name mb-1">{languageUI.voucherName}</p>
                                <div className="input-add-table">
                                    <input type="text" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder={languageUI.enterVoucherName} value={voucherName} onChange={(e) => {
                                        setVoucherName(e.target.value)
                                    }} />
                                </div>
                            </div>
                            <div>
                                <div className="mt-6">
                                    <p className="text-add-table-name mb-1">{languageUI.discountValue}</p>
                                    <div>
                                        <Form defaultValue={"option-inventory-1"}>
                                            <div className="d-flex justify-content-between align-items-center h-50px">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    id={`option-voucher-1`}
                                                    label={languageUI.percentGiftCard}
                                                    name="option-voucher"
                                                    className={`d-flex align-items-center w-fit radio-choose-option-inventory ${voucherType === 1 ? "text-green" : ""}`}
                                                    checked={voucherType === 1}
                                                    onClick={() => setVoucherType(1)}
                                                />
                                                <div className={`d-flex align-items-center gap-2 ${voucherType === 1 ? '' : 'd-none'}`}>
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <div className="btn-remove-plus-number" onClick={() => {
                                                            if (DiscountPercent > 0) {
                                                                setDiscountPercent(DiscountPercent - 1)
                                                            }
                                                        }}>
                                                            <img src={minusGreen} alt="" />
                                                        </div>
                                                        <div className="input-number-table-multi">
                                                            <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={DiscountPercent} onChange={(e) => {
                                                                if (e.target.value && Number(e.target.value) <= 0) {
                                                                    return;
                                                                }
                                                                if (e.target.value && Number(e.target.value) > 100) {
                                                                    return;
                                                                }
                                                                setDiscountPercent(Number(e.target.value))
                                                            }} />
                                                        </div>
                                                        <div className="btn-remove-plus-number" onClick={() => {
                                                            if (DiscountPercent < 100) {
                                                                setDiscountPercent(DiscountPercent + 1)
                                                            }
                                                        }}>
                                                            <img src={plusGreen} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center h-50px">
                                                <Form.Check // prettier-ignore
                                                    type="radio"
                                                    id={`option-voucher-2`}
                                                    label={languageUI.amountGiftCard}
                                                    className={`d-flex align-items-center w-fit radio-choose-option-inventory ${voucherType === 1 ? "" : "text-green"}`}
                                                    name="option-voucher"
                                                    checked={voucherType === 2}
                                                    onClick={() => setVoucherType(2)}
                                                />
                                                <div className={`d-flex align-items-center gap-2 ${voucherType === 1 ? 'd-none' : ''} `}>
                                                    <div className="d-flex gap-2 align-items-center">
                                                        <div className="btn-remove-plus-number" onClick={() => {
                                                            if (discountAmount > 0) {
                                                                setDiscountAmount(discountAmount - 1)
                                                            }
                                                        }}>
                                                            <img src={minusGreen} alt="" />
                                                        </div>
                                                        <div className="input-number-table-multi">
                                                            <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={discountAmount} onChange={(e) => {
                                                                if (e.target.value && Number(e.target.value) <= 0) {
                                                                    return;
                                                                }
                                                                setDiscountAmount(Number(e.target.value))
                                                            }} />
                                                        </div>
                                                        <div className="btn-remove-plus-number" onClick={() => {
                                                            setDiscountAmount(discountAmount + 1)
                                                        }}>
                                                            <img src={plusGreen} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    </div>

                                </div>
                            </div>
                            <div className="box-checkbox-voucher">
                                <FormControlLabel
                                    key={`happy-day`}
                                    className="radio-voucher"

                                    control={
                                        <Checkbox value={checked} className='custom-radio-circle text-primary' onChange={(e) => { setChecked(Number(e.target.checked)); if (Number(e.target.checked) === 0) { setCodeName('') } }} />
                                    }
                                    label={'Check this box if you want to create this voucher with the same CODE NAME'}
                                />
                                <div className="ps-4">
                                    <p className="content-checkbox-voucher">It can be used for marketing purposes because this code can be used by anyone one time and will be associated with their phone number.</p>
                                    <p className="note-checkbox-voucher">(Recommend not setting an expiration date for this kind of voucher.)</p>

                                    <div className="d-flex gap-2 align-items-center mt-2">
                                        <p className="content-checkbox-voucher">Create Code Name</p>
                                        <input type="text" className="code-name-voucher" readOnly={checked === 1 ? false : true} value={codeName.toUpperCase()} onChange={(e) => {
                                            if (e.target.value.length > 6) {
                                                return;
                                            } else {
                                                setCodeName(e.target.value)
                                            }
                                        }} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6">
                                <p className="text-add-table-name mb-1">{languageUI.voucherExpiresIn}</p>
                                <InputGroup className="mb-3  h-52px rounded-8px">
                                    <Form.Control
                                        placeholder={languageUI.voucherExpiresIn}
                                        aria-describedby="basic-addon2"
                                        className="text-input-usd"
                                        type="number"
                                        value={monthNumber}
                                        onChange={(e) => {
                                            if (e.target.value && (Number(e.target.value) <= 0 || Number(e.target.value) % 1 !== 0)) {
                                                return;
                                            }
                                            setMonthNumber(e.target.value)
                                        }}
                                    />
                                    <InputGroup.Text id="basic-addon2" className="text-input-usd">{languageUI.month}</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <div className="text-center text-danger text-italic">{messageError}</div>
                            <div className="d-flex justify-content-between w-100 mt-6">
                                <div className="btn-import-inventory-footer " onClick={handleClose}>{languageUI.back}</div>
                                <div className="btn-import-inventory-footer border-12B064 text-white bg-12B064" onClick={SaveVoucher}>{languageUI.save}</div>
                            </div>
                        </div>
                        <img src={leaf1} alt="" className="position-leaf1-voucher" />
                        <img src={leaf2} alt="" className="position-leaf2-voucher" />
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalCreateVoucher;
