import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "./Styles.css"
import { useLocation, useNavigate } from "react-router-dom";
import { FormatDolla } from "../../utils/format";
import { DetailBill, FoodDetail, gDetailOrderCustomer } from "../../api/apiOwner/custommerRecieptApi";
import ModalLoading from "../../components/ModalLoading";
import moment from "moment";
import { ClickAwayListener, Tooltip } from "@mui/material";
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { cartBagOrange } from "../../components/ImgExport";
import { getLanguageCheckIn } from "../../Services/languageCheckin";




const HistoryOrderDetail = () => {
  const languageUI = getLanguageCheckIn();
  const nameStore = localStorage.getItem("namestore");
  const navigate = useNavigate()
  const location = useLocation();
  const params: any = location.state;
  const order_id = params?.order_id;
  const owner_id = params?.ownerID;
  const [ShowLoading, setShowLoading] = useState(false);
  const [detailOrder, setDetailOrder] = useState<DetailBill>();
  const [openToolTip, setOpenToolTip] = useState(false);
  const [listFood, setListFood] = useState<FoodDetail[]>([])
  const gDetailOrder = async () => {
    setShowLoading(true)
    const req = await gDetailOrderCustomer(owner_id, order_id);
    if (Number(req?.status) === 1) {
      setDetailOrder(req?.data)
      setListFood(req?.data?.listFood ?? [])
      setShowLoading(false)
    } else {
      setShowLoading(false)
    }
  }


  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };
  useEffect(() => {
    gDetailOrder()

  }, [])

  return (

    <body>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <header className="w-100 custom-header-choose-menu d-flex justify-content-between align-items-center">
        <div className=" px-4 py-1 bg-white d-flex  rounded-4 border" onClick={() => navigate(-1)}>
          <ArrowBackIcon className="text-orange" />
        </div>
        <p className="title-name-store mb-0">{nameStore}</p>

      </header>
      <div className="h-100vh custom-pading-header px-4 rounded-4">
        <p className="font-bold text-4a4a6a text-xl text-center mt-2">{languageUI.HistoryOrder}</p>

        <div className="account-pages d-flex   bg-white " >
          <div className=" rounded-3 overflow-hidden w-100">

            <div className="p-3 custom-h-my-bill">
              <div className="pb-2 border-bottom">
                <div className="d-flex justify-content-between mb-1">
                  <span className="text-title">{languageUI.Invoicenumber}</span>
                  <span className="text-price-food-2 ">{detailOrder?.id}</span>
                </div>
                <div className="d-flex justify-content-between mb-1 ">
                  <span className="text-title">{languageUI.Printdatetime}</span>
                  <span className="text-price-food-2">{moment(detailOrder?.timeend, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY - hh:mm:ss")}</span>
                </div>
                <div className="d-flex justify-content-between mb-1 ">
                  <span className="text-title">{languageUI.Customer}</span>
                  <span className="text-price-food-2">{detailOrder?.custommer_name}</span>
                </div>
              </div>
              {listFood.map((item, i) => (
                <div className="pb-2 border-bottom" key={i}>

                  <div className="d-flex justify-content-between pt-2 gap-2" >
                    <span className="name-food ">{item?.name}</span>
                    <div className="d-flex align-items-center gap-1">
                      <div className="d-flex align-items-end gap-2px ">
                        <div className="text-number mb-2px">{item?.quantity}</div>
                        <div className="text-x">
                          x
                        </div>
                        <div className="text-number mb-2px">
                          {FormatDolla(Number(item?.price))}$
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="d-flex align-items-center gap-2">
                    {item?.notefood_name && (
                      <div className="bg-F6F9F8 mt-1">
                        <p className="mb-0 text-12B064">{item?.notefood_name}</p>
                      </div>
                    )}
                    <div className="status-items-order mt-1">
                      <img src={cartBagOrange} alt="" />
                      <p className="mb-0 text-items-order">{Number(item.form_order) === 1 ? languageUI.Dinein : languageUI.Takeaway}</p>
                    </div>
                  </div>
                  {item?.side_name && (
                    <div className="mb-2">
                      <div className="d-flex gap-1 mt-1"><p className="mb-0 text-choose-a-side text-666687">+ {item?.side_name}</p> <p className="mb-0 text-choose-a-side text-12B064">(Free x1)</p></div>
                    </div>
                  )}

                  {item?.note && (
                    <p className="text-note-information-card-items mb-0">{item?.note}</p>
                  )}
                </div>
              ))}
              <div className="py-3">
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">{languageUI.ItemsTotal}</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">{Number(detailOrder?.sub_total) ? FormatDolla(Number(detailOrder?.sub_total)) : 0}</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">{languageUI.Discount}</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">{FormatDolla(Number(detailOrder?.discount))}</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">Tip</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">{FormatDolla(Number(detailOrder?.tip_amount))}</div>
                  </div>
                </div>
                <div className="d-flex justify-content-between mb-2">
                  <span className="text-subtitle">Tax</span>
                  <div className="d-flex gap-2px">
                    <div className="text-dollar-2">$</div>
                    <div className="text-price-food-2">{Number(detailOrder?.sub_total) ? FormatDolla(Number(detailOrder?.tax_percent) * Number(detailOrder?.sub_total) / 100) : 0} </div>
                  </div>
                </div>
                {Number(detailOrder?.feePrice) > 0 && (
                  <div className="d-flex align-items-end justify-content-between mb-1">
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <div className="tooltip-payment-web-checkin">
                        <Tooltip
                          className=""
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={openToolTip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          placement="right"
                          title={languageUI.TheconveniencefeeischargedpertransactionbyathirdpartyvendorWedonotretainanyportionofthisfee}
                        >
                          <div className="d-flex gap-1 align-items-end w-fit" onClick={() => setOpenToolTip(true)}>
                            <div className="title-pay-bill-customer mb-0">{languageUI.Conveniencefee}</div>
                            <HelpOutlineSharpIcon className="" />
                          </div>
                        </Tooltip>
                      </div>
                    </ClickAwayListener>
                    <div className="d-flex gap-2px align-items-end">
                      <p className="price-pay-bill-customer mb-0 h-fit">{FormatDolla(Number(detailOrder?.feePrice))}</p>
                      <p className="price-pay-bill-customer-dollar  text-8E8EA9">$</p>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between">
                  <span className="text-content text-4A4A6A">{languageUI.Total}</span>
                  <div className="d-flex gap-2px ">
                    <div className="text-FFB080 ">$</div>
                    <div className=" text-price-mybill">{FormatDolla(Number(detailOrder?.total_order))}</div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>
    </body>
  );
};

export default HistoryOrderDetail;
