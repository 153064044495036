import "./styles.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as addCartApi from "../../../api/apiCheckin/addCartApi";
// import * as orderApi from "../../../api/apiCheckin/orderApi";
import { FormatDolla } from "../../../utils/format";
import {
  arrowLeft,
  knifeFork,
  plusOrange,
} from "../../../components/ImgExport";
// import socket from '../../../utils/socket';
import ModalLoading from "../../../components/ModalLoading";
// import * as SettingPrinterApi from "../../../api/apiOwner/SettingPrinterApi";
import React from "react";
import OrderFoodCardItem from "../../webcheckin/components/OrderFoodCardItem/OrderFoodCardItem";
import ModalAddSideDishOnline from "../Component/ModalAddSideDishOnline/ModalAddSideDishOnline";
import { getLanguageCheckIn } from "../../../Services/languageCheckin";

const CardInformationOnline: React.FC = () => {
  const navigate = useNavigate();
  const languageUI = getLanguageCheckIn();
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalAddSideDish, setShowModalAddSideDish] =
    useState<boolean>(false);
  const [listSide, setListSide] = useState<addCartApi.listSide[]>([]);
  const [cartInfo, setCartInfo] = useState<addCartApi.InfoCart[]>([]);
  const [totalCart, setTotalCart] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalSide, setTotalSide] = useState<number>(0);
  const [totalSideChoosed, setTotalSideChoosed] = useState<number>(0);
  const [requestMealList, setRequestMealList] = useState<string>("");
  // const [messError, setMessError] = useState<string>('')
  // const [totalPriceSides, setTotalPriceSides] = useState<number>(0)

  // const [listPrinterName, setListPrinterName] = useState<SettingPrinterApi.ListPrinterConnect[]>([])
  const maxLength = 250;
  const dismisModalAddSideDish = () => {
    setShowModalAddSideDish(false);
    gInfoCart();
  };
  // const gListPrinter = async () => {
  //   const req = await SettingPrinterApi.gNamePrinterMainByCatalog(ownerID, 2);
  //   if (Number(req?.status) === 1) {
  //     setListPrinterName(req?.data ?? [])
  //   }
  // }

  const handleChange = (event: any) => {
    const newText = event.target.value;

    if (newText.length <= maxLength) {
      setRequestMealList(newText);
    }
  };

  const gInfoCart = async () => {
    if (custommerId && ownerID) {
      setShowLoading(true);
      const Info = await addCartApi.gInfoCartCustommerOnline(
        custommerId,
        ownerID
      );
      if (Info.status == 1) {
        setListSide(Info?.listSide ?? []);
        setCartInfo(Info?.data ?? []);
        setTotalCart(Number(Info?.total));
        setTotalPrice(Number(Info?.totalPrice));
        setTotalSide(Number(Info?.maxSide));
        setTotalSideChoosed(Number(Info?.totalSideChoosed));
        // setTotalPriceSides(Number(Info?.totalPriceSides))

        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  //xóa cart
  const DelCart = async (cart_id: string) => {
    // setCartInfo(cartInfo.filter(item => item?.id != cart_id))
    if (cart_id) {
      setShowLoading(true);
      const Del = await addCartApi.delCartById(cart_id);
      if (Del.status == 1) {
        gInfoCart();
      } else {
        setShowLoading(false);
      }
    }
  };

  //cập nhật số lượng
  const UpdateQuantityCart = async (cart_id: string, quantity: number = 1) => {
    setShowLoading(true);
    const upQuantity = await addCartApi.upQuantityCartById(cart_id, quantity);
    if (upQuantity.status == 1) {
      gInfoCart();
    } else {
      setShowLoading(false);
    }
  };

  // //tạo hóa đơn
  // const CreatOrderPay = async () => {
  //   if (cartInfo.length == 0) {
  //     setMessError("Please choose a dish!")
  //   } else if (custommerId && ownerID) {

  //     setShowLoading(true)
  //     const result = await orderApi.CreateOrderOnline(custommerId, ownerID, requestMealList, totalPrice, cartInfo, listSide, totalPriceSides);
  //     if (result.status == 1) {

  //       socket.emit('PushNotificationCreateOrder', { ownerID, custommerId, message: result?.message ?? "You have a new order" })
  //       socket.emit('PushPrintOrder', { ownerID, custommerId, customer_name: result?.customer_name, order_id: result?.order_id, requestMealList, totalPrice, cartInfo, listPrinterName, listSide })

  //       setTimeout(() => {
  //         setShowLoading(false)
  //         navigate('/online/orderstatus')
  //       }, 1500);

  //     } else {
  //       setMessError(result.message ?? "")
  //       setShowLoading(false)
  //     }
  //   }

  // }

  const Order_More = () => {
    navigate("/online/menu/" + custommerId);
  };
  useEffect(() => {
    // gListPrinter()
    gInfoCart();
  }, []);
  return (
    <div className="bg-white w-100 min-h-100vh">
      <header className="header-imformation-order px-3 d-flex justify-content-between align-items-start">
        <div>
          <p className="mb-0 text-review-imformation">{languageUI.Review}</p>
          <p className="mb-0 text-number-order">
            {languageUI.yourorder} ({totalCart})
          </p>
        </div>

        <div className="flex flex-column align-items-end">
          <div
            className="d-flex align-items-center gap-1"
            onClick={() => Order_More()}
          >
            <img src={plusOrange} alt="" />
            <p className="mb-0 text-order-more">{languageUI.Ordermore}</p>
          </div>
          {totalSide > 0 && (
            <div className="mt-2">
              <button
                className="custom-side-dish-btn border-none w-100 text-ffffff"
                onClick={() => setShowModalAddSideDish(true)}
              >
                {languageUI.ChooseFreeSideHere} (x{totalSide})
              </button>
            </div>
          )}
        </div>
      </header>
      <div className="padding-top-header-information">
        <div className="pt-3 px-3">
          <div className="d-flex flex-column gap-2">
            {cartInfo.map((item, i) => (
              <OrderFoodCardItem
                // status={true}
                textStatus="Well done"
                key={"cart" + i}
                insufficient={item?.insufficient}
                slcon={Number(item?.slcon)}
                notechef={item?.notechef}
                notefood_name={item?.notefood_name}
                cart_id={item?.id}
                DelCart={DelCart}
                food_id={item?.service_item_id}
                price={Number(item?.price)}
                foodName={item?.name}
                quantity={Number(item?.quantity)}
                service_item_id={item?.service_item_id}
                side_name={item?.side_name}
                table_id={item?.table_id}
                status={item?.type == "1" ? false : true}
                UpdateQuantityCart={UpdateQuantityCart}
                totalOnFood={10000}
              />
            ))}
          </div>

          {/* <p className="choose-a-side mb-0">{languageUI.Morerequest}</p>
          <textarea
            className="text-more-request"
            id="textarea"
            value={requestMealList}
            onChange={handleChange}
            maxLength={maxLength}
          ></textarea> */}
          {/* <div className="w-100 d-flex justify-content-end px-3">
            <small className="text-muted text-right">
              {requestMealList.length}/{maxLength}
            </small>
          </div> */}
          <div>
            {listSide.length > 0 && (
              <>
                <p className="choose-a-side mb-0">{languageUI.SideDish}</p>
                {listSide.map((item, index) => (
                  <div>
                    <p className="text-side-dish mb-0">
                      {index + 1}. {item.name} (x{item.quantity})
                    </p>
                  </div>
                ))}
              </>
            )}
          </div>
          {/* <div>
                        <p className="choose-a-side mb-0">You like some more drink</p>
                        <div className="d-flex gap-1 overflow-x-scroll scroll-hidden">
                            <div className="w-150px flex-shrink-0">
                                <FoodMenuItem foodTotal={12} img="" handleAddToCart={() => { }} className="" link={'#'} name="Sting" price={12} setFootTotal={() => { }} customerID="" description="" />
                            </div>
                            <div className="w-150px flex-shrink-0">
                                <FoodMenuItem foodTotal={12} img="" handleAddToCart={() => { }} className="" link={'#'} name="Sting" price={12} setFootTotal={() => { }} customerID="" description="" />
                            </div>
                            <div className="w-150px flex-shrink-0">
                                <FoodMenuItem foodTotal={12} img="" handleAddToCart={() => { }} className="" link={'#'} name="Sting" price={12} setFootTotal={() => { }} customerID="" description="" />
                            </div>
                        </div>
                        <p className="choose-a-side mb-0">You want to add  something else</p>
                        <div className="d-flex gap-1 overflow-x-scroll scroll-hidden">
                            <div className="w-150px flex-shrink-0">
                                <FoodMenuItem foodTotal={12} img="" handleAddToCart={() => { }} className="" link={'#'} name="Sting" price={12} setFootTotal={() => { }} customerID="" description="" />
                            </div>
                            <div className="w-150px flex-shrink-0">
                                <FoodMenuItem foodTotal={12} img="" handleAddToCart={() => { }} className="" link={'#'} name="Sting" price={12} setFootTotal={() => { }} customerID="" description="" />
                            </div>
                            <div className="w-150px flex-shrink-0">
                                <FoodMenuItem foodTotal={12} img="" handleAddToCart={() => { }} className="" link={'#'} name="Sting" price={12} setFootTotal={() => { }} customerID="" description="" />
                            </div>
                        </div>

                    </div> */}
        </div>
      </div>
      <div className="footer-choose-menu py-3 w-100 bg-white px-3 border-top">
        <div className="d-flex justify-content-between ">
          <p className="text-total-information-card">{languageUI.Total}</p>
          <div className="d-flex gap-2px">
            <div className="text-dollar">$</div>
            <div className="text-price-information-card">
              {FormatDolla(totalPrice)}
            </div>
          </div>
        </div>
        <div className=" d-flex gap-2 align-items-center">
          <div
            className="position-relative"
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={arrowLeft} alt="" />
          </div>
          {/* <a href="/webcheckin/OrderStatus" className="w-100"> */}

          <button
            className="custom-filter-btn border-none w-100 text-ffffff"
            onClick={() => navigate("/online/payment")}
            disabled={cartInfo.length == 0}
          >
            <img src={knifeFork} alt="" />
            {languageUI.Paynow}
          </button>
          {/* </a> */}
        </div>
      </div>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <ModalAddSideDishOnline
        dissmis={dismisModalAddSideDish}
        show={showModalAddSideDish}
        totalSide={totalSide}
        totalSideChoosed={totalSideChoosed}
      />
    </div>

    // <div className="px-2">
    //     <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
    //     <ModalAlert handleClose={dismisModalAlertCallStaff} dismisImg={true} img={"#"} show={showModalAlertCallStaff} title={messError}></ModalAlert>
    //     <div className="bg-gray-light rounded-5 w-100 container py-3 my-5 min-h-100">
    //         <div className="d-flex justify-content-between align-items-center">
    //             <div className="custom-btn-back-page bg-primary" onClick={() => navigate(-1)}>
    //                 <i className="fas fa-arrow-left text-xl"></i>
    //             </div>
    //             <p className="text-3xl font-bold m-0">Cart</p>
    //             <div className="custom-btn-order-more bg-primary" onClick={() => { navigate("choosemenu/" + custommerId) }}>
    //                 Order More
    //             </div>
    //         </div>
    //         <div className="mt-2 d-flex">
    //             <img src={a123} alt=" " className="h-50px w-50px me-2" />
    //             <p className="m-0 text-end d-flex align-items-end text-base font-bold">Order Card ({totalCart})</p>
    //         </div>
    //         <div>
    //             {cartInfo.map((item, i) => (
    //                 <OrderFoodCardItem
    //                     key={'cart' + i}
    //                     insufficient={item?.insufficient}
    //                     slcon={Number(item?.slcon)}
    //                     notechef={item?.notechef}
    //                     notefood_name={item?.notefood_name}
    //                     cart_id={item?.id}
    //                     DelCart={DelCart}
    //                     food_id={item?.service_item_id}
    //                     price={Number(item?.price)}
    //                     foodName={item?.name}
    //                     quantity={Number(item?.quantity)}
    //                     service_item_id={item?.service_item_id}
    //                     side_name={item?.side_name}
    //                     table_id={item?.table_id}
    //                     Status={item?.type == '1' ? true : false}
    //                     UpdateQuantityCart={UpdateQuantityCart}
    //                     totalOnFood={10000}
    //                 />
    //             ))}

    //         </div>
    //         <div className="">
    //             <p className="font-bold text-end mt-2 me-2">Total: {totalCart}</p>
    //         </div>
    //         <p className="mb-2 font-bold text-base text-11151f">Add More Request for this meal!</p>
    //         <input type="text" value={requestMealTmp} className="border-none  width-75 height-40px border-input-add-request" placeholder="More request for serve" onChange={(e: any) => { setRequestMealTmp(e.target.value) }}
    //             onKeyPress={(ev) => {
    //                 if (ev.code == "Enter") {
    //                     setRequestMealList([...requestMealList, requestMealTmp])
    //                     setRequestMealTmp('')
    //                 }

    //             }} />
    //         <div className="d-flex gap-2 overflow-x-scroll hidden-scrollbar mt-2">
    //             {requestMealList.length > 0 && requestMealList.map((res, i) => (
    //                 <div className="h-30px btn btn-sm border-none px-2 bg-primary text-11151f d-flex justify-content-center align-items-center text-white font-bold p-0 mt-1 position-relative flex-shink-0" key={'res' + i} >
    //                     {res}
    //                     <div className="position-absolute custom-btn-close-add-request" onClick={() => {
    //                         setRequestMealList(requestMealList.filter(list => list != res))
    //                     }}>
    //                         <i className="fas fa-times text-11151f text-xs"></i>
    //                     </div>
    //                 </div>
    //             ))}
    //         </div>
    //         <div className="d-flex justify-content-between px-3 mt-3">
    //             <p className="font-bold text-xl">Total</p>
    //             <p className="font-bold text-xl">${FormatDolla(totalPrice)}</p>
    //         </div>
    //         <Button className="h-30px w-100 bg-primary p-0 mt-1 font-bold" onClick={() => CreatOrderPay()}>
    //             SEND ORDER
    //         </Button>
    //         <ModalConfimOrder show={showModalConfirmOrder} Order_More={Order_More} handleClose={dismisModalConfirmOrder} cartInfo={cartInfo} />
    //     </div>
    // </div >
  );
};

export default CardInformationOnline;
