import { Modal, Button } from "react-bootstrap";
import { loadingImg } from "../Image";

interface ModalLoadingProps {
  show: boolean;
  onHide?: () => void;
}

/**
 * ModalLoading
 */
const ModalLoading = (props: ModalLoadingProps) => {
  const addClassNameDialog = () => {
    let modalEl = document.querySelector(".loading-modal");
    let modalElParent = modalEl?.parentElement;
    modalElParent?.classList.add("loading-dialog");
  };

  return (
    <Modal
      backdropClassName="modal-loading-backdrop"
      dialogClassName="loading-modal"
      backdrop="static"
      keyboard={false}
      centered
      show={props.show}
      onHide={props.onHide}
      onEntered={addClassNameDialog}
    >
      <Modal.Body className="d-flex">
        <img src={loadingImg} className="m-auto" />
      </Modal.Body>
    </Modal>
  );
};

export default ModalLoading;
