import React, { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading";
import "./Styles.css";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import GiftCardItem from "./components/GiftCard/GiftCardItem/GiftCardItem";
import { useNavigate, useParams } from "react-router-dom";
import * as giftCodeVoucher from "../../api/apiOwner/giftCodeVoucher";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import html2canvas from "html2canvas";

const GiftCardDetail: React.FC = () => {
  let params = useParams();
  let voucher_id = params?.voucher_id;
  let is_never_expire = params?.is_never_expire;

  const owner_id = useSelector(userSlice.selectorUserId);
  const navigate = useNavigate();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showNumber, setShowNumber] = useState<number>(10);
  const [listCardVoucher, setListCardVoucher] = useState<
    giftCodeVoucher.DetailGiftVoucher[]
  >([]);

  const gListCardVoucher = async () => {
    if (owner_id && voucher_id) {
      setShowLoading(true);
      const req = await giftCodeVoucher.gListCodeGiftVoucher(
        owner_id,
        voucher_id,
        showNumber
      );
      if (Number(req?.status) === 1) {
        setListCardVoucher(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setListCardVoucher([]);
      }
    }
  };

  const updateDownloadGift = async (giftId: number) => {
    const req = await giftCodeVoucher.upDownloadGift(giftId);
    if (Number(req.status) === 1) {
      gListCardVoucher();
    }
  };
  function convertAndDownloadAll() {
    const contents = document.querySelectorAll(".gift-card-printer");
    console.log("Đang tải....");

    const contentArray = Array.from(contents);

    function convertAndDownloadNext() {
      if (contentArray.length > 0) {
        const content: any = contentArray.shift();

        const filename =
          listCardVoucher[contents.length - contentArray.length - 1]
            ?.voucher_code + ".png";
        updateDownloadGift(
          Number(
            listCardVoucher[contents.length - contentArray.length - 1]?.id
          ) ?? 0
        );
        html2canvas(content).then(function (canvas) {
          const imgURL = canvas.toDataURL("image/png");
          const downloadLink = document.createElement("a");
          downloadLink.href = imgURL;
          downloadLink.download = filename;

          // Simulate a click event to trigger the download
          downloadLink.click();

          // Chuyển đổi và tải về tiếp theo
          convertAndDownloadNext();
        });
      }
    }

    // Bắt đầu chuyển đổi và tải về từng phần tử một
    convertAndDownloadNext();
  }
  useEffect(() => {
    gListCardVoucher();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="px-3 pt-90px  h-100vh pb-5  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory ">
          <div className="d-flex justify-content-between align-items-center py-7 px-6 border-bottom">
            <div className="d-flex align-items-center gap-3">
              <div
                className="btn-create-voucher"
                onClick={() => {
                  navigate("/web-owner/giftCard");
                }}
              >
                <ArrowBackRoundedIcon className="text-xl text-white" />
                Back
              </div>
              <p className="mb-0 text-inventory">List QrCode Gift Card</p>
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex gap-2 align-items-center">
                <p className="mb-0 text-11151f font-bold text-xl">Show:</p>
                <div className="input-show-list-gift-card">
                  <input
                    type="number"
                    className=" border-none focus-visiable-none w-full"
                    value={showNumber?.toString()}
                    onChange={(e) => {
                      if (e.target.value && Number(e.target.value) <= 0) {
                        return;
                      }
                      setShowNumber(Number(e.target.value));
                    }}
                    onBlur={gListCardVoucher}
                  />
                </div>
              </div>

              <div
                className="btn-create-voucher"
                onClick={convertAndDownloadAll}
              >
                <FileDownloadRoundedIcon className="text-xl text-white" />
                Download All
              </div>
            </div>
          </div>
          <div className="p-4 d-flex flex-wrap gap-3 box-list-gift-card-detail">
            {listCardVoucher.map((items, i) => (
              <GiftCardItem
                listCardVoucher={items}
                key={i}
                handleUpdateStatus={updateDownloadGift}
                index={i + 1}
                is_never_expire={Number(is_never_expire)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCardDetail;
