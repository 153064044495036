interface WeatherIcon {
    name: string;
    category: string;
}

export const WEATHERICONS: WeatherIcon[] = [
    {
        name: 'day-cloudy-high',
        category: 'new-icons',
    },
    {
        name: 'moonrise',
        category: 'new-icons',
    },
    {
        name: 'na',
        category: 'new-icons',
    },
    {
        name: 'volcano',
        category: 'new-icons',
    },
    {
        name: 'day-light-wind',
        category: 'new-icons',
    },
    {
        name: 'moonset',
        category: 'new-icons',
    },
    {
        name: 'flood',
        category: 'new-icons',
    },
    {
        name: 'train',
        category: 'new-icons',
    },
    {
        name: 'day-sleet',
        category: 'new-icons',
    },
    {
        name: 'night-sleet',
        category: 'new-icons',
    },
    {
        name: 'sandstorm',
        category: 'new-icons',
    },
    {
        name: 'small-craft-advisory',
        category: 'new-icons',
    },
    {
        name: 'day-haze',
        category: 'new-icons',
    },
    {
        name: 'night-alt-sleet',
        category: 'new-icons',
    },
    {
        name: 'tsunami',
        category: 'new-icons',
    },
    {
        name: 'gale-warning',
        category: 'new-icons',
    },
    {
        name: 'night-cloudy-high',
        category: 'new-icons',
    },
    {
        name: 'raindrop',
        category: 'new-icons',
    },
    {
        name: 'earthquake',
        category: 'new-icons',
    },
    {
        name: 'storm-warning',
        category: 'new-icons',
    },
    {
        name: 'night-alt-partly-cloudy',
        category: 'new-icons',
    },
    {
        name: 'barometer',
        category: 'new-icons',
    },
    {
        name: 'fire',
        category: 'new-icons',
    },
    {
        name: 'hurricane-warning',
        category: 'new-icons',
    },
    {
        name: 'sleet',
        category: 'new-icons',
    },
    {
        name: 'humidity',
        category: 'new-icons',
    },
    {
        name: 'day-sunny',
        category: 'day-time',
    },
    {
        name: 'day-cloudy',
        category: 'day-time',
    },
    {
        name: 'day-cloudy-gusts',
        category: 'day-time',
    },
    {
        name: 'day-cloudy-windy',
        category: 'day-time',
    },
    {
        name: 'day-fog',
        category: 'day-time',
    },
    {
        name: 'day-hail',
        category: 'day-time',
    },
    {
        name: 'day-haze',
        category: 'day-time',
    },
    {
        name: 'day-lightning',
        category: 'day-time',
    },
    {
        name: 'day-rain',
        category: 'day-time',
    },
    {
        name: 'day-rain-mix',
        category: 'day-time',
    },
    {
        name: 'day-rain-wind',
        category: 'day-time',
    },
    {
        name: 'day-showers',
        category: 'day-time',
    },
    {
        name: 'day-sleet',
        category: 'day-time',
    },
    {
        name: 'day-sleet-storm',
        category: 'day-time',
    },
    {
        name: 'day-snow',
        category: 'day-time',
    },
    {
        name: 'day-snow-thunderstorm',
        category: 'day-time',
    },
    {
        name: 'day-snow-wind',
        category: 'day-time',
    },
    {
        name: 'day-sprinkle',
        category: 'day-time',
    },
    {
        name: 'day-storm-showers',
        category: 'day-time',
    },
    {
        name: 'day-sunny-overcast',
        category: 'day-time',
    },
    {
        name: 'day-thunderstorm',
        category: 'day-time',
    },
    {
        name: 'day-windy',
        category: 'day-time',
    },
    {
        name: 'solar-eclipse',
        category: 'day-time',
    },
    {
        name: 'hot',
        category: 'day-time',
    },
    {
        name: 'day-cloudy-high',
        category: 'day-time',
    },
    {
        name: 'day-light-wind',
        category: 'day-time',
    },

    {
        name: 'night-clear',
        category: 'night-time',
    },
    {
        name: 'night-alt-cloudy',
        category: 'night-time',
    },
    {
        name: 'night-alt-cloudy-gusts',
        category: 'night-time',
    },
    {
        name: 'night-alt-cloudy-windy',
        category: 'night-time',
    },
    {
        name: 'night-alt-hail',
        category: 'night-time',
    },
    {
        name: 'night-alt-lightning',
        category: 'night-time',
    },
    {
        name: 'night-alt-rain',
        category: 'night-time',
    },
    {
        name: 'night-alt-rain-mix',
        category: 'night-time',
    },
    {
        name: 'night-alt-rain-wind',
        category: 'night-time',
    },
    {
        name: 'night-alt-showers',
        category: 'night-time',
    },
    {
        name: 'night-alt-sleet',
        category: 'night-time',
    },
    {
        name: 'night-alt-sleet-storm',
        category: 'night-time',
    },
    {
        name: 'night-alt-snow',
        category: 'night-time',
    },
    {
        name: 'night-alt-snow-thunderstorm',
        category: 'night-time',
    },
    {
        name: 'night-alt-snow-wind',
        category: 'night-time',
    },
    {
        name: 'night-alt-sprinkle',
        category: 'night-time',
    },
    {
        name: 'night-alt-storm-showers',
        category: 'night-time',
    },
    {
        name: 'night-alt-thunderstorm',
        category: 'night-time',
    },
    {
        name: 'night-cloudy',
        category: 'night-time',
    },
    {
        name: 'night-cloudy-gusts',
        category: 'night-time',
    },
    {
        name: 'night-cloudy-windy',
        category: 'night-time',
    },
    {
        name: 'night-fog',
        category: 'night-time',
    },
    {
        name: 'night-hail',
        category: 'night-time',
    },
    {
        name: 'night-lightning',
        category: 'night-time',
    },
    {
        name: 'night-partly-cloudy',
        category: 'night-time',
    },
    {
        name: 'night-rain',
        category: 'night-time',
    },
    {
        name: 'night-rain-mix',
        category: 'night-time',
    },
    {
        name: 'night-rain-wind',
        category: 'night-time',
    },
    {
        name: 'night-showers',
        category: 'night-time',
    },
    {
        name: 'night-sleet',
        category: 'night-time',
    },
    {
        name: 'night-sleet-storm',
        category: 'night-time',
    },
    {
        name: 'night-snow',
        category: 'night-time',
    },
    {
        name: 'night-snow-thunderstorm',
        category: 'night-time',
    },
    {
        name: 'night-snow-wind',
        category: 'night-time',
    },
    {
        name: 'night-sprinkle',
        category: 'night-time',
    },
    {
        name: 'night-storm-showers',
        category: 'night-time',
    },
    {
        name: 'night-thunderstorm',
        category: 'night-time',
    },
    {
        name: 'lunar-eclipse',
        category: 'night-time',
    },
    {
        name: 'stars',
        category: 'night-time',
    },
    {
        name: 'storm-showers',
        category: 'night-time',
    },
    {
        name: 'thunderstorm',
        category: 'night-time',
    },
    {
        name: 'night-alt-cloudy-high',
        category: 'night-time',
    },
    {
        name: 'night-cloudy-high',
        category: 'night-time',
    },
    {
        name: 'night-alt-partly-cloudy',
        category: 'night-time',
    },
    {
        name: 'cloud',
        category: 'neutral',
    },
    {
        name: 'cloudy',
        category: 'neutral',
    },
    {
        name: 'cloudy-gusts',
        category: 'neutral',
    },
    {
        name: 'cloudy-windy',
        category: 'neutral',
    },
    {
        name: 'fog',
        category: 'neutral',
    },
    {
        name: 'hail',
        category: 'neutral',
    },
    {
        name: 'rain',
        category: 'neutral',
    },
    {
        name: 'rain-mix',
        category: 'neutral',
    },
    {
        name: 'rain-wind',
        category: 'neutral',
    },
    {
        name: 'showers',
        category: 'neutral',
    },
    {
        name: 'sleet',
        category: 'neutral',
    },
    {
        name: 'snow',
        category: 'neutral',
    },
    {
        name: 'sprinkle',
        category: 'neutral',
    },
    {
        name: 'storm-showers',
        category: 'neutral',
    },
    {
        name: 'thunderstorm',
        category: 'neutral',
    },
    {
        name: 'snow-wind',
        category: 'neutral',
    },
    {
        name: 'snow',
        category: 'neutral',
    },
    {
        name: 'smog',
        category: 'neutral',
    },
    {
        name: 'smoke',
        category: 'neutral',
    },
    {
        name: 'lightning',
        category: 'neutral',
    },
    {
        name: 'raindrops',
        category: 'neutral',
    },
    {
        name: 'raindrop',
        category: 'neutral',
    },
    {
        name: 'dust',
        category: 'neutral',
    },
    {
        name: 'snowflake-cold',
        category: 'neutral',
    },
    {
        name: 'windy',
        category: 'neutral',
    },
    {
        name: 'strong-wind',
        category: 'neutral',
    },
    {
        name: 'sandstorm',
        category: 'neutral',
    },
    {
        name: 'earthquake',
        category: 'neutral',
    },
    {
        name: 'fire',
        category: 'neutral',
    },
    {
        name: 'flood',
        category: 'neutral',
    },
    {
        name: 'meteor',
        category: 'neutral',
    },
    {
        name: 'tsunami',
        category: 'neutral',
    },
    {
        name: 'volcano',
        category: 'neutral',
    },
    {
        name: 'hurricane',
        category: 'neutral',
    },
    {
        name: 'tornado',
        category: 'neutral',
    },
    {
        name: 'small-craft-advisory',
        category: 'neutral',
    },
    {
        name: 'gale-warning',
        category: 'neutral',
    },
    {
        name: 'storm-warning',
        category: 'neutral',
    },
    {
        name: 'hurricane-warning',
        category: 'neutral',
    },
    {
        name: 'wind-direction',
        category: 'neutral',
    },

    {
        name: 'alien',
        category: 'miscellaneous',
    },
    {
        name: 'celsius',
        category: 'miscellaneous',
    },
    {
        name: 'fahrenheit',
        category: 'miscellaneous',
    },
    {
        name: 'degrees',
        category: 'miscellaneous',
    },
    {
        name: 'thermometer',
        category: 'miscellaneous',
    },
    {
        name: 'thermometer-exterior',
        category: 'miscellaneous',
    },
    {
        name: 'thermometer-internal',
        category: 'miscellaneous',
    },
    {
        name: 'cloud-down',
        category: 'miscellaneous',
    },
    {
        name: 'cloud-up',
        category: 'miscellaneous',
    },
    {
        name: 'cloud-refresh',
        category: 'miscellaneous',
    },
    {
        name: 'horizon',
        category: 'miscellaneous',
    },
    {
        name: 'horizon-alt',
        category: 'miscellaneous',
    },
    {
        name: 'sunrise',
        category: 'miscellaneous',
    },
    {
        name: 'sunset',
        category: 'miscellaneous',
    },
    {
        name: 'moonrise',
        category: 'miscellaneous',
    },
    {
        name: 'moonset',
        category: 'miscellaneous',
    },
    {
        name: 'refresh',
        category: 'miscellaneous',
    },
    {
        name: 'refresh-alt',
        category: 'miscellaneous',
    },
    {
        name: 'umbrella',
        category: 'miscellaneous',
    },
    {
        name: 'barometer',
        category: 'miscellaneous',
    },
    {
        name: 'humidity',
        category: 'miscellaneous',
    },
    {
        name: 'na',
        category: 'miscellaneous',
    },
    {
        name: 'train',
        category: 'miscellaneous',
    },
    {
        name: 'moon-new',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-cresent-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-cresent-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-cresent-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-cresent-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-cresent-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-cresent-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-first-quarter',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-gibbous-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-gibbous-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-gibbous-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-gibbous-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-gibbous-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-waxing-gibbous-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-full',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-gibbous-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-gibbous-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-gibbous-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-gibbous-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-gibbous-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-gibbous-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-third-quarter',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-crescent-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-crescent-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-crescent-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-crescent-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-crescent-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-waning-crescent-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-new',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-cresent-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-cresent-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-cresent-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-cresent-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-cresent-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-cresent-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-first-quarter',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-gibbous-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-gibbous-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-gibbous-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-gibbous-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-gibbous-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waxing-gibbous-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-full',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-gibbous-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-gibbous-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-gibbous-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-gibbous-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-gibbous-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-gibbous-6',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-third-quarter',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-crescent-1',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-crescent-2',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-crescent-3',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-crescent-4',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-crescent-5',
        category: 'moon-phase',
    },
    {
        name: 'moon-alt-waning-crescent-6',
        category: 'moon-phase',
    },
    {
        name: 'time-1',
        category: 'time',
    },
    {
        name: 'time-2',
        category: 'time',
    },
    {
        name: 'time-3',
        category: 'time',
    },
    {
        name: 'time-4',
        category: 'time',
    },
    {
        name: 'time-5',
        category: 'time',
    },
    {
        name: 'time-6',
        category: 'time',
    },
    {
        name: 'time-7',
        category: 'time',
    },
    {
        name: 'time-8',
        category: 'time',
    },
    {
        name: 'time-9',
        category: 'time',
    },
    {
        name: 'time-10',
        category: 'time',
    },
    {
        name: 'time-11',
        category: 'time',
    },
    {
        name: 'time-12',
        category: 'time',
    },
    {
        name: 'direction-up',
        category: 'direction-arrows',
    },
    {
        name: 'direction-up-right',
        category: 'direction-arrows',
    },
    {
        name: 'direction-right',
        category: 'direction-arrows',
    },
    {
        name: 'direction-down-right',
        category: 'direction-arrows',
    },
    {
        name: 'direction-down',
        category: 'direction-arrows',
    },
    {
        name: 'direction-down-left',
        category: 'direction-arrows',
    },
    {
        name: 'direction-left',
        category: 'direction-arrows',
    },
    {
        name: 'direction-up-left',
        category: 'direction-arrows',
    },
];
