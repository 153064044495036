import { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import "./styles.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  addToken,
  conversionPointCustomer,
} from "../../../api/apiCheckin/checkinApi";
import ModalLoading from "../../../components/ModalLoading";
import Swal from "sweetalert2";
import { getLanguageCheckIn } from "../../../Services/languageCheckin";
import React from "react";

const StatusOrderMethodOnline = () => {
  const languageUI = getLanguageCheckIn();
  const [now, setNow] = useState(10);
  const [hiddenNow, setHiddenNow] = useState(false);
  const ownerID = Number(localStorage.getItem("ownerID"));
  const params: any = useParams();
  let id_customer = params.id;
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const AddToken = async () => {
    const req = await addToken(id_customer, ownerID);
    if (Number(req?.status) === 1 && req?.data) {
      localStorage.setItem("token_custommer", req.data);
      setShowLoading(false);
      navigate(`/online/menu/${id_customer}`);
    } else {
      setShowLoading(false);
      Swal.fire({
        position: "center",
        icon: "error",
        title: req?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  const GoToMenu = async (status: string) => {
    setShowLoading(true);
    const req = await conversionPointCustomer(ownerID, id_customer);
    if (Number(req?.status) === 1) {
      localStorage.setItem("statusMethod", status);
      AddToken();
    } else {
      setShowLoading(false);
      Swal.fire({
        position: "center",
        icon: "error",
        title: req?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };
  useEffect(() => {
    if (now < 99) {
      setTimeout(() => {
        setNow(now + 20);
      }, 100);
    } else if (now > 99) {
      setHiddenNow(true);
    }
  }, [now]);
  return (
    <div>
      <ModalLoading onHide={() => setShowLoading(false)} show={showLoading} />
      <div className="w-100 h-100 bg-wait-screen">
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ minHeight: "100vh" }}
        >
          <div
            className={`shadow-choose-status-order w-90 rounded-4 max-w-500px bg-white rounded-3 p-4 d-flex flex-column gap-3 ${
              hiddenNow ? "" : "d-none"
            }`}
          >
            <h3 className="text-center font-bold text-4a4a6a mb-0">
              {languageUI.Chooseyourordermethod}
            </h3>
            <div
              className="w-100 bg-primary rounded-4 py-4 d-flex justify-content-center font-bold text-white"
              onClick={() => navigate("/online/bookingOnline")}
            >
              {languageUI.Reservation}
            </div>
            <div
              className="w-100 bg-primary rounded-4 py-4 d-flex justify-content-center font-bold text-white"
              onClick={() => GoToMenu("1")}
            >
              {languageUI.Pickup}
            </div>
            {/* <div
              className="w-100 bg-primary rounded-4 py-4 d-flex justify-content-center font-bold text-white"
              onClick={() => GoToMenu("2")}
            >
              {languageUI.Delivery}
            </div> */}
          </div>
          <ProgressBar
            variant="warning"
            now={now}
            className={`w-50 ${hiddenNow ? "d-none" : ""} `}
          />
        </div>
      </div>
    </div>
  );
};
export default StatusOrderMethodOnline;
