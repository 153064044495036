import { Modal } from "react-bootstrap";
import "./Styles.css";
import React from "react";
import { imgWarring } from "../../../../components/Image";

interface ModalNotFoundOwnerProps {
  show: boolean;
}

const ModalNotFoundOwner = ({ show }: ModalNotFoundOwnerProps) => {
  return (
    <>
      <Modal
        show={show}
        centered
        className="bg-transparent modal-delete-customer modal-clock-in-out"
      >
        <Modal.Dialog className="h-100 w-100 m-0">
          <Modal.Body className="h-100 w-100 m-0">
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="box-img-warring">
                <img src={imgWarring} alt="" className="w-100 h-100" />
              </div>
              <p className="text-owner-not-found">Owner Not Found </p>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalNotFoundOwner;
