// OrderStatus

import { Accordion } from "react-bootstrap";
import "./Styles.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormatDolla } from "../../utils/format";
import {
  facebookIcon,
  instagramIcon,
  like3d,
  plusOrange,
  wallet,
} from "../../components/ImgExport";
import OrderListITem from "./components/OrderListITem/OrderListITem";
import * as orderConfilmApi from "../../api/apiCheckin/orderConfilmApi";
import * as paymentApi from "../../api/apiCheckin/paymentApi";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";

import React from "react";
import ModalLoading from "../../components/ModalLoading";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import FacebookIcon from "@mui/icons-material/Facebook";
import { gInfoLinkeOwner } from "../../api/apiOwner/SettingInformation";

const OrderStatus: React.FC = () => {
  const navigate = useNavigate();
  const languageUI = getLanguageCheckIn();
  const [listSide, setListSide] = useState<orderConfilmApi.SideList[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const custommerId = localStorage.getItem("custommerId");
  const table_id = localStorage.getItem("table_id");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const [tax, setTax] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [orderInfo, setOrrderInfo] = useState<orderConfilmApi.InfoOrder[]>([]);
  const [totalFood, setTotalFood] = useState<number>(0);
  const [dNone, setDNone] = useState(false);
  const [linkFacebook, setLinkFacebook] = useState<string>("");
  const [linkInstagram, setLinkInstagram] = useState<string>("");

  const gInfoOrderConfilm = async () => {
    if (custommerId && ownerID && table_id) {
      setShowLoading(true);
      const Info = await orderConfilmApi.gOrderConfilmPay(
        custommerId,
        ownerID,
        table_id
      );
      if (Number(Info.status) === 1) {
        setOrrderInfo(Info?.data ?? []);
        setTotalFood(Number(Info?.totalFood));
        setTotalPrice(Number(Info?.totalPrice));
        setListSide(Info.SideList ?? []);

        // setRequestOrder(Info?.requestList ?? [])
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const gLink = async () => {
    if (ownerID) {
      const req = await gInfoLinkeOwner(ownerID);
      if (Number(req?.status) === 1) {
        setLinkFacebook(req?.data?.facebook_link ?? "");
        setLinkInstagram(req?.data?.instagram_link ?? "");
      } else {
        setLinkFacebook("");
        setLinkInstagram("");
      }
    }
  };

  const gConfig = async () => {
    const config = await paymentApi.getConfig(ownerID);
    if (Number(config.status) === 1) {
      setTax(Number(config?.data?.tax) ?? 0);
    }
  };
  useEffect(() => {
    gConfig();
    gLink();
    gInfoOrderConfilm();
  }, []);
  return (
    <div className="bg-white w-100 min-h-100vh">
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <header className="header-imformation-order px-3 d-flex justify-content-between align-items-start">
        <div>
          <p className="mb-0 text-review-imformation">{languageUI.Review}</p>
          <p className="mb-0 text-number-order">
            {languageUI.yourorder} ({totalFood})
          </p>
        </div>
        <div
          className="d-flex align-items-center gap-1"
          onClick={() => navigate("/webcheckin/choosemenu/" + custommerId)}
        >
          <img src={plusOrange} alt="" />
          <p className="mb-0 text-order-more">{languageUI.Ordermore}</p>
        </div>
      </header>
      <div className="padding-top-header-information">
        <div className="pt-3 px-3">
          <div
            className={`${
              dNone === true ? "d-none" : ""
            } transition-0.5 d-flex flex-column align-items-center`}
          >
            {/* <p className="we-got-you-order">{languageUI.Yourorderwillbereadyin}</p> */}
            <div className="d-flex gap-2 align-items-center mb-2">
              <ThumbUpRoundedIcon
                className="icon-play-game"
                style={{ color: "#00A15A" }}
              />
              <p className="we-start-to m-0">
                We got your order and <br /> start to processing it now!
              </p>
            </div>
            {/* <img src={like3d} alt="" /> */}
            {/* <p className="text-center text-4a4a6a">Enjoy your wait with some <br /> games, P.S the game points <br />can be turned into restaurant points </p> */}
            <div className="px-5 w-100">
              <div className="border-top pt-2 w-100">
                <div className="d-flex align-items-center justify-content-between mb-3 gap-3 ">
                  <p className="we-got-you-order m-0">
                    Would you like to play a game <br /> while you're waiting
                  </p>
                  <button
                    className="rounded-3 py-3 text-xl border-none font-bold focus-visiable-none text-white bg-primary "
                    onClick={() => navigate("/webcheckin/listGame")}
                  >
                    <SportsEsportsIcon className="text-white icon-play-game " />
                  </button>
                </div>
              </div>
              {linkFacebook !== "" && (
                <div className="border-top pt-2 w-100">
                  <div className="d-flex align-items-center justify-content-between mb-3 gap-3 ">
                    <p className="we-got-you-order m-0">
                      Like us on Facebook to get <br /> updates on our food!
                    </p>
                    <button
                      className="rounded-3 pt-3 text-xl border-none font-bold focus-visiable-none text-white "
                      style={{ backgroundColor: "#1877F2" }}
                      onClick={() => (window.location.href = linkFacebook)}
                    >
                      <img
                        src={facebookIcon}
                        alt=""
                        style={{ width: "45px", height: "40px" }}
                      />
                    </button>
                  </div>
                </div>
              )}
              {linkInstagram !== "" && (
                <div className="border-top pt-2 w-100">
                  <div className="d-flex align-items-center justify-content-between mb-3 gap-3 ">
                    <p className="we-got-you-order m-0">
                      Or follow us on Instagram to
                      <br /> get food updates as well!
                    </p>

                    <img
                      src={instagramIcon}
                      alt=""
                      style={{ height: "62px", width: "62px" }}
                      onClick={() => (window.location.href = linkInstagram)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <Accordion>
            <Accordion.Item
              eventKey="0"
              className="border-none focus-visible-none"
            >
              <Accordion.Header
                className="text-order-list"
                onClick={() => {
                  setDNone(!dNone);
                }}
              >
                {languageUI.Orderlistandprices}
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column gap-2">
                  <OrderListITem
                    orderInfo={orderInfo}
                    status={true}
                    textStatus="Well done"
                  />
                </div>
                <div>
                  {listSide.length > 0 && (
                    <>
                      <p className="choose-a-side mb-0">
                        {languageUI.SideDish}
                      </p>
                      {listSide.map((item, index) => (
                        <div>
                          <p className="text-side-dish mb-0">
                            {index + 1}. {item.name} (x{item.quantity})
                          </p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="my-2 py-2 border-top d-flex flex-column gap-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">
                      {languageUI.ItemsTotal}
                    </div>
                    <div className="d-flex gap-2px">
                      <div className="text-price-food-3">
                        {FormatDolla(Number(totalPrice))}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">Tax</div>
                    <div className="d-flex gap-2px">
                      <div className="text-dollar-3">$</div>
                      <div className="text-price-food-3">
                        {tax > 0 ? FormatDolla((totalPrice * tax) / 100) : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="footer-choose-menu py-3 w-100 bg-white px-3 border-top">
        <div className="d-flex justify-content-between ">
          <p className="text-total-information-card">{languageUI.Total}</p>
          <div className="d-flex gap-2px">
            <div className="text-dollar">$</div>
            <div className="text-price-information-card">
              {FormatDolla(totalPrice + (totalPrice * tax) / 100)}
            </div>
          </div>
        </div>
        <div className=" d-flex gap-2 align-items-center">
          {/* <div className="position-relative" onClick={()=>{navigate(-1)}}>
                        <img src={arrowLeft} alt="" />
                    </div> */}

          {/* <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={gListTip}>
                        <img src={wallet} alt="" />
                        Pay now
                    </button> */}

          <button
            className="custom-filter-btn border-none w-100 text-ffffff"
            onClick={() => navigate("/webcheckin/Payment")}
          >
            <img src={wallet} alt="" />
            {languageUI.Paynow}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
