import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  KeyboardArrowLeftIcon,
  lockClosed,
  iconFemale,
  iconMale,
  cake,
  Group,
  phone,
  arrowRight,
  dollarGreen,
} from "../../../../../../components/Image";
import Topbar from "../../../../../../layouts/Topbar";
import "./Styles.css";
import React from "react";
import Form from "react-bootstrap/Form";
import * as comboApi from "../../../../../../api/apiOwner/comboApi";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment-timezone";
import ModalLoading from "../../../../../../components/ModalLoading";
import ModalToast from "../../../ModalToast/ModalToastErr";
import * as StaffApi from "../../../../../../api/apiOwner/StaffApi";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { uploadImgStaff } from "../../../../../../components/ImgExport";
import { targetImg } from "../../../../../../api/urlImage";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { formatDate } from "../../../../../../utils/format";
import { getLanguageUI } from "../../../../../../Services/languageUI";
interface EditCreateStaffProps {}
const EditCreateStaff: React.FC<EditCreateStaffProps> = () => {
  const languageUI = getLanguageUI().staffPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const location = useLocation();
  const params: any = location.state;
  const staff_id = params?.staff_id;
  const tabOptionEmployee = params?.tabOptionEmployee;
  const tabSelect = params?.tabSelect;
  const [comboPayroll, setComboPayroll] = useState<comboApi.ComboOption[]>([]);
  const [comboRole, setComboRole] = useState<comboApi.ComboOption[]>([]);
  const [payrollId, setPayrollId] = useState<string>("");
  const [genderId, setGenderId] = useState<string>("");
  const [passcode, setPasscode] = useState<string>("");
  const [status, setStatus] = useState<boolean>(true);
  const [clockIn, setClockIn] = useState<dayjs.Dayjs | null | any>("");
  const [clockOut, setClockOut] = useState<dayjs.Dayjs | null | any>("");
  const [autoClock, setAutoClock] = useState<boolean>(true);
  const [autoTimeValue, setautoTimeValue] = useState<dayjs.Dayjs | null | any>(
    ""
  );
  const [payrollValue, setpayrollValue] = useState<string>("");
  const [tipChecked, setTipChecked] = useState<boolean>(true);
  const [birthday, setBirthDay] = useState<dayjs.Dayjs | null | any>("");
  const [address, setAddress] = useState<string>("");
  const [roleId, setroleId] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [staffName, setStaffName] = useState("");
  const [messageError, setMessageError] = useState("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [showToast, setShowToas] = useState<boolean>(false);
  const [PhotoTmp, setPhotoTmp] = useState<string>(""); //ảnh tạm tải lên
  const [File, setFile] = useState<null | File>(); //ảnh tạm tải lên
  const navigate = useNavigate();
  const avtRef = useRef<HTMLInputElement>(null);
  const [MessageToast, setMessageToast] = useState<string>("");

  const gComboPayRoll = async () => {
    const req = await comboApi.gComboPayroll();
    if (Number(req?.status) === 1) {
      setComboPayroll(req?.data ?? []);
    }
  };
  const gComboRole = async () => {
    const req = await comboApi.gComboPosition();
    if (Number(req?.status) === 1) {
      setComboRole(req?.data ?? []);
    }
  };

  const onSelectFile = async (picture: any) => {
    var file = picture[0];
    if (!file.type.match("image")) {
      setMessageToast("File bạn chọn không đúng định dạng!");
    } else {
      setMessageToast("");
      var picReader = new FileReader();
      picReader.readAsDataURL(file);
      picReader.onload = function () {
        setFile(file);

        setPhotoTmp(picReader.result?.toString() ?? "");
      };
      picReader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };

  const gDetailStaff = async () => {
    if (staff_id) {
      setShowLoading(true);
      const req = await StaffApi.gDetailStaffActive(staff_id);
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        const Info = req?.data;
        setStaffName(Info?.name ?? "");
        setPasscode(Info?.passcode ?? "");
        setGenderId(Info?.gender ?? "");
        setStatus(Number(Info?.active) === 1 ? true : false);
        setBirthDay(Info?.birthday ? dayjs(Info?.birthday) : "");
        setPhoneNumber(Info?.phone ?? "");
        setAddress(Info?.address ?? "");
        setroleId(Info?.positon_id ?? "");
        setClockIn(
          Info?.working_time_start
            ? dayjs(
                moment(new Date()).format("YYYY-MM-DD") +
                  " " +
                  moment(Info?.working_time_start, "hh:mma").format("HH:mm")
              )
            : ""
        );
        setClockOut(
          Info?.working_time_end
            ? dayjs(
                moment(new Date()).format("YYYY-MM-DD") +
                  " " +
                  moment(Info?.working_time_end, "hh:mma").format("HH:mm")
              )
            : ""
        );
        setAutoClock(Number(Info?.automatic_clockout) === 1 ? true : false);
        setautoTimeValue(
          Info?.automatic_clockout_time
            ? dayjs(
                moment(new Date()).format("YYYY-MM-DD") +
                  " " +
                  moment(Info?.automatic_clockout_time, "hh:mma").format(
                    "HH:mm"
                  )
              )
            : ""
        );
        setPayrollId(Info?.payroll_id ? Info?.payroll_id : "");
        setpayrollValue(Info?.payroll_value ? Info?.payroll_value : "");
        setTipChecked(Number(Info?.tip) === 1 ? true : false);
        setPhotoTmp(Info?.img ? targetImg + Info?.img : "");
      } else {
        setShowLoading(false);
      }
    }
  };
  const SubmitAddAccount = async () => {
    if (staffName.trim() === "") {
      setMessageError("Staff name is required");
    } else if (passcode.length !== 4) {
      setMessageError("Passcode is required and includes 4 numbers");
    } else if (genderId === "") {
      setMessageError("Gender is required");
    } else if (birthday === "" || isNaN(birthday?.$d)) {
      setMessageError("Birthday malformed");
    } else if (phoneNumber === "") {
      setMessageError("Phone number is required");
    } else if (address.trim() === "") {
      setMessageError("Address is required");
    } else if (roleId === "") {
      setMessageError("Role is required");
    } else if (clockIn === "" || isNaN(clockIn?.$d)) {
      setMessageError("Clock in malformed");
    } else if (
      !isNaN(clockIn?.$d) &&
      !isNaN(clockOut?.$d) &&
      formatDate(clockIn?.$d) > formatDate(clockOut?.$d)
    ) {
      setMessageError("Clock in malformed");
      return;
    } else if (clockOut === "" || isNaN(clockOut?.$d)) {
      setMessageError("Clock out malformed");
    } else if (autoClock && isNaN(autoTimeValue?.$d)) {
      setMessageError("AutoTime value malformed");
    } else if (payrollId === "") {
      setMessageError("Payrol is required");
    } else if (payrollValue === "") {
      setMessageError("Salary is required");
    } else {
      setMessageError("");
      if (UserIdStore) {
        setShowLoading(true);
        const clockIntime = moment(formatDate(clockIn?.$d)).format("hh:mm A");
        const clockOuttime = moment(formatDate(clockOut?.$d)).format("hh:mm A");
        const autoClockTime = moment(formatDate(autoTimeValue?.$d)).format(
          "HH:mm"
        );
        const birth = moment(formatDate(birthday?.$d)).format("YYYY-MM-DD");
        const req = await StaffApi.editInfomationStaff(
          UserIdStore,
          staff_id,
          passcode,
          staffName,
          genderId,
          birth,
          phoneNumber,
          address,
          roleId,
          status ? 1 : 0,
          clockIntime,
          clockOuttime,
          autoClock ? 1 : 0,
          autoClockTime,
          payrollId,
          payrollValue,
          tipChecked ? 1 : 0,
          File
        );
        if (Number(req?.status) === 1) {
          setMessageSuccess("Successfully added employees");
          setShowLoading(false);
          setShowToas(true);
          navigate("/web-owner/staffs", {
            state: { tabSelect, tabOptionEmployee },
          });
        } else {
          setMessageError(req?.message ?? "Edit new staff fail");
          setShowLoading(false);
        }
      } else {
        setMessageError("Owner not found");
      }
    }
  };

  useEffect(() => {
    if (staff_id) {
      gDetailStaff();
      gComboPayRoll();
      gComboRole();
    } else {
      navigate("/web-owner/staffs");
    }
  }, []);
  return (
    <>
      <ModalToast
        show={showToast}
        handleClose={() => setShowToas(false)}
        content={messageSuccess}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="">{/* <Topbar pageName="Staffs" /> */}</div>
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex justify-between  ml-12 pt-3">
            <div
              className="w-100 flex items-center py-12px"
              onClick={() =>
                navigate("/web-owner/staffs", {
                  state: { tabSelect, tabOptionEmployee },
                })
              }
            >
              <div className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center mr-18px">
                <KeyboardArrowLeftIcon />
              </div>
              <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                {languageUI.editInfoStaff}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className=" flex justify-center items-center py-11">
        <div className="w-100 max-width-900px radius-18px bg-white h-100vh-240px">
          <div className=" px-12 ">
            <div className="flex pt-6 w-100  justify-between gap-2">
              <div className={`change-avt-staff position-relative p-1 `}>
                <img
                  src={uploadImgStaff}
                  alt=""
                  className={` ${PhotoTmp !== "" ? "d-none" : ""}`}
                />
                <img
                  src={PhotoTmp}
                  alt=""
                  className={`w-100 h-100 radius-50 ${
                    PhotoTmp === "" ? "d-none" : ""
                  } object-fit-cover`}
                  onError={(e) => {
                    e.currentTarget.src = uploadImgStaff;
                  }}
                />
                <input
                  type="file"
                  ref={avtRef}
                  accept="image/*"
                  onChange={(e) => {
                    onSelectFile(e.target.files);
                  }}
                  className="position-absolute w-100 h-100px opacity-0 top-0 start-0"
                  onClick={(e: any) => (e.target.value = null)}
                />
              </div>
              {/* <Dropzone onUpload={handleUpload} />
                                {avatarUrl && (
                                    <div className="avatar-container">
                                        <img src={avatarUrl} alt="Avatar" className="avatar" />
                                    </div>
                                )} */}
              <div className="flex gap-2 w-100 justify-between">
                <div className="flex flex-column w-50">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A mb-2">
                    {languageUI.staffName}
                  </span>
                  <div className="radius-6px border  p-2 mb-4">
                    <input
                      type="text"
                      className="border-none bg-transparent w-100"
                      value={staffName}
                      onChange={(e) => setStaffName(e.target.value)}
                    />
                  </div>
                  <div className="flex items-center mb-1">
                    <Form.Check // prettier-ignore
                      type={"radio"}
                      id={`radio-female`}
                      name="radio-gender"
                      className="d-flex align-items-center p-0 custom-radio-staff"
                      checked={Number(genderId) === 2}
                      onClick={() => setGenderId("2")}
                      label={
                        <div className="d-flex align-items-center mt-1">
                          <img src={iconFemale} alt="" className="mr-2" />
                          <span className="text-18px fw-600 font-urbansist text-FF2CD0">
                            {languageUI.female}
                          </span>
                        </div>
                      }
                    />
                    {/* <Radion
                                            checked={selectedValue === 'female'}
                                            onChange={(e) => setSelectedValue(e.target.value)}
                                            value='female'
                                            className="mr-2 color-raius-btn" />
                                        <img src={iconFemale} alt="" className="mr-2" />
                                        <span className="text-18px fw-600 font-urbansist text-FF2CD0">Female</span> */}
                  </div>
                  <div className="flex items-center">
                    <Form.Check // prettier-ignore
                      type={"radio"}
                      id={`radio-male`}
                      name="radio-gender"
                      checked={Number(genderId) === 1}
                      onClick={() => setGenderId("1")}
                      className="d-flex align-items-center p-0 custom-radio-staff mt-1"
                      label={
                        <div className="d-flex align-items-center">
                          <img src={iconMale} alt="" className="mr-2" />
                          <span className="text-18px fw-600 font-urbansist text-269AFF">
                            {languageUI.male}
                          </span>
                        </div>
                      }
                    />
                    {/* <Radio
                                            checked={selectedValue === 'male'}
                                            onChange={(e) => setSelectedValue(e.target.value)}
                                            value='male'
                                            className="mr-2 color-raius-btn" />
                                         */}
                  </div>
                </div>
                <div className="flex flex-column w-50">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A mb-2">
                    {languageUI.passcode}
                  </span>
                  <div className="radius-6px border  flex items-center p-2 mb-4">
                    <img src={lockClosed} alt="" className="mr-2" />
                    <input
                      type="number"
                      className="border-none bg-transparent"
                      value={passcode}
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          return;
                        }
                        setPasscode(e.target.value);
                      }}
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="text-18px fw-600 font-urbansist text-4A4A6A mr-12">
                      {languageUI.status}
                    </span>
                    <Form>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className="custom-switch-size custom-color-switch "
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
            <div className="pr-6">
              <div className="w-100">
                <div className="py-2">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A ">
                    {languageUI.birthday}
                  </span>
                  <div className="flex items-center border p-2 radius-6px mt-2">
                    <img src={cake} alt="" className="mr-2" />
                    {/* <input type="text" className="border-none w-100 h-20px" value={birthday} onChange={(e) => setBirthDay(e.target.value)} /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDatePicker"]}>
                        <DemoItem>
                          <MobileDatePicker
                            className="border-mui-none"
                            defaultValue={""}
                            value={birthday}
                            onChange={(newValue) => {
                              setBirthDay(newValue);
                            }}
                            format="DD-MM-YYYY"
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="py-2">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A ">
                    {languageUI.phoneNumber}
                  </span>
                  <div className="flex items-center border p-2 radius-6px mt-2">
                    <img src={phone} alt="" className="mr-2" />
                    <input
                      type="number"
                      className="border-none w-100 h-20px"
                      placeholder="Phone number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>

                <div className="py-2">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A ">
                    {languageUI.address}
                  </span>
                  <div className="flex items-center border p-2 radius-6px mt-2">
                    <img src={address} alt="" className="mr-2" />
                    <input
                      type="text"
                      className="border-none w-100 h-20px"
                      placeholder="Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>

                <div className="py-2">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A ">
                    {languageUI.role}
                  </span>
                  <div className="d-flex align-items-center border  px-2  rounded-6px ">
                    <img src={Group} alt="" className="mr-2" />
                    <Select
                      className="react-select react-select-container w-100  border-select-none custom-select-role"
                      classNamePrefix="react-select"
                      defaultValue={[""]}
                      placeholder={languageUI.chooseRole}
                      isClearable={true}
                      options={comboRole}
                      value={comboRole.filter(
                        (item) => Number(item?.value) === Number(roleId)
                      )}
                      onChange={(e) => setroleId(e?.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div>
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A ">
                    {languageUI.workingTime}
                  </span>
                  <div className="flex items-center  gap-3">
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <DesktopTimePicker
                            className="w-150px"
                            defaultValue={""}
                            value={clockIn}
                            onChange={(newValue) => {
                              setClockIn(newValue);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={[
                                                        'TimePicker',
                                                        'MobileTimePicker',
                                                        'DesktopTimePicker',
                                                        'StaticTimePicker',
                                                    ]}
                                                >

                                                    <DemoItem >
                                                        <MobileTimePicker className="custom-input-clockin-out" defaultValue={''} value={clockIn} onChange={(newValue) => {
                                                            setClockIn(newValue);
                                                        }} />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider> */}
                    </div>
                    <div className="mt-8">
                      <img src={arrowRight} alt="" />
                    </div>
                    <div>
                      {/* <div className="flex items-center border radius-6px mt-2">
                                                <input type="text" className="border-none w-70px h-40px bg-transparent" value={clockOut} onChange={(e) => setClockOut(e.target.value)} />
                                                <Form.Select className="custom-select" value={optionClockOut} onChange={(e: any) => setOptionClockOut(e.target.value)}>
                                                    <option value={'am'}>AM</option>
                                                    <option value={'pm'}>PM</option>
                                                </Form.Select>
                                            </div> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <DesktopTimePicker
                            className="w-150px"
                            value={clockOut}
                            onChange={(newValue) => {
                              setClockOut(newValue);
                              setautoTimeValue(newValue);
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={[
                                                        'MobileTimePicker',
                                                    ]}
                                                >

                                                    <DemoItem >
                                                        <MobileTimePicker className="custom-input-clockin-out" value={clockOut} onChange={(newValue) => {
                                                            setClockOut(newValue); setautoTimeValue(newValue);
                                                        }} />
                                                    </DemoItem>
                                                </DemoContainer>
                                            </LocalizationProvider> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-2 w-100">
                <div className="flex items-center px-8">
                  <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      className="custom-input-size custom-color-switch"
                      checked={autoClock}
                      onChange={(e) => setAutoClock(e.target.checked)}
                    />
                  </Form>
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A mr-4 mt-1">
                    {languageUI.automaticClockOutTime}
                  </span>
                  {/* <div className="flex items-center border radius-6px ">
                                        <input type="text" className="border-none w-70px h-40px bg-transparent" value={autoTimeValue} onChange={(e) => setautoTimeValue(e.target.value)} />
                                        <Form.Select className="custom-select" value={optionAutoClock} onChange={(e: any) => setOptionAutoClock(e.target.value)}>
                                            <option value={'am'}>AM</option>
                                            <option value={'pm'}>PM</option>
                                        </Form.Select>
                                    </div> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["TimePicker"]}>
                      <DesktopTimePicker
                        className="w-150px"
                        value={autoTimeValue}
                        onChange={(newValue) => {
                          setautoTimeValue(newValue);
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer
                                            components={[
                                                'MobileTimePicker',
                                            ]}
                                        >

                                            <DemoItem >
                                                <MobileTimePicker className="custom-input-clockin-out" value={autoTimeValue} onChange={(newValue) => {
                                                    setautoTimeValue(newValue);
                                                }} />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider> */}
                </div>
              </div>

              <div className="py-2">
                <div>
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A ">
                    {languageUI.payyRoll}
                  </span>
                  <div className="flex w-100 py-2">
                    <div className="d-flex align-items-center border  px-2 w-290px rounded-6px mr-8 h-42px">
                      <img src={dollarGreen} alt="" className="mr-2" />
                      <Form.Select
                        className="custom-bg-white w-100"
                        value={payrollId}
                        onChange={(e: any) => setPayrollId(e.target.value)}
                      >
                        {comboPayroll.map((item, i) => (
                          <option value={item?.value} key={`pay${i}`}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </div>

                    <div className="flex ">
                      {/* <button className="radius-50 border-12B064 bg-white mr-2 custom-radius-pre" onClick={preClick}>-</button> */}
                      {/* <div className="border radius-3 flex items-center justify-center w-100px mr-2">{count}</div> */}
                      {/* <button className="radius-50 border-12B064 bg-white custom-radius-next" onClick={nextClick}>+</button> */}
                      <input
                        type="number"
                        className="border bg-transparent w-100px custom-input-salary"
                        value={payrollValue}
                        onChange={(e) => {
                          if (Number(e.target.value) < 0) {
                            return;
                          }
                          setpayrollValue(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2 w-100">
                <div className="flex items-center ">
                  <span className="text-18px fw-600 font-urbansist text-4A4A6A mr-8">
                    Tip
                  </span>
                  <Form>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      className="custom-switch-size custom-color-switch"
                      checked={tipChecked}
                      onChange={(e) => setTipChecked(e.target.checked)}
                    />
                  </Form>
                  <span className="text-17px  font-urbansist text-4A4A6A mt-1">
                    {languageUI.nameAppearsAtChckout}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-shadow-broup-btn py-2">
            <div className="text-center text-danger text-italic">
              {messageError}
            </div>
            <div className="flex px-12 gap-2">
              <button className="radius-6px border py-1 w-50 bg-white text-17px font-urbansist text-4A4A6A">
                {languageUI.back}
              </button>
              <button
                className="radius-6px border-none py-1 w-50 bg-0FA54A text-17px font-urbansist text-white"
                onClick={SubmitAddAccount}
              >
                {languageUI.update}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCreateStaff;
