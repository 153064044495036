import { useEffect, useRef, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import HyperDatepicker from "../../components/Datepicker";

// components
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { records as data } from "./components/fakeDataTableReview";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  useLocation,
  useSearchParams
} from "react-router-dom";
import * as ReviewFromCustomerAPI from "../../api/apiOwner/GetReviewFromCustomer";
import moment from "moment";

const GetReviewForCustomer = () => {
  const location = useLocation()

  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [activeTab, setActiveTab] = useState("goodReview");

  // Set date
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());

  const [ListReviewGood, setListReviewGood] = useState<Array<any>>([]);
  const [ListReviewBad, setListReviewBad] = useState<Array<any>>([]);
  const newday = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
  const dateNow = newday.getDate();
  const monthNow =
    Number(newday.getMonth()) + 1 < 10
      ? "0" + (Number(newday.getMonth()) + 1)
      : newday.getMonth() + 1;
  const yearNow = newday.getFullYear();
  const startDateFormat = useRef<any>(yearNow + "-" + monthNow + "-01");
  const endDateFormat = useRef<any>(yearNow + "-" + monthNow + "-" + dateNow);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const onDateChangeStart = (date: Date) => {
    if (date) {
      setSelectedStartDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      startDateFormat.current = year + "-" + month + "-01";
      GetListReviewGood();
      GetListReviewBad();
    }
  };
  const onDateChangeEnd = (date: Date) => {
    if (date) {
      setSelectedEndDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      var lastDayOfMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      endDateFormat.current = year + "-" + month + "-" + lastDayOfMonth;
      GetListReviewGood();
      GetListReviewBad();
    }
  };

  const GetListReviewGood = async () => {
    if (UserIdStore) {
      const ListReviewGood = await ReviewFromCustomerAPI.GetReviewFromCustomer(
        UserIdStore,
        Number(searchParams.get('customerId')),
        startDateFormat.current,
        endDateFormat.current,
        1
      );

      if (Number(ListReviewGood.status) === 1) {
        setListReviewGood(ListReviewGood.data ? ListReviewGood.data : []);
      } else {
        setListReviewGood([]);
      }
    }
  };

  const GetListReviewBad = async () => {
    if (UserIdStore) {
      const ListReviewBad = await ReviewFromCustomerAPI.GetReviewFromCustomer(
        UserIdStore,
        Number(searchParams.get('customerId'),),
        startDateFormat.current,
        endDateFormat.current,
        2
      );
      if (Number(ListReviewBad.status) === 1) {
        setListReviewBad(ListReviewBad.data ? ListReviewBad.data : []);
      } else {
        setListReviewBad([]);
      }
    }
  };

  // Datatable config
  const columns = [
    {
      Header: "Name",
      accessor: "username",
      sort: false,
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      sort: false,
    },
    {
      Header: "Review Time",
      accessor: "timestamp",
      sort: false,
    },
    {
      Header: "Review",
      accessor: "name",
      sort: false,
    },
    {
      Header: "Message",
      accessor: "message",
      sort: false,
    },
  ];

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: data.length,
    },
  ];

  useEffect(() => {
    let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
    let menuItemElOwner = document?.querySelectorAll(".menu-owner");
    menuItemElAdmin.forEach((item, i) => {
      menuItemElAdmin[i]?.classList.add("d-none");
    });
    menuItemElOwner.forEach((item, i) => {
      menuItemElOwner[i]?.classList.remove("d-none");
    });
  }, []);

  useEffect(() => {
    if (UserIdStore) {
      GetListReviewGood();
      GetListReviewBad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, UserIdStore]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Review", path: "#" },
          { label: "Detail", path: "#", active: true },
        ]}
        title={"Review"}
      />

      <div className="py-3 px-2 rounded-3 mb-3 bg-white shadow">
        <Row className="align-items-end mb-3">
          <Col xs={12} sm={12} md={5} lg={5} xl={3}>
            <label className="form-label">Date start:</label> <br />
            <HyperDatepicker
              hideAddon={true}
              value={selectedStartDate}
              dateFormat="MM/yyyy"
              onChange={(date) => {
                onDateChangeStart(date);
              }}
              showMonthYearPicker
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="text-center form-control border-0">to</div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} xl={3}>
            <label className="form-label">Date end</label> <br />
            <HyperDatepicker
              hideAddon={true}
              value={selectedEndDate}
              dateFormat="MM/yyyy"
              onChange={(date) => {
                onDateChangeEnd(date);
              }}
              showMonthYearPicker
            />
          </Col>
        </Row>

        <Tab.Container
          defaultActiveKey={activeTab}
          onSelect={(tab: any) => setActiveTab(tab)}
        >
          <Row className="mb-3">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <Nav as="ul" variant="pills" className="navtab-bg">
                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer ms-0"
                      eventKey="goodReview"
                    >
                      Good review
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link className="cursor-pointer" eventKey="badReview">
                      Nuetral & Bad
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
          </Row>

          <Tab.Content className="pt-0">
            <Tab.Pane eventKey="goodReview">
              <div>
                <Table
                  columns={columns}
                  data={ListReviewGood}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="badReview">
              <div>
                <Table
                  columns={columns}
                  data={ListReviewBad}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default GetReviewForCustomer;
