import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import './styles.css'
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalSendEmailToGroupProps {
  show: boolean;
  handleClose: () => void
}
const ModalSendEmailToGroup: React.FC<ModalSendEmailToGroupProps> = ({ handleClose, show }) => {
  const [gmailSendAll, setGmailSendAll] = useState("");
  const languageUI = getLanguageCheckIn();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className="modal-rounded-16px"
    >
      <Modal.Header className="py-2 border-bottom" closeButton>
        <Modal.Title className="font-600">{languageUI.Sendemailtogroup}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <p className="text-16px m-0 text-414151f font-600">{languageUI.Title}:</p>
        <input
          className="w-100 text-16px text-black focus-visiable-none mt-2"
          style={{
            height: '40px',
            padding: "5px 15px",
            borderRadius: "5px",
            border: "1px solid #ced4da",
          }}
          type="number"
          placeholder={languageUI.addquantitysms}
          min="0"
        />
        <p className="text-16px m-0 text-414151f mt-2 font-600">{languageUI.Content}:</p>
        <Form.Group className="mt-1">
          <Form.Control
            className="text-16px m-0 text-414151f"
            as="textarea"
            placeholder={languageUI.EnterContentEmail}
            rows={6}
            value={gmailSendAll}
            onChange={(e: any) => {
              setGmailSendAll(e.target.value);
            }}
          />
          <p style={{ color: "#f1556c", textAlign: "center" }}>
            {/* {checkErrorMessSendAll ? errMessSendAll : ""} */}
          </p>{" "}
        </Form.Group>
        {/* <label htmlFor="messLength">Number of message characters: </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={messSendAll.length}
            readOnly
          /> */}
      </Modal.Body>
      <Modal.Footer className="justify-content-end border-top">
        <Button variant="primary">
          {languageUI.Send}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalSendEmailToGroup