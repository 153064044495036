import { useEffect, useState } from 'react';
import { ListFoodWaiting } from '../../../../api/apiOwner/ketchenApi';
import { cartBag, chervonRightPrimary, clockBlue, store } from '../../../../components/ImgExport'
import './Styles.css'
import moment from 'moment-timezone';
import { pickup, shipper } from '../../../../components/Image';
import { getLanguageUI } from '../../../../Services/languageUI';
interface BillORderCounterFoodItemsProps {
    foodName: string;
    quantityFood: number;
    note: string;
    optionFood: string;
    eatStatus: boolean;
    accompanyingFood: string;


}
const BillORderCounterFoodItems = ({ foodName, accompanyingFood, note, optionFood, quantityFood, eatStatus }: BillORderCounterFoodItemsProps) => {
    const languageUI = getLanguageUI().kitchenCounter
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                <p className='name-food-counter mb-0'>{foodName}</p>
                <div className='d-flex gap-2px'>
                    <p className='mb-0 text-x-counter'>x</p>
                    <p className='mb-0 text-number-food-counter'>{quantityFood}</p>
                </div>
            </div>
            <div className='d-flex align-items-center gap-2 mt-2'>
                {optionFood && (
                    <div className='box-option-food-counter'>{optionFood}</div>
                )}
                <div className='box-status-eat-counter gap-1'>
                    {eatStatus ? <img src={store} alt="" /> : <img src={cartBag} alt="" />}
                    {eatStatus ? languageUI.dineIn : languageUI.takeAway}
                </div>
            </div>
            {accompanyingFood && (
                <div className='d-flex align-items-center gap-1 mt-2'>
                    <p className='mb-0 text-accompanying-food '>+ {accompanyingFood}</p>
                    <p className='mb-0 text-accompanying-food text-12B064 flex-shink-0'>(Free x1)</p>
                </div>
            )}

            <p className='mb-0 mt-1 text-note-food-counter'>{note}</p>
        </div>
    )
}
export interface listSideDish {
    id: string,
    name: string,
    price: string,
    quantity: string,
    typeFood: string
}
interface BillORderCounterProps {
    tableName: string,
    timeOrder: string,
    orderId: string,
    custommer_name?: string;
    listFood: ListFoodWaiting[],
    CompleteOrder: (orderid: string) => void,
    statusOrder: number,
    arrListSide: listSideDish[]
}
const BillORderCounter = ({ tableName = '', listFood = [], orderId = '', timeOrder = '', CompleteOrder, custommer_name = '', statusOrder, arrListSide }: BillORderCounterProps) => {
    const languageUI = getLanguageUI().kitchenCounter
    const [timeWaiting, setTimeWaiting] = useState<string>('')
    const calculateTimeDifference = () => {
        const timestamp1: any = new Date(timeOrder);
        const timestamp2: any = new Date(moment().format('YYYY-MM-DD HH:mm:ss'));
        // Tính khoảng cách thời gian
        const timeDifference = Math.abs(timestamp2 - timestamp1);
        // Chuyển khoảng cách thời gian thành giờ, phút và giây
        const hours = Math.floor(timeDifference / 3600000);
        const minutes = Math.floor((timeDifference % 3600000) / 60000);
        // const seconds = Math.floor((timeDifference % 60000) / 1000);                              
        setTimeWaiting((hours < 10 ? ('0' + hours) : hours) + ":" + (minutes < 10 ? ('0' + minutes) : minutes))
    }

    const Finish = () => {
        const els = document.querySelector(".bill" + orderId);
        els?.setAttribute(
            "class",
            "box-bill-counter mb-3 d-none  bill" + orderId
        );
        CompleteOrder(orderId)

    }
    useEffect(() => {
        setTimeout(() => {
            calculateTimeDifference()
        }, 60000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeWaiting])

    useEffect(() => {

        if (timeOrder) {
            calculateTimeDifference()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])
    return (
        <div className={`box-bill-counter mb-3 bill${orderId}`}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                    {tableName && (
                        <div className="name-table-counter">
                            {tableName}
                        </div>
                    )}
                    {statusOrder === 1 ? (
                        <div>
                            <img src={pickup} alt="" className='img-pickup-bill-order' />
                        </div>
                    ) : statusOrder === 2 ? (
                        <div>
                            <img src={shipper} alt="" className='img-pickup-bill-order' />
                        </div>
                    ) : ''}

                    <div className="time-table-counter">
                        <img src={clockBlue} alt="" />
                        {timeWaiting}
                    </div>
                </div>
                <div>

                </div>
                <div className="complete-table-counter" onClick={() => Finish()}>
                    <img src={chervonRightPrimary} alt="" />
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center mt-2'>
                <p className='mb-0 text-name-order-bill '>Order-#{orderId}</p>
                {custommer_name !== '' && (
                    <p className='name-food-counter mb-0'>{custommer_name}</p>
                )}

            </div>
            {listFood.map((item, i) => (
                <BillORderCounterFoodItems key={`food${i}`} accompanyingFood={item?.side_name} eatStatus={Number(item?.form_order) === 1 ? true : false} foodName={item?.name} note={item?.note} optionFood={item?.notefood_name} quantityFood={Number(item?.quantity)} />
            ))}
            {arrListSide.length > 0 ? (<div>
                <p className='m-0 name-food-counter mt-2'>{languageUI.freeSide}</p>
                <div className='ps-2'>
                    {arrListSide.map((item, index) => (
                        <p className='m-0 font-medium'>{Number(index) + 1}: {item.name} x{item.quantity}</p>
                    ))}
                </div>
            </div>) : ''}



        </div>
    )
}
export default BillORderCounter