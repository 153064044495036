import Modal from 'react-bootstrap/Modal';
import "./styles.css"
import { useEffect, useState } from 'react';
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import { MenuInfo, addFoodToGroup } from '../../../../api/apiOwner/templateMenuApi';
import { Form } from 'react-bootstrap';
import { InfoComboFood } from '../../../../api/apiOwner/UpSale';

interface ModalAddAnotherDishProps {
  show: boolean;
  handleClose: (load?: boolean) => void;
  ListFood: InfoComboFood[];
  handleSuscces: (listFood: InfoComboFood[]) => void
}
const ModalAddFoodUpSale = ({
  show,
  handleClose,
  ListFood,
  handleSuscces
}: ModalAddAnotherDishProps) => {
  const [showListFood, setShowListFood] = useState<InfoComboFood[]>([])
  // console.log(123123123123);


  const handleChecked = (index: number, status: number) => {
    let newList = showListFood;
    newList[index].checked = status;
    setShowListFood([...newList])
    console.log(showListFood);

  }
  useEffect(() => {
    if (show) {
      setShowListFood(ListFood)
    }
  }, [show])

  return (

    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-add-food-order"
      >
        <Modal.Body>
          <Modal.Header>
            <div className='items-dish-choose text-white '>
              <h3 className='m-0 text-white'>
                Choose the food item that was previously generated.
              </h3>
            </div>
          </Modal.Header>
          <div className='box-add-another-dish'>
            <div className='container-box-add-dish-group h-400px overflow-y-scroll'>
              <Form>
                {showListFood ? (ListFood.map((item, i) => (
                  <div className='mt-2 items-dish-choose-container' key={i}>
                    <Form.Check // prettier-ignore
                      className='d-flex gap-2 align-items-center'
                      type='checkbox'
                      id={`default-${i}`}
                      label={item?.name}
                      checked={Number(item.checked) === 1}
                      onClick={() => handleChecked(i, Number(item.checked) === 1 ? 0 : 1)}
                    />
                  </div>
                ))) : ''}



              </Form>
            </div>
          </div>
          <Modal.Footer>
            <div className='w-100 d-flex justify-content-end gap-3 mt-3'>
              <div className='btn-footer-add-dish-group ' onClick={() => handleClose()}>
                Close
              </div>
              <div className='btn-footer-add-dish-group bg-primary' onClick={() => handleSuscces(showListFood)} >
                save
              </div>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ModalAddFoodUpSale;