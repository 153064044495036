import { calender } from "../../../../../../components/ImgExport";
import Select from "react-select";
import { AddIcon } from "../../../../../../components/Image";
import { useEffect, useState } from "react";
import './Styles.css'
import EmployeeWorkingToday from "./EmployeeWorkingToday";
import EmployeeActive from "./EmployeeActive";
import { useNavigate } from "react-router-dom";
import EmployeeInactive from "./EmployeeInactive";
import moment from "moment-timezone";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import * as StaffApi from "../../../../../../api/apiOwner/StaffApi";
import ModalLoading from "../../../../../../components/ModalLoading";
import React from "react";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface EmployeeProp {
    tabOptionEmployee: number
}
const Employee: React.FC<EmployeeProp> = ({ tabOptionEmployee = 0 }) => {
    const languageUI = getLanguageUI().staffPage
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [chooseOptionEmployee, setChooseOptionEmployee] = useState<number>(1)
    const [listDaySearch, setListDaySearch] = useState<{ value: string, label: string }[]>([]);
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const [listEmployeeWorking, setListEmployeeWorking] = useState<StaffApi.StaffWorking[]>([]);
    const [listEmployeeActive, setListEmployeeActive] = useState<StaffApi.StaffActive[]>([]);
    const [listEmployeeInActive, setListEmployeeInActive] = useState<StaffApi.StaffActive[]>([]);
    const [daySelected, setDaySelected] = useState<string>()
    const navigate = useNavigate()
    const get30Day = () => {

        var today = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
        var listDay: { label: string, value: string }[] = [];
        for (let i = 0; i <= 30; i++) {
            var priorDate = new Date(new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)')).setDate(today.getDate() - i));
            if (i === 0) {
                let item = {
                    label: languageUI.today,
                    value: moment(priorDate).format('YYYY-MM-DD')
                }
                listDay.push(item)
            } else if (i === 1) {
                let item = {
                    label: languageUI.yesterday,
                    value: moment(priorDate).format('YYYY-MM-DD')
                }
                listDay.push(item)
            } else {
                let item = {
                    label: moment(priorDate).format('DD-MM-YYYY'),
                    value: moment(priorDate).format('YYYY-MM-DD')
                }
                listDay.push(item)
            }
        }
        setListDaySearch([...listDay])
    }

    const gListEmployeeWorking = async (daySearch: string = '') => {
        if (UserIdStore) {
            setShowLoading(true)
            const req = await StaffApi.gListStaffWorkingByDate(UserIdStore, daySearch);
            if (Number(req.status) === 1) {
                setListEmployeeWorking(req?.data ?? []);
                setShowLoading(false)
            } else {
                setShowLoading(false)
            }
        }
    }
    const gListEmployeeActive = async () => {
        if (UserIdStore) {
            const req = await StaffApi.gListStaffActive(
                UserIdStore, 1
            );
            if (Number(req.status) === 1) {
                setListEmployeeActive(req?.data ?? []);
            }

            //lấy danh sách chưa active
            const reqInActive = await StaffApi.gListStaffActive(
                UserIdStore, 0
            );
            if (Number(reqInActive.status) === 1) {
                setListEmployeeInActive(reqInActive?.data ?? []);

            }
        }
    }
    const HandleActiveSuccess = () => {
        gListEmployeeActive();

    }
    //thay đổi search date
    const handleOnchageDate = (e: any) => {
        if (e?.value === undefined) {
            setDaySelected("");
            gListEmployeeWorking('')
            return;
        }
        setDaySelected(e?.value);
        gListEmployeeWorking(e?.value);
    }
    useEffect(() => {
        if (tabOptionEmployee) {
            setChooseOptionEmployee(tabOptionEmployee)
        }
        get30Day()
        gListEmployeeWorking()
        gListEmployeeActive()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <div className="mt-12 px-12">
                <div className="bg-white radius-18px w-100 ">
                    <div className="d-flex w-100 justify-content-between align-items-center">
                        <div className="h-70px custom-radius-table  bg-white px-4 d-flex align-items-center border-bottom container-fluid justify-content-between">
                            <div className="d-flex align-items-center gap-3">
                                <div className={`d-flex align-items-center  h-100 gap-2 ${chooseOptionEmployee === 1 ? "" : "d-none"}`}>
                                    <p className="text-5 text-212134 mb-0 d-flex align-items-center mr-6">{languageUI.employeeWorkingToday}</p>
                                    <div className="d-flex align-items-center border  px-2  rounded-8px h-48px">
                                        <img src={calender} alt="" />
                                        <Select
                                            className="react-select react-select-container w-180px  border-select-none"
                                            classNamePrefix="react-select"
                                            defaultValue={[""]}
                                            placeholder={languageUI.today}
                                            isClearable={true}
                                            options={listDaySearch}
                                            onChange={(e: any) => handleOnchageDate(e)}
                                        />
                                    </div>
                                </div>

                                <button className={` h-48px px-4 radius-2  text-12px fw-600 font-urbansist ${chooseOptionEmployee === 1 ? 'bg-0FA54A border-none text-white' : 'border-DEE2E6 text-4A4A6A bg-white'}`} onClick={() => setChooseOptionEmployee(1)}>
                                    {languageUI.employeeWorking}
                                </button>
                                <button className={` h-48px px-4 radius-2  text-12px fw-600 font-urbansist ${chooseOptionEmployee === 2 ? 'bg-0FA54A border-none text-white' : 'border-DEE2E6 text-4A4A6A bg-white'}`} onClick={() => setChooseOptionEmployee(2)}>
                                    {languageUI.activeEmployee}
                                </button>
                                <button className={` h-48px px-4 radius-2  text-12px fw-600 font-urbansist ${chooseOptionEmployee === 3 ? 'bg-0FA54A border-none text-white' : 'border-DEE2E6 text-4A4A6A bg-white'}`} onClick={() => setChooseOptionEmployee(3)}>
                                    {languageUI.inactiveEmployee}
                                </button>
                            </div>
                            <button className="flex items-center bg-white border-12B064 h-48px px-4 radius-2 text-12B064 text-12px fw-600 font-urbansist"
                                onClick={() => navigate('/web-owner/creatstaffs', { state: { tabSelect: 0, tabOptionEmployee: 2 } })}
                            >
                                <AddIcon className="mr-2 text-12B064" /> {languageUI.createNewStaff}
                            </button>
                        </div>
                    </div>
                    {
                        Number(chooseOptionEmployee) === 1 ? (<EmployeeWorkingToday listEmployeeWorking={listEmployeeWorking} ReloadWorking={() => gListEmployeeWorking(daySelected)} />) : Number(chooseOptionEmployee) === 2 ? (<EmployeeActive listEmployeeActive={listEmployeeActive} HandleActiveSuccess={HandleActiveSuccess} />) : (<EmployeeInactive listEmployeeInActive={listEmployeeInActive} HandleActiveSuccess={HandleActiveSuccess} />)
                    }
                </div>



            </div>
        </>
    )
}
export default Employee