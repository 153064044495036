import React from 'react';

const Snake: React.FC<{ snakeDots: { x: number; y: number }[] }> = ({ snakeDots }) => {
  return (
    <>
      {snakeDots.map((dot, i) => (
        <div key={i} className="snake-dot" style={{ left: `${dot.x}px`, top: `${dot.y}px` }}></div>
      ))}
    </>
  );
};

export default Snake;