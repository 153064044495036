import * as Request from "../Request";
export interface Devices {
    id: string
    title: string,
    description: string,
    price: string,
    imgurl: string,
    quantity: number,
    timestamp: string,
    checked?: boolean
}
export interface ListDevices {
    id: string,
    device_id: string,
    quantity: string,
    buydevice_id: string,
    title: string,
    description: string,
    price: string,
    imgurl: string
}
export interface BuyDevice {
    id: string,
    owner_id: string,
    id_package: string,
    price: string,
    shippingAddress: string,
    suitShipping: string,
    status: string,
    timestamp: string,
    name: string,
    contact_person: string,
    sellPhone: string,
    package: string,
    cms: string,
    list_device: ListDevices[]
}
export interface DetailBuyDevice {
    id: string,
    owner_id: string,
    id_package: string,
    price: string,
    shippingAddress: string,
    suitShipping: string,
    status: string,
    timestamp: string,
    name: string,
    contact_person: string,
    sellPhone: string,
    package: string,
    cms: string,
    address: string,
    email: string,
    suit_of_unit: string,
    store_city: string,
    store_state: string,
    zipcode: string,
    timeZoneId: string,
    detail_address: string,
    restaurantPhone: string,
    time_name: string
}
export interface Result<T> {
    status: number,
    message: string,
    data: T,
    total?: number,
    totalPage?: number
}

export const gComboDevices = async () => {
    return await Request.fetchAPI(`admin/gComboDevicePos.php`, "POST", {})
        .then((res: Result<Devices[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};

export const gListDeviceBought = async () => {
    return await Request.fetchAPI(`admin/gListDeviceBought.php`, "POST", {})
        .then((res: Result<BuyDevice[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};
export const gListDeviceBoughtHistory = async (date_start: string = '', date_end: string = '', page: number = 1, limit: number = 0) => {
    return await Request.fetchAPI(`admin/gListDeviceBoughtHistory.php`, "POST", { date_end, date_start, page, limit })
        .then((res: Result<BuyDevice[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
                total: 1,
                totalPage: 1
            };
        });
};
export const upStatusDeviceBought = async (listOrderChoosed: string[]) => {
    return await Request.fetchAPI(`admin/upStatusDeviceBought.php`, "POST", { listOrderChoosed: JSON.stringify(listOrderChoosed) })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};
export const gInfoBuyDevices = async (id: string) => {
    return await Request.fetchAPI(`admin/gInfoBuyDevices.php`, "POST", { id })
        .then((res: Result<DetailBuyDevice>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};