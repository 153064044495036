import { useEffect, useRef, useState } from 'react'
import useEventListener from '@use-it/event-listener'
import { Circle, Rectangle } from './types'
import * as constants from './constants'
import EndDialog from './EndDialog'
import cloud from '../../assets/images/clouds-game.png'
import bird from '../../assets/images/bird-game.png'
import ground from '../../assets/images/ground-game.png'
import './styles.css'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { flappyBirdGameLogoText, gameOverFlappyBird } from '../ImgExport'
import { useNavigate } from 'react-router-dom'
import { Score } from '@mui/icons-material'
import { gConfigGame, gGameRoundCustomer, updateScoreGame } from '../../api/apiCheckin/gameApi'
import { getLanguageCheckIn } from '../../Services/languageCheckin'




// ground
let groundX = 0

// bird
let birdX = 60
let birdY = 300
let birdYSpeed = 0

// pipes
let pipeGapBottomY = constants.PIPE_HEIGHT
let pipeX = constants.CANVAS_WIDTH

// score
let score: number = 0
let bestScore: number = parseInt(localStorage.getItem('bestScore') || '0')

// check collision between circle and rectangle
const checkCollision = (circle: Circle, rect: Rectangle) => {

  if ((circle.x + circle.radius) >= rect.x && (circle.x - circle.radius) <= (rect.x + rect.width)) {
    if ((circle.y + circle.radius) >= rect.y && (circle.y - circle.radius) <= (rect.y + rect.height)) {
      // TODO: IMPROVE COLLISION CHECK
      return true
    }
  }
  return false
}

// check if bird has touched a pipe
const touchedPipe = () => {
  const birdHitbox: Circle = {
    x: birdX + (constants.BIRD_WIDTH / 2),
    y: birdY + (constants.BIRD_HEIGHT / 2) + 5,
    radius: 20
  }

  const upperPipe: Rectangle = {
    x: pipeX,
    y: 0,
    width: constants.PIPE_WIDTH,
    height: pipeGapBottomY
  }

  const lowerPipe: Rectangle = {
    x: pipeX,
    y: pipeGapBottomY + constants.PIPE_GAP,
    width: constants.PIPE_WIDTH,
    height: (constants.CANVAS_HEIGHT - constants.HEIGHT_GROUND) - (pipeGapBottomY + constants.PIPE_GAP)
  }

  return checkCollision(birdHitbox, upperPipe) || checkCollision(birdHitbox, lowerPipe)
}

// check if bird has touched the ground
const fallOut = () => (birdY + constants.BIRD_HEIGHT) > (constants.CANVAS_HEIGHT - constants.HEIGHT_GROUND)
const getOut = () => birdY < 0

// stop game 
const reset = () => {
  hasStarted = false
  hasFinished = true
}

let hasStarted = false
let hasFinished = false
let canGetScore = true

const languageUI = getLanguageCheckIn();

function GameFlaapyBird() {
  const canvas = useRef<HTMLCanvasElement>(null)
  const navigate = useNavigate()
  const [showMenu, setShowMenu] = useState<boolean>(true)
  const [showEndGame, setShowEndGame] = useState<boolean>(false)
  const [checkPoints, setCheckPoints] = useState(false)
  const [playFlappyBird, setPlayFlappyBird] = useState<number>(0)
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = localStorage.getItem("ownerID");

  const gConfig = async () => {
    if (ownerID) {
      const req = await gConfigGame(ownerID)
      if (Number(req?.status) === 1 && Number(req?.data?.is_show_redemption_game) === 1) {
        setCheckPoints(true)
      } else {
        setCheckPoints(false)
      }
    } else {
      setCheckPoints(false)
    }

  }
  // bird jump
  const jump = () => {
    if (playFlappyBird > 0) {
      setPlayFlappyBird(Number(playFlappyBird - 1))
      setShowMenu(false)
      if (hasFinished) {
        return
      }
      if (!hasStarted) {
        hasStarted = true;
      }
      birdYSpeed = constants.JUMP_SPEED
    }

  }

  // enable space button
  const handler = (key: any) => {
    if (hasFinished) {
      return
    }
    if (key.code === 'Space') {
      if (!hasStarted) {
        hasStarted = true;
      }
      jump()
    }
  }
  useEventListener('keypress', handler)

  const draw = (context: CanvasRenderingContext2D) => {

    // draw background
    context.fillStyle = "#abfcff"
    context.fillRect(0, 0, constants.CANVAS_WIDTH, constants.CANVAS_HEIGHT)

    // draw clouds
    const CLOUDS = new Image()
    CLOUDS.src = cloud
    context.drawImage(
      CLOUDS, 0, 0, 480, 400
    )

    // // draw ground
    const GROUND = new Image()
    GROUND.src = ground
    context.drawImage(
      GROUND, groundX, constants.GROUND_Y, constants.GROUND_WIDTH, constants.GROUND_HEIGHT
    )
    context.drawImage(
      GROUND,
      groundX + constants.CANVAS_WIDTH,
      constants.GROUND_Y,
      constants.GROUND_WIDTH,
      constants.GROUND_HEIGHT
    )
    // context.drawImage(
    //   constants.GROUND,
    //   groundX,
    //   constants.GROUND_Y,
    //   constants.GROUND_WIDTH,
    //   constants.GROUND_HEIGHT
    // )
    // context.drawImage(
    //   constants.GROUND,
    //   groundX + constants.CANVAS_WIDTH,
    //   constants.GROUND_Y,
    //   constants.GROUND_WIDTH,
    //   constants.GROUND_HEIGHT
    // )

    // draw bird
    const BIRD = new Image()
    BIRD.src = bird
    context.drawImage(
      BIRD, birdX, birdY, 50, 50
    )
    // context.drawImage(
    //   constants.BIRD,
    //   birdX,
    //   birdY,
    //   constants.BIRD_WIDTH,
    //   constants.BIRD_HEIGHT)

    // draw pipes
    context.fillStyle = "#e69119"
    context.fillRect(pipeX, 0, constants.PIPE_WIDTH, pipeGapBottomY)
    context.fillRect(
      pipeX,
      pipeGapBottomY + constants.PIPE_GAP,
      constants.PIPE_WIDTH,
      (constants.CANVAS_HEIGHT - constants.HEIGHT_GROUND)
    );
  }
  const updateScore = async () => {
    if (custommerId) {
      const req = await updateScoreGame(custommerId, 2, score);//2 là game flappybird
      if (Number(req.status) === 1) {
        getGameRoundCustomer()
      }
    }
  }

  const getGameRoundCustomer = async () => {
    if (custommerId) {
      const req = await gGameRoundCustomer(custommerId)
      if (Number(req.status) === 1) {
        setPlayFlappyBird(Number(req.data?.plays_flappybird) ?? 0)

      }
    }
  }

  useEffect(() => {

    if (showEndGame) {
      updateScore()
    }
  }, [showEndGame])
  useEffect(() => {
    gConfig()
    getGameRoundCustomer()
  }, [])
  useEffect(() => {

    if (canvas.current) {
      const context = canvas.current.getContext("2d")
      if (context) {
        setInterval(() => {

          // dying
          if (touchedPipe() || fallOut() || getOut()) {
            if (score > bestScore) {
              bestScore = score
              localStorage.setItem('bestScore', score.toString())
            }
            setShowEndGame(true)
            reset()


          }

          // check if we should give another score
          if (canGetScore && (birdX > pipeX + constants.PIPE_WIDTH)) {
            canGetScore = false
            score++
          }

          draw(context)

          if (!hasStarted) {
            return
          }

          // reset pipes
          if (pipeX < -constants.PIPE_WIDTH) {
            pipeX = constants.CANVAS_WIDTH;
            pipeGapBottomY = constants.PIPE_GAP * Math.random()
            canGetScore = true
          }

          // reset ground
          if (groundX <= -constants.CANVAS_WIDTH) {
            groundX = 0
          }


          // draw scores
          var gradient = context.createLinearGradient(0, 0, 30, 0);
          gradient.addColorStop(0, "magenta");
          gradient.addColorStop(0.5, "blue");
          gradient.addColorStop(1.0, "red");
          context.fillStyle = gradient
          context.font = '60px Roboto'
          context.fillText(score.toString(), constants.CANVAS_WIDTH / 2 - 15, 100)

          // movements
          pipeX -= constants.SPEED
          groundX -= constants.SPEED
          birdY += birdYSpeed * (constants.INTERVAL / 1000)
          birdYSpeed -= constants.FALL_SPEED * (constants.INTERVAL / 1000)

        }, constants.INTERVAL)
      }
    }

  }, [])

  const handleRestarGame = () => {
    groundX = 0
    birdX = 60
    birdY = 300
    birdYSpeed = 0
    pipeGapBottomY = constants.PIPE_HEIGHT
    pipeX = constants.CANVAS_WIDTH
    score = 0
    setShowEndGame(false)
    hasStarted = false
    hasFinished = false
    canGetScore = true

  }
  return (
    <div
      onClick={jump}
      onKeyPress={jump}
      className='w-100 d-flex justify-content-center align-items-center flex-column'
    >
      <div className={`box-menu-flappy-brid ${showMenu ? '' : 'd-none'}`}>
        <div className='d-flex flex-column align-items-center'>
          <img src={flappyBirdGameLogoText} alt="" className='w-200px' />
          <div className='d-flex gap-4 w-fit mt-3'>

            {Number(playFlappyBird) > 0 && (
              <div className='btn-back-flappybird' onClick={() => { jump(); setShowMenu(false) }} onKeyDown={() => { jump(); setShowMenu(false) }}>
                {languageUI.Start}
              </div>
            )}
            <div className='btn-back-flappybird' onClick={() => navigate(-1)}>
              {languageUI.Exit}
            </div>
          </div>
        </div>
      </div>
      <div className={`box-end-game ${showEndGame ? '' : 'd-none'}`}>
        <div className='end-game-container shadow-btn-default '>
          <img src={gameOverFlappyBird} alt="" className='w-200px' />
          <div className='d-flex w-100 px-4'>
            <div className='w-50'>
              <div className='text-score-game'>{languageUI.Score}: {score}</div>
            </div>
            <div className='w-50'>
              <div className='text-score-game'>{languageUI.BestScore}: {bestScore}</div>
            </div>
          </div>
          <div className='d-flex gap-4 w-fit mt-3'>
            <div className='btn-back-flappybird' onClick={() => navigate(-1)}>
              {languageUI.Exit}
            </div>
            {Number(playFlappyBird) > 0 && (
              <div className='btn-back-flappybird' onClick={handleRestarGame}>
                {languageUI.Again}
              </div>
            )}
          </div>
        </div>
      </div>

      <canvas
        ref={canvas}
        width={constants.CANVAS_WIDTH}
        height={constants.CANVAS_HEIGHT}
      />
      {/* <EndDialog showDialog={showModal} score={score} bestScore={bestScore}></EndDialog> */}
    </div>
  )
}

export default GameFlaapyBird