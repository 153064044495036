import React from "react"
import { Modal } from "react-bootstrap"
import CloseIcon from '@mui/icons-material/Close';
import './styles.css'
import { listSide } from "../../../../api/apiCheckin/addCartApi";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
interface ModalShowSideDishProps {
  ListFreeSidesChoosed: listSide[];
  DeleteSide: (id: string, quantitySide: number) => void;
  show: boolean;
  dismis: () => void;
}
const ModalShowSideDish = ({ dismis, show, DeleteSide, ListFreeSidesChoosed = [] }: ModalShowSideDishProps) => {
  const languageUI = getLanguageCheckIn();
  return (
    <Modal
      show={show}
      onHide={dismis}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-show-side-dish"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {languageUI.ListSideDish}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="max-h-40vh overflow-y-scroll d-flex flex-column gap-2">
          {ListFreeSidesChoosed.map((item, i) => (
            <div className="h-40px w-full rounded-3 bg-primary border-primary ps-3 d-flex justify-content-between align-items-center relative" key={i}>
              <h4 className="m-0 text-white">{item?.name} (x{item?.quantity})</h4>
              <div className="px-3 h-full d-flex justify-content-center align-items-center" onClick={() => DeleteSide(item?.id, Number(item?.quantity))}>
                <CloseIcon className="text-white" />
              </div>
            </div>
          ))}

        </div>

      </Modal.Body>
    </Modal>
  )
}
export default ModalShowSideDish