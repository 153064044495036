import { Modal } from "react-bootstrap";
import "./Styles.css"
import { leaf1, leaf2 } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import { SketchPicker } from 'react-color'
import { getLanguageUI } from "../../../../Services/languageUI";



interface ModalCreateCategoryProps {
  show: boolean;
  handleClose: () => void;
  titleModel: string;
  colorHex?: string;
  handleSuccess?: (status?: boolean) => void;
  FoodGroupNameAdd: string;
  setFoodGroupNameAdd: (e: string) => void;
  FoodGroupNumerical: number;
  FoodGroupNumericalMax: number;
  setFoodGroupNumerical: (e: number) => void;
  saveNewFoodGroup: (color: string) => void;
}

const ModalCreateCategory = ({
  FoodGroupNameAdd,
  FoodGroupNumerical,
  FoodGroupNumericalMax,
  show,
  handleClose,
  setFoodGroupNameAdd,
  setFoodGroupNumerical,
  handleSuccess,
  saveNewFoodGroup,
  titleModel = '',
  colorHex = '',

}: ModalCreateCategoryProps) => {
  const languageUI = getLanguageUI().menuTemplate
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [colorPickerRGBa, setColorPickerRGBa] = useState('#f17013')
  const [changeColorHex, setChangeColorHex] = useState<string>("#f17013")

  const handelClickColor = () => {
    setDisplayColorPicker(!displayColorPicker)
  }
  const handleCloseColor = () => {
    setDisplayColorPicker(false)
  }
  const handleChangeColor = (color: any) => {

    setColorPickerRGBa(color.hex)
    setChangeColorHex(color.hex)

  }
  useEffect(() => {
    if (show) {
      if (colorHex !== '') {
        setChangeColorHex(colorHex)
        setColorPickerRGBa(colorHex)
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className="modal-delete-customer modal-clock-in-out">
        <Modal.Dialog className="w-100">
          <Modal.Body>
            <div className="position-relative w-100">
              <div className="position-relative py-3 px-8 z-10">
                <p className="text-delete-modal-customer mb-0 text-center">{titleModel}</p>
                <div className="mt-4">
                  <p className="text-add-table-name mb-1">{languageUI.categoriesName}</p>
                  <div className="input-add-table bg-white">
                    <input type="text" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder="Name" value={FoodGroupNameAdd} onChange={(e) => {
                      setFoodGroupNameAdd(e.target.value)
                    }} />
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="w-48">
                      <p className="text-add-table-name mb-1">{languageUI.orderNo}</p>
                      <div className="input-add-table bg-white">
                        <input type="number" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " min={1} max={FoodGroupNumericalMax} placeholder={languageUI.noNumber} value={FoodGroupNumerical} onChange={(e) => {
                          if (e.target.value && Number(e.target.value) > FoodGroupNumericalMax) {
                            return
                          }
                          setFoodGroupNumerical(Number(e.target.value))
                        }} />
                      </div>
                    </div>
                    <div className="w-48">
                      <p className="text-add-table-name mb-1">{languageUI.pickACategoryColor}</p>
                      <div className="input-add-table d-flex gap-2 align-items-center p-2 ">
                        <div className="" onClick={handelClickColor}>
                          <div className="btn-change-color-menu border color-style"
                            style={{ backgroundColor: colorPickerRGBa }}
                          >
                          </div>
                        </div>

                        {displayColorPicker ? <div className="popover-style">
                          <div className="cover-style" onClick={handleCloseColor} />
                          <SketchPicker color={colorPickerRGBa} onChange={handleChangeColor} />
                        </div> : null}
                        <p className="mb-0 text-change-color-menu">{changeColorHex}</p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>{languageUI.cancel}</div>
                    <div className=" btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={() => saveNewFoodGroup(changeColorHex)}>{languageUI.save}</div>
                  </div>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1-otp-owner" />
              <img src={leaf2} alt="" className="img-leaf2-otp-owner" />
            </div>
          </Modal.Body>
        </Modal.Dialog>

      </Modal>
    </>

  );
};

export default ModalCreateCategory;
