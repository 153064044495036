import "./Styles.css"
import React from "react";
import { FormatDolla } from "../../../../utils/format";

interface ContentTableOfflineModeProps {
    numerical: string;
    idOrder: string;
    priceBill: string;
    tip: number;
    staff: string;
    timePrint: string;
    customer: string;
    UserIdStore?: number
    setShowLoading?: (val: boolean) => void
}

const ContentTableOfflineMode = ({
    customer,
    idOrder,
    numerical,
    priceBill,
    staff,
    timePrint,
    tip = 0,
}: ContentTableOfflineModeProps) => {
    return (
        <>
            <tr className="w-100 d-table">
                <td className="text-content-table-customer text-32324D ps-2 pt-3 w-10">{numerical}</td>
                <td className="text-content-table-customer text-269AFF pt-3 w-16">{'#' + idOrder}</td>
                <td className="pt-3 w-16"><p className="mb-0 text-12B064 text-content-table-customer-3 float-left ">{priceBill}</p><p className="text-dollar-table-customer text-71D0A2">$</p></td>
                <td className="pt-3 w-16"><p className="mb-0 text-4A4A6A text-content-table-customer-3 float-left ">{tip > 0 ? FormatDolla(tip) : "-"}</p>{tip > 0 && (<p className="text-dollar-table-customer text-8E8EA9">$</p>)}</td>
                <td className="text-dollar-table-customer text-32324D pt-3 w-16">{staff}</td>
                <td className="text-dollar-table-customer text-32324D pt-3 w-16">{timePrint}</td>
                <td className="text-dollar-table-customer text-32324D pt-3 ps-1 w-18">{customer}</td>
            </tr>
        </>
    );
};

export default ContentTableOfflineMode;
