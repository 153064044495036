/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from "react-bootstrap";
import "./Styles.css"
import { leaf1, leaf2, minusGreen, plusGreen } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { addTableMulti, addTableSingle, gDefaultTableNameAdd } from "../../../../api/apiOwner/tableApi";
import { getLanguageUI } from "../../../../Services/languageUI";



interface ModalAddFloorProps {
    show: boolean;
    floor_id: string;
    handleSuccess: () => void;
    handleClose: () => void;
    setShowLoading: (status: boolean) => void;
}

const ModalAddTable = ({
    show,
    handleSuccess,
    handleClose,
    setShowLoading,
    floor_id
}: ModalAddFloorProps) => {
    const languageUI = getLanguageUI().setUpTable
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [addNumberTable, setAddNumberTable] = useState(false);
    const [NameTable, setNamTable] = useState('')
    const [seatQuantitySingle, setSeatQuantitySingle] = useState<number | string>(2)
    const [seatQuantityMulti, setSeatQuantityMulti] = useState<number | string>(2)
    const [tableNumberMulti, setTableNumberMulti] = useState<number>(0)
    const [messageError, setMessageError] = useState('')

    const gTableNameDefault = async () => {
        if (UserIdStore) {
            const reqName = await gDefaultTableNameAdd(UserIdStore, floor_id);
            if (Number(reqName?.status) === 1) {
                setNamTable(reqName?.tableName ?? '')
            }
        }
    }

    const addTableSinglebyOwner = async () => {
        if (UserIdStore) {
            // if (Number(seatQuantitySingle) <= 0) {
            //     setMessageError("Please enter seat quantity!");
            // } else if (Number(seatQuantitySingle) % 2 != 0) {
            //     setMessageError("Invalid seat number. Enter even number only: 2-4-6-8-10…!");
            // } else {
            setShowLoading(true)
            const reqAdd = await addTableSingle(UserIdStore, floor_id, Number(seatQuantitySingle));
            if (Number(reqAdd?.status) === 1) {
                handleClose()
                handleSuccess()
            } else {
                setShowLoading(false)
                setMessageError(reqAdd?.message ?? "")
            }
            // }

        }
    }
    const addTableMultibyOwner = async () => {
        if (UserIdStore) {
            if (tableNumberMulti <= 0) {
                setMessageError(languageUI.pleaseEnterTable);
            } else
                // if (Number(seatQuantityMulti) <= 0) {
                //     setMessageError("Please enter seat quantity!");
                // } else if (Number(seatQuantityMulti) % 2 != 0) {
                //     setMessageError("Invalid seat number. Enter even number only: 2-4-6-8-10…!");
                // } else {
                setShowLoading(true)
            const reqAdd = await addTableMulti(UserIdStore, floor_id, tableNumberMulti, Number(seatQuantityMulti));
            if (Number(reqAdd?.status) === 1) {
                handleClose()
                gTableNameDefault()
                handleSuccess()
            } else {
                setShowLoading(false)
                setMessageError(reqAdd?.message ?? "")
            }
            // }

        }
    }
    useEffect(() => {
        setMessageError('')

    }, [addNumberTable])
    useEffect(() => {
        if (show) {
            setTableNumberMulti(0)
            setMessageError('')
            gTableNameDefault()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])
    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer">
                <Modal.Dialog>
                    <Modal.Body>
                        <div className="position-relative px-3 w-466px">
                            <div className="w-100">
                                <p className="text-delete-modal-customer px-6 mb-0 text-center">{languageUI.howManyTablesAdd}</p>
                                <div className="box-btn-option-add-table mt-3">
                                    <div className={`${addNumberTable ? "btn-option-add-table-2" : "btn-option-add-table"}`} onClick={() => setAddNumberTable(false)}>
                                        {languageUI.single}
                                    </div>
                                    <div className={`${addNumberTable ? "btn-option-add-table" : "btn-option-add-table-2"}`} onClick={() => setAddNumberTable(true)}>
                                        {languageUI.multi}
                                    </div>
                                </div>
                                <p className="text-center text-danger text-italic mb-1">{messageError}</p>
                                <div className={`mt-2 ${addNumberTable ? "d-none" : ""}`}>
                                    {/* <p className="text-add-table-name mt-3 mb-1">Seat quantity</p>
                                    <div className="input-add-table">
                                        <input type="number" className=" w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder="Enter even number only: 2-4-6-8-10…" value={seatQuantitySingle} onChange={(e) => {
                                            if (e.target.value && Number(e.target.value) <= 0) {
                                                return;
                                            }
                                            setSeatQuantitySingle(e.target.value)
                                        }} />
                                    </div> */}
                                    <div className="d-flex justify-content-between mt-3">
                                        <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>
                                            {languageUI.cancel}
                                        </div>
                                        <div className="btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={addTableSinglebyOwner}>
                                            {languageUI.save}
                                        </div>
                                    </div>
                                </div>
                                <div className={`mt-2 w-100  ${addNumberTable ? "" : "d-none"} `}>
                                    <div className="d-flex flex-column w-100 align-items-center justify-content-center">
                                        <div className="d-flex gap-2 align-items-center">
                                            <div className="btn-remove-plus-number" onClick={() => {
                                                if (tableNumberMulti > 0) {
                                                    setTableNumberMulti(tableNumberMulti - 1)
                                                }
                                            }}>
                                                <img src={minusGreen} alt="" />
                                            </div>
                                            <div className="input-number-table-multi">
                                                <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={tableNumberMulti} onChange={(e) => {
                                                    if (e.target.value && Number(e.target.value) <= 0) {
                                                        return;
                                                    }
                                                    setTableNumberMulti(Number(e.target.value))
                                                }} />
                                            </div>
                                            <div className="btn-remove-plus-number" onClick={() => {
                                                setTableNumberMulti(tableNumberMulti + 1)
                                            }}>
                                                <img src={plusGreen} alt="" />
                                            </div>
                                        </div>
                                        {/* <div className="mt-2 w-100">
                                            <p className="text-add-table-name mt-3 mb-1">Seat per table</p>
                                            <div className="input-add-table">
                                                <input type="number" className=" w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder="Enter even number only: 2-4-6-8-10…" value={seatQuantityMulti} onChange={(e) => {
                                                    if (e.target.value && Number(e.target.value) <= 0) {
                                                        return;
                                                    }
                                                    setSeatQuantityMulti(e.target.value)
                                                }} />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>
                                            {languageUI.cancel}
                                        </div>
                                        <div className="btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={addTableMultibyOwner}>
                                            {languageUI.save}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img src={leaf1} alt="" className="img-leaf1-setup-table" />
                            <img src={leaf2} alt="" className="img-leaf2" />
                        </div>
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalAddTable;
