import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import React from "react";
import { Rating } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ModalChooseStaffRating from "./components/ModalChooseStaffRating/ModalChooseStaffRating";
import {
  StaffActive,
  addReviewStaff,
  checkReviewStaffExist,
  gListStaffActive,
} from "../../api/apiOwner/StaffApi";
import { useNavigate, useParams } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";

const RatingStaff = () => {
  const params = useParams();
  const [valueRating, setValueRating] = React.useState<number | null>(5);
  const [contentFeedback, setContentFeedback] = React.useState<string>("");
  const [showModalShooseStaff, setShowModalShooseStaff] = useState(false);
  const [showModalLoading, setShowModalLoading] = useState(false);
  const [listEmployeeActive, setListEmployeeActive] = useState<StaffActive[]>(
    []
  );
  const [nameStaffActive, setNameStaffActive] = useState("");
  const [idStaffActive, setIdStaffActive] = useState<number>(0);
  const [idStaffActiveted, setIdStaffActiveted] = useState<number>(0);
  const [messErr, setMessErr] = useState("");
  const [ownerId, setOwnerId] = useState<number>(0);

  const navigate = useNavigate();
  const checkTokenReviewStaffExist = async () => {
    const req = await checkReviewStaffExist(params?.review_token ?? "");
    if (Number(req.status) === 1) {
      setOwnerId(Number(req?.data));
    } else {
      navigate("/webcheckin/tokenNotFound");
    }
  };

  const dismisModalShooseStaff = () => {
    setShowModalShooseStaff(false);
  };
  const gListEmployeeActive = async () => {
    if (ownerId) {
      const req = await gListStaffActive(ownerId, 1);
      if (Number(req.status) === 1) {
        setListEmployeeActive(req?.data ?? []);
      }
    }
  };
  const handleSubmitStaff = (idStaff: number, nameStaff: string) => {
    setNameStaffActive(nameStaff);
    setIdStaffActive(idStaff);
    setIdStaffActiveted(idStaff);
    setShowModalShooseStaff(false);
  };
  useEffect(() => {
    if (showModalShooseStaff) {
      gListEmployeeActive();
    }
  }, [showModalShooseStaff]);

  const handleReviewStaff = async () => {
    setShowModalLoading(true);

    if (contentFeedback === "") {
      setMessErr("Please enter feedback");
      setShowModalLoading(false);
      return;
    }
    if (valueRating === null || Number(valueRating) === 0) {
      setMessErr("Please rate the staff");
      setShowModalLoading(false);
      return;
    }
    if (idStaffActive === null) {
      setMessErr("Please select staff");
      setShowModalLoading(false);
      return;
    }

    if (ownerId) {
      const res = await addReviewStaff(
        params?.review_token ?? "",
        ownerId,
        contentFeedback,
        Number(valueRating),
        Number(idStaffActive)
      );
      if (Number(res.status) === 1) {
        setShowModalLoading(false);
        setMessErr("");
        navigate("/webcheckin/thankyouForReview");
      }
    }
  };
  useEffect(() => {
    checkTokenReviewStaffExist();
  }, []);
  return (
    <>
      <div
        className="account-pages bg-primary birthday-page d-flex"
        style={{ minHeight: "100vh" }}
      >
        <ModalLoading
          onHide={() => setShowModalLoading(false)}
          show={showModalLoading}
        />
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">
                    We'd love to hear your thoughts on your server today! It's
                    completely confidential and your feedback goes directly to
                    the manager
                  </h4>
                  <div>
                    <div
                      className="w-100 h-50px rounded-6 bg-orange-light d-flex align-items-center px-3 gap-2"
                      onClick={() => setShowModalShooseStaff(true)}
                    >
                      <PersonIcon className="text-orange flex-shrink-0" />
                      <p className="m-0 text-orange font-bold text-xl text-truncate">
                        {nameStaffActive === ""
                          ? "Choose staff"
                          : nameStaffActive}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <Rating
                      size="large"
                      sx={{
                        fontSize: "4rem",
                      }}
                      value={valueRating}
                      onChange={(event, newValue) => {
                        setValueRating(newValue);
                        setMessErr("");
                      }}
                    />
                  </div>
                  <div className="mt-2">
                    <textarea
                      className="note-feedback-request focus-visiable-none"
                      id="textarea"
                      value={contentFeedback}
                      onChange={(e) => {
                        setContentFeedback(e.target.value);
                        setMessErr("");
                      }}
                    ></textarea>
                  </div>

                  <div className="text-center mt-2">
                    {messErr === "" ? (
                      ""
                    ) : (
                      <p className="my-1 text-danger text-italic">{messErr}</p>
                    )}
                    <Button
                      className="rounded-4 font-bold text-white"
                      onClick={handleReviewStaff}
                    >
                      Submit
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ModalChooseStaffRating
        listEmployeeActive={listEmployeeActive}
        handleClose={dismisModalShooseStaff}
        show={showModalShooseStaff}
        idStaffActiveted={idStaffActiveted}
        handeSubmit={handleSubmitStaff}
      />
    </>
  );
};

export default RatingStaff;
