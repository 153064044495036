import React, { useEffect, useState } from 'react';
import './styles.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import { truckLoading } from '../../../components/Image';
import Swal from "sweetalert2";
import { CancelRevervationCustomer, Reservation, ReservationDetailId, gRevervationDetailById, gRevervationDetailCustomer } from '../../../api/apiCheckin/reservationApi';
import ModalLoading from '../../../components/ModalLoading';
import socket from '../../../utils/socket';
import { getLanguageCheckIn } from '../../../Services/languageCheckin';
const ReservationDetail = () => {
  const languageUI = getLanguageCheckIn();
  const params = useParams();
  const reservation_id = params?.reservation_id;
  const [reservationDetail, setReservation] = useState<ReservationDetailId>()
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [messFail, setMessFail] = useState<string>('')
  const [messOrder, setMessOrder] = useState<string>(languageUI.Pleasewaitforconfirmation)

  const navigate = useNavigate()
  
  const gRevervationDetail = async () => {
    if (reservation_id) {
      const req = await gRevervationDetailById(reservation_id);
      if (Number(req?.status) === 1) {
        setReservation(req?.data)
        if (Number(req?.data?.status) === 1) {
          setMessOrder(languageUI.Tablereservationsconfirmed)
        } else {
          setMessOrder(languageUI.Pleasewaitforconfirmation)
        }
      }
    }

  }

  useEffect(() => {
    gRevervationDetail()
  }, [])
  
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <header className="w-100 custom-header-choose-menu d-flex justify-content-between align-items-center">
        
        <p className="title-name-store mb-0 text-truncate">{reservationDetail?.owner_name}</p>
      </header>
      <main>
        <div className="account-pages  custom-pading-header pb-70px bg-white px-4" style={{ minHeight: "100vh" }}>
          <div className="p-4 w-100 shadow-choose-status-order h-fit rounded-5 mt-3">
            <div className='d-flex justify-content-end mb-3'>
              <div className='d-flex gap-2 align-items-center'>
                <img src={truckLoading} alt="" className='h-40px' />
                <p className={`${Number(reservationDetail?.status) === 1 ? "text-primary" : "text-orange"} font-medium m-0 text-base text-italic`}>{messOrder}</p>
              </div>
            </div>
            <h2 className="font-bold text-4a4a6a text-center m-0">{languageUI.Booking}</h2>
            <div className='d-flex justify-content-between items-center mt-3 gap-2 w-100'>
              <p className="mb-0 font-bold text-4a4a6a flex-shrink-0 w-20">{languageUI.Fullname}:</p>
              <p className="mb-0 font-bold text-4a4a6a">{reservationDetail?.fullname}</p>
            </div>
            <div className='d-flex justify-content-between items-center mt-2 gap-2 w-100'>
              <p className="mb-0 font-bold text-4a4a6a flex-shrink-0 w-20">{languageUI.Phonenumber}:</p>
              <p className="mb-0 font-bold text-4a4a6a">{reservationDetail?.phone}</p>
            </div>
            <div className='d-flex justify-content-between items-center mt-2 gap-2 w-100'>
              <p className="mb-0 font-bold text-4a4a6a flex-shrink-0 w-20">{languageUI.Date}:</p>
              <p className="mb-0 font-bold text-4a4a6a">{reservationDetail?.booking_date}</p>
            </div>
            <div className='d-flex justify-content-between items-center mt-2 gap-2 w-100'>
              <p className="mb-0 font-bold text-4a4a6a flex-shrink-0 w-20">{languageUI.Time}:</p>
              <p className="mb-0 font-bold text-4a4a6a">{reservationDetail?.booking_time}</p>
            </div>
            <div className='d-flex justify-content-between items-center mt-2 gap-2 w-100'>
              <p className="mb-0 font-bold text-4a4a6a flex-shrink-0 w-20">{languageUI.Seats}:</p>
              <p className="mb-0 font-bold text-4a4a6a">{reservationDetail?.seats} {languageUI.Seat}</p>
            </div>
            <div className='d-flex justify-content-between items-start mt-2 gap-2 w-100'>
              <p className="mb-0 font-bold text-4a4a6a flex-shrink-0 w-20">{languageUI.Note}:</p>
              <p className="mb-0 font-bold text-4a4a6a text-right">{reservationDetail?.note}</p>
            </div>
            {messFail && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messFail}</p>)}
          </div>
        </div >
      </main>
    </>
  )
}
export default ReservationDetail