import Modal from 'react-bootstrap/Modal';
import { keyPassWord } from '../../../../components/Image';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from 'react';
import './styles.css'
import React from 'react';
import { changPassWordOwner, changPasscodeOwner } from '../../../../api/apiAdmin/GroupCustomerAdmin';
import { getLanguageCheckIn } from '../../../../Services/languageCheckin';

interface ModalResetAccountProps {
  show: boolean;
  ownerId: number;
  handleClose: () => void
}
function ModalResetAccount({ handleClose, show, ownerId }: ModalResetAccountProps) {
  const languageUI = getLanguageCheckIn();
  const [showNewCode, setShowNewCode] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [NewPass, setNewPass] = useState<string>("");
  const [RetypeNewPass, setRetypeNewPass] = useState<string>("");
  const [message, setMessage] = useState<any>("");
  const [messageSuccess, setMessageSuccess] = useState<any>("");
  const [NewPassCode, setNewPassCode] = useState<string>("");
  const [RetypeNewPassCode, setRetypeNewPassCode] = useState<string>("");
  const [showConfirmPasscode, setShowConfirmPasscode] = useState(false);
  const handleClickShowComfirmPassword = () => setShowConfirmPassword((showConfirm) => !showConfirm);


  const SubmitChangePasscode = async () => {
    if (ownerId) {
      if (NewPassCode.length !== 4) {
        setMessage(languageUI.Passcodemustcontain4characters)
      } else if (NewPassCode !== RetypeNewPassCode) {
        setMessage(languageUI.Reenterincorrectpasscode);
      } else {
        setMessage('');
        const req = await changPasscodeOwner(ownerId, NewPassCode);
        if (Number(req?.status) === 1) {
          setMessageSuccess(languageUI.Updatepasscodesuccessful)
        } else {
          setMessageSuccess(languageUI.Updatepasscodefailed)
        }
      }
    } else {
      setMessage(languageUI.Custommerisnotfound);
    }
  }

  const SubmitChangePassWord = async () => {
    if (ownerId) {
      if (NewPass.length !== 4) {
        setMessage(languageUI.Passwordmustcontain4characters)
      } else if (NewPass !== RetypeNewPass) {
        setMessage(languageUI.Reenterincorrectpassword);
      } else {
        setMessage('');
        const req = await changPassWordOwner(ownerId, NewPass);
        if (Number(req?.status) === 1) {
          setMessageSuccess(languageUI.Updatepasswordsuccessful)
        } else {
          setMessageSuccess(languageUI.Updatepasswordfailed)
        }
      }
    } else {
      setMessage(languageUI.Custommerisnotfound);
    }
  }

  useEffect(() => {
    if (show) {
      setNewPassCode('')
      setRetypeNewPassCode('')
      setNewPass('')
      setRetypeNewPass('')
      setMessage('')
      setMessageSuccess('')
    }
  }, [show])
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-reset-account"
    >
      <Modal.Body>
        <div className="w-100 d-flex flex-nowrap">
          <div className="position-relative w-50">
            <div className="flex justify-center items-center pt-6 ">
              <img src={keyPassWord} alt="" />
              <span className="text-4A4A6A text-8 fw-600 font-urbansist ml-2">{languageUI.Changepasscode}</span>
            </div>
            {/* <div className="flex flex-column mb-2 custom-p-password">
              <span className="text-5 mb-2">old passcode</span>
              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className="text-label-setting-password">passcode</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPasscode ? 'number' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPasscode(!showPasscode)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={languageUI.password}
                  value={OldPassCode}
                  onChange={(e: any) => { if (e.target.value && (e.target.value.length > 4 || Number(e.target.value) < 0)) { return; } setOldPassCode(e.target.value) }}

                />
              </FormControl>
            </div> */}
            <div className="flex flex-column mb-2 custom-p-password">
              <span className="text-5 mb-2">{languageUI.NewPasscode}</span>
              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className="text-label-setting-password">{languageUI.Enternewpasscode}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showNewCode ? 'number' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewCode(!showNewCode)}
                        edge="end"
                      >
                        {showNewCode ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={languageUI.Enternewpasscode}
                  value={NewPassCode}
                  onChange={(e: any) => { if (e.target.value && (e.target.value.length > 4 || Number(e.target.value) < 0)) { return; } setNewPassCode(e.target.value) }}
                />
              </FormControl>
            </div>
            <div className="flex flex-column mb-2 custom-p-password">
              <span className="text-5 mb-2">{languageUI.RetypePasscode}</span>
              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className="text-label-setting-password">{languageUI.Enternewpasscodetoconfirm}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPasscode ? 'number' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPasscode(!showConfirmPasscode)}
                        edge="end"
                      >
                        {showConfirmPasscode ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={languageUI.Enternewpasscodetoconfirm}
                  value={RetypeNewPassCode}
                  // onChange={(e: any) => { setRetypeNewPass(e.target.value) }}
                  onChange={(e: any) => { if (e.target.value && (e.target.value.length > 4 || Number(e.target.value) < 0)) { return; } setRetypeNewPassCode(e.target.value) }}
                />
              </FormControl>
            </div>
            <div className='d-flex justify-content-center'>
              <button className="w-50 radius-2 text-5 bg-0FA54A  text-white py-2 border-none" onClick={SubmitChangePasscode}>
                {languageUI.submit}
              </button>
            </div>
            {/* <div className="w-100">
              <p className="text-danger fs-12 mb-2 text-italic text-center">{messagecode}</p>
              <p className="text-success fs-12 mb-2 text-center">{messageSuccesscode}</p>
              <div className="flex items-center pb-5 px-14 justify-content-center">
                <div className="pl-2 w-50">
                  <button className="w-100 radius-2 text-5 bg-0FA54A  text-white py-2 border-none"
                  >Change passcode</button>
                </div>

              </div>
            </div> */}


          </div>
          <div className="w-50 position-relative">
            <div className="flex justify-center items-center pt-6 ">
              <img src={keyPassWord} alt="" />
              <span className="text-4A4A6A text-8 fw-600 font-urbansist ml-2">{languageUI.ResetPassword}</span>
            </div>
            {/* <div className="flex flex-column mb-2 custom-p-password">
              <span className="text-5 mb-2">New Password</span>
              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className="text-label-setting-password">Enter new password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showNewPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={languageUI.enterNewPassword}
                  value={NewPass}
                  onChange={(e: any) => { setNewPass(e.target.value) }}
                />
              </FormControl>
            </div> */}
            <div className="flex flex-column mb-2 custom-p-password">
              <span className="text-5 mb-2">{languageUI.NewPassword}</span>
              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className="text-label-setting-password">{languageUI.Enternewpassword}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showNewPassword ? 'number' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={languageUI.Enternewpassword}
                  value={NewPass}
                  onChange={(e: any) => { if (e.target.value && (e.target.value.length > 4 || Number(e.target.value) < 0)) { return; } setNewPass(e.target.value) }}
                />
              </FormControl>
            </div>
            <div className="flex flex-column mb-2 custom-p-password">
              <span className="text-5 mb-2">{languageUI.RetypePassword}</span>
              <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password" className="text-label-setting-password">{languageUI.Enternewpasswordtoconfirm}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowComfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={languageUI.Enternewpasswordtoconfirm}
                  value={RetypeNewPass}
                  onChange={(e: any) => { if (e.target.value && (e.target.value.length > 4 || Number(e.target.value) < 0)) { return; } setRetypeNewPass(e.target.value) }}
                />
              </FormControl>
            </div>
            <div className='d-flex justify-content-center'>
              <button className="w-50 radius-2 text-5 bg-0FA54A  text-white py-2 border-none" onClick={SubmitChangePassWord}>
                {languageUI.submit}
              </button>
            </div>


          </div>

        </div>
        <div>
          <div className="w-100">
            <p className="text-danger fs-12 mb-2 text-italic text-center">{message}</p>
            <p className="text-success fs-12 mb-2 text-center">{messageSuccess}</p>
            <div className="flex items-center justify-content-center gap-5 pb-5 px-14">
              <div className="pl-2 w-70 mt-2">
                <button className="w-100 radius-2 text-5 bg-ed534e  text-white py-2 border-none" onClick={handleClose}>
                  {languageUI.Cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  );
}
export default ModalResetAccount