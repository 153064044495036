/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../LeftMenuv2/LeftSidebarv2";
import "./Styles.css";
import Topbar from "../Topbar";
import { useEffect, useState } from "react";
import React from "react";
import NotificationItem from "../../components/NotificationItem/NotificationItem";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import {
  Notification,
  gListNotification,
} from "../../api/apiOwner/notificationApi";
import socket from "../../utils/socket";
import { checkConnectPrinterByCatalog } from "../../api/apiOwner/SettingPrinterApi";
import { getLanguageUI } from "../../Services/languageUI";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NotificationsIcon from "@mui/icons-material/Notifications";

const AppLayout = () => {
  const location = useLocation();
  const languageUI = getLanguageUI().layout;
  const [pageName, setPageName] = useState("");
  const [notificationRouterhidden, setNotificationRouterhidden] =
    useState(false);
  const [listNotification, setListNotification] = useState<Notification[]>([]);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [listWaringPrinter, setListWaringPrinter] = useState<string[]>([]);
  const [showFullSideBar, setShowFullSideBar] = useState<boolean>(true);
  const [showNotificaton, setShowNotification] = useState<boolean>(true);

  const sidebarNavOwnerItems = [
    {
      display: "QRCode",
      to: "/web-owner/qrcode",
      section: ["/web-owner/qrcode"],
      notification: 0,
    },
    {
      display: languageUI.tablePOS,
      to: "/web-owner/tablepos",
      section: ["/web-owner/tablepos"],
      notification: 1,
    },
    {
      display: languageUI.menuPOS,
      to: "/web-owner/posmenu",
      section: ["/web-owner/posmenu"],
      notification: 1,
    },

    {
      display: languageUI.clockInOut,
      to: "/web-owner/clockinclockout",
      section: ["/web-owner/clockinclockout"],
      notification: 1,
    },
    {
      display: languageUI.pickup,
      to: "/web-owner/pickup",
      section: ["/web-owner/pickup"],
      notification: 1,
    },
    {
      display: languageUI.setting,
      to: "/web-owner/settingownerinformation",
      section: ["/web-owner/settingownerinformation"],
      notification: 0,
    },
    {
      display: languageUI.setUpTable,
      to: "/web-admin/edit-setting-customer-infor/",
      section: ["/web-admin/edit-setting-customer-infor/"],
      notification: 0,
    },
    {
      display: languageUI.menuTemplate,
      to: "/web-owner/menutemplate",
      section: ["/web-owner/menutemplate"],
      notification: 0,
    },
    {
      display: languageUI.customer,
      to: "/web-owner/customer",
      section: ["/web-owner/customer"],
      notification: 0,
    },
    {
      display: languageUI.customerReciepts,
      to: "/web-owner/customerreciepts",
      section: ["/web-owner/customerreciepts"],
      notification: 0,
    },
    {
      display: languageUI.report,
      to: "/web-owner/report",
      section: [
        "/web-owner/report",
        "/web-owner/report/incomedetail",
        "/web-owner/report/incomedatedetail",
      ],
      notification: 0,
    },
    {
      display: languageUI.review,
      to: "/web-owner/review",
      section: ["/web-owner/review", "/web-owner/detailreview"],
      notification: 0,
    },
    {
      display: languageUI.inventory,
      to: "/web-owner/inventory",
      section: ["/web-owner/inventory"],
      notification: 0,
    },
    {
      display: languageUI.staffs,
      to: "/web-owner/staffs",
      section: [
        "/web-owner/staffs",
        "/web-owner/creatstaffs",
        "/web-owner/editstaffs",
        "/web-owner/salaryweek",
        "/web-owner/salary2week",
        "/web-owner/salaryweek",
        "/web-owner/tablesalary2week",
        "/web-owner/tablesalaryweek",
        "/web-owner/salarymonth",
        "/web-owner/tablesalarymonth",
      ],
      notification: 0,
    },
    {
      display: languageUI.setUpVoucher,
      to: "/web-owner/setupvoucher",
      section: ["/web-owner/setupvoucher"],
      notification: 0,
    },
    {
      display: languageUI.subscription,
      to: "/web-owner/subscription",
      section: ["/web-owner/subscription"],
      notification: 0,
    },
    {
      display: languageUI.feedback,
      to: "/web-owner/feedback",
      section: ["/web-owner/feedback"],
      notification: 0,
    },
    {
      display: languageUI.offlineMode,
      to: "/web-owner/offlinebooking",
      section: ["/web-owner/offlinebooking"],
      notification: 0,
    },
    {
      display: languageUI.order,
      to: "/web-owner/order",
      section: ["/web-owner/order"],
      notification: 1,
    },
    {
      display: "History",
      to: "/web-owner/history",
      section: ["/web-owner/history"],
      notification: 1,
    },
    {
      display: languageUI.setupTable,
      to: "/web-owner/setuptable",
      section: ["/web-owner/setuptable"],
      notification: 0,
    },
    {
      display: languageUI.reservation,
      to: "/web-owner/reservation",
      section: ["/web-owner/reservation"],
      notification: 1,
    },
    {
      display: languageUI.giftCard,
      to: "/web-owner/giftCard",
      section: ["/web-owner/giftCard"],
      notification: 0,
    },
    {
      display: "Handheld POS",
      to: "/web-owner/handheldPOS",
      section: ["/web-owner/handheldPOS"],
      notification: 0,
    },
    {
      display: languageUI.giftCardHistory,
      to: "/web-owner/giftCardHistory",
      section: ["/web-owner/giftCardHistory"],
      notification: 0,
    },
    {
      display: languageUI.listQrCodeGiftCard,
      to: "/web-owner/giftCardDetail",
      section: ["/web-owner/giftCardDetail"],
      notification: 0,
    },
    {
      display: "Station",
      to: "/web-owner/pos-setting",
      section: ["/web-owner/pos-setting"],
      notification: 0,
    },
  ];
  const gListNotificationByOwner = async () => {
    if (UserIdStore) {
      const req = await gListNotification(UserIdStore);
      if (req?.status === 1) {
        setListNotification(req?.data ?? []);
      } else {
      }
    }
  };
  const checkConnectPrinter = async () => {
    var pathName = window.location.pathname;
    if (pathName.search("/web-owner/") > -1) {
      if (UserIdStore) {
        const req = await checkConnectPrinterByCatalog(UserIdStore, pos_id);
        if (Number(req?.status) === 1) {
          setListWaringPrinter(req?.data ?? []);
        }
      }
    }
  };
  const handleShowFullSideBar = () => {
    setShowFullSideBar(!showFullSideBar);
  };
  const changeShowNotification = () => {
    setShowNotification(!showNotificaton);
  };
  useEffect(() => {
    checkConnectPrinter();
    setPageName(
      sidebarNavOwnerItems.filter((item) =>
        item.section.some((nav) => nav === window.location.pathname)
      )[0]?.display
    );
    const location = window.location.pathname;
    if (location === "/web-owner/posmenu") {
      setNotificationRouterhidden(true);
    } else if (location === "/web-owner/tablepos") {
      setNotificationRouterhidden(true);
    } else if (location === "/web-owner/clockinclockout") {
      setNotificationRouterhidden(true);
    } else if (location === "/web-owner/order") {
      setNotificationRouterhidden(true);
    } else {
      setNotificationRouterhidden(false);
    }
  }, [location]);
  useEffect(() => {
    socket.on("PushShowNotification" + UserIdStore, (data: any) => {
      var pathName = window.location.pathname;
      if (pathName.search("/web-owner/") > -1) {
        gListNotificationByOwner();
      }
    });
  }, [socket]);
  useEffect(() => {
    gListNotificationByOwner();
  }, []);
  return (
    <div
      className="custom-root"
      style={{
        padding: `0px 0px 0px ${showFullSideBar ? "100px" : `190px`}`,
      }}
    >
      <Sidebar
        showFullSideBar={showFullSideBar}
        changeFullSideBar={handleShowFullSideBar}
      />
      <div className="flex  h-100vh">
        <div className="w-100">
          <div>
            <Topbar pageName={pageName} showFullSideBar={showFullSideBar} />
          </div>
          <Outlet />
        </div>
        <div
          className={`${
            notificationRouterhidden ? "" : "d-none"
          } custom-notification flex-shrink-0 position-relative`}
          style={{ width: `${showNotificaton ? "100px" : ""}` }}
        >
          <div
            className="bth-change-show-notification"
            onClick={changeShowNotification}
          >
            {showNotificaton ? (
              <KeyboardDoubleArrowLeftIcon />
            ) : (
              <KeyboardDoubleArrowRightIcon />
            )}
          </div>
          <p className="text-center text-notification-router">
            {showNotificaton ? (
              <NotificationsIcon
                style={{ fontSize: "30px", color: "#00A15A" }}
              />
            ) : (
              languageUI.notification
            )}
          </p>
          <div className="px-4 box-content-notification">
            <div className="flex flex-column gap-2 py-2">
              {listNotification.map(
                (item, i) =>
                  Number(item?.is_read) === 0 && (
                    <NotificationItem
                      staffName={item.staff_name}
                      is_read={Number(item?.is_read)}
                      table_name={item?.table_name}
                      timestamp={item?.timeline ?? ""}
                      type={Number(item?.type)}
                      notificationId={item?.id}
                      title={item?.title}
                      is_end_payment={Number(item?.is_end_payment)}
                      key={i}
                      showNotificaton={showNotificaton}
                    />
                  )
              )}
            </div>
          </div>
        </div>
      </div>
      {listWaringPrinter.length > 0 && (
        <div className="Popup-Printer">
          {listWaringPrinter.map((item, i) => (
            <div className="content-popup-printer" key={i}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppLayout;
