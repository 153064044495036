import { Form } from "react-bootstrap";
import "./Styles.css";
import { useEffect, useState } from "react";
import * as ChooseMenu from "../../../../api/apiCheckin/chooseMenuApi";
import * as addCartApi from "../../../../api/apiCheckin/addCartApi";
import { plus } from "../../../../components/ImgExport";
import { FormatDolla } from "../../../../utils/format";
import { Transition } from "react-transition-group";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { Modal } from "@mui/material";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalAddToCardOnlineProps {
  show: boolean;
  ownerID?: string;
  customerID?: string;
  price: number;
  side_id?: string;
  detailFood?: ChooseMenu.InfoFood;
  side_Dishes?: ChooseMenu.Side_Dishes[];
  noteFood?: ChooseMenu.NoteFood[];
  setShowLoading?: (load: boolean) => void;
  handleClose: () => void;
  foodTotal: number;
  name?: string;
  description?: string;
  setFootTotal: (num: number) => void;
  listOptionNote: ChooseMenu.listNoteOptionFood[];
}

const ModalAddToCardOnline = ({
  show,
  handleClose,
  detailFood,
  side_Dishes,
  ownerID = "",
  foodTotal = 0,
  setFootTotal,
  customerID = "",
  setShowLoading,
  side_id = "",
  name,
  description,
  price,
  noteFood,
  listOptionNote = [],
}: ModalAddToCardOnlineProps) => {
  const languageUI = getLanguageCheckIn();
  const [valueSideDishis, setValueSideDishis] = useState<string>();
  const [noteChef, setNoteChef] = useState<string>("");
  const valueOrdeBeefsteak = 2;
  const [messError, setMessError] = useState<string>("");
  const [NoteFoodId, setNoteFoodId] = useState<string>();
  const [activeOptionNoteFood, setActiveOptionNoteFood] = useState<string[]>(
    []
  );
  const [priceSide, setPriceSide] = useState<number>(0);

  const AddToCart = async () => {
    if (ownerID == "") {
      setMessError(languageUI.Ownerisnotfound);
    } else if (!detailFood) {
      setMessError(languageUI.Foodisnotfound);
    } else if (!customerID || customerID == "") {
      setMessError(languageUI.Custommerisnotfound);
    } else {
      if (noteFood && noteFood.length > 0 && !NoteFoodId) {
        setMessError(languageUI.Pleasechoosenoteforthisfood);
      } else {
        handleClose?.();
        setShowLoading?.(true);
        const note =
          (noteChef !== "" && activeOptionNoteFood.length > 0
            ? noteChef + ", "
            : noteChef) + activeOptionNoteFood.join(", ");
        const result = await addCartApi.addCartOnline(
          customerID,
          ownerID,
          detailFood?.id,
          detailFood?.price,
          1,
          NoteFoodId,
          valueSideDishis,
          valueOrdeBeefsteak,
          note,
          priceSide
        );
        if (result.status == 1) {
          setFootTotal(foodTotal + 1);
          setShowLoading?.(false);
        } else if (result.status == 2) {
          setFootTotal(foodTotal + 1);
          setShowLoading?.(false);
        } else {
          setShowLoading?.(false);
          if (noteFood && noteFood.length > 0 && !NoteFoodId) {
            setMessError(languageUI.Pleasechoosenoteforthisfood);
          } else {
            handleClose?.();
            setShowLoading?.(true);
            const note =
              (noteChef !== "" && activeOptionNoteFood.length > 0
                ? noteChef + ", "
                : noteChef) + activeOptionNoteFood.join(", ");
            const result = await addCartApi.addCartOnline(
              customerID,
              ownerID,
              detailFood?.id,
              detailFood?.price,
              1,
              NoteFoodId,
              valueSideDishis,
              valueOrdeBeefsteak,
              note,
              priceSide
            );
            if (result.status == 1) {
              setFootTotal(foodTotal + 1);
              setShowLoading?.(false);
            } else if (result.status == 2) {
              setFootTotal(foodTotal + 1);
              setShowLoading?.(false);
            } else {
              setShowLoading?.(false);
            }
          }
        }
      }
    }
  };
  const maxLength = 250;

  const handleChange = (event: any) => {
    const newText = event.target.value;

    if (newText.length <= maxLength) {
      setNoteChef(newText);
    }
  };
  const listActiveOptionNoteFood = (optionName: string, price: number) => {
    let note = optionName + (Number(price) > 0 ? `: $${price}` : "");

    if (activeOptionNoteFood.includes(note)) {
      if (note !== "") {
        setActiveOptionNoteFood(
          activeOptionNoteFood.filter((item) => item !== note)
        );
        setPriceSide(priceSide - Number(price));
      }
    } else {
      if (note !== "") {
        setActiveOptionNoteFood([...activeOptionNoteFood, note]);
        setPriceSide(priceSide + Number(price));
      }
    }
  };

  useEffect(() => {
    if (show) {
      setMessError("");
      setValueSideDishis(side_id ?? "");
      setNoteChef("");
      setPriceSide(0);
      setNoteFoodId(undefined);
    }
  }, [show]);
  return (
    <>
      <Transition in={show} timeout={400}>
        {(state: string) => (
          <Modal
            className="mui-add-to-card"
            keepMounted
            open={!["exited", "exiting"].includes(state)}
            onClose={handleClose}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: "none",
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: "blur(8px)" },
                    entered: { opacity: 1, backdropFilter: "blur(8px)" },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === "exited" ? "hidden" : "visible",
            }}
          >
            <ModalDialog
              layout="center"
              size="lg"
              sx={{
                opacity: 0,
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogTitle>
                <div className="w-100">
                  <div
                    className="d-flex justify-content-end w-100"
                    onClick={handleClose}
                  >
                    <CloseIcon />
                  </div>
                  <div className="d-flex w-100 justify-content-between align-items-center mt-1">
                    <p className="title-filter mb-0 font-bold pe-2">{name}</p>
                    <div className="d-flex gap-2px align-items-center">
                      <div className="text-dollar">$</div>
                      <div className="text-price-food">
                        {FormatDolla(price)}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="px-3 overflow-y-scroll">
                  <div className="d-flex justify-content-between align-items">
                    <p className="title-filter m-0">{description}</p>
                  </div>
                  {side_Dishes && side_Dishes.length > 0 && (
                    <>
                      <p className="choose-a-side mb-0">
                        {languageUI.Chooseasidedishes}
                      </p>
                      <Form>
                        {side_Dishes.map((sideItem, s) => (
                          <Form.Check
                            key={`side${s}`}
                            name="choose-a-side mb-2"
                            className="custom-radio-yellow p-0 d-flex align-items-center"
                            label={
                              <div className="d-flex gap-1 mt-1">
                                <p className="mb-0 text-choose-a-side text-666687">
                                  {sideItem?.name}
                                </p>{" "}
                                <p className="mb-0 text-choose-a-side text-12B064">
                                  (Free x1)
                                </p>
                              </div>
                            }
                            type="radio"
                            id={sideItem?.id}
                            checked={valueSideDishis == sideItem?.id}
                            onClick={() => setValueSideDishis(sideItem?.id)}
                          />
                        ))}
                      </Form>
                    </>
                  )}
                  {noteFood && noteFood.length > 0 && (
                    <>
                      <div className="d-flex gap-3 align-items-center mt-1">
                        <p className="choose-a-side mt-0 mb-0">
                          {languageUI.Note}
                        </p>
                        {messError !== "" && (
                          <div className="text-italics text-danger text-center box-mess-error">
                            <i className="fas fa-exclamation-triangle"></i>{" "}
                            {messError}
                          </div>
                        )}
                      </div>
                      <div className="d-flex gap-2 flex-wrap mt-1">
                        {noteFood &&
                          noteFood.map((items, i) => (
                            <div
                              key={`note${i}`}
                              className={`d-flex justify-content-center align-items-center   ${
                                NoteFoodId == items?.notefood_id
                                  ? "custom-choose-categories-2"
                                  : "custom-choose-categories"
                              }`}
                              onClick={() => {
                                setNoteFoodId(items.notefood_id);
                              }}
                            >
                              <p
                                className={`name-food-add-to-pos-menu mb-0 ${
                                  NoteFoodId == items.notefood_id
                                    ? "text-ffffff"
                                    : "text-8E8EA9"
                                }`}
                              >
                                {items.notefood_name}
                              </p>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                  {listOptionNote.length > 0 ? (
                    <div className="mt-1">
                      <p className="name-food-add-to-pos-menu mb-0 ">
                        {/* {languageUI.NoteFood} */}
                        Add-on
                      </p>
                      <div className="d-flex flex-wrap gap-2 mt-1">
                        {listOptionNote.map((item, index) => (
                          <div
                            className={` w-fit name-food-add-to-pos-menu px-3 py-1 text-14px   rounded-4  ${
                              activeOptionNoteFood.includes(
                                item.option_name +
                                  (Number(item.price) > 0
                                    ? `: $${item.price}`
                                    : "") ?? ""
                              )
                                ? "text-white bg-FF7B2C border-FF7B2C"
                                : "text-4a4a6a border"
                            }`}
                            key={`list-option-note-${item.id}`}
                            onClick={() =>
                              listActiveOptionNoteFood(
                                item.option_name ?? "",
                                Number(item.price) ?? 0
                              )
                            }
                          >
                            {item.option_name}{" "}
                            {Number(item.price) > 0 ? ": $" + item.price : ""}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <p className="choose-a-side mb-0 ">
                    {languageUI.Morerequest}
                  </p>
                  <div>
                    <textarea
                      className="text-more-request"
                      id="textarea"
                      value={noteChef}
                      onChange={handleChange}
                      maxLength={maxLength}
                    ></textarea>
                    <div className="w-100 d-flex justify-content-end px-3">
                      <small className="text-muted text-right">
                        {noteChef.length}/{maxLength}
                      </small>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <button
                  className="custom-filter-btn border-none w-100 text-ffffff"
                  onClick={AddToCart}
                >
                  <img src={plus} alt="" className="img-filter " />
                  {languageUI.Addtocart}
                </button>
              </DialogActions>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </>
  );
};

export default ModalAddToCardOnline;
