import { Accordion, Form, Modal } from "react-bootstrap";
import "./Styles.css";
import {
  leaf1,
  leaf2,
  pencilEdit,
  strashRed,
  trashBlack,
  trashGray,
  trashRed,
  trashRedRemoveFloor,
} from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import { FloorInfo } from "../../../../api/apiOwner/tableApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  DeleteFloor,
  EditFloorName,
  addFloorByOwner,
} from "../../../../api/apiOwner/FloorApi";
import ModalOTPDeleteTable from "../ModalOTPDeleteTable/ModalOTPDeleteTable";
import { TroubleshootOutlined } from "@mui/icons-material";
import ModalEditNameTable from "../ModalEditNameTable/ModalEditNameTable";
import ModalLoading from "../../../../components/ModalLoading";
import { boolean } from "yup";
import { SketchPicker } from "react-color";

interface AddFloorItemProps {
  color: string;
  numberTable: number;
  floorName: string;
  floorId: string;
  is_tmp: boolean;
  setFloorIdRemove: (floorid: string) => void;
  removeFloor: (floor_id: string, is_tmp: boolean) => void;
  setShowModalOTPDeleteTable: (status: boolean) => void;
  setShowModalEditNameTable: (status: boolean) => void;
  handelEdit: (
    floorID: string,
    floorName: string,
    is_Tmp: boolean,
    color: string
  ) => void;
}

const AddFloorItem = ({
  numberTable = 0,
  floorName = "",
  floorId = "",
  is_tmp = false,
  setFloorIdRemove,
  removeFloor,
  setShowModalOTPDeleteTable,
  setShowModalEditNameTable,
  handelEdit,
  color,
}: AddFloorItemProps) => {
  const handelDelete = () => {
    if (is_tmp) {
      removeFloor(floorId, is_tmp);
    } else {
      setShowModalOTPDeleteTable(true);
      setFloorIdRemove(floorId);
    }
  };
  return (
    <div className="d-flex justify-content-between align-items-center mt-2">
      <div className="max-width-add-floor">
        <p className="text-delete-modal-customer mb-0 text-truncate">
          {floorName}
        </p>
        <div className="d-flex gap-2px">
          <p className="text-has-floor text-A5A5BA mb-0 d-flex gap-2px">
            has<p className="text-has-floor m-0 text-12B064">{numberTable}</p>
            table
          </p>
        </div>
      </div>
      <div className="d-flex gap-2">
        <button
          className="btn-edit-delete-floor"
          disabled={numberTable === 0 ? false : true}
          onClick={() => handelDelete()}
        >
          {numberTable === 0 ? (
            <img src={trashRedRemoveFloor} alt="" />
          ) : (
            <img src={trashGray} alt="" />
          )}
        </button>
        <button
          className="btn-edit-delete-floor"
          onClick={() => {
            handelEdit(floorId, floorName, is_tmp, color);
            setShowModalEditNameTable(true);
          }}
        >
          <img src={pencilEdit} alt="" />
        </button>
      </div>
    </div>
  );
};

interface ModalAddFloorProps {
  listFloor: FloorInfo[];
  show: boolean;
  handleSuccess: () => void;
  handleClose: () => void;
}
interface FloorTmp {
  num_table: number;
  floor_name: string;
  color: string;
}
const ModalAddFloor = ({
  listFloor = [],
  show,
  handleSuccess,
  handleClose,
}: ModalAddFloorProps) => {
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [listFloorTmp, setFloorTmp] = useState<FloorTmp[]>([]);
  const [floorName, setFloorName] = useState<string>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalEditNameTable, setShowModalEditNameTable] =
    useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  // const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showModalOTPDeleteTable, setShowModalOTPDeleteTable] =
    useState<boolean>(false);
  const [floorIdRemove, setFloorIdRemove] = useState("");
  const [floorIdEdit, setFloorIdEdit] = useState("");
  const [colorFloor, setColorFloor] = useState("#f17013");
  const [floorNameEdit, setFloorNameEdit] = useState("");
  const [is_tmpEdit, setIs_TmpEdit] = useState<boolean>(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorPickerRGBa, setColorPickerRGBa] = useState("#f17013");
  const [changeColorHex, setChangeColorHex] = useState<string>("#f17013");

  const handelClickColor = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleCloseColor = () => {
    setDisplayColorPicker(false);
  };
  const handleChangeColor = (color: any) => {
    setColorPickerRGBa(color.hex);
    setChangeColorHex(color.hex);
  };

  const dismisModalOTPDeleteTable = () => {
    setShowModalOTPDeleteTable(false);
  };
  const dismisModalEditNameTable = () => {
    setShowModalEditNameTable(false);
  };

  const removeFloor = async (floor_id: string, is_tmp: boolean) => {
    if (is_tmp) {
      setFloorTmp(
        listFloorTmp.filter(
          (item) =>
            item?.floor_name?.toLocaleLowerCase() !=
            floor_id?.toLocaleLowerCase()
        )
      );
    } else {
      setShowLoading(true);
      const reqDel = await DeleteFloor(floor_id);
      if (Number(reqDel?.status) === 1) {
        setShowLoading(false);
        setFloorIdRemove("");
        handleSuccess();
      } else {
        setShowLoading(false);
        setMessageError(reqDel?.message ?? "");
      }
    }
  };

  const AddFloorTmp = () => {
    setMessageError("");
    if (
      !floorName ||
      floorName.trim() === "" ||
      listFloorTmp.some(
        (item) => item?.floor_name?.toLowerCase() === floorName?.toLowerCase()
      ) ||
      listFloor.some(
        (item) => item?.floor_name?.toLowerCase() === floorName?.toLowerCase()
      )
    ) {
      setFloorName("");
    } else {
      setFloorName("");
      setFloorTmp([
        ...listFloorTmp,
        { floor_name: floorName, num_table: 0, color: colorPickerRGBa },
      ]);
    }
  };

  const EditFloorTmp = async (
    floorID: string,
    floorName: string,
    color: string
  ) => {
    setFloorTmp([
      ...listFloorTmp.map((item) =>
        item?.floor_name?.toLowerCase() === floorID?.toLowerCase()
          ? { ...item, floor_name: floorName, color: color }
          : item
      ),
    ]);
    dismisModalEditNameTable();
  };
  const SubmitSaveFloor = async () => {
    if (UserIdStore) {
      console.log(listFloorTmp);

      if (listFloorTmp.length === 0) {
        setMessageError("Please enter the floor name!");
      } else {
        setShowLoading(true);
        const listFloorName = listFloorTmp.map((item) => {
          return { floor_name: item?.floor_name, color: item?.color };
        });

        const ReqAdd = await addFloorByOwner(UserIdStore, listFloorName);
        if (Number(ReqAdd.status) === 1) {
          setShowLoading(false);
          setMessageError("");
          handleClose();
          handleSuccess();
        } else {
          setMessageError(ReqAdd?.message ?? "");
          setShowLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    if (show) {
      setMessageError("");
      setFloorIdRemove("");
      setFloorNameEdit("");
      setFloorIdEdit("");
      setIs_TmpEdit(false);
      setFloorTmp([]);
    }
  }, [show]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className={`modal-delete-customer ${
          showModalOTPDeleteTable || showModalEditNameTable ? "d-none" : ""
        }`}
      >
        <Modal.Dialog>
          <Modal.Body>
            <div className="position-relative px-3 w-466px">
              <div className="w-100">
                <p className="text-delete-modal-customer mb-0 text-center">
                  Floor management
                </p>
                <div>
                  <p className="text-add-floor mb-1">Add Floor</p>
                  <div className="box-input-add-floor w-100">
                    <input
                      type="text"
                      className="input-add-floor focus-visiable-none border-none"
                      value={floorName}
                      placeholder="Floor name"
                      onChange={(e) => setFloorName(e.target.value)}
                    />
                    <div className="btn-input-add-floor" onClick={AddFloorTmp}>
                      Add
                    </div>
                  </div>
                  <div className="w-100 mt-1">
                    <p className="text-add-table-name mb-1">Color</p>
                    <div className="input-add-table d-flex gap-2 align-items-center p-2 ">
                      <div className="" onClick={handelClickColor}>
                        <div
                          className="btn-change-color-menu border color-style"
                          style={{ backgroundColor: colorPickerRGBa }}
                        ></div>
                      </div>

                      {displayColorPicker ? (
                        <div className="popover-style">
                          <div
                            className="cover-style"
                            onClick={handleCloseColor}
                          />
                          <SketchPicker
                            color={colorPickerRGBa}
                            onChange={handleChangeColor}
                          />
                        </div>
                      ) : null}
                      <p className="mb-0 text-change-color-menu">
                        {changeColorHex}
                      </p>
                    </div>
                  </div>
                  <p className="text-center text-danger text-italic mb-1">
                    {messageError}
                  </p>
                  {listFloorTmp.map((item, i) => (
                    <AddFloorItem
                      color={item.color}
                      setShowModalOTPDeleteTable={setShowModalOTPDeleteTable}
                      setShowModalEditNameTable={setShowModalEditNameTable}
                      removeFloor={removeFloor}
                      numberTable={Number(item.num_table)}
                      key={`floortmp${i}`}
                      floorId={item?.floor_name}
                      floorName={item?.floor_name}
                      setFloorIdRemove={setFloorIdRemove}
                      is_tmp={true}
                      handelEdit={(
                        floorID: string,
                        floorName: string,
                        is_Tmp: boolean,
                        color: string
                      ) => {
                        setFloorIdEdit(floorID);
                        setFloorNameEdit(floorName);
                        setIs_TmpEdit(is_Tmp);
                        setColorFloor(color);
                      }}
                    />
                  ))}
                  {listFloor.map((items, i) => (
                    <AddFloorItem
                      color={items.color}
                      setShowModalOTPDeleteTable={setShowModalOTPDeleteTable}
                      setShowModalEditNameTable={setShowModalEditNameTable}
                      removeFloor={removeFloor}
                      numberTable={Number(items.num_table)}
                      key={`floor${i}`}
                      floorId={items?.id}
                      floorName={items?.floor_name}
                      setFloorIdRemove={setFloorIdRemove}
                      is_tmp={false}
                      handelEdit={(
                        floorID: string,
                        floorName: string,
                        is_Tmp: boolean
                      ) => {
                        setFloorIdEdit(floorID);
                        setFloorNameEdit(floorName);
                        setIs_TmpEdit(is_Tmp);
                      }}
                    />
                  ))}

                  {/* <AddFloorItem numberTable={10} /> */}

                  <div className="px-3">
                    <div className="d-flex justify-content-between mt-3">
                      <div
                        className="btn-save-cancel-floor text-4a4a6a border"
                        onClick={handleClose}
                      >
                        Cancel
                      </div>
                      <div
                        className="btn-save-cancel-floor text-white bg-0FA54A border-0FA54A"
                        onClick={SubmitSaveFloor}
                      >
                        Save
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1-setup-table" />
              <img src={leaf2} alt="" className="img-leaf2" />
              <ModalOTPDeleteTable
                handleClose={dismisModalOTPDeleteTable}
                show={showModalOTPDeleteTable}
                UserIdStore={UserIdStore}
                handleSuccess={() => removeFloor(floorIdRemove, false)}
              />
              <ModalEditNameTable
                handleClose={dismisModalEditNameTable}
                show={showModalEditNameTable}
                floorIdEdit={floorIdEdit}
                floorNameEdit={floorNameEdit}
                handleSuccess={handleSuccess}
                is_tmp={is_tmpEdit}
                EditFloorTmp={EditFloorTmp}
                bgColor={colorFloor}
              />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalAddFloor;
