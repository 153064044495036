import { Toast, ToastContainer } from "react-bootstrap";
import "./Styles.css"
import AudioPlayer from "react-h5-audio-player";


interface ModalNotificationProps {
    show: boolean;
    handleClose: () => void;
    content: string;
    audioNotif: any;
    onPlay: () => void;
}

const ModalNotification = ({
    show,
    audioNotif,
    onPlay,
    content
}: ModalNotificationProps) => {
    return (
        <>
            <ToastContainer
                className="mt-3 toast-container-notification position-fixed"
                // position="top-center"
                style={{ zIndex: 1002 }}
            >
                <Toast className="w-70 py-2 bg-eeeeee " show={show}>

                    <Toast.Body className=" text-center"><p className="text-center mb-0 text-notification">{content}</p></Toast.Body>
                </Toast>
            </ToastContainer>
            <AudioPlayer
                //autoPlay
                ref={audioNotif}
                src="https://api.brijackposadmin.com/owner/image/adios/notificationAudio.wav"
                className="recored-audio-player d-none"
                onPlay={() => onPlay()}
            />
        </>

    );
};

export default ModalNotification;
