import Sheet from "react-modal-sheet";
import { arrowLeft } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import "./Styles.css";
import { Elements } from "@stripe/react-stripe-js";
import { Token, loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { gstripeKeyOwner } from "../../../../api/apiOwner/StripeOwnerApi";
import ModalLoading from "../../../../components/ModalLoading";
import React from "react";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalAddVisaCardProps {
  show: boolean;
  showDateYear: boolean;
  CVVNumber?: number;
  expiryDate?: string;
  cardNumber?: number | string;
  setCardNumber: (val: number | string) => void;
  setCVVNumber: (val: number) => void;
  setexpiryDate: (val: string) => void;
  handleClose: (value: boolean) => void;
  handleCloseshowDateYear: () => void;
  handleShowDateYear: () => void;
  setTokenCard: (token: Token) => void;
}

const ModalAddVisaCard = ({
  show,
  CVVNumber,
  expiryDate,
  cardNumber,
  setCardNumber,
  setCVVNumber,
  setexpiryDate,
  handleClose,
  handleCloseshowDateYear,
  handleShowDateYear,
  showDateYear,
  setTokenCard,
}: ModalAddVisaCardProps) => {
  const ownerID = Number(localStorage.getItem("ownerID"));
  const languageUI = getLanguageCheckIn();
  const [publishKey, setpublishKey] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);

  const handleCreateToken = async (card: string | number, token: Token) => {
    setCardNumber(card);
    setTokenCard(token);
    handleClose?.(false);
  };

  const gKeyStripe = async () => {
    setShowLoading(true);
    const req = await gstripeKeyOwner(ownerID);
    if (Number(req?.status) === 1) {
      setpublishKey(req?.data?.publishKey ?? "");
    }
    setShowLoading(false);
  };

  useEffect(() => {
    if (show) {
      gKeyStripe();
    }
  }, [show]);

  return (
    <div>
      <ModalLoading show={showLoading} onHide={() => setShowLoading(false)} />
      <Sheet
        // ref={ref}
        isOpen={show}
        onClose={() => handleClose?.(false)}
        snapPoints={[1000]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <div className="d-flex justify-content-between align-items-center px-3">
            <div>
              <img
                src={arrowLeft}
                alt=""
                onClick={() => handleClose?.(false)}
              />
            </div>
            <p className="text-select-name-employee mb-0">
              {languageUI.AddVisacardinformation}
            </p>
            <div>
              <img
                src={arrowLeft}
                alt=""
                className="opacity-0 visible-hidden"
              />
            </div>
          </div>
          <Sheet.Content>
            <div className="mt-3">
              <Elements stripe={loadStripe(publishKey)}>
                <PaymentForm handleCreateToken={handleCreateToken} />
              </Elements>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          className="bg-bg-backdrop"
          onTap={() => handleClose?.(false)}
        />
      </Sheet>
    </div>
  );
};

export default ModalAddVisaCard;
