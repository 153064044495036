import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  KeyboardArrowLeftIcon,
  viewMonth,
  KeyboardArrowRightIcon,
  EastIcon,
} from "../../../../../components/Image";
import Topbar from "../../../../../layouts/Topbar";
import * as reportApi from "../../../../../api/apiOwner/reportApi";
import * as userSlice from "../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../../components/ModalLoading";
import "../Styles.css";
import moment from "moment-timezone";
import { getLanguageUI } from "../../../../../Services/languageUI";
const IncomeDetail: React.FC = () => {
  const languageUI = getLanguageUI().reportPage;
  const location = useLocation();
  const params: any = location.state;
  const monthDefault = params?.selectMonth;
  const yearDetfault = params?.countYear;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [selectMonth, setSelectMonth] = useState<number>();
  const [countYear, setCountYear] = useState<number>(
    Number(moment().format("YYYY"))
  );
  const [listMonthReport, setListMonthReport] = useState<
    reportApi.ReportIncomeByMonth[]
  >([]);
  const [listDayReport, setListDayReport] = useState<
    reportApi.ReportIncomeByDay[]
  >([]);

  const navigate = useNavigate();
  const handelSelectMonth = (index: number) => {
    setSelectMonth(index);
    gReportByMonth(countYear, index);
  };
  const handleDecrease = () => {
    setCountYear(countYear - 1);
    gReportByYear(countYear - 1);
  };
  const handleIncrease = () => {
    const checkYear = Number(moment().format("YYYY"));
    if (countYear >= checkYear) {
      return;
    }

    setCountYear(countYear + 1);
    gReportByYear(countYear + 1);
  };

  const gReportByYear = async (year: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      setListDayReport([]);
      const req = await reportApi.gReportbyYear(UserIdStore, year);
      if (Number(req?.status) === 1) {
        setListMonthReport(req?.data ?? []);
        setShowLoading(false);
        if (monthDefault) {
          handelSelectMonth(monthDefault);
        }
      } else {
        setListMonthReport([]);
        setShowLoading(false);
      }
    }
  };

  const gReportByMonth = async (year: number, month: number) => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportbyMonth(UserIdStore, year, month);
      if (Number(req?.status) === 1) {
        setListDayReport(req?.data ?? []);
        setShowLoading(false);
      } else {
        setListDayReport([]);
        setShowLoading(false);
      }
    }
  };

  const goToDetail = (dayValue: string, dayName: string) => {
    navigate("/web-owner/report/incomedatedetail", {
      state: { dayValue, dayName, countYear, selectMonth },
    });
  };

  useEffect(() => {
    if (yearDetfault) {
      gReportByYear(yearDetfault);
    } else {
      gReportByYear(countYear);
    }
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="">{/* <Topbar pageName="Report" /> */}</div>
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex justify-between  ml-12 pt-3">
            <div
              className="w-100 flex items-center py-12px"
              onClick={() => navigate("/web-owner/report")}
            >
              <div className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center mr-18px">
                <KeyboardArrowLeftIcon />
              </div>
              <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                {languageUI.detailIncome}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 body-content-incomedetail">
        <div className="m-38px bg-white radius-6">
          <div className="flex justify-center items-center pt-6">
            <div
              className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center mr-18px cursor"
              onClick={() => handleDecrease()}
            >
              <KeyboardArrowLeftIcon />
            </div>
            <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
              {countYear}
            </span>
            <div
              className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center ml-18px cursor"
              onClick={() => handleIncrease()}
            >
              <KeyboardArrowRightIcon />
            </div>
          </div>

          <div className="flex mt-10px">
            <div className="w-50 border-r-DCDCE4">
              <div className="flex justify-center">
                <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                  {languageUI.incomeByMonth}
                </span>
              </div>
              <div className="flex flex-wrap w-100 p-15 cursor gap-3">
                {listMonthReport.map((item, i) => (
                  <div
                    className={`flex items-center justify-between  p-4 w-46  radius-3 ${
                      selectMonth === Number(item.monthValue)
                        ? "border-12B064"
                        : "border-DCDCE4"
                    }`}
                    onClick={() => handelSelectMonth(Number(item.monthValue))}
                    key={`month${i}`}
                  >
                    <span className="text-6 text-4A4A6A fw-600 font-urbansist">
                      {item.monthName}
                    </span>
                    <span className="text-6 text-12B064 fw-600 font-urbansist">
                      ${item.income}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-50">
              <div className="flex justify-center">
                <span className="text-8 fw-600 font-urbansist mr-6 text-212134 pb-19">
                  {languageUI.incomeByDate}
                </span>
              </div>
              <div className="px-15 h-535px overflow-y-scroll">
                {listDayReport.length > 0 ? (
                  listDayReport.map((item, i) => (
                    <div
                      className="flex items-center justify-between  p-4 w-100 mb-6 radius-3 border-DCDCE4 cursor"
                      key={`day${i}`}
                    >
                      <span className="text-6 text-4A4A6A fw-600 font-urbansist">
                        {item.dayName}
                      </span>
                      <span className="text-6 text-12B064 fw-600 font-urbansist flex items-center">
                        ${item.income}
                        {Number(item.income) > 0 && (
                          <div
                            className="bg-white border-0FA54A ml-2 radius-6px flex items-center p-overal cursor"
                            onClick={() =>
                              goToDetail(item?.dayValue, item?.dayName)
                            }
                          >
                            <span className="text-17px text-12B064 font-urbansist">
                              {languageUI.detail}
                            </span>
                            <EastIcon className="ml-2 text-12B064 text-4" />
                          </div>
                        )}
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center w-100">
                    <img src={viewMonth} alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeDetail;
