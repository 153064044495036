import * as Request from "../Request";

export interface Result<T> {
    status: number,
    message: string,
    data: T,
}

export interface AccountHandheld {
    ha_id: number,
    merchant_id: string,
    authorization: string,
    date_add: string
}
export interface HandheldDevice {
    hsn_id: number,
    ha_id: number,
    hsn_name: string,
    hsn_key: string,
    date_add: string
}
export interface DefaultHsn {
    hsn_id: number,
    id: number
}

export const gAcountUATHandheld = async (
    owner_id: number,
) => {
    return await Request.fetchAPI(`owner/gAcountUATHandheld.php`, "POST", {
        owner_id,
    })
        .then((res: Result<AccountHandheld>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};
export const addAcountUATHandheld = async (owner_id: number, merchant_id: string, authorization: string) => {
    return await Request.fetchAPI(`owner/addAcountUATHandheld.php`, "POST", {
        owner_id, merchant_id, authorization
    })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};
export const editAcountUATHandheld = async (owner_id: number, merchant_id: string, authorization: string, ha_id: number) => {
    return await Request.fetchAPI(`owner/editAcountUATHandheld.php`, "POST", {
        owner_id, merchant_id, authorization, ha_id
    })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};

export const gListHandheldDevice = async (
    owner_id: number, ha_id: number
) => {
    return await Request.fetchAPI(`owner/gListHandheldDevices.php`, "POST", {
        owner_id, ha_id
    })
        .then((res: Result<HandheldDevice[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};
export const addHandheldDevice = async (
    owner_id: number, ha_id: number, hsn_name: string, hsn_key: string, pos_id: number, is_default: boolean = false
) => {
    return await Request.fetchAPI(`owner/addHandheldDevice.php`, "POST", {
        owner_id, ha_id, hsn_name, hsn_key, pos_id, is_default: is_default ? 1 : 0
    })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
            };
        });
};
export const editHandheldDevice = async (
    owner_id: number, ha_id: number, hsn_id: number, hsn_name: string, hsn_key: string
) => {
    return await Request.fetchAPI(`owner/editHandheldDevice.php`, "POST", {
        owner_id, ha_id, hsn_name, hsn_key, hsn_id
    })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
            };
        });
};
export const deleteHandhelđevice = async (
    owner_id: number, hsn_id: number
) => {
    return await Request.fetchAPI(`owner/deleteHandheldDevice.php`, "POST", {
        owner_id, hsn_id
    })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
            };
        });
};
export const gDefaultHsnByPos = async (
    owner_id: number, pos_id: number
) => {
    return await Request.fetchAPI(`owner/gDefaultHsnByPos.php`, "POST", {
        owner_id, pos_id
    })
        .then((res: Result<DefaultHsn>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};
export const setDefaultHsnByPos = async (
    owner_id: number, pos_id: number, hsn_id: number
) => {
    return await Request.fetchAPI(`owner/setDefaultHsnByPos.php`, "POST", {
        owner_id, pos_id, hsn_id
    })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: ''
            };
        });
};