import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Request from "../../api/Request";
import * as userSlice from "../../store/slices/userSlice";
import ModalNotFoundOwner from "./components/ModalNotFoundOwner/ModalNotFoundOwner";
import { useSelector } from "react-redux";


const SendMenuToPhone = () => {
    const owner_id = useSelector(userSlice.selectorUserId);
    const navigate = useNavigate()
    const [showModalNotFoundOwner, setShowModalNotFoundOwner] = useState<any>("");
    let params = useParams();
    let id_owner = params?.ownerCode;
    const dispatchUser = useDispatch();
    const ReduxStoreUser = async (id: number, role: number, nameStore: string = '', user_name: string = '', tax: number = 0, address: string = '', phone: string = '') => {
        await dispatchUser(userSlice.setUserId(id));
        await dispatchUser(userSlice.setRole(role));
        await dispatchUser(userSlice.setNameStore(nameStore));
        await dispatchUser(userSlice.setUserName(user_name));
        await dispatchUser(userSlice.setTax(tax));
        await dispatchUser(userSlice.setAddress(address));
        await dispatchUser(userSlice.setPhoneNumber(phone));

    };
    const GetInfoOwner = async () => {
        await Request.fetchAPI("owner/gInfoOwner.php?id=" + owner_id, "GET", {})
            .then(async (result: any) => {
                if (Number(result?.status) === 1) {
                    if (Number(result?.data?.role) === 0) {
                        await dispatchUser(userSlice.setUserId(0));
                        await dispatchUser(userSlice.setUserName(""));
                        // navigate("/web-admin/account-staff");
                        setShowModalNotFoundOwner(true)
                    } else if (Number(result?.data?.role) === 1) {
                        navigate("/web-owner/qrcode");
                    }
                    // console.log("Thông tin Owner", result?.data);
                } else {

                    setShowModalNotFoundOwner(true)
                }
            })
            .catch((err: any) => {

                setShowModalNotFoundOwner(true)
            });

    };
    const getInfo = async () => {
        if (id_owner) {
            await Request.fetchAPI("owner/getInfoOwnerByCode.php", "post", {
                id_owner
            })
                .then((result: any) => {
                    if (Number(result?.status) === 1) {
                        //đăng nhập thành công
                        ReduxStoreUser(result?.data?.id, result?.data?.role, result?.data?.name, result?.data?.username, result?.data?.tax, result?.data?.address, result?.data?.phone);
                        // console.log("đăng nhập thành công");
                    } else {
                        // window.location.href = "https://brijackposadmin.com/";
                        setShowModalNotFoundOwner(true)
                    }
                })
                .catch((err: any) => {
                    // window.location.href = "https://brijackposadmin.com/";
                    setShowModalNotFoundOwner(true)

                });
        } else {

            setShowModalNotFoundOwner(true)

        }

    }
    useEffect(() => {
        getInfo()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (owner_id && owner_id != null && owner_id > 0) {
            localStorage.setItem('is_ownerMenu', '1');
            localStorage.setItem('isOwner', '1');
            GetInfoOwner();

            // navigate("/web-owner/qrcode");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner_id]);
    useEffect(() => {
        if (showModalNotFoundOwner) {
            setTimeout(() => {
                setShowModalNotFoundOwner(false)
                // window.location.href = "https://brijackposadmin.com/";
            }, 3000)
        }
    }, [showModalNotFoundOwner])
    return (
        <div className="w-100vw h-100vh bg-gradient-go-to-owner">
            <div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
            </div>
            <ModalNotFoundOwner show={showModalNotFoundOwner} />
        </div>
    );
};

export default SendMenuToPhone;
