import '../../Styles.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, Radio, RadioGroup } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { HappyDay, HappyRepeat, ListDay, ListFood, gComboHappyDay, gComboHappyRepeat, gInfosettingHappyHour, saveSettingHappyHour } from '../../../../api/apiOwner/HappyHour';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import * as userSlice from "../../../../store/slices/userSlice";
import moment from 'moment';
import { formatDate } from '../../../../utils/format';
import FoodSelectItem from './component/FoodSelectItem/FoodSelectItem';
import ModalLoading from '../../../../components/ModalLoading';
import { getLanguageUI } from '../../../../Services/languageUI';
import { useDispatch } from 'react-redux';
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
import Swal from "sweetalert2";
interface ConectStripeProp {
  ChangeSetting: () => void
}
const HappyHour: React.FC<ConectStripeProp>  = ({ChangeSetting}) => {
  const languageUI = getLanguageUI().setting
  const dispatch = useDispatch()
  const [listHappyDay, setListHappyDay] = useState<HappyDay[]>([])
  const [listHappyRepeat, setListHappyRepeat] = useState<HappyRepeat[]>([])
  const [valueTimeEnd, setValueTimeEnd] = useState<dayjs.Dayjs | null | any>('')
  const [valueTimeStart, setValueTimeStart] = useState<dayjs.Dayjs | null | any>('')
  const owner_id = useSelector(userSlice.selectorUserId);
  const [listFoodHappyHour, setListFoodHappyHour] = useState<ListFood[]>([])
  const [listDayHappyHour, setListDayHappyHour] = useState<ListDay[]>([])
  const [repeatId, setrepeatId] = useState(0)
  const [discountFood, setDiscountFood] = useState<number>()
  const [statusDiscount, setStatusDiscount] = useState<number>(0)
  const [showModalLoading, setShowModalLoading] = useState(false)

  const comboHappyDay = async () => {
    const res = await gComboHappyDay()
    if (Number(res.status) === 1) {
      setListHappyDay(res.data ?? [])
    }
  }
  const comboHappyRepeat = async () => {
    const res = await gComboHappyRepeat()
    if (Number(res.status) === 1) {
      setListHappyRepeat(res.data ?? [])
    }
  }
  const infoHappyHour = async () => {
   
    if (owner_id) { 
      setShowModalLoading(true)
      const res = await gInfosettingHappyHour(owner_id)
      if (Number(res.status) === 1) {
        setListFoodHappyHour(res.listFood)
        setListDayHappyHour(res.listDay)
        setrepeatId(Number(res.repeat_id))
        setDiscountFood(res.data?.discount ? Number(res.data?.discount) : undefined)
        setValueTimeStart(res.data?.timestart ? (dayjs(moment(new Date()).format("YYYY-MM-DD") + ' ' + moment(res.data?.timestart, "hh:mma").format("HH:mm"))) : '')
        setValueTimeEnd(res.data?.timeend ? (dayjs(moment(new Date()).format("YYYY-MM-DD") + ' ' + moment(res.data?.timeend, "hh:mma").format("HH:mm"))) : '')
        setStatusDiscount(Number(res.data?.status))
        setShowModalLoading(false)
      }else{
        setShowModalLoading(false)
      }

    }
  }
  const changeDiscountFood = (foodId: string, value: string) => {
    setListFoodHappyHour(listFoodHappyHour.map((item) => {
      return Number(item.id) === Number(foodId) ? { ...item, discount: Number(value) } : item;
    }))
  }
  const changeDayHappyHour = (dayId: string, checked: boolean) => {
    setShowModalLoading(true)
    if (checked) {
      setListDayHappyHour([...listDayHappyHour, { id: dayId }])
      setShowModalLoading(false)

    } else {
      setListDayHappyHour(listDayHappyHour.filter(item => Number(item.id) !== Number(dayId)))
      setShowModalLoading(false)
    }
  }
  const handleSubmitHappyHour = async () => {
    
    if (owner_id) {
      setShowModalLoading(true)
      const lisDay = listDayHappyHour.map(item => {
        return item.id
      })
      let timeStart = moment(formatDate(valueTimeStart?.$d)).format("hh:mm A")
      let timeEnd = moment(formatDate(valueTimeEnd?.$d)).format("hh:mm A")
      const listFood = listFoodHappyHour.filter(item => Number(item.checked) === 1)
      const res = await saveSettingHappyHour(owner_id, repeatId, timeStart, timeEnd, statusDiscount, discountFood ?? 0, lisDay, listFood)
      if (Number(res.status) === 1) {
        setShowModalLoading(false)
        dispatch(flagSlice.clearSetting())
      }else{
        Swal.fire({
          position: "center",
          icon: "warning",
          title: res?.message??"fail",
          showConfirmButton: false,
          timer: 2000,
        });
        setShowModalLoading(false)

      }
    }
  }
  const handleChangeSelectFood = (foodId: string, checked: boolean) => {
    setListFoodHappyHour(listFoodHappyHour.map((item) => {
      return Number(item.id) === Number(foodId) ? { ...item, checked: checked ? 1 : 0 } : item;
    }))
  }

  useEffect(() => {
    comboHappyDay()
    comboHappyRepeat()
    infoHappyHour()
    console.log(statusDiscount);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <ModalLoading show={showModalLoading} onHide={() => setShowModalLoading(false)} />
      <div className='box-connect-stripe px-5 box-happy-hour'>
        <div className='d-flex align-items-center'>
          <div className='w-200px'>
            <p className='mb-0 font-bold text-16px text-4a4a6a'>{languageUI.chooseHappyHourDay}</p>
          </div>
          <div className='flex align-items-center gap-3'>
            {listHappyDay.map((item, index) => (
              <FormControlLabel
                key={`happy-day-${item.id}`}
                control={
                  <Checkbox className='custom-radio-circle text-primary' checked={listDayHappyHour.some(val => Number(val.id) === Number(item.id))} onChange={(e) => { changeDayHappyHour(item.id, e.target.checked);ChangeSetting() }} value={item.value} />
                }
                label={item.name}
              />
            ))}

            {/* <FormControlLabel
              control={
                <Checkbox className='custom-radio-circle text-primary' />
              }
              label="Tueday"
            />
            <FormControlLabel
              control={
                <Checkbox className='custom-radio-circle text-primary' />
              }
              label="Webnesday"
            />
            <FormControlLabel
              control={
                <Checkbox className='custom-radio-circle text-primary' />
              }
              label="Thurday"
            />
            <FormControlLabel
              control={
                <Checkbox className='custom-radio-circle text-primary' />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox className='custom-radio-circle text-primary' />
              }
              label="Saturday"
            /> */}
          </div>
        </div>
        <div className='d-flex align-items-center mt-1'>
          <div className='w-200px'>
            <p className='mb-0 font-bold text-16px text-4a4a6a'>{languageUI.Repeat}</p>
          </div>
          <div className='flex align-items-center gap-3'>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              value={repeatId}
              onChange={(e) => {setrepeatId(Number(e.target.value));ChangeSetting()}}
              name="radio-buttons-group"
              className='d-flex gap-3 items-center'
            >
              {listHappyRepeat.map((item, index) => (
                <FormControlLabel control={
                  <Radio
                    value={Number(item.id)}
                    sx={{
                      color: '#00A15A',
                      '&.Mui-checked': {
                        color: '#00A15A',
                      },
                    }}
                  />}
                  label={item.name}
                />
              ))}
            </RadioGroup>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='w-200px'>
            <p className='mb-0 font-bold text-16px text-4a4a6a'>{languageUI.chooseHappyHourTime}</p>
          </div>
          <div className='mt-2 d-flex gap-3 align-items-center'>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={[
                'TimePicker',
              ]}>
                <DemoItem>
                  <DesktopTimePicker ampm className="w-fit bg-white rounded-3" value={valueTimeStart} onChange={(newValue) => {setValueTimeStart(newValue);ChangeSetting()}} />
                </DemoItem>
              </DemoContainer>

            </LocalizationProvider>
            <div className=''>
              <p className='mb-0 font-bold text-16px text-4a4a6a'>{languageUI.to}</p>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DemoContainer components={[
                'TimePicker',
              ]}>
                <DemoItem>
                  <DesktopTimePicker ampm className="w-fit bg-white rounded-3" value={valueTimeEnd} onChange={(newValue) => {setValueTimeEnd(newValue);ChangeSetting()}} />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <div className='d-flex align-items-start w-100'>
          <div className='w-200px'>
            <p className='mb-0 font-bold text-16px text-4a4a6a mt-2'>{languageUI.chooseFood}</p>
          </div>
          <div className='mt-2 flex gap-3 w-100'>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={statusDiscount}
              name="choose-food-group flex-shink-0"
              className='w-200px'
            >
              <FormControlLabel value={0} control={
                <Radio sx={{
                  color: '#00A15A',
                  '&.Mui-checked': {
                    color: '#00A15A',
                  },
                }}
                  onChange={(e) => {
                    setStatusDiscount(0);ChangeSetting()
                  }}
                />}
                label={languageUI.chooseAllFood} />
              <FormControlLabel value={1} control={<Radio sx={{
                color: '#00A15A',
                '&.Mui-checked': {
                  color: '#00A15A',
                },
              }}
                onChange={(e) => {
                  setStatusDiscount(1);ChangeSetting();
                }}
              />} label={languageUI.custom} />
            </RadioGroup>
            {statusDiscount === 0 ? (
              <div className='d-flex align-items-center gap-2 h-fit'>
                <input type="text" className='px-2 py-1 rounded-3 input-choose-discount fous-visiable-none w-100px' value={discountFood} onChange={(e) => {setDiscountFood(Number(e.target.value));ChangeSetting()}} />
                <p className='mb-0 font-bold text-16px text-4a4a6a'>% Discount</p>
              </div>
            ) : (
              <div className='w-full box-select-food-happy-hour pb-4'>
                <p className='mb-0 font-bold text-20px text-4a4a6a text-center mt-2'>{languageUI.selectFood}</p>
                <div className='flex flex-column align-items-center mt-2 check-box-start'>
                  {listFoodHappyHour.map((item, index) => (
                    <div key={`list-food-select-${item.id}`}>
                      <FoodSelectItem id={item.id} changeDiscountFood={changeDiscountFood} checked={item.checked} discount={item.discount} name={item.name} handleChangeSelectFood={handleChangeSelectFood} ChangeSetting={ChangeSetting}/>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex w-100 justify-content-end mt-2'>
          <button className="border-none bg-primary w-150px rounded-4 shadow-button-default text-white font-bold h-40px" onClick={handleSubmitHappyHour}>
            {languageUI.save}
          </button>
        </div>
      </div>
      {/* <button className="custom-btn-save-setting-information" onClick={handleSubmitHappyHour} >
        save
      </button> */}
    </div>
  )
}
export default HappyHour