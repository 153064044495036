import * as Request from "../Request";

export interface Result {
  status: number;
  message?: string;
  role?: string;
}
export interface Data<T> {
  status: number;
  message: string;
  data: T;
}
export interface StaffJoin {
  id: number;
  staff_name: string;
}
export const checkPasscode = async (owner_id: number, passCode: string) => {
  return await Request.fetchAPI(`owner/check-passcode-owner.php`, "POST", {
    owner_id,
    passCode,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        role: "",
      };
    });
};
export const getStaffByPasscode = async (
  owner_id: number,
  passCode: string
) => {
  return await Request.fetchAPI(`owner/gStaffByPasscode.php`, "POST", {
    owner_id,
    passCode,
  })
    .then((res: Data<StaffJoin>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const checkPasscodeStaffWorkTime = async (
  owner_id: number,
  passCode: string,
  staff_id: string
) => {
  return await Request.fetchAPI(
    `owner/check-passcode-staff-work-time.php`,
    "POST",
    { owner_id, passCode, staff_id }
  )
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        role: "",
      };
    });
};
