import { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useNavigate } from 'react-router-dom';

const WaitCheckin = () => {
    const navigate = useNavigate();
    const custommerId = localStorage.getItem("custommerId");
    const [now, setNow] = useState(30)
    useEffect(() => {
        if (now > 125) {
            navigate("/webcheckin/choosemenu/" + custommerId)
        } else {
            setTimeout(() => {
                setNow(now + 25)
            }, 1000);
        }

    }, [now])
    return (
        <>
            <div className='w-100 h-100 bg-wait-screen'>
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ minHeight: "100vh" }}>
                    <h3 className="name-store text-white ">
                        Your brandname
                    </h3>
                    <ProgressBar variant="warning" now={now} className="w-50" />
                </div>
            </div>

        </>
    )
}

export default WaitCheckin