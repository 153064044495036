import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Request from "../../api/Request";
import Swal from "sweetalert2";
// import * as ForgotPasswordAdminAPI from "../../api/apiAdmin/ForgotPassword";

// components
import { VerticalForm } from "../../components";
import AuthLayout from "./AuthLayout";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const ForgetPasswordAdmin = () => {
  const languageUI = getLanguageCheckIn();
  const [email, setEmail] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const [checkCodeErr, setCheckCodeErr] = useState(false);
  const [checkCodeErrMess, setCheckCodeErrMess] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const getCode = async () => {
    if (email === "") {
      setCheckError(true);
      setErMess(languageUI.Pleaseenteryouremail);
      return;
    }
    if (mailformat.test(email) === false) {
      setCheckError(true);
      setErMess(languageUI.Emailyouenteredisnotintheformat);
      return;
    }
    setCheckError(false);
    setErMess("");
    setShowCodeInput(true);

    await Request.fetchAPI(`/admin/forget_password.php`, "post", {
      username: email,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          console.log("okee", res);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const checkCodeForgotPW = async () => {
    if (email === "") {
      setCheckError(true);
      setErMess(languageUI.Pleaseenteryouremail);
      return;
    }
    if (mailformat.test(email) === false) {
      setCheckError(true);
      setErMess(languageUI.Emailyouenteredisnotintheformat);
      return;
    }
    if (checkCode === "") {
      setCheckCodeErr(true);
      setCheckCodeErrMess(languageUI.Pleaseentercodegetfromyouremail);
      return;
    }

    await Request.fetchAPI(`admin/check_code.php`, "post", {
      username: email,
      code: checkCode,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: languageUI.ForgotPasswordSuccess,
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/web-admin/login");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: languageUI.SomethingwentwrongPleasetryagainlater,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: languageUI.SomethingwentwrongPleasetryagainlater,
          showConfirmButton: false,
          timer: 2500,
        });
      });
    setCheckError(false);
    setErMess("");
    setCheckCodeErr(false);
    setCheckCodeErrMess("");
  };

  return (
    <>
      <AuthLayout helpText={t(languageUI.ForgetYourPassword)} bottomLinks={""}>
        <VerticalForm onSubmit={checkCodeForgotPW}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              type="email"
              placeholder={languageUI.EnteryourEmail}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <p style={{ color: "#f1556c", textAlign: "center" }}>
              {checkError ? errMess : ""}
            </p>{" "}
            {showCodeInput ? (
              <>
                <Form.Label>{languageUI.Code}</Form.Label>
                <Form.Control
                  className="mb-2"
                  name="code"
                  type="text"
                  placeholder={languageUI.Entercode}
                  value={checkCode}
                  onChange={(e: any) => setCheckCode(e.target.value)}
                />
                <p style={{ color: "#f1556c", textAlign: "center" }}>
                  {checkCodeErr ? checkCodeErrMess : ""}
                </p>{" "}
                <p>{languageUI.WehavesentthecodetoyouremailChecktogetit}</p>
              </>
            ) : null}
            <Button onClick={getCode}>{languageUI.GetCode}</Button>
          </Form.Group>

          <div className="text-center d-grid">
            <Button variant="primary" type="submit" onClick={checkCodeForgotPW}>
              {t(languageUI.ResetPassword)}
            </Button>
            <Link to="/web-admin/login" className="mt-2">
              {languageUI.Login}
            </Link>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default ForgetPasswordAdmin;
