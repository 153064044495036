import * as Request from "../Request";
import { InfoOrder } from "./orderConfilmApi";

export interface listSide {
    side_id: string,
    quantity: string,
}
export interface itemDoordash {
    name: string,
    quantity: number,
    description: string,
    external_id: string
}
export interface DoordashOrder {
    external_delivery_id: string,
    currency: string,
    delivery_status: string,
    fee: number,
    pickup_address: string,
    pickup_business_name: string,
    pickup_phone_number: string,
    pickup_instructions: string,
    pickup_external_business_id: string,
    pickup_external_store_id: string,
    dropoff_address: string,
    dropoff_business_name: string,
    dropoff_phone_number: string,
    dropoff_instructions: string,
    order_value: number,
    items: itemDoordash[],
    updated_at: string,
    pickup_time_estimated: string,
    dropoff_time_estimated: string,
    tax: number,
    support_reference: string,
    tracking_url: string,
    contactless_dropoff: boolean,
    action_if_undeliverable: string,
    tip: number,
    dropoff_requires_signature: boolean,
    dropoff_cash_on_delivery: number
}

export interface dataReq<T> {
    status: number,
    message: string,
    data: T,
    orderCode: string,
    delivery_fee: string
}


export const gFeeOrder = async (customer_id: number, owner_id: number, dropoff_address: string, dropoff_business_name: string, dropoff_phone_number: string, cardInfor: InfoOrder[], totalPrice: number, sideDish: listSide[] = []) => {
    return await Request.fetchAPI(`checkin/gFeeDoordash.php`, "POST", { customer_id, owner_id, dropoff_address, dropoff_business_name, dropoff_phone_number, cartInfo: JSON.stringify(cardInfor), totalPrice, sideDish: JSON.stringify(sideDish) })
        .then((res: dataReq<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
                orderCode: '',
                delivery_fee: ''

            };
        });
};

export const acceptOrderDoordash = async (dropoff_phone_number: string, maHD: string) => {
    return await Request.fetchAPI(`checkin/acceptOrderDoordash.php`, "POST", { dropoff_phone_number, maHD })
        .then((res: dataReq<DoordashOrder>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};