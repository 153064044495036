import { Modal } from "react-bootstrap";
import "./Styles.css";
import {
  handClick,
  handheldImg,
  leaf1,
  leaf2,
} from "../../../../components/ImgExport";
import OtpInput from "react18-input-otp";
import { useEffect, useState } from "react";
import * as PasscodeApi from "../../../../api/apiOwner/PasscodeApi";
import ModalLoading from "../../../../components/ModalLoading";
import $ from "jquery";
import { getLanguageUI } from "../../../../Services/languageUI";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { HandheldDevices } from "../../../../api/apiOwner/cardPointUATApi";

interface ModalChangeTerminalProps {
  show: boolean;
  listDevices: HandheldDevices[];
  setHsnID: (hsn: number) => void;
  handleClose: (status?: boolean) => void;
}

const ModalChangeTerminal = ({
  show,
  listDevices = [],
  setHsnID,
  handleClose,
}: // setIsOwner
ModalChangeTerminalProps) => {
  const [selectHandheld, setSelectHandheld] = useState<number>(0);

  const applyHsn = () => {
    if (selectHandheld > 0) {
      setHsnID(selectHandheld);
      handleClose();
    }
  };
  return (
    <>
      {/* <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} /> */}
      <Modal
        show={show}
        onHide={handleClose}
        className=" border-modal-16px modal-clock-in-out"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body className="p-0">
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="text-center position-relative z-10">
                <p className="text-delete-modal-customer mb-1 text-center px-4">
                  Select The Terminal you want to use
                </p>
                <div className="list-handheld-machine mt-3">
                  {/* Map danh sách handheld ở đây */}
                  {listDevices.map((items, i) => (
                    <div
                      key={i}
                      className={`w-120px rounded-5 p-3 ${
                        selectHandheld === items?.hsn_id
                          ? "border-none bg-orange"
                          : "border-4a4a6a"
                      }`}
                      onClick={() => setSelectHandheld(items?.hsn_id)}
                    >
                      <img src={handheldImg} alt="" className="w-100" />
                      <p
                        className={`mt-2 font-bold text-center   mb-0 ${
                          selectHandheld === items?.hsn_id
                            ? "text-white"
                            : "text-4a4a6a"
                        }`}
                      >
                        {items?.hsn_name}
                      </p>
                    </div>
                  ))}
                </div>

                <button
                  className="border-none text-white font-bold bg-primary h-40px w-150px rounded-3 mt-3"
                  onClick={applyHsn}
                >
                  Apply
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalChangeTerminal;
