// import axiosClient, { ResponseSuccess } from "../axiosClient";
import * as Request from "../Request";

export const ListStaff = async () => {
  return await Request.fetchAPI("admin/gListStaff.php", "GET", {})
    .then((res: Request.Response<IListAccountStaff[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const InfoStaff = async (id: number) => {
  return await Request.fetchAPI("admin/gInfoAdmin.php?id=" + id, "GET", {})
    .then((res: Request.Response<IAccountStaff>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};

export const DeleteStaff = async (id: number) => {
  return await Request.fetchAPI("admin/deleteStaff.php", "post", {
    id_staff: id,
  })
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const EditStaff = async (
  UserIdStore: number,
  id: number,
  name: string,
  phone: string,
  permission: number
) => {
  return await Request.fetchAPI("admin/editStaffV2.php", "post", {
    admin_id: UserIdStore,
    id_staff: id,
    name: name,
    phone: phone,
    permission: permission,
  })
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const ChangeStatic = async (UserIdStore: number, id_staff: number) => {
  return await Request.fetchAPI("admin/updateStatusStaff.php", "post", {
    admin_id: UserIdStore,
    id_staff: id_staff,
  })
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gComboPermissionAdmin = async () => {
  return await Request.fetchAPI("admin/gComboPermissionAdmin.php", "post", {
  })
    .then((res: Request.Response<PermissionAdmin[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const AddStaff = async (
  IDAdmin: number,
  EmailAdd: string,
  PasswordAdd: string,
  NameAdd: string,
  PhoneAdd: string,
  PermissionAdd: number
) => {
  return await Request.fetchAPI("admin/addStaffv2.php", "post", {
    admin_id: IDAdmin,
    email: EmailAdd,
    password: PasswordAdd,
    name: NameAdd,
    phone: PhoneAdd,
    permission: PermissionAdd,
  })
    .then((res: Request.Response<[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export interface aaa {
  data: IAccountStaff[];
  totalRow?: number;
}

export interface IListAccountStaff {
  userID?: number;
  email?: string;
  name?: string;
  phone?: string;
  payment_status?: number;
  status?: number;
}

export interface IAccountStaff {
  // status?: number;
  email?: string;
  name?: string;
  phone?: string;
  role?: string;
  permission?: string;
  permission_id?: string
}

export interface PermissionAdmin {
  permission_id: string;
  label: string;
}