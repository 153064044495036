import "./styles.css";

import gsap from "gsap";

import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { gConfigGame, gGameRoundCustomer, updateScoreGame } from "../../../../api/apiCheckin/gameApi";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface GameProps {
  checkPoints: boolean
}
const TIME_LIMIT = 30000;
const MOLE_SCORE = 10;
const POINTS_MULTIPLIER = 0.9;
const TIME_MULTIPLIER = 1.25;

function Moles({ children }: { children: any }) {
  return <div className="body-game">{children}</div>;
}
const languageUI = getLanguageCheckIn();
const Mole = ({ points, delay, speed, onWhack, pointsMin = 5 }: any) => {
  const buttonRef = useRef(null);
  const bobRef: any = useRef<gsap.core.Tween | null>(null);
  const pointsRef = useRef(points);
  const [whacked, setWhacked] = useState(false);

  const whack = () => {
    setWhacked(true);
    onWhack(pointsRef.current);
  };

  useEffect(() => {
    gsap.set(buttonRef.current, {
      yPercent: 100,
      display: "block"
    });
    bobRef.current = gsap.to(buttonRef.current, {
      yPercent: 0,
      duration: speed,
      yoyo: true,
      repeat: -1,
      delay,
      repeatDelay: delay,
      onRepeat: () => {
        pointsRef.current = Math.floor(
          Math.max(pointsRef.current * POINTS_MULTIPLIER, pointsMin)
        );
      }
    });

    return () => {
      bobRef.current?.kill();
    };
  }, [delay, speed, pointsMin]);

  useEffect(() => {
    if (whacked) {
      pointsRef.current = points;
      bobRef.current.pause();
      gsap.to(buttonRef.current, {
        yPercent: 100,
        duration: 0.1,
        onComplete: () => {
          gsap.delayedCall(gsap.utils.random(1, 3), () => {
            setWhacked(false);
            bobRef.current
              .restart()
              .timeScale(bobRef.current?.timeScale() * TIME_MULTIPLIER);
          });
        }
      });
    }
  }, [whacked]);

  return (
    <div className="mole-hole">
      <button className="mole" ref={buttonRef} onClick={whack}>
        <span className="sr-only">Whack</span>
      </button>
    </div>
  );
};
const Score = ({ value }: any) => (
  <div className="info-text">{`${languageUI.Score}: ${value}`}</div>
);

const Timer = ({ time, interval = 1000, onEnd }: any) => {
  const [internalTime, setInternalTime] = useState(time);
  const timerRef = useRef(time);
  const timeRef = useRef(time);

  useEffect(() => {
    if (internalTime === 0 && onEnd) onEnd();
  }, [internalTime, onEnd]);

  useEffect(() => {
    timerRef.current = setInterval(
      () => setInternalTime((timeRef.current -= interval)),
      interval
    );
    return () => clearTimeout(timerRef.current);
  }, [interval]);

  return <span className="info-text">{`${languageUI.Time}: ${internalTime / 1000}s`}</span>;
};

const Game = ({ checkPoints }: GameProps) => {
  const custommerId = localStorage.getItem("custommerId");
  const [playing, setPlaying] = useState(false);
  const [finished, setFinished] = useState(false);
  const [score, setScore] = useState(0);
  const [playFlappyWhackAMole, setPlayWhackAMole] = useState(0)
  const navigate = useNavigate();

  const generateMoles = () =>
    new Array(9).fill(0).map(() => ({
      speed: gsap.utils.random(0.2, 0.8),
      delay: gsap.utils.random(0.5, 2),
      points: MOLE_SCORE
    }));

  const [moles, setMoles] = useState(generateMoles());

  const getGameRoundCustomer = async () => {
    if (custommerId) {
      const req = await gGameRoundCustomer(custommerId)
      if (Number(req.status) === 1) {
        setPlayWhackAMole(Number(req.data?.plays_whackamole) ?? 0)

      }
    }
  }

  const endGame = async () => {
    setPlayWhackAMole(playFlappyWhackAMole - 1)
    setPlaying(false);
    setFinished(true);
    if (custommerId) {
      const req = await updateScoreGame(custommerId, 1, score);//1 là game đập chuột
      if (Number(req.status) === 1) {
        getGameRoundCustomer()
      }
    }



  };

  const startGame = () => {
    if (playFlappyWhackAMole > 0) {
      setPlayWhackAMole(playFlappyWhackAMole - 1)
      setScore(0);
      setMoles(generateMoles());
      setPlaying(true);
      setFinished(false);
    }
  };

  const onWhack = (points: number) => {
    setScore(score + points);
  }

  useEffect(() => {
    getGameRoundCustomer()
  }, [])
  return (
    <div className="body-Whack">
      {!playing && !finished && (
        <>
          <p className="text-title-mole">Whack-a-mole</p>
          {playFlappyWhackAMole > 0 && (
            <button className="btn-start-game w-200px" onClick={startGame}>{languageUI.Startgame}</button>
          )}
          <button className="btn-exit-game mt-2 w-200px" onClick={() => navigate(-1)}>{languageUI.Exit}</button>
        </>
      )}
      {playing && (
        <>
          <button className="end-game" onClick={endGame}>
            {languageUI.Endgame}
          </button>
          <Score value={score} />
          <Timer time={TIME_LIMIT} onEnd={endGame} />
          <Moles>
            {moles.map(({ speed, delay, points }, id) => (
              <Mole
                key={id}
                onWhack={onWhack}
                points={points}
                delay={delay}
                speed={speed}
              />
            ))}
          </Moles>
        </>
      )}
      {finished && (
        <>
          <Score value={score} />
          {playFlappyWhackAMole > 0 && (
            <button onClick={startGame} className="btn-play-again w-200px">{languageUI.Playagain}</button>
          )}

          <button className="btn-exit-game mt-2 w-200px" onClick={() => navigate(-1)}>{languageUI.Exit}</button>
        </>
      )}
    </div>
  );
};

export default function App() {
  const [checkPoints, setCheckPoints] = useState(false)
  const ownerID = localStorage.getItem("ownerID");

  const gConfig = async () => {
    if (ownerID) {

      const req = await gConfigGame(ownerID)
      if (Number(req?.status) === 1 && Number(req?.data?.is_show_redemption_game) === 1) {
        setCheckPoints(true)
      } else {
        setCheckPoints(false)
      }
    } else {
      setCheckPoints(false)
    }

  }
  useEffect(() => {

    gConfig()
  }, [])
  return <Game checkPoints={checkPoints} />;
}
