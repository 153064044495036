import { Modal } from "react-bootstrap";
import "./Styles.css";
import {
  leaf1,
  leaf2,
  trashBlack,
  trashRed,
} from "../../../../../../components/ImgExport";
import { useState } from "react";
import { DeleteVoucher } from "../../../../../../api/apiOwner/voucherApi";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface ModalDeleteVoucherProps {
  show: boolean;
  HandleDelSuccess: () => void;
  voucherID: string;
  handleClose: () => void;
  setShowLoading: (e: boolean) => void;
}

const ModalDeleteVoucher = ({
  show,
  HandleDelSuccess,
  voucherID = "",
  handleClose,
  setShowLoading,
}: ModalDeleteVoucherProps) => {
  const languageUI = getLanguageUI().voucherPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [messageError, setMessageError] = useState<string>("");

  const DelVoucher = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await DeleteVoucher(voucherID, UserIdStore);
      if (Number(req?.status) === 1) {
        handleClose();
        HandleDelSuccess();
      } else {
        setShowLoading(false);
        setMessageError(req?.message ?? "delete failed");
      }
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-delete-voucher"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body>
            <div className="position-relative px-3">
              <div className="d-flex align-items-center gap-2px">
                <img src={trashBlack} alt="" />
                <p className="text-delete-modal-customer mb-0">
                  {languageUI.delete}
                </p>
              </div>
              <p className="text-content-modal-delete-customer mt-2">
                {languageUI.areYouWantDelete}
              </p>
              <div className="text-center text-danger text-italic">
                {messageError}
              </div>
              <div className="d-flex justify-content-between">
                <div
                  className="btn-cancel-modal-delete-customer bg-white"
                  onClick={handleClose}
                >
                  {languageUI.cancel}
                </div>
                <div
                  className="btn-cancel-modal-delete-customer border-delete bg-white"
                  onClick={DelVoucher}
                >
                  <img src={trashRed} alt="" className="" />
                  <p className="mb-0 text-delete-modal-customer-btn">
                    {languageUI.delete}
                  </p>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1" />
              <img src={leaf2} alt="" className="img-leaf2" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalDeleteVoucher;
