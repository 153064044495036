import Select from "react-select";

import { useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import './Styles.css'
import MaskedInput from "react-text-mask";
import * as CreateCustomerAdminAPI from "../../../../api/apiAdmin/CreateCustomerAdmin";
import Swal from "sweetalert2";
import ModalLoading from "../../../../components/ModalLoading";
import { useLocation, useNavigate } from "react-router-dom";
import { ComboOption, gComboTimeZone } from "../../../../api/apiOwner/comboApi";
import moment from "moment-timezone";
import PaymentFormLandingPage from "./Component.tsx/PaymentFormLandingPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { gConfigStripeAdmin } from "../../../../api/apiAdmin/ConfigSetting";
import PlaceIcon from '@mui/icons-material/Place';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";



const CreatFormLandingPage: React.FC = () => {
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = location.state;
  const packageId = params?.id;
  const owner_id = params?.owner_id;
  const ListDevices = params?.ListDevices;
  const packName = params?.packName;
  const totalPrice = params?.price;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ListPackage, setListPackage] =
    useState<CreateCustomerAdminAPI.IListPackage[]>([]);
  const [selected, setSelected] = useState("US");
  const [selectedSRestaurant, setSelectedSRestaurant] = useState("US");
  const [ContactPerson, setContactPerson] = useState<any>("");
  const [detailAddress, setDetailAddress] = useState<string>("");
  const [StoreName, setStoreName] = useState<any>("");
  const [CellPhone, setCellPhone] = useState<any>("");
  const [Address, setAddress] = useState<any>("");
  const [EmailAddress, setEmailAddress] = useState<any>("");
  const [restaurantPhone, setRestaurantPhone] = useState<string>("");
  const [SuitOfUnit, setSuitOfUnit] = useState<any>("");
  const [SuitOfUnitShipping, setSuitOfUnitShipping] = useState<string>("");
  const [City, setCity] = useState<any>("");
  const [States, setStates] = useState<any>("");
  const [OwnerPassCode, setOwnerPassCode] = useState<any>("");
  const [Role, setRole] = useState<number>(0);
  const [Package, setPackage] = useState<number>(0);
  const [PaymentStatus, setPaymentStatus] = useState<number>(1);
  const [IdCard, setIdCard] = useState<any>("");
  const [CVC, setCVC] = useState<any>("");
  const newday = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
  const monthNow =
    Number(newday.getMonth()) + 1 < 10
      ? "0" + (Number(newday.getMonth()) + 1)
      : newday.getMonth() + 1;
  const yearNow = newday.getFullYear();
  const startDateFormatRef = useRef<any>(monthNow + "-" + yearNow);
  const [ShowLoading, setShowLoading] = useState(false);
  const [messPasscodeErr, setMessPasscodeErr] = useState<string>('')
  const [timeZone, setTimeZone] = useState<number>(0)
  const [listTimeZone, setListTimeZone] = useState<ComboOption[]>([])
  const [zipCode, setZipCode] = useState<number | string>('')
  const [password, setPassword] = useState<string>('')
  const formRef = useRef<HTMLFormElement>(null);
  const [publishKey, setpublishKey] = useState<string>('');
  const [locat, setlocat] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 }); //tọa độ map địa chỉ

  const [isMarkerShown, setIsMarkerShown] = useState<boolean>(false); // maker của map
  const [shippingAddress, setShippingAddress] = useState<string>('')

  const gComboTime = async () => {
    const req = await gComboTimeZone();
    if (Number(req?.status) === 1) {
      setListTimeZone(req?.data ?? [])
    }
  }



  const GetListPackage = async () => {
    setShowLoading(true);
    const ListPackage = await CreateCustomerAdminAPI.ListPackage();

    if (Number(ListPackage.status) === 1) {
      setShowLoading(false);
      setListPackage(ListPackage?.data);
    } else {
      setShowLoading(false);
      setListPackage([]);
    }
    setShowLoading(false);
  };
  const ReloadForm = () => {
    setContactPerson("");
    setStoreName("");
    setCellPhone("");
    setAddress("");
    setEmailAddress("");
    setSuitOfUnit("");
    setCity("");
    setStates("");
    setOwnerPassCode("");
    setRole(0);
    setPackage(0);
    setPaymentStatus(1);
    setIdCard("");
    setCVC("");
    setShowLoading(false);
    setZipCode('')
  };

  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;
    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");

    setCellPhone(phoneReplace);
  };
  const onchagePhoneNumberRestaurant = (e: any) => {
    let phone = e?.target.value;
    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");

    setRestaurantPhone(phoneReplace);
  };
  const onchagePasscode = (e: any) => {
    let code = e?.target.value;
    const passcode = code
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");

    setOwnerPassCode(passcode);
  };




  const SubmitToken = async () => {
    setMessPasscodeErr('')
    if (OwnerPassCode.length !== 4) {
      setMessPasscodeErr(languageUI.Passcodemustcontain4characters)
    } else if (!password || password === '') {
      setMessPasscodeErr('')
      Swal.fire({
        position: "center",
        icon: "error",
        title: languageUI.Passwordisrequired,
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (password.length < 4) {
      setMessPasscodeErr('')
      Swal.fire({
        position: "center",
        icon: "error",
        title: languageUI.Weakpassword,
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      setShowLoading(true);
      formRef.current && formRef.current.click();
    }

  };

  const createNewStaff = async (tokenpayment: string, lastCart: string | number) => {

    if (owner_id && tokenpayment !== '') {
      setMessPasscodeErr('')

      const AddOwner = await CreateCustomerAdminAPI.AddOwner(
        owner_id,
        ContactPerson,
        StoreName,
        CellPhone,
        Address,
        EmailAddress,
        SuitOfUnit,
        City,
        // BusinessType,
        States,
        OwnerPassCode,
        Role,
        Package,
        PaymentStatus,
        IdCard,
        startDateFormatRef.current,
        CVC,
        password,
        detailAddress,
        locat.lat,
        locat.lng,
        timeZone,
        restaurantPhone,
        zipCode,
        tokenpayment,
        lastCart,
        shippingAddress,
        SuitOfUnitShipping,
        totalPrice,
        ListDevices ?? []
      );
      if (Number(AddOwner.status) === 1) {
        if (Number(AddOwner?.data?.payment_status) === 1) {
          if (AddOwner?.data?.owner_id) {
            PaymentPackage(AddOwner?.data?.owner_id)
          } else {
            setShowLoading(false);
          }

        } else {
          ReloadForm();
          Swal.fire({
            position: "center",
            icon: "success",
            title: languageUI.Createsuccess,
            showConfirmButton: false,
            timer: 1500,
          });
          setShowLoading(false);
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: AddOwner?.message
            ? AddOwner?.message
            : languageUI.SomethingwentwrongPleasetryagainlater,
          showConfirmButton: false,
          timer: 3000,
        });
        setShowLoading(false);
      }
    } else {
      setShowLoading(false);
      navigate("/web-admin/login");
    }
  }

  const PaymentPackage = async (OwnerIdPackage: number) => {
    const Pay = await CreateCustomerAdminAPI.Payment(OwnerIdPackage, Package);
    if (Number(Pay?.status) === 1) {
      setShowLoading(false)
      if (Pay?.returnUrl) {
        window.location.href = Pay?.returnUrl;
      } else {
        Swal.fire({
          position: "center",
          icon: "success",
          title: languageUI.Createsuccess,
          showConfirmButton: false,
          timer: 1500,
        });
        ReloadForm();
      }
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: Pay?.status
          ? Pay?.status.toString()
          : languageUI.SomethingwentwrongPleasetryagainlater,
        showConfirmButton: false,
        timer: 3000,
      });
      setShowLoading(false)
      return;
    }

  };
  const gConfigStripe = async () => {
    const req = await gConfigStripeAdmin();
    if (Number(req?.status) === 1) {
      setpublishKey(req?.publishKey ?? '')
    }
  }
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setDetailAddress(value);
    setlocat(latLng);
    setIsMarkerShown(!isMarkerShown);
  }
  const handleSelectShipping = async (value: string) => {
    setShippingAddress(value);
    setIsMarkerShown(!isMarkerShown);
  }
  useEffect(() => {
    if (owner_id) {
      gConfigStripe()
      setPackage(packageId)
      GetListPackage();
      gComboTime()

    } else {
      navigate('/')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className=" p-6 flex justify-center" >
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="w-100 bg-white radius-2 p-6">
        <div className="border-bottom">
          <div className="flex w-100 items-center gap-3 mb-7 flex-column-768">
            <div className="flex  w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Contactperson}:<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" placeholder={languageUI.Contactperson} className="custom-input" value={ContactPerson} onChange={(e: any) => {
                  setContactPerson(e.target.value);
                }} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Storename}:<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 " >
                <input type="text" placeholder={languageUI.Storename} className="custom-input" value={StoreName} onChange={(e) => setStoreName(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="flex w-100 align-items-center gap-3 mb-7 flex-column-768">
            <div className="flex align-items-center  w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Cellphone}:<span className="text-F24343">*</span></span>
              <div className="w-85 position-relative">
                <div className="border-DEE2E6 flex w-100 align-items-center   radius-2 ">
                  <div className="border-r-DCDCE4 flex items-center ">
                    <ReactFlagsSelect
                      selected={selected}
                      onSelect={(code) => { setSelected(code); }}
                    // countries={['US']}
                    />
                  </div>
                  {/* <input type="number" placeholder="Cellphone" className="custom-input" /> */}
                  <input type="number" className="border-none forcus-visiable-none h-40px w-100" value={CellPhone} onChange={onchagePhoneNumber} />
                  {/* <MaskedInput
                                        mask={[
                                            "(",
                                            /[0-9]/,
                                            /\d/,
                                            /\d/,
                                            ")",
                                            " ",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            "-",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        placeholder="(___) ___-____"
                                        className="form-control border-none"
                                        value={CellPhone}
                                        onChange={onchagePhoneNumber}
                                    /> */}
                </div>
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Address}:<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 " >
                <input type="text" placeholder={languageUI.Address} className="custom-input" value={Address} onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="flex w-100 items-center gap-3 mb-7 flex-column-768">
            {/* <div className="flex  w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">Restaurant phone:<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" placeholder="Restaurant phone" className="custom-input" value={restaurantPhone} onChange={(e) => setRestaurantPhone(e.target.value)} />
              </div>
            </div> */}
            <div className="flex align-items-center  w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.RestaurantPhone}:<span className="text-F24343">*</span></span>
              <div className="w-85 position-relative">
                <div className="border-DEE2E6 flex w-100 align-items-center   radius-2 ">
                  <div className="border-r-DCDCE4 flex items-center ">
                    <ReactFlagsSelect
                      selected={selectedSRestaurant}
                      onSelect={(code) => { setSelectedSRestaurant(code); }}
                    // countries={['US']}
                    />
                  </div>
                  {/* <input type="number" placeholder="Cellphone" className="custom-input" /> */}
                  <input type="number" className="border-none forcus-visiable-none h-40px w-100" value={restaurantPhone} onChange={onchagePhoneNumberRestaurant} />
                  {/* <MaskedInput
                                        mask={[
                                            "(",
                                            /[0-9]/,
                                            /\d/,
                                            /\d/,
                                            ")",
                                            " ",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            "-",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        placeholder="(___) ___-____"
                                        className="form-control border-none"
                                        value={CellPhone}
                                        onChange={onchagePhoneNumber}
                                    /> */}
                </div>
              </div>
            </div>
            <div className="flex items-center r w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Suitofunit}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 " >
                <input type="text" placeholder={languageUI.Suitofunit} className="custom-input" value={SuitOfUnit} onChange={(e) => setSuitOfUnit(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="flex w-100 items-center gap-3 mb-7 flex-column-768">
            <div className="flex  w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Emailaddress}:<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" placeholder={languageUI.Emailaddress} className="custom-input" value={EmailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.City}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" placeholder={languageUI.City} className="custom-input" value={City} onChange={(e) => setCity(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="flex w-100  gap-3 mb-7 flex-column-768">
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.OwnerPassCode} ({languageUI.loginOwnerMode} ):<span className="text-F24343">*</span></span>
              <div className=" w-84">
                <div className="border-DEE2E6 w-100 h-fit-content overflow-hidden radius-2 ">
                  <MaskedInput
                    mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                    className="form-control border-none"
                    placeholder="(xxxx):number"
                    value={OwnerPassCode}
                    onChange={onchagePasscode}
                  />
                </div>
                <div className="text-italic text-danger mt-1">{messPasscodeErr}</div>
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.States}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 " >
                <input type="text" placeholder={languageUI.States} className="custom-input" value={States} onChange={(e) => setStates(e.target.value)} />
              </div>
            </div>
          </div>
          <div className="flex w-100  gap-3 mb-7 flex-column-768">
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit"> {languageUI.Webpassword} ({languageUI.loginPOS}):<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" placeholder={languageUI.Enterpassword} className="custom-input" value={password}
                  onChange={(e) => { setPassword(e.target.value) }} />
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">Zip Code:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="Number" placeholder="Zip Code" className="custom-input" value={zipCode}
                  onChange={(e) => { if (e.target.value && (e.target.value.length > 10 || Number(e.target.value) < 0)) { return } setZipCode(e.target.value) }} />
              </div>
            </div>
          </div>









          <div className="flex w-100  gap-3 mb-7 flex-column-768 pe-4">
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">{languageUI.Timezone}:<span className="text-F24343">*</span></span>
              <div className="d-flex align-items-center border  ps-2 pe-4  rounded-8px h-42px w-84">
                <Select
                  className="react-select react-select-container w-100  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.Timezone}
                  isClearable={false}
                  options={listTimeZone}
                  // onChange={(e) => setPaymentStatus(e?.value)}
                  onChange={(e: any) => {
                    if (e == null) {
                      setTimeZone(0);
                    } else {
                      setTimeZone(e.value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex w-100  gap-3 mb-7 flex-column-768">
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2 pe-2">
              <span className="w-20 flex-shrink-0 h-fit"> {languageUI.DetailAddress}:<span className="text-F24343">*</span></span>
              <div className={`d-flex justify-content-between border-DEE2E6 radius-2 py-2 px-2 mt-2 w-100`}>
                <PlacesAutocomplete
                  value={detailAddress}
                  onChange={setDetailAddress}
                  onSelect={handleSelect}

                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="w-100 position-relative">
                      <div className="d-flex align-items-center w-100">
                        <input
                          className="focus-visible-none border-none w-100"

                          style={{
                            width: "calc(100% - 25px)",
                          }}
                          {...getInputProps({
                            placeholder: languageUI.Enteryourdetailaddress,
                          })}
                        />
                        {detailAddress !== "" ? (
                          <CloseIcon className="text-dark font-md"
                            onClick={(e) => {
                              setDetailAddress("");
                            }} />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="dropdown-place w-100 position-absolute left-0 right-0 z-index-9 mt-2  px-3">
                        {/* {loading && <div>Loading...</div>} */}
                        {suggestions.map((suggestion) => {
                          const style = suggestion.active
                            ? { backgroundColor: "#a83232", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              className="d-flex align-items-center flex-wrap py-1"
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              <PlaceIcon />
                              <span className="phone-order-online" style={{ maxWidth: "calc(100% - 45px)" }}>
                                {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>
          </div>
          {ListDevices && ListDevices.length > 0 && (
            <div className="flex w-100  gap-3 mb-7 flex-column-768">
              <div className="flex items-center w-1-2 max-w-768-w-full gap-2 pe-2">
                <span className="w-20 flex-shrink-0 h-fit"> {languageUI.Shippiingaddress}:<span className="text-F24343">*</span></span>
                <div className={`d-flex justify-content-between border-DEE2E6 radius-2 py-2 px-2 mt-2 w-100`}>
                  <PlacesAutocomplete
                    value={shippingAddress}
                    onChange={setShippingAddress}
                    onSelect={handleSelectShipping}

                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="w-100 position-relative">
                        <div className="d-flex align-items-center w-100">
                          <input
                            className="focus-visible-none border-none w-100"

                            style={{
                              width: "calc(100% - 25px)",
                            }}
                            {...getInputProps({
                              placeholder: "Name, address, unit, city, state, zip",
                            })}
                          />
                          {shippingAddress !== "" ? (
                            <CloseIcon className="text-dark font-md"
                              onClick={(e) => {
                                setShippingAddress("");
                              }} />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="dropdown-place w-100 position-absolute left-0 right-0 z-index-9 mt-2  px-3">
                          {/* {loading && <div>Loading...</div>} */}
                          {suggestions.map((suggestion) => {
                            const style = suggestion.active
                              ? { backgroundColor: "#a83232", cursor: "pointer" }
                              : { backgroundColor: "#ffffff", cursor: "pointer" };
                            return (
                              <div
                                className="d-flex align-items-center flex-wrap py-1"
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                <PlaceIcon />
                                <span className="phone-order-online" style={{ maxWidth: "calc(100% - 45px)" }}>
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
              <div className="flex items-center r w-1-2 max-w-768-w-full gap-2">
                <span className="w-20 flex-shrink-0 h-fit">{languageUI.Suitofunit} (Shipping):</span>
                <div className="border-DEE2E6 w-85 overflow-hidden radius-2 " >
                  <input type="text" placeholder={`${languageUI.Suitofunit} (Shipping)`} className="custom-input" value={SuitOfUnitShipping} onChange={(e) => setSuitOfUnitShipping(e.target.value)} />
                </div>
              </div>
            </div>
          )}

        </div>
        <h4 className="my-5">{languageUI.Subscription}</h4>
        <div className="border-bottom">
          <div className="flex w-100  gap-3 mb-7 flex-column-768">
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
              <span className="w-20 flex-shrink-0 h-fit">Package:<span className="text-F24343">*</span></span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                {/* <Select
                  className="react-select react-select-container w-100 custom-h-38px border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder="Package"
                  isClearable={false}
                  options={ListPackage.map((item) => {
                    return {
                      value: item.id,
                      label: item.label,
                    };
                  })}
                  value={ListPackage.filter(item => item?.id == Package)}
                  onChange={(e) => setPackage(e?.value)}

                /> */}
                <input type="text" placeholder="Package" className="custom-input" value={packName} readOnly />
              </div>
            </div>
            {/* <div className="flex  w-1-2 max-w-768-w-full gap-2">
                            <span className="w-20 flex-shrink-0 h-fit">Payment<br />status:<span className="text-F24343">*</span></span>
                            <div className="d-flex align-items-center border  px-2  rounded-8px h-42px w-83">
                                <Select
                                    className="react-select react-select-container w-100  border-select-none"
                                    classNamePrefix="react-select"
                                    defaultValue={[""]}
                                    placeholder="Payment status"
                                    isClearable={false}
                                    options={[{
                                        value: 0,
                                        label: 'Paid',
                                    }]}
                                    value={[{
                                        value: 0,
                                        label: 'Paid',
                                    }]}
                                    onChange={(e) => setPaymentStatus(e?.value)}
                                />
                            </div>
                        </div> */}
          </div>
        </div>
        <h4 className="my-5">{languageUI.Cardinformation}</h4>
        <Elements stripe={loadStripe(publishKey)}>
          <PaymentFormLandingPage formRef={formRef} getToken={createNewStaff} />
        </Elements>
        {/* <div>
                    <div className="flex w-100  gap-3 mb-7 flex-column-768">
                        <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
                            <span className="w-20 flex-shrink-0 h-fit">Id Card:<span className="text-F24343">*</span></span>
                            <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                                <MaskedInput
                                    mask={[
                                        /[0-9]/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        "-",
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                        /\d/,
                                    ]}
                                    placeholder="____-____-____-____"
                                    className="form-control border-none"
                                    value={IdCard}
                                    onChange={onchageCardID}
                                />
                            </div>
                        </div>
                        <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
                            <span className="w-20 flex-shrink-0 h-fit">Date of Card:<span className="text-F24343">*</span></span>
                            <div className="d-flex align-items-center border  rounded-8px h-42px  w-83">
                                <div className="w-100">
                                    <div className="form-group border-none">
                                        <HyperDatepicker
                                            hideAddon={true}
                                            value={selectedStartDate}
                                            dateFormat="MMMM yyyy"
                                            inputClass="border-none"
                                            onChange={(date) => {
                                                onDateChangeStart(date);
                                            }}
                                            showMonthYearPicker
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex w-100 gap-3 mb-7 flex-column-768">
                        <div className="flex items-center w-1-2 max-w-768-w-full gap-2">
                            <span className="w-20 flex-shrink-0 h-fit">CVC:</span>
                            <div className="border-DEE2E6 w-85 overflow-hidden radius-2 " >
                                <input type="number" placeholder="CVC" className="custom-input" value={CVC} onChange={(e) => setCVC(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="flex justify-end mt-3">
          <button className="border-none bg-00a15a text-white radius-1 w-100px text-4 py-3" onClick={SubmitToken}>{languageUI.Create}</button>
        </div>
      </div>
    </div>
  )
}
export default CreatFormLandingPage