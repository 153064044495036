import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  imgLeaf,
  imgLeaf2,
  LocalMallIcon,
} from "../../../../../../components/Image";
import "./Styles.css";
import {
  DetailBill,
  FoodDetail,
} from "../../../../../../api/apiOwner/custommerRecieptApi";
import moment from "moment";
import { FormatDolla } from "../../../../../../utils/format";
import * as userSlice from "../../../../../../store/slices/userSlice";
import * as SettingPrinterApi from "../../../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../../../api/apiOwner/printerApi";
import ModalToast from "../../../ModalToast/ModalToastErr";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelpOutlineSharpIcon from "@mui/icons-material/HelpOutlineSharp";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface drawerRight {
  openDrawer: boolean;
  detailOrder?: DetailBill;
  UserIdStore?: number | null;
  dismisDrawer: () => void;
}
const DrawerOrderDetail: React.FC<drawerRight> = ({
  openDrawer,
  detailOrder,
  UserIdStore,
  dismisDrawer,
}) => {
  const languageUI = getLanguageUI().reportPage;
  const languagePrinterUI = getLanguageUI().printPage;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [listFood, setListFood] = useState<FoodDetail[]>([]);
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [listPrinterName, setListPrinterName] = useState<
    SettingPrinterApi.ListPrinterByCatalog[]
  >([]);
  const [openToolTip, setOpenToolTip] = useState(false);

  const handleTooltipClose = () => {
    setOpenToolTip(false);
  };
  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await SettingPrinterApi.gListNamePrinterElse(
        UserIdStore,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  useEffect(() => {
    gListPrinter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (openDrawer) {
      if (detailOrder?.listFood) {
        setListFood(detailOrder?.listFood ?? []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer]);
  const PrintCard = async () => {
    try {
      const rawhtml =
        `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px">
            <h3 style="width: 100%; margin:  auto; text-align: center">${
              languagePrinterUI.report
            }</h3>
            <div style="width: 100%;">
            <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
              detailOrder?.table_name
                ? `${languagePrinterUI.tableName}: ${detailOrder?.table_name} -`
                : ""
            }Order#${detailOrder?.id}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languagePrinterUI.printDateTime
              }:</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${moment(
                detailOrder?.timestart,
                "YYYY-MM-DD hh:mm:ss"
              ).format("DD/MM/YYYY - hh:mm:ss")}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languagePrinterUI.customer
              }: ${
          detailOrder?.custommer_name ? detailOrder?.custommer_name : "---"
        }</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languagePrinterUI.phoneNumber
              }: ${detailOrder?.phone ? detailOrder?.phone : "---"}</h6>
            </div>
            
            

           ${listFood
             .map(
               (cart, i) =>
                 `
              <div style="display: table; width: 100%">
           <div style = "display: table-row; padding-top: 10px; width: 100%" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <p  style=" margin: 0; text-align: start; font-size: 24px ">${
                  i + 1
                }.${cart?.name}</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p class="quanlity-number; margin-top: 10px !important " style="font-size: 24px ; ">${Number(
                  cart?.quantity
                )}x${Number(cart?.price)}$</p>
                  ${
                    Number(cart?.form_order) !== 1
                      ? `<p style=" flex-shrink: 0;font-size: 24px ;  white-space: nowrap; font-weight: 600;">${languagePrinterUI.takeAway}</p>`
                      : `<p style=" flex-shrink: 0; font-size: 24px ;  white-space: nowrap; font-weight: 600;">${languagePrinterUI.diner}</p>`
                  }
                </div>
              </div >
              </div >
              <div style="width: 100%">
               ${
                 cart?.note
                   ? `<p class="" style="font-size: 24px ; " >-${cart?.note}</p>`
                   : ""
               }
                    
                  ${
                    cart?.notefood_name
                      ? `<p class=""  style="width: 100% ; white-space: nowrap " style="font-size: 24px ; ">-${cart?.notefood_name}</p>`
                      : ""
                  }
              </div>

              `
             )
             .join(" ")}
           <div style="width: 100%;">
                <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
            </div>
      
           <div style="display: table; width: 100%;  padding-right: 40px;">
        <div style = "display: table-row; padding-top: 0px; width: 100%" >
          <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.subTotal}:</p>
          </div>
          <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
              <p style="font-size: 24px ; ">$${
                Number(detailOrder?.sub_total)
                  ? FormatDolla(Number(detailOrder?.sub_total))
                  : 0
              }</p>
            </div>
          </div>
          </div>
           <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.tax}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">$${
              Number(detailOrder?.sub_total)
                ? FormatDolla(
                    (Number(detailOrder?.sub_total) *
                      Number(detailOrder?.tax_percent)) /
                      100
                  )
                : 0
            }</p>
          </div>
        </div>
        </div>
          <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.discount}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">$${FormatDolla(
              Number(detailOrder?.discount)
            )}</p>
            </div>
        </div>
        </div>
           <div style="display: table; width: 100%">
          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">Tip:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">$${FormatDolla(
              Number(detailOrder?.tip_amount)
            )}</p>
          </div>
        </div>
        </div>
          <div style="display: table; width: 100%">

          <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <p style="font-size: 24px ; ">${languagePrinterUI.tipFor}:</p>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <p style="font-size: 24px ; ">${
              detailOrder?.staff_name ? detailOrder?.staff_name : "---"
            }</p>
          </div>
        </div>
        </div>
          
        ` +
        (Number(detailOrder?.feePrice) > 0
          ? `<div style="display: table; width: 100%">

            <div style="display: table-row; padding-top: 0px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 4px">
                <p style="font-size: 24px ; ">${
                  languagePrinterUI.ConvenienceFee
                }:</p>
              </div>
              <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p style="font-size: 24px ; ">$${FormatDolla(
                  Number(detailOrder?.feePrice)
                )}</p>
              </div>
            </div>
          </div>`
          : ``) +
        `
           <div style="display: table; width: 100%">

        <div style = "display: table-row; padding-top: 0px; width: 100%" >
            <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 0px">
            <h6>${languagePrinterUI.total}:</h6>
            </div>
            <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:0px;">
            <h6>$${FormatDolla(Number(detailOrder?.total_order))}</h6>
            </div>
        </div>
        </div>
        
            <div style="width: 100%; margin-top: 38px">
            <div style="width: 100%;">
            <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
        </div>

              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${nameStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${addressStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
            </div>
            <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">${
              languagePrinterUI.thanks
            }!</h3>
          </div >
          </body >
          </html >
  `;
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);

  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <Drawer
        className="custom-btn-close custom-w-drawer"
        anchor="right"
        onClose={dismisDrawer}
        open={openDrawer}
      >
        <div className="w-100  position-relative">
          <div className="mt-16 px-12">
            <span className="flex justify-center text-4A4A6A text-8 pb-5 fw-600 font-urbansist">
              {languageUI.report}
            </span>
          </div>
          <div className="border-bottom pb-6 px-12">
            <div className="flex justify-between items-center pb-3">
              <span className="text-15px fw-600 font-urbansist text-666687">
                {languageUI.printDateTime}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {moment(detailOrder?.timestart, "YYYY-MM-DD hh:mm:ss").format(
                  "DD/MM/YYYY - hh:mm:ss"
                )}
              </span>
            </div>

            <div className="flex justify-between items-center pb-3">
              <span className="text-15px fw-600 font-urbansist text-666687">
                {languageUI.customer}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {Number(detailOrder?.custommer_id) > 0
                  ? detailOrder?.custommer_name
                  : "-----"}
              </span>
            </div>
            <div className="flex justify-between items-center pb-3">
              <span className="text-15px fw-600 font-urbansist text-666687">
                {languageUI.phoneNumber}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {Number(detailOrder?.custommer_id) > 0
                  ? detailOrder?.phone
                  : "-----"}
              </span>
            </div>
          </div>
          <div className="border-bottom pl-12">
            <div className="pb-3 overflow-y-scroll h-content-orderdetail-report">
              {listFood.map((item, i) => (
                <div className="pr-12" key={i}>
                  <div className="flex justify-between items-center pt-4 ">
                    <span className="text-6 fw-600 font-urbansist text-4A4A6A">
                      {item?.name}
                    </span>
                    <span className="text-18px fw-700 font-urbansist text-4A4A6A">
                      {item.quantity}x{FormatDolla(Number(item.price))}
                    </span>
                  </div>
                  <div>
                    {Number(item.side_id) !== 0 && (
                      <div className="flex items-center my-3">
                        <span className="text-17px font-urbansist text-4A4A6A">
                          + {item.side_name}{" "}
                        </span>
                        <span className="text-17px font-urbansist text-12B064 ml-2">
                          (Free x1)
                        </span>
                      </div>
                    )}
                    {item?.description !== "" && (
                      <span className="text-15px font-urbansist text-a5a5ba">
                        {item?.description}
                      </span>
                    )}
                  </div>
                  <div>
                    <div className="flex items-center my-3">
                      {Number(item.notefood_id) !== 0 && (
                        <span className="text-14px font-urbansist text-12B064 radius-2 bg-F6F9F8 p-2">
                          {item?.notefood_name}
                        </span>
                      )}
                      <span className="flex items-center text-14px font-urbansist text-FF7B2C bg-FFF2EA radius-2 ml-2 p-1">
                        <LocalMallIcon className="mr-1 text-20px" />
                        {item.form_order === "1"
                          ? languageUI.dineIn
                          : languageUI.takeAWay}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="border-bottom px-12">
            <div className="flex justify-between items-center py-4 ">
              <span className="text-18px fw-600 font-urbansist text-212134">
                {languageUI.subTotal}
              </span>
              <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                $
                {Number(detailOrder?.sub_total)
                  ? FormatDolla(Number(detailOrder?.sub_total))
                  : 0}
              </span>
            </div>
            <div className="flex justify-between items-center pb-4 ">
              <span className="text-18px fw-600 font-urbansist text-212134">
                {languageUI.tax}
              </span>
              <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                $
                {detailOrder?.tax_percent
                  ? FormatDolla(
                      (Number(detailOrder?.tax_percent) *
                        Number(detailOrder?.sub_total)) /
                        100
                    )
                  : 0}
              </span>
            </div>
          </div>
          <div className="px-12 ">
            <div className="flex justify-between items-center pt-4 ">
              <span className="text-18px fw-600 font-urbansist text-212134">
                {languageUI.discount}
              </span>
              <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                ${FormatDolla(Number(detailOrder?.discount))}
              </span>
            </div>
            {Number(detailOrder?.tip_amount) > 0 && (
              <div className="flex justify-between items-center py-4 ">
                <span className="text-18px fw-600 font-urbansist text-212134">
                  {languageUI.tipfor} {detailOrder?.staff_name}
                </span>
                <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                  ${FormatDolla(Number(detailOrder?.tip_amount))}
                </span>
              </div>
            )}
            {Number(detailOrder?.feePrice) > 0 && (
              <div className="flex justify-between items-center py-4">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div className="tooltip-payment-web-checkin">
                    <Tooltip
                      className=""
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      open={openToolTip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      placement="right"
                      title={languageUI.theConvenienceFee}
                    >
                      <div
                        className="d-flex gap-1 align-items-end w-fit"
                        onClick={() => setOpenToolTip(true)}
                      >
                        <div className="text-18px fw-600 font-urbansist text-212134">
                          {languageUI.convenienceFee}
                        </div>
                        <HelpOutlineSharpIcon className="" />
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
                <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                  ${FormatDolla(Number(detailOrder?.feePrice))}
                </span>
              </div>
            )}
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <span className="text-18px fw-600 font-urbansist text-212134 mr-1">
                  {languageUI.total}
                </span>
                <span className="text-18px fw-700 font-urbansist text-a5a5ba">
                  (+ {languageUI.tax})
                </span>
              </div>
              <div>
                <span className="text-FF7B2C text-6 fw-600 font-urbansist">
                  ${FormatDolla(Number(detailOrder?.total_order))}
                </span>
              </div>
            </div>
          </div>
          <div className="px-12 mt-4">
            <button
              className=" border-none radius-2 bg-FF7B2C text-white text-4 font-urbansist fw-600 w-100 py-2"
              onClick={PrintCard}
            >
              {languageUI.print}
            </button>
          </div>

          <img src={imgLeaf} alt="" className="custom-position-img" />
          <img src={imgLeaf2} alt="" className="custom-position-img2-drawer" />
        </div>
      </Drawer>
    </>
  );
};

export default DrawerOrderDetail;
