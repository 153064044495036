import Sheet from "react-modal-sheet";
import { bellOrange, checkCircle } from "../../../../components/ImgExport";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AppleIcon from "@mui/icons-material/Apple";
import { useState } from "react";
import "./Styles.css";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface MethodPaymentProps {
  show: boolean;
  handleClose: () => void;
  handelSubmit: () => void;
  handelCallStaff: () => void;
  setValueNumberMethod: any;
  Amount?: number;
}

const MethodPayment = ({
  show,
  setValueNumberMethod,
  Amount = 0,
  handleClose,
  handelSubmit,
  handelCallStaff,
}: MethodPaymentProps) => {
  const languageUI = getLanguageCheckIn();
  const array: {
    id: number;
    name: string;
    icon: any;
  }[] = [
    {
      id: 2,
      name: "ATM / Visa Card",
      icon: <CreditCardIcon />,
    },
    // {
    //     id: 3,
    //     name: "Google Pay",
    //     icon: <GoogleIcon />
    // }
    //     ,
    {
      id: 4,
      name: "Apple Pay",
      icon: <AppleIcon />,
    },
  ];
  const [valueTip, setValueTip] = useState<number>();

  return (
    <div>
      <Sheet
        // ref={ref}
        isOpen={show}
        onClose={handleClose}
        snapPoints={[500]}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className="px-3">
              <p className="text-number-order">{languageUI.PaymentMethod}</p>
              <div className="d-flex flex-column gap-2">
                {/* <p className="name-ployee-items text-center">
                  {languageUI.ForEveryDoolarYouSpend} <br />{" "}
                  {languageUI.proceedtothecounter}
                </p> */}
                {array.map((items, i) => (
                  <div
                    key={i}
                    className={`${
                      valueTip === items.id
                        ? "select-employee-items-2"
                        : "select-employee-items"
                    }`}
                    onClick={() => {
                      setValueTip(items.id);
                      setValueNumberMethod(items.id);
                      //   handelSubmit();
                    }}
                  >
                    <p
                      className={`icon-ployee-items ${
                        valueTip === items.id ? "text-ffffff" : "text-A5A5BA"
                      }`}
                    >
                      {items.icon}
                    </p>
                    <p
                      className={`name-ployee-items ${
                        valueTip === items.id ? "text-ffffff" : "text-A5A5BA"
                      }`}
                    >
                      {items.name}
                    </p>
                  </div>
                ))}
              </div>
              {/* <div className="mt-2 d-flex justify-content-between align-items-center">
                <p className="mb-0 text-number-order"> {languageUI.Needhelp}</p>
                <div className="d-flex gap-2" onClick={handelCallStaff}>
                  <img src={bellOrange} alt="" />
                  <p className="mb-0 text-call-staff">{languageUI.Callstaff}</p>
                </div>
              </div> */}
            </div>
          </Sheet.Content>
          <div className="py-3 px-3 fixed bottom-0 w-100 bg-white border-top">
            <button
              className="custom-filter-btn border-none w-100 text-ffffff"
              // onClick={()=>{handelPayment(); handleClose()}}
              onClick={handelSubmit}
            >
              <img src={checkCircle} alt="" className="img-filter " />
              Select
            </button>
          </div>
          {/* <ModalPaymentCash handleClose={dismisModalPaymentCash} show={showModalPaymentCash} /> */}
        </Sheet.Container>
        <Sheet.Backdrop className="bg-bg-backdrop" onTap={handleClose} />
      </Sheet>
    </div>
  );
};

export default MethodPayment;
