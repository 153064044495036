import { Form, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../Styles.css"
import { useEffect, useState } from "react";
import React from "react";
import Select from "react-select";
import moment from "moment-timezone";
import { reader } from "../../../components/ImgExport";
// import ModalDatePicker from "react-mobile-datepicker-ts";



interface ModalReaderProps {
    show: boolean;
    handleClose: () => void;
    amount?: number
}

const ModalReader = ({
    show,
    handleClose,
    amount = 0
}: ModalReaderProps) => {


    return (
        <>
            <Modal show={show} centered className="modal-delete-customer modal-import-inventory">
                <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
                    <Modal.Body>
                        <div className="p-5">
                            <div className="d-flex gap-1 ">
                                <p className="mb-0 text-stripe-total">Total:</p>
                                <p className="mb-0 text-stripe-total-price">${amount}</p>
                            </div>
                            <div className="w-100 d-flex justify-content-center py-3">
                                <img src={reader} alt="" />
                            </div>
                            <div className="d-flex justify-content-center py-2 box-back-reader" onClick={handleClose}>
                                Cancel
                            </div>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalReader;