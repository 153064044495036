
import { Card, Col, Container, Row } from "react-bootstrap";

export default function ErrorScanQr() {
  return (
    <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
      <Container className="m-auto">
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="bg-pattern rounded-3 shadow mb-0">
              <Card.Body className="p-4 text-center">
                <i
                  style={{ fontSize: "50px" }}
                  className="fe-alert-circle text-warning m-auto "
                ></i>
                <h2 className="text-center mb-4 mt-4">
                  Please Scan QR again!!
                </h2>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
