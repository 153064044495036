
import Sheet, { SheetRef } from "react-modal-sheet";
import { arrowLeft, checkCircle, clock } from "../../../../../components/ImgExport";
import { getLanguageCheckIn } from "../../../../../Services/languageCheckin";


interface ModalPaymentCashProps {
    show: boolean;
    handleClose: () => void;
}

const ModalPaymentCash = ({
    show,
    handleClose,
}: ModalPaymentCashProps) => {
    const languageUI = getLanguageCheckIn();

    return (
        <div>
            <Sheet
                // ref={ref}
                isOpen={show}
                onClose={() => handleClose?.()}
                snapPoints={[250]}
            >
                <Sheet.Container>
                    <Sheet.Header />
                    <Sheet.Content>
                        <div className="px-3">
                            <div className="d-flex align-items-center">
                                <img src={clock} alt="" />
                                <p className="text-select-name-employee mb-0">{languageUI.Wewillnotifyyourwaiteranddontforgetyouarealwayswelcometopayatthecounter}</p>
                            </div>
                        </div>
                    </Sheet.Content>
                    <div className="py-3 px-3 fixed bottom-0 w-100 bg-white border-top d-flex gap-2 align-items-center">
                        <div className="position-relative" onClick={() => handleClose?.()}>
                            <img src={arrowLeft} alt="" />
                        </div>
                        <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={() => handleClose?.()}>
                            <img src={checkCircle} alt="" className="img-filter " />
                            {languageUI.Gotit}
                        </button>
                    </div>
                </Sheet.Container>
                <Sheet.Backdrop className="bg-bg-backdrop" onTap={() => handleClose?.()} />
            </Sheet>
        </div>
    );
};

export default ModalPaymentCash;
