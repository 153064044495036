import * as Request from "../Request";

export const AllCustomer = async (
  UserIdStore: number,
  startDate: string,
  endDate: string,
  type: number
) => {
  return await Request.fetchAPI(
    "owner/gAllCustomer.php?admin_id=" +
      UserIdStore +
      "&&date_start=" +
      startDate +
      "&&date_end=" +
      endDate +
      "&&type=" +
      type,
    "GET",
    {
      // }).then((res:Request.Response<IAllCustomerData[]>) => {
    }
  )
    .then((res: IAllCustomer) => {
      return res;
      // }).catch((err:Request.Response<IAllCustomerData[]>) => {
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const SendSMS = async (
  UserIdStore: number,
  id_customer: number,
  MessageSend: string
) => {
  return await Request.fetchAPI("owner/send_SMS.php", "POST", {
    id_owner: UserIdStore,
    id_customer: id_customer,
    messages: MessageSend,
  })
    .then((res: ISendMessage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const SendAllSMS = async (
  UserIdStore: number,
  date_start: string,
  date_end: string,
  type: number,
  messages: string
) => {
  return await Request.fetchAPI("owner/send_all_SMS.php", "POST", {
    admin_id: UserIdStore,
    date_start: date_start,
    date_end: date_end,
    type: type,
    messages: messages,
  })
    .then((res: ISendMessage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        success: [],
        fail: [],
      };
    });
};

export interface IAllCustomerData {
  id_customer?: number;
  point?: number;
  phone?: string;
  name?: string;
  lastVisit?: string;
}
export interface IAllCustomer {
  message: string;
  status: number;
  data: IAllCustomerData[];
}

export interface ISendMessage {
  message: string;
  status: number;
  success: [];
  fail: [];
}
