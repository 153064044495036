import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';
import { classNames } from 'react-select/src/utils';

interface BreadcrumbItems {
    label: string;
    path: string;
    active?: boolean;
}

interface PageTitleProps {
    breadCrumbItems?: Array<BreadcrumbItems>;
    title: string;
    className?: "" | string;
}

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {
    return (
        <Row>
            <Col>
                <div className={`page-title-box ${props['className']}`}>
                    <div className="page-title-right">
                        <Breadcrumb className="m-0">
                            {(props['breadCrumbItems'] || []).map((item, index) => {
                                return item.active ? (
                                    <Breadcrumb.Item active key={index}>
                                        {item.label}
                                    </Breadcrumb.Item>
                                ) : (
                                    <Breadcrumb.Item key={index} href={item.path}>
                                        {item.label}
                                    </Breadcrumb.Item>
                                );
                            })}
                        </Breadcrumb>
                    </div>
                    <h4 className="page-title">{props['title']}</h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
