import "../../StylesSetting.css";
import {
  imgLeaf,
  keyPassWord,
  imgLeaf2,
  phone,
} from "../../../../components/Image";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import * as SettingChangePassword from "../../../../api/apiOwner/SettingChangePassword";
import { useNavigate } from "react-router-dom";
import ModalLoading from "../../../../components/ModalLoading";
import { getLanguageUI } from "../../../../Services/languageUI";
import * as SettingInformationAPI from "../../../../api/apiOwner/SettingInformation";
import { useDispatch } from "react-redux";
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import * as staffJointSlice from "../../../../store/slices/staffJointSlice";
interface ParamProp {
  ChangeSetting: () => void;
}
const PassWord: React.FC<ParamProp> = ({ ChangeSetting }) => {
  const languageUI = getLanguageUI().setting;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [OldPass, setOldPass] = React.useState<string>("");
  const [NewPass, setNewPass] = React.useState<string>("");
  const [RetypeNewPass, setRetypeNewPass] = React.useState<string>("");
  const [message, setMessage] = React.useState<any>("");
  const [messagecode, setMessagecode] = React.useState<any>("");
  const [messageSuccess, setMessageSuccess] = React.useState<any>("");
  const [messageSuccesscode, setMessageSuccesscode] = React.useState<any>("");
  const [ShowLoading, setShowLoading] = React.useState<boolean>(false);
  const [OldPassCode, setOldPassCode] = React.useState<string>("");
  const [NewPassCode, setNewPassCode] = React.useState<string>("");
  const [RetypeNewPassCode, setRetypeNewPassCode] = React.useState<string>("");
  const [showPasscode, setShowPasscode] = React.useState(false);
  const [showNewPasscode, setShowNewPasscode] = React.useState(false);
  const [showConfirmPasscode, setShowConfirmPasscode] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [mesErrPhoneNumber, setMessErrPhoneNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [editPhoneNumber, setEditPhoneNumber] = React.useState(true);
  const [statusDisplay, setStatusDisplay] = React.useState("1");
  const [typeDisplay, setTypeDisplay] = React.useState(1);
  const [minuteDisPlay, setMinuteDisPlay] = React.useState<number>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowNewPassword = () =>
    setShowNewPassword((showNew) => !showNew);
  const handleClickShowComfirmPassword = () =>
    setShowConfirmPassword((showConfirm) => !showConfirm);

  const ReloadForm = () => {
    setOldPass("");
    setNewPass("");
    setRetypeNewPass("");
    setOldPassCode("");
    setNewPassCode("");
    setRetypeNewPassCode("");
    setShowPassword(false);
    setShowPasscode(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setShowNewPasscode(false);
    setShowConfirmPasscode(false);
    setMessage("");
  };
  const GetInfomationOwner = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const Infomation = await SettingInformationAPI.InformationOwner(
        UserIdStore
      );
      if (Number(Infomation?.status) === 1 && Infomation?.data) {
        setEmail(Infomation?.data?.email ?? "");
        setPhoneNumber(Infomation?.data?.phone ?? "");
        setAddress(Infomation?.data?.address ?? "");
      }
      setShowLoading(false);
    }
  };
  const gConfigDisplay = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const Infomation = await SettingChangePassword.gConfigDisplay(
        UserIdStore
      );
      if (Number(Infomation?.status) === 1 && Infomation?.data) {
        setTypeDisplay(Infomation?.data?.type_display);
        setMinuteDisPlay(Infomation?.data?.minutes_display);
      }
      setShowLoading(false);
    }
  };
  const changePassword = async () => {
    if (UserIdStore) {
      if (OldPass.trim() === "") {
        setMessage(languageUI.pleaseEnterOldPass);
      } else if (NewPass.trim() === "") {
        setMessage(languageUI.pleaseEnterNewPass);
      } else if (RetypeNewPass.trim() === "") {
        setMessage(languageUI.pleaseEnterRetypePass);
      } else {
        setShowLoading(true);
        const Res_Status = await SettingChangePassword.ChangePassword(
          UserIdStore,
          OldPass,
          NewPass,
          RetypeNewPass
        );
        if (Number(Res_Status.status) === 1) {
          setMessage("");
          setMessageSuccess(languageUI.changePasswordSuccess);
          setShowLoading(false);
          dispatch(flagSlice.clearSetting());
          ReloadForm();
        } else {
          setMessageSuccess("");
          setMessage(
            Res_Status?.message
              ? Res_Status?.message
              : "Something went wrong. Please try again later!"
          );
          dispatch(flagSlice.clearSetting());
          setShowLoading(false);
        }
      }
    } else {
      navigate("/auth/login");
    }
  };
  const changePassCode = async () => {
    if (UserIdStore) {
      if (OldPassCode.trim() === "") {
        setMessage(languageUI.pleaseEnterOldPasscode);
      } else if (NewPassCode.trim() === "") {
        setMessage(languageUI.pleaseEnterNewPasscode);
      } else if (RetypeNewPassCode.trim() === "") {
        setMessage(languageUI.pleaseEnterRetypePasscode);
      } else {
        setShowLoading(true);
        const Res_Status = await SettingChangePassword.ChangePasscode(
          UserIdStore,
          OldPassCode,
          NewPassCode,
          RetypeNewPassCode
        );
        if (Number(Res_Status.status) === 1) {
          setMessagecode("");
          setMessageSuccesscode(languageUI.changePasscodeSuccess);
          setShowLoading(false);
          dispatch(flagSlice.clearSetting());
          ReloadForm();
        } else {
          setMessageSuccesscode("");
          setMessagecode(
            Res_Status?.message
              ? Res_Status?.message
              : "Something went wrong. Please try again later!"
          );
          dispatch(flagSlice.clearSetting());
          setShowLoading(false);
        }
      }
    } else {
      navigate("/auth/login");
    }
  };

  const ePhoneOwner = async () => {
    setShowLoading(true);
    if (UserIdStore) {
      if (phoneNumber.length !== 10) {
        setMessErrPhoneNumber("The phone number is not in the correct format");
        setShowLoading(false);
        return;
      }
      const req = await SettingInformationAPI.editPhoneOwner(
        UserIdStore,
        phoneNumber
      );
      if (Number(req.status) === 1) {
        setEditPhoneNumber(true);
        setMessErrPhoneNumber("");
        setShowLoading(false);
        dispatch(flagSlice.clearSetting());
      } else {
        setShowLoading(false);
        setMessErrPhoneNumber(req.message ?? "");
        dispatch(flagSlice.clearSetting());
      }
    }
  };
  const ChangeTypeDisplay = async (value: string) => {
    setTypeDisplay(Number(value));
    if (Number(value) === 2 && Number(minuteDisPlay) < 1) {
      ChangeSetting();
    } else {
      if (UserIdStore) {
        const req = await SettingChangePassword.saveConfigDisplay(
          UserIdStore,
          Number(value),
          Number(minuteDisPlay)
        );
        if (Number(req?.status) === 1) {
          dispatch(flagSlice.clearSetting());
          dispatch(
            staffJointSlice.setSecondAllowed(Number(minuteDisPlay) * 60)
          );
          dispatch(staffJointSlice.setTypeDisplay(Number(value)));
        }
      }
    }
  };
  const changeMinutes = async (value: string) => {
    if (typeDisplay === 1 || Number(value) < 1) {
      ChangeSetting();
    } else {
      if (UserIdStore) {
        const req = await SettingChangePassword.saveConfigDisplay(
          UserIdStore,
          Number(typeDisplay),
          Number(value)
        );
        if (Number(req?.status) === 1) {
          dispatch(flagSlice.clearSetting());
          dispatch(staffJointSlice.setSecondAllowed(Number(value) * 60));
          dispatch(staffJointSlice.setTypeDisplay(Number(typeDisplay)));
        }
      }
    }
  };
  React.useEffect(() => {
    GetInfomationOwner();
    gConfigDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="w-100 h-body-password-code flex justify-center">
        <div className="w-80 bg-white h-content-password-setting  radius-3  position-relative">
          <div className="d-flex pb-3">
            <div className="w-50 rounded-5  d-flex flex-column pt-5 pl-70px">
              <span className="text-4A4A6A text-5 fw-600 font-urbansist ml-2">
                Email: {email}
              </span>
              <div className="ml-2 d-flex align-items-center gap-2">
                <p className="text-4A4A6A text-5 fw-600 font-urbansist p-0 m-0 flex-shrink-0">
                  {languageUI.phoneNumber}:
                </p>
                <input
                  type="number"
                  inputMode="numeric"
                  value={phoneNumber}
                  className={`text-4A4A6A text-5 fw-600 font-urbansist  focus-visiable-none w-160px flex-shrink-0  rounded-4 px-4 py-1 ${
                    editPhoneNumber ? "border-none" : "border-green"
                  }`}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    ChangeSetting();
                  }}
                  readOnly={editPhoneNumber}
                />
                {editPhoneNumber ? (
                  <div
                    className="h-100"
                    onClick={() => {
                      setEditPhoneNumber(false);
                    }}
                  >
                    <p className="text-blue m-0 text-5 fw-600">Edit</p>
                  </div>
                ) : (
                  <div
                    className="h-100"
                    onClick={() => {
                      ePhoneOwner();
                    }}
                  >
                    <p className="text-blue m-0 text-5 fw-600">Save</p>
                  </div>
                )}
                {mesErrPhoneNumber && (
                  <div className="text-italic text-err flex-shrink-0 ml-2">
                    <p className="m-0 text-italic text-red flex-shrink-0  font-medium">
                      {mesErrPhoneNumber}
                    </p>
                  </div>
                )}
              </div>

              <span className="text-4A4A6A text-5 fw-600 font-urbansist ml-2">
                {languageUI.address} {address}
              </span>
            </div>
            <div className="w-50 pt-5">
              <span className="text-4A4A6A text-5 fw-600 font-urbansist ml-2">
                Display:
              </span>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={typeDisplay}
                name="choose-food-group flex-shink-0"
                className="w-100"
                onChange={(e) => {
                  ChangeTypeDisplay(e.target.value);
                }}
              >
                <FormControlLabel
                  value={1}
                  control={
                    <Radio
                      sx={{
                        color: "#00A15A",
                        "&.Mui-checked": {
                          color: "#00A15A",
                        },
                      }}
                      // onChange={(e) => {
                      //   setTypeDisplay(1);
                      //   ChangeSetting();
                      // }}
                    />
                  }
                  label={
                    <p className="text-4A4A6A text-5 fw-400 font-urbansist m-0 d-inline w-100">
                      Alway on
                    </p>
                  }
                />
                <FormControlLabel
                  className="w-100 mb-1"
                  value={2}
                  control={
                    <Radio
                      sx={{
                        color: "#00A15A",
                        "&.Mui-checked": {
                          color: "#00A15A",
                        },
                      }}
                      // onChange={(e) => {
                      //   setTypeDisplay(2);
                      //   ChangeSetting();
                      // }}
                    />
                  }
                  label={
                    <p className="text-4A4A6A text-5 fw-400 font-urbansist m-0 d-inline w-100">
                      Automatic off{" "}
                      <input
                        type="number"
                        className="button-off-display"
                        value={minuteDisPlay?.toString()}
                        onChange={(e) => {
                          setMinuteDisPlay(
                            e?.target?.value
                              ? Number(e.target.value.toString())
                              : 0
                          );
                          changeMinutes(e.target.value);
                        }}
                      />{" "}
                      minute
                    </p>
                  }
                />
                <FormControlLabel
                  className="w-100 mb-1"
                  value={3}
                  control={
                    <Radio
                      sx={{
                        color: "#00A15A",
                        "&.Mui-checked": {
                          color: "#00A15A",
                        },
                      }}
                      // onChange={(e) => {
                      //   setTypeDisplay(2);
                      //   ChangeSetting();
                      // }}
                    />
                  }
                  label={
                    <p className="text-4A4A6A text-5 fw-400 font-urbansist m-0 d-inline w-100">
                      Always on + Ordering&Payments
                    </p>
                  }
                />
              </RadioGroup>
            </div>
          </div>

          <div className="w-100 d-flex flex-nowrap">
            <div className="position-relative w-50">
              <div className="flex justify-center items-center pt-6 ">
                <img src={keyPassWord} alt="" />
                <span className="text-4A4A6A text-8 fw-600 font-urbansist ml-2">
                  {languageUI.changePasscode}
                </span>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">{languageUI.oldPasscode}</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="text-label-setting-password"
                  >
                    {languageUI.passcode}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPasscode ? "number" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPasscode(!showPasscode)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={languageUI.password}
                    value={OldPassCode}
                    onChange={(e: any) => {
                      if (
                        e.target.value &&
                        (e.target.value.length > 4 ||
                          Number(e.target.value) < 0)
                      ) {
                        return;
                      }
                      setOldPassCode(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">{languageUI.newPasscode}</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="text-label-setting-password"
                  >
                    {languageUI.enterNewPasscode}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showNewPasscode ? "number" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPasscode(!showNewPasscode)}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={languageUI.enterNewPasscode}
                    value={NewPassCode}
                    onChange={(e: any) => {
                      if (
                        e.target.value &&
                        (e.target.value.length > 4 ||
                          Number(e.target.value) < 0)
                      ) {
                        return;
                      }
                      setNewPassCode(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">{languageUI.retypePasscode}</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="text-label-setting-password"
                  >
                    {languageUI.enterNewPasscodeToConfirm}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showConfirmPasscode ? "number" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPasscode(!showConfirmPasscode)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={languageUI.enterNewPasscodeToConfirm}
                    value={RetypeNewPassCode}
                    // onChange={(e: any) => { setRetypeNewPass(e.target.value) }}
                    onChange={(e: any) => {
                      if (
                        e.target.value &&
                        (e.target.value.length > 4 ||
                          Number(e.target.value) < 0)
                      ) {
                        return;
                      }
                      setRetypeNewPassCode(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="w-100">
                <p className="text-danger fs-12 mb-2 text-italic text-center">
                  {messagecode}
                </p>
                <p className="text-success fs-12 mb-2 text-center">
                  {messageSuccesscode}
                </p>
                <div className="flex items-center pb-5 px-14 justify-content-center">
                  <div className="pl-2 w-50">
                    <button
                      className="w-100 radius-2 text-5 bg-0FA54A  text-white py-2 border-none"
                      onClick={() => {
                        changePassCode();
                      }}
                    >
                      {languageUI.changePasscode}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-50 position-relative">
              <div className="flex justify-center items-center pt-6 ">
                <img src={keyPassWord} alt="" />
                <span className="text-4A4A6A text-8 fw-600 font-urbansist ml-2">
                  {languageUI.changePassword}
                </span>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">{languageUI.oldPass}</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="text-label-setting-password"
                  >
                    {languageUI.password}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={languageUI.password}
                    value={OldPass}
                    onChange={(e: any) => {
                      setOldPass(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">{languageUI.newPass}</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="text-label-setting-password"
                  >
                    {languageUI.enterNewPassword}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showNewPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={languageUI.enterNewPassword}
                    value={NewPass}
                    onChange={(e: any) => {
                      setNewPass(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex flex-column mb-2 custom-p-password">
                <span className="text-5 mb-2">{languageUI.retypePass}</span>
                <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    className="text-label-setting-password"
                  >
                    {languageUI.enterNewPasswordToConfirm}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showConfirmPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowComfirmPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label={languageUI.enterNewPasswordToConfirm}
                    value={RetypeNewPass}
                    onChange={(e: any) => {
                      setRetypeNewPass(e.target.value);
                      ChangeSetting();
                    }}
                  />
                </FormControl>
              </div>
              <div className="w-100">
                <p className="text-danger fs-12 mb-2 text-italic text-center">
                  {message}
                </p>
                <p className="text-success fs-12 mb-2 text-center">
                  {messageSuccess}
                </p>
                <div className="flex items-center justify-content-center pb-5 px-14">
                  <div className="pl-2 w-50">
                    <button
                      className="w-100 radius-2 text-5 bg-0FA54A  text-white py-2 border-none"
                      onClick={() => {
                        changePassword();
                      }}
                    >
                      {languageUI.changePass}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={imgLeaf} alt="" className="custom-position-img" />
          <img src={imgLeaf2} alt="" className="custom-position-img2" />
        </div>
      </div>
    </>
  );
};
export default PassWord;
