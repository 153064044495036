import React, { useState } from "react";
import { KeyboardArrowLeftIcon } from "../../../../../components/Image";
import { iconSearchGreen } from "../../../../../components/ImgExport";
import Topbar from "../../../../../layouts/Topbar";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface ContentTableReviewDetailProps {
  name: string;
  point: string;
  orderId: string;
  review: "1" | "2" | "3";
  phone: string;
  message: string;
}
const ContentReviewDetail = ({
  name,
  orderId,
  phone,
  point,
  review = "1",
  message,
}: ContentTableReviewDetailProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TableRow className="d-flex align-items-center mb-2">
        <TableCell className="text-269AFF text-17px font-urbansist w-10 border-none">
          {point}
        </TableCell>
        <TableCell className="text-32324D text-17px font-urbansist w-18 border-none">
          {name}
        </TableCell>
        <TableCell
          align="left"
          className="text-32324D text-17px font-urbansist w-18 border-none"
        >
          {orderId}
        </TableCell>
        <TableCell align="left" className="w-18 border-none">
          <div className="">
            <span
              className={`radius-1  text-17px font-urbansist p-review-inf border-none ${
                review === "1"
                  ? "bg-12B0641A text-12B064"
                  : review === "2"
                  ? "bg-1E7BCC text-1E7BCC"
                  : "text-F24343  bg-F243431A"
              }`}
            >
              {review === "1"
                ? "Good 👍"
                : review === "2"
                ? "Neutral 😐"
                : "Bad 👎"}
            </span>
          </div>
        </TableCell>
        <TableCell
          align="left"
          className="text-269AFF text-17px font-urbansist w-18 border-none"
        >
          {message}
        </TableCell>
        <TableCell
          align="left"
          className="text-32324D text-17px font-urbansist w-18 border-none"
        >
          {phone}
        </TableCell>
      </TableRow>
    </>
  );
};

const DetailReview: React.FC = () => {
  const navigate = useNavigate();
  const [chooseOptionReview, setChooseOptionReview] = React.useState<number>(0);
  return (
    <>
      <div className="">{/* <Topbar pageName="ReviewDetail" /> */}</div>
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex  ml-12">
            <div className="w-100 flex items-center py-3">
              <div className="d-flex justify-between  ">
                <div
                  className="w-100 flex items-center "
                  onClick={() => navigate("/web-owner/review")}
                >
                  <div className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center mr-18px">
                    <KeyboardArrowLeftIcon />
                  </div>
                  <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                    Review detail
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12  px-4">
        <div className="bg-white radius-18px ml-12 w-95">
          <div className="h-70px custom-radius-table  bg-white px-4 d-flex align-items-center border-bottom container-fluid justify-content-between">
            <div className="d-flex align-items-center justify-between h-100 pe-2 gap-2">
              <p className="text-5 text-212134 mb-0 d-flex align-items-center">
                Customer review
              </p>
              <div
                className={`d-flex align-items-center  p-review  rounded-8px ${
                  chooseOptionReview == 1 ? "border-0FA54A" : "border"
                }`}
                onClick={() => setChooseOptionReview(1)}
              >
                <span className="text-#a5a5ba font-urbansist text-18px">
                  👍 Good
                </span>
              </div>
              <div
                className={`d-flex align-items-center  p-review  rounded-8px ${
                  chooseOptionReview == 2 ? "border-0FA54A" : "border"
                }`}
                onClick={() => setChooseOptionReview(2)}
              >
                <span className="text-#a5a5ba font-urbansist text-18px">
                  😐 Neutral
                </span>
              </div>
              <div
                className={`d-flex align-items-center  p-review  rounded-8px ${
                  chooseOptionReview == 3 ? "border-0FA54A" : "border"
                }`}
                onClick={() => setChooseOptionReview(3)}
              >
                <span className="text-#a5a5ba font-urbansist text-18px">
                  👎 Bad
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center ps-3 w-50 border-left">
              <div className="flex items-center">
                <img src={iconSearchGreen} alt="" className="pr-2" />
                <input
                  type="text"
                  className="focus-visible-none input-find-by-phone-employee w-fit text-4A4A6A"
                  placeholder="Find review by phone...."
                />
              </div>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="text-12B064 text-4 fw-600 font-urbansist w-10">
                    Points
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-12B064 text-4 fw-600 font-urbansist w-18"
                  >
                    Customer Name
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-12B064 text-4 fw-600 font-urbansist w-18"
                  >
                    Order ID
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-12B064 text-4 fw-600 font-urbansist w-18"
                  >
                    Review
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-12B064 text-4 fw-600 font-urbansist w-18"
                  >
                    Message review
                  </TableCell>
                  <TableCell
                    align="left"
                    className="text-12B064 text-4 fw-600 font-urbansist w-18"
                  >
                    Phone
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="body-table-reivew">
                <ContentReviewDetail
                  message="View"
                  name="sada"
                  orderId="123"
                  phone="123"
                  point="2131"
                  review="1"
                />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default DetailReview;
