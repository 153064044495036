import { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/PageTitle";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as SettingUpdatePhoneNumberAdminAPI from "../../api/apiAdmin/SettingUpdatePhoneNumberAdmin";
import MaskedInput from "react-text-mask";
import React from "react";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

const SettingUpdatePhoneNumberAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [Phone, setPhone] = useState<string>("");
  const [Message, setMessage] = useState<any>("");
  const [MessageSuccess, setMessageSuccess] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState(false);
  const languageUI = getLanguageCheckIn();
  const [phoneSupport, setPhoneSupport] = useState<number>();
  const [SMSSupport, setSMSSupport] = useState<string>();
  const [webSupport, setWebSupport] = useState<string>("");

  const { t } = useTranslation();
  let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  menuItemElAdmin.forEach((item, i) => {
    menuItemElAdmin[i]?.classList.remove("d-none");
  });
  menuItemElOwner.forEach((item, i) => {
    menuItemElOwner[i]?.classList.add("d-none");
  });

  const GetPhone = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const GetPhoneNumber =
        await SettingUpdatePhoneNumberAdminAPI.gSettingUpdatePhone(UserIdStore);
      if (Number(GetPhoneNumber.status) === 1) {
        setMessage("");
        setPhone(GetPhoneNumber.data?.phone);
        setSMSSupport(GetPhoneNumber.data?.sms_support);
        setWebSupport(GetPhoneNumber.data.web_support);
      } else {
        setMessage(
          GetPhoneNumber?.message
            ? GetPhoneNumber?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
        setPhone("");
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhone(phoneReplace);
  };

  const onchagePhoneSupport = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneSupport(phoneReplace);
  };

  const onchageSMSSupport = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setSMSSupport(phoneReplace);
  };

  const ChangePhone = async () => {
    if (Phone.length !== 10) {
      setMessage(languageUI.phoneNumberFormatConsistsOf10Numbers);
      return;
    }
    if (UserIdStore) {
      setShowLoading(true);
      const ChangePhoneNumber =
        await SettingUpdatePhoneNumberAdminAPI.ChangePhone(UserIdStore, Phone);
      if (Number(ChangePhoneNumber.status) === 1) {
        setMessage("");
        setMessageSuccess(languageUI.Changephonesuccess);
        setTimeout(() => {
          setShowLoading(false);
          window.location.reload();
        }, 2000);
      } else {
        setMessage(
          ChangePhoneNumber?.message
            ? ChangePhoneNumber?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
        setMessageSuccess("");
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const ReloadForm = () => {
    setPhone("");
    setMessage("");
    setMessageSuccess("");
    setShowLoading(false);
  };

  const handleSettingSmsSupport = async () => {
    if (SMSSupport?.length !== 10) {
      setMessage(languageUI.phoneNumberFormatConsistsOf10Numbers);
      return;
    }
    if (UserIdStore) {
      setShowLoading(true);

      const req = await SettingUpdatePhoneNumberAdminAPI.settingSmsSupport(
        UserIdStore,
        SMSSupport
      );
      if (req.status === 1) {
        setMessageSuccess("Change Success");
        setShowLoading(false);
      } else {
        setMessage(
          req?.message
            ? req?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
        setSMSSupport("");
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  const handleSettingWebSupport = async () => {
    if (webSupport === "") {
      setMessage("Please enter web support!");
      return;
    }
    if (UserIdStore) {
      setShowLoading(true);

      const req = await SettingUpdatePhoneNumberAdminAPI.settingWebSupport(
        UserIdStore,
        webSupport
      );
      if (req.status === 1) {
        setMessageSuccess("Change Success");
        setShowLoading(false);
      } else {
        setMessage(
          req?.message
            ? req?.message
            : languageUI.SomethingwentwrongPleasetryagainlater
        );
        setWebSupport("");
      }
      setShowLoading(false);
    } else {
      navigate("/web-admin/login");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setMessageSuccess("");
    }, 3000);
  }, [MessageSuccess]);

  useEffect(() => {
    ReloadForm();
    GetPhone();
  }, [location.pathname]);

  return (
    <div className="">
      <div className="mt-header px-6">
        <PageTitle
          breadCrumbItems={[
            { label: languageUI.Setting, path: "#" },
            { label: languageUI.Updatephonenumber, path: "#", active: true },
          ]}
          title={languageUI.Settingphonenumber}
        />
      </div>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="px-6 rounded-3 mb-3  ">
        <Col xs={12} sm={12} md={6} lg={5}>
          <Form.Label className="text-danger">{Message}</Form.Label>
          <Form.Label className="text-success">{MessageSuccess}</Form.Label>
          <div className="d-flex align-items-center w-100">
            {/* <Form.Control
              type="text"
              placeholder="Your phone number"
              value={Phone}
              onChange={(e: any) => {
                setPhone(e.target.value);
              }}
            /> */}
            <div className="w-200px flex-shink-0">
              <p className="m-0 font-bold text-4a4a6a whitespace-nowrap flex-shỉnk-0">
                Phone Support
              </p>
            </div>
            <MaskedInput
              mask={[
                "(",
                /[0-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholder="(___) ___-____"
              className="form-control"
              value={Phone}
              onChange={onchagePhoneNumber}
            />
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                ChangePhone();
              }}
            >
              {t(languageUI.Save)}
            </Button>
          </div>
          <div className="d-flex align-items-center w-100 mt-2">
            <div className="w-200px flex-shink-0">
              <p className="m-0 font-bold text-4a4a6a whitespace-nowrap flex-shỉnk-0">
                SMS Support
              </p>
            </div>
            <MaskedInput
              mask={[
                "(",
                /[0-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              placeholder="(___) ___-____"
              className="form-control"
              value={SMSSupport}
              onChange={onchageSMSSupport}
            />
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                handleSettingSmsSupport();
              }}
            >
              {t(languageUI.Save)}
            </Button>
          </div>
          <div className="d-flex align-items-center w-100 mt-2">
            <div className="w-200px flex-shink-0">
              <p className="m-0 font-bold text-4a4a6a whitespace-nowrap flex-shỉnk-0">
                Web Support
              </p>
            </div>
            <input
              type="text"
              className="input-setting-info w-100"
              value={webSupport}
              placeholder="Please enter link web support"
              onChange={(e) => {
                setWebSupport(e.target.value);
              }}
            />
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => {
                handleSettingWebSupport();
              }}
            >
              {t(languageUI.Save)}
            </Button>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default SettingUpdatePhoneNumberAdmin;
