// import { EastIcon, arrowDouble } from "../../../../../../components/Image"
import React, { useEffect, useState } from "react";
import "../Styles.css"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { pencilEditMenu, strashRed } from "../../../../../components/ImgExport";
import { Form } from "react-bootstrap";
import ModalEditVoucher from "./ModalEditVoucher";
import ModalDeleteVoucher from "./ModalDeleteVoucher/ModalDeleteVoucher";
import { upEnableVoucher } from "../../../../../api/apiOwner/voucherApi";
import { getLanguageUI } from "../../../../../Services/languageUI";

interface ContentTableVoucherProps {
    numerical: number;
    voucherID: string;
    createAt: string;
    expiryDate: string;
    voucherName: string;
    voucherCode: string;
    discountValue: string;
    codeName: string;
    enable: number;
    HandleEditSuccess: () => void;
    setShowLoading: (e: boolean) => void;
}
const ContentTableVoucher = ({
    voucherID,
    createAt,
    expiryDate,
    discountValue,
    numerical,
    codeName,
    voucherCode,
    voucherName,
    enable = 0,
    setShowLoading,
    HandleEditSuccess

}: ContentTableVoucherProps) => {
    const languageUI = getLanguageUI().voucherPage
    const [showModalEditVoucher, setShowModalEditVoucher] = useState(false);
    const [showModalDeleteVoucher, setShowModalDeleteVoucher] = useState(false);
    const [checked, setChecked] = useState<boolean>(false)

    const dismisModalDeleteVoucher = () => {
        setShowModalDeleteVoucher(false)
    }
    const dismisModalEditVoucher = () => {
        setShowModalEditVoucher(false)
    }
    const enableVoucher = async (id: string, enable: number) => {
        await upEnableVoucher(id, enable);
    }
    useEffect(() => {
        setChecked(enable === 1 ? true : false)
    }, [])
    return (
        <>
            <TableRow className="d-flex align-items-center">
                <TableCell className="text-269AFF text-17px fw-500  font-urbansist w-8 border-none">{numerical}</TableCell>
                <TableCell className="text-32324D text-17px fw-500  font-urbansist w-10 border-none">
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        className="h-20px w-36px mb-1"
                        checked={checked}
                        onChange={(e) => { setChecked(!checked); enableVoucher(voucherID, !checked ? 1 : 0) }}
                    />
                </TableCell>
                {/* <TableCell align="left" className="text-32324D text-17px fw-500 font-urbansist w-10 border-none">{voucherCode}</TableCell> */}
                <TableCell align="left" className="w-14 text-32324D text-17px fw-500 font-urbansist  border-none">{createAt}</TableCell>
                <TableCell align="left" className="text-32324D text-17px fw-500 font-urbansist w-14 border-none">{expiryDate} {languageUI.month}</TableCell>
                <TableCell align="left" className="text-32324D text-17px fw-500 font-urbansist w-19 border-none">{voucherName}</TableCell>
                <TableCell align="left" className="text-32324D text-17px fw-500 font-urbansist w-10 border-none">{codeName}</TableCell>
                <TableCell align="right" className="text-32324D text-17px fw-500 font-urbansist w-10 border-none" >
                    {discountValue}
                </TableCell>
                <TableCell align="right" className="text-32324D text-17px fw-500 font-urbansist w-15 border-none">
                    <div className="d-flex gap-2 justify-content-end">
                        <div className="btn-delete-edit-staff" onClick={() => setShowModalEditVoucher(true)}>
                            <img src={pencilEditMenu} alt="" />
                        </div>
                        <div className="btn-delete-edit-staff" onClick={() => setShowModalDeleteVoucher(true)}>
                            <img src={strashRed} alt="" />
                        </div>
                    </div>
                </TableCell>

            </TableRow>
            <ModalEditVoucher handleClose={dismisModalEditVoucher} show={showModalEditVoucher} voucherID={voucherID} setShowLoading={setShowLoading} HandleEditSuccess={HandleEditSuccess} />
            <ModalDeleteVoucher handleClose={dismisModalDeleteVoucher} show={showModalDeleteVoucher} voucherID={voucherID} HandleDelSuccess={HandleEditSuccess} setShowLoading={setShowLoading} />
        </>
    )
}
export default ContentTableVoucher