import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';
import { green, orange, red } from '../../../../../components/Image';
import '../Styles.css'
import { useState } from 'react';
import { ChartReview } from '../../../../../api/apiOwner/ReviewOwner';
import { getLanguageUI } from '../../../../../Services/languageUI';

interface ChartDonutProp {
    infoChart: ChartReview[];
    TotalReview: number;
}
const ChartDonut: React.FC<ChartDonutProp> = ({ infoChart = [], TotalReview = 0 }) => {
    const languageUI = getLanguageUI().reviewPage
    const [numberClickChart, setNumberClickChart] = useState<number>(100)
    const [valueNameClickChart, setValueNameClickChart] = useState<string>("Total")
    return (
        <>
            <div className="flex items-center justify-between mb-18px">
                <div className='flex flex-column'>
                    <span className="text-5 mb-1 text-212134">{languageUI.overview}</span>
                    <span className='text-3 fw-600 font-urbansist text-8E8EA9'>{languageUI.totalReview}</span>
                </div>
                <div className='flex items-center'>
                    <span className='text-9 fw-600 font-urbansist text-212134'>{TotalReview}</span>
                </div>
            </div>
            <div className='flex items-center justify-center py-3 flex-column gap-2'>
                <div className="position-relative d-flex justify-content-center align-items-center">
                    <Stack direction="row">
                        <PieChart
                            series={[
                                {
                                    paddingAngle: 5,
                                    innerRadius: 60,
                                    outerRadius: 80,
                                    data: infoChart,
                                },
                            ]}

                            margin={{ right: 5 }}
                            width={160}
                            height={160}
                            legend={{ hidden: true }}
                            colors={infoChart.map(item => { return item.color })}
                        />

                    </Stack>
                    <div className='flex flex-column custom-percent-review'>
                        <span className="text-9 font-urbansist fw-600 text-4A4A6A text-center">{numberClickChart}%</span>
                        <span className='text-17px font-urbansist text-4A4A6A text-center'>{valueNameClickChart}</span>
                    </div>
                </div>

                <div className=''>
                    {infoChart.map(item => (
                        <>
                            <div className='flex items-center justify-between mb-3' onClick={() => { setNumberClickChart(item.percent); setValueNameClickChart(item.label) }}>
                                <div className='flex items-center'>
                                    <div className='box-color-chart flex-shink-0' style={{ backgroundColor: item.color }}>
                                    </div>
                                    <span className='text-17px fw-500 font-urbansist text-4A4A6A pl-2'>{item.label}</span>
                                </div>
                                <span className='text-17px fw-500 font-urbansist text-4A4A6A pl-6'>{item.value}</span>

                                <span className='text-17px fw-500 font-urbansist text-#a5a5ba pl-7'>{item.percent}%</span>
                            </div>
                        </>
                    ))}

                    {/* <div className='flex items-center justify-between mb-3'>
                        <div className='flex items-center'>
                            <img src={orange} alt="" />
                            <span className='text-17px fw-500 font-urbansist text-4A4A6A pl-2'>Neutral</span>
                        </div>
                        <span className='text-17px fw-500 font-urbansist text-4A4A6A pr-14px'>3000</span>
                        <span className='text-17px fw-500 font-urbansist text-#a5a5ba'>33%</span>
                    </div>
                    <div className='flex items-center justify-between'>
                        <div className='flex items-center'>
                            <img src={red} alt="" />
                            <span className='text-17px fw-500 font-urbansist text-4A4A6A pl-2'>Bad</span>
                        </div>
                        <span className='text-17px fw-500 font-urbansist text-4A4A6A'>3000</span>
                        <span className='text-17px fw-500 font-urbansist text-#a5a5ba'>5%</span>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default ChartDonut