import * as Request from "../Request";

export interface InfoOrder {
  id?: string;
  owner_id?: string;
  custommer_id?: string;
  table_id?: string;
  service_item_id?: string;
  price?: string | number;
  quantity?: string;
  notefood_id?: string;
  side_id?: string;
  form_order?: string;
  note?: string;
  name?: string;
  imageCourse?: string;
  donvitinh?: string;
  table_name?: string;
  notefood_name?: string;
  side_name?: string;
  slcon?: string;
  insufficient?: boolean;
  description?: string;
  typeFood?: number;
}
export interface RequestOrder {
  id?: string;
  order_id?: string;
  request_name?: string;
}
export interface SideList {
  id: string;
  name: string;
  price: string;
  quantity: string;
  typeFood: string;
}
export interface Result {
  status: number;
  message?: string;
  totalFood: string;
  totalPrice: string;
  order_id: string;
  requestList?: RequestOrder[];
  data?: InfoOrder[];
  staff_name?: string;
  tip_staff_id?: string;
  tip_amount?: string;
  voucher_id: string;
  voucher_code: string;
  discount: string;
  SideList: SideList[];
}
export interface listSide {
  id: string;
  imageCourse: string;
  name: string;
  price: string;
  quantity: string;
  side_id: string;
  table_id: string;
  typeFood: string;
}
export interface OrderWait {
  custommer_id: string;
  name: string;
  listFood: InfoOrder[];
  order_id: string;
  totalPrice: string;
  staff_name: string;
  tip_amount: string;
  tip_staff_id: string;
  discount: string;
  listSide?: listSide[];
  guestNumber: number;
}
export interface OrderPickupWait {
  custommer_id: string;
  name: string;
  listFood: InfoOrder[];
  order_id: string;
  totalPrice: string;
  staff_name: string;
  tip_amount: string;
  tip_staff_id: string;
  discount: string;
  listSide?: listSide[];
  guestNumber: number;
  time_order: string,
  customer_phone: string;
}

export interface ResultOrderWait {
  status: number;
  message?: string;
  data?: OrderWait[];
}
export interface ResultOrder<T> {
  status: number;
  message: string;
  data: T;
}
export interface ResultData {
  status: number;
  message?: string;
  data?: IDetailBill;
}

export interface IDetailBill {
  id: number;
  owner_id: number;
  custommer_id: number;
  table_id: number;
  table_name: string;
  custommer_name: string;
  timestart: string;
  timeend: string;
  phone: string;
  vat_amount: number;
  total_order: number;
  tip_amount: number;
  staff_name: any;
  discount: number;
  feePrice: number;
  sub_total: number;
  tax_percent: number;
  time_order: string;
  listFood: ListFood[];
}

export interface ListFood {
  id: number;
  service_item_id: number;
  price: number;
  quantity: number;
  notefood_id: number;
  side_id: number;
  form_order: number;
  note: string;
  name: string;
  imageCourse: string;
  donvitinh: any;
  notefood_name?: string;
  side_name: any;
  description: string;
}
export const gDetailBill = async (owner_id: number, order_id: number) => {
  return await Request.fetchAPI(`owner/gDetailBill.php`, "POST", {
    owner_id,
    order_id,
  })
    .then((res: ResultData) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: err,
      };
    });
};

export const gOrderConfilmPay = async (
  custommerId: string,
  ownerID: number,
  table_id: string
) => {
  return await Request.fetchAPI(`checkin/gorderConfilmPay.php`, "POST", {
    custommerId,
    ownerID,
    table_id,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        totalFood: "0",
        totalPrice: "0",
        requestList: [],
        order_id: "0",
        data: [],
        staff_name: "",
        tip_staff_id: "",
        tip_amount: "",
        voucher_id: "0",
        voucher_code: "",
        discount: "0",
        SideList: [],
      };
    });
};

export const gListOrderWaitByTable = async (
  owner_id: number,
  table_id: string,
  paymentMethod: number = 1
) => {
  return await Request.fetchAPI(`owner/gAllFoodInTable.php`, "POST", {
    owner_id,
    table_id,
    paymentMethod,
  })
    .then((res: ResultOrderWait) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const splitBill = async (
  owner_id: number,
  order_id: string,
  listFoodChange: string[]
) => {
  return await Request.fetchAPI(`owner/splitBill.php`, "POST", {
    owner_id,
    order_id,
    listFoodChange: JSON.stringify(listFoodChange),
  })
    .then((res: ResultOrder<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const voidBill = async (
  owner_id: number,
  order_id: string,
  listFoodVoid: string[]
) => {
  return await Request.fetchAPI(`owner/voidBill.php`, "POST", {
    owner_id,
    order_id,
    listFoodVoid: JSON.stringify(listFoodVoid),
  })
    .then((res: ResultOrder<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
// Lấy danh sách món ăn đã đặt(confilm order)

export const gOrderConfilmPayOnline = async (
  custommerId: string,
  ownerID: number
) => {
  return await Request.fetchAPI(`checkin/gorderConfilmPayOnline.php`, "POST", {
    custommerId,
    ownerID,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        totalFood: "0",
        totalPrice: "0",
        requestList: [],
        order_id: "0",
        data: [],
        staff_name: "",
        tip_staff_id: "",
        tip_amount: "",
        voucher_id: "0",
        voucher_code: "",
        discount: "0",
        SideList: [],
      };
    });
};

export const gListOrderPickupWait = async (
  owner_id: number, page: number = 1, limit: number = 10
) => {
  return await Request.fetchAPI(`owner/gAllorderPickupWait.php`, "POST", {
    owner_id, page, limit
  })
    .then((res: ResultOrder<{ total: number, totalPage: number, listOrder: OrderPickupWait[] }>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};