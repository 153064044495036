import React, { useRef, useEffect, forwardRef, useState } from "react";
import { Button, Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import classNames from "classnames";
import "./styles.css";

// components
import Pagination from "./Pagination";
import Select from "react-select";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
import { calender } from "../../../../components/ImgExport";

interface GlobalFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
  searchBoxClass: any;
}
const languageUI = getLanguageCheckIn();
// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  searchBoxClass,
}: GlobalFilterProps) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState<any>(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className={classNames(searchBoxClass)}>
      <span className="d-flex align-items-center">
        {languageUI.Search} :{" "}
        <input
          type="search"
          value={value || ""}
          onChange={(e: any) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`${count} ${languageUI.records}...`}
          className="form-control w-auto ms-1"
        />
      </span>
    </div>
  );
};

interface IndeterminateCheckboxProps {
  indeterminate: any;
  children?: React.ReactNode;
}

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <div className="form-check ps-0">
        <input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
        <label htmlFor="form-check-input" className="form-check-label"></label>
      </div>
    </>
  );
});

interface TableProps {
  isSearchable?: boolean;
  isSortable?: boolean;
  pagination?: boolean;
  isSelectable?: boolean;
  isExpandable?: boolean;
  sizePerPageList?: {
    text: string;
    value: number;
  }[];
  columns: {
    Header: string;
    accessor: string;
    sort?: boolean;
    Cell?: any;
    className?: string;
  }[];
  data: any[];
  pageSize?: any;
  searchBoxClass?: string;
  tableClass?: string;
  theadClass?: string;
  overflowDropdown?: boolean;
  yearSearch?: number;
  nextYearSearch?: number;
  FilterCustomer?: (type: number) => void;
  handleShowMess: () => void;
  handleShowSendEmail: () => void;
  handleChangeYearOption: (value: number) => void;
  handleChangeNextYearOption: (value: number) => void;
}

const Table = (props: TableProps) => {
  const isSearchable = props["isSearchable"] || false;
  const isSortable = props["isSortable"] || false;
  const pagination = props["pagination"] || false;
  // const isSelectable = props["isSelectable"] || false;
  const isExpandable = props["isExpandable"] || false;
  const sizePerPageList = props["sizePerPageList"] || [];
  const overflowDropdown = props["overflowDropdown"] || [];

  let otherProps: any = {};

  if (isSearchable) {
    otherProps["useGlobalFilter"] = useGlobalFilter;
  }
  if (isSortable) {
    otherProps["useSortBy"] = useSortBy;
  }
  if (isExpandable) {
    otherProps["useExpanded"] = useExpanded;
  }
  if (pagination) {
    otherProps["usePagination"] = usePagination;
  }
  // if (isSelectable) {
  //   otherProps["useRowSelect"] = useRowSelect;
  // }

  const dataTable = useTable(
    {
      columns: props["columns"],
      data: props["data"],
      initialState: { pageSize: props["pageSize"] || 10 },
    },
    otherProps.hasOwnProperty("useGlobalFilter") &&
      otherProps["useGlobalFilter"],
    otherProps.hasOwnProperty("useSortBy") && otherProps["useSortBy"],
    otherProps.hasOwnProperty("useExpanded") && otherProps["useExpanded"],
    otherProps.hasOwnProperty("usePagination") && otherProps["usePagination"],
    otherProps.hasOwnProperty("useRowSelect") && otherProps["useRowSelect"]

    // (hooks) => {
    //   isSelectable &&
    //     hooks.visibleColumns.push((columns: any) => [
    //       // Let's make a column for selection
    //       {
    //         id: "selection",
    //         // The header can use the table's getToggleAllRowsSelectedProps method
    //         // to render a checkbox
    //         Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
    //           <div>
    //             <IndeterminateCheckbox
    //               {...getToggleAllPageRowsSelectedProps()}
    //             />
    //           </div>
    //         ),
    //         // The cell can use the individual row's getToggleRowSelectedProps method
    //         // to the render a checkbox
    //         Cell: ({ row }: any) => (
    //           <div>
    //             <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //           </div>
    //         ),
    //       },
    //       ...columns,
    //     ]);

    //   isExpandable &&
    //     hooks.visibleColumns.push((columns: any) => [
    //       // Let's make a column for selection
    //       {
    //         // Build our expander column
    //         id: "expander", // Make sure it has an ID
    //         Header: ({
    //           getToggleAllRowsExpandedProps,
    //           isAllRowsExpanded,
    //         }: any) => (
    //           <span {...getToggleAllRowsExpandedProps()}>
    //             {isAllRowsExpanded ? "-" : "+"}
    //           </span>
    //         ),
    //         Cell: ({ row }) =>
    //           // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
    //           // to build the toggle for expanding a row
    //           row.canExpand ? (
    //             <span
    //               {...row.getToggleRowExpandedProps({
    //                 style: {
    //                   // We can even use the row.depth property
    //                   // and paddingLeft to indicate the depth
    //                   // of the row
    //                   paddingLeft: `${row.depth * 2}rem`,
    //                 },
    //               })}
    //             >
    //               {row.isExpanded ? "-" : "+"}
    //             </span>
    //           ) : null,
    //       },
    //       ...columns,
    //     ]);
    // }
  );

  let rows = pagination ? dataTable.page : dataTable.rows;
  const listMont = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [yearOption, setYearOption] = useState<number[]>([]);
  const [nextYearOption, setNextYearOption] = useState<number[]>([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let year = currentYear; year >= 2023; year--) {
      yearsArray.push(Number(year));
    }
    setYearOption(yearsArray);
    setNextYearOption(yearsArray);
  }, []);

  return (
    <>
      <div className="d-flex w-100 justify-content-between pe-4">
        <div className="px-4">
          {isSearchable && (
            <GlobalFilter
              preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
              globalFilter={dataTable.state.globalFilter}
              setGlobalFilter={dataTable.setGlobalFilter}
              searchBoxClass={props["searchBoxClass"]}
            />
          )}
        </div>
        <div className="text-end  btn-sm-relative gap-3 flex gap-3">
          <div className="d-flex align-items-center border  px-2  rounded-8px h-42px gap-3">
            <img src={calender} alt="" />
            <Select
              className="react-select react-select-container w-180px  border-select-none"
              classNamePrefix="react-select"
              defaultValue={[""]}
              placeholder={"Year"}
              isClearable={true}
              value={yearOption
                .filter((item) => item === props.yearSearch)
                .map((e) => {
                  return {
                    value: e,
                    label: e,
                  };
                })}
              options={yearOption.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              // options={[
              //   {
              //     value: 1,
              //     label: languageUI.New,
              //   },
              //   {
              //     value: 0,
              //     label: languageUI.Old,
              //   },
              // ]}
              onChange={(e: any) =>
                props.handleChangeYearOption(Number(e.value))
              }
            />
          </div>
          <div className="d-flex align-items-center border  px-2  rounded-8px h-42px gap-3">
            <img src={calender} alt="" />
            <Select
              className="react-select react-select-container w-180px  border-select-none"
              classNamePrefix="react-select"
              defaultValue={[""]}
              placeholder={"Next Year"}
              isClearable={true}
              value={nextYearOption
                .filter((item) => item === props.nextYearSearch)
                .map((e) => {
                  return {
                    value: e,
                    label: e,
                  };
                })}
              options={nextYearOption.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })}
              onChange={(e: any) =>
                props.handleChangeNextYearOption(Number(e.value))
              }
            />
          </div>
          <Button variant="outline-primary" onClick={props.handleShowSendEmail}>
            <i className="fe-send me-2"></i>
            {languageUI.SendEmailtoallcustomerinthisgroup}
          </Button>
          <Button
            onClick={props.handleShowMess}
            variant="outline-primary"
            className="bg-primary text-white"
          >
            <i className="fe-send me-2 text-white"></i>
            {languageUI.SendSMSAllCustomer}
          </Button>
        </div>
      </div>

      <div
        role="region"
        aria-label="data table"
        tabIndex={0}
        className={`box-table-group-customer ${
          props["overflowDropdown"] ? "overflow-visible" : ""
        }`}
      >
        <table
          {...dataTable.getTableProps()}
          className={classNames(
            "table table-centered react-table table-group-customer",
            props["tableClass"]
          )}
        >
          <thead className={props["theadClass"]}>
            {(dataTable.headerGroups || []).map((headerGroup: any) => (
              <tr className="px-3" {...headerGroup.getHeaderGroupProps()}>
                {(headerGroup.headers || []).map((column: any) => (
                  <th
                    clasName="pin number-length-table w-100px d-none"
                    {...column.getHeaderProps(
                      column.sort && column.getSortByToggleProps()
                    )}
                    className={classNames({
                      sorting_desc: column.isSortedDesc === true,
                      sorting_asc: column.isSortedDesc === false,
                      sortable: column.sort === true,
                    })}
                    style={{
                      // display: `${
                      //   column.render("Footer") === "hide" ? "none" : ""
                      // }`,
                      display: "none",
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th className="number-length-table">#</th>
                <th className="header-customer-name">
                  {languageUI.Customername}
                </th>
                <th>{languageUI.StoreInfo}</th>
                <th>suit_of_unit</th>
                <th>
                  Package & <br /> {languageUI.Datejoin}
                </th>
                <th>
                  Package <br /> {languageUI.Dateexpire}
                </th>
                <th>
                  SMS
                  <br />
                  {languageUI.remain}
                </th>
                <th>
                  {languageUI.Last4} <br /> {languageUI.onCard}
                </th>
                {/* <th>
                  Rating
                  <br />
                  when joint
                </th>
                <th>
                  Rating
                  <br />
                  now
                </th> */}
                <th align="center">{languageUI.Active}</th>
                <th align="center">
                  <div className="d-flex justify-content-center">
                    {/* <Select
                      className="react-select react-select-container w-150px  border-select-none px-4 bg-white rounded-3 border-primary"
                      classNamePrefix="react-select"
                      defaultValue={[""]}
                      placeholder={languageUI.View}
                      isClearable={true}
                      options={[
                        {
                          value: 1,
                          label: languageUI.New,
                        },
                        {
                          value: 0,
                          label: languageUI.Old,
                        },
                      ]}
                      onChange={(e: any) =>
                        props?.FilterCustomer?.(e?.value ?? 0)
                      }
                    /> */}
                    <Select
                      className="react-select react-select-container w-150px  border-select-none"
                      classNamePrefix="react-select"
                      defaultValue={[""]}
                      placeholder={languageUI.View}
                      isClearable={true}
                      isSearchable={false}
                      options={[
                        {
                          value: 1,
                          label: languageUI.New,
                        },
                        {
                          value: 0,
                          label: languageUI.Old,
                        },
                      ]}
                      onChange={(e: any) =>
                        props?.FilterCustomer?.(e?.value ?? 0)
                      }
                    />
                  </div>
                </th>
                <th>
                  <div className="w-100 d-flex justify-content-center ">
                    Last year
                  </div>
                </th>
                <th>
                  <div className="w-100 d-flex justify-content-center ">
                    Last month
                  </div>
                </th>
                <th>
                  <div className="w-200px d-flex justify-content-center ">
                    Google Review
                  </div>
                </th>
                {listMont.map((item, index) => (
                  <th align="center" key={index}>
                    <div className="w-100 d-flex justify-content-center">
                      {item}
                    </div>
                  </th>
                ))}
                {listMont.map((item, index) => (
                  <th align="center" key={index}>
                    <div className="w-100 d-flex justify-content-center">
                      {item}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...dataTable.getTableBodyProps()}>
            {(rows || []).map((row: any, i: number) => {
              dataTable.prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {(row.cells || []).map((cell: any, index: number) => {
                    if (index === 1 || index === 0) {
                      return (
                        <th
                          className={`th-sticky-tbody-${index}`}
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </th>
                      );
                    } else {
                      return (
                        <td
                          className="z-index--10"
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                            },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pagination && (
        <Pagination tableProps={dataTable} sizePerPageList={sizePerPageList} />
      )}
    </>
  );
};

export default Table;
