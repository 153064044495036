import { Modal } from "react-bootstrap";
import "./Styles.css";
import { leaf1, leaf2 } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import { EditFloorName } from "../../../../api/apiOwner/FloorApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { SketchPicker } from "react-color";

interface ModalEditNameTableProps {
  show: boolean;
  floorNameEdit: string;
  floorIdEdit: string;
  is_tmp: boolean;
  bgColor: string;
  EditFloorTmp: (floorID: string, FloorName: string, color: string) => void;
  handleSuccess: () => void;
  handleClose: () => void;
}

const ModalEditNameTable = ({
  show,
  floorIdEdit,
  floorNameEdit,
  is_tmp = true,
  EditFloorTmp,
  handleClose,
  handleSuccess,
  bgColor,
}: ModalEditNameTableProps) => {
  const [messageError, setMessageError] = useState("");
  const [floorName, setFloorName] = useState<string>("");
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorPickerRGBa, setColorPickerRGBa] = useState("#f17013");
  const [changeColorHex, setChangeColorHex] = useState<string>("#f17013");

  const handelClickColor = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleCloseColor = () => {
    setDisplayColorPicker(false);
  };
  const handleChangeColor = (color: any) => {
    setColorPickerRGBa(color.hex);
    setChangeColorHex(color.hex);
  };
  const SubmitSaveFloor = async () => {
    if (floorName.trim() === "") {
      setMessageError("Please enter floor name!");
    } else {
      if (is_tmp) {
        EditFloorTmp(floorIdEdit, floorName, colorPickerRGBa);
      } else {
        const reqEdit = await EditFloorName(
          floorIdEdit,
          floorName,
          UserIdStore ?? 0,
          colorPickerRGBa
        );
        if (Number(reqEdit?.status) === 1) {
          handleClose();
          handleSuccess();
        } else {
          setMessageError(reqEdit?.message ?? "");
        }
      }
    }
  };
  useEffect(() => {
    if (show) {
      setMessageError("");
      setFloorName(floorNameEdit);
      setColorPickerRGBa(bgColor);
    }
  }, [show]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-clock-in-out modal-edit-name-table"
      >
        <Modal.Dialog>
          <Modal.Body>
            <div className="position-relative px-3 d-flex align-items-center flex-column">
              <div className="text-center">
                <p className="text-delete-modal-customer mb-1 text-center">
                  Floor Management
                </p>
                <p className="text-center text-danger text-italic mb-1">
                  {messageError}
                </p>
              </div>
              <p className="text-add-table-name mb-1 mt-3 text-left w-100">
                Floor Name
              </p>
              <div className="box-edit-name-table ">
                <input
                  type="text"
                  className="input-edit-name-table focus-visiable-none border-none"
                  placeholder="enter name table"
                  value={floorName}
                  onChange={(e) => setFloorName(e.target.value)}
                />
              </div>
              <div className="w-100 mt-1">
                <p className="text-add-table-name mb-1">Color</p>
                <div className="input-add-table d-flex gap-2 align-items-center p-2 ">
                  <div className="" onClick={handelClickColor}>
                    <div
                      className="btn-change-color-menu border color-style"
                      style={{ backgroundColor: colorPickerRGBa }}
                    ></div>
                  </div>

                  {displayColorPicker ? (
                    <div className="popover-style">
                      <div className="cover-style" onClick={handleCloseColor} />
                      <SketchPicker
                        color={colorPickerRGBa}
                        onChange={handleChangeColor}
                      />
                    </div>
                  ) : null}
                  <p className="mb-0 text-change-color-menu">
                    {changeColorHex}
                  </p>
                </div>
              </div>
              <div className="px-3 w-100">
                <div className="d-flex justify-content-between mt-3">
                  <div
                    className="btn-save-cancel-floor text-4a4a6a border"
                    onClick={handleClose}
                  >
                    Cancel
                  </div>
                  <div
                    className="btn-save-cancel-floor text-white bg-0FA54A border-0FA54A"
                    onClick={SubmitSaveFloor}
                  >
                    Save
                  </div>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1-edit-name-table" />
              <img src={leaf2} alt="" className="img-leaf2-edit-name-table" />
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalEditNameTable;
