import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISettingSlice {
  isSetting: boolean,

}

const initialSettingState: ISettingSlice = {
  isSetting: false,
}

const settingSlice = createSlice({
  name: 'isSettting',
  initialState: initialSettingState,
  reducers: {
    setIsSetting: (state, action: PayloadAction<boolean>) => {
      state.isSetting = action.payload
    },
    clearSetting: (state) => initialSettingState
  }
})

export const selectorSetting = (state: { isSettting: ISettingSlice }) => state.isSettting.isSetting;
export const { setIsSetting, clearSetting } = settingSlice.actions;
export default settingSlice.reducer;