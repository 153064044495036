
import { FormatDolla } from "../../../../utils/format";
import "./Styles.css"

interface ContentTableSumaryClockProps {
    order_id: string;
    table_name: string;
    price: number;
    tipPrice: number;
    timeOrder: string;
}

const ContentTableSumaryClock = ({
    order_id, table_name = '', price = 0, tipPrice = 0, timeOrder = ''
}: ContentTableSumaryClockProps) => {
    return (
        <>
            <tr className="w-100 d-table">
                <td className="text-content-table-clock text-32324D ps-2 pt-3 w-12 font-500">{table_name}</td>
                <td className="text-content-table-clock text-269AFF pt-3 w-22 font-500">#{order_id}</td>
                <td className="pt-3 w-22 text-time-table-clock"><p className="mb-0 text-4A4A6A text-content-table-customer-3 float-left ">{FormatDolla(price)}</p><p className="text-dollar-table-customer text-8E8EA9">$</p></td>
                <td className="pt-3 w-22 text-time-table-clock"><p className="mb-0 text-4A4A6A text-content-table-customer-3 float-left ">{FormatDolla(tipPrice)}</p><p className="text-dollar-table-customer text-8E8EA9">$</p></td>
                <td className="text-content-table-clock font-500 text-228BE6 pt-3 w-22 ps-1">{timeOrder}</td>
            </tr>
        </>
    );
};

export default ContentTableSumaryClock;
