import { useEffect, useState } from "react";
import './Styles.css'
import React from "react";
import { copyIconAdmin } from "../../components/ImgExport";
import { ConfigSetting, Device, EditReturnUrl, deleteDevicePos, gComboDevicePos } from "../../api/apiAdmin/ConfigSetting";
import Swal from "sweetalert2";
import ModalAddEquipment from "./components/ModalAddEquipment/ModalAddEquipment";
import ModalEditEquipment from "./components/ModalEditEquipment/ModalEditEquipment";
import { targetImgadmin } from "../../api/Request";
import default_product from "../../assets/images/default_product.png";
import ModalLoading from "../../components/ModalLoading";
import { getLanguageCheckIn } from "../../Services/languageCheckin";



const SettingConfig = () => {
  const languageUI = getLanguageCheckIn();
  const [returnUrlState, setReturlState] = useState('')
  const [landingPageState, setLandingPageState] = useState('')
  const [showCopySuccess, setShowCopySuccess] = useState(false)
  const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)
  const [showModalAddEquipment, setShowModalAddEquipment] = useState(false)
  const [showModalEditEquipment, setShowModalEditEquipment] = useState(false)
  const [showModalLoading, setShowModalLoading] = useState(false)

  const [listDeview, setListDeview] = useState<Device[]>([])
  const [idDevice, setIdDevice] = useState('')


  const dismisModalAddEquipment = () => {
    listComboDevice()
    setShowModalAddEquipment(false)
  }
  const dismisModalEditEquipment = () => {
    listComboDevice()
    setShowModalEditEquipment(false)

  }



  const getConfig = async () => {
    const config = await ConfigSetting();
    if (config.status === 1) {
      setReturlState(config?.data?.returnUrl ?? '')
      setLandingPageState(config?.data?.landingpage ?? '')
    }
  }

  const handelUpdateUrl = async () => {
    const editReturnUrl = await EditReturnUrl(returnUrlState);
    if (editReturnUrl.status === 1) {
      setShowUpdateSuccess(true)
    }
  }
  const listComboDevice = async () => {
    const res = await gComboDevicePos()
    if (Number(res.status) === 1) {
      setListDeview(res.data ?? [])
    }
  }
  function copyToClipboard(text: String) {
    const el: any = document.createElement('textarea');  // Tạo một textarea ẩn
    el.value = text;  // Gán nội dung muốn sao chép vào textarea
    document.body.appendChild(el);  // Thêm textarea vào body
    el.select();  // Chọn toàn bộ nội dung trong textarea
    document.execCommand('copy');  // Sao chép nội dung vào clipboard
    document.body.removeChild(el);  // Xóa textarea
    setShowCopySuccess(true)
  }
  const ConfirmDeleteEquipment = (id: string) => {
    Swal.fire({
      title: languageUI.Areyousuredeleteequipment,
      text: languageUI.equipmentwillbedeletedwhenyouclickOK,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: languageUI.Cancel,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDevice(id)
      }
    })
  }

  const deleteDevice = async (id: string) => {
    setShowModalLoading(true)
    const res = await deleteDevicePos(id)
    if (Number(res.status) === 1) {
      listComboDevice()
      setShowModalLoading(false)
    }
  }
  useEffect(
    () => {
      getConfig()
      listComboDevice()
    }, []
  )

  useEffect(
    () => {
      if (showCopySuccess) {
        setTimeout(() => {
          setShowCopySuccess(false)
        }, 1000)
      }
    }, [showCopySuccess]

  )
  useEffect(
    () => {
      if (showUpdateSuccess) {
        setTimeout(() => {
          setShowUpdateSuccess(false)
        }, 1000)
      }
    }, [showUpdateSuccess]
  )

  return (
    <>
      <ModalLoading onHide={() => setShowModalLoading(false)} show={showModalLoading} />
      <div className="pt-70px">
        <div >
          <span className='text-666687 text-7 fw-600 mx-3 mt-3'>{languageUI.LandingPageURL}</span>
          <div className='mx-3 mt-2'>
            <div className="bg-white radius-2 border p-t-l flex items-center px-3">
              <input type='text' readOnly value={landingPageState} className="text-101113 text-5 ml-2 border-none focus-visiable-none w-100 text-truncate" />

              <img src={copyIconAdmin} alt="" className="custom-icon-copy" onClick={() => copyToClipboard(landingPageState)} />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <span className='text-666687 text-7 fw-600 mx-3 mt-3'>{languageUI.ReturnURL}</span>
          <div className='mx-3 mt-2'>
            <div className="bg-white radius-2 border p-t-l flex items-center px-3">
              <input type='text' value={returnUrlState} onChange={(e) => { setReturlState(e.target.value) }} className="text-101113 text-5 ml-2 border-none focus-visiable-none w-100 text-truncate" />
              <img src={copyIconAdmin} alt="" className="custom-icon-copy" onClick={() => copyToClipboard(returnUrlState)} />
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex w-100 justify-content-end px-5">
          <div className="btn-update-config" onClick={handelUpdateUrl}>
            {languageUI.Update}
          </div>
        </div>
      </div>
      <div className={`box-container-copy-success ${showCopySuccess ? '' : 'd-none'}`}>
        <div className="box-copy-success">
          {languageUI.CopySuccess}
        </div>
      </div>
      <div className={`box-container-copy-success ${showUpdateSuccess ? '' : 'd-none'}`}>
        <div className="box-copy-success">
          {languageUI.UpdateSuccess}
        </div>
      </div>
      <div className="p-4">
        <div className="bg-white rounded-4 w-full p-4">
          <div className="pe-4 d-flex justify-content-between align-items-center">
            <p className="m-0 text-4a4a6a font-bold text-xl">{languageUI.equipment}:</p>
            <div className="bg-primary px-6 py-2 text-white font-bold rounded-4" onClick={() => { setShowModalAddEquipment(true) }}>
              {languageUI.Add}
            </div>
          </div>
          <div>
            {listDeview.map((item, index) => (
              <div className="mt-3 d-flex justify-content-between gap-4 shadow-items-equiment p-3 rounded-4 mt-2" key={`device-${item.id}`}>
                <div className="w-200px h-120px rounded-4 flex-shrink-0">
                  <img src={targetImgadmin + item.imgurl} onError={(e) => {
                    e.currentTarget.src = default_product;
                  }} alt="" className="w-100 h-100 rounded-4" />
                </div>
                <div className="w-100 d-flex flex-column justify-content-between">
                  <div className="w-100 py-2">
                    <div className="w-100 d-flex justify-content-between align-items-center">
                      <p className="font-medium text-base text-4a4a6a mb-1">{item.title}</p>
                      <p className="font-medium text-base text-4a4a6a mb-1">{languageUI.Price}: {item.price}$</p>
                    </div>
                    <p className="font-normal text-base text-4a4a6a mb-1">{item.description}</p>
                  </div>
                  <div className="w-100 d-flex justify-content-end gap-3">
                    <div className="bg-delete px-6 py-2 text-white font-bold rounded-4" onClick={() => ConfirmDeleteEquipment(item.id)}>
                      {languageUI.Delete}
                    </div>
                    <div className="bg-blue px-6 py-2 text-white font-bold rounded-4" onClick={() => { setIdDevice(item.id); setShowModalEditEquipment(true) }}>
                      {languageUI.Edit}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>
      <ModalAddEquipment handleClose={dismisModalAddEquipment} show={showModalAddEquipment} />
      <ModalEditEquipment handleClose={dismisModalEditEquipment} id={idDevice} show={showModalEditEquipment} />
    </>
  );
};

export default SettingConfig;
