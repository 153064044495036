import "./Styles.css";
import {
  imgDetailQr,
  qrCodeDecoBottomLeft,
  qrCodeDecoTopRight,
} from "../../../../components/Image";
import {
  print,
  download,
  camera,
  subtract,
} from "../../../../components/ImgExport";
import printJS from "print-js";
import { getLanguageUI } from "../../../../Services/languageUI";
import React from "react";

interface QRCodeItemsProps {
  NameTable?: string;
  tableId?: string;
  nameBranch?: string;
  QRCode: any;
  classRef: string;
  downOne: (id: string, name: string) => void;
  NameFloor?: string;
}

const QRCodeItems = ({
  NameTable = "",
  QRCode,
  tableId = "",
  nameBranch = "",
  classRef,
  downOne,
  NameFloor = "",
}: QRCodeItemsProps) => {
  const languageUI = getLanguageUI().qrCode;
  function PrintCard() {
    const printUID = new Date().getTime();
    console.log("ádasdasdasdasd");

    const config: any = {
      printable: "QR_img_print", //id DOM element
      documentTitle: `CardPrint-${printUID}`, //title html document send to iframe
      targetStyles: ["*"], //accepts all the styles
      targetStyle: ["*"], //accepts all the styles
      frameId: "cardprint", //custom frame id
      //font_size: "14pt", 							//deprecated, set font site for all elements
      // style:
      //     "body { margin: 0; font-size: 36px; } .card-number { font-size: 32px!important; color: red!important; }",
      type: "html", //print type html
      onError: (error: any) => {
        console.error("Error Printing: ", error);
      },
      onPrintDialogClose: () => {
        console.info("Print Window Closed");
      },
    };

    printJS(config);
  }
  return (
    <div className={`w-370px mb-3`}>
      <div
        className={`pb-3 px-24px rounded-5 bg-white border-bottom  border shadow position-relative`}
        id="QR_img"
      >
        <div>
          {/* <img
            src={qrCodeDecoBottomLeft}
            className="img-fluid" alt=""
            style={{ position: "absolute", left: 0, bottom: 30, height: 135, zIndex: 0 }}
          />
          <img
            src={qrCodeDecoTopRight}
            className="img-fluid" alt=""
            style={{ position: "absolute", right: 0, top: 30, height: 135, zIndex: 0 }}
          /> */}
          <div className="w-100 d-flex justify-content-center pt-2">
            <div className="d-flex align-items-center rounded-12px overflow-hidden z-10">
              <div className="bg-FF7B2C py-2 px-3 h-100">
                <p className="mb-0 text-center text-brand-name-qr">
                  {NameTable}
                </p>
                {/* <p className="mb-0 text-center text-table-number-qr">{languageUI.floor} {NameFloor}</p> */}
              </div>
              <div className="bg-12B064  px-3 h-100 d-flex align-items-center">
                <p className="text-brand-name-qr mb-0">{nameBranch}</p>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex align-items-center justify-content-center px-4 mt-3 gap-2">
            <div style={{ width: "120px" }}>
              <h5
                style={{
                  textAlign: "center",
                  marginBottom: 0,
                  fontFamily: '"Jua", sans-serif',
                  fontSize: "20px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  letterSpacing: "-0.5px",
                  color: "#212134",
                  width: "100%",
                }}
              >
                {languageUI.scanQRCodeNow}
                <br />
              </h5>
            </div>
            <div
              className=""
              style={{
                width: "90px",
                height: "90px",
                borderRadius: "16px",
                aspectRatio: "1/1",
              }}
            >
              {QRCode}
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 justify-content-center mb-0 z-10 position-relative mt-2">
            <h3 className="m-0 by-using-your-phone">
              {languageUI.byUsingYourPhone}
            </h3>
            <img src={camera} alt="" />
          </div>
          <div className="d-flex justify-content-center w-100">
            <h5 className="scan-to-view mt-2">
              {languageUI.doYouWantToFind}
              <br />
            </h5>
          </div>
          <div className="w-100">
            <img src={imgDetailQr} alt="" className="w-100" />
          </div>

          <div className="w-100 d-flex justify-content-center mb-0 z-10 position-relative">
            <h5 className="operate-by mt-1">
              {/* Order directly on your phone & we’ll
                            <br /> bring your order right to your table */}
              {languageUI.orderDirectlyIn}
              <br style={{ textAlign: "center" }}></br>
              {languageUI.bringYourOrder}
            </h5>
          </div>
        </div>
        <div className=" d-flex justify-content-center">
          <div className=" d-flex gap-2  z-10">
            <button
              className="btn-download-print d-flex align-items-center px-3 py-1"
              onClick={() => downOne?.(tableId, NameTable)}
            >
              <img src={download} alt="" className="me-1" />
              <p className="mb-0">{languageUI.download}</p>
            </button>
            {/* <button className="btn-download-print d-flex align-items-center px-3 py-1">
                            <img src={print} alt="" className="me-1" />
                            <p className="mb-0" onClick={PrintCard}>{languageUI.print}</p>
                        </button> */}
          </div>
        </div>
      </div>
      <div
        className="position-fixed"
        style={{ top: "10000px", left: "100000px" }}
      >
        <div className="" id="QR_img_print">
          <div
            className={`w-370px mb-3 ${classRef}`}
            style={{
              width: "370px",
              marginBottom: "12px",
              flexShrink: 0,
              backgroundColor: "white",
              borderRadius: "16px",
              overflow: "hidden",
            }}
          >
            <div
              className={``}
              style={{
                borderRadius: "16px",
                padding: "18px 24px",
                backgroundColor: "white",
                border: " 1px solid #00A15A",
                position: "relative",
                // borderColor: "#00A15A !important",
                // boxShadow: "0px 0px 35px 0px rgba(154, 161, 171, 0.15)",
                overflow: "hidden",
              }}
              id="QR_img"
            >
              <div style={{ backgroundColor: "white" }}>
                {/* <img
                  src={qrCodeDecoBottomLeft}
                  alt=""
                  style={{ position: "absolute", left: 0, bottom: 30, height: 135, zIndex: 0 }}
                />
                <img
                  src={qrCodeDecoTopRight}
                  alt=""
                  style={{ position: "absolute", right: 0, top: 30, height: 135, zIndex: 0 }}
                /> */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "8px",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "12px",
                      overflow: "hidden",
                      zIndex: 10,
                      position: "relative",
                      backgroundColor: "white",
                      height: "40px",
                    }}
                  >
                    <div
                      id="name-table-print"
                      style={{
                        backgroundColor: "#FF7B2C",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        printColorAdjust: "exact",
                        zIndex: "20",
                        flexDirection: "column",
                        gap: "2px",
                        flexShrink: 0,
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: 0,
                          fontFamily: "Roboto",
                          fontSize: "20px",
                          fontWeight: 500,
                          lineHeight: "normal",
                          letterSpacing: "0px",
                          color: "white",
                        }}
                      >
                        {NameTable}
                      </p>
                      {/* <p
                        style={{
                          textAlign: "center",
                          marginBottom: 0,
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "normal",
                          letterSpacing: "0px",
                          color: "white",
                          display: "flex",
                          flexWrap: "nowrap",
                          flexShrink: 0,
                        }}
                      >
                        Floor {NameFloor}
                      </p> */}
                    </div>
                    <div
                      style={{
                        backgroundColor: "#12B064",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: 0,
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          fontWeight: 600,
                          lineHeight: "normal",
                          letterSpacing: "0px",
                          color: "white",
                          alignItems: "center",
                        }}
                      >
                        {nameBranch}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    className="center-qr-code"
                    style={{
                      width: "77%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "12px",
                      position: "relative",
                    }}
                  >
                    <div style={{ width: "60%", backgroundColor: "white" }}>
                      <h5
                        style={{
                          textAlign: "center",
                          marginBottom: 0,
                          fontFamily: '"Jua", sans-serif',
                          fontSize: "20px",
                          fontWeight: 600,
                          lineHeight: "normal",
                          letterSpacing: "-0.5px",
                          color: "#212134",
                          width: "100%",
                        }}
                      >
                        {languageUI.scanQRCodeNow}
                        <br />
                      </h5>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        height: "fit-content",
                        borderRadius: "16px",
                        right: 0,
                        left: 0,
                        textAlign: "center",
                        margin: "auto",
                        marginLeft: "20px",
                        backgroundColor: "white",
                      }}
                    >
                      {QRCode}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "center",
                    marginBottom: "8px",
                    marginTop: "12px",
                    backgroundColor: "white",
                  }}
                >
                  <h3
                    style={{
                      textAlign: "center",
                      marginTop: "0px",
                      marginBottom: "0px",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0px",
                      color: "#8E8EA9",
                    }}
                  >
                    {languageUI.byUsingYourPhone}
                  </h3>
                  <img src={camera} alt="" />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                  }}
                >
                  <h5
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      fontFamily: '"Jua", sans-serif',
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "-0.5px",
                      color: "#212134",
                      width: "200px",
                    }}
                  >
                    {languageUI.doYouWantToFind}
                    <br />
                  </h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8px",
                    backgroundColor: "white",
                  }}
                >
                  <img
                    src={imgDetailQr}
                    alt=""
                    className="w-full"
                    style={{ width: "100%" }}
                  />
                </div>

                {/* <div>
                  <p
                    style={{
                      marginBottom: "0",
                      paddingRight: "35px",
                      marginTop: "4px",
                      textAlign: "right",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0px",
                      color: "#A5A5BA"
                    }}
                  >- BriJack POS -</p>
                </div> */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "4px",
                    marginBottom: "8px",
                    backgroundColor: "white",
                  }}
                >
                  <h5
                    style={{
                      width: "300px",
                      textAlign: "center",
                      marginBottom: 0,
                      fontFamily: "Roboto",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "normal",
                      letterSpacing: "0px",
                      color: "#A5A5BA",
                      zIndex: 10,
                      marginTop: "0px",
                      position: "relative",
                    }}
                  >
                    {languageUI.orderDirectlyIn}
                    <br style={{ textAlign: "center" }}></br>
                    {languageUI.bringYourOrder}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeItems;
