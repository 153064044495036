import * as languageUser from "./languageUser";
export var Language = {
  EN: {
    layout: {
      pickup: "Pickup",
      tablePOS: "Table POS",
      menuPOS: "Menu POS",
      clockInOut: "Clock in / Clock out",
      setting: "Settings",
      setUpTable: "Set up table",
      menuTemplate: "Menu template",
      customer: "Customer",
      customerReciepts: "Customer Receipts",
      report: "Report",
      review: "Review",
      inventory: "Inventory",
      staffs: "Staffs",
      setUpVoucher: "Set up Voucher",
      subscription: "Subscription",
      feedback: "Feedback",
      offlineMode: "Offline Mode",
      order: "Order",
      setupTable: "Setup Table",
      reservation: "Reservation",
      giftCard: "Gift Card",
      giftCardHistory: "Gift Card History",
      listQrCodeGiftCard: "List QrCode Gift Card",
      notification: "Notification",
      payment: "Payment",
      paid: "Paid",
      help: "Help",
      newOrder: "New Order",
      kitchenCounter: "Kitchen & Counter",
      ownerMode: "Owner Mode",
      staffMode: "Staff Mode",
      logOut: "Log out",
      toOwnerStaff: "To Owner Staff! Please enter your passcode.",
      toVoidBillOTP: "To Void Bill! Please enter your passcode.",

      toOwnerLogOut: "To Log Out! Please enter your passcode.",
      enterPasscode: "Enter Owner or Manager Passcode",
      click: "Click",
      history: "History",
      webSupport: "Web Support",
      phoneSupport: "Phone Support",
      SMSSupport: "SMS Support",
    },
    notification: {
      home: "Home",
      order: "Order",
      payment: "Payment",
      paid: "Paid",
      suport: "Suport",
      delete2Day: "Notification will be delete after 2 days",
      orderNumber: "Order number",
      request: "Request",
      dishes: "dishes",
      rarkReadAll: "Mark read all notification",
      exit: "Exit",
      reloadPage: "Reload Page",
    },
    login: {
      loginToSystem: "Login to system",
      userName: "Username",
      password: "Password",
      forgetPassword: "Forget Your Password?",
      login: "Login",
      enterYourUsername: "Enter your username / Email",
      enterYourPassword: "Enter your password",
      passWordRequired: "Password is required",
      usernameRequired: "Username is required",
      contactUsAtHotline: "Please try again or contact us at hotline",
      somethingWentWrong: "Something went wrong. Please try again later!",
      anErrorHasOccurred:
        "An error has occurred. Please check your internet connection",
    },
    forgotPassword: {
      forgotPassword: "Forgot Password",
      enterYourEmail: "Enter your email",
      getCode: "Get code",
      login: "Login?",
      code: "Code",
      enterCode: "Enter code",
      weHaveSentTheCode:
        "We have sent the code to your email. Check to get it !",
      emailNotFormat: "Email you entered is not in the format",
      pleaseEnterEmail: "Please enter your email",
      pleaseEnterCode: "Please enter code get from your email",
      somethingWentWrong: "Something went wrong. Please try again later!",
      changePasswordSuccess: "Change Password Success!",
    },
    posMenu: {
      pickUp: "Pick up",
      time: "Time",
      guest: "Guest",
      filter: "Filter",
      chooseFoodGroup: "Choose food group",
      eatHere: "Eat here",
      orderToGo: "Order to go",
      findDishes: "Find dishes...",
      clear: "Clear",
      clearAll: "Clear all",
      subTotoal: "Sub total",
      tax: "Tax",
      enterTip: "Enter tip",
      discount: "Discount",
      payment: "Payment",
      cash: "Cash",
      card: "Card",
      total: "Total",
      order: "Order",
      noteForKitchen: "Note for kitchen",
      addToCard: "Add to cart",
      sideDish: "Side Dish",
      viewMore: "View more",
      viewLess: "View less",
      delivery: "Delivery App",
      apply: "Apply",
      ConvenienceFee: "Convenience fee",
      theConvenienceFee:
        "The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee.",
      submit: "Submit",
      mayItakeYourOrder: "May I take your order!",
      pleaseChoosePaymentMethod: "Please choose payment method!",
      tippingMustThan0: "Tipping must be greater than 0",
      tableName: "Table name:",
      takeAWay: "Take away",
      Guest: "Guest",
      cashTotal: "Cash total",
      staffOrder: "Staff order",
      Pleaseentercustomername: "Please enter customer name!",
      Pleaseentercustomerphone: "Please enter customer phone!",
      PleaseEnterAddOnDescription: "Please Enter Add-On Description",
      PleaseEnterAddOnPrice: "Please Enter Add-On Price",
      Description: "Description",
      EnterAmount: "Enter Amount",
    },
    posTable: {
      findTableByPhone: "Find table by phone or customer name...",
      avaiable: "Avaiable",
      Reserved: "Reserved",
      Paid: "Paid",
      bill: "Bill",
      addTip: "Add tip",
      paymentMethod: "Payment method",
      payWidthCard: "Pay with card",
      payWidthCash: "Pay with cash",
      rececievedAmount: "Receieved amount",
      remainingAmount: "(0) Remaining amount",
      summary: "Summary",
      customer: "Custom Tip Amount",
      employeeWantToTip: "Employee want to tip",
      itemsTotal: "Items total",
      tip: "Tip",
      tax: "Tax",
      Total: "Total",
      Discount: "Discount",
      setAvailbleState: " Are you sure you want to return table",
      setAvailbleState2: "Available state?",
      payBill: "Pay bill",
      chooseBillOrder: "Choose bill to order",
      dineIn: "Dine in",
      takeAway: "Take away",
      floor: "Floor",
      chooseAll: "Choose all",
      ordertogo: "Order to-go",
      apply: "Apply",
      howmanyGuests: "How many Guests?",
      enternumberguests: "Enter number guests",
      Confirm: "Confirm",
      newbill: "New bill",
      splitbill: "Split bill",
      voidBill: "Void bill",
      movebill: "Move bill",
      moveBillToTable: "Move Bill To Table",
      cash: "Cash",
      cashTotal: "Cash total",
      pickup: "Pickup",
      customerName: "Customer name",
      customerphonenumber: "Customer phone number",
      submit: "Submit",
      submitAndOrder: "Submit & Order",
    },
    clockInOut: {
      clock_In: "Clock-in",
      clock_Out: "Clock-out",
      clockInOut: "Clock-in/Clock-out",
      no: "No",
      employee: "Employee",
      clockIn: "Clock in",
      clockOut: "Clock out",
      workTime: "Work time",
      summary: "Summary",
      date: "Date",
      name: "Name",
      tip: "Tip",
      print: "Print",
      table: "Table",
      Order: "Order",
      bill: "Bill",
      time: "Time",
    },
    qrCode: {
      scanToView: "Scan to view our menu",
      doYouWantToFind: `Do you want to find out \n what the most popular \n food is?`,
      byUsingYourPhone: "By using your phone camera",
      orderDirectlyIn: `Order directly in your phone & we'll`,
      bringYourOrder: "bring your order right to your table",
      download: "Download",
      print: "Print",
      downloadAll: "Download all",
      printAll: "Print all",
      floor: "Floor",
      scanQRCodeNow: "Scan the QR Code now!",
    },
    setting: {
      everythingElse: "Everything Else (Reciep, Staffs, Owner mode)",
      entree: "Entree",
      appetizer: "Appetizer",
      beverage: "Beverage",
      titleQuesAddPrinter: "What does this printer print for?",
      information: "Information",
      password: "Password",
      tiptax: "Tip & Tax",
      connectPrinter: "Connect Printer",
      connectStripe: "Connect Stripe",
      allowCustomerInput: "Allow customer input DAY OF BIRTHDAY when check-in",
      showCustomerPoint: "Show customer point when check-in",
      textOver160Character:
        "Texts Over 160 Characters, Including Coupon Codes, Will Be Sent in 2 Messages .",
      googleBussinessID: "Google Bussiness ID",
      nameStore: "Name store",
      askForReviewMessage: "Ask for Review Message",
      feedbackforserver: "Feedback For Server",
      thankYourForVisitUsToday:
        "Thank you for visit us today. Can you give us a review. Under 5, we fail.",
      customTime: "Custom time",
      birthdayMessage: "Birthday message",
      weWantToWishYou:
        "We want to wish you a good birthday and have a small present for your next visit, with 10% discount. Enjoy",
      timeSentBirthdayMessage: "Time sent birthday message",
      onBirthdayDay: "On birthday day",
      couponForCertain: "Coupon for certain point message:",
      thankYouForBeingOur:
        "Thank you for being our loyal customer! we happy to give you 10% off for you next visit.",
      point: "point",
      for1Spent: "for $1 spent",
      sentCouponWhenTheyReach: "Sent coupon when they reach",
      couponMessage: "Coupon message",
      hiFriendThisIs: "Hi friend this is",
      longTimeNoSee: `👋Long time no see! We miss you. Here's a special 30% OFF coupon to welcome you back.Can't wait to see you!🎉`,
      noSent: "No send",
      changePassword: "Change password",
      oldPass: "Old pass",
      newPass: "New pass",
      enterNewPassword: "Enter new password",
      retypePass: "Retype password",
      enterNewPasswordToConfirm: "Enter new password to confirm",
      cancel: "Cancel",
      changePass: "Change password",
      automaticallyCalculate:
        "Automatically calculate based on total bill of customer Will be show at Customer check out page.",
      enterTheNumberOf: "Enter the number of %",
      tax: "Sale tax %",
      save: "Save",
      forYourReference: "For your reference",
      ortherRestaurantsIncreased:
        "Other restaurants increased 23% of TIP by making 20% 30% and 40% instead of 10% 15% 20%.",
      myPrinters: "My printers",
      searchForPrinter: "Search for printer",
      connectPrinterViaWifi: "Connect to printer Via Wifi",
      connect: "Connect",
      printer: "Printer",
      printFromSections: "Print from sections",
      chooseSectionToPrint: "Choose section to print",
      qrCodeMenu: "QR code menu",
      customerReceipts: "Customer receipts",
      kitchenOrder: "Kitten order",
      staffTicket: "Staff ticket",
      disconnectThisDevice: "Disconnect this device",
      addNewPaymentGateway: "Add new payment gateway",
      rightAway: "Send right away",
      chooseVoucherID: "Choose Voucher ID",
      Reward: "Reward",
      sendWhenTheyNotVisit: "Send when they not visit",
      month: "Month",
      day: "Day",
      hours: "hours",
      minutes: "minutes",
      customMessage: "Custom Message",
      tipRequite3TipOption: "Tip (requite 3 tip option)",
      otherRestaurantsIncreased:
        "Other restaurants increased 23% of TIP by making 20% 30% and 40% instead of 10% 15% 20%.",
      timeZone: "Time Zone",
      LinkForCustomer:
        "Link for customer to order for takeout, it not attached to any table. ( this for people want to order online and come pick it up)",
      exchangePoints: "Exchange points:",
      showRewards: "Show Rewards",
      pointGame: "Point game",
      pointRestaurant: "Point restaurant",
      addTerminalStripe: "Add Terminal Stripe",
      upSale: "Up-sale",
      happyHour: "Happy Hour",
      forEveryDollar:
        "For every dollar you spend, you will earn an equivalent amount of points.",
      onceYouAccumulate: "Once you accumulate",
      points: "points.",
      weWillProvide: "We will provide you with a discount of",
      off: "off",
      thisSentenceBelow: 'Note: This sentence below will show in "point page"',
      changePasscode: "Change passcode",
      oldPasscode: "Old passcode",
      newPasscode: "New passcode",
      retypePasscode: "Retype passcode",
      pleaseEnterOldPass: "Please enter old pass!",
      pleaseEnterNewPass: "Please enter new pass!",
      pleaseEnterRetypePass: "Please enter retype pass!",
      changePasswordSuccess: "Change password success",
      pleaseEnterOldPasscode: "Please enter old passcode!",
      pleaseEnterNewPasscode: "Please enter new passcode!",
      pleaseEnterRetypePasscode: "Please enter retype passcode!",
      changePasscodeSuccess: "Change passcode success",
      phoneNumber: "Phone number",
      address: "Address:",
      passcode: "passcode",
      enterNewPasscodeToConfirm: "Enter new password to confirm",
      enterNewPasscode: "Enter new password",
      apply3Processing:
        "Apply 3% processing fee will when customer using their card.",
      yourCanPassYourStripe:
        "You can pass your Stripe fees on to your customers by including the fee into the final charge amount. It is important to ensure that this action complies with any applicable laws that pertain to your business. If you are unsure whether such laws apply, consult with your legal counsel.",
      ortherRestaurantIncreased:
        "Other restaurants increased 23% of TIP by making 20% 30% and 40% instead of 10% 15% 20%.",
      toReceivePaymentForm:
        "To receive payments from customers using online services, connect to a payment gateway from payments company Stripe.",
      weChoseThisCompany:
        "We chose this company as a partner because it is one of the largest and highly secure companies.",
      toConnectToStripe: "To connect to Stripe payment gateway, only 2 steps: ",
      onestCreateAccount: "1st: Create account with stripe",
      twondAddStripe: "2nd: Add Stripe API key to BriJack POS",
      addNewPayment: "Add New Payment Gateway",
      accountDefault: "Account default",
      account: "Account",
      connectDoordash: "Connect doordash",
      developerId: "Developer id",
      keyId: "Key id",
      signingSecret: "Signing secret",
      registerAccount: "Register account",
      addNewAccPaymentGateway: "Add New Account Payment Gateway",
      submit: "Submit",
      enterNameAccount: "Enter name account",
      nameForYourReference: "Name (For your reference)",
      changeTerminalReader: "Change Terminal Reader",
      connected: "Connected",
      addTerminalReader: "Add Terminal Reader",
      delete: "Delete",
      POSPrinter: "POS printer",
      webOrderPrinter: "Web Order printer",
      menuPOSPrinter: "Menu POS printer",
      choosePrinter: "Choose Printer",
      listPrinter: "List Printer",
      avaiablePrinter: "Avaiable printer",
      addPrinter: "Add Printer",
      printerName: "Printer Name",
      enterPrinterName: "Enter Printer Name",
      printerIsRequired: "Printer name is required",
      wantDeletePrinter: "Do you want delete printer",
      statusUpSale: "Turn on/off Up-sale:",
      chooseNumberFood: "Choose number food for this section:",
      chooseNumberDrink: "Choose number drink for this section:",
      chooseHappyHourDay: "Choose Happy Hour Day:",
      Repeat: "Repeat",
      chooseHappyHourTime: "Choose Happy Hour Time:",
      chooseFood: "ChooseFood",
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      everyWeedk: "Every Week",
      every2Weedk: "Every 2 Week",
      every4Weedk: "Every 4 Week",
      chooseAllFood: "Choose All Food",
      custom: "Custom",
      selectFood: "Select Food",
      to: "To",
    },
    orderPage: {
      timeOrder: "Time Order",
      totalItems: "Total Items",
      phone: "Phone",
      customer: "Customer",
      invoiceMethod: "Invoice Method",
      delete: "Delete",
      print: "Print",
      total: "Total",
      pages: "Pages",
      show: "Show",
      page: "page",
      goto: "Go to",
      showAll: "Show All",
      phoneNumber: "Phone number",
      sideDish: "Side dish:",
      tableName: "Table name",
    },
    kitchenCounter: {
      back: "Back",
      history: "History",
      hideHeader: "Hide header",
      showHeader: "Show header",
      takeAway: "Take away",
      dineIn: "Dine-in",
      home: "Home",
      request: "Request",
      freeSide: "Free Side:",
      backToRequest: "Back to request",
      restore: "Restore",
      dishReadyAt: "Dish Ready at",
      complete: "Completed",
    },
    reservation: {
      dateTime: "DateTime:",
      customer: "Customer:",
      phoneNumber: "Phone number:",
      seatsNumber: "Seats:",
      note: "Note:",
      cancel: "cancel",
      confirm: "Confirm",
      wantConfirm: "Do you want to confirm booking?",
      wantCancel: "Do you want to cancel booking?",
      wantFinish: "Do you want to finish reservation?",
      finish: "Finish",
      enterReservation: "Enter total reservation booking",
      save: "Save",
      seats: "seats:",
      delete: "Delete",
      bookingTableDetail: "Booking Table Detail",
      info: "Info",
      Date: "Date",
      Time: "Time",
      Seat: "Seat",
      wantSaveChange: "Do you want to save your changes?",
    },
    passCode: {
      toClockInEnter: "To clock-in enter your passcode",
      employeePassCode: "Employee pass code",
      click: "Click",
      toClockOutEnter: "To clock-out enter your passcode",
      pleaseEnterPasscode: "Please enter passcode!",
      toDeleteEnter: "To delete enter your pass code",
      delete: "Delete",
      yourPassCode: "Your passcode",
      toShowDetail: "To show detail, enter your pass code",
    },
    setUpTable: {
      available: "Available",
      reserved: "Reserved",
      paid: "Paid",
      floor: "Floor",
      information: "Information",
      shape: "Shape",
      square: "Square",
      circle: "Circle",
      size: "Size",
      rorate: "Rotate",
      manage: "Manage",
      tool: "Tool",
      floorManagement: "Floor management",
      addTable: "Add Table",
      multiSelect: "Multi select",
      clear: "Delete All table",
      addFloor: "Add Floor",
      add: "Add",
      cancel: "Cancel",
      save: "Save",
      howManyTablesAdd: "How many tables would you like to add",
      single: "Single",
      multi: "Multi",
      selected: "Selected",
      youWantToClear: "You want to clear?",
      no: "No",
      yes: "Yes",
      areYouSure: "Are you sure?",
      pleaseEnterTable: "Please enter table quantity!",
    },
    customerPage: {
      filter: "Filter",
      today: "Today",
      longTimeNoSee: "Long time no see",
      byReview: "By review",
      byJourney: "By Journey",
      customerList: "Customer list",
      customer: "Customer",
      findReviewByPhone: "Find review by phone",
      sendSMS: "Send SMS",
      lastVisit: "Last visit",
      timesVisit: "Times visit",
      points: "Points",
      name: "Name",
      phone: "Phone",
      totalAmount: "Total amount",
      total: "Total",
      pages: "pages",
      show: "Show",
      page: "page",
      goTo: "Go to",
      customerSelected: "customer selected",
      to: "to",
      chooseVoucherID: "Choose voucher ID",
      sendNow: "Send Now",
      male: "Male",
      female: "Female",
      point: "Point",
      returnVisits: "Return visit",
      orderHistory: "Order History",
      date: "Date",
      time: "Time",
      order: "Order",
      table: "Table",
      bills: "Bills",
      tips: "Tips",
      staff: "Staff",
      orderDetail: "Order Detail",
      printDateTime: "Print date-time:",
      phoneNumber: "Phone number:",
      dineIn: "Dine In",
      takeAWay: "Take away",
      subTotal: "Sub total",
      tax: "Tax",
      discount: "Discount",
      totalTax: "Total (Tax)",
      print: "Print",
      tipFor: "Tip for",
      ConvenienceFee: "Convenience fee",
    },
    menuTemplate: {
      menuManagement: "Menu Management",
      sideDish: "Side Dish",
      bevarage: "Bevarage",
      appetizers: "Appetizers",
      footRank: "Food Rank",
      footRating: "Food Rating",
      today: "Today",
      yesterday: "Yesterday",
      allFood: "All food",
      addCategories: "Add categories",
      showingFoodRanking: "Showing food ranking:",
      has: "has",
      products: "Products.",
      activeCategory: "Active category?",
      addFood: "Add food",
      addAnotherdish: "Add another dish",
      activeFood: "Active food",
      addSideDish: "Add Side Dish",
      createFood: "Create New Food",
      uploadFoodImg: "Upload Food Images",
      recommendedImageFormats: "Recommended image formats:",
      atLeast: ", at least",
      forOptimalDisplay: "for optimal dispplay.",
      foodName: "Food Name:",
      selectFoodOrBeverage: "Select Section",
      orderNo: "Order no.",
      price: "Price:",
      description: "Description:",
      descriptionText: "Description tex",
      selectMaterialToConnect:
        "Select material to connect into inventory for inventory management (optional)",
      rawMaterialInWarehouse: "Raw materials, materials in Warehouse",
      chooseSideComplimetary:
        "Choose side complimetary with this food (optional)",
      optionsForThis: "(Optional) Options for this dish: ",
      exampleMedium: "Example: Medium, Medium rare, Well done.",
      pressEnterAfter: "(Press 'Enter' after you type)",
      exampleSpice: "Example:   “Spicy”, “Gluten-free”,....and press enter",
      preTextFor: "(Optional) Pre text for this dish (customer can choose all)",
      cancel: "Cancel",
      save: "Save",
      beverage: "Beverage",
      appetizer: "Appetizer",
      entree: "Entree",
      enterPrice: "Enter price",
      createNewFoodCategory: "Create New Food Category",
      categoriesName: "Categories name",
      name: "Name",
      pickACategoryColor: "Pick a category color",
      edit: "Edit",
      delete: "Delete",
      editFoodGroup: "Edit food group",
      doYouWantDelete: "Do you want to delete food group ?",
      doYouWantDeleteFood: "Do you want to delete food ?",
      yes: "Yes",
      no: "No",
      editFood: "Edit food",
      itWillShowInFoodMenu: "It will show in food menu",
      saveMaterials: "Save materials",
      qty: "Qty",
      createNewSideDish: "Create new side dish",
      editNewSideDish: "Edit side dish",
      noNumber: "No.",
      sideDishName: "Side Dish Name",
      howmanyFreeSide: "How many free side customers can choose for this food",
      cancelled: "Cancelled",
      yourFoodIsSafe: "Your food is safe",
      deleted: "Deleted",
      yourFoodDeleted: "Your food has been deleted.",
      yourGroupFoodDeleted: "Your Group Food has been delete.",
      updateSuccessful: "Update successful",
      chooseTheFoodItem: "Choose the food item that was previously generated",
      searchAndSelect: "Search & Select",
      soupAndStews: "Soup and Stews",
      Desserts: "Desserts",
      Salad: "Salad",
      Grill: "Grill",
      foodSection: "Food Section",
      print: "Print",
      back: "Back",
      settingFoodSection: "Setting Food Section",
    },
    customerReceipts: {
      filter: "Filter",
      today: "Today",
      selectStaff: "Select staff",
      findreceiptby: "Find receipts by phone or customer name...",
      customerReceipts: "Customer Receipts",
      table: "Table",
      order: "Order",
      bill: "Bill",
      tip: "Tip",
      staff: "Staff",
      timePrint: "Time print",
      customer: "Customer",
      phoneNumber: "Phone Number",
      printDateTime: "print Date-time",
      viewMore: "View more",
      viewLess: "View less",
      subTotal: "Sub Total",
      tax: "Tax",
      discount: "Discount",
      total: "Total",
      deleteBill: "Delete Bill",
      print: "Print",
      tipfor: "Tip for",
      theConvenienceFee:
        "The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee.",
      ConvenienceFee: "Convenience fee",
      staffOrder: "Staff order",
    },
    reportPage: {
      moneyTime: "Money Time",
      customersOverTime: "Customers Over Time",
      income: "Income",
      profit: "Profit",
      salary: "Salary",
      import: "Import",
      visa: "Visa",
      cash: "Cash",
      returnCustomer: "Return customer",
      newCustomer: "New customer",
      coupons: "Coupons",
      goodReview: "Good reivew",
      badReview: "Bad review",
      detailIncome: "Detail Income",
      download: "Download",
      saleTax: "Sale tax",
      orderOnlineToGo: "Order onine to go",
      orderOnlinePickUp: "Order onine pick-up",
      googlePay: "Google pay",
      applePay: "Apple pay",
      reseveTable: "Reseve table",
      order: "Order",
      today: "To day",
      totalCustomer: "Total customer",
      incomeByMonth: "Income by month",
      incomeByDate: "Income by date",
      january: "January ",
      february: "February ",
      march: "March ",
      april: "April ",
      may: "May ",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      detail: "Detail",

      date: "Date",
      time: "Time",
      table: "Table",
      staff: "Staff",
      bill: "Bills",
      printDateTime: "print Date-time",
      viewMore: "View more",
      viewLess: "View less",
      subTotal: "Sub Total",
      tax: "Tax",
      discount: "Discount",
      total: "Total",
      deleteBill: "Delete Bill",
      print: "Print",
      tipfor: "Tip for",
      theConvenienceFee:
        "The convenience fee is charged per transaction by a third-party vendor. We do not retain any portion of this fee.",
      convenienceFee: "Convenience fee",
      report: "Report",
      customer: "Customer",
      phoneNumber: "Phone number",
      dineIn: "Dine In",
      takeAWay: "Take away",
    },
    reviewPage: {
      filter: "Filter",
      today: "To day",
      overview: "Overview",
      totalReview: "Total review",
      good: "Good",
      nuetral: "Nuetral",
      bad: "Bad",
      HavenGiveReview: "Haven't give review yet",
      customerReview: "Customer review",
      findByPhone: "Find review by phone",
      sendSMSToAll: "Send SMS to all on this group",
      points: "Points",
      customerName: "Customer name",
      orderID: "Order ID",
      review: "Review",
      messageReview: "Message review",
      phone: "Phone",
      sendSMS: "Send SMS",
      sendNow: "Send Now",
      to: "to",
    },
    inventoryPage: {
      inventory: "Inventory",
      all: "All",
      outOfStock: "Out of stock",
      expiringSoon: "Expiring soon",
      print: "Print",
      createMaterial: "Create material",
      import: "Import",
      export: "Export",
      noNumber: "No.",
      materialCode: "Material code",
      materialName: "Material name",
      dateAdded: "Date added",
      InventoryStock: "Inventory stock",
      reminder: "Reminder",
      unit: "Unit",
      status: "Status",
      delete: "Detele",
      preview: "Preview",
      createNewMaterial: "Create New Material",
      enterMaterialName: "Enter material name",
      selectUnit: "Select unit",
      cancel: "Cancel",
      save: "Save",
      importMaterial: "Import Material",
      searchAndSelect: "Search & Select",
      enterNumber: "Enter number",
      totalPrice: "Total price",
      importer: "Importer",
      note: "Note:",
      poundLiterBotter: 'recommend set reminder by "Low set"',
      boxSetReninder: 'Set reminder by " DAY "',
      becauseItCanBe:
        "Because it can be connected to food, to perform the process of subtracting products in the inventory.",
      chooseOneReminder: "Choose 1 reminder option",
      timeBase: "Time base",
      lowSetReminder: "Low-set reminder",
      day: "Day",
      back: "Back",
      exportMaterial: "Export Material",
      ExportDate: "Export date",
      total: "Total",
      exportNumber: "Export number",
      totalLeftInStock: "Total left in stock",
      exporter: "Exporter",
      lowStock: "Low Stock",
      inStock: "In stock",
      materialNameRequired: "Material name is required",
      unitRequired: "Unit is required",
      successAddedMaterial: "Successfully added new material",
      ownerNotFound: "Owner not found",
      importNumber: "Import number",
      pleaseEnterMaterial: "Please enter material name!",
      pleaseEnterDate: "Please enter date added!",
      pleaseEnterImportNumber: "Please enter import number",
      pleaseEnterTotalPrice: "Please enter total price",
      plesaeEnterImporter: "Please enter importer!",
      pleaseEnterDayNumber: "Please enter day number",
      pleaseMassNumber: "Please enter mass number",
      enterName: "Enter name",
    },
    staffPage: {
      employee: "Employee",
      salary: "Salary",
      tipRule: "Tip Rule",
      staffReview: "Staff Review",
      employeeWorkingToday: "Employee working today",
      today: "Today",
      employeeWorking: "Employee working",
      activeEmployee: " Active employee",
      inactiveEmployee: "Inactive employee",
      createNewStaff: "Create new staff",
      noNumber: "No.",
      enployee: "Employee",
      clockIn: "Clock in",
      clockOut: "Clock out",
      workingTime: "Working time",
      edit: "Edit",
      position: "Position",
      dateJoined: "Date joined",
      caculateSalaryWeek: "Calulate Salary For All Employee Each Week",
      fromMondayToSunday: "(from Monday-Sunday)",
      caculateSalary2Week: "Calulate Salary For All Employee Each 2 Week",
      caculateSalaryMonth: "Calulate Salary For All Employee Each 1 month",
      fromJanuarytoDecember: "(from January-December)",
      setupTiprule: "Setup Tip Rule",
      pleaseEnSureTheTotal: "Please ensure the total tip enter is 100% or less",
      allBevarageNeed: "All bevarage need to be equal amount",
      allCleanTableNeed: "All Clean table need to be equal amount",
      save: "Save",
      total: "Total",
      note: "Note:",
      ifThereAreTwoPeople:
        'If there are two people serving beverages in your store, the system will evenly distribute the percentage of the tip for beverage servers, ensuring that each person receives an equal share. For example, if the tip percentage of "Beverage server" is 10%, each person will receive 5%.',
      theSamePrinciple:
        "The same principle will also apply to the manager, chef, and table cleaning staff.",
      exampleHowToInput: "Example how to input amount",
      foodServer: "Food Server:",
      chef: "Chef:",
      manager: "Manager:",
      beverageServer: "Beverage server:",
      cleanTable: "Clean table:",
      plusOff: "Plus(%) off",
      hisTip: "hisTip",
      enterNumberOnly: "(Enter number only)",
      close: "Close",
      all: "All",
      good: "Good",
      justOK: "Just Ok",
      bad: "Bad",
      dateTime: "Date/Time",
      customerNamePhone: "Customer name/Phone",
      rating: "Rating",
      staff: "Staff",
      feedBack: "Feedback",
      pages: "pages",
      show: "Show",
      page: "page",
      Goto: "Go to",
      createStaff: "Create Staff",
      staffName: "Staff Name",
      passcode: "Passcode",
      female: "Female",
      male: "Male",
      status: "Status",
      birthday: "Birthday",
      phoneNumber: "Phone number",
      address: "Address",
      role: "Role",
      chooseRole: "Choose role",
      automaticClockOutTime: "Automatic Clock-out time",
      payyRoll: "Payroll",
      chooseRoll: "Choose role",
      payPerHour: "Pay per hour",
      payPerDay: "Pay per day",
      payPerWeek: "Pay per week",
      payPer2Week: "Pay per 2 week",
      payPerMonth: "Pay per month",
      nameAppearsAtChckout:
        "Name appears at checkout of a paying customer in the Tips section",
      back: "Back",
      create: "Create",
      editInfoStaff: "Edit Info Staff",
      january: "January ",
      february: "February ",
      march: "March ",
      april: "April ",
      may: "May ",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      name: "Name",
      payer: "Payer",
      commission: "Commission",
      totalTime: "Total time",
      totalWorkEarn: "total work earn",
      totalTip: "Total tip",
      totalPay: "Total pay",
      deduct: "Deduct",
      bonus: "Bonus",
      print: "Print",
      employeeIncentives: "Employee Incentives and Deductions",
      amount: "Amount",
      enterAmount: "Enter amount",
      yesterday: "Yesterday",
      date: "Date",
      table: "Table",
      timePrinter: "Time print",
      bill: "Bill",
      order: "Order",
      active: "Active",
      Inactive: "Inactive",
      areYouSureInactive: "Are you sure you want to Inactive",
      areYouSureActive: "Are you sure you want to Active",
      noIChangeMyMind: "No, I change my mind",
      yes: "Yes",
      update: "Update",
      allTotalTip: "All total tip",
      showAll: "Show all",
    },
    giftCard: {
      giftCardHistory: "Gift Card History",
      neverExpire: "Never Expire",
      giftCard: "Gift Card",
      createGiftCardProgram: "Create Gift Card",
      noNumber: "No.",
      enable: "Enable",
      expiryDate: "Expiry Date",
      giftCardName: "Gift Card Name",
      giftCardValue: "Gift Card Value",
      quantity: "Quantity",
      used: "Used",
      createGiftCard: "Create Gift Card",
      enterGiftCardName: "enter gift card name",
      valueGiftCard: "Value Gift Card",
      percentGiftCard: "Percent gift card (%)",
      amountGiftCard: "Amount gift card ($)",
      giftCardExpiresIn: "Gift Card Expires in",
      to: "To",
      back: "Back",
      save: "Save",
      detail: "Detail",
      listQRCodeGiftCard: "List QRCode Gift Card",
      download: "Download",
      downloaded: "Downloaded",
      show: "Show",
      downloadAll: "Download All",
      errOwnerNotFound: "Owner not found. Please log in again! ",
      errVoucherName: "Voucher name is required",
      errCoupon: "Please enter coupon",
      errEnterDateStart: "Please enter date start!",
      errEnterDateEnd: "Please enter date end",
      theStartDate: "The start date must be smaller than the end date",
      dateAdd: "Date Add",
      personSell: "Person Sell",
      sellTo: "Sell To",
      Remain: "Remain",
      From: "From",
      cardBalance: "Card Balance",
      viewTransactions: "View Transactions",
      Date: "Date",
      amount: "Amount",
      balance: "Balance",
      Thisgiftcardcannotbeexchangedforcashunlesssuch:
        "This gift card cannot be exchanged for cash unless such ",
      redemptionisrequiredbyapplicablelaw:
        "redemption is required by applicable law.",
      step1: "Step 1",
      step2: "Step 2",
      step3: "Step 3",
      step4: "Step 4",
      info: "Info",
      sendfrom: "Send from",
      sendto: "Send to",
      phone: "Phone",
      deliver: "Deliver",
      now: "Now",
      schedule: "Schedule",
      Messageoptional: "Message (optional)",
      writeSomeNote: "Write some note...",
      cancel: "Cancel",
      payWithCash: "Pay With Cash",
      payWithCard: "Pay With Card",
      urchasedDateAndTime: "Purchased date and Time",
      seller: "Seller",
      contactPhoneEmail: "Contact Phone/Email",
    },
    voucherPage: {
      voucher: "Voucher",
      createVouvherProgram: "Create Voucher Program",
      noNumber: "No.",
      enable: "Enable",
      createAt: "Create at",
      expiryDate: "Expiry Date",
      voucherName: "Voucher name",
      codeName: "Code name",
      discountValue: "Discount value",
      action: "Action",
      editVoucher: "Edit Voucher",
      enterVoucherName: "Enter voucher name ",
      percentGiftCard: "Percent gift card (%)",
      amountGiftCard: "Amount gift card ($)",
      voucherExpiresIn: "Voucher expires in",
      month: "Month",
      back: "Back",
      save: "Save",
      errOwnerNotFound: "Owner not found. Please log in again! ",
      errVoucherName: "Voucher name is required",
      errCoupon: "Please enter coupon",
      errEnterDateStart: "Please enter date start!",
      errEnterDateEnd: "Please enter date end",
      theStartDate: "The start date must be smaller than the end date",
      areYouWantDelete: "Are you sure you want to delete?",
      cancel: "Cancel",
      delete: "Delete",
      createVoucher: "Create Voucher",
      pleaseEnterExpire: "please enter Expire in.",
      voucherHaveSameCodeName: "Voucher Have Same Code Name",
    },
    subscriptionPage: {
      subscription: "Subscription",
      yourSubcription: "Your Subscription",
      youAreUsing: "You are using",
      to: "to",
      thisMonthYouHave: "This month you have",
      sMSLeft: "SMS left",
      yourCard: "Your Card",
      update: "Update",
      subcriptionRenew: "Subscription Renew:",
      cancel: "Cancel",
      pleaseChoosePackage: "Please choose your package",
      month: "Month",
      year: "Year",
      monthly: "MONTHLY",
      yearly: "YEARLY",
      twoYearly: "EVERY 2 YEAR",
      basic: "BASIC",
      large: "LARGE",
      super: "SUPER",
      saveTK: "Save",
      months: "months",
      whatIncluded: "What’s included",
      upgrade: "Upgrade",
      ultimatePackageCall: "Ultimate Package Call us at",
      changeCard: "Change Card",
      cardNumber: "Card Number",
      expiryDate: "ExpiryDate",
      submit: "Submit",
      areYouSureCancel: "Are You Sure Cancel?",
      heyYouWhen:
        "Hey you. When you cancel your account, you will lose everything",
      customerData: "Customer data",
      customerContact: "Customer contact",
      theAbilityToDo: "The ability to do marketing automation",
      increaseGoogleReview: "Increase Google Review Rating",
      getBackLostCustomer: "Get back lost customers",
      back: "Back",
      remove: "Remove",
      upgradePackage: "Upgrade Package",
      wantToChangePackage: "Are you sure you want to change the package ?",
      yestIWant: "Yes, I want",
      save: "Save",
      current: "Current",
      pleaseEnterPasscode: "Please enter passcode",
      confirm: "Confirm",
      enterYouPass: "Enter you pass code to cancel account",
      close: "Close",
      thisYourPackage: "This is the package you are using",
      changePackageSuccess: "Change package success!",
      cancelPackageSuccess: "Cancel package success",
    },
    feedback: {
      yourFeedback: "Your Feedback",
      pleaseGiveUsYour:
        "Please give us your opinion about what feature you like, which one you dont like, what feature you wish to have to help your restaurant. our goal is to serve your business better.",
      enterFeedback: "Enter feedback",
      attachFile: "Attch file",
      dragAdnDropToUpload: "Drag and drop or click to upload",
      descriptionLimitsText: "Description/Limits Text",
      send: "Send",
      history: "History",
      feedBack: "Feedback",
      ownerNotFound: "Owner not found",
      thankYourFeedback: "Thank you for your feedback to us",
      contentIsRequired: "Content is required",
    },
    offlineModePage: {
      offlineMode: "Offline Mode",
      table: "Table",
      order: "Order",
      bill: "Bill",
      staff: "Staff",
      timePrint: "Time print",
      customer: "Customer",
      submit: "Submit",
    },
    printPage: {
      time: "Time",
      guest: "Guest number",
      tableName: "Table name",
      customer: "Customer",
      phoneNumber: "Phone Number",
      takeAway: "Take away",
      diner: "Diner",
      freeSide: "Free side",
      thanks: "Thank you",
      subTotal: "Sub Total",
      tax: "Tax",
      discount: "Discount",
      total: "Total",
      tipFor: "Tip for",
      bill: "Bill",
      printDateTime: "Print date-time",
      orderDetail: "Order detail",
      ConvenienceFee: "Convenience fee",
      report: "Report",
    },
    handheldPOS: {
      createHandheldPOS: "Create handheld pos",
      noNumber: "No.",
      nameHandheldPOS: "Name handheld",
      creationDate: " Creation date",
      createAccountHandheld: "Create Account Handheld",
      editAccountHandheld: "Edit Account Handheld",
      editHandheld: "Edit Handheld",
      pleaseEnterMerchanId: "please enter MerchanId",
      pleaseEnterAuthorization: "please enter Authorization",
      pleaseEnterHsnName: "please enter Hsn Name",
      pleaseEnterHsnKey: "please enter Hsn Key",
    },
  },
  VN: {
    handheldPOS: {
      createHandheldPOS: "Create handheld pos",
      noNumber: "STT",
      nameHandheldPOS: "Tên máy cầm tay",
      creationDate: " Ngày tạo",
      createAccountHandheld: "Tạo Tài Khoản Máy",
      editAccountHandheld: "Chỉnh Sửa Tài Khoản Máy",
      editHandheld: "Chỉnh Sửa Máy ",
      pleaseEnterMerchanId: "Vui lòng nhập MerchanId",
      pleaseEnterAuthorization: "Vui lòng nhập Authorization",
      pleaseEnterHsnName: "Vui lòng nhập Hsn Name",
      pleaseEnterHsnKey: "Vui lòng nhập Hsn Key",
    },
    layout: {
      pickup: "Đến lấy",
      tablePOS: "Bàn",
      menuPOS: "Menu POS",
      clockInOut: "Chấm công",
      setting: "Cài đặt",

      setUpTable: "Thiết lập bàn",
      menuTemplate: "Menu",
      customer: "Khách hàng",
      customerReciepts: "Biên lai",
      report: "Báo cáo",
      review: "Đánh giá",
      inventory: "Kho",
      staffs: "Nhân viên",
      setUpVoucher: "Thiết lập voucher",
      subscription: "Subscription ",
      feedback: "Phản hồi",
      offlineMode: "Chế độ ngoại tuyến",
      order: "Hóa đơn",
      setupTable: "Thiết lập bàn",
      reservation: "Đặt chỗ",
      giftCard: "Thẻ quà tặng",
      giftCardHistory: "Lịch sử thẻ quà tặng",
      listQrCodeGiftCard: "Danh sách thẻ quà tặng bằng QrCode",
      notification: "Thông báo",
      payment: "Thanh toán",
      paid: "Thanh toán",
      help: "Trợ giúp",
      newOrder: "Gọi món",
      kitchenCounter: "Bếp",
      ownerMode: "Chủ sở hữu",
      staffMode: "Nhân viên",
      logOut: "Đăng xuất",
      toOwnerStaff: "Đến chế độ chủ sở hữu! Vui lòng nhập mật mã của bạn.",
      toVoidBillOTP: "Để vô hiệu hóa hóa đơn! Vui lòng nhập mật mã của bạn.",
      toOwnerLogOut: "Để đăng xuất! Vui lòng nhập mật mã của bạn.",
      enterPasscode: "Nhập mật mã chủ sở hữu hoặc người quản lý",
      click: "Nhấn",
      history: "Lịch sử",
      webSupport: "Trang web Hỗ trợ",
      phoneSupport: "Số điện thoại hỗ trợ",
      SMSSupport: "SMS Hỗ trợ",
    },
    notification: {
      home: "Trang chủ",
      order: "Đơn hàng",
      payment: "Thanh toán",
      paid: "Thanh toán",
      suport: "Hỗ trợ",
      delete2Day: "Thông báo sẽ bị xóa sau 2 ngày",
      orderNumber: "Đơn hàng số:",
      request: "Yêu cầu",
      dishes: "món ăn",
      rarkReadAll: "Đánh dấu đã đọc tất cả",
      exit: "Thoát",
      reloadPage: "Tải lại trang",
    },
    login: {
      loginToSystem: "Đăng nhập vào hệ thống",
      userName: "Tên đăng nhập",
      password: "Mật khẩu",
      forgetPassword: "Quên mật khẩu?",
      login: "Đăng nhập",
      enterYourUsername: "Nhập tên tài khoản / Email",
      enterYourPassword: "Nhập mật khẩu của bạn",
      passWordRequired: "Vui lòng nhập mật khẩu!",
      usernameRequired: "Vui lòng nhập tên tài khoản!",
      contactUsAtHotline:
        "Vui lòng thử lại hoặc liên hệ với chúng tôi theo đường dây nóng",
      somethingWentWrong: "Đã xảy ra lỗi, vui lòng thử lại sau!",
      anErrorHasOccurred:
        "Một lỗi đã xảy ra. Xin vui lòng kiểm tra kết nối Internet của bạn",
    },
    forgotPassword: {
      forgotPassword: "Quên mật khẩu",
      enterYourEmail: "Nhập email của bạn",
      getCode: "Lấy mã",
      login: "Đăng nhập?",
      code: "Mã code",
      enterCode: "Nhập mã code",
      weHaveSentTheCode:
        "Chúng tôi đã gửi mã đến email của bạn. Kiểm tra để có được nó!",
      emailNotFormat: "Email không đúng định dạng",
      pleaseEnterEmail: "Vui lòng nhập email của bạn",
      pleaseEnterCode: "Vui lòng nhập mã code từ email",
      somethingWentWrong: "Đã xảy ra lỗi, vui lòng thử lại sau!",
      changePasswordSuccess: "Thay dổi mật khẩu thành công!",
    },
    posMenu: {
      pickUp: "Đến lấy",
      time: "Giờ",
      guest: "SL khách",
      filter: "Lọc",
      chooseFoodGroup: "Chọn loại món",
      eatHere: "Tại quán",
      orderToGo: "Mang đi",
      findDishes: "Tìm kiếm món ăn...",
      clear: "Xóa",
      clearAll: "Xóa tất cả",
      subTotoal: "Tổng đơn",
      tax: "Thuế",
      enterTip: "Nhập tip",
      discount: "giảm giá",
      payment: "Thanh toán",
      cash: "Tiền mặt",
      card: "Thẻ",
      total: "Tổng cộng",
      order: "Order",
      noteForKitchen: "Ghi chú",
      addToCard: "Thêm vào giỏ hàng",
      sideDish: "món ăn kèm",
      viewMore: "Xem thêm",
      viewLess: "Thu gọn",
      delivery: "Giao Hàng",
      apply: "Áp dụng",
      ConvenienceFee: "Phí thanh toán",
      theConvenienceFee:
        "Phí thanh toán được tính cho mỗi giao dịch bởi nhà cung cấp bên thứ ba. Chúng tôi không giữ lại bất kỳ phần nào của khoản phí này.",
      submit: "Đồng ý",
      mayItakeYourOrder: "Tôi có thể nhận đơn đặt hàng của bạn được không!",
      pleaseChoosePaymentMethod: "Vui lòng chọn phương thức thanh toán!",
      tippingMustThan0: "Tiền tip phải lớn hơn 0",
      tableName: "Tên bàn:",
      takeAWay: "Mang đi",
      Guest: "Khách",
      cashTotal: " Tổng tiền mặt",
      staffOrder: "Nhân viên order",
      Pleaseentercustomername: "Vui lòng nhập tên khách hàng!",
      Pleaseentercustomerphone: "Vui lòng nhập số điện thoại!",
      PleaseEnterAddOnDescription: "Vui lòng nhập mô tả bổ sung",
      PleaseEnterAddOnPrice: "Vui lòng nhập giá bổ sung",
      Description: "Mô tả",
      EnterAmount: "Giá",
    },
    posTable: {
      findTableByPhone: "Tìm kiếm bằng số điện thoại hoặc tên của khách hàng",
      avaiable: "Trống",
      Reserved: "Đang",
      Paid: "Xong",
      bill: "Hóa đơn",
      addTip: "Tip",
      paymentMethod: "Phương thức thanh toán",
      payWidthCard: "Trả bằng thẻ",
      payWidthCash: "Trả bằng tiền mặt",
      rececievedAmount: "Số tiền nhận",
      remainingAmount: "(0) Số tiền thừa",
      summary: "Hóa đơn",
      customer: "Nhập tip",
      employeeWantToTip: "Nhân viên muốn tip",
      itemsTotal: "Tổng số tiền",
      tip: "Tip",
      tax: "Thuế",
      Total: "Tổng tiền",
      Discount: "Giảm giá",
      setAvailbleState: "bạn có muốn bàn",
      setAvailbleState2: "này chuyển thành bàn trống?",
      payBill: "Thanh toán",
      chooseBillOrder: "Chọn bill để order",
      dineIn: "Tại bàn",
      takeAway: "Mang đi",
      floor: "Tầng",
      chooseAll: "Chọn tất cả",
      ordertogo: "Mang đi",
      apply: "Áp dụng",
      howmanyGuests: "Có bao nhiêu khách?",
      enternumberguests: "Nhập số khách",
      Confirm: "Xác nhận",
      splitbill: "Tách hóa đơn",
      voidBill: "Xóa món",
      movebill: "Di chuyển hóa đơn",
      newbill: "Tạo mới",
      moveBillToTable: "Di chuyển hóa đơn sang bàn",
      cash: "Tiền mặt",
      cashTotal: " Tổng tiền mặt",
      pickup: "Đến lấy",
      customerName: "Tên khách hàng",
      customerphonenumber: "Số điện thoại",
      submit: "Xác nhận",
      submitAndOrder: "Xác nhận & đặt đơn",
    },
    clockInOut: {
      clock_In: "Giờ bắt đầu",
      clock_Out: "Giờ kết thúc",
      clockInOut: "Bảng chấm công",
      no: "Stt",
      employee: "Nhân viên",
      clockIn: "Giờ bắt đầu",
      clockOut: "Giờ kết thúc",
      workTime: "Thời gian làm",
      summary: "Thống kê",
      date: "Ngày",
      name: "Tên",
      tip: "Tip",
      print: "In",
      table: "Bàn",
      Order: "Mã đơn",
      bill: "Tổng đơn",
      time: "Thời gian",
    },
    qrCode: {
      scanToView: "Quét để xem thực đơn",
      doYouWantToFind: `Bạn có muốn tìm món ăn phổ biến nhất không?`,
      byUsingYourPhone: "Bằng cách sử dụng điện thoại của bạn",
      orderDirectlyIn: `Đặt hàng trực tiếp trên điện thoại &`,
      bringYourOrder: " chúng tôi sẽ mang đến bàn của bạn",
      download: "Tải xuống",
      print: "In",
      downloadAll: "Tải xuống tất cả",
      printAll: "In tất cả",
      floor: "Floor",
      scanQRCodeNow: "Quét mã QR ngay!",
    },
    setting: {
      titleQuesAddPrinter: "Máy in này in để làm gì?",
      everythingElse: "Lựa chọn khác (Biên nhận, Nhân viên, Chế độ chủ sở hữu)",
      entree: "Thức ăn chính",
      appetizer: "Món khai vị",
      beverage: "Đồ uống",
      information: "Thông tin",
      password: "Mật khẩu",
      tiptax: "Tip & Thuế",
      connectPrinter: "Kết nối máy in",
      connectStripe: "Kết nối Stripe",
      allowCustomerInput:
        "Cho phép khách hàng nhập NGÀY SINH NHẬT khi check-in",
      showCustomerPoint: "Hiển thị điểm của khách hàng khi check-in",
      textOver160Character:
        "Tin nhắn trên 160 ký tự, không bao gồm mã giảm giá, sẽ được gửi trong 2 tin nhắn.",
      googleBussinessID: "Google Bussiness ID",
      nameStore: "Tên cửa hàng",
      askForReviewMessage: "Yêu cầu tin nhắn đánh giá",
      feedbackforserver: "Feedback For Server",
      thankYourForVisitUsToday:
        "Cảm ơn bạn đã ghé thăm chúng tôi ngày hôm nay. Bạn có thể cho chúng tôi một đánh giá.",
      customTime: "Cấu hình thời gian",
      birthdayMessage: "Lời chúc sinh nhật",
      weWantToWishYou:
        "Chúng tôi muốn chúc bạn một sinh nhật vui vẻ và có một món quà nhỏ cho lần ghé thăm tiếp theo của bạn với mức giảm giá 10%.",
      timeSentBirthdayMessage: "Thời gian gửi lời chúc sinh nhật",
      onBirthdayDay: "Vào ngày sinh nhật",
      couponForCertain: "Phiếu giảm giá tích điểm:",
      thankYouForBeingOur:
        "Cảm ơn bạn đã là khách hàng thân thiết của chúng tôi! chúng tôi rất vui được giảm giá 10% cho lần ghé thăm tiếp theo của bạn.",
      point: "điểm",
      for1Spent: "với 1 USD chi tiêu",
      sentCouponWhenTheyReach: "Đã gửi phiếu giảm giá khi họ đến",
      couponMessage: "Thông báo phiếu giảm giá",
      hiFriendThisIs: "Xin chào bạn, tôi là",
      longTimeNoSee: `👋Lâu rồi không gặp! Chúng tôi nhớ bạn. Đây là phiếu giảm giá GIẢM GIÁ 30% đặc biệt để chào mừng bạn quay trở lại. Rất mong được gặp bạn!🎉`,
      noSent: "Không gửi",
      changePassword: "Đổi mật khẩu",
      oldPass: "Mật khẩu cũ",
      newPass: "Mật khẩu mới",
      enterNewPassword: "Nhập mật khẩu mới",
      retypePass: "Nhập lại mật khẩu",
      enterNewPasswordToConfirm: "Nhập mật khẩu mới để xác nhận.",
      cancel: "Hủy",
      changePass: "Đổi mật khẩu",
      automaticallyCalculate:
        "Tự động tính toán dựa trên tổng hóa đơn của khách hàng, sẽ hiển thị tại trang thanh toán của khách hàng.",
      enterTheNumberOf: "Nhập phần trăm %",
      tax: "Thuế",
      save: "Lưu",
      forYourReference: "Để bạn tham khảo",
      ortherRestaurantsIncreased:
        "Other restaurants increased 23% of TIP by making 20% 30% and 40% instead of 10% 15% 20%.",
      myPrinters: "Máy in của tôi",
      searchForPrinter: "Tìm kiếm máy in",
      connectPrinterViaWifi: "Kết nối với máy in qua Wifi",
      connect: "Kết nối",
      printer: "Máy in",
      printFromSections: "Print from sections",
      chooseSectionToPrint: "Chọn phần để in",
      qrCodeMenu: "mã Qr thực đơn",
      customerReceipts: "Biên lai khách hàng",
      kitchenOrder: "đặt hàng nhà bếp",
      staffTicket: "Phiếu nhân viên",
      disconnectThisDevice: "Ngắt kết nối thiết bị này",
      addNewPaymentGateway: "Thêm cổng thanh toán mới",
      rightAway: "Ngay lặp tức",
      chooseVoucherID: "Chọn ID mã giảm giá",
      Reward: "Quà tặng",
      sendWhenTheyNotVisit: "Gửi khi họ không ghé thăm",
      month: "Tháng",
      day: "Ngày",
      hours: "giờ",
      minutes: "phút",
      customMessage: "Cấu trúc tin nhắn",
      tipRequite3TipOption: "Tip (yêu cầu tùy chọn 3 tip)",
      otherRestaurantsIncreased:
        "Các nhà hàng khác tăng 23% TIP bằng cách kiếm 20% 30% và 40% thay vì 10% 15% 20%.",
      timeZone: "Múi giờ",
      LinkForCustomer:
        "Link để khách hàng gọi mang đi, không gắn vào bất kỳ bàn nào. (cái này dành cho những người muốn đặt hàng trực tuyến và đến lấy)",
      exchangePoints: "Đổi điểm:",
      showRewards: "Hiển thị đổi điểm",
      pointGame: "Điểm game",
      pointRestaurant: "Điểm nhà hàng",
      addTerminalStripe: "Thêm máy đọc thẻ Stripe",
      upSale: "Up-sale",
      happyHour: "Happy Hour",
      forEveryDollar:
        "Với mỗi đô la bạn chi tiêu, bạn sẽ kiếm được số điểm tương đương.",
      onceYouAccumulate: "Một khi bạn tích lũy",
      points: "Điểm.",
      weWillProvide: "Chúng tôi sẽ giảm giá cho bạn",
      off: ".",
      thisSentenceBelow: 'Lưu ý: Câu dưới đây sẽ hiển thị trong "Point Page"',
      changePasscode: "Thay đổi mật mã",
      oldPasscode: "Mật mã cũ",
      newPasscode: "Mật mã mới",
      retypePasscode: "Nhập lại mật mã",
      pleaseEnterOldPass: "Vui lòng nhập mật khẩu cũ!",
      pleaseEnterNewPass: "Vui lòng nhập mật khẩu mới!",
      pleaseEnterRetypePass: "Vui lòng nhập lại mật khẩu!",
      changePasswordSuccess: "Thay đổi mật khẩu thành công",
      pleaseEnterOldPasscode: "Vui lòng nhập mật mã cũ!",
      pleaseEnterNewPasscode: "Vui lòng nhập mật mã mới!",
      pleaseEnterRetypePasscode: "Vui lòng nhập lại mật mã!",
      changePasscodeSuccess: "Thay đổi mật mã thành công",
      phoneNumber: "Số điện thoại",
      address: "Địa chỉ:",
      passcode: "mật mã",
      enterNewPasscodeToConfirm: "Nhập mật mã mới để xác nhận",
      enterNewPasscode: "Nhập mật mã mới",
      apply3Processing: "Áp dụng phí xử lý 3% khi khách hàng sử dụng thẻ.",
      yourCanPassYourStripe:
        "Bạn có thể chuyển phí Stripe của mình cho khách hàng bằng cách cộng khoản phí này vào số tiền tính phí cuối cùng. Điều quan trọng là đảm bảo rằng hành động này tuân thủ mọi luật hiện hành liên quan đến doanh nghiệp của bạn. Nếu bạn không chắc chắn liệu các luật đó có áp dụng hay không, hãy tham khảo ý kiến cố vấn pháp lý của bạn.",
      ortherRestaurantIncreased:
        "Các nhà hàng khác tăng 23% TIP bằng cách kiếm 20% 30% và 40% thay vì 10% 15% 20%.",
      toReceivePaymentForm:
        "Để nhận thanh toán từ khách hàng sử dụng dịch vụ trực tuyến, hãy kết nối với cổng thanh toán từ công ty thanh toán Stripe.",
      weChoseThisCompany:
        "Chúng tôi chọn công ty này làm đối tác vì đây là một trong những công ty lớn nhất và có độ bảo mật cao.",
      toConnectToStripe:
        "Để kết nối với cổng thanh toán Stripe, chỉ cần 2 bước:",
      onestCreateAccount: "1st: Tạo tài khoản Stripe",
      twondAddStripe: "2nd: Thêm key Stripe API kết nối BriJack POS",
      addNewPayment: "Thêm Cổng thanh toán mới",
      accountDefault: "Tài khoản mặc định",
      account: "Tài khoản",
      connectDoordash: "Kết nối Doordash",
      developerId: "Developer id",
      keyId: "Key id",
      signingSecret: "Signing secret",
      registerAccount: "Đăng ký tài khoản mới",
      addNewAccPaymentGateway: "Thêm tài khoản thanh toán mới",
      submit: "Lưu",
      enterNameAccount: "Nhập tên tài khoản",
      nameForYourReference: "Tên (For your reference)",
      changeTerminalReader: "Thay đổi máy đọc thẻ",
      connected: "Đã kết nối",
      addTerminalReader: "Thêm máy đọc thẻ",
      delete: "Xóa",
      avaiablePrinter: "Máy in có sẵn",
      POSPrinter: "In ở POS",
      webOrderPrinter: "In ở trang Order",
      menuPOSPrinter: "In ở trang Menu POS",
      choosePrinter: "Chọn máy in",
      listPrinter: "Danh sách máy in",
      addPrinter: "Thêm máy in",
      printerName: "Tên máy in",
      enterPrinterName: "Nhập tên máy in",
      printerIsRequired: "Tên máy in không được để trống.",
      wantDeletePrinter: "Bạn có muốn xóa máy in",
      statusUpSale: "Bật/tắt Up-sale:",
      chooseNumberFood: "Chọn số món ăn cho phần này:",
      chooseNumberDrink: "Chọn số đồ uống cho phần này:",
      chooseHappyHourDay: "Chọn Ngày Happy Hour:",
      Repeat: "Lặp lại:",
      chooseHappyHourTime: "Chọn Giờ Happy Hour:",
      chooseFood: "Chọn Món ăn",
      monday: "Thứ Hai",
      tuesday: "Thứ Ba",
      wednesday: "Thứ Tư",
      thursday: "Thứ Năm",
      friday: "Thứ Sáu",
      saturday: "Thứ Bảy",
      sunday: "Chủ Nhật",
      everyWeedk: "Mỗi Tuần",
      every2Weedk: "2 Tuần 1 lần",
      every4Weedk: "4 Tuần 1 lần",
      chooseAllFood: "Chọn Tất Cả",
      custom: "Tùy Chọn",
      selectFood: "Chọn Món Ăn",
      to: "Đến",
    },
    orderPage: {
      timeOrder: "Giờ đặt",
      totalItems: "Tổng món ăn",
      phone: "SĐT",
      customer: "Khách hàng",
      invoiceMethod: "Loại hóa đơn",
      delete: "Xóa",
      print: "In",
      total: "Tổng",
      pages: "trang",
      show: "Hiển thị",
      page: "trang",
      goto: "Đi tới",
      showAll: "Hiển thị tất cả",
      phoneNumber: "Số điện thoại",
      sideDish: "Món ăn kèm:",
    },
    kitchenCounter: {
      back: "Trở về",
      history: "Lịch sử",
      hideHeader: "Ẩn thanh tiêu đề",
      showHeader: "hiện thanh tiêu đề",
      takeAway: "Mang đi",
      dineIn: "Tại bàn",
      home: "Trang chủ",
      request: "Bếp",
      freeSide: "Món ăn kèm:",
      backToRequest: "Trở về trang bếp",
      restore: "Khôi phục",
      dishReadyAt: "Hoàn thành lúc",
      complete: "Hoàn thành",
    },
    reservation: {
      dateTime: "Ngày giờ:",
      customer: "Khách hàng:",
      phoneNumber: "Số điện thoại:",
      seatsNumber: "Số ghế:",
      note: "Ghi chú:",
      cancel: "Hủy",
      confirm: "Xác nhận",
      wantConfirm: "Bạn có muốn xác nhận đặt bàn?",
      wantCancel: "Bạn có muốn hủy đặt bàn?",
      wantFinish: "Bạn có muốn hoàn tất việc đặt chỗ?",
      finish: "Hoàn thành",
      enterReservation: "Nhập tổng số lượt đặt chỗ",
      save: "Lưu",
      seats: "ghế",
      delete: "Xóa",
      bookingTableDetail: "Chi tiết bảng đặt chỗ",
      info: "Thông tin",
      Date: "Ngày",
      Time: "Giờ",
      Seat: "Ghế",
      wantSaveChange: "Bạn có muốn lưu các thay đổi?",
    },
    passCode: {
      toClockInEnter: "Để bắt đầu chấm công, hãy nhập mật mã của bạn",
      employeePassCode: "Mã nhân viên",
      click: "Nhấn",
      toClockOutEnter: "Để hoàn tất chấm công, hãy nhập mật mã của bạn",
      pleaseEnterPasscode: "Vui lòng nhập mã!",
      toDeleteEnter: "Để xóa hãy nhập mật mã của bạn",
      delete: "Xóa",
      yourPassCode: "Mật mã của bạn",
      toShowDetail: "Để hiển thị chi tiết, hãy nhập mật mã của bạn",
    },
    setUpTable: {
      available: "Bàn Trống",
      reserved: "Đang sử dụng",
      paid: "Đã thanh toán",
      floor: "Tầng",
      information: "Thông tin",
      shape: "Hình",
      square: "Vuông",
      circle: "Tròn",
      size: "Kích cỡ",
      rorate: "Xoay",
      manage: "Quản lý",
      tool: "Công cụ",
      floorManagement: "Quản lý tầng",
      addTable: "Thêm Bàn",
      multiSelect: "Chọn Nhiều",
      clear: "Xóa",
      addFloor: "Thêm Tầng",
      add: "Thêm",
      cancel: "Hủy",
      save: "Lưu",
      howManyTablesAdd: "Bạn muốn thêm bao nhiêu bàn?",
      single: "Một bàn",
      multi: "Nhiều bàn",
      selected: "Đã chọn",
      youWantToClear: "Bạn có muốn quay về mặc định?",
      no: "Hủy",
      yes: "Có",
      areYouSure: "Bán có chắc chắn?",
      pleaseEnterTable: "Vui lòng nhập số lượng bàn!",
    },
    customerPage: {
      filter: "Lọc",
      today: "Hôm nay",
      longTimeNoSee: "Khách hàng lâu ngày",
      byReview: "Bằng nhận xét",
      byJourney: "Bằng khoảng thời gian",
      customerList: "Danh sách khách hàng",
      customer: "Khách hàng",
      findReviewByPhone: "Tìm kiếm nhận xét bằng số điện thoại",
      sendSMS: "Gửi SMS",
      lastVisit: "Lần cuối",
      timesVisit: "Số lần",
      points: "Điểm",
      name: "Tên",
      phone: "Số điện thoại",
      totalAmount: "Tổng số tiền",
      total: "Tổng",
      pages: "Trang",
      show: "Hiển thị",
      page: "trang",
      goTo: "Đi tới",
      customerSelected: "khách hàng đã chọn",
      to: "đến",
      chooseVoucherID: "Choose voucher ID",
      sendNow: "Gửi Ngay",
      male: "Nam",
      female: "Nữ",
      point: "Điểm",
      returnVisits: "Lần quay lại",
      orderHistory: "Lịch sử đơn hàng",
      date: "Ngày",
      time: "Giờ",
      order: "Đặt hàng",
      table: "Bàn",
      bills: "Hóa đơn",
      tips: "Tips",
      staff: "Nhân viên",
      orderDetail: "Chi tiết đơn hàng",
      printDateTime: "Ngày giờ in:",
      phoneNumber: "Số điện thoại:",
      dineIn: "Tại nhà hàng",
      takeAWay: "Mang đi",
      subTotal: "Tổng",
      tax: "Thuế",
      discount: "Giảm giá",
      totalTax: "Tổng (Thuế)",
      print: "In",
      tipFor: "Tip cho",
      ConvenienceFee: "Phí thanh toán",
    },
    menuTemplate: {
      menuManagement: "Quản lý thực đơn",
      sideDish: "Món ăn kèm",
      bevarage: "Đồ uống",
      appetizers: "Món khai vị",
      footRank: "Xếp hạng món ăn",
      footRating: "Đánh giá món ",
      today: "Hôm nay",
      yesterday: "Hôm qua",
      allFood: "Tất cả các món",
      addCategories: "Thêm danh mục",
      showingFoodRanking: "Hiển thị xếp hạng món ăn:",
      has: "Có",
      products: "Sản phẩm.",
      activeCategory: "Đang hoạt động",
      addFood: "Thêm món ăn",
      addAnotherdish: "Thêm món ăn khác",
      activeFood: "Hiển thị",
      addSideDish: "Thêm món ăn kèm",
      createFood: "Tạo món ăn mới",
      uploadFoodImg: "Tải lên hình ảnh món ăn",
      recommendedImageFormats: "Đề xuất định dạng hình ảnh:",
      atLeast: ", ít nhất",
      forOptimalDisplay: "để hiển thị tối ưu.",
      foodName: "Tên món ăn:",
      selectFoodOrBeverage: "Chọn thực phẩm hoặc đồ uống!",
      orderNo: "Số thứ tự.",
      price: "Giá:",
      description: "Mô tả:",
      descriptionText: "Nhập mô tả",
      selectMaterialToConnect:
        "Chọn nguyên liệu để kết nối vào kho để quản lý kho (tùy chọn)",
      rawMaterialInWarehouse: "Nguyên liệu, vật liệu trong Kho",
      chooseSideComplimetary: "Chọn món ăn bổ sung cho món ăn này (tùy chọn)",
      optionsForThis: "(Tùy chọn) Tùy chọn cho món ăn này: ",
      exampleMedium: "Ví dụ: Bình thường, Chín vừa, Chín tái.",
      pressEnterAfter: "(Nhấn 'Enter' sau khi gõ)",
      exampleSpice:
        "Ví dụ: “Cay”, “Không chứa protein gluten”,....và nhấn enter",
      preTextFor:
        "(Tùy chọn) Văn bản mở đầu cho món ăn này (khách hàng có thể chọn tất cả)",
      cancel: "Hủy",
      save: "Lưu",
      beverage: "Đồ uống",
      appetizer: "Đồ khai vị",
      entree: "Thức ăn chính",
      enterPrice: "Nhập giá",
      createNewFoodCategory: "Tạo mới danh mục thực phẩm",
      categoriesName: "Tên Danh Mục",
      name: "Tên",
      pickACategoryColor: "Chọn màu danh mục",
      edit: "Chỉnh sửa",
      delete: "Xóa",
      editFoodGroup: "Chỉnh sửa nhóm thực phẩm",
      doYouWantDelete: "Bạn có muốn xóa nhóm thực phẩm?",
      doYouWantDeleteFood: "Bạn có muốn xóa món ăn?",
      yes: "Có",
      no: "Hủy",
      editFood: "Chỉnh sửa thực phẩm",
      itWillShowInFoodMenu: "Nó sẽ hiển thị trong menu đồ ăn",
      rawMaterials: "Nguyên liệu, vật liệu trong Kho",
      saveMaterials: "Lưu tài liệu",
      qty: "SL",
      createNewSideDish: "Tạo món ăn kèm mới",
      editNewSideDish: "Chỉnh sửa món ăn kèm",
      noNumber: "STT",
      sideDishName: "Tên Món Ăn Kèm",
      howmanyFreeSide:
        "Có bao nhiêu món ăn phụ miễn phí mà khách hàng có thể chọn món ăn này",
      cancelled: "Cancelled",
      yourFoodIsSafe: "Your food is safe",
      deleted: "Đã xóa",
      yourFoodDeleted: "Món ăn của bạn đã được xóa.",
      yourGroupFoodDeleted: "Nhóm thực phẩm của bạn đã được xóa.",
      updateSuccessful: "Cập nhật thành công.",
      chooseTheFoodItem: "Chọn món ăn đã được tạo trước đó",
      searchAndSelect: "Tìm kiếm & Chọn ",
      soupAndStews: "Súp và món hầm",
      Desserts: "Món tráng miệng",
      Salad: "Xa lát",
      Grill: "Nướng",
      foodSection: "Phần thực phẩm",
      print: "In",
      settingFoodSection: "Cài đặt phần thực phẩm",
      back: "Trở về",
    },
    customerReceipts: {
      filter: "Lọc",
      today: "Hôm nay",
      selectStaff: "Chọn nhân viên",
      findreceiptby: "Tìm kiếm biên lai bằng số điện thoại hoặc tên khách hàng",
      customerReceipts: "Biên Lai Khách Hàng",
      table: "Bảng",
      order: "Hóa đơn",
      bill: "Tổng tiền",
      tip: "Tip",
      staff: "Nhân viên",
      timePrint: "Thời gian",
      customer: "Khách hàng",
      phoneNumber: "Số điện thoại",
      printDateTime: "Thời gian in",
      viewMore: "Xem thêm",
      viewLess: "Thu gọn",
      subTotal: "Tổng sản phẩm",
      tax: "Thuế",
      discount: "Giảm giá",
      total: "Tổng tiền",
      deleteBill: "Xóa hóa đơn ",
      print: "In",
      tipfor: "Tip cho",
      theConvenienceFee:
        "Phí thanh toán được tính cho mỗi giao dịch bởi nhà cung cấp bên thứ ba. Chúng tôi không giữ lại bất kỳ phần nào của khoản phí này.",
      ConvenienceFee: "Phí thanh toán",
      staffOrder: "Nhân viên order",
    },
    reportPage: {
      moneyTime: "Dòng Tiền",
      customersOverTime: "Customers Over Time",
      income: "Thu nhập",
      profit: "Lợi nhuận",
      salary: "Tiền Lương",
      import: "Nhập kho",
      visa: "Visa",
      cash: "Tiền mặt",
      returnCustomer: "Khách hàng cũ",
      newCustomer: "Khách hàng mới",
      coupons: "Phiếu giảm giá",
      goodReview: "Đánh giá tốt",
      badReview: "Đánh giá kém",
      detailIncome: "Chi tiết thu nhập",
      download: "Tải xuống",
      saleTax: "Thuế",
      orderOnlineToGo: "Đặt hàng online mang đi",
      orderOnlinePickUp: "Đặt hàng online đến lấy",
      googlePay: "Thanh toán bằng Google",
      applePay: "Thanh toán bằng Apple",
      reseveTable: "Đặt lại bàn",
      order: "Đặt hàng",
      today: "Hôm nay",
      totalCustomer: "Tổng khách hàng",
      incomeByMonth: "Lợi nhuận theo tháng",
      incomeByDate: "Lợi nhuận theo ngày",
      january: "Tháng 1",
      february: "Tháng 2",
      march: "Tháng 3",
      april: "Tháng 4",
      may: "Tháng 5",
      june: "Tháng 6",
      july: "Tháng 7",
      august: "Tháng 8",
      september: "Tháng 9",
      october: "Tháng 10",
      november: "Tháng 11",
      december: "Tháng 12",
      detail: "Chi tiết",
      date: "Ngày",
      time: "Thời gian",
      table: "Bàn",
      staff: "Nhân viên",
      bill: "Tổng tiền",
      printDateTime: "Thời gian in",
      viewMore: "Xem thêm",
      viewLess: "Thu gọn",
      subTotal: "Tổng sản phẩm",
      tax: "Thuế",
      discount: "Giảm giá",
      total: "Tổng tiền",
      deleteBill: "Xóa hóa đơn ",
      print: "In",
      tipfor: "Tip cho",
      theConvenienceFee:
        "Phí thanh toán được tính cho mỗi giao dịch bởi nhà cung cấp bên thứ ba. Chúng tôi không giữ lại bất kỳ phần nào của khoản phí này.",
      convenienceFee: "Phí thanh toán",
      report: "Báo cáo",
      customer: "Khách hàng",
      phoneNumber: "Số điện thoại",
      dineIn: "Tại nhà hàng",
      takeAWay: "Mang đi",
    },
    reviewPage: {
      filter: "Lọc",
      today: "Hôm nay",
      overview: "Tổng quan",
      totalReview: "Tổng lượt đánh giá",
      good: "Tốt",
      nuetral: "Bình thường",
      bad: "Tệ",
      HavenGiveReview: "Chưa đưa ra đánh giá",
      customerReview: "Đánh giá của khách hàng",
      findByPhone: "Tìm đánh giá bằng số điện thoại",
      sendSMSToAll: "Gửi SMS tới mọi người trong nhóm",
      points: "Điểm",
      customerName: "Tên KH",
      orderID: "ID đơn hàng",
      review: "Đánh giá",
      messageReview: "Đánh giá tin nhắn",
      phone: "Số điện thoại",
      sendSMS: "Gửi SMS",
      sendNow: "Gửi Ngay",
      to: "đến",
    },
    inventoryPage: {
      inventory: "Kho",
      all: "Tất cả",
      outOfStock: "Hết hàng",
      expiringSoon: "Sắp hết hàng",
      print: "In",
      createMaterial: "Tạo nguyên liệu",
      import: "Nhập",
      export: "Xuất",
      noNumber: "STT.",
      materialCode: "Mã nguyên liệu",
      materialName: "Tên nguyên liệu",
      dateAdded: "Ngày nhập",
      InventoryStock: "Tồn kho",
      reminder: "Nhắc nhở",
      unit: "Đơn vị",
      status: "Trạng thái",
      delete: "Xóa",
      preview: "Xem trước",
      createNewMaterial: "Tạo Mới Nguyên Liệu",
      enterMaterialName: "Nhập tên nguyên liệu:",
      selectUnit: "Chọn đơn vị",
      cancel: "Hủy",
      save: "Lưu",
      importMaterial: "Nhập nguyên liệu",
      searchAndSelect: "Tìm kiếm & Chọn",
      enterNumber: "Nhập số lượng",
      totalPrice: "Tổng giá",
      importer: "Người Nhập",
      note: "Chú ý:",
      poundLiterBotter: 'Khuyên bạn nên đặt lời nhắc bằng "Đặt thấp"',
      boxSetReninder: 'Đặt lời nhắc theo " NGÀY "',
      becauseItCanBe:
        "Bởi vì nó có thể được kết nối với thực phẩm, để thực hiện quá trình trừ sản phẩm trong kho.",
      chooseOneReminder: "Chọn 1 tùy chọn nhắc nhở",
      timeBase: "Thời gian",
      lowSetReminder: "Lời nhắc cài đặt thấp",
      day: "Ngày",
      back: "Trở lại",
      exportMaterial: "Đặt lời nhắc ở mức thấp",
      ExportDate: "Ngày Xuất",
      total: "Tổng",
      exportNumber: "Số lượng xuất",
      totalLeftInStock: "Số hàng còn trong kho",
      exporter: "Người xuất",
      lowStock: "Hàng sắp hết",
      inStock: "Còn hàng",
      materialNameRequired: "Tên nguyên liệu không được rỗng!",
      unitRequired: "Đơn vị không được rỗng!",
      successAddedMaterial: "Thêm nguyên liệu thành công",
      ownerNotFound: "Không tìm thấy chủ sở hữu.",
      importNumber: "Nhập số lượng",
      pleaseEnterMaterial: "Vui lòng nhập tên vật liệu!",
      pleaseEnterDate: "Vui lòng nhập ngày thêm!",
      pleaseEnterImportNumber: "Vui lòng nhập số lượng nhập",
      pleaseEnterTotalPrice: "Vui lòng nhập tổng giá",
      plesaeEnterImporter: "Vui lòng nhập người nhập!",
      pleaseEnterDayNumber: "Vui lòng nhập số ngày",
      pleaseMassNumber: "Xin vui lòng nhập số khối",
      enterName: "Nhập tên",
    },
    staffPage: {
      employee: "Nhân viên",
      salary: "Tiền lương",
      tipRule: "Thống kê Tip",
      staffReview: "Đánh giá nhân viên",
      employeeWorkingToday: "Nhân viên làm hôm nay",
      today: "Hôm nay",
      employeeWorking: "Nhân viên đang làm việc",
      activeEmployee: "Nhân viên đang làm",
      inactiveEmployee: "Nhân viên đã nghỉ",
      createNewStaff: "Tạo mới nhân viên",
      noNumber: "STT",
      clockIn: "Giờ bắt đầu",
      clockOut: "Giờ kết thúc",
      workingTime: "Thời gian làm",
      edit: "Chỉnh sửa",
      position: "Vị trí",
      dateJoined: "Ngày tham gia",
      caculateSalaryWeek: "Tính lương cho tất cả nhân viên mỗi tuần",
      fromMondayToSunday: "(từ thứ 2 đến chủ nhật)",
      caculateSalary2Week: "Tính lương cho tất cả nhân viên mỗi 2 tuần",
      caculateSalaryMonth: "Tính lương cho tất cả nhân viên mỗi 1 tháng",
      fromJanuarytoDecember: "(từ tháng 1 đến tháng 12)",
      setupTiprule: "thiết lập Tip",
      pleaseEnSureTheTotal:
        "Hãy đảm bảo tổng số tiền tip nhập vào là 100% hoặc ít hơn",
      allBevarageNeed: "Tất cả đồ uống cần có số lượng bằng nhau",
      allCleanTableNeed: "Tất cả các bàn sạch cần có số lượng bằng nhau",
      save: "Lưu",
      total: "Tổng",
      note: "Chú ý:",
      ifThereAreTwoPeople:
        'Nếu có hai người phục vụ đồ uống trong cửa hàng của bạn, hệ thống sẽ phân bổ đều phần trăm tiền boa cho người phục vụ đồ uống, đảm bảo mỗi người nhận được phần bằng nhau. Ví dụ: nếu tỷ lệ tiền boa của "Người phục vụ đồ uống" là 10% thì mỗi người sẽ nhận được 5%.',
      theSamePrinciple:
        "Nguyên tắc tương tự cũng sẽ được áp dụng cho người quản lý, đầu bếp và nhân viên dọn bàn.",
      exampleHowToInput: "Ví dụ về cách nhập số tiền",
      foodServer: "Quản lý thực phẩm:",
      chef: "Đầu bếp:",
      manager: "Quản lý:",
      beverageServer: "Quản lý nước giải khát:",
      cleanTable: "Dọn bàn:",
      plusOff: "Cộng thêm(%) của",
      hisTip: "tiền Tip",
      enterNumberOnly: "(Chỉ nhập số)",
      close: "Đóng",
      all: "Tất cả",
      good: "Tốt",
      justOK: "Ổn",
      bad: "Tệ",
      dateTime: "Ngày/Giờ",
      customerNamePhone: "Tên KH/SDT",
      rating: "Xếp hạng",
      staff: "Nhân viên",
      feedBack: "Phản hồi",
      pages: "trang",
      show: "Hiển thị",
      page: "trang",
      Goto: "Đi tới",
      createStaff: "Tạo nhân viên",
      staffName: "Tên nhân viên",
      passcode: "Mật mã",
      female: "Nữ",
      male: "Nam",
      status: "Trạng thái",
      birthday: "Ngày sinh",
      phoneNumber: "Số điện thoại",
      address: "Địa chỉ",
      role: "Chức vụ",
      chooseRole: "Chọn chức vụ",
      automaticClockOutTime: "Tự động hết thời gian làm việc",
      payyRoll: "Phương thức trả lương",
      chooseRoll: "Chọn phương thức",
      payPerHour: "Trả theo giờ",
      payPerDay: "Trả theo ngày",
      payPerWeek: "Trả theo tuần",
      payPer2Week: "Trả theo 2 tuần",
      payPerMonth: "Trả theo tháng",
      nameAppearsAtChckout:
        "Tên xuất hiện khi thanh toán của khách hàng trong phần Tip",
      back: "Trở về",
      create: "Tạo",
      editInfoStaff: "Chỉnh sửa thông tin nhân viên",
      january: "Tháng 1",
      february: "Tháng 2 ",
      march: "Tháng 3 ",
      april: "Tháng 4 ",
      may: "Tháng 5 ",
      june: "Tháng 6",
      july: "Tháng 7",
      august: "Tháng 8",
      september: "Tháng 9",
      october: "Tháng 10",
      november: "Tháng 11",
      december: "Tháng 12",
      name: "Tên",
      payer: "Trả lương",
      commission: "Lương cơ bản",
      totalTime: "Tổng thời gian",
      totalWorkEarn: "Tổng tiền làm",
      totalTip: "Tổng Tip",
      totalPay: "Tổng nhận được",
      deduct: "Kháu trừ",
      bonus: "Tiền thưởng",
      print: "In",
      employeeIncentives: "Khuyến khích và khấu trừ nhân viên",
      amount: "Số tiền",
      enterAmount: "Nhập số tiền",
      yesterday: "Hôm qua",
      date: "Ngày",
      table: "Bàn",
      timePrinter: "Thời gian in",
      bill: "Tiền",
      order: "Hóa đơn",
      active: "Hoạt động trở lại",
      Inactive: "Tắt hoạt động",
      areYouSureInactive: "Bán có muốn tắt hoạt động của",
      areYouSureActive: "Bạn có muốn mở lại hoạt động của",
      noIChangeMyMind: "Hủy",
      yes: "Đồng ý",
      update: "Cập nhật",
      allTotalTip: "Tất cả tiền tip",
      showAll: "Hiển thị tất cả",
    },
    giftCard: {
      giftCardHistory: "Lịch Sử Thẻ Quà Tặng",
      neverExpire: "Vô Hạn",
      giftCard: "Thẻ Quà Tặng",
      createGiftCardProgram: "Tạo Thẻ Quà Tặng",
      noNumber: "STT",
      enable: "Sử Dụng",
      expiryDate: "Thời Hạn",
      giftCardName: "Tên Thẻ",
      giftCardValue: "Giá Trị Thẻ",
      quantity: "Số lượng",
      used: "Đã Dùng",
      createGiftCard: "Tạo Thẻ Quà Tặng",
      enterGiftCardName: "Nhập Tên Thẻ",
      valueGiftCard: "Giá Trị Của Thẻ",
      percentGiftCard: "Thẻ quà tặng phần trăm (%)",
      amountGiftCard: "Thẻ quà tặng tiền mặt ($)",
      giftCardExpiresIn: "Thời gian áp dụng thẻ",
      to: "Đến",
      back: "Trở về",
      save: "Lưu",
      detail: "Chi tiết",
      listQRCodeGiftCard: "Danh Sách Thẻ Quà Tặng Bằng QRCode",
      download: "Tải xuống",
      downloaded: "Đã tải",
      show: "Hiển thị",
      downloadAll: "Tải Xuống Tất Cả",
      errOwnerNotFound:
        "Không tìm thấy chủ sở hữu. Xin vui lòng đăng nhập lại! ",
      errVoucherName: "Tên voucher là bắt buộc",
      errCoupon: "Xin vui lòng nhập phiếu giảm giá",
      errEnterDateStart: "Xin vui lòng nhập ngày bắt đầu!",
      errEnterDateEnd: "Xin vui lòng nhập ngày kết thúc",
      theStartDate: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc",
      dateAdd: "Ngày tạo",
      personSell: "Người bán",
      sellTo: "Người mua",
      Remain: "Số dư",
      From: "Từ",
      cardBalance: "Số dư thẻ",
      viewTransactions: "Xem giao dịch",
      Date: "Ngày",
      amount: "Số tiền",
      balance: "số dư",
      Thisgiftcardcannotbeexchangedforcashunlesssuch:
        "Thẻ quà tặng này không thể đổi thành tiền mặt trừ khi ",
      redemptionisrequiredbyapplicablelaw:
        "luật hiện hành yêu cầu việc đổi quà đó.",
      step1: "Bước 1",
      step2: "Bước 2",
      step3: "Bước 3",
      step4: "Bước 4",
      info: "Thông tin",
      sendfrom: "Gửi từ",
      sendto: "Gửi đến",
      phone: "Số điện thoại",
      deliver: "Giao",
      now: "Bây giờ",
      schedule: "Lịch",
      Messageoptional: "Tin nhắn (lựa chọn)",
      writeSomeNote: "Viết một số ghi chú...",
      cancel: "Hủy",
      payWithCash: "Trả bằng tiền mặt",
      payWithCard: "Trả bằng thẻ",
      urchasedDateAndTime: "Ngày và giờ mua",
      seller: "Người bán",
      contactPhoneEmail: "Điện thoại/Email liên hệ",
    },
    voucherPage: {
      voucher: "Mã Voucher",
      createVouvherProgram: "Tạo Voucher ",
      noNumber: "STT",
      enable: "Tình trạng",
      createAt: "Ngày Tạo",
      expiryDate: "Hạn sử dụng",
      voucherName: "Tên voucher",
      codeName: "Tên mã",
      discountValue: "Giá trị",
      action: "Thao tác",
      editVoucher: "Chỉnh Sửa Voucher",
      enterVoucherName: "Nhập tên voucher",
      percentGiftCard: "Thẻ quà tặng phần trăm (%)",
      amountGiftCard: "Thẻ quà tặng tiền mặt ($)",
      voucherExpiresIn: "Hạn sử dụng",
      month: "Tháng",
      back: "Trở Về",
      save: "Lưu",
      errOwnerNotFound:
        "Không tìm thấy chủ sở hữu. Xin vui lòng đăng nhập lại! ",
      errVoucherName: "Tên voucher là bắt buộc",
      errCoupon: "Xin vui lòng nhập phiếu giảm giá",
      errEnterDateStart: "Xin vui lòng nhập ngày bắt đầu!",
      errEnterDateEnd: "Xin vui lòng nhập ngày kết thúc",
      theStartDate: "Ngày bắt đầu phải nhỏ hơn ngày kết thúc",
      areYouWantDelete: "Bạn có chắc chắn muốn xóa?",
      cancel: "Hủy",
      delete: "Xóa",
      createVoucher: "Tạo Voucher",
      pleaseEnterExpire: "Vui lòng nhập hạn sử dụng.",
      voucherHaveSameCodeName: "Voucher Có Cùng Tên",
    },
    subscriptionPage: {
      subscription: "Subscription",
      yourSubcription: "Gói Của Bạn",
      youAreUsing: "Bạn đang dùng:",
      to: "đến",
      thisMonthYouHave: "Tháng này bạn có",
      sMSLeft: "SMS Còn lại",
      yourCard: "Thẻ của bạn",
      update: "Cập nhật",
      subcriptionRenew: "Gia hạn gói:",
      cancel: "Hủy",
      pleaseChoosePackage: "Vui lòng chọn gói:",
      month: "Tháng",
      year: "Năm",
      monthly: "THÁNG",
      yearly: "NĂM",
      twoYearly: "2 NĂM",
      basic: "CƠ BẢN",
      large: "NÂNG CAO",
      super: "CAP CẤP",
      saveTK: "Tiết kiệm",
      months: "tháng",
      whatIncluded: "Bao gồm:",
      upgrade: "Nâng cấp",
      ultimatePackageCall: "Hãy gọi chúng tôi",
      changeCard: "Thay Đổi Thẻ",
      cardNumber: "Số Card",
      expiryDate: "Ngày hết hạn",
      submit: "Gửi Lên",
      areYouSureCancel: "Bán Có Muốn Hủy?",
      heyYouWhen: "khi bạn hủy, tài khoản sẽ mất tất cả.",
      customerData: "Dữ liệu khách hàng",
      customerContact: "Thông tin khách hàng",
      theAbilityToDo: "Khả năng tự động hóa tiếp thị",
      increaseGoogleReview: "Tăng xếp hạng đánh giá của Google",
      getBackLostCustomer: "Lấy lại khách hàng đã mất",
      back: "Trở về",
      remove: "Xóa",
      upgradePackage: "Nâng Cấp Gói",
      wantToChangePackage: "Bạn có chắc chắn muốn thay đổi gói không?",
      yestIWant: "Có, Tôi muốn",
      save: "Lưu",
      current: "Đang dùng",
      pleaseEnterPasscode: "Vui lòng nhập mật mã.",
      confirm: "Xác Nhận Hủy Tài Khoản",
      enterYouPass: "Nhập mật mã để hủy tài khoản của bạn",
      close: "Đóng",
      thisYourPackage: "Đây là gói bạn đang sử dụng.",
      changePackageSuccess: "Thay đổi gói thành công",
      cancelPackageSuccess: "Hủy gói thành công",
    },
    feedback: {
      yourFeedback: "Phản hội của bạn",
      pleaseGiveUsYour:
        "Vui lòng cho chúng tôi biết ý kiến của bạn về tính năng nào bạn thích, tính năng nào bạn không thích, tính năng nào bạn mong muốn có để hỗ trợ nhà hàng của mình. mục tiêu của chúng tôi là phục vụ doanh nghiệp của bạn tốt hơn.",
      enterFeedback: "Nhập phản hồi",
      attachFile: "Đính kèm tập tin",
      dragAdnDropToUpload: "Kéo và thả hoặc nhấp để tải lên",
      descriptionLimitsText: "Mô tả/Giới hạn văn bản",
      send: "Gửi",
      history: "Lịch Sử",
      feedBack: "Phản hồi",
      ownerNotFound: "Không tìm thấy tài khoản! vui lòng đăng nhập lại.",
      thankYourFeedback: "Cám ơn phản hồi của bạn đã gửi cho chúng tôi",
      contentIsRequired: "Nội dung không được rỗng!",
    },
    offlineModePage: {
      offlineMode: "Ngoại Tuyến",
      table: "Bàn",
      order: "Hóa đơn",
      bill: "Tổng Tiền",
      staff: "Nhân viên",
      timePrint: "Thời gian",
      customer: "Khách hàng",
      submit: "Gửi lên",
    },
    printPage: {
      time: "Thời gian",
      guest: "SL khách",
      tableName: "Tên bàn",
      customer: "Khách hàng",
      phoneNumber: "Số điện thoại",
      takeAway: "Mang đi",
      diner: "Tại nhà hàng",
      freeSide: "Món ăn kèm",
      thanks: "Cám ơn",
      subTotal: "Tổng SP",
      tax: "Thuế",
      discount: "Giảm giá",
      total: "Tổng tiền",
      tipFor: "Tip cho",
      bill: "Hóa đơn",
      printDateTime: "Ngày giờ in",
      orderDetail: "Chi tiết hóa đơn",
      ConvenienceFee: "Phí thanh toán",
      report: "Báo cáo",
    },
  },
};

export const getLanguageUI = () => {
  var lg = languageUser.getLanguageUser();
  if (lg !== 1) {
    const pElement = document.querySelectorAll("p");
    const inputElement = document.querySelectorAll("input");
    const divElement = document.querySelectorAll("div");
    const textareaElement = document.querySelectorAll("textarea");
    const h3Element = document.querySelectorAll("h3");
    const h5Element = document.querySelectorAll("h5");
    const h4Element = document.querySelectorAll("h4");

    if (pElement && pElement !== null) {
      for (let index = 0; index < pElement.length; index++) {
        pElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (textareaElement && textareaElement !== null) {
      for (let index = 0; index < textareaElement.length; index++) {
        textareaElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (inputElement && inputElement !== null) {
      for (let index = 0; index < inputElement.length; index++) {
        inputElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h5Element && h5Element !== null) {
      for (let index = 0; index < h5Element.length; index++) {
        h5Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h3Element && h3Element !== null) {
      for (let index = 0; index < h3Element.length; index++) {
        h3Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (h4Element && h4Element !== null) {
      for (let index = 0; index < h4Element.length; index++) {
        h4Element[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
    if (divElement && divElement !== null) {
      for (let index = 0; index < divElement.length; index++) {
        divElement[index].style.fontFamily = "Roboto,  sans-serif";
      }
    }
  }
  if (lg === 1) {
    return Language.EN;
  } else {
    return Language.VN;
  }
};
