
import { Modal } from "react-bootstrap";
import './Styles.css'
import { useEffect, useState } from "react";
import ModalLoading from "../../components/ModalLoading";
import { DetailBuyDevice, gInfoBuyDevices } from "../../api/apiAdmin/devicePos";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
interface InforDeviceOrderProps {
  show: boolean,
  id: string,
  handleClose: () => void
}
const InforDeviceOrder = ({ show, handleClose, id }: InforDeviceOrderProps) => {
  const [ShowLoading, setShowLoading] = useState(false);
  const [detailOrder, setDetailOrder] = useState<DetailBuyDevice>();
  const languageUI = getLanguageCheckIn();
  const gInfoOrder = async () => {
    if (id) {
      setShowLoading(true)
      const req = await gInfoBuyDevices(id);
      if (Number(req?.status) === 1) {
        setDetailOrder(req?.data)
        setShowLoading(false)
      } else {
        setShowLoading(false)
      }
    }


  }
  useEffect(() => {
    if (show) {
      gInfoOrder()
    }
  }, [show])

  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-infor-device-order"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            {languageUI.OrderDeviceDetail}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-4" >
            <h4 className="pt-3 mb-0">{languageUI.Info}</h4>
          </div>
          <div className="w-100 p-4 d-flex flex-wrap">
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Contactperson}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder={languageUI.Contactperson} className="custom-input h-100" value={detailOrder?.contact_person} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Storename}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.name} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Cellphone}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.sellPhone} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Address}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.address} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.RestaurantPhone}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.restaurantPhone} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Suitofunit}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.suitShipping} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Emailaddress}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.email} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.City}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.store_city} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.Timezone}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.time_name} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.States}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.store_state} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">{languageUI.DetailAddress}:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.detail_address} />
              </div>
            </div>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">Zip Code:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.zipcode} />
              </div>
            </div>
          </div>
          <div className="px-4">
            <h4 className="my-5">{languageUI.Subscription}</h4>
            <div className="flex  w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-20 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">Package:</span>
              <div className="border-DEE2E6 w-85 overflow-hidden radius-2 ">
                <input type="text" readOnly placeholder="Contact person" className="custom-input h-100" value={detailOrder?.package} />
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </Modal>
    </>

  )
};

export default InforDeviceOrder;
