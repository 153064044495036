
import { Form } from "react-bootstrap";
import "./Styles.css"
import { pencilEdit, trashRedRemoveFloor } from "../../../../components/ImgExport";
import default_product from "../../../../assets/images/default_product.png";
import { Droppable } from "react-beautiful-dnd";
import { FormatDolla } from "../../../../utils/format";
import React from "react";
import { getLanguageUI } from "../../../../Services/languageUI";


interface SideDishGroupItemProps {
  id?: string;
  status?: boolean;
  img: string;
  foodName: string;
  className?: string;
  foodGroupName?: string;
  foodSTT: number;
  priceFood: number;
  hasFoodGroup?: boolean;
  handleDelFood?: () => void;
  handleEditFood?: () => void;
  activeFoodMenu?: (id?: any) => void;
}

const SideDishGroupItem = ({
  id = "",
  foodSTT,
  status,
  img,
  className,
  foodName,
  foodGroupName,
  hasFoodGroup = true,
  priceFood = 0,
  handleDelFood,
  handleEditFood,
  activeFoodMenu,
}: SideDishGroupItemProps) => {
  const languageUI = getLanguageUI().menuTemplate

  return (
    <>
      <div className="" >
        <div
          className={` d-flex justify-content-between align-items-center flex-wrap  mb-2 h-107px ${className}`}
          style={{ marginLeft: "20px" }}
        >
          <div className="d-flex gap-2 h-100">
            <div className="box-img-food-name">
              <div className="w-50 h-100 bg-12B064 d-flex flex-column justify-content-center align-items-center">
                <p className="mb-1 text-numberic-no">{languageUI.noNumber}</p>
                <div className="fw-bold  text-numberic">({foodSTT})</div>
              </div>
              <div className="w-50 h-100 ">
                <img src={img} alt="" className="h-100 w-100 object-fit-cover" onError={(e) => {
                  e.currentTarget.src = default_product;
                }} />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center">
              <div className="food-name-menu-items mb-1"> {foodName}</div>
              <p className="mb-0 d-flex gap-2px mb-1 price-food-name-menu">{FormatDolla(priceFood)} <p className="mb-1 text-dollar-food-menu-items">$</p></p>
              <div className="d-flex gap-1">
                <Form.Check
                  type="switch"
                  id="rating-allow-customer-point"
                  className="d-flex align-items-center checked-blue"
                  checked={status}
                  onChange={(e: any) => {
                    activeFoodMenu?.(id);
                  }}
                />
                <p className="mb-0 active-category">{languageUI.activeFood}</p>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <button className="btn-edit-delete-floor" onClick={handleDelFood} >
              <img src={trashRedRemoveFloor} alt="" />
            </button>
            <button className="btn-edit-delete-floor" onClick={handleEditFood} >
              <img src={pencilEdit} alt="" />
            </button>
          </div>
        </div>
      </div>

    </>
  );
};

export default SideDishGroupItem;
