import { MailIcon } from "../../../../components/Image";
import React, { useEffect, useState } from "react";
import ChartDonut from "./Component/ChartDonut";
import { calender, iconSearchGreen } from "../../../../components/ImgExport";
import TableReview from "./Component/TableCustomReview";
import ModalSendSMSReview from "./Component/ModalSendSMSReview";
import moment from "moment-timezone";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../components/ModalLoading";
import * as ReviewOwnerAPI from "../../../../api/apiOwner/ReviewOwner";
import * as comboApi from "../../../../api/apiOwner/comboApi";
import { CustomerSelected } from "../../../../api/apiOwner/customerApi";
import "./Styles.css";
import Select from "react-select";
import { getLanguageUI } from "../../../../Services/languageUI";

const ReviewFood: React.FC = () => {
  const languageUI = getLanguageUI().reviewPage;
  const [listDaySearch, setListDaySearch] = useState<
    { value: string; dayName: string }[]
  >([]);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalSendSMS, setShowModalSendSMS] = useState(false);
  // const [searchstart, setSearchstart] = useState<any>(new Date())//ngày bắt đầu
  // const [searchend, setSearchend] = useState<any>(new Date())//ngày kết thúc
  const [ListReview, setListReview] = useState<
    ReviewOwnerAPI.IListReviewData[]
  >([]);
  const [comboReview, setComboReview] = useState<comboApi.ComboReview[]>([]);
  const [typeId, setTypeId] = useState<number>(0);
  const [searchPhone, setSearchPhone] = useState<string>("");
  const [listCustomerSelected, setListCustomerSelected] = useState<
    CustomerSelected[]
  >([]);
  const [iscCheclAll, setIsCheckAll] = useState<boolean>(false);
  const [infoChart, setInfoChart] = useState<ReviewOwnerAPI.ChartReview[]>([]);
  const [TotalReview, setTotalReview] = useState<number>(0);
  const [comboVoucher, setComboVoucher] = useState<comboApi.ComboVoucher[]>([]);
  const [daySelected, setDaySelected] = useState<string>();
  const gComboVoucherOwner = async () => {
    if (UserIdStore) {
      const req = await comboApi.gComboVoucher(UserIdStore);
      if (Number(req?.status) === 1) {
        setComboVoucher(req?.data ?? []);
      }
    }
  };
  const dismisModalSendSMs = () => {
    setShowModalSendSMS(false);
  };

  const gComboReview = async () => {
    const req = await comboApi.gComboReviewType();
    if (Number(req?.status) === 1) {
      setComboReview(req?.data ?? []);
    }
  };
  const GetListReview = async (
    dateSearch: string = "",
    ReviewId: number = 0,
    phoneFilter: string = ""
  ) => {
    // var start = datestart !== '' ? moment(datestart).format('YYYY-MM-DD') : '';
    // var end = dateend !== '' ? moment(dateend).format('YYYY-MM-DD') : '';

    if (UserIdStore) {
      setListCustomerSelected([]);
      setShowLoading(true);
      const ListReviewGood = await ReviewOwnerAPI.ListReview(
        UserIdStore,
        dateSearch,
        ReviewId,
        phoneFilter
      );
      if (Number(ListReviewGood.status) === 1) {
        setListReview(ListReviewGood.data ?? []);
      } else {
        setListReview([]);
      }
      setShowLoading(false);
    }
  };

  const gInfoChart = async (datesearch: string = "") => {
    if (UserIdStore) {
      const req = await ReviewOwnerAPI.gInfoChartReview(
        UserIdStore,
        datesearch
      );
      if (Number(req.status) === 1) {
        setInfoChart(req.data ?? []);
        setTotalReview(req?.totalReview ? Number(req?.totalReview) : 0);
      }
    }
  };
  const handleChecked = (
    checked: boolean,
    idCustomer: string,
    phone: string,
    name: string
  ) => {
    if (
      checked &&
      !listCustomerSelected.some((item) => item.customer_id === idCustomer)
    ) {
      setListCustomerSelected([
        ...listCustomerSelected,
        { customer_id: idCustomer, phone, name },
      ]);
    } else {
      setListCustomerSelected(
        listCustomerSelected.filter((item) => item?.customer_id !== idCustomer)
      );
    }
  };
  const handleCheckedAll = (checked: boolean) => {
    if (checked) {
      var arrTmp = listCustomerSelected;
      ListReview.map((item) => {
        if (!arrTmp.some((cus) => cus?.customer_id === item?.customer_id)) {
          arrTmp.push({
            customer_id: item?.customer_id,
            phone: item?.phone,
            name: item?.username,
          });
        }
      });
      setListCustomerSelected([...arrTmp]);
    } else {
      setListCustomerSelected([]);
    }
  };

  const getIsCheckedAll = () => {
    var all = document.querySelectorAll(
      "input[name=chkBox]:not(:checked)"
    ).length;

    if (listCustomerSelected.length > 0 && all === 0) {
      setIsCheckAll(true);
    } else {
      setIsCheckAll(false);
    }
  };

  const handleOnchageDate = (e: any) => {
    if (e?.value === undefined) {
      setDaySelected("");
      GetListReview("", typeId, searchPhone);
      gInfoChart("");
      return;
    }
    setDaySelected(e?.value);
    GetListReview(e?.value, typeId, searchPhone);
    gInfoChart(e?.value);
  };
  const get30Day = () => {
    var today = new Date(
      moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
    );
    var listDay: { dayName: string; value: string }[] = [];
    for (let i = 0; i <= 30; i++) {
      var priorDate = new Date(
        new Date(
          moment().format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)")
        ).setDate(today.getDate() - i)
      );
      if (i === 0) {
        let item = {
          dayName: "To day",
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      } else if (i === 1) {
        let item = {
          dayName: "Yesterday",
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      } else {
        let item = {
          dayName: moment(priorDate).format("DD-MM-YYYY"),
          value: moment(priorDate).format("YYYY-MM-DD"),
        };
        listDay.push(item);
      }
    }
    setListDaySearch([...listDay]);
  };
  useEffect(() => {
    getIsCheckedAll();
  }, [listCustomerSelected]);

  useEffect(() => {
    GetListReview();
    gInfoChart();
    gComboReview();
    gComboVoucherOwner();
    get30Day();
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      {/* <div className="">
                <Topbar pageName="Review" />
            </div> */}
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex  ml-12">
            <div className="w-100 flex items-center py-3">
              <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                {languageUI.filter}
              </span>
              {/* <div className="d-flex align-items-center border px-2 rounded-8px h-42px mr-6">
								<img src={calender} alt="" />
								<div className="w-100">
									<div className="form-group">
										<DateRangePicker
											initialSettings={{
												// startDate: new Date(),
												showDropdowns: true,
												autoUpdateInput: false,
												locale: {
													cancelLabel: 'Reset',
													applyLabel: "Apply"
												}
											}}
											onApply={handleApply}
											onCancel={handleCancel}
										>
											<input type="text" className="form-control input-daterange-timepicker border-none w-195px" placeholder="Today" value={searchstart != "" && searchend != "" ? (moment(searchstart).format('DD/MM/YYYY') + "-" + moment(searchend).format('DD/MM/YYYY')) : ""} readOnly={true} />
										</DateRangePicker>
									</div>
								</div>
							</div> */}
              <div className="d-flex align-items-center border  px-2  rounded-8px h-42px">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.today}
                  isClearable={true}
                  options={listDaySearch.map((item) => {
                    return {
                      value: item.value,
                      label: item.dayName,
                    };
                  })}
                  onChange={(e: any) => handleOnchageDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 d-flex px-4 h-fit">
        <div className="bg-white radius-18px p-6 w-350px h-100 flex-shink-0">
          <ChartDonut infoChart={infoChart} TotalReview={TotalReview} />
        </div>
        <div className="bg-white radius-18px ml-12 w-100">
          <div className="custom-radius-table  bg-white px-4 custom-flex-header border-bottom container-fluid ">
            <div className=" py-2  custom-header-left">
              <div className="d-flex gap-3">
                <p className="text-5 text-212134 mb-0 d-flex align-items-center">
                  {languageUI.customerReview}
                </p>
                {comboReview.map((item, i) => (
                  <div
                    className={`d-flex align-items-center  p-3  rounded-8px ${
                      typeId === Number(item?.id) ? "border-0FA54A" : "border"
                    }`}
                    onClick={() => {
                      if (typeId === Number(item?.id)) {
                        setTypeId(0);
                        GetListReview(daySelected);
                      } else {
                        setTypeId(Number(item?.id));
                        GetListReview(daySelected, Number(item?.id));
                      }
                    }}
                    key={`review${i}`}
                  >
                    <span className="text-#a5a5ba font-urbansist text-18px">
                      {item?.icon} {item?.name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <div className="flex items-center border rounded-5 h-40px px-2">
                <img src={iconSearchGreen} alt="" className="pr-2" />
                <input
                  type="number"
                  value={searchPhone}
                  onChange={(e) => setSearchPhone(e.target.value)}
                  className="focus-visible-none input-find-by-phone-employee w-fit text-4A4A6A"
                  placeholder={languageUI.findByPhone}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      GetListReview(daySelected, typeId, searchPhone);
                    }
                  }}
                />
              </div>
              <button
                className="flex items-center border-none text-F8F9FA p-send-sms bg-0FA54A text-17px fw-500 font-urbansist radius-2 flex-shink-0"
                onClick={() => setShowModalSendSMS(true)}
                disabled={listCustomerSelected.length === 0}
              >
                <MailIcon className="mr-1" />
                {languageUI.sendSMSToAll}
              </button>
            </div>
          </div>
          <TableReview
            ListReview={ListReview}
            listCustomerSelected={listCustomerSelected}
            comboVoucher={comboVoucher}
            handleCheckedAll={handleCheckedAll}
            handleChecked={handleChecked}
            isCheckAll={iscCheclAll}
          />
          {/* <div className="flex justify-center py-2">
                        <div className="flex items-center">
                            <span className='text-17px font-urbansist text-4A4A6A'>Total {page} pages</span>
                            <Stack spacing={2}>
                                <Pagination
                                    count={11} defaultPage={1} shape="rounded" onChange={handleChange} />
                            </Stack>

                        </div>

                    </div> */}
        </div>
      </div>
      <ModalSendSMSReview
        onHide={dismisModalSendSMs}
        show={showModalSendSMS}
        listCustomerSelected={listCustomerSelected}
        comboVoucher={comboVoucher}
      />
    </>
  );
};

export default ReviewFood;
