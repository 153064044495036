import { Form, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../Styles.css"
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import React from "react";
import { calender, searchGreen } from "../../../../../components/ImgExport";
import Select from "react-select";
import { ComboIngredient } from "../../../../../api/apiOwner/comboApi";
import { ExportMaterial, gInfoMaterial } from "../../../../../api/apiOwner/InventoryApi";
import { getLanguageUI } from "../../../../../Services/languageUI";
// import ModalDatePicker from "react-mobile-datepicker-ts";



interface ModalExportInventoryProps {
    show: boolean;
    UserIdStore?: number | null;
    comboIngredient: ComboIngredient[];
    setShowLoading: (e: boolean) => void;
    handleClose: () => void;
    handleSuccess: (mess: string) => void;
}

const ModalExportInventory = ({
    show,
    UserIdStore = 0,
    setShowLoading,
    comboIngredient = [],
    handleClose,
    handleSuccess
}: ModalExportInventoryProps) => {
    const languageUI = getLanguageUI().inventoryPage
    const [startDate, setStartDate] = useState<any>(new Date());
    const [ingredient_id, setIngredient_id] = useState('')
    const [quantity, setQuantity] = useState<string | number>('')
    const [exporter, setexporter] = useState<string>('')
    const [unitName, setUnitName] = useState('')
    const [Inventory_stock, setInventory_stock] = useState<number>(0)
    const [messageError, setMessageError] = useState<string>('')
    const ResetForm = () => {
        setStartDate(new Date());
        setQuantity('')

        setexporter('')

        setIngredient_id('')
        setUnitName('')
        setInventory_stock(0)
        setMessageError('')
    }
    const handleOnchageMaterial = async (material_id: string) => {
        if (!material_id) {
            setUnitName('')
            setInventory_stock(0)
            setIngredient_id('')
            return;
        }
        setIngredient_id(material_id)
        const req = await gInfoMaterial(material_id);
        if (Number(req?.status) === 1) {
            setUnitName(req?.data?.unit_name ?? '');
            setInventory_stock(req?.data?.Inventory_stock ? Number(req?.data?.Inventory_stock) : 0);
            if (Number(quantity) > Number(req?.data?.Inventory_stock)) {
                setQuantity(0)
            }
        }
    }
    const SubmitExport = async () => {
        if (UserIdStore) {
            if (ingredient_id === '') {
                setMessageError(languageUI.pleaseEnterMaterial);
            } else if (!startDate || startDate === '') {
                setMessageError(languageUI.pleaseEnterDate)
            } else if (quantity === '' || Number(quantity) <= 0) {
                setMessageError(languageUI.pleaseEnterImportNumber)
            } else if (exporter === '') {
                setMessageError(languageUI.pleaseEnterImportNumber)
            } else {
                setMessageError('')
                var dateAdd = moment.utc(startDate).format("YYYY-MM-DD");
                // console.log({ ingredient_id, startDate: moment(startDate).format("YYYY-MM-DD"), quantity, totalPrice, importer, reminder_option, tableNumberDay, tableNumberUnit });
                setShowLoading(true);
                const req = await ExportMaterial(UserIdStore, ingredient_id, dateAdd, Number(quantity), exporter)
                if (Number(req?.status) === 1) {
                    setShowLoading(false);
                    handleSuccess("Successful raw material export.")
                } else {
                    setShowLoading(false);
                    setMessageError(req?.message ?? "Export of raw materials failed");
                }
            }
        } else {
            setMessageError('Owner not found');
        }
    }
    useEffect(() => {
        if (!show) {
            ResetForm();
        }
    }, [show])
    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer modal-import-inventory">
                <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
                    <Modal.Body>
                        <p className="text-delete-modal-customer mb-0 text-center">{languageUI.exportMaterial}</p>
                        <div className=" mt-3 mb-1">
                            <div className="w-100">
                                <p className="text-add-table-name mb-1">{languageUI.materialName}</p>
                                <div className="input-add-table ps-2 pe-1">
                                    <img src={searchGreen} alt="" />
                                    <Select
                                        className="react-select react-select-container w-100 select-unit-new-material border-select-none"
                                        classNamePrefix="react-select"
                                        defaultValue={[""]}
                                        placeholder={languageUI.searchAndSelect}
                                        isClearable={true}
                                        options={comboIngredient.map((item) => {
                                            return {
                                                value: item.id,
                                                label: item.ingredient_name,
                                            };
                                        })}
                                        onChange={(e: any) => handleOnchageMaterial(e?.value)}
                                    />
                                </div>
                            </div>
                            <div className="w-100 mt-2">
                                <p className="text-add-table-name mb-1">{languageUI.ExportDate}</p>
                                <div className="input-add-table ps-2 pe-1 ">
                                    <img src={calender} alt="" className="me-1" />
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="To day" className="border-none focus-visiable-none  text-input-add-table" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2 mt-6">
                            <div>
                                <p className="text-add-table-name mb-1">{languageUI.total}</p>
                                {/* <div className="input-add-table bg-fafafa">
                                    <input type="number" disabled={true} className="border-none focus-visiable-none text-input-add-table px-2 w-100 " placeholder="00" />
                                </div> */}
                                <InputGroup className="h-42px rounded-8px opacity-07">
                                    <Form.Control
                                        placeholder="00"
                                        aria-describedby="basic-addon2"
                                        className="text-input-usd"
                                        type="number"
                                        value={Inventory_stock}
                                        disabled
                                    />
                                    <InputGroup.Text id="basic-addon2" className="text-input-usd">{unitName}</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <p className="mb-0 mt-30px text-function-inventory">-</p>
                            <div>
                                <p className="text-add-table-name mb-1">{languageUI.exportNumber}</p>
                                {/* <div className="input-add-table">
                                    <input type="number" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder="Material Number" />
                                </div> */}
                                <InputGroup className="h-42px rounded-8px ">
                                    <Form.Control
                                        placeholder={languageUI.total}
                                        aria-describedby="basic-addon2"
                                        className="text-input-usd"
                                        type="number"
                                        value={quantity}
                                        onChange={(e) => {
                                            if (e.target.value && (Number(e.target.value) < 0 || Number(e.target.value) > Inventory_stock)) {
                                                return;
                                            }
                                            setQuantity(e.target.value)
                                        }}
                                    />
                                    <InputGroup.Text id="basic-addon2" className="text-input-usd">{unitName}</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <p className="mb-0 mt-30px text-function-inventory">=</p>
                            <div>
                                <p className="text-add-table-name mb-1 text-ff7b2c">{languageUI.totalLeftInStock}</p>
                                <InputGroup className="h-42px rounded-8px opacity-07">
                                    <Form.Control
                                        placeholder="00"
                                        aria-describedby="basic-addon2"
                                        className="text-input-usd"
                                        type="number"
                                        value={Inventory_stock - Number(quantity)}
                                        disabled
                                    />
                                    <InputGroup.Text id="basic-addon2" className="text-input-usd">{unitName}</InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="mt-6">
                            <p className="text-add-table-name mb-1">{languageUI.exporter}</p>
                            <div className="input-add-table">
                                <input type="text" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder={languageUI.enterName} value={exporter} onChange={e => setexporter(e.target.value)} />
                            </div>
                        </div>
                        <div className="text-danger text-center">{messageError}</div>
                        <div className="d-flex justify-content-between w-100 mt-6">
                            <div className="btn-import-inventory-footer" onClick={handleClose}>{languageUI.back}</div>
                            <div className="btn-import-inventory-footer border-12B064 text-white bg-12B064" onClick={SubmitExport}>{languageUI.save}</div>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalExportInventory;
