interface Records {
    namePackage: string;
    type: string;
    cash: number;
    sms: number;
    action: string;
}

const records: Records[] = [
    {
        namePackage: "Basic",
        type: "Monthly",
        cash: 5,
        sms:  48000,
        action: '',
    },
    {
        namePackage: "Basic",
        type: "Yearly",
        cash: 6,
        sms:  96000,
        action: '',
    },
    {
        namePackage: "Basic",
        type: "Every 5 years",
        cash: 4,
        sms:  5000,
        action: '',
    },
    {
        namePackage: "Large",
        type: "Monthly",
        cash: 1,
        sms:  2000,
        action: '',
    },
    {
        namePackage: "Large",
        type: "Yearly",
        cash: 2,
        sms:  4000,
        action: '',
    },
    {
        namePackage: "Large",
        type: "Every 5 years",
        cash: 3,
        sms:  8000,
        action: '',
    },
    {
        namePackage: "Supper",
        type: "Monthly",
        cash: 1,
        sms:  2000,
        action: '',
    },
    {
        namePackage: "Supper",
        type: "Yearly",
        cash: 2,
        sms:  4000,
        action: '',
    },
    {
        namePackage: "Supper",
        type: "Every 5 years",
        cash: 3,
        sms:  8000,
        action: '',
    }
];

export { records };
