/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Nav,
  Tab,
  Dropdown,
  Button,
  Form,
  Modal,
} from "react-bootstrap";
import HyperDatepicker from "../../components/Datepicker";
// components
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import { CellValue } from "react-table";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as CustomerTypeAPI from "../../api/apiOwner/CustomerType";
import * as CustomerOverviewAPI from "../../api/apiOwner/CustomerOverview";

import Swal from "sweetalert2";
import moment from "moment-timezone";

const CustomerType = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const UserIdStore = useSelector(userSlice.selectorUserId);

  const [ListTotalCustomer, setListTotalCustomer] = useState<
    Array<CustomerTypeAPI.IAllCustomerData>
  >([]);
  const [ListCustomerDontComeBack, setListCustomerDontComeBack] = useState<
    Array<any>
  >([]);
  const [ListCustomerRegular, setListCustomerRegular] = useState<Array<any>>(
    []
  );
  const [ListMoreThanTwoTimes, setListMoreThanTwoTimes] = useState<Array<any>>(
    []
  );
  const [ListCustomerGoodReview, setListCustomerGoodReview] = useState<
    Array<any>
  >([]);
  const [ListCustomerNeutralReview, setListCustomerNeutralReview] = useState<
    Array<any>
  >([]);
  const [ListCustomerBadReview, setListCustomerBadReview] = useState<
    Array<any>
  >([]);
  const [ListCustomerNotReview, setListCustomerNotReview] = useState<
    Array<any>
  >([]);

  const [MessageSuccess, setMessageSuccess] = useState<any>("");
  const [Message, setMessage] = useState<any>("");
  const [MessageSendAll, setMessageSendAll] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);

  const newday = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
  const dateNow = newday.getDate();
  const monthNow =
    Number(newday.getMonth()) + 1 < 10
      ? "0" + (Number(newday.getMonth()) + 1)
      : newday.getMonth() + 1;
  const yearNow = newday.getFullYear();
  const startDateFormat = useRef<any>(yearNow + "-" + monthNow + "-01");
  const endDateFormat = useRef<any>(yearNow + "-" + monthNow + "-" + dateNow);
  const idCustomerSendMessageRef = useRef<number>(0);
  const typeRef = useRef<number>(0);

  const [TotalCustomer, setTotalCustomer] = useState<any>("");
  const [TotalNewCustomer, setTotalNewCustomer] = useState<any>("");
  const [TotalReturnCustomer, setTotalReturnCustomer] = useState<any>("");

  //modal send sms
  const [MessageSend, setMessageSend] = useState<any>("");
  const [checkErrorSendOneCustomer, setCheckErrorSendOneCustomer] =
    useState(false);
  const [messErrorSendOneCustomer, setmessErrorSendOneCustomer] = useState("");

  //
  const [activeTab, setActiveTab] = useState("totalCustomer");
  const [overflowDropdown, setOverflowDropdown] = useState(false);
  const [modalSendMessage, setModalSendMessage] = useState(false);
  const [modalSendAllMessage, setModalSendAllMessage] = useState(false);
  // Datepicker
  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(new Date());
  const onDateChangeStart = (date: Date) => {
    if (date) {
      setSelectedStartDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      startDateFormat.current = year + "-" + month + "-01";
      GetListTotalCustomer();
      GetListCustomerDontComeBack();
      GetListCustomerRegular();
      GetListCustomerGoodReview();
      GetListCustomerNeutralReview();
      GetListCustomerBadReview();
      GetListCustomerNotReview();
      GetListMoreThanTwoTimes();
      GetCustomerOverview();
    }
  };
  const onDateChangeEnd = (date: Date) => {
    if (date) {
      setSelectedEndDate(date);
      var month =
        Number(date.getMonth()) + 1 < 10
          ? "0" + (Number(date.getMonth()) + 1)
          : date.getMonth() + 1;
      var year = date.getFullYear();
      var lastDayOfMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).getDate();

      endDateFormat.current = year + "-" + month + "-" + lastDayOfMonth;
      GetListTotalCustomer();
      GetListCustomerDontComeBack();
      GetListCustomerRegular();
      GetListCustomerGoodReview();
      GetListCustomerNeutralReview();
      GetListCustomerBadReview();
      GetListCustomerNotReview();
      GetListMoreThanTwoTimes();
      GetCustomerOverview();
    }
  };

  const GetListTotalCustomer = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomer = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        1
      );
      if (Number(AllCustomer.status) === 1) {
        // console.log(AllCustomer);
        setListTotalCustomer(AllCustomer.data ? AllCustomer.data : []);
      } else {
        setListTotalCustomer([]);
      }
      setShowLoading(false);
    }
  };

  const GetListCustomerDontComeBack = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerDontComeBack = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        2
      );
      if (Number(AllCustomerDontComeBack.status) === 1) {
        // console.log(AllCustomerDontComeBack);
        setListCustomerDontComeBack(
          AllCustomerDontComeBack.data ? AllCustomerDontComeBack.data : []
        );
      } else {
        setListCustomerDontComeBack([]);
      }
      setShowLoading(false);
    }
  };

  const GetListCustomerRegular = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerRegular = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        3
      );
      if (Number(AllCustomerRegular.status) === 1) {
        // console.log(AllCustomerRegular);
        setListCustomerRegular(
          AllCustomerRegular.data ? AllCustomerRegular.data : []
        );
      } else {
        setListCustomerRegular([]);
      }
      setShowLoading(false);
    }
  };

  const GetListMoreThanTwoTimes = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerMoreThanTwoTimes = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        8
      );
      if (Number(AllCustomerMoreThanTwoTimes.status) === 1) {
        setListMoreThanTwoTimes(
          AllCustomerMoreThanTwoTimes.data
            ? AllCustomerMoreThanTwoTimes.data
            : []
        );
      } else {
        setListMoreThanTwoTimes([]);
      }
      setShowLoading(false);
    }
  };
  const GetListCustomerGoodReview = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerGoodReview = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        4
      );
      if (Number(AllCustomerGoodReview.status) === 1) {
        // console.log(AllCustomerGoodReview);
        setListCustomerGoodReview(
          AllCustomerGoodReview.data ? AllCustomerGoodReview.data : []
        );
      } else {
        setListCustomerGoodReview([]);
      }
      setShowLoading(false);
    }
  };

  const GetListCustomerNeutralReview = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerNeutralReview = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        5
      );
      if (Number(AllCustomerNeutralReview.status) === 1) {
        // console.log(AllCustomerNeutralReview);
        setListCustomerNeutralReview(
          AllCustomerNeutralReview.data ? AllCustomerNeutralReview.data : []
        );
      } else {
        setListCustomerNeutralReview([]);
      }
      setShowLoading(false);
    }
  };

  const GetListCustomerBadReview = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerBadReview = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        6
      );
      if (Number(AllCustomerBadReview.status) === 1) {
        // console.log(AllCustomerBadReview);
        setListCustomerBadReview(
          AllCustomerBadReview.data ? AllCustomerBadReview.data : []
        );
      } else {
        setListCustomerBadReview([]);
      }
      setShowLoading(false);
    }
  };

  const GetListCustomerNotReview = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const AllCustomerNotReview = await CustomerTypeAPI.AllCustomer(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        7
      );
      if (Number(AllCustomerNotReview.status) === 1) {
        // console.log(AllCustomerNotReview);
        setListCustomerNotReview(
          AllCustomerNotReview.data ? AllCustomerNotReview.data : []
        );
      } else {
        setListCustomerNotReview([]);
      }
      setShowLoading(false);
    }
  };

  const ReloadFormMessage = () => {
    setMessage("");
    setMessageSuccess("");
    setMessageSend("");
    idCustomerSendMessageRef.current = 0;
    setCheckErrorSendOneCustomer(false);
    setmessErrorSendOneCustomer("");
    setMessageSendAll("");
  };

  const sendMessage = async () => {
    if (MessageSend === "") {
      setCheckErrorSendOneCustomer(true);
      setmessErrorSendOneCustomer("Please enter message");
      return;
    }
    if (UserIdStore) {
      setShowLoading(true);
      const SendSMS = await CustomerTypeAPI.SendSMS(
        UserIdStore,
        idCustomerSendMessageRef.current,
        MessageSend
      );
      if (Number(SendSMS.status) === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: SendSMS.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: SendSMS.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setShowLoading(false);
      setCheckErrorSendOneCustomer(false);
      setModalSendMessage(false);
      setmessErrorSendOneCustomer("");
      setMessageSend("");
    }
  };

  const sendAllMessage = async () => {
    if (MessageSendAll === "") {
      setCheckErrorSendOneCustomer(true);
      setmessErrorSendOneCustomer("Please enter message");
      return;
    }
    if (MessageSendAll.length >= 160) {
      setCheckErrorSendOneCustomer(true);
      setmessErrorSendOneCustomer(
        "Messages cannot exceed 160 characters. Please re-enter messages"
      );
      return;
    }
    if (activeTab === "totalCustomer") {
      typeRef.current = 1;
    } else if (activeTab === "longTimeCustomer") {
      typeRef.current = 2;
    } else if (activeTab === "regularCustomer") {
      typeRef.current = 3;
    } else if (activeTab === "goodReview") {
      typeRef.current = 4;
    } else if (activeTab === "neuralReview") {
      typeRef.current = 5;
    } else if (activeTab === "badReview") {
      typeRef.current = 6;
    } else if (activeTab === "notReview") {
      typeRef.current = 7;
    } else if (activeTab === "morethantwotimes") {
      typeRef.current = 8;
    }

    if (UserIdStore) {
      setShowLoading(true);
      const SendAllSMS = await CustomerTypeAPI.SendAllSMS(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        typeRef.current,
        MessageSendAll
      );
      if (Number(SendAllSMS.status) === 1) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `Sent message success: ${SendAllSMS?.success.length} & error: ${SendAllSMS?.fail?.length}`,
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong. Please try again later!",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setShowLoading(false);
      setCheckErrorSendOneCustomer(false);
      setModalSendAllMessage(false);
      setmessErrorSendOneCustomer("");
      setMessageSendAll("");
    }
  };

  const GetCustomerOverview = async () => {
    if (UserIdStore) {
      // CustomerOverview
      const CustomerOverview = await CustomerOverviewAPI.CustomerOverview(
        UserIdStore,
        startDateFormat.current,
        endDateFormat.current,
        1
      );
      if (Number(CustomerOverview.status) === 1) {
        setTotalCustomer(CustomerOverview?.data?.total_customer);
        setTotalNewCustomer(
          CustomerOverview?.data?.total_number_of_new_customer
        );
        setTotalReturnCustomer(
          CustomerOverview?.data?.total_number_of_return_customer
        );
      } else {
        setTotalCustomer(0);
        setTotalNewCustomer(0);
        setTotalReturnCustomer(0);
      }
      setShowLoading(false);
    }
  };

  // Datatable setting
  // Datatable config
  const columns = [
    {
      Header: "#",
      accessor: "index",
      sort: false,
      Cell: (cell: any) => (
        <>
          <div>{Number(cell.row.index) + 1}</div>
        </>
      ),
    },
    {
      Header: "Last visit",
      accessor: "lastVisit",
      sort: false,
    },
    {
      Header: "Point",
      accessor: "point",
      sort: false,
    },
    {
      Header: "Name",
      accessor: "name",
      sort: false,
    },
    {
      Header: "Phone",
      accessor: "phone",
      sort: false,
    },
    {
      Header: "Action",
      accessor: "action",
      sort: false,
      Cell: (cell: CellValue) => {
        return (
          <>
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                className="p-0 rounded-circle square-btn d-flex"
              >
                <i className="fe-more-horizontal m-auto"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  href="#"
                  onClick={() => {
                    setModalSendMessage(true);
                    setMessageSend("");
                    idCustomerSendMessageRef.current =
                      cell?.row?.original?.id_customer;
                  }}
                >
                  Send SMS
                </Dropdown.Item>
                {/* <Dropdown.Item href="review">Get review from this user</Dropdown.Item> */}
                <Link to="/web-owner/review" className="dropdown-item">
                  <div>Get review from this user</div>
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const sizePerPageList = [
    {
      text: "5",
      value: 5,
    },
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "100",
      value: 100,
    },
  ];

  useEffect(() => {
    if (UserIdStore) {
      setShowLoading(false);
      GetListTotalCustomer();
      GetListCustomerDontComeBack();
      GetListCustomerRegular();
      GetListCustomerGoodReview();
      GetListCustomerNeutralReview();
      GetListCustomerBadReview();
      GetListCustomerNotReview();
      GetListMoreThanTwoTimes();
      GetCustomerOverview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, UserIdStore]);


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Customer", path: "#" },
          { label: "Customer Type", path: "#", active: true },
        ]}
        title={"Customer Type"}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal
        show={modalSendMessage}
        onHide={() => {
          setModalSendMessage(false);
          ReloadFormMessage();
        }}
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder="Messages can only contain 160 characters"
              rows={3}
              value={MessageSend}
              onChange={(e) => {
                setMessageSend(e.target.value);
              }}
            />
            <p style={{ color: "#f1556c", textAlign: "center" }}>
              {checkErrorSendOneCustomer ? messErrorSendOneCustomer : ""}
            </p>{" "}
          </Form.Group>
          <label htmlFor="messLength">Number of message characters: </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={MessageSend.length}
            readOnly
          />
        </Modal.Body>
        {/* <div className="text-center text-success">{MessageSuccess}</div>
        <div className="text-center text-danger">{Message}</div> */}
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="primary" onClick={sendMessage}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalSendAllMessage}
        onHide={() => {
          setModalSendAllMessage(false);
          ReloadFormMessage();
        }}
      >
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              placeholder="Messages can only contain 160 characters"
              rows={3}
              value={MessageSendAll}
              onChange={(e) => {
                setMessageSendAll(e.target.value);
              }}
            />
            <p style={{ color: "#f1556c", textAlign: "center" }}>
              {checkErrorSendOneCustomer ? messErrorSendOneCustomer : ""}
            </p>{" "}
          </Form.Group>
          <label htmlFor="messLength">Number of message characters: </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={MessageSendAll.length}
            readOnly
          />
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="primary" onClick={sendAllMessage}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="py-3 px-2 rounded-3 mb-3 bg-white shadow">
        <Row className="align-items-end mb-4">
          <Col xs={12} sm={12} md={5} lg={5} xl={3}>
            <label className="form-label">Date start</label> <br />
            <HyperDatepicker
              hideAddon={true}
              value={selectedStartDate}
              dateFormat="MM/yyyy"
              onChange={(date) => {
                onDateChangeStart(date);
              }}
              showMonthYearPicker
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={1}>
            <div className="text-center form-control border-0">to</div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={5} xl={3}>
            <label className="form-label">Date finish</label> <br />
            <HyperDatepicker
              hideAddon={true}
              value={selectedEndDate}
              dateFormat="MM/yyyy"
              onChange={(date) => {
                onDateChangeEnd(date);
              }}
              showMonthYearPicker
            />
          </Col>
        </Row>

        <Row>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="mb-3 mb-sm-3 mb-md-0 mb-lg-0"
          >
            <div className="bg-primary px-3 py-4 rounded-4 text-white h-100 shadow position-relative overflow-hidden customer-overview-info">
              <div className="circle-deco position-absolute bg-white-gradient rounded-circle"></div>
              <h4 className="header-title text-center text-white">
                Total customer
              </h4>
              <h2 className="fw-bold text-center text-white mb-0 mt-3">
                {TotalCustomer}
              </h2>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="mb-3 mb-sm-3 mb-md-0 mb-lg-0"
          >
            <div className="bg-danger px-3 py-4 rounded-4 text-white h-100 shadow position-relative overflow-hidden customer-overview-info">
              <div className="circle-deco position-absolute bg-white-gradient rounded-circle"></div>
              <h4 className="header-title text-center text-white">
                Total Number Of New Customer
              </h4>
              <h2 className="fw-bold text-center text-white mb-0 mt-3">
                {TotalNewCustomer}
              </h2>
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="mb-3 mb-sm-3 mb-md-0 mb-lg-0"
          >
            <div className="bg-info px-3 py-4 rounded-4 text-white h-100 shadow position-relative overflow-hidden customer-overview-info">
              <div className="circle-deco position-absolute bg-white-gradient rounded-circle"></div>
              <h4 className="header-title text-center text-white">
                Total Number Of Return Customer
              </h4>
              <p className="text-center"> (2 times in 6 months)</p>
              <h2 className="fw-bold text-center text-white mb-0 mt-3">
                {TotalReturnCustomer}
              </h2>
            </div>
          </Col>
        </Row>

        <Tab.Container
          defaultActiveKey={activeTab}
          onSelect={(tab: any) => setActiveTab(tab)}
        >
          <Row className="mb-3">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <Nav
                  as="ul"
                  variant="pills"
                  className="navtab-bg customer-type-nav"
                >
                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0 px-1 ms-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="totalCustomer"
                    >
                      Total customer ({ListTotalCustomer.length})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="longTimeCustomer"
                    >
                      Long time no see ({ListCustomerDontComeBack.length})
                      <br />
                      (3 month no comeback)
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="regularCustomer"
                    >
                      Regular ({ListCustomerRegular.length})
                      <br />
                      (2 times in 6 month)
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="morethantwotimes"
                    >
                      More than two times ({ListMoreThanTwoTimes.length})
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="goodReview"
                    >
                      Good review ({ListCustomerGoodReview.length})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="neuralReview"
                    >
                      Neutral review ({ListCustomerNeutralReview.length})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="badReview"
                    >
                      Bad review ({ListCustomerBadReview.length})
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link
                      className="cursor-pointer text-center h-100 d-flex align-items-center lh-1 px-1 py-0 mt-2 me-0"
                      style={{
                        paddingTop: "0.25rem",
                        paddingBottom: "0.25rem",
                      }}
                      eventKey="notReview"
                    >
                      Haven't give review yet ({ListCustomerNotReview.length})
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
          </Row>

          <Tab.Content className="pt-0">
            <Tab.Pane eventKey="totalCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListTotalCustomer}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="longTimeCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListCustomerDontComeBack}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="regularCustomer">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListCustomerRegular}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="morethantwotimes">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListMoreThanTwoTimes}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="goodReview">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListCustomerGoodReview}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="neuralReview">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListCustomerNeutralReview}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="badReview">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListCustomerBadReview}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="notReview">
              <div>
                <div className="text-end">
                  <Button
                    onClick={() => setModalSendAllMessage(true)}
                    variant="outline-primary"
                  >
                    <i className="fe-send me-2"></i>Send SMS to All On This
                    Group
                  </Button>
                </div>
                <Table
                  overflowDropdown={overflowDropdown}
                  columns={columns}
                  data={ListCustomerNotReview}
                  pageSize={5}
                  sizePerPageList={sizePerPageList}
                  isSortable={true}
                  pagination={true}
                  isSearchable={true}
                // isSelectable={true}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default CustomerType;
