import {
  arrowLeftPage,
  arrowRightPage,
  calender,
  iconSearchGreen,
} from "../../../../components/ImgExport";
import Select from "react-select";
import { MailIcon, selected } from "../../../../components/Image";
import ModalSend from "./Component/Modal/ModalSendSMS";
import React, { useEffect, useState } from "react";
import "./Styles.css";
import ContentTableCustomer from "./Component/ContentTableCustomer";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment-timezone";
import * as customerApi from "../../../../api/apiOwner/customerApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../components/ModalLoading";
import Select2 from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as comboApi from "../../../../api/apiOwner/comboApi";
import { getLanguageUI } from "../../../../Services/languageUI";

interface CustomerSelected {
  customer_id: string;
  phone: string;
  name: string;
}

const Customer: React.FC = () => {
  const languageUI = getLanguageUI().customerPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showModalSendSMS, setModalSendSMS] = React.useState<boolean>(false);
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRow, setTotalRow] = useState<number>(1);
  const [pageGoTo, setPageGoTo] = useState<number>();
  const [limitRow, setLimitRow] = useState<number>(50);
  const [searchstart, setSearchstart] = useState<any>(""); //ngày bắt đầu
  const [searchend, setSearchend] = useState<any>(""); //ngày kết thúc
  const [listCustomer, setListCustomer] = useState<customerApi.InfoCustomer[]>(
    []
  );
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [type, setType] = useState<number>(1);
  const [comboMonth, setComboMonth] = useState<comboApi.ComboOption[]>([]);
  const [comboReview, setComboReview] = useState<comboApi.ComboOption[]>([]);
  const [comboJourney, setComboJourney] = useState<comboApi.ComboOption[]>([]);
  const [monthCheck, setMonthCheck] = useState<number>(0);
  const [id_review, setId_review] = useState<number>(0);
  const [searchPhone, setSearchPhone] = useState<string>("");
  const [listCustomerSelected, setListCustomerSelected] = useState<
    CustomerSelected[]
  >([]);
  const [comboVoucher, setComboVoucher] = useState<comboApi.ComboVoucher[]>([]);

  const optionPage = [
    {
      value: 50,
      page: ` ${languageUI.show} 50 / ${languageUI.page}`,
    },
    {
      value: 100,
      page: ` ${languageUI.show} 100 / ${languageUI.page}`,
    },
    {
      value: 0,
      page: "Show all",
    },
  ];

  const gComboVoucherOwner = async () => {
    if (UserIdStore) {
      const req = await comboApi.gComboVoucher(UserIdStore);
      if (Number(req?.status) === 1) {
        setComboVoucher(req?.data ?? []);
      }
    }
  };
  const handleApply = (event: any, picker: any) => {
    var start = picker.startDate.format("YYYY-MM-DD");
    var end = picker.endDate.format("YYYY-MM-DD");
    setSearchstart?.(start);
    setSearchend?.(end);
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
        " - " +
        picker.endDate.format("DD/MM/YYYY")
    );

    setType(1);
    setMonthCheck(0);
    setPageCurrent(1);
    gOptionReview(1, start, end, monthCheck);
    if (pageCurrent === 1) {
      gListAllCustomer(1, 1, limitRow, start, end, 0, id_review);
    }
  };
  const handleCancel = (event: any, picker: any) => {
    picker.element.val("");
    setSearchstart?.("");
    setSearchend?.("");
    setType(1);
    setMonthCheck(0);
    setPageCurrent(1);
    gOptionReview(1, "", "", monthCheck);
    if (pageCurrent === 1) {
      gListAllCustomer(1, 1, limitRow, "", "", 0, id_review);
    }
  };

  const gOptionMonth = async () => {
    if (UserIdStore) {
      const reqCombo = await comboApi.gComboMonthLongSeebyOwner(UserIdStore);
      if (Number(reqCombo?.status) === 1) {
        setComboMonth(reqCombo?.data ?? []);
      }
    }
  };
  const gOptionReview = async (
    typecheck: number,
    datestart: string = "",
    dateend: string = "",
    month: number = 0
  ) => {
    if (UserIdStore) {
      const reqCombo = await comboApi.gComboReviewReport(
        UserIdStore,
        typecheck,
        month,
        datestart,
        dateend
      );
      if (Number(reqCombo?.status) === 1) {
        setComboReview(reqCombo?.data ?? []);
      }
    }
  };
  const gOptionJourney = async () => {
    if (UserIdStore) {
      const reqCombo = await comboApi.gComboJourney(UserIdStore);
      if (Number(reqCombo?.status) === 1) {
        setComboJourney(reqCombo?.data ?? []);
      }
    }
  };
  const handleSearchMonth = async (month: number) => {
    setSearchstart("");
    setSearchend("");
    if (month > 0) {
      setType(2);
      setMonthCheck(month);
      gOptionReview(2, searchstart, searchend, month);
    } else {
      setMonthCheck(0);
      setType(1);
      gOptionReview(1, searchstart, searchend, 0);
    }
    setPageCurrent(1);
    if (pageCurrent === 1) {
      gListAllCustomer(
        month > 0 ? 2 : 1,
        1,
        limitRow,
        "",
        "",
        month,
        id_review
      );
    }
  };
  const handleSearchReview = async (idReview: number) => {
    if (idReview > 0) {
      setId_review(idReview);
    } else {
      setId_review(0);
    }
    setPageCurrent(1);
    if (pageCurrent === 1) {
      gListAllCustomer(
        type,
        1,
        limitRow,
        searchstart,
        searchend,
        monthCheck,
        idReview
      );
    }
  };
  const handleSearchJourney = async (idjour: number) => {
    setSearchstart("");
    setSearchend("");
    setMonthCheck(0);
    if (idjour > 0) {
      setType(idjour);
      gOptionReview(idjour, searchstart, searchend, 0);
    } else {
      setType(1);
      gOptionReview(1, searchstart, searchend, 0);
    }
    setPageCurrent(1);
    if (pageCurrent === 1) {
      gListAllCustomer(
        idjour > 0 ? idjour : 1,
        1,
        limitRow,
        searchstart,
        searchend,
        monthCheck,
        id_review
      );
    }
  };
  const handleChecked = (
    checked: boolean,
    idCustomer: string,
    phone: string,
    name: string
  ) => {
    if (
      checked &&
      !listCustomerSelected.some((item) => item.customer_id === idCustomer)
    ) {
      setListCustomerSelected([
        ...listCustomerSelected,
        { customer_id: idCustomer, phone, name },
      ]);
    } else {
      setListCustomerSelected(
        listCustomerSelected.filter((item) => item?.customer_id !== idCustomer)
      );
    }
  };
  const handleCheckedAll = (checked: boolean) => {
    if (checked) {
      setListCustomerSelected([
        ...listCustomer.map((item) => {
          return {
            customer_id: item?.id_customer,
            phone: item?.phone,
            name: item?.name,
          };
        }),
      ]);
    } else {
      setListCustomerSelected([]);
    }
  };
  const gListAllCustomer = async (
    typecheck: number,
    page: number,
    limit: number,
    datestart: string = "",
    dateend: string = "",
    month: number = 0,
    idreview: number = 0
  ) => {
    setListCustomerSelected([]);
    if (UserIdStore) {
      setShowLoading(true);
      const reqCus = await customerApi.getAllCustomer(
        UserIdStore,
        datestart,
        dateend,
        typecheck,
        page,
        limit,
        month,
        idreview,
        searchPhone
      );
      if (Number(reqCus?.status) === 1) {
        setListCustomer(reqCus?.data ?? []);
        setTotalPage(reqCus?.totalPage ? Number(reqCus?.totalPage) : 1);
        setTotalRow(reqCus?.total ? Number(reqCus?.total) : 1);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  useEffect(() => {
    gOptionMonth();
    gComboVoucherOwner();
    gOptionJourney();
    gOptionReview(type, searchstart, searchend, monthCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setPageGoTo(pageCurrent);
    gListAllCustomer(
      type,
      pageCurrent,
      limitRow,
      searchstart,
      searchend,
      monthCheck,
      id_review
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent, limitRow]);

  const handleChangeShowRow = (value: number) => {
    setLimitRow(value);
    setPageCurrent(1);
  };
  const pagingRender = () => {
    if (totalPage <= 5) {
      let pagelist = [];
      for (let index = 1; index <= totalPage; index++) {
        pagelist.push(
          <button
            key={"page" + index}
            className={` ${
              pageCurrent === index
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
            }`}
            onClick={() => {
              setPageCurrent(index);
            }}
          >
            {index}
          </button>
        );
      }
      return pagelist;
    } else {
      let pagelist = [];
      if (pageCurrent <= 4) {
        for (let index = 1; index <= 5; index++) {
          pagelist.push(
            <button
              key={"page" + index}
              className={` ${
                pageCurrent === index
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(index);
              }}
            >
              {index}
            </button>
          );
        }
        pagelist.push(
          <button key={"page..."} className={`btn-page-customer-receipts`}>
            ...
          </button>
        );
        pagelist.push(
          <button
            key={"page" + totalPage}
            className={` ${
              pageCurrent === totalPage
                ? "btn-page-customer-receipts-target"
                : "btn-page-customer-receipts"
            }`}
            onClick={() => {
              setPageCurrent(totalPage);
            }}
          >
            {totalPage}
          </button>
        );
      } else {
        //p=5
        var dem = 0;
        for (
          let index = pageCurrent - 3;
          index <=
          (totalPage - pageCurrent > 4 ? pageCurrent + 3 : totalPage - 1);
          index++
        ) {
          if (dem === 0) {
            pagelist.push(
              <button
                key={"page1"}
                className={` ${
                  pageCurrent === 1
                    ? "btn-page-customer-receipts-target"
                    : "btn-page-customer-receipts"
                }`}
                onClick={() => {
                  setPageCurrent(1);
                }}
              >
                {1}
              </button>
            );
            pagelist.push(
              <button key={"page...2"} className={`btn-page-customer-receipts`}>
                ...
              </button>
            );
            dem++;
          }
          pagelist.push(
            <button
              key={"page" + index}
              className={` ${
                pageCurrent === index
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(index);
              }}
            >
              {index}
            </button>
          );
        }
        if (totalPage - pageCurrent > 4) {
          pagelist.push(
            <button key={"page...3"} className={`btn-page-customer-receipts`}>
              ...
            </button>
          );
          pagelist.push(
            <button
              key={"page" + totalPage}
              className={` ${
                pageCurrent === totalPage
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(totalPage);
              }}
            >
              {totalPage}
            </button>
          );
        } else {
          pagelist.push(
            <button
              key={"page" + totalPage}
              className={` ${
                pageCurrent === totalPage
                  ? "btn-page-customer-receipts-target"
                  : "btn-page-customer-receipts"
              }`}
              onClick={() => {
                setPageCurrent(totalPage);
              }}
            >
              {totalPage}
            </button>
          );
        }
      }

      return pagelist;
    }
    // return pagelist;
  };
  return (
    <>
      <ModalSend
        show={showModalSendSMS}
        onHide={() => setModalSendSMS(!showModalSendSMS)}
        listCustomerSelected={listCustomerSelected}
        comboVoucher={comboVoucher}
      />
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex">
            <div className="w-100 flex items-center py-2 px-4">
              <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                {languageUI.filter}
              </span>
              <div className="d-flex align-items-center border px-2 rounded-8px h-42px  w-200">
                <img src={calender} alt="" />
                <div className="w-100">
                  <div className="form-group">
                    <DateRangePicker
                      initialSettings={{
                        // startDate: new Date(),
                        showDropdowns: true,
                        autoUpdateInput: false,
                        locale: {
                          cancelLabel: "Reset",
                          applyLabel: "Apply",
                        },
                      }}
                      onApply={handleApply}
                      onCancel={handleCancel}
                    >
                      <input
                        type="text"
                        className="form-control input-daterange-timepicker border-none w-195px"
                        placeholder="Today"
                        value={
                          searchstart !== "" && searchend !== ""
                            ? moment(searchstart).format("DD/MM/YYYY") +
                              "-" +
                              moment(searchend).format("DD/MM/YYYY")
                            : ""
                        }
                        readOnly={true}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center border  px-2  rounded-8px h-42px ml-6 ">
                <img src={calender} alt="" className="pe-2" />
                <Select
                  className="react-select react-select-container w-200px p-0 border-select-none"
                  classNamePrefix="react-select"
                  // defaultValue={[""]}
                  placeholder={languageUI.longTimeNoSee}
                  isClearable={true}
                  value={comboMonth.filter(
                    (item) => item?.value === monthCheck
                  )}
                  options={comboMonth.map((item) => {
                    return {
                      value: item.value,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => {
                    handleSearchMonth(e?.value ? Number(e?.value) : 0);
                  }}
                />
              </div>

              {/* <div className="d-flex align-items-center border  px-2  rounded-8px h-42px ml-6">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-200px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.byReview}
                  isClearable={true}
                  value={comboReview.filter(item => Number(item?.value) === id_review)}
                  options={comboReview.map((item) => {
                    return {
                      value: item.value,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => {
                    handleSearchReview(e?.value ? Number(e?.value) : 0)
                  }}
                />
              </div>

              <div className="d-flex align-items-center border  px-2  rounded-8px h-42px ml-6">
                <img src={calender} alt="" />
                <Select
                  className="react-select react-select-container w-200px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.byJourney}
                  isClearable={true}
                  value={comboJourney.filter(item => Number(item?.value) === type)}
                  options={comboJourney.map((item) => {
                    return {
                      value: item.value,
                      label: item.label,
                    };
                  })}
                  onChange={(e) => {
                    handleSearchJourney(e?.value ? Number(e?.value) : 0)
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white radius-18px ml-12 w-95 mt-8 w-100vh">
        <div className="h-70px w-100 custom-radius-table  bg-white px-4 d-flex align-items-center border-bottom container-fluid ">
          <div className="w-fit flex-shrink-0 d-flex align-items-center justify-between border-right h-100 pe-2 ">
            <div className="flex flex-column">
              <span className="text-5 text-212134 mb-0 d-flex align-items-center">
                {languageUI.customerList}
              </span>
              {totalRow > 0 && (
                <span className="text-5 text-269AFF">
                  ({totalRow} {languageUI.customer})
                </span>
              )}
            </div>
          </div>
          <div className="d-flex align-items-center w-100 pl-50px  justify-between">
            <div className="flex items-center w-100">
              <img src={iconSearchGreen} alt="" className="pr-2" />
              <input
                type="text"
                className="focus-visible-none input-find-by-phone-employee w-100"
                placeholder={languageUI.findReviewByPhone}
                value={searchPhone}
                onChange={(e) => setSearchPhone(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setPageCurrent(1);
                    if (pageCurrent === 1) {
                      gListAllCustomer(
                        type,
                        1,
                        limitRow,
                        searchstart,
                        searchend,
                        monthCheck,
                        id_review
                      );
                    }
                  }
                }}
              />
            </div>
            <div className="flex items-center flex-shrink-0">
              {listCustomerSelected.length > 0 && (
                <>
                  <img src={selected} alt="" />
                  <span className="text-FAA300 text-17px fw-600 font-urbansist ml-3">
                    {listCustomerSelected.length} {languageUI.customerSelected}
                  </span>
                </>
              )}

              <button
                className="cursor flex items-center border-none text-F8F9FA p-send-sms bg-0FA54A text-17px fw-500 font-urbansist radius-2 ml-6"
                onClick={() => setModalSendSMS(!showModalSendSMS)}
                disabled={listCustomerSelected.length === 0}
              >
                <MailIcon className="mr-1" />
                {languageUI.sendSMS}
              </button>
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="w-5">
                <input
                  type="checkbox"
                  checked={
                    listCustomer.length > 0 &&
                    listCustomer.length === listCustomerSelected.length
                  }
                  disabled={listCustomer.length === 0}
                  onClick={(e: any) => handleCheckedAll(e.target.checked)}
                />
              </th>
              <th
                scope="col"
                className="w-10 text-18px text-12B064 fw-600 font-urbansist"
              >
                {languageUI.lastVisit}
              </th>
              <th scope="col" className="w-10">
                <div className="flex justify-between  items-center">
                  <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">
                    {languageUI.timesVisit}
                  </span>
                </div>
              </th>
              <th scope="col" className="w-10">
                <div className="flex justify-between  items-center">
                  <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">
                    {languageUI.points}
                  </span>
                </div>
              </th>
              <th
                scope="col"
                className="w-35 text-18px text-12B064 fw-600 font-urbansist"
              >
                {languageUI.name}
              </th>
              <th
                scope="col"
                className="w-15 text-18px text-12B064 fw-600 font-urbansist"
              >
                {languageUI.phone}
              </th>
              <th scope="col" className="w-10">
                <div className="flex justify-between  items-center">
                  <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">
                    {languageUI.totalAmount}
                  </span>
                </div>
              </th>
              <th className="w-5"></th>
            </tr>
          </thead>
          <tbody className="h-body-table-customer">
            {listCustomer.map((item, i) => (
              <ContentTableCustomer
                lastVisit={item?.lastVisit}
                timeVisit={Number(item?.timeVisit)}
                point={Number(item?.point)}
                name={item?.name}
                phone={item?.phone}
                totalAmount={item?.total_amount ? item?.total_amount : "0"}
                key={`Cus${i}`}
                customer_id={item?.id_customer}
                checked={listCustomerSelected.some(
                  (row) => row?.customer_id === item?.id_customer
                )}
                handleChecked={handleChecked}
              />
            ))}
          </tbody>
        </table>
        {/* {totalPage > 1 && ( */}
        <div className="w-100 h-66px d-flex justify-content-center align-items-center border-top">
          <div className="d-flex h-fit d-flex  align-items-center">
            <p className="mb-0 h-fit text-page-table-bottom">
              {languageUI.total} {totalPage} {languageUI.pages}
            </p>
            <button
              className="btn-page-customer-receipts"
              onClick={() => {
                pageCurrent > 1
                  ? setPageCurrent(pageCurrent - 1)
                  : setPageCurrent(1);
              }}
            >
              <img src={arrowLeftPage} alt="" />
            </button>
            {pagingRender()}

            <button
              className="btn-page-customer-receipts"
              onClick={() => {
                pageCurrent !== totalPage
                  ? setPageCurrent(pageCurrent + 1)
                  : setPageCurrent(totalPage);
              }}
            >
              <img src={arrowRightPage} alt="" />
            </button>
            <div className="d-flex align-items-center border  rounded-8px h-42px mx-4">
              {/* <Select
                                menuPlacement="top"
                                className="react-select react-select-container w-150px  border-select-none "
                                classNamePrefix="react-select"
                                defaultValue={[""]}
                                placeholder="Show 50 / page"
                                isClearable={true}
                                options={optionPage.map((item) => {
                                    return {
                                        value: item.value,
                                        label: item.page,
                                    };
                                })}
                                onChange={(e) => { handleChangeShowRow(e?.value) }}
                            /> */}
              <Select2
                className="h-42px p-0"
                labelId="demo-simple-select-label"
                id="demo-simple-select-5"
                placeholder="Choose voucher ID"
                value={limitRow}
                onChange={(e: any) =>
                  handleChangeShowRow(Number(e.target.value))
                }
              >
                {optionPage.map((item, i) => (
                  <MenuItem key={i} value={item?.value}>
                    {" "}
                    {item.page}
                  </MenuItem>
                ))}
              </Select2>
            </div>
            <p className="mb-0 text-page-table-bottom me-2">
              {languageUI.goTo}
            </p>
            <div className="input-text-page">
              <input
                type="number"
                className="w-100 text-page-table-bottom border-none px-2"
                readOnly={totalPage === 1}
                value={pageGoTo}
                onChange={(e) => {
                  if (e.target.value && Number(e.target.value) > 0) {
                    setPageGoTo(Number(e.target.value));
                  } else {
                    setPageGoTo(undefined);
                  }
                }}
                onBlur={(e) => {
                  setPageCurrent(
                    Number(e.target.value) <= 0
                      ? 1
                      : Number(e.target.value) > totalPage
                      ? totalPage
                      : Number(e.target.value)
                  );
                }}
              />
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};
export default Customer;
