import { Modal } from "react-bootstrap";
import "./Styles.css";
import { MenuCategory } from "../../../../api/apiCheckin/chooseMenuApi";

interface ModalViewMoreGroupFoodProps {
  show: boolean;
  handleClose: () => void;
  ListMenuItems: MenuCategory[];
  scrollToTop: (id: string) => void;
}

const ModalViewMoreGroupFood = ({
  show,
  handleClose,
  ListMenuItems,
  scrollToTop,
}: ModalViewMoreGroupFoodProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-viewmore-group modal-delete-voucher"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body>
            <div className="position-relative px-3">
              <div className="w-100">
                <p className="text-delete-modal-customer mb-0 text-center w-100">
                  Choose Group Menu
                </p>
              </div>
              <div className="d-flex gap-2 flex-wrap mt-3 box-content-veiwmore">
                {ListMenuItems.map((group, i) => {
                  return (
                    <>
                      {i === 0 && (
                        <div
                          className={`  h-40px  border rounded-5 px-3 w-fit d-flex justify-content-center align-items-center bg-primary text-white`}
                          onClick={() => {
                            // setSelectOptionMenu("all");
                            scrollToTop(`group${group?.foodgroup_id}`);
                            handleClose();
                          }}
                        >
                          <p className={`text-title-bill mb-0 text-white`}>
                            All
                          </p>
                        </div>
                      )}
                      <div
                        key={"gr" + i}
                        className={`h-40px    rounded-5 px-3 w-fit d-flex justify-content-center align-items-center `}
                        style={{ backgroundColor: `${group?.color}` }}
                        onClick={() => {
                          // setSelectOptionMenu(group?.foodgroup_id ?? "");
                          scrollToTop(`group${group?.foodgroup_id}`);
                          handleClose();
                        }}
                      >
                        <p
                          className={`text-title-bill text-white mb-0 text-truncate`}
                        >
                          {group.foodgroup_name}
                        </p>
                      </div>
                    </>
                  );
                })}
              </div>

              {/* <div className="d-flex justify-content-between">
                <div
                  className="btn-cancel-modal-delete-customer bg-white"
                  onClick={handleClose}
                >
                  {languageUI.cancel}
                </div>
                <div
                  className="btn-cancel-modal-delete-customer border-delete bg-white"
                  onClick={DelVoucher}
                >
                  <img src={trashRed} alt="" className="" />
                  <p className="mb-0 text-delete-modal-customer-btn">
                    {languageUI.delete}
                  </p>
                </div>
              </div>
              <img src={leaf1} alt="" className="img-leaf1" />
              <img src={leaf2} alt="" className="img-leaf2" /> */}
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalViewMoreGroupFood;
