
import "./Styles.css"

interface OrderItemsTableProps {
    foodName: string;
    descreption: string;
    quantity: number;
    price: number;
}
const OrderItemsTable: React.FC<OrderItemsTableProps> = ({foodName,descreption,price,quantity}) => {

    return (
        <div className="bg-white rounded-5 overflow-hidden p-2 mb-2">
            <div className="row">
                <div className="col-9 d-flex">
                    <div className="me-2">
                        <img src="https://images.unsplash.com/photo-1687851898832-650714860119?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80" alt=""
                            className="w-100px h-100px rounded-4 ratio ratio-1x1" />
                    </div>
                    <div>
                        <p className="mb-0 font-bold text-base">{foodName}</p>
                        <p className="mb-0 font-medium text-base">{descreption}</p>
                        <p className="mb-0 font-bold text-base">Quantity: {quantity}</p>
                    </div>
                </div>
                <div className="col-3">
                    <p className="mb-0 font-bold text-base text-danger">Price: ${price}</p>
                </div>
            </div>

        </div>
    );
};


export default OrderItemsTable;
