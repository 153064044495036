import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  bill,
  clock,
  dollarGreen,
  tip,
} from "../../../../../../components/Image";
import "./Styles.css";
import { DetailTimeSheet } from "../../../../../../api/apiOwner/StaffApi";
import { FormatDolla } from "../../../../../../utils/format";
import * as SettingPrinterApi from "../../../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../../../api/apiOwner/printerApi";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalToast from "../../../ModalToast/ModalToastErr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface drawerRight {
  openDrawerSumary: boolean;
  detailTimeSheet: DetailTimeSheet;
  listPrinterName?:SettingPrinterApi.ListPrinterByCatalog[]
  dismisDrawer: () => void;
}
const DrawerSumary: React.FC<drawerRight> = ({
  openDrawerSumary,
  detailTimeSheet = {},
  listPrinterName=[],
  dismisDrawer,
}) => {
  const languageUI = getLanguageUI().clockInOut;
  const languagePrinterUI = getLanguageUI().printPage;
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");

  const PrintCard = async () => {
    try {
      const rawhtml = `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 40px;">
            <h3 style="width: 100%; margin:  auto; text-align: center">${
              languageUI.summary
            }</h3>
            <div style="width: 100%;">
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.name
              }: ${detailTimeSheet?.name}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.date
              }: ${detailTimeSheet?.datesheet}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.clockIn
              }: ${
        detailTimeSheet?.clock_in ? detailTimeSheet?.clock_in : " - - -"
      }</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.clockOut
              }: ${
        detailTimeSheet?.clock_out ? detailTimeSheet?.clock_out : " - - -"
      }</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">Tip: $${
                detailTimeSheet?.tip
                  ? FormatDolla(Number(detailTimeSheet?.tip))
                  : "0"
              }</h6>
            </div>

              
          <div style="display: table; width: 100%">
            <div style = "display: table-row; padding-top: 10px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p  style=" margin: 0; text-align: start; font-size: 24px ">${
                  languageUI.bill
                }</p>
              </div>
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important" style="font-size: 24px ; ">Tip</p>
              </div>
               <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important" style="font-size: 24px ; ">${
                  languageUI.time
                }</p>
              </div>
            </div>
             ${
               detailTimeSheet?.list_order &&
               detailTimeSheet?.list_order
                 .map(
                   (item, i) =>
                     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                     `
           <div style = "display: table-row; padding-top: 10px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p  style=" margin: 0; text-align: start; font-size: 24px ">#${item.id}</p>
              </div>
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important" style="font-size: 24px ; ">$${item.tip_amount}</p>
              </div>
               <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important" style="font-size: 24px ; ">${item.timeOrder}</p>
              </div>
            </div>
         `
                 )
                 .join(" ")
             }
          </div>

            <div style="width: 100%;">
            <div style="width: 100%;">
            <h6 style="font-weight: 400; margin: 0; text-align: center;">- - - - - - - - - -</h6>
        </div>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${nameStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${addressStore}</h5>
              <h5 class="" style="font-weight: 400; margin: 0; text-align: center;">${phoneOwnerStore}</h5>
            </div>
            <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">---OoO---</h3>
          </div>
          </body>

          </html>
          `;
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <Drawer
        className="custom-btn-close custom-w-drawer-sumary"
        anchor="right"
        onClose={dismisDrawer}
        open={openDrawerSumary}
      >
        <div className="w-100 px-6 position-relative h-100vh">
          <div className="mt-16">
            <span className="flex  text-4A4A6A text-8 pb-5 fw-600 font-urbansist">
              <img src={bill} alt="" className="mr-4" />
              {languageUI.summary}
            </span>
          </div>
          <div className="border-bottom pb-6">
            <div className="flex  items-center pb-3">
              <span className="w-30 text-15px fw-600  text-666687">
                {languageUI.name}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {detailTimeSheet?.name}
              </span>
            </div>
            <div className="flex  items-center pb-3">
              <span className="w-30 text-15px fw-600  text-666687">
                {languageUI.date}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {detailTimeSheet?.datesheet}
              </span>
            </div>
            <div className="flex  items-center pb-3">
              <span className="w-30 text-15px fw-600  text-666687">
                {languageUI.clockIn}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {detailTimeSheet?.clock_in}
              </span>
            </div>
            <div className="flex  items-center pb-3">
              <span className="w-30 text-15px fw-600  text-666687">
                {languageUI.clockOut}
              </span>
              <span className="text-14px fw-700 font-urbansist text-4A4A6A">
                {detailTimeSheet?.clock_out ? detailTimeSheet?.clock_out : ""}
              </span>
            </div>
            <div className="flex  items-center pb-3">
              <span className="w-30 text-15px fw-600  text-666687">Tip</span>
              <div className="flex">
                <span className="text-14px fw-700 font-urbansist text-4A4A6A mr-1">
                  {detailTimeSheet?.tip ? detailTimeSheet?.tip : 0}
                </span>
                <span className="text-a5a5ba text-3 fw-700">$</span>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between border-bottom w-100 py-2">
            <span className="w-25 text-18px font-urbansist text-12B064 fw-600">
              Order
            </span>
            <span className="w-30 text-18px font-urbansist text-12B064 fw-600 flex items-center ">
              <img src={clock} alt="" className="mr-3" />
              {languagePrinterUI.printDateTime}
            </span>
            <span className="w-25 text-18px font-urbansist text-12B064 fw-600 flex items-center justify-center">
              <img src={dollarGreen} alt="" className="mr-3" />
              {languageUI.bill}
            </span>
            <span className="w-25 text-18px font-urbansist text-12B064 fw-600 flex items-center justify-end">
              <img src={tip} alt="" className="mr-3" />
              Tip
            </span>
          </div>
          <div className="h-430px overflow-y-scroll">
            {detailTimeSheet?.list_order &&
              detailTimeSheet?.list_order.map((item, i) => (
                <div className="flex justify-between  py-2" key={item.id}>
                  <div className="flex flex-column w-25">
                    <span className="text-15px font-urbansist text-32324D">
                      {item.table_name}
                    </span>
                    <span className="text-13px font-urbansist text-4A4A6A fw-600">
                      #{item.table_name}
                    </span>
                  </div>
                  <div className="flex flex-column w-30">
                    <span className="text-15px font-urbansist text-32324D">
                      {item.dateOrder}
                    </span>
                    <span className="text-13px font-urbansist text-4A4A6A fw-600">
                      {item.timeOrder}
                    </span>
                  </div>
                  <div className="flex w-25 justify-center">
                    <span className="text-18px fw-600 font-urbansist text-4A4A6A mr-1">
                      {FormatDolla(Number(item.price))}
                    </span>
                    <span className="text-a5a5ba text-3 fw-700">$</span>
                  </div>
                  <div className="flex w-25 justify-end">
                    <span className="text-18px fw-600 font-urbansist text-4A4A6A mr-1">
                      {FormatDolla(Number(item.tip_amount))}
                    </span>
                    <span className="text-a5a5ba text-3 fw-700">$</span>
                  </div>
                </div>
              ))}
          </div>
          <div className="bg-ffffff box-shadow-print position-absolute bottom-10 w-90">
            <button
              className="border-FF7B2C radius-2 text-FF7B2C bg-FFF2EA w-100 p-2 text-6 fw-600 font-urbansist"
              onClick={PrintCard}
            >
              Print
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerSumary;
