import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Request from "../../api/Request";



const ShowReview = () => {


  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const [messageReview, setMessageReview] = useState<any>("");
  const [nameOwner, setNameOwner] = useState<any>("");

  const params = useParams();
  let id_review = params?.id_review;

  const getInforReview = async () => {
    await Request.fetchAPI(`/checkin/gInfoReview.php?id=${id_review}`, "get")
      .then((res) => {
        if (Number(res.status) === 1) {
          setCheckError(false)
          setMessageReview(res?.data?.message);
          setNameOwner(res?.data?.name);
        } else {
          setCheckError(true)
          setErMess(res?.message ?? '')
        }
      })
      .catch((error: any) => { console.log(error); });
  };

  useEffect(() => {
    getInforReview();
  }, [getInforReview]);
  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <div>
                    <h4 className="text-center text-uppercase fw-bold">
                      {nameOwner} need your feedback
                    </h4>
                    <div className="d-flex align-items-center justify-content-center">
                      <i
                        className="fas fa-star fs-1 me-2 text-warning"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2 text-warning"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2 text-warning"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2"
                        style={{ cursor: "pointer" }}
                      ></i>
                      <i
                        className="fas fa-star fs-1 me-2"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                    <Form.Control
                      disabled={true}
                      className="my-3"
                      as="textarea"
                      rows={6}
                      value={messageReview}
                      placeholder={`Dear you, as owners of Invogue Nails & Spa, we value the quality of our service and would like to hear true feedbacks from you in order to serve you better`}
                    />
                    <p style={{ color: "#f1556c", textAlign: "center" }}>
                      {checkError ? errMess : ""}
                    </p>
                    {/* <div className="text-end">
                      <Button variant="primary">Submit feedback</Button>
                    </div> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ShowReview;
