import * as Request from "../Request";

export interface Result {
  status: number;
  message?: string;
  data?: string;
}
export interface CountCart {
  status: number;
  message?: string;
  total?: string;
}
export interface InfoCart {
  id?: string;
  owner_id?: string;
  custommer_id?: string;
  table_id?: string;
  service_item_id?: string;
  price?: string | number;
  quantity?: string;
  notefood_id?: string;
  side_id?: string;
  type?: string;
  notechef?: string;
  delf?: string;
  timestamp?: string;
  name?: string;
  imageCourse?: string;
  donvitinh?: string;
  table_name?: string;
  notefood_name?: string;
  side_name?: string;
  slcon?: string;
  insufficient?: boolean;
  typeFood?: number;
  priceSides?: number;
}
export interface listSide {
  id: string;
  imageCourse: string;
  name: string;
  price: string;
  quantity: string;
  side_id: string;
  table_id: string;
  typeFood: string;
}
export interface ResultCartInfo {
  status: number;
  message?: string;
  total: number;
  totalPrice: number;
  maxSide: number;
  data?: InfoCart[];
  listSide?: listSide[];
  totalSideChoosed: number;
  totalPriceSides: number;
}

export interface req<T> {
  status: number;
  message?: string;
  data?: T;
}

export const addCart = async (
  custommerId: string,
  ownerID: string,
  service_item_id: string,
  price: string,
  quantity: number = 1,
  valueNoteFood: string = "",
  valueSideDishis: string = "",
  type: number = 1,
  noteChef: string = "",
  table_id: string = "0",
  priceSides: number = 0
) => {
  return await Request.fetchAPI(`checkin/addCart.php`, "POST", {
    custommerId,
    ownerID,
    service_item_id,
    price,
    quantity,
    valueNoteFood,
    valueSideDishis,
    type,
    noteChef,
    table_id,
    priceSides,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

//lấy số lượng giỏ hàng
export const gNumCart = async (
  custommerId: string,
  ownerID: string,
  table_id: string
) => {
  return await Request.fetchAPI(`checkin/gToTalCart.php`, "POST", {
    custommerId,
    ownerID,
    table_id,
  })
    .then((res: CountCart) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        total: 0,
      };
    });
};
//lấy số lượng sản phẩm chưa thanh toán
export const gNumFoodNotPay = async (
  custommerId: string,
  ownerID: string,
  table_id: string
) => {
  return await Request.fetchAPI(`checkin/gToTalProductNotPay.php`, "POST", {
    custommerId,
    ownerID,
    table_id,
  })
    .then((res: CountCart) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        total: 0,
      };
    });
};

//lấy thông tin giỏ hàng
export const gInfoCartCustommer = async (
  custommerId: string = "",
  ownerID: number = 0,
  table_id: string = ""
) => {
  return await Request.fetchAPI(`checkin/cartInfomation.php`, "POST", {
    custommerId,
    ownerID,
    table_id,
  })
    .then((res: ResultCartInfo) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        total: 0,
        totalPrice: 0,
        maxSide: 0,
        data: [],
        totalSideChoosed: 0,
        listSide: [],
        totalPriceSides: 0,
      };
    });
};

//xóa thông tin giỏ hàng
export const delCartById = async (cart_id: string) => {
  return await Request.fetchAPI(`checkin/delCartById.php`, "POST", { cart_id })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
//update lại số lượng giỏ hàng
export const upQuantityCartById = async (cart_id: string, quantity: number) => {
  return await Request.fetchAPI(`checkin/updateQuantityCart.php`, "POST", {
    cart_id,
    quantity,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

//update lại số lượng side giỏ hàng
export const addSideCartById = async (
  custommerId: string,
  ownerID: number,
  table_id: string,
  sideId: string
) => {
  return await Request.fetchAPI(`checkin/addSideToCart.php`, "POST", {
    custommerId,
    ownerID,
    table_id,
    sideId,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gListCartSide = async (
  custommerId: string,
  owner_id: number,
  table_id: string
) => {
  return await Request.fetchAPI(`checkin/gListSideCartChoosed.php`, "POST", {
    owner_id,
    custommerId,
    table_id,
  })
    .then((res: req<listSide[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const DelCartSide = async (sideId: string) => {
  return await Request.fetchAPI(`checkin/delSideCart.php`, "POST", { sideId })
    .then((res: req<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gNumberCardOrderOnline = async (
  custommerId: string,
  ownerID: string
) => {
  return await Request.fetchAPI(`checkin/gToTalCartOnline.php`, "POST", {
    custommerId,
    ownerID,
  })
    .then((res: CountCart) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        total: 0,
      };
    });
};
export const addCartOnline = async (
  custommerId: string,
  ownerID: string,
  service_item_id: string,
  price: string,
  quantity: number = 1,
  valueNoteFood: string = "",
  valueSideDishis: string = "",
  type: number = 1,
  noteChef: string = "",
  priceSides: number = 0
) => {
  return await Request.fetchAPI(`checkin/addCartOnline.php`, "POST", {
    custommerId,
    ownerID,
    service_item_id,
    price,
    quantity,
    valueNoteFood,
    valueSideDishis,
    type,
    noteChef,
    priceSides,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

//lấy thông tin giỏ hàng online
export const gInfoCartCustommerOnline = async (
  custommerId: string = "",
  ownerID: number = 0
) => {
  return await Request.fetchAPI(`checkin/cartInfomationOnline.php`, "POST", {
    custommerId,
    ownerID,
  })
    .then((res: ResultCartInfo) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        total: 0,
        totalPrice: 0,
        maxSide: 0,
        data: [],
        totalSideChoosed: 0,
        listSide: [],
        totalPriceSides: 0,
      };
    });
};
// lấy số món ăn kèm trong giỏ hàng
export const addSideToCartOnline = async (
  custommerId: string,
  ownerID: number,
  sideId: string
) => {
  return await Request.fetchAPI(`checkin/addSideToCartOnline.php`, "POST", {
    custommerId,
    ownerID,
    sideId,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

//update lại số lượng side giỏ hàng Online
export const gListCartSideOnline = async (
  custommerId: string,
  owner_id: number
) => {
  return await Request.fetchAPI(
    `checkin/gListSideCartChoosedOnline.php`,
    "POST",
    { owner_id, custommerId }
  )
    .then((res: req<listSide[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
