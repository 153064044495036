import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";

import "./Styles.css";
import { useNavigate } from "react-router-dom";
import {
  RequestOrderHistory,
  foodItem,
  gHistoryOrder,
  gListHistoryOrderCustomerV2,
  orderHistory,
} from "../../api/apiCheckin/orderApi";
import ModalLoading from "../../components/ModalLoading";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import { default_product } from "../../components/Image";
import { FormatDolla } from "../../utils/format";
import { InfoHappyHour, Menu, gHappyHour } from "../../api/apiOwner/menuPosApi";
import { plus } from "../../components/ImgExport";
import { targetupload } from "../../api/urlImage";
import {
  InfoFood,
  NoteFood,
  Side_Dishes,
  gInfoFood,
  listNoteOptionFood,
} from "../../api/apiCheckin/chooseMenuApi";
import ModalAddToCard from "./components/ModalAddToCard/ModalAddToCard";
import { gNumCart } from "../../api/apiCheckin/addCartApi";
// import { Menu } from "../../api/apiCheckin/chooseMenuApi";

const HistoryOrder = () => {
  const languageUI = getLanguageCheckIn();
  const nameStore = localStorage.getItem("namestore");
  const navigate = useNavigate();
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const [listOrder, setListOrder] = useState<RequestOrderHistory[]>([]);
  const [ShowLoading, setShowLoading] = useState(false);
  const [infoHappyhour, setInfoHappyhour] = useState<InfoHappyHour>();
  const [showHappyHour, setShowHappyHour] = useState<boolean>(false);
  const [listFoodSell, setListFoodSell] = useState<Menu[]>([]);
  const [timeExpiration, setTimeExpiration] = useState<number>();
  const [detailFood, setDetailFood] = useState<InfoFood>();
  const [noteFood, setNoteFood] = useState<NoteFood[]>([]);
  const [side_Dishes, setSide_Dishes] = useState<Side_Dishes[]>([]);
  const [listNoteOptionFood, setlistNoteOptionFood] = useState<
    listNoteOptionFood[]
  >([]);
  const [foodItemActive, setFoodItemActive] = useState<foodItem>();

  const [showModalAddToCard, setShowAddToCard] = useState(false);
  const [foodTotal, setFootTotal] = useState<number>(0);

  //gHappyHour
  const gShowHappyHour = async () => {
    if (ownerID) {
      const req = await gHappyHour(ownerID);
      if (Number(req?.status) === 1) {
        setInfoHappyhour(req?.data);
        setTimeExpiration(Number(req?.time_difference));
        setListFoodSell(req?.listFood ?? []);
        setShowHappyHour(true);
      } else {
        setInfoHappyhour(undefined);
        setTimeExpiration(0);
        setListFoodSell([]);
        setShowHappyHour(false);
      }
    }
  };
  const gListHistoryOrder = async () => {
    if (custommerId && ownerID) {
      setShowLoading(true);
      const req = await gListHistoryOrderCustomerV2(custommerId, ownerID);
      if (Number(req?.status) === 1) {
        setListOrder(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const gDiscountHappyHourByFood = (food_id: string) => {
    return Number(infoHappyhour?.status) === 0
      ? 1
      : listFoodSell.filter((item) => Number(item.id) === Number(food_id))[0]
          ?.discount ?? 0;
  };

  const getInfoFood = async (foodItem: foodItem) => {
    setFoodItemActive(foodItem);
    if (foodItem.service_item_id) {
      setShowLoading(true);
      const infoFood = await gInfoFood(foodItem.service_item_id.toString());
      if (Number(infoFood?.status) === 1 && infoFood?.data?.InfoFood) {
        setDetailFood(infoFood?.data?.InfoFood);
        setNoteFood(infoFood?.data?.NoteFood ?? []);
        setSide_Dishes(infoFood?.data?.Side_Dishes ?? []);
        setlistNoteOptionFood(infoFood?.data?.listNoteOptionFood ?? []);
        setShowLoading(false);
        setShowAddToCard(true);
      } else {
        setShowLoading(false);
      }
    }
  };

  const CountNumCart = async () => {
    var table_id = localStorage.getItem("table_id");
    if (table_id && custommerId && ownerID) {
      const num = await gNumCart(custommerId, ownerID.toString(), table_id);
      setFootTotal(Number(num?.total ?? 0));
    }
  };
  const dissmisModalAddToCard = () => {
    setShowAddToCard(false);
  };

  const navToDetail = (order_id: string) => {
    navigate("/webcheckin/historyOrderDetail", {
      state: { order_id, ownerID },
    });
  };
  useEffect(() => {
    gListHistoryOrder();
    gShowHappyHour();
  }, []);

  return (
    <body>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <header className="w-100 custom-header-choose-menu d-flex justify-content-between align-items-center">
        <p className="title-name-store mb-0">{nameStore}</p>
        <div
          className=" px-4 py-1 bg-white d-flex  rounded-4 border"
          onClick={() => navigate(-1)}
        >
          <HomeIcon className="text-orange" />
        </div>
      </header>
      <div className="h-100vh custom-pading-header px-4 rounded-4">
        <p className="font-bold text-4a4a6a text-xl text-center mt-2">
          {languageUI.HistoryOrder}
        </p>

        <div className="table-order-history-check-in ">
          {listOrder.map((item, i) => (
            <div className="" key={i}>
              <div className="d-flex text-xl">
                <p className="font-bold font-roboto text-4a4a6a">
                  {item.timestart}
                </p>
                {/* <p className="font-bold font-roboto text-orange">
                  OrderID#{item.id}
                </p> */}
              </div>
              <div className="box-food-hisotry">
                {item.listFood.map((food, i) => (
                  <div className="h-100" key={i}>
                    <div
                      className={`h-100 rounded-5 shadow-sm bg-F6F6F9 px-2 py-2 d-flex flex-column overflow-hidden position-relative`}
                      onClick={() => getInfoFood(food)}
                    >
                      <div className="">
                        <div className="  mb-2">
                          {Number(
                            gDiscountHappyHourByFood(
                              food.service_item_id.toString() ?? ""
                            )
                          ) > 0 ? (
                            <div className=" position-relative d-flex gap-2">
                              <div className="d-flex gap-2px">
                                <div className="text-dollar-old">$</div>
                                <del className="text-price-food-old">
                                  {FormatDolla(food.price)}
                                </del>
                              </div>
                              <div className="d-flex gap-2px">
                                <div className="text-dollar">$</div>
                                <div className="text-price-food">
                                  {/* {FormatDolla(price)} */}
                                  {showHappyHour
                                    ? Number(infoHappyhour?.status) === 0
                                      ? FormatDolla(
                                          Number(food.price) *
                                            (1 -
                                              Number(infoHappyhour?.discount) /
                                                100)
                                        )
                                      : FormatDolla(
                                          Number(food.price) *
                                            (1 -
                                              Number(
                                                gDiscountHappyHourByFood(
                                                  food.service_item_id.toString() ??
                                                    ""
                                                )
                                              ) /
                                                100)
                                        )
                                    : FormatDolla(Number(food.price))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex gap-2px">
                              <div className="text-dollar">$</div>
                              <div className="text-price-food">
                                {FormatDolla(food.price)}
                              </div>
                            </div>
                          )}

                          <div className="btn-add-food-cart ">
                            <div className="w-100 h-100 position-relative">
                              <img
                                src={plus}
                                alt=""
                                className="btn-icon-plus"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="height-120px w-100">
                          <img
                            src={targetupload + food.imageCourse}
                            className="img-fluid w-100 height-120px object-fit-cover aspect-ratio-16-11 rounded-5 mb-1 mb-sm-1 mb-md-2 mb-lg-3 flex-shrink-0"
                            onError={(e) => {
                              e.currentTarget.src = default_product;
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between flex-column h-100  food-item-info  pb-1 ">
                        {/* <div className="d-flex align-items-center food-item-info flex-wrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap"> */}
                        <div className="d-flex justify-content-between flex-column px-2">
                          <div>
                            <h4 className="text-name-food text-two-line-name mb-1">
                              {food.name}
                            </h4>
                            <div className="text-describe">
                              {food.description}
                            </div>
                          </div>
                        </div>
                        {/* {Number(food.ordersServed) > 0 && (
                          <h6 className="text-right text-4a4a6a m-0 pt-1 flex-shrink-0">
                            {rank > 0 ? `#${rank} - ` : ""}
                            {ordersServed} {languageUI.OrdersServed}
                          </h6>
                        )} */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {/* <table className="table">
            <thead>
              <tr className="bg-primary">
                <th scope="col" className="w-1-3 bg-primary text-sm text-white fw-600 font-urbansist">{languageUI.TimeOrder}</th>
                <th scope="col" className="w-1-3 bg-primary">
                  <div className="flex justify-between  items-center">
                    <span className="text-sm text-white fw-600 font-urbansist mr-3">Order#</span>
                  </div>
                </th>
                <th scope="col" className="w-1-3 bg-primary">
                  <div className="flex justify-between  items-center">
                    <span className="text-sm text-white fw-600 font-urbansist mr-3">{languageUI.TotalItems}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className=" w-100">
              {listOrder.map((item, i) => (
                <tr className="w-100 " onClick={() => navToDetail(item?.id)} key={i}>
                  <td className="border-bottom-none text-sm text-4A4A6A w-1-3">{item?.timeend}</td>
                  <td className="border-bottom-none text-sm text-269AFF w-1-3">#{item?.id}</td>
                  <td className="border-bottom-none text-sm text-269AFF text-center w-1-3">{item?.totalItem}</td>
                </tr>
              ))}

            </tbody>
          </table> */}
        </div>
      </div>
      <ModalAddToCard
        ownerID={ownerID.toString()}
        customerID={custommerId ?? ""}
        detailFood={detailFood}
        noteFood={noteFood}
        side_Dishes={side_Dishes}
        side_id={foodItemActive?.side_id.toString()}
        price={foodItemActive?.price ?? 0}
        foodTotal={foodTotal}
        setFootTotal={setFootTotal}
        name={foodItemActive?.name}
        description={foodItemActive?.description}
        handleClose={dissmisModalAddToCard}
        setShowLoading={(load) => setShowLoading(load)}
        show={showModalAddToCard}
        listOptionNote={listNoteOptionFood}
      />
    </body>
  );
};

export default HistoryOrder;
