/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useCallback } from "react";
import "../../Styles.css";
// import Select from 'react-select';
import { pencilEdit, strashRed } from "../../../../components/ImgExport";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import ModalLoading from "../../../../components/ModalLoading";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ModalOTPDeleteAccount from "./Modal";
import * as StripeOwnerApi from "../../../../api/apiOwner/StripeOwnerApi";
import ModalConfirmAccountDefault from "../ModalConfirmAccountDefault/ModalEditNameTable";
import * as gConfigDoordashApi from "../../../../api/apiOwner/doordashApi";
import { getLanguageUI } from "../../../../Services/languageUI";
import { useDispatch } from "react-redux";
import * as flagSlice from "../../../../store/slices/FlagSettingSlice";
import CheckIcon from "@mui/icons-material/Check";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
interface ConectStripeProp {
  ChangeSetting: () => void;
}

const ConectStripe: React.FC<ConectStripeProp> = ({ ChangeSetting }) => {
  const languageUI = getLanguageUI().setting;
  const dispatch = useDispatch();
  const [nextPageAddAccount, setNextPageAddAccount] = useState(true);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showModalOTPDeleteAccount, setShowModalOTPDeleteAccount] =
    useState<boolean>(false);
  const [showModalSetDefault, setShowModalSetDefault] =
    useState<boolean>(false);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [accountName, setAccountName] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [publishKey, setPublishKey] = useState("");
  const [messageError, setMessageError] = useState("");
  const [messageErrorDoordash, setMessageErrorDoordash] = useState("");

  const [listAccount, setListAccount] = useState<
    StripeOwnerApi.ListAccountStripe[]
  >([]);
  const [idAccount, setIdAccount] = useState("");
  //
  const [developerId, setDeveloperId] = useState<string>("");
  const [nameDoordash, setNameDoordash] = useState<string>("");

  const [keyId, setKeyId] = useState<string>("");
  const [signingSecret, setSigningSecret] = useState<string>("");

  const gConfigDoordash = async () => {
    if (UserIdStore) {
      const req = await gConfigDoordashApi.gConfigDoordash(UserIdStore);
      if (Number(req?.status) === 1) {
        setDeveloperId(req?.data?.developer_id ?? "");
        setKeyId(req?.data?.key_id ?? "");
        setSigningSecret(req?.data?.signing_secret ?? "");
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setDeveloperId("");
        setKeyId("");
        setSigningSecret("");
      }
    }
  };

  const dismisModalOTPDeleteAccount = (reload: boolean = false) => {
    setShowModalOTPDeleteAccount(false);
    if (reload) {
      gListAccount();
    }
  };
  const dismisModalSetDefault = () => {
    setShowModalSetDefault(false);
  };
  const gListAccount = useCallback(async () => {
    if (UserIdStore) {
      const req = await StripeOwnerApi.gListAccountStripeByOwner(UserIdStore);
      if (Number(req?.status) === 1) {
        setListAccount(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setDefaultAccount = async () => {
    if (UserIdStore) {
      const req = await StripeOwnerApi.setDefaultAccountStripe(
        UserIdStore,
        idAccount
      );
      if (Number(req?.status) === 1) {
        setShowModalSetDefault(false);
        gListAccount();
      } else {
        setShowLoading(false);
      }
      dispatch(flagSlice.clearSetting());
    }
  };
  const SubmitAdd = async () => {
    if (UserIdStore) {
      if (!accountName || accountName.trim() === "") {
        setMessageError("Please enter account name!");
      } else if (!secretKey || secretKey.trim() === "") {
        setMessageError("Please enter secret key!");
      } else if (!publishKey || publishKey.trim() === "") {
        setMessageError("Please enter publish key!");
      } else {
        setMessageError("");
        setShowLoading(true);
        const req = await StripeOwnerApi.addAccountStripe(
          UserIdStore,
          secretKey,
          publishKey,
          accountName
        );
        if (Number(req?.status) === 1) {
          setShowLoading(false);
          setNextPageAddAccount(true);
          gListAccount();
        } else {
          setShowLoading(false);
          setMessageError(req?.message ?? "The system is maintenance");
        }
        dispatch(flagSlice.clearSetting());
      }
    }
  };

  const SubmitDoordash = async () => {
    if (UserIdStore) {
      if (!developerId || developerId.trim() === "") {
        setMessageErrorDoordash("Please enter developer Id!");
      } else if (!keyId || keyId.trim() === "") {
        setMessageErrorDoordash("Please enter key id!");
      } else if (!signingSecret || signingSecret.trim() === "") {
        setMessageErrorDoordash("Please enter signing Secret!");
      } else {
        setMessageErrorDoordash("");
        setShowLoading(true);
        const req = await gConfigDoordashApi.AddConfigDoordash(
          UserIdStore,
          developerId,
          keyId,
          signingSecret
        );
        if (Number(req?.status) === 1) {
          setShowLoading(false);
        } else {
          setShowLoading(false);
          setMessageErrorDoordash(req?.message ?? "The system is maintenance");
        }
        dispatch(flagSlice.clearSetting());
      }
    }
  };
  useEffect(() => {
    setShowLoading(true);
    gListAccount();
    gConfigDoordash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openNewTab = (url: string) => {
    window.open(url, "_blank");
  };
  useEffect(() => {
    if (nextPageAddAccount) {
      setAccountName("");
      setSecretKey("");
      setPublishKey("");
    }
  }, [nextPageAddAccount]);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="w-100">
        <ModalOTPDeleteAccount
          show={showModalOTPDeleteAccount}
          handleClose={dismisModalOTPDeleteAccount}
          UserIdStore={UserIdStore}
          idAccount={idAccount}
        />
        <div className="box-connect-stripe p-5 h-100 overflow-y-scroll w-100">
          <div className={`${nextPageAddAccount ? "" : "d-none"}`}>
            <p className="text-add-new-payment">
              {languageUI.addNewPaymentGateway}
            </p>
            <div className="w-100 d-flex gap-3">
              <div className="w-100">
                <p className="text-add-new-payment-content mb-0">
                  {languageUI.toReceivePaymentForm}
                </p>
                <p className="text-add-new-payment-content mb-0">
                  {languageUI.weChoseThisCompany}
                </p>
                <p className="text-add-new-payment-content mb-0">
                  {languageUI.toConnectToStripe}
                </p>
                <div className="ps-2">
                  <p className="text-add-new-payment-content mb-0">
                    {languageUI.onestCreateAccount}
                  </p>
                  <p className="text-add-new-payment-content mb-0">
                    {languageUI.twondAddStripe}
                  </p>
                </div>
              </div>
              <div
                className="btn-add-payment-gateway flex-shink-0 gap-2 "
                onClick={() => setNextPageAddAccount(false)}
              >
                <AddIcon />
                <p className="mb-0 text-center">
                  {languageUI.addNewPaymentGateway}
                </p>
              </div>
            </div>
            <p className="text-add-new-payment">{languageUI.accountDefault}</p>
            <div className="d-flex flex-column gap-2">
              {listAccount.map(
                (item2, i) =>
                  Number(item2?.is_default) === 1 && (
                    <div className="box-account-payment" key={i}>
                      <div className="account-payment ps-2 d-flex gap-3 align-items-end">
                        <p className="name-account-payment mb-0">
                          {item2?.account_name}
                        </p>
                        <div className="d-flex gap-1 align-items-end">
                          <CheckIcon className="text-005f9f" />
                          <p className="name-account-connected mb-0">
                            connected
                          </p>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            <p className="text-add-new-payment mt-2">{languageUI.account}</p>
            <div className="d-flex flex-column gap-2">
              {listAccount.map(
                (item, i) =>
                  Number(item?.is_default) !== 1 && (
                    <div className="box-account-payment" key={i}>
                      <div className="account-payment ps-2">
                        <p className="name-account-payment mb-0">
                          {item?.account_name}
                        </p>
                        <div
                          className="box-option-account-payment"
                          onClick={() => {
                            setIdAccount(item?.id);
                            setShowModalOTPDeleteAccount(true);
                          }}
                        >
                          <DeleteForeverIcon />
                        </div>
                        <div
                          className="box-option-account-payment-2"
                          onClick={() => {
                            setIdAccount(item?.id);
                            setShowModalSetDefault(true);
                          }}
                        >
                          {/* <img src={pencilEdit} alt="" /> */}
                          <ModeEditOutlineOutlinedIcon />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
            {/* connect doordash */}
            <div className="w-100 d-flex flex-column align-items-center d-none">
              <div className="w-100">
                <p className="text-add-new-payment ww">
                  {languageUI.connectDoordash}
                </p>
                <div className="account-payment ps-2 d-flex gap-3 align-items-end">
                  <p className="name-account-payment mb-0">{nameDoordash}</p>
                  <div className="d-flex gap-1 align-items-end">
                    <CheckIcon className="text-005f9f" />
                    <p className="name-account-connected mb-0">connected</p>
                  </div>
                </div>
              </div>
              <div className="d-flex  w-fit mt-3 items-center">
                <div className="p-4  shadow-sm w-fitContent radius-2 bg-white">
                  <p className="text-add-new-payment-content ">
                    Name(For Your Reference)
                  </p>
                  <div className="pb-2">
                    <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                      <input
                        type="text"
                        className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                        value={nameDoordash}
                        onChange={(e) => {
                          setNameDoordash(e.target.value);
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>
                  <p className="text-add-new-payment-content ">Developer id</p>
                  <div className="pb-2">
                    <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                      <input
                        type="text"
                        className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                        value={developerId}
                        onChange={(e) => {
                          setDeveloperId(e.target.value);
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>

                  <p className="text-add-new-payment-content ">Key id</p>
                  <div className="pb-2">
                    <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                      <input
                        type="text"
                        className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                        value={keyId}
                        onChange={(e) => {
                          setKeyId(e.target.value);
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>
                  <p className="text-add-new-payment-content ">
                    Signing secret
                  </p>
                  <div className="pb-2">
                    <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                      <input
                        type="text"
                        className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                        value={signingSecret}
                        onChange={(e) => {
                          setSigningSecret(e.target.value);
                          ChangeSetting();
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center text-danger text-italic">
                    {messageErrorDoordash}
                  </div>

                  <div className="flex items-center justify-between px4">
                    <a
                      onClick={() =>
                        openNewTab("https://www.doordash.com/consumer/login/")
                      }
                      className="text-0FA54A font-urbansist fw-600"
                    >
                      {languageUI.registerAccount}
                    </a>
                    <button
                      className="border-none bg-00a15a text-white font-urbansist fw-600 text-4 radius-2 py-2 px-4"
                      onClick={SubmitDoordash}
                    >
                      {languageUI.save}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${nextPageAddAccount ? "d-none" : ""}`}>
            <div
              className="btn-back-connect-stripe"
              onClick={() => setNextPageAddAccount(true)}
            >
              <ArrowBackIosIcon />
              {languageUI.connectStripe}
            </div>
            <p className="text-add-new-payment">
              {languageUI.addNewAccPaymentGateway}
            </p>
            <div className="flex justify-center w-full mt-3">
              <div className="p-4  shadow-sm w-fitContent radius-2 bg-white">
                <p className="text-add-new-payment-content ">
                  {languageUI.enterNameAccount}
                </p>
                <div className="pb-2">
                  <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                    <input
                      type="text"
                      className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                      value={accountName}
                      onChange={(e) => {
                        setAccountName(e.target.value);
                        ChangeSetting();
                      }}
                    />
                  </div>
                </div>

                <p className="text-add-new-payment-content ">Secret key</p>
                <div className="pb-2">
                  <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                    <input
                      type="text"
                      className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                      value={secretKey}
                      onChange={(e) => {
                        setSecretKey(e.target.value);
                        ChangeSetting();
                      }}
                    />
                  </div>
                </div>
                <p className="text-add-new-payment-content ">Public key</p>
                <div className="pb-2">
                  <div className="w-400px h-60px p-0 px-2 box-input-account-payment">
                    <input
                      type="text"
                      className="h-100 w-100 border-none forcus-visiable-none bg-transparent"
                      value={publishKey}
                      onChange={(e) => {
                        setPublishKey(e.target.value);
                        ChangeSetting();
                      }}
                    />
                  </div>
                </div>
                <div className="text-center text-danger text-italic">
                  {messageError}
                </div>

                <div className="flex items-center justify-between px4">
                  <a
                    onClick={() =>
                      openNewTab("https://dashboard.stripe.com/register")
                    }
                    className="text-0FA54A font-urbansist fw-600"
                  >
                    {languageUI.registerAccount}
                  </a>
                  <button
                    className="border-none bg-00a15a text-white font-urbansist fw-600 text-4 radius-2 py-2 px-4"
                    onClick={SubmitAdd}
                  >
                    {languageUI.submit}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmAccountDefault
        handleClose={dismisModalSetDefault}
        show={showModalSetDefault}
        handleSubmit={setDefaultAccount}
      />
    </>
  );
};

export default memo(ConectStripe);
