import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "../LeftMenuv2/LeftSidebarv2";
import "./Styles.css";
import Topbar from "../Topbar";
import { useEffect, useState } from "react";
import LeftMenuAdmin from "../LeftMenuAdmin/LeftMenuAdmin";
import React from "react";
import TopbarAdmin from "../TopbarAdmin";

const AppLayoutAdmin = () => {
  const location = useLocation();
  const [pageName, setPageName] = useState("");
  const sidebarNavOwnerItems = [
    {
      display: "Account Staff",
      to: "/web-admin",
      section: ["/web-admin"],
    },
    {
      display: "Customer / Overview",
      to: "/web-admin/customer-overview-admin",
      section: ["/web-admin/customer-overview-admin"],
    },
    {
      display: "Customer / Group Customer",
      to: "/web-admin/group-customer-admin",
      section: ["/web-admin/group-customer-admin"],
    },

    {
      display: "Customer / Create Customer",
      to: "/web-admin/create-customer-admin",
      section: ["/web-admin/create-customer-admin"],
    },
    {
      display: "Customer / Device Customer",
      to: "/web-admin/device-customer",
      section: ["/web-admin/device-customer"],
    },
    {
      display: "Customer / Device Customer / Customer Detail",
      to: "/web-admin/customer-device-detail",
      section: ["/web-admin/customer-device-detail"],
    },
    {
      display: "Customer / Device Customer / Customer Detail/ Infor",
      to: "/web-admin/infor-device-order",
      section: ["/web-admin/infor-device-order"],
    },
    {
      display: "Customer / Feedback",
      to: "/web-admin/feedback",
      section: ["/web-admin/feedback"],
    },
    {
      display: "Setting / Change Password",
      to: "/web-admin/setting-change-password",
      section: ["/web-admin/setting-change-password"],
    },
    {
      display: "Setting / Update Phone Number",
      to: "/web-admin/setting-update-phone-number",
      section: ["/web-admin/setting-update-phone-number"],
    },
    {
      display: "Subcription",
      to: "/web-admin/subscription-admin",
      section: ["/web-admin/subscription-admin"],
    },
    {
      display: "Account Information",
      to: "/web-admin/acccount-infor",
      section: ["/web-admin/acccount-infor"],
    },
    {
      display: "Customer / QR Code",
      to: "/web-admin/qrcode",
      section: ["/web-admin/qrcode"],
    },
    {
      display: "Setting Information ",
      to: "web-admin/edit-setting-customer-infor",
      section: ["web-admin/edit-setting-customer-infor"],
    },
    {
      display: "Review",
      to: "/web-owner/review",
      section: ["/web-owner/review", "/web-owner/detailreview"],
    },
    {
      display: "Inventory",
      to: "/web-owner/inventory",
      section: ["/web-owner/inventory"],
    },
    {
      display: "Staffs",
      to: "/web-owner/staffs",
      section: [
        "/web-owner/staffs",
        "/web-owner/creatstaffs",
        "/web-owner/editstaffs",
      ],
    },
    {
      display: "Set up Voucher",
      to: "/web-owner/setupvoucher",
      section: ["/web-owner/setupvoucher"],
    },
    {
      display: "Subscription",
      to: "/web-owner/subscription",
      section: ["/web-owner/subscription"],
    },
    {
      display: "Feedback",
      to: "/web-owner/feedback",
      section: ["/web-owner/feedback"],
    },
  ];
  useEffect(() => {
    const path = sidebarNavOwnerItems.filter((item) =>
      item.section.some((nav) => window.location.pathname.search(nav) > -1)
    );
    setPageName(path.length > 1 ? path[1]?.display : path[0]?.display);
    console.log(
      sidebarNavOwnerItems.filter((item) =>
        item.section.some((nav) => window.location.pathname.search(nav) > -1)
      )
    );
  }, [location]);

  return (
    <div
      className="custom-root"
      style={{
        padding: "0px 0px 0px 190px",
      }}
    >
      <TopbarAdmin pageName={pageName} />
      <LeftMenuAdmin />
      <Outlet />
    </div>
  );
};

export default AppLayoutAdmin;
