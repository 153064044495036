import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// actions
import { resetAuth } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";

// components
import * as Request from "../../api/Request";
import { useParams } from "react-router";
import React from "react";
import { getLanguageCheckIn } from "../../Services/languageCheckin";


const EnterFirstName = () => {
  const languageUI = getLanguageCheckIn();
  const dispatch = useDispatch<AppDispatch>();
  const [validated, setValidated] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const navigate = useNavigate();

  const nameStore = localStorage.getItem("namestore");
  const [input_day_of_birth, setInput_day_of_birth] = useState<number>(0);
  const [show_customer_point, setShow_customer_point] = useState<number>(0);
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const params: any = useParams();
  let id_customer = params.id;

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const location = useLocation();

  // Get info customer
  const gInfoCustomer = async (id_customer: any) => {
    await Request.fetchAPI(`checkin/gInfoCustomer.php?id=${id_customer}`, "get")
      .then((res) => {
        if (Number(res.status) === 1) {
          getInforOwner(res?.data?.admin_id);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  //Get info owner
  const getInforOwner = async (id_owner: any) => {
    await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
      (res) => {
        if (Number(res.status) === 1) {
          setInput_day_of_birth(res?.data?.input_day_of_birth);
          setShow_customer_point(res?.data?.show_customer_point);
        }
      }
    );
  };

  const handleSubmit = () => {
    if (firstName === "") {
      setCheckError(true);
      setErMess(languageUI.Pleaseenteryourfullname);
      return;
    }
    addUserName(firstName, id_customer);
  };

  const addUserName = async (firstName: any, id_customer: any) => {
    await Request.fetchAPI(`/checkin/uNameCustomer.php`, "post", {
      username: firstName,
      customer_id: id_customer,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          if (Number(input_day_of_birth) === 0) {
            navigate(`/webcheckin/enterbirthday/${id_customer}`);
          } else if (Number(input_day_of_birth) === 1 && Number(show_customer_point) === 1) {
            navigate(`/webcheckin/choosemenu/${id_customer}`);
          } else {
            navigate(`/webcheckin/showpointcheckin/${id_customer}`);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  let bodyEl = document?.querySelector("body");
  bodyEl?.classList.remove("pb-0", "m-0", "birthday-page");

  useEffect(() => {
    gInfoCustomer(id_customer);
  }, [location.pathname, id_customer]);

  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">{nameStore}</h4>

                  <Form
                    noValidate
                    validated={validated}
                    className="text-center"
                  >
                    <Form.Group className="mb-3" controlId="validationCustom03">
                      <Form.Label>{languageUI.Fullname}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={languageUI.EnterFullName}
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <p style={{ color: "#f1556c", textAlign: "center" }}>
                        {checkError ? errMess : ""}
                      </p>{" "}
                    </Form.Group>
                    <Button
                      variant="outline-primary"
                      className="waves-effect waves-light mb-4 mt-2"
                      onClick={handleSubmit}
                    >
                      {languageUI.go}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EnterFirstName;
