import { useCallback, useEffect, useState } from "react";
import { Form, InputGroup, ProgressBar, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Request from "../../api/Request";
import Swal from "sweetalert2";

// actions
import { resetAuth } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";

// components
import FoodMenuItem from "./components/FoodMenuItem";
import { targetupload } from "../../api/urlImage";
import { useParams } from "react-router";
import NoData from "../../assets/images/not-found-file.svg";
import "./Styles.css";
import { gNumCart, gNumFoodNotPay } from "../../api/apiCheckin/addCartApi";
import { billIcon, chevronRightWhite } from "../../components/ImgExport";
import * as chooseMenuApi from "../../api/apiCheckin/chooseMenuApi";
import React from "react";
import HistoryIcon from "@mui/icons-material/History";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import * as menuPosApi from "../../api/apiOwner/menuPosApi";
import socket from "../../utils/socket";

interface FoodMenuProps {
  id?: number;
  image?: any;
  foodNumber?: number;
  name?: string;
  price?: number;
}
const ChooseMenu = () => {
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [listMenu, setListMenu] = useState([]);
  const nameStore = localStorage.getItem("namestore");

  const [foodMenu, setFoodMenu] = useState<FoodMenuProps[]>(
    JSON.parse(localStorage.getItem("foodMenu") || "[]")
  );
  const [searchFood, setSearchFood] = useState("");
  const [searchFoodGroup, setSearchFoodGroup] = useState("");
  const [foodTotal, setFootTotal] = useState<number>(0);
  const [foodTotalPay, setFootTotalPay] = useState<number>(0);

  const [ownerID, setOwnerID] = useState<any>("");
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [hiddenBackState, setHiddenBackState] = useState<boolean>(true);

  const [showHappyHour, setShowHappyHour] = useState<boolean>(false);
  const [timeExpiration, setTimeExpiration] = useState<number>();
  const [infoHappyhour, setInfoHappyhour] =
    useState<menuPosApi.InfoHappyHour>();
  const [listFoodSell, setListFoodSell] = useState<menuPosApi.Menu[]>([]);
  const [now, setNow] = useState(10);
  useEffect(() => {
    if (now < 90) {
      setTimeout(() => {
        setNow(now + 10);
      }, 100);
    }
  }, [now]);
  const params: any = useParams();
  const customerID = params.id;

  //gHappyHour
  const gShowHappyHour = async () => {
    if (ownerID) {
      const req = await menuPosApi.gHappyHour(ownerID);
      if (Number(req?.status) === 1) {
        setInfoHappyhour(req?.data);
        setTimeExpiration(Number(req?.time_difference));
        setListFoodSell(req?.listFood ?? []);
        setShowHappyHour(true);
      } else {
        setInfoHappyhour(undefined);
        setTimeExpiration(0);
        setListFoodSell([]);
        setShowHappyHour(false);
      }
    }
  };
  useEffect(() => {
    if (timeExpiration)
      setTimeout(() => {
        if (timeExpiration > 0) {
          setTimeExpiration(timeExpiration - 1);
        } else {
          setInfoHappyhour(undefined);
          setTimeExpiration(0);
          setListFoodSell([]);
          setShowHappyHour(false);
        }
      }, 1000);
  }, [timeExpiration]);
  const pushReloadHappyHour = useCallback(async () => {
    // await socket.on("pushReloadHappyHour" + ownerID, (data: any) => {
    var pathName = window.location.pathname;
    if (pathName.search("/web-owner/posmenu") > -1) {
      gShowHappyHour();
    }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  //Get info customer
  const gInfoCustomer = async (id_customer: any) => {
    await Request.fetchAPI(`checkin/gInfoCustomer.php?id=${id_customer}`, "get")
      .then((res) => {
        if (Number(res.status) === 1) {
          setOwnerID(res?.data?.admin_id);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  // search
  const handleSearch = async (clear: boolean = false) => {
    await Request.fetchAPI(
      `/checkin/gListMenu.php?owner_id=${ownerID}&foodgroup_id=${
        clear ? "" : searchFoodGroup
      }&food_name=${searchFood}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        setListMenu(res.data);

        if (searchFood === "") {
          setHiddenBackState(true);
        } else {
          setHiddenBackState(false);
        }
      }
    });
  };

  useEffect(() => {
    if (searchFoodGroup !== "") {
      handleSearch();
    }
  }, [searchFoodGroup]);

  const handleOnchageFoodName = (e: any) => {
    setSearchFood(e?.target.value);
  };

  const getListMenuFood = async (ownerID: any) => {
    await Request.fetchAPI(
      `/checkin/gListMenu.php?owner_id=${ownerID}`,
      "get"
    ).then((res) => {
      if (Number(res?.status) === 1) {
        setListMenu(res.data);
      }
    });
  };

  const gDiscountHappyHourByFood = (food_id: string) => {
    return Number(infoHappyhour?.status) === 0
      ? 1
      : listFoodSell.filter((item) => Number(item.id) === Number(food_id))[0]
          ?.discount ?? 0;
  };
  const handleDecrece = (id: any) => {
    const updateFoodMenuNumber = [...foodMenu];
    for (let i = 0; i <= foodMenu.length; i++) {
      if (Number(updateFoodMenuNumber[i]?.id) === Number(id)) {
        updateFoodMenuNumber[i].foodNumber =
          Number(updateFoodMenuNumber[i]?.foodNumber) + 1;
      }
    }
    setFoodMenu(updateFoodMenuNumber);
  };

  //add food to shipping
  const handleAddToCart = (
    menu_id: any,
    image: any,
    name: string,
    price: any
  ) => {
    var item: FoodMenuProps = {
      id: menu_id,
      image: image,
      name: name,
      foodNumber: 1,
      price: price,
    };

    const checkId = foodMenu.find(
      (listMenu) => Number(listMenu.id) === Number(item.id)
    );
    if (checkId === undefined) {
      setFoodMenu((data) => [...data, item]);
    } else handleDecrece(item.id);

    Swal.fire({
      position: "top-end",
      icon: "success",
      title: languageUI.Addedyourchoice,
      showConfirmButton: false,
      timer: 1500,
    });
    localStorage.setItem("foodMenu", JSON.stringify(foodMenu));
  };

  //get num cart
  const CountNumCart = async () => {
    var table_id = localStorage.getItem("table_id");
    if (table_id) {
      const num = await gNumCart(customerID, ownerID, table_id);
      setFootTotal(Number(num?.total ?? 0));
    }
  };
  //get số sản ohaamr chờ thanh toán
  const CountNumFood = async () => {
    var table_id = localStorage.getItem("table_id");
    if (table_id) {
      const num = await gNumFoodNotPay(customerID, ownerID, table_id);
      setFootTotalPay(Number(num?.total ?? 0));
    }
  };

  useEffect(() => {
    if (customerID) gInfoCustomer(customerID);
  }, [customerID]);

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  useEffect(() => {
    if (ownerID) {
      gShowHappyHour();
      // pushReloadHappyHour();
      getListMenuFood(ownerID);
      gListFoodCategory(ownerID);
      CountNumCart();
      CountNumFood();
      if (!ownerID) {
        return;
      }
      const eventName = "PushShowNotification" + ownerID;

      socket.on(eventName, pushReloadHappyHour);

      return () => {
        socket.off(eventName, pushReloadHappyHour);
      };
    }
  }, [ownerID]);

  //////////////
  const gListFoodCategory = async (ownerID: any) => {
    const Category = await chooseMenuApi.gFoodCateGory(ownerID);
    if (Number(Category.status) === 1) {
      if (Category?.data && Category?.data?.length > 0) {
      }
      setShowLoadingPage(false);
      setNow(100);
    } else {
      setShowLoadingPage(false);
      setNow(100);
    }
  };
  const handleClearSearch = async () => {
    setHiddenBackState(true);
    setSearchFood("");
    setSearchFoodGroup("");
    await Request.fetchAPI(
      `/checkin/gListMenu.php?owner_id=${ownerID}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        setListMenu(res.data);
      }
    });
  };

  return showLoadingPage ? (
    <div className="w-100 h-100 bg-wait-screen">
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{ minHeight: "100vh" }}
      >
        <h3 className="name-store text-white ">{nameStore}</h3>
        <ProgressBar variant="warning" now={now} className="w-50" />
      </div>
    </div>
  ) : (
    <>
      {/* Menubody */}
      <header className="w-100 custom-header-choose-menu d-flex justify-content-between align-items-center gap-2">
        {/* <img src={logoPrijack} alt="" /> */}
        <p className="title-name-store mb-0 text-truncate">{nameStore}</p>
        <div
          className=" px-4 py-0 bg-white d-flex flex-column items-center rounded-4 border"
          onClick={() => navigate("/webcheckin/historyOrder")}
        >
          <HistoryIcon className="text-orange" />
          <p className="history-order text-orange">History Orders</p>
        </div>
      </header>
      <div className="account-pages d-flex box-menu-food-order  custom-pading-header bg-white">
        <div className="px-0 px-sm-0 px-md-3 px-lg-4  position-relative pt-0 bg-white">
          <div className="px-2 px-sm-2 px-md-3 px-lg-4 pb-footer">
            <Row>
              <div className="d-flex gap-2 align-items-start justify-content-between">
                <p className="text-header mt-3">
                  {languageUI.exploreUniqueFlavors}
                  <br />
                  {languageUI.atOurRestaurant} 🍲
                </p>
              </div>
              <div className="col-12 col-425-100 h-fit">
                {/* <div className="mb-2">
                        <Select
                          className="react-select react-select-container"
                          classNamePrefix="react-select"
                          defaultValue={[""]}
                          placeholder="Choose food group"
                          isClearable={true}
                          value={listSelectFoodGR.filter(item => item?.id == searchFoodGroup).map((item1) => {
                            return {
                              value: item1.id,
                              label: item1.name,
                            };
                          })}
                          options={listSelectFoodGR.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })}
                          onChange={handleOnchageFoodGroup}
                        ></Select>
                      </div> */}
              </div>
              <div className="col-12 col-425-100 mt-0 ">
                <div className="rounded-6px overflow-hidden bg-F6F6F9 border">
                  <InputGroup className="h-40px">
                    <Form.Control
                      placeholder={languageUI.Whatareyoulookingfor}
                      className="border-none bg-F6F6F9 text-8E8EA9 focus-none h-40px"
                      value={searchFood}
                      onChange={handleOnchageFoodName}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          handleSearch();
                        }
                      }}
                    />
                    <InputGroup.Text className=" border-none bg-F6F6F9 h-40px">
                      <button
                        className="bg-F6F6F9"
                        style={{ border: "none", background: "none" }}
                        onClick={() => handleSearch()}
                      >
                        <div className="d-flex align-items-center">
                          <p className="mb-0 me-1 text-name-food text-8E8EA9">
                            {languageUI.Search}
                          </p>
                          <i className="fas fa-search text-8E8EA9"></i>
                        </div>
                      </button>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
            </Row>
            {/* <div className="d-flex gap-2 mt-2 mb-2">
                    {categoryActive.map((items, i) => (
                      <div key={i} className={`text-filter  flex-shink-0 border-1px-primary rounded-21px h-40px w-30  d-flex justify-content-center align-items-center bg-white text-4A4A6A ${CategoryChoosed == items.id ? "bg-primary text-white" : ""}`} onClick={() => { setCategoryChoosed(items.id ?? ''); setSearchFoodGroup(items.id ?? '') }}>
                        <p className={`m-0 text-xs text-4A4A6A ${CategoryChoosed == items.id ? "bg-primary text-white" : ""}`}>{items.name}</p>
                      </div>
                    ))}
                    {listSelectFoodGR.length > 2 && (
                      <div className={`text-filter  flex-shink-0 border-1px-primary rounded-21px h-40px w-30  d-flex justify-content-center align-items-center bg-white text-4A4A6A`} onClick={() => { setShowViewMore(true) }}>
                        <p className={`m-0 text-xs text-4A4A6A `}>ViewMore +</p>
                      </div>
                    )}

                  </div> */}
            <div
              className="btn-clear-search-food mt-2 px-2"
              hidden={hiddenBackState}
              onClick={handleClearSearch}
            >
              <img src={chevronRightWhite} className="rotate-180deg" alt="" />
              {languageUI.Back}
              <img
                src={chevronRightWhite}
                className="rotate-180deg opacity-0"
                alt=""
              />
            </div>
            {listMenu.length > 0 ? (
              <>
                {listMenu.map((menu: any, index: number) => (
                  <div key={index}>
                    <h4 className="header-title mb-0 mt-3 px-2 font-bold text-2xl text-capitalize text-black">
                      {menu.foodgroup_name}
                    </h4>
                    <div className="box-menu-in-phone">
                      {menu.list_menu.map((food: any) => (
                        <FoodMenuItem
                          rank={food.rank}
                          ordersServed={food.ordersServed}
                          key={food.menu_id}
                          menu_id={food?.menu_id}
                          customerID={customerID}
                          ownerID={ownerID}
                          img={`${targetupload}${food.imageCourse}`}
                          side_id={food.side_id}
                          name={food.menu_name}
                          description={food.description}
                          // price={Number(food.price)}
                          price={
                            showHappyHour
                              ? Number(infoHappyhour?.status) === 0
                                ? Number(food.price) *
                                  (1 - Number(infoHappyhour?.discount) / 100)
                                : Number(food.price) *
                                  (1 -
                                    Number(
                                      gDiscountHappyHourByFood(
                                        food.menu_id ?? ""
                                      )
                                    ) /
                                      100)
                              : Number(food.price)
                          }
                          priceOld={Number(food.price)}
                          foodTotal={foodTotal}
                          setFootTotal={(num) => setFootTotal(num)}
                          link="#"
                          showHappyHour={Number(
                            gDiscountHappyHourByFood(food.menu_id ?? "")
                          )}
                          handleAddToCart={() =>
                            handleAddToCart(
                              food.menu_id,
                              food.imageCourse,
                              food.menu_name,
                              food.price
                            )
                          }
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <div className="w-100 mt-2">
                  <p className="text-center gap-1">
                    <p className="d-inline mb-0 me-1">{languageUI.Powerby}</p>
                    <p className="d-inline mb-0 text-blue text-underline">
                      Brijack POS
                    </p>
                  </p>
                </div>

                <div className="footer-choose-menu d-flex gap-2 align-items-center py-3 w-100 bg-white px-3 border-top">
                  {foodTotalPay > 0 && (
                    <div className="position-relative">
                      <span
                        onClick={() => navigate("/webcheckin/payment")}
                        className="w-100"
                      >
                        <img
                          src={billIcon}
                          alt=""
                          className="height-49px width-30px"
                        />
                        <div className="number-cart-2">{foodTotalPay}</div>
                      </span>
                    </div>
                  )}

                  {/* <div className="position-relative" onClick={() => navigate('/webcheckin/cardInformation')}>
                          <img src={cart} alt="" />
                        </div> */}
                  <span
                    className="w-100"
                    onClick={() => navigate("/webcheckin/cardInformation")}
                  >
                    <button className="custom-filter-btn border-none w-100 text-ffffff">
                      <div className="number-cart">{foodTotal}</div>
                      {languageUI.Revieworder}
                    </button>
                  </span>
                </div>
              </>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <img src={NoData} alt="" />
                <p className="text-2xl text-green font-medium">
                  {languageUI.Nodata}
                </p>
                {/* <div className="btn-clear-search-food mt-2 px-2" onClick={handleClearSearch}>
                        <img src={chevronRightWhite} className="rotate-180deg" alt="" />
                        Back
                        <img src={chevronRightWhite} className="rotate-180deg opacity-0" alt="" />

                      </div> */}
              </div>
            )}
          </div>
        </div>
        {/* <ModalViewMore disableOnClose={dismisModalViewMore} show={showViewMore} listSelectFoodGR={listSelectFoodGR} CategoryChoosed={CategoryChoosed} handleOnchageFoodGroup={handleOnchageFoodGroup} /> */}
      </div>
    </>
  );
};

export default ChooseMenu;
