import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { plusWhite } from "../../components/ImgExport";
import ModalLoading from "../../components/ModalLoading";
import "./Styles.css";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import * as giftCodeVoucher from "../../api/apiOwner/giftCodeVoucher";
import { getLanguageUI } from "../../Services/languageUI";
import ContentTablePOSSystem from "./components/POSComponent/ContentTablePOSSytem";
import ModalAddPosSystem from "./components/GiftCard/ModalAddPosSystem/ModalAddPosSystem";
import ModalEditPosSystem from "./components/GiftCard/ModalEditPosSystem/ModalEditPosSystem";
import {
  addNewPosDevice,
  deletePos,
  editNewPosDevice,
  gListPosByOwner,
  ListPOS,
} from "../../api/apiOwner/PosAPI";
import ModalDeletePOS from "./components/GiftCard/ModalDeletePOS/ModalDeletePOS";
import { Form } from "react-bootstrap";

const POSSystem: React.FC = () => {
  const languageUI = getLanguageUI().giftCard;
  const owner_id = useSelector(userSlice.selectorUserId);
  const [POSItem, setPOSItem] = useState<ListPOS>();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalAddPOS, setShowModalAddPOS] = useState<boolean>(false);
  const [showModaEditPOS, setShowModalEditPOS] = useState<boolean>(false);
  const [showModaDeletePOS, setShowModalDeletePOS] = useState<boolean>(false);
  const [listPosOwner, setListPosOwner] = useState<ListPOS[]>([]);
  const [mainStation, setMainStation] = useState<number>(0);

  const [messErr, setMessErr] = useState<string>("");
  const dismisModalAddPOS = () => {
    setShowModalAddPOS(false);
  };
  const dismisModalEditPOS = () => {
    setShowModalEditPOS(false);
  };
  const dismisModalDeletePOS = () => {
    setShowModalDeletePOS(false);
    setMessErr("");
  };

  const handleaddNewPosDevice = async () => {
    setShowLoading(true);
    if (owner_id) {
      const req = await addNewPosDevice(owner_id);
      if (req?.status === 1) {
        dismisModalAddPOS();
        getListPosByOwner();
      } else {
        setMessErr(req?.message ?? "");
      }
    }
    setShowLoading(false);
  };

  const getListPosByOwner = async () => {
    setShowLoading(true);
    if (owner_id) {
      const req = await gListPosByOwner(owner_id);
      if (req?.status === 1) {
        setListPosOwner(req?.data ?? []);
      } else {
        setMessErr(req.message ?? "");
      }
    }
    setShowLoading(false);
  };
  const handleEditNewPosDevice = async (
    namePOS: string,
    status: number,
    pos_id: number
  ) => {
    setShowLoading(true);
    if (owner_id) {
      const req = await editNewPosDevice(owner_id, namePOS, status, pos_id);
      if (req?.status === 1) {
        dismisModalEditPOS();
        getListPosByOwner();
      } else {
        setMessErr(req?.message ?? "");
      }
    }
    setShowLoading(false);
  };
  const handleDeletePos = async (pos_id: number) => {
    setShowLoading(true);
    if (owner_id) {
      const req = await deletePos(owner_id, pos_id);
      if (req?.status === 1) {
        dismisModalDeletePOS();
        getListPosByOwner();
      } else {
        setMessErr(req.message ?? "");
      }
    }
    setShowLoading(false);
  };
  const handleDelete = (item: ListPOS) => {
    setShowModalDeletePOS(true);
    setPOSItem(item);
  };
  const handleEditPOS = (item: ListPOS) => {
    setShowModalEditPOS(true);
    setPOSItem(item);
  };

  useEffect(() => {
    getListPosByOwner();
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="px-3 pt-90px  h-100vh pb-5  w-100">
        <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory ">
          <div className="d-flex justify-content-between align-items-center py-7 px-6">
            <p className="mb-0 text-inventory">Station</p>
            <div className="btn-create-voucher" onClick={handleaddNewPosDevice}>
              <img src={plusWhite} alt="" />
              Create Station
            </div>
          </div>
          <div>
            <TableContainer
              className="border-top"
              sx={{ maxHeight: "calc(100vh - 250px)" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className="text-12B064 text-4 fw-600 font-urbansist w-10"
                    >
                      {languageUI.noNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist "
                    >
                      Station
                    </TableCell>

                    <TableCell
                      align="left"
                      className="text-12B064 text-4 fw-600 font-urbansist"
                    >
                      Created at
                    </TableCell>
                    <TableCell
                      align="center"
                      className="text-12B064 text-4 fw-600 font-urbansist"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="body-pos-station">
                  {listPosOwner.map((item, index) => (
                    <ContentTablePOSSystem
                      dateCreated={item.timestamp}
                      namePOS={item.pos_name}
                      no={index + 1}
                      status={item.status}
                      idPOS={item.pos_id}
                      handleDeletePOS={() => {
                        handleDelete(item);
                      }}
                      handleEditPOS={() => {
                        handleEditPOS(item);
                      }}
                      key={index}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ModalAddPosSystem
        handleClose={dismisModalAddPOS}
        show={showModalAddPOS}
        setShowLoading={setShowLoading}
        HandleCreatSuccess={handleaddNewPosDevice}
        messErr={messErr}
      />
      <ModalEditPosSystem
        namePOS={POSItem?.pos_name ?? ""}
        typePos={POSItem?.status ?? 1}
        idPos={POSItem?.pos_id ?? 0}
        handleClose={dismisModalEditPOS}
        show={showModaEditPOS}
        setShowLoading={setShowLoading}
        HandleCreatSuccess={handleEditNewPosDevice}
        messErr={messErr}
      />
      <ModalDeletePOS
        HandleDelSuccess={() => handleDeletePos(POSItem?.pos_id ?? 0)}
        handleClose={dismisModalDeletePOS}
        setShowLoading={setShowLoading}
        show={showModaDeletePOS}
        messErr={messErr}
      />
    </>
  );
};

export default POSSystem;
