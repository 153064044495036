import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import './styles.css'
import { ArrowBack, LocationOnOutlined, Person2Outlined, PhoneInTalkOutlined } from "@mui/icons-material";
import MaskedInput from "react-text-mask";
import { Checkbox, FormControlLabel } from "@mui/material";
import { addAddressCustomer } from "../../../../api/apiCheckin/addressApi";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { closeLineWhite } from "../../../../components/ImgExport";
import PlaceIcon from '@mui/icons-material/Place';
import CloseIcon from '@mui/icons-material/Close';
import SimpleMap from "./SimpleMap";
import { KeyGoogleMap } from "../../../../api/Request";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalAddAddressProps {
  show: boolean;
  handleClose: () => void
  customer_id: string,
  handleSuccess: (id: string) => void
}
interface ShowMessError {
  fullName: string,
  phoneNumber: string,
  address: string,
  apertment: string,
  city: string,
  state: string,
  zipcode: string,
  unit: string
}
const ModalAddAddress: React.FC<ModalAddAddressProps> = ({ handleClose, show, customer_id, handleSuccess }) => {
  const languageUI = getLanguageCheckIn();
  const [phoneValue, setPhoneValue] = useState('');
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [defaultAddress, setDefaultAddress] = useState<number>(0);
  const [locat, setlocat] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 }); //tọa độ map địa chỉ
  const [isMarkerShown, setIsMarkerShown] = useState<boolean>(false); // maker của map

  const err = {
    fullName: '',
    phoneNumber: '',
    address: '',
    apertment: '',
    city: '',
    state: '',
    zipcode: '',
    unit: ''
  }
  const [messError, setMessError] = useState<ShowMessError>(err)

  const handleCreateAddress = () => {
    const mess = err;
    var check = false
    if (fullName === '') {
      mess.fullName = languageUI.Fullnameisrequire;
      check = true
    }
    if (phoneValue === '') {
      mess.phoneNumber = languageUI.Phonenumberisrequire;
      check = true
    } else if (phoneValue.length !== 10) {
      mess.phoneNumber = languageUI.Phonenumberisnotinthecorrectformat;
      check = true
    }

    if (address === '' || (locat.lat === 0 && locat.lng === 0)) {
      mess.address = languageUI.Addressisrequire;
      check = true
    }

    if (check) {
      setMessError({ ...mess })
    } else {
      setMessError({ ...err })
      addAddressCustomerHandle()
    }
  }
  useEffect(() => {
    if (show) {
      setMessError(err)
    }
  }, [show])

  const addAddressCustomerHandle = async () => {
    let addressFinal = unit !== '' ? unit + ' ' + address : address
    const res = await addAddressCustomer(customer_id, fullName, phoneValue, addressFinal, defaultAddress, locat.lat, locat.lng)
    if (Number(res.status) === 1) {
      handleClose()
      handleSuccess(res.idAddress)
    }
  }

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setlocat(latLng);
    setIsMarkerShown(!isMarkerShown);
  }
  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneValue(phoneReplace);
  };
  useEffect(() => {
    setFullName('')
    setAddress('')
    setUnit('')
  }, [show])
  return (
    <Modal show={show} fullscreen={true} onHide={handleClose}>
      <Modal.Header className="shadow-header border-bottom">
        <div className=" px-4 py-2 rounded-6 border-FF7B2C" onClick={handleClose}>
          <ArrowBack className="text-FF7B2C" />
        </div>
        <Modal.Title className="text-center text-414151f font-bold text-20px">{languageUI.Addnewaddress}</Modal.Title>
        <div className=" px-4 py-2 rounded-6 border-FF7B2C opacity-0" >
          <ArrowBack className="text-FF7B2C" />
        </div>
      </Modal.Header>
      <Modal.Body color="white">
        <div className="">
          <div className="d-flex justify-content-end">
            <FormControlLabel control={<Checkbox color="primary" onChange={(e) => setDefaultAddress(Number(e.target.checked))} className="text-primary" />} label={languageUI.Setasdefault} />
          </div>

          <div className={`d-flex justify-content-between ${messError.fullName !== '' ? 'border-1px-red' : 'border-1px-gray'} rounded-16px`}>
            <input
              type="text"
              className="input-voucher focus-visible-none w-100"
              placeholder={languageUI.Fullname}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          {messError.fullName && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.fullName}</p>)}
          <MaskedInput
            mask={[
              "(",
              /[0-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]}
            placeholder={languageUI.Phonenumber}
            className={`d-flex w-100 mt-2 justify-content-between ${messError.phoneNumber !== '' ? 'border-1px-red' : 'border-1px-gray'} rounded-16px phone-order-online`}
            value={phoneValue}
            onChange={onchagePhoneNumber}
          />
          {messError.phoneNumber && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.phoneNumber}</p>)}

          <div className={`d-flex justify-content-between ${messError.address !== '' ? 'border-1px-red' : 'border-1px-gray'} rounded-16px mt-2`}>
            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}

            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="w-100">
                  <div className="d-flex align-items-center w-100">
                    <input
                      className="input-voucher focus-visible-none w-100"

                      style={{
                        width: "calc(100% - 25px)",
                      }}
                      {...getInputProps({
                        placeholder: languageUI.Enteryouraddress,
                      })}
                    />
                    {address != "" ? (
                      <CloseIcon className="text-dark font-md"
                        onClick={(e) => {
                          setAddress("");
                        }} />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="dropdown-place position-absolute left-0 right-0 z-index-9 mt-2  px-3">
                    {/* {loading && <div>Loading...</div>} */}
                    {suggestions.map((suggestion) => {
                      const style = suggestion.active
                        ? { backgroundColor: "#a83232", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          className="d-flex align-items-center flex-wrap py-1"
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          <PlaceIcon />
                          <span className="phone-order-online" style={{ maxWidth: "calc(100% - 45px)" }}>
                            {suggestion.description}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className={`d-flex justify-content-between border-1px-gray rounded-16px mt-2`}>
            <input
              type="text"
              className="input-voucher focus-visible-none w-100"
              placeholder={languageUI.Unitsuit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </div>
          {messError.address && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.address}</p>)}
          <div
            className="w-100 object-cover overflow-hidden rounded-sm mt-3"
            style={{ height: 250 }}
          >
            <SimpleMap
              isMarkerShown={true}
              googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + KeyGoogleMap + "&v=3.exp&libraries=geometry,drawing,places"}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              locat={locat}
            />
          </div>
        </div>

      </Modal.Body>
      <Modal.Footer className="box-shadow-footer">
        <div className=" d-flex gap-3 align-items-center w-100">
          <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={handleCreateAddress}>
            {languageUI.confirm}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default ModalAddAddress