import React, { useState } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { pocket } from '../../../../components/ImgExport';
import { Token } from '@stripe/stripe-js';

interface PaymentFormProps {
    handleCreateToken: (card: string | number, token: Token) => void
}
const PaymentForm = ({ handleCreateToken }: PaymentFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [messageError, setMessageError] = useState<string>('')
    const handleSubmit = async (e: any) => {
        e.preventDefault();


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            setMessageError('Make sure to disable form submission until Stripe.js has loaded.')
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        if (cardElement) {
            const { token, error } = await stripe.createToken(cardElement);
            if (error) {
                // Handle errors with the card element
                setMessageError(error?.message ?? '')
                console.error(error);
            } else {
                if (token) {
                    setMessageError('')
                    // Send the token to your server for processing
                    handleCreateToken(token?.card?.last4 ?? '', token);
                } else {
                    setMessageError('Connect fail!')
                }


            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className='m-0 p-0'>
            {messageError !== '' && (
                <div className="text-danger text-center ">{messageError}</div>
            )}
            <div className="row m-0 px-3">
                {/* <CardElement /> */}
                <div className="col-12 mt-2">
                    <div className="w-100 border  rounded-16px  py-3 px-2  border">
                        <label className='w-100'>
                            Card Number
                            <CardNumberElement />
                        </label>

                    </div>
                </div>
                <div className="col-7 mt-2" >
                    <div className="w-100 border w-100 rounded-16px py-3 px-2   border">
                        <label className='w-100'>
                            Expiry Date
                            <CardExpiryElement />
                        </label>
                    </div>

                </div>
                <div className="col-5 mt-2">
                    <div className="w-100 border w-100 rounded-16px py-3 px-2   border">
                        <label className='w-100'>
                            CVC
                            <CardCvcElement />
                        </label>
                    </div>
                </div>

            </div>

            <div className="py-3 px-3 fixed bottom-0 w-100 bg-white border-top d-flex gap-2 align-items-center">
                <button className="custom-filter-btn border-none w-100 text-ffffff" type='submit'>
                    <img src={pocket} alt="" className="img-filter " />
                    Next
                </button>
            </div>
        </form>
    );
};

export default PaymentForm;