import React, { useEffect, useState } from "react";
import { Button, Alert, Row, Col, Form, InputGroup } from "react-bootstrap";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import * as Request from "../../api/Request";
import axios from "axios";

// actions
import { resetAuth, loginUser } from "../../redux/actions";

// store
import { RootState, AppDispatch } from "../../redux/store";

// components
import { VerticalForm, FormInput } from "../../components/";

import AuthLayout from "./AuthLayout";
import ModalLoading from "../../components/ModalLoading";
import { getLanguageUI } from "../../Services/languageUI";
import * as staffJointSlice from "../../store/slices/staffJointSlice";

interface LocationState {
  from?: Location;
}

interface UserData {
  username: string;
  password: string;
}

/* bottom links */
// const BottomLink = () => {
//     const { t } = useTranslation();

//     return (
//         <Row className="mt-3">
//             <Col className="text-center">
//                 <p>
//                     <Link to={'/auth/forget-password'} className="text-white-50 ms-1">
//                         {t('Forgot your password?')}
//                     </Link>
//                 </p>
//                 <p className="text-white-50">
//                     {t("Don't have an account?")}{' '}
//                     <Link to={'/auth/register'} className="text-white ms-1">
//                         <b>{t('Sign Up')}</b>
//                     </Link>
//                 </p>
//             </Col>
//         </Row>
//     );
// };

/* social links */
// const SocialLinks = () => {
//     const socialLinks = [
//         {
//             variant: 'primary',
//             icon: 'facebook',
//         },
//         {
//             variant: 'danger',
//             icon: 'google',
//         },
//         {
//             variant: 'info',
//             icon: 'twitter',
//         },
//         {
//             variant: 'secondary',
//             icon: 'github',
//         },
//     ];
//     return (
//         <>
//             <ul className="social-list list-inline mt-3 mb-0">
//                 {(socialLinks || []).map((item, index) => {
//                     return (
//                         <li key={index} className="list-inline-item">
//                             <Link
//                                 to="#"
//                                 className={classNames(
//                                     'social-list-item',
//                                     'border-' + item.variant,
//                                     'text-' + item.variant
//                                 )}
//                             >
//                                 <i className={classNames('mdi', 'mdi-' + item.icon)}></i>
//                             </Link>
//                         </li>
//                     );
//                 })}
//             </ul>
//         </>
//     );
// };

const Login: React.FC = ({ history }: any) => {
  const languageUI = getLanguageUI().login;
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  // const { user, userLoggedIn, loading, error } = useSelector((state: RootState) => ({
  //     user: state.Auth.user,
  //     loading: state.Auth.loading,
  //     error: state.Auth.error,
  //     userLoggedIn: state.Auth.userLoggedIn,
  // }));

  // useEffect(() => {
  //     dispatch(resetAuth());
  // }, [dispatch]);
  const navigate = useNavigate();

  const [UserName, setUserName] = useState<any>("");
  const [Password, setPassword] = useState<any>("");
  const [Showpass, setShowpass] = useState<boolean>(false);
  const [message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [Hotline, setHotline] = useState<any>("");
  const [PrimaryAction, setPrimaryAction] = useState<any>();
  const [SecondaryAction, setSecondaryAction] = useState<any>();
  const [confirmExit, setConfirmExit] = useState(false);
  const userIdStore = useSelector(userSlice.selectorUserId);
  const userRole = useSelector(userSlice.selectorRole);

  const ReloadForm = () => {
    setUserName("");
    setPassword("");
    setMessage("");
    setShowpass(false);
  };

  const dispatchUser = useDispatch();
  // const UserIdStore = useSelector(userSlice.selectorUserId);
  // // const UserNameStore = useSelector(userSlice.selectorName);
  // console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",UserIdStore);

  const ReduxStoreUser = async (
    id: number,
    role: number,
    nameStore: string = "",
    user_name: string = "",
    tax: number = 0,
    address: string = "",
    phone: string = ""
  ) => {
    await dispatchUser(userSlice.setUserId(id));
    await dispatchUser(userSlice.setRole(role));
    await dispatchUser(userSlice.setNameStore(nameStore));
    await dispatchUser(userSlice.setUserName(user_name));
    await dispatchUser(userSlice.setTax(tax));
    await dispatchUser(userSlice.setAddress(address));
    await dispatchUser(userSlice.setPhoneNumber(phone));
  };

  const LoginByAccount = async () => {
    // console.log("username: ", UserName);
    // console.log("Password: ", Password);
    if (
      !UserName ||
      UserName.trim() == "" ||
      !Password ||
      Password.trim() == ""
    ) {
      // thông báo lỗi dạng inline
      if (!Password || Password.trim() == "") {
        setMessage(languageUI.passWordRequired);
      } else if (!UserName || UserName.trim() == "") {
        setMessage(languageUI.usernameRequired);
      } else {
        setMessage("");
      }
    } else {
      // let formData = new FormData();
      // formData.append("username", UserName);
      // formData.append("password", Password);

      // await fetch("http://192.168.1.142/login_owner.php",
      //     {
      //         body: formData,
      //         method: "post"
      //     }
      // )
      setShowLoading(true);
      await Request.fetchAPI("owner/login_owner.php", "post", {
        username: UserName,
        password: Password,
      })
        .then((result: any) => {
          if (result?.status == 1) {
            //đăng nhập thành công
            setMessage("");
            ReduxStoreUser(
              result?.data[0]?.id,
              result?.data[0]?.role,
              result?.data[0]?.name,
              result?.data[0]?.username,
              result?.data[0]?.tax,
              result?.data[0]?.address,
              result?.data[0]?.phone
            );
            console.log("đăng nhập thành công");
          } else {
            setMessage(result?.message);
          }
        })
        .catch((err: any) => {
          setPrimaryAction("Liên hệ ngay");
          setSecondaryAction("Để sau");
          setMessage(`${languageUI.contactUsAtHotline} ${Hotline}`);
          setConfirmExit(true);
        });
      setShowLoading(false);
    }
    setShowLoading(false);
  };

  const GetInfoOwner = async () => {
    setShowLoading(true);
    await Request.fetchAPI("owner/gInfoOwner.php?id=" + userIdStore, "GET", {})
      .then(async (result: any) => {
        if (result?.status == 1) {
          if (result?.data?.role == 0) {
            await dispatchUser(userSlice.setUserId(0));
            await dispatchUser(userSlice.setUserName(""));
            await dispatchUser(userSlice.clearUser());
            // navigate("/web-admin/account-staff");
          } else if (result?.data?.role == 1) {
            navigate("/web-owner/pos");
          }
          setMessage("");
          // console.log("Thông tin Owner", result?.data);
        } else {
          setMessage(
            result?.message ? result?.message : languageUI.somethingWentWrong
          );
        }
      })
      .catch((err: any) => {
        setMessage(languageUI.anErrorHasOccurred);
      });

    setShowLoading(false);
  };
  /*
    form validation schema
    */
  const schemaResolver = yupResolver(
    yup.object().shape({
      username: yup.string().required(t("Vui lòng nhập tài khoản của bạn")),
      password: yup.string().required(t("Vui lòng nhập mật khẩu của bạn")),
    })
  );

  /*
    handle form submission
    */
  const onSubmit = (formData: UserData) => {
    dispatch(loginUser(formData["username"], formData["password"]));
    // alert("dsdssd");
  };

  const location = useLocation();
  const redirectUrl = location.pathname ? location.pathname : "/";

  useEffect(() => {
    if (location.pathname == "/auth/login") {
      ReloadForm();
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(staffJointSlice.clearStaffJoint());
    if (userIdStore && userIdStore != null && userIdStore > 0) {
      localStorage.setItem("is_ownerMenu", "0");
      GetInfoOwner();
      // navigate("/web-owner/qrcode");
    }
  }, [location.pathname, userIdStore]);

  return (
    <>
      {/* {(userLoggedIn || user) && <Redirect to={redirectUrl}></Redirect>} */}

      <AuthLayout helpText={t(languageUI.loginToSystem)} bottomLinks={""}>
        {/* {error && (
                    <Alert variant="danger" className="my-2">
                        {error}
                    </Alert>
                )} */}

        <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
        <VerticalForm
          onSubmit={onSubmit}
          resolver={schemaResolver}
          defaultValues={{ username: UserName, password: Password }}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{languageUI.userName}</Form.Label>
            <Form.Control
              type="text"
              placeholder={languageUI.enterYourUsername}
              onChange={(e: any) => {
                setUserName(e.target.value);
              }}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type={`${Showpass ? "text" : "password"}`} placeholder="Enter your Password"  onChange={(e: any)=>{setPassword(e.target.value)}}/>
                    </Form.Group> */}
          <Form.Label>{languageUI.password}</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={`${Showpass ? "text" : "password"}`}
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              placeholder={languageUI.enterYourPassword}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
            />
            <InputGroup.Text
              className={`input-group-password ${
                Showpass ? "show-password" : ""
              }`}
              id="inputGroup-sizing-sm"
              onClick={() => {
                setShowpass(!Showpass);
              }}
            >
              <span className="password-eye"></span>
            </InputGroup.Text>
          </InputGroup>

          <Form.Label className="text-danger">{message}</Form.Label>
          <div className="mb-3 text-end">
            <Link to="/auth/forget-password" className="text-primary">
              {languageUI.forgetPassword}
            </Link>
          </div>
          <div className="text-center d-grid">
            <Button
              variant="primary"
              type="submit"
              // disabled={loading}
              onClick={() => {
                LoginByAccount();
              }}
            >
              {t(languageUI.login)}
            </Button>
            {/* <Link to="/web-owner/qrcode">
                            <Button variant="primary" className="d-block w-100" disabled={loading}>
                                {t('Login')}
                            </Button>
                        </Link> */}
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default Login;
