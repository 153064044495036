import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./slices/userSlice";
import floorReducer from "./slices/floorSlice";
import tableReducer from "./slices/tableSlice";
import tipPercentReducer from "./slices/tipPercentSlice";
import ListStaffTipSlice from "./slices/ListStaffTipSlice";
import listFoodGroupSlice from "./slices/listFoodGroupSlice";
import menuFoodSlice from "./slices/menuFoodSlice";
import ListDetailFoodSlice from "./slices/ListDetailFoodSlice";
import orderSlice from "./slices/orderSlice";
import FlagSettingSlice from "./slices/FlagSettingSlice";
import staffJointSlice from "./slices/staffJointSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "floor",
    "table",
    "tippercent",
    "stafftip",
    "foodcategory",
    "menufood",
    "infofood",
    "order",
    "isSettting",
    "staffjoint",
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  floor: floorReducer,
  table: tableReducer,
  tippercent: tipPercentReducer,
  stafftip: ListStaffTipSlice,
  foodcategory: listFoodGroupSlice,
  menufood: menuFoodSlice,
  infofood: ListDetailFoodSlice,
  order: orderSlice,
  isSettting: FlagSettingSlice,
  staffjoint: staffJointSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});
export const persistor = persistStore(store);
export default store;
