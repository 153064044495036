import { arrowLeft, chervonRightPrimary } from "../../../../../../components/ImgExport";
import { useEffect, useRef, useState } from "react";
import './Styles.css';
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { getLanguageUI } from "../../../../../../Services/languageUI";
interface calculateSalaryEmployeeProps {
    monthName: string,
    month: number
}
const CalculateSalaryEmployee: React.FC<calculateSalaryEmployeeProps> = ({ monthName, month }) => {
    const navigate = useNavigate()

    return (
        <div className="calculate-Salary-box">
            <div>
                {monthName} {(new Date()).getFullYear()}
            </div>
            <img src={chervonRightPrimary} alt="" onClick={() => navigate('/web-owner/tablesalarymonth', { state: { month, monthName } })} />
        </div>
    )
}
interface SalaryEachWeekProps {
}
const SalaryEachWeek: React.FC<SalaryEachWeekProps> = () => {
    const languageUI = getLanguageUI().staffPage
    const navigate = useNavigate()
    const [weeksInYear, setWeeksInYear] = useState<{ monthName: string, month: number }[]>([])
    const ref = useRef<HTMLDivElement>(null);
    function getWeeksInYear() {

        const month = [{
            monthName: languageUI.january,
            month: 1
        },
        {
            monthName: languageUI.february,
            month: 2
        },
        {
            monthName: languageUI.march,
            month: 3
        },
        {
            monthName: languageUI.april,
            month: 4
        },
        {
            monthName: languageUI.may,
            month: 5
        },
        {
            monthName: languageUI.june,
            month: 6
        },
        {
            monthName: languageUI.july,
            month: 7
        },
        {
            monthName: languageUI.august,
            month: 8
        },
        {
            monthName: languageUI.september,
            month: 9
        },
        {
            monthName: languageUI.october,
            month: 10
        },
        {
            monthName: languageUI.november,
            month: 11
        },
        {
            monthName: languageUI.december,
            month: 12
        },

        ]



        setWeeksInYear(month);
        setTimeout(() => {
            scrollToElement()
        }, 1000);
    }
    const scrollToElement = () => {
        if (ref.current) {
            // Sử dụng scrollIntoView để cuộn đến phần tử
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };
    useEffect(() => {
        getWeeksInYear()
    }, [])

    return (
        <>
            <div className="box-salary-for-employee">
                <div className="header-salary-for-employee">
                    <div className="btn-back-salary-week" onClick={() => navigate('/web-owner/staffs', { state: { tabSelect: 1 } })}>
                        <img src={arrowLeft} alt="" />
                        {languageUI.back}
                    </div>
                    <p className="text-title-salary-week"> {languageUI.caculateSalaryMonth} {languageUI.fromJanuarytoDecember}</p>
                    <div className="btn-back-salary-week opacity-0 hidden">
                        <img src={arrowLeft} alt="" />
                        Back
                    </div>
                </div>
                <div className="box-container-salary-for-element">
                    <div className="container-salary-for-element scroll-hidden">
                        {
                            weeksInYear.map((week, index) => (
                                <>
                                    {week.month === Number(moment().format("MM")) ? (
                                        <div key={index} ref={ref} className="text-white" style={{ backgroundColor: "#4a81d4" }}>
                                            <CalculateSalaryEmployee monthName={week.monthName} month={week.month} />
                                        </div>
                                    ) : (
                                        <div key={index}>
                                            <CalculateSalaryEmployee monthName={week.monthName} month={week.month} />
                                        </div>
                                    )}

                                </>
                            ))
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
export default SalaryEachWeek