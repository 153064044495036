import * as Request from "../Request";

export const GetInfoPackage = async (UserIdStore: number, id: number) => {
    return await Request.fetchAPI('admin/gInfoPacked.php?id_packed=' + id + '&&admin_id=' + UserIdStore, 'GET', {
    }).then((res: IinfoPackage) => {
        return res;
    }).catch((err:any) => {
        return  {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};

export const ListPackage = async () => {
    return await Request.fetchAPI('admin/gListPacked.php', 'GET', {
    }).then((res: IListPackage) => {
        return res;
    }).catch((err:any) => {
        return  {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};

export const EditPackage = async (idPackedEdit: number, Description: string, Price: number, SMS: number) => {
    return await Request.fetchAPI('admin/editPacked.php', 'post', {
        "id_packed": idPackedEdit,
        "description": Description,
        "price": Price,
        "cms": SMS
    }).then((res: IEditPackage) => {
        return res;
    }).catch((err:any) => {
        return  {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};


export interface IinfoPackageData {
    description?: string;
    price?: number;
    cms?: number;
}

export interface IinfoPackage {
    message: string,
    status: number,
    data: IinfoPackageData []
}

export interface IListPackageData {
    id?: string;
    namePackage?: string;
    type?: string;
    cash?: number;
    sms?: number;
    value?: number;
    label?: string;
}

export interface IListPackage {
    message: string,
    status: number,
    data: IListPackageData []
}

export interface IEditPackage {
    message: string,
    status: number,
    data: []
}