export const SIMPLELINEICONS = [
    {
        name: 'icon-user',
    },
    {
        name: 'icon-people',
    },
    {
        name: 'icon-user-female',
    },
    {
        name: 'icon-user-follow',
    },
    {
        name: 'icon-user-following',
    },
    {
        name: 'icon-user-unfollow',
    },
    {
        name: 'icon-login',
    },
    {
        name: 'icon-logout',
    },
    {
        name: 'icon-emotsmile',
    },
    {
        name: 'icon-phone',
    },
    {
        name: 'icon-call-end',
    },
    {
        name: 'icon-call-in',
    },
    {
        name: 'icon-call-out',
    },
    {
        name: 'icon-map',
    },
    {
        name: 'icon-location-pin',
    },
    {
        name: 'icon-direction',
    },
    {
        name: 'icon-directions',
    },
    {
        name: 'icon-compass',
    },
    {
        name: 'icon-layers',
    },
    {
        name: 'icon-menu',
    },
    {
        name: 'icon-list',
    },
    {
        name: 'icon-options-vertical',
    },
    {
        name: 'icon-options',
    },
    {
        name: 'icon-arrow-down',
    },
    {
        name: 'icon-arrow-left',
    },
    {
        name: 'icon-arrow-right',
    },
    {
        name: 'icon-arrow-up',
    },
    {
        name: 'icon-arrow-up-circle',
    },
    {
        name: 'icon-arrow-left-circle',
    },
    {
        name: 'icon-arrow-right-circle',
    },
    {
        name: 'icon-arrow-down-circle',
    },
    {
        name: 'icon-check',
    },
    {
        name: 'icon-clock',
    },
    {
        name: 'icon-plus',
    },
    {
        name: 'icon-close',
    },
    {
        name: 'icon-trophy',
    },
    {
        name: 'icon-screen-smartphone',
    },
    {
        name: 'icon-screen-desktop',
    },
    {
        name: 'icon-plane',
    },
    {
        name: 'icon-notebook',
    },
    {
        name: 'icon-mustache',
    },
    {
        name: 'icon-mouse',
    },
    {
        name: 'icon-magnet',
    },
    {
        name: 'icon-energy',
    },
    {
        name: 'icon-disc',
    },
    {
        name: 'icon-cursor',
    },
    {
        name: 'icon-cursor-move',
    },
    {
        name: 'icon-crop',
    },
    {
        name: 'icon-chemistry',
    },
    {
        name: 'icon-speedometer',
    },
    {
        name: 'icon-shield',
    },
    {
        name: 'icon-screen-tablet',
    },
    {
        name: 'icon-magic-wand',
    },
    {
        name: 'icon-hourglass',
    },
    {
        name: 'icon-graduation',
    },
    {
        name: 'icon-ghost',
    },
    {
        name: 'icon-game-controller',
    },
    {
        name: 'icon-fire',
    },
    {
        name: 'icon-eyeglass',
    },
    {
        name: 'icon-envelope-open',
    },
    {
        name: 'icon-bell',
    },
    {
        name: 'icon-badge',
    },
    {
        name: 'icon-anchor',
    },
    {
        name: 'icon-wallet',
    },
    {
        name: 'icon-vector',
    },
    {
        name: 'icon-speech',
    },
    {
        name: 'icon-puzzle',
    },
    {
        name: 'icon-printer',
    },
    {
        name: 'icon-present',
    },
    {
        name: 'icon-playlist',
    },
    {
        name: 'icon-pin',
    },
    {
        name: 'icon-picture',
    },
    {
        name: 'icon-handbag',
    },
    {
        name: 'icon-globe-alt',
    },
    {
        name: 'icon-globe',
    },
    {
        name: 'icon-folder-alt',
    },
    {
        name: 'icon-folder',
    },
    {
        name: 'icon-film',
    },
    {
        name: 'icon-feed',
    },
    {
        name: 'icon-drop',
    },
    {
        name: 'icon-drawar',
    },
    {
        name: 'icon-docs',
    },
    {
        name: 'icon-doc',
    },
    {
        name: 'icon-diamond',
    },
    {
        name: 'icon-cup',
    },
    {
        name: 'icon-calculator',
    },
    {
        name: 'icon-bubbles',
    },
    {
        name: 'icon-briefcase',
    },
    {
        name: 'icon-book-open',
    },
    {
        name: 'icon-basket-loaded',
    },
    {
        name: 'icon-basket',
    },
    {
        name: 'icon-bag',
    },
    {
        name: 'icon-action-undo',
    },
    {
        name: 'icon-action-redo',
    },
    {
        name: 'icon-wrench',
    },
    {
        name: 'icon-umbrella',
    },
    {
        name: 'icon-trash',
    },
    {
        name: 'icon-tag',
    },
    {
        name: 'icon-support',
    },
    {
        name: 'icon-frame',
    },
    {
        name: 'icon-size-fullscreen',
    },
    {
        name: 'icon-size-actual',
    },
    {
        name: 'icon-shuffle',
    },
    {
        name: 'icon-share-alt',
    },
    {
        name: 'icon-share',
    },
    {
        name: 'icon-rocket',
    },
    {
        name: 'icon-question',
    },
    {
        name: 'icon-pie-chart',
    },
    {
        name: 'icon-pencil',
    },
    {
        name: 'icon-note',
    },
    {
        name: 'icon-loop',
    },
    {
        name: 'icon-home',
    },
    {
        name: 'icon-grid',
    },
    {
        name: 'icon-graph',
    },
    {
        name: 'icon-microphone',
    },
    {
        name: 'icon-music-tone-alt',
    },
    {
        name: 'icon-music-tone',
    },
    {
        name: 'icon-earphones-alt',
    },
    {
        name: 'icon-earphones',
    },
    {
        name: 'icon-equalizer',
    },
    {
        name: 'icon-like',
    },
    {
        name: 'icon-dislike',
    },
    {
        name: 'icon-control-start',
    },
    {
        name: 'icon-control-rewind',
    },
    {
        name: 'icon-control-play',
    },
    {
        name: 'icon-control-pause',
    },
    {
        name: 'icon-control-forward',
    },
    {
        name: 'icon-control-end',
    },
    {
        name: 'icon-volume-1',
    },
    {
        name: 'icon-volume-2',
    },
    {
        name: 'icon-volume-off',
    },
    {
        name: 'icon-calender',
    },
    {
        name: 'icon-bulb',
    },
    {
        name: 'icon-chart',
    },
    {
        name: 'icon-ban',
    },
    {
        name: 'icon-bubble',
    },
    {
        name: 'icon-camrecorder',
    },
    {
        name: 'icon-camera',
    },
    {
        name: 'icon-cloud-download',
    },
    {
        name: 'icon-cloud-upload',
    },
    {
        name: 'icon-eye',
    },
    {
        name: 'icon-flag',
    },
    {
        name: 'icon-heart',
    },
    {
        name: 'icon-info',
    },
    {
        name: 'icon-key',
    },
    {
        name: 'icon-link',
    },
    {
        name: 'icon-lock',
    },
    {
        name: 'icon-lock-open',
    },
    {
        name: 'icon-magnifier',
    },
    {
        name: 'icon-magnifier-add',
    },
    {
        name: 'icon-magnifier-remove',
    },
    {
        name: 'icon-paper-clip',
    },
    {
        name: 'icon-paper-plane',
    },
    {
        name: 'icon-power',
    },
    {
        name: 'icon-refresh',
    },
    {
        name: 'icon-reload',
    },
    {
        name: 'icon-settings',
    },
    {
        name: 'icon-star',
    },
    {
        name: 'icon-symble-female',
    },
    {
        name: 'icon-symbol-male',
    },
    {
        name: 'icon-target',
    },
    {
        name: 'icon-credit-card',
    },
    {
        name: 'icon-paypal',
    },
    {
        name: 'icon-social-tumblr',
    },
    {
        name: 'icon-social-twitter',
    },
    {
        name: 'icon-social-facebook',
    },
    {
        name: 'icon-social-instagram',
    },
    {
        name: 'icon-social-linkedin',
    },
    {
        name: 'icon-social-pintarest',
    },
    {
        name: 'icon-social-github',
    },
    {
        name: 'icon-social-gplus',
    },
    {
        name: 'icon-social-reddit',
    },
    {
        name: 'icon-social-skype',
    },
    {
        name: 'icon-social-dribbble',
    },
    {
        name: 'icon-social-behance',
    },
    {
        name: 'icon-social-foursqare',
    },
    {
        name: 'icon-social-soundcloud',
    },
    {
        name: 'icon-social-spotify',
    },
    {
        name: 'icon-social-stumbleupon',
    },
    {
        name: 'icon-social-youtube',
    },
    {
        name: 'icon-social-dropbox',
    },
];
