// import * as React from 'react';
import "./Styles.css";
import { arrowLeft } from "../../../../../../components/ImgExport";
import ContentTableSalaryEmployee from "../ContentTable/ContentTableSalary/ContentTableSalaryEmployee";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import * as StaffApi from "../../../../../../api/apiOwner/StaffApi";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../../store/slices/userSlice";
import * as SettingPrinterApi from "../../../../../../api/apiOwner/SettingPrinterApi";
import ModalSalaryBonus from "../../../ModalSalaryBonus/ModalSalaryBonus";
import { getLanguageUI } from "../../../../../../Services/languageUI";

interface TableSalaryEmployeeProp {}
const TableSalaryEmployee: React.FC<TableSalaryEmployeeProp> = () => {
  const languageUI = getLanguageUI().staffPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const navigate = useNavigate();
  const location: any = useLocation();
  const { month, monthName } = location.state;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ShowLoading, setShowLoading] = useState<boolean>(true);
  const [listSalary, setListSalary] = useState<StaffApi.SalaryStaffWeek[]>([]);
  const [listPrinterName, setListPrinterName] = useState<
    SettingPrinterApi.ListPrinterByCatalog[]
  >([]);
  const [showModalSalaryBonus, setShowModalSalaryBonus] =
    useState<boolean>(false);
  const [idStaff, setIdStaff] = useState<number>();
  const [nameStaff, setNameStaff] = useState<string>("");

  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await SettingPrinterApi.gListNamePrinterElse(
        UserIdStore,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  useEffect(() => {
    gListPrinter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const gListSalary = async () => {
    if (UserIdStore) {
      const req = await StaffApi.showSalaryEmployeeMonth(UserIdStore, month);
      if (Number(req?.status) === 1) {
        setListSalary(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const openModalSalaryBonus = (id: number, nameStaff: string) => {
    setIdStaff(id);
    setNameStaff(nameStaff);
    setShowModalSalaryBonus(true);
  };
  const dismisModalSalaryBonus = () => {
    setShowModalSalaryBonus(false);
    gListSalary();
  };
  useEffect(() => {
    gListSalary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} /> */}
      <div className="salary-table">
        <div className="header-salary-for-employee">
          <div
            className="btn-back-salary-week"
            onClick={() => navigate("/web-owner/salarymonth")}
          >
            <img src={arrowLeft} alt="" />
            {languageUI.back}
          </div>
          <p className="text-title-salary-week">
            {" "}
            {languageUI.caculateSalaryMonth},{" "}
            {`${monthName} ${new Date().getFullYear()}`}
          </p>
          <div className="btn-back-salary-week opacity-0 hidden">
            <img src={arrowLeft} alt="" />
            Back
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th
                scope="col"
                className="w-5 text-14px text-12B064 fw-600 font-urbansist"
              >
                #
              </th>
              <th
                scope="col"
                className="w-10 text-14px text-12B064 fw-600 font-urbansist"
              >
                {languageUI.name}
              </th>
              <th scope="col" className="w-10 ">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.payer}
                </span>
              </th>
              <th scope="col" className="w-10">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.commission}
                </span>
              </th>
              <th scope="col" className="w-5 ">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.totalTime}
                </span>
              </th>
              <th scope="col" className="w-10 ">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.totalWorkEarn}
                </span>
              </th>
              <th scope="col" className="w-10">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.totalTip}
                </span>
              </th>
              <th scope="col" className="w-10">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.totalPay}
                </span>
              </th>
              <th scope="col" className="w-10">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.deduct}
                </span>
              </th>
              <th scope="col" className="w-10">
                <span className="text-14px text-12B064 fw-600 font-urbansist">
                  {languageUI.bonus}
                </span>
              </th>
              <th scope="col" className="w-10">
                {/* <div className="text-14px text-12B064 fw-600 font-urbansist btn-print-salary-staff-week">Print</div> */}
              </th>
            </tr>
          </thead>
          <tbody className="h-body-table-salary">
            {listSalary.map((item, i) => (
              <ContentTableSalaryEmployee
                listPrinterName={listPrinterName}
                key={i}
                no={i + 1}
                name={item?.staff_name}
                payer={item?.payroll_name}
                commission={Number(item?.payroll_value)}
                timeWorking={item?.time_working}
                totalPay={Number(item?.total_salary) + Number(item?.tip)}
                totalTip={Number(item?.tip)}
                totalWorEarn={Number(item?.total_salary)}
                startWeek={monthName}
                endWeek={monthName}
                history={item?.history}
                payroll_id={item?.payroll_id}
                is_month={true}
                UserIdStore={UserIdStore}
                bonus={Number(item.totalBonus)}
                deduct={Number(item.totalDeduct)}
                id={Number(item.id)}
                handleOpenModalSalaryBonus={openModalSalaryBonus}
              />
            ))}
          </tbody>
        </table>
      </div>
      <ModalSalaryBonus
        nameStaff={nameStaff}
        id_staff={idStaff ?? 0}
        handleClose={dismisModalSalaryBonus}
        show={showModalSalaryBonus}
      />
    </>
  );
};
export default TableSalaryEmployee;
