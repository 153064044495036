import React, { useState } from "react";
import { KeyboardArrowRightIcon } from "../../../../../components/Image";
import OderDetailHistory from "./Drawer/OderDetailHistory";
import { ListPrinterByCatalog } from "../../../../../api/apiOwner/SettingPrinterApi";
interface ContentTableOrderHistoryProps {
    date: string;
    time: string;
    order: string;
    table: string;
    bill: string;
    tip: string;
    staff: string;
    listPrinterName?:ListPrinterByCatalog[]
}
const ContentOderHistory = ({
    staff,
    tip,
    bill,
    date,
    time,
    order,
    table,
    listPrinterName=[]
}: ContentTableOrderHistoryProps) => {
    const [showDrawerOderDetailHistory, setDrawerOrderDetailHistory] = useState<boolean>(false)

    return (
        <>
            <OderDetailHistory openDrawer={showDrawerOderDetailHistory} dismisDrawer={() => setDrawerOrderDetailHistory(!showDrawerOderDetailHistory)} order={order} listPrinterName={listPrinterName}/>
            <tr className="d-flex align-items-center mb-2 ">
                <td className="border-bottom-none text-17px text-4A4A6A w-13">{date}</td>
                <td className="border-bottom-none text-17px text-4A4A6A w-13">{time}</td>
                <td className="border-bottom-none text-17px text-269AFF w-13">#{order}</td>
                <td className="border-bottom-none text-17px text-269AFF w-13">{table}</td>
                <td className="border-bottom-none text-18px text-4A4A6A w-13">{bill}</td>
                <td className="border-bottom-none text-18px text-4A4A6A w-13">{tip}</td>
                <td className="w-13 border-bottom-none" >
                    <div className="flex items-center justify-between">
                        <span className="text-17px text-4A4A6A">{staff}</span>

                    </div>
                </td>
                <td className="border-bottom-none w-9 text-align-end" onClick={() => setDrawerOrderDetailHistory(!showDrawerOderDetailHistory)}><KeyboardArrowRightIcon /></td>
            </tr>
        </>
    )
}
export default ContentOderHistory