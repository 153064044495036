import { Button, Modal, Toast, ToastContainer } from "react-bootstrap";
import "./Styles.css"
import AudioPlayer from "react-h5-audio-player";


interface ModalOutDateGameRoundProps {
  show: boolean;
  handleClose: () => void;
}

const ModalOutDateGameRound = ({
  show,
  handleClose
}: ModalOutDateGameRoundProps) => {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-timeout-token"
      >
        <Modal.Body>
          <div className="p-2 pb-0">
            <div className="box-payment-success px-2">
              <p className="text-notification-payment-success text-center font-weight-600 mb-1">
                Your turn is over
              </p>
              <p className="text-notification-payment-success text-center font-weight-600 m-0"> please come back tomorrow!</p>

            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>

  );
};

export default ModalOutDateGameRound;
