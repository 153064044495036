import * as React from 'react';
import { useState } from "react";
import Box from '@mui/material/Box';
import "../Styles.css";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ListInventoryWarehouse } from '../../../api/apiOwner/InventoryApi';
import moment from 'moment';
import { trashRed } from '../../../components/ImgExport';
import ModalOTPDeleteInventoryItems from './ModalOTPDeleteInventoryItems/ModalOTPDeleteInventoryItems';
import { getLanguageUI } from '../../../Services/languageUI';

interface StatusInventoryProps {
    status?: '0' | '1' | '2';
    id?: string,
    HandelDelSuccess?: () => void
}
const StatusInventory = ({ status }: StatusInventoryProps) => {
    const languageUI = getLanguageUI().inventoryPage
    return (
        <>
            <div>
                <p className="title-status-inventory mb-1 capi">{Number(status) === 1 ? languageUI.lowStock : Number(status) === 0 ? languageUI.outOfStock : languageUI.inStock}</p>
                <div className="d-flex gap-2px align-items-center">
                    <div className={`box-display-status-1 ${Number(status) === 1 ? "bg-FF7B2C" : Number(status) === 2 ? "bg-0FA54A" : ""}`}></div>
                    <div className={`box-display-status-2 ${Number(status) === 2 ? "bg-0FA54A" : ""}`}></div>
                </div>
            </div>
        </>
    )
}
const EditItemsInventory = ({ id, HandelDelSuccess }: StatusInventoryProps) => {
    const languageUI = getLanguageUI().inventoryPage
    const [showModalDeleteInventory, setShowModalDeleteInventory] = useState(false)
    const dismisModalShowDeleteInventory = () => {
        setShowModalDeleteInventory(false)
        HandelDelSuccess?.()
    }
    return (
        <>
            <div className='d-flex gap-2'>
                {/* <div className='btn-edit-delete-inventory-items' onClick={() => { setShowModalInventory(true) }}>
                    <img src={editTableInventoryGreen} className="" alt="" />
                </div> */}
                <div className='btn-edit-delete-inventory-items' onClick={() => { setShowModalDeleteInventory(true) }} >
                    <img src={trashRed} className="" alt="" />
                    {languageUI.delete}
                </div>
            </div>
            <ModalOTPDeleteInventoryItems handleClose={dismisModalShowDeleteInventory} show={showModalDeleteInventory} id={id} />
        </>
    )
}

const getRowClassName = (params: any) => {
    if (params.id % 2 === 0) {
        return 'text-table';
    }
    return 'text-table';
};

interface TableInventoryProp {
    listInventoryFilter: ListInventoryWarehouse[];
    HandelDelSuccess?: () => void;
}
const TableInventory: React.FC<TableInventoryProp> = ({ listInventoryFilter = [], HandelDelSuccess }) => {
    const languageUI = getLanguageUI().inventoryPage

    const columns: GridColDef[] = [
        { field: 'stt', headerName: 'No.', flex: 2, headerClassName: 'super-app-theme--header', },
        {
            field: 'material_code',
            headerClassName: 'super-app-theme--header',
            headerName: languageUI.materialCode,
            flex: 4,
            editable: false,
        },
        {
            field: 'ingredient_name',
            headerClassName: 'super-app-theme--header',
            headerName: languageUI.materialName,
            flex: 4,
            editable: false,
        },
        {
            field: 'dateadd',
            headerName: languageUI.dateAdded,
            headerClassName: 'super-app-theme--header',
            // type: 'number',
            flex: 4,
            editable: false,
            renderCell: (e: any) => { return <div>{moment(e?.value).format("DD/MM/YYYY hh:mm")}</div> }
        },
        {
            field: 'Inventory_stock',
            headerName: languageUI.InventoryStock,
            headerClassName: 'super-app-theme--header',
            sortable: false,
            editable: false,
            flex: 4,
            // valueGetter: (params: GridValueGetterParams) =>
            //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'reminder',
            headerName: languageUI.reminder,
            headerClassName: 'super-app-theme--header',
            sortable: false,
            editable: false,
            flex: 3,
            renderCell: (e: any) => { return <>{e.row.option_value + " " + e.row.unit_reminder}</> }
            // valueGetter: (params: GridValueGetterParams) =>
            //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'unit_name',
            headerName: languageUI.unit,
            headerClassName: 'super-app-theme--header',
            // type: 'number',
            flex: 2,
            editable: false,
        },
        {
            field: 'status',
            headerName: languageUI.status,
            headerClassName: 'super-app-theme--header',
            // type: 'number',
            flex: 4,
            editable: false,
            renderCell: (e: any) => { return <><StatusInventory status={e?.value} /></> }
        },
        {
            field: 'edit',
            headerName: '',
            headerClassName: 'super-app-theme--header',
            // type: 'number',
            flex: 3,
            editable: false,
            renderCell: (Cell: any) => {
                return <><EditItemsInventory id={Cell?.row?.id} HandelDelSuccess={HandelDelSuccess} /></>
            }
        },
    ];

    return (
        <Box className="height-table-inventory" sx={{
            width: '100%'
        }}>
            <DataGrid
                rows={listInventoryFilter}
                columns={columns}
                className=''
                // initialState={{
                //     pagination: {
                //         paginationModel: {
                //             pageSize: 5,
                //         },
                //     },
                // }}
                // pageSizeOptions={[5]}
                // checkboxSelection
                disableRowSelectionOnClick
                hideFooter
                getRowClassName={getRowClassName}
            />
        </Box>
    );
}
export default TableInventory;