
import { Button, Modal } from "react-bootstrap";
import OrderItemsTable from "./components/OrderItemsTable/OrderItemsTable";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalChooseNameTip from "../webcheckin/components/ModalChooseNameTip/ModalChooseNameTip";
import Check from "../../assets/images/check.png"
import ModalAlert from "../../components/ModalAlert/ModalAlert";
import "./Styles.css"
interface PaymentBillInofrmationProps { }
const PaymentBillInofrmation: React.FC<PaymentBillInofrmationProps> = ({ history, match }: any) => {
  const navigate = useNavigate();
  const [valueTip, setValueTip] = useState<number>();
  const [valueTipCustom, setValueTipCustom] = useState()
  const [valueTipCustom2, setValueTipCustom2] = useState()
  const [showModalOpenChooseTip, setShowModalOpenChooseTip] = useState(false)
  const [radioMethodPayment, setRadioMethodPayment] = useState(false)
  const [showModalChooseNameTip, setShowModalChooseNameTip] = useState(false)
  const [showModalAlertSuccess, setShowModalAlertSuccess] = useState(false)


  function handleChange(event: any) {
    setValueTipCustom(valueTipCustom2);
    setShowModalOpenChooseTip(false)

  }
  const dismisModalAlertSuccess = () => {
    setShowModalAlertSuccess(false)
  }
  function handleChangeInput(event: any) {
    setValueTipCustom2(event.target.value)
  }
  const dismisModalChooseNameTip = () => {
    setShowModalChooseNameTip(false);
  }
  const array: {
    id: number,
    name: string
  }[]
    = [{
      id: 1,
      name: "5% : 2$"
    },
    {
      id: 2,
      name: "10% : 2$"
    },
    {
      id: 3,
      name: "20%: 2$"
    }]

  return (
    <div className=" mt-3 rounded-5 bg-white p-3 pb-5">
      <div className="mb-3 d-flex justify-content-between">
        <div className=" bg-primary px-3 py-1 rounded-5 w-fit d-flex justify-content-center align-items-center curs-pointer cursor-pointer" onClick={() => { navigate(-1) }}>
          <i className="fas fa-arrow-left text-white text-xl"></i>
        </div>
        <p className="text-3xl font-bold m-0">Bill Information</p>
        <div className=" bg-primary px-3 py-1 rounded-5 w-fit d-flex justify-content-center align-items-center opacity-0">
          <i className="fas fa-arrow-left text-white text-xl"></i>
        </div>
      </div>
      <div>
        <div className="rounded-5 bg-gray-light p-3 mb-2">
          <div className="d-flex justify-content-between pe-2 mb-2">
            <div className="d-flex">
              <p className="mb-0">The dishes of the table: {3}</p>
            </div>
            <p className="mb-0">Order number: {3}</p>
            <p className="mb-0">Custom name: {"Khach hang A"}</p>
          </div>
          <div className="custum-scroll-food-bill custom-scroll-bar">
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
          </div>
        </div>
        <div className="rounded-5 bg-gray-light p-3">
          <div className="d-flex justify-content-between pe-2 mb-2">
            <div className="d-flex">
              <p className="mb-0">The dishes of the table: {3}</p>
            </div>
            <p className="mb-0">Order number: {3}</p>
            <p className="mb-0">Custom name: {"Khach hang A"}</p>
          </div>
          <div className="custum-scroll-food-bill custom-scroll-bar">
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
            <OrderItemsTable descreption="Chín hoàn toàn , món ăn kèm: khoai tây,...(mô tả món ăn)" foodName="Bò Bít Tết" price={50} quantity={1} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="mt-3 d-block" role="group" aria-label="Basic radio toggle button group" >
            <div className={`flex-shink-0 border-1px-primary rounded-5 h-40px w-fit px-2 d-flex justify-content-center align-items-center bg-white text-green ${radioMethodPayment === false ? "bg-primary text-white" : ""}`} onClick={() => { setRadioMethodPayment(false) }}>
              <p className={`m-0  text-green font-bold text-base ${radioMethodPayment === false ? "bg-primary text-white" : ""}`}>Pay Width Card</p>
            </div>
            <div className="ps-3">
              <div className="w-100  d-flex gap-2  justify-content-between mt-2">
                {array.map((items, i) => (
                  <div key={i} className={`flex-shink-0 border-green-light rounded-5 h-40px w-30  d-flex justify-content-center align-items-center bg-ffffff text-000000 ${Number(valueTip) === Number(items.id) ? "bg-blue-light  text-blue-light" : ""}`} onClick={() => { setValueTip(items.id) }}>
                    <p className={`m-0 text-xs font-bold`}>{items.name}</p>
                  </div>
                ))}
              </div>
              <div className="d-flex gap-2">
                <div className={`flex-shink-0 border-green-light rounded-5 h-40px  d-flex justify-content-center align-items-center bg-white   px-2 w-fit mt-2`} onClick={() => { setShowModalOpenChooseTip(true); setValueTip(0) }}>
                  <p className={`m-0 bg-white text-000000 font-bold `}>Custom</p>
                </div>
                <div className={`flex-shink-0  rounded-5 h-40px  d-flex justify-content-center align-items-center bg-blue-light text-white px-2 w-fit mt-2 `}>
                  <p className={`m-0 text-white`}>{valueTipCustom}$</p>
                </div>
              </div>
              <Modal className="modal-add-to-card" show={showModalOpenChooseTip} onHide={() => setShowModalOpenChooseTip(false)} centered>
                <div className="w-100 pt-10 pr-4">
                  <p className="p-0 m-0 text-xl text-black font-bold text-capitalize text-center">Custom Tip</p>
                </div>
                <Modal.Body>
                  <p className="p-0 m-0 text-base font-bold ">Please Enter Tip amount</p>
                  <input type="number" className="h-40px w-100 border-input-add-request rounded-3 font-bold" onChange={handleChangeInput} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="primary" onClick={handleChange}>
                    Add To Cart
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="mt-2" onClick={() => setShowModalChooseNameTip(true)}>
                <button className="border-none bg-primary text-white rounded-3 font-bold text-base w-fit py-1 px-2">Select the employee</button>
              </div>
              <ModalChooseNameTip handleClose={dismisModalChooseNameTip} show={showModalChooseNameTip} />
              <p className="text-base font-bold mt-2 mb-0">Discount Code</p>
              <div className="d-flex gap-2">
                <input type="text" className="border-green-light rounded-4 h-40px font-bold w-75 px-2" />
                <button className="bg-primary px-2 border-none rounded-3 text-white font-bold">Add</button>
              </div>
            </div>
            <div className="mt-2">
              <div className={`flex-shink-0 border-1px-primary rounded-5 h-40px w-fit px-2 d-flex justify-content-center align-items-center bg-white text-green ${radioMethodPayment === true ? "bg-primary text-white" : ""}`} onClick={() => { setRadioMethodPayment(true) }}>
                <p className={`m-0  text-green font-bold text-base ${radioMethodPayment === true ? "bg-primary text-white" : ""}`}>Pay Width Cash</p>
              </div>
              <div className="mt-2 ps-3">
                <div className="d-flex align-items-center gap-2">
                  <p className="mb-0 text-base font-bold w-70px text-center">Enter</p>
                  <input type="number" className="border-green-light rounded-4 h-40px font-bold w-75 px-2" />
                </div>
                <div className="d-flex align-items-center gap-2 mt-2">
                  <p className="mb-0 text-base font-bold w-70px text-center">Change</p>
                  <input type="number" className="border-green-light rounded-4 h-40px font-bold w-75 px-2" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="col-6 d-flex flex-column align-items-end px-4 mt-5">
          {/* <input type="number" className="border-input-add-request rounded-4 h-40px font-bold w-75 px-2 mb-2" /> */}
          <div className="border-green-light w-75 rounded-4 h-40px bg-rimary mb-2 px-2 d-flex align-items-center justify-content-between ">
            <p className="mb-0 text-sm font-medium">VAT 10%:</p>
            <p className="mb-0 text-sm font-bold">4.1$</p>
          </div>
          <div className="border-green-light w-75 rounded-4 h-40px bg-rimary mb-2 px-2 d-flex align-items-center justify-content-between">
            <p className="mb-0 text-sm font-medium">TIP 10%:</p>
            <p className="mb-0 text-sm font-bold">4.1$</p>
          </div>
          <div className="w-100">
            <p className="text-xl font-bold text-right">Total bill: 45,1$</p>
          </div>
        </div>
      </div>
      <div className="w-100 text-right px-3 mt-2">
        <button className="px-3 py-2 font-bold text-white bg-primary border-none rounded-4 text-xl" onClick={() => { setShowModalAlertSuccess(true) }}>Payment</button>
      </div>
      <ModalAlert handleClose={dismisModalAlertSuccess} img={Check} show={showModalAlertSuccess} title="Success" />
    </div>
  );
};

export default PaymentBillInofrmation;
