import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import moment from "moment";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import {
  RevervationDetailCustomer,
  UpRevervationInfo,
} from "../../../../api/apiOwner/Reservation";
import ModalLoading from "../../../../components/ModalLoading";
import { formatDate } from "../../../../utils/format";
import { getLanguageUI } from "../../../../Services/languageUI";

interface ModalInfoReservationProps {
  show: boolean;
  id: string;
  handleClose: () => void;
}
const ModalInfoReservation = ({
  show,
  id,
  handleClose,
}: ModalInfoReservationProps) => {
  const languageUI = getLanguageUI().reservation;
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [bookingDate, setBookingDate] = useState("");
  const [timeBooking, setTimeBooking] = useState<dayjs.Dayjs | null | any>("");
  const [seats, setSeats] = useState("");
  const [note, setNote] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [messErrSeat, setMessErrSeats] = useState("");

  const UserIdStore = useSelector(userSlice.selectorUserId);

  const ListRevervationDetailCustomer = async () => {
    setShowLoading(true);
    if (UserIdStore) {
      const req = await RevervationDetailCustomer(UserIdStore.toString(), id);
      if (Number(req.status) === 1) {
        setName(req.data?.fullname ?? "");
        setPhone(req.data?.phone ?? "");
        setBookingDate(req.data?.booking_date ?? "");
        setTimeBooking(
          req.data?.booking_time
            ? dayjs(
                moment(new Date()).format("YYYY-MM-DD") +
                  " " +
                  moment(req.data?.booking_time, "hh:mma").format("HH:mm")
              )
            : ""
        );
        setNote(req.data?.note ?? "");
        setSeats(req.data?.seats ?? "");
        setShowLoading(false);
      }
    }
  };

  const UpDateRevervationInfo = async () => {
    setShowLoading(true);
    let time = moment(formatDate(timeBooking?.$d)).format("hh:mm A");
    if (UserIdStore) {
      const res = await UpRevervationInfo(
        UserIdStore.toString(),
        id,
        time,
        seats.toString(),
        note
      );
      if (Number(res.status) === 1) {
        setShowLoading(false);
        handleClose();
      }
    }
  };
  const handleConfirmChangeInfo = () => {
    if (Number(seats) < 1) {
      setMessErrSeats("seat must be greater than 0");
      return;
    }
    Swal.fire({
      title: languageUI.wantSaveChange,
      text: "",
      icon: "question",
      showCancelButton: true,
      cancelButtonColor: "#f66b6b",
      confirmButtonColor: "#28bb4b",
      cancelButtonText: languageUI.cancel,
      confirmButtonText: languageUI.save,
    }).then((result) => {
      if (result.isConfirmed) {
        UpDateRevervationInfo();
      }
    });
  };

  useEffect(() => {
    if (show) {
      ListRevervationDetailCustomer();
    }
  }, [show]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-infor-device-order"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter text-center">
            <h3 className="m-0 text-4a4a6a d-inline ">
              {" "}
              {languageUI.bookingTableDetail}
            </h3>
            <h3 className="m-0 d-inline text-orange"> - #{id}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-4">
            <h4 className="m-0">{languageUI.info}</h4>
          </div>
          <div className="w-100  d-flex flex-wrap px-4">
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-30 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">
                {languageUI.customer}:
              </span>
              <div className="border-DEE2E6 w-70 overflow-hidden radius-2 ">
                <input
                  type="text"
                  readOnly
                  placeholder="Customer"
                  className="custom-input h-100 font-medium text-4a4a6a text-base"
                  value={name}
                />
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-30 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">
                {languageUI.phoneNumber}:
              </span>
              <div className="border-DEE2E6 w-70 overflow-hidden radius-2 ">
                <input
                  type="text"
                  readOnly
                  placeholder="Phone Number"
                  className="custom-input h-100 font-medium text-4a4a6a text-base"
                  value={phone}
                />
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-30 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">
                {languageUI.Date}:
              </span>
              <div className="border-DEE2E6 w-70 overflow-hidden radius-2 ">
                <input
                  type="text"
                  readOnly
                  placeholder="Phone Number"
                  className="custom-input h-100 font-medium text-4a4a6a text-base"
                  value={bookingDate}
                />
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-30 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">
                {languageUI.Time}:
              </span>
              <div className="border-DEE2E6 w-70 overflow-hidden radius-2 ">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DateTimePicker", "DateTimePicker"]}
                  >
                    <DesktopTimePicker
                      // readOnly
                      className="w-100 choose-time-reservation text-4a4a6a"
                      views={["hours", "minutes"]}
                      value={timeBooking}
                      onChange={(newValue) => {
                        setTimeBooking(newValue);
                      }}
                      // onAccept={() => setIsChangeClockIn(isChangeClockIn + 1)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
            <div className="flex items-center w-1-2 max-w-768-w-full gap-2 px-4 pb-4">
              <span className="w-30 flex-shrink-0 h-fit text-lg text-4a4a6a font-medium">
                {languageUI.Seat}:
              </span>
              <div className="w-70">
                <div className="border-DEE2E6 w-100  overflow-hidden radius-2 ">
                  <input
                    type="number"
                    placeholder="Phone Number"
                    inputMode={"numeric"}
                    className="custom-input h-100 font-medium text-4a4a6a text-base"
                    value={Number(seats)}
                    onChange={(e) => setSeats(e.target.value.toString())}
                  />
                </div>
                {messErrSeat === "" ? (
                  ""
                ) : (
                  <p className="mb-0 mt-1 text-italic text-danger">
                    {messErrSeat}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="px-4">
            <h4 className="mt-2">{languageUI.note}</h4>
            <div className="flex  w-100 max-w-768-w-full gap-2 px-4 pb-4">
              <textarea
                className="text-more-request2 mt-1"
                id="textarea"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              ></textarea>
            </div>
            <ModalLoading
              onHide={() => setShowLoading(false)}
              show={showLoading}
            />
          </div>
          <div className="d-flex gap-3 justify-content-end">
            <button
              className="py-2 px-6 font-bold text-white bg-red rounded-4 border-none shadow-button-default"
              onClick={handleClose}
            >
              {languageUI.cancel}
            </button>
            <button
              className="py-2 px-6 font-bold text-white bg-primary rounded-4 border-none shadow-button-default"
              onClick={handleConfirmChangeInfo}
            >
              {languageUI.save}
            </button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalInfoReservation;
