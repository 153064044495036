import { closeLineWhite } from "../../../../components/ImgExport";
import "./Styles.css"

interface ItemsChooseRawProps {
    name: string;
    mass?: string;
    unit?: number;
    index?: number;
    onRemove?: (index: number) => void
}
const ItemsChooseRaw = ({
    mass = "",
    name,
    unit = 0,
    index = 0,
    onRemove
}: ItemsChooseRawProps) => {
    return (
        <div className="items-choose-raw position-relative">
            <p className={`mb-0 text-name-choose-raw d-flex gap-1 `}>{name}<p className={`mb-0 text-mass-choose-raw ${mass == "" && unit === 0 ? "d-none" : ""}`}>({unit} {mass})</p>
            </p>
            <div className="btn-remove-box-raw" onClick={() => onRemove?.(index)}>
                <img src={closeLineWhite} alt="" />
            </div>
        </div>
    )
}
export default ItemsChooseRaw