import { useState } from "react";
import { chervonRightPrimary } from "../../../../components/ImgExport";
import ModalShowBillOrderCustomer from "../ModalShowBillOrderCustomer/ModalShowBillOrderCustomer";
import "./Styles.css";
import * as custommerRecieptApi from "../../../../api/apiOwner/custommerRecieptApi";
import React from "react";
import { ListPrinterByCatalog } from "../../../../api/apiOwner/SettingPrinterApi";

interface ContentTableCustomerProps {
  numerical: string;
  idOrder: string;
  priceBill: number;
  tip: number;
  staff: string;
  timePrint: string;
  customer: string;
  UserIdStore?: number;
  listPrinterName?: ListPrinterByCatalog[];
  setShowLoading?: (val: boolean) => void;
  removeOrderById?: (order_id: string) => void;
}

const ContentTableCustomer = ({
  customer,
  idOrder,
  numerical,
  priceBill,
  staff,
  timePrint,
  tip = 0,
  UserIdStore,
  listPrinterName = [],
  setShowLoading,
  removeOrderById,
}: ContentTableCustomerProps) => {
  const [showModalShowBill, setShowModalShowBill] = useState(false);
  const [detailOrder, setDetailOrder] =
    useState<custommerRecieptApi.DetailBill>();
  const dismisModalShowBill = () => {
    setShowModalShowBill(false);
  };

  const gDetailOrder = async () => {
    if (UserIdStore) {
      setShowLoading?.(true);
      const gDetail = await custommerRecieptApi.gDetailOrderCustomer(
        UserIdStore,
        idOrder
      );
      if (Number(gDetail?.status) === 1) {
        setDetailOrder(gDetail?.data ?? undefined);
        setShowLoading?.(false);
        setShowModalShowBill(true);
      } else {
        setShowLoading?.(false);
      }
    }
  };

  return (
    <>
      <tr className="w-100 d-table">
        <td className="text-content-table-customer text-32324D ps-2 pt-3 w-10">
          {numerical}
        </td>
        <td className="text-content-table-customer text-269AFF pt-3 w-16">
          {"#" + idOrder}
        </td>
        <td className="pt-3 w-16">
          <p className="mb-0 text-12B064 text-content-table-customer-3 float-left ">
            {priceBill}
          </p>
          <p className="text-dollar-table-customer text-71D0A2">$</p>
        </td>
        <td className="pt-3 w-16">
          <p className="mb-0 text-4A4A6A text-content-table-customer-3 float-left ">
            {tip > 0 ? tip : "-"}
          </p>
          {tip > 0 && (
            <p className="text-dollar-table-customer text-8E8EA9">$</p>
          )}
        </td>
        <td className="text-dollar-table-customer text-32324D pt-3 w-16">
          {staff}
        </td>
        <td className="text-dollar-table-customer text-32324D pt-3 w-16">
          {timePrint}
        </td>
        <td className="text-dollar-table-customer text-32324D pt-3 ps-1 w-16">
          {customer}
        </td>
        <td
          className="text-dollar-table-customer text-32324D pt-3 w-2"
          onClick={gDetailOrder}
        >
          <img src={chervonRightPrimary} alt="" />
        </td>
      </tr>
      <ModalShowBillOrderCustomer
        handleClose={dismisModalShowBill}
        show={showModalShowBill}
        detailOrder={detailOrder}
        UserIdStore={UserIdStore}
        listPrinterName={listPrinterName}
        removeOrderById={removeOrderById}
      />
    </>
  );
};

export default ContentTableCustomer;
