import React, { useEffect, useState } from 'react';
import './styles.css'
import { calender, checkedSuccessGif } from '../../../components/ImgExport';
import Select from "react-select";
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import ModalCheckedSuccess from '../../../components/ModalSuccess/ModalCheckedSuccess';
import moment from "moment-timezone";
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import dayjs from 'dayjs';
import ModalLoading from '../../../components/ModalLoading';
import { addRevervation, checkRevervationCustomer } from '../../../api/apiCheckin/reservationApi';
import socket from '../../../utils/socket';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { formatDate } from '../../../utils/format';
import { getLanguageCheckIn } from '../../../Services/languageCheckin';

interface ShowMessError {
  daySelected: string,
  phoneNumber: string,
  timeSelected: string,
  seats: string,
  customer: string,
  fullName: string
}
const BookingOnline = () => {
  const err = {
    daySelected: '',
    phoneNumber: '',
    timeSelected: '',
    seats: '',
    customer: '',
    fullName: ''
  }
  const languageUI = getLanguageCheckIn();
  const nameStore = localStorage.getItem("namestore");
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = localStorage.getItem("ownerID");
  const firstName = localStorage.getItem("firstName");
  const phone = localStorage.getItem("phoneNumber");
  const [showModalOrderSuccess, setShowModalOrderSuccess] = useState(false)
  const [listDaySearch, setListDaySearch] = useState<{ value: string, dayName: string }[]>([]);
  const [daySelected, setDaySelected] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>(phone ?? '')
  const [timeSelected, setTimeSelected] = useState<dayjs.Dayjs | null | any>('')
  const [seats, setseats] = useState<number | string>()
  const [note, setNote] = useState<string>('')
  const [fullName, setFullName] = useState<string>(firstName ?? '')
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [ShowHistory, setShowHistory] = useState<boolean>(false);

  const [messError, setMessError] = useState<ShowMessError>(err)
  const [messFail, setMessFail] = useState<string>('')
  const navigate = useNavigate()

  const ResetForm = () => {
    var today = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
    var priorDate = new Date(new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)')).setDate(today.getDate()));
    setDaySelected(moment(priorDate).format('YYYY-MM-DD'))
    setPhoneNumber(phone ?? '')
    setTimeSelected('')
    setseats('')
    setNote('')
    setFullName(firstName ?? '')
  }
  //lấy 30 ngày
  const get30Day = () => {
    var today = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
    var listDay: { dayName: string, value: string }[] = [];
    for (let i = 0; i <= 7; i++) {
      var priorDate = new Date(new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)')).setDate(today.getDate() + i));
      if (i === 0) {
        let item = {
          dayName: languageUI.Today,
          value: moment(priorDate).format('YYYY-MM-DD')
        }
        setDaySelected(moment(priorDate).format('YYYY-MM-DD'))
        listDay.push(item)
      } else if (i === 1) {
        let item = {
          dayName: languageUI.Tomorow,
          value: moment(priorDate).format('YYYY-MM-DD')
        }
        listDay.push(item)
      } else {
        let item = {
          dayName: moment(priorDate).format('MM-DD-YYYY'),
          value: moment(priorDate).format('YYYY-MM-DD')
        }
        listDay.push(item)
      }
    }
    setListDaySearch([...listDay])
  }
  const handleOnchageDate = (e: any) => {
    if (e?.value) {
      setDaySelected(e?.value);
    } else {
      var today = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
      var priorDate = new Date(new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)')).setDate(today.getDate()));
      setDaySelected(moment(priorDate).format('YYYY-MM-DD'))
    }
  }
  // tắt modal ordersuccess
  const dismisModalOrderSuccess = () => {
    setShowModalOrderSuccess(false)
  }

  const submitOrder = async () => {
    const mess = err;
    var check = false;
    if (!phoneNumber || phoneNumber === '') {
      mess.phoneNumber = languageUI.pleaseEnterPhone;
      check = true
    }
    if (phoneNumber.length !== 10) {
      mess.phoneNumber = languageUI.phoneNumberFormatConsistsOf10Numbers;
      check = true
    }
    if (daySelected === '') {
      mess.daySelected = languageUI.Pleasechooseday;
      check = true
    }
    if (timeSelected === '' || isNaN(timeSelected?.$d)) {
      mess.timeSelected = languageUI.Clockinmalformed;
      check = true
    }
    if (!seats || Number(seats) <= 0) {
      mess.seats = languageUI.Pleaseenterseatsnumber;
      check = true
    }
    if (!custommerId && !ownerID) {
      setMessFail(languageUI.Customerinformationnotfoundpleaseloginagain)
      check = true
    }
    if (check) {
      setMessError({ ...mess })
    } else {
      setMessError({ ...err })
      setMessFail('')
      addOrderTable()
    }
  }

  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;

    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setPhoneNumber(phoneReplace);
  };

  const addOrderTable = async () => {


    const clockIntime = moment(formatDate(timeSelected?.$d)).format("hh:mm A");
    if (custommerId && ownerID) {
      setShowLoading(true);
      const req = await addRevervation(custommerId, ownerID, phoneNumber, daySelected, clockIntime, Number(seats), fullName, note);
      if (Number(req?.status) === 1) {
        setShowLoading(false)
        ResetForm()
        setShowModalOrderSuccess(true)
        socket.emit('PushNotificationBookingTable', { ownerID, customer_name: fullName, custommerId, message: languageUI.Youhaveanewtablereservation })
        checkShowHistory()
      } else {
        setShowLoading(false)
        setMessFail(req?.message ?? languageUI.TablebookingfailedPleasetryagainlater)
      }
    }
  }

  const checkShowHistory = async () => {
    if (custommerId && ownerID) {
      const req = await checkRevervationCustomer(custommerId, ownerID);
      if (Number(req?.status) === 1) {
        if (Number(req?.total) > 0) {
          setShowHistory(true)
        } else {
          setShowHistory(false)
        }
      } else {
        setShowHistory(false)
      }
    }
  }
  useEffect(() => {
    get30Day()
    checkShowHistory()
  }, [])

  const checkReplyBooking = async () => {
    if (custommerId) {
      await socket.on("PushReplyBookingTable" + custommerId, (data: any) => {
        checkShowHistory()
      })
    }


  }
  useEffect(() => {
    checkReplyBooking()
  }, [socket])
  return (
    <>
      <header className="w-100 custom-header-choose-menu d-flex align-items-center gap-3">
        {/* <img src={logoPrijack} alt="" /> */}
        <button className='px-4 py-1 border-none bg-orange rounded-4 shadow-button-default' onClick={() => navigate("/online/statusOrderMethodOnline/" + custommerId)}><ArrowBackRoundedIcon className='text-white' /></button>
        <p className="title-name-store mb-0">{nameStore}</p>
      </header>
      <main>
        <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
        <div className="account-pages  custom-pading-header pb-70px bg-white px-4" style={{ minHeight: "100vh" }}>
          {ShowHistory && (
            <div className='w-100 d-flex justify-content-end'>
              <button className='border-none mt-3 rounded-4 bg-orange px-6 py-2 d-flex gap-2 align-items-center shadow-button-default' onClick={() => navigate('/online/listReservation')}>
                <p className='text-white mb-0 font-bold'>{languageUI.Listreservation}</p>
                <HistoryIcon className='text-white font-bold' />
              </button>
            </div>
          )}

          <div className="p-4 w-100 shadow-choose-status-order h-fit rounded-5 mt-3">
            <h2 className="font-bold text-4a4a6a text-center m-0">{languageUI.Booking}</h2>
            <div className='d-flex justify-content-between items-center mt-3'>
              <p className="mb-0 font-bold text-4a4a6a">{languageUI.Fullname}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px w-224px h-56px">
                <input type="text" placeholder={languageUI.EnterFullName} className={`border-0 focus-visiable-none outline-none text-sm font-medium text-4a4a6a ${messError.fullName !== '' ? 'border-1px-red' : 'border-1px-gray'}`} value={fullName} onChange={e => setFullName(e.target.value)} />
              </div>
            </div>
            {messError.fullName && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.fullName}</p>)}
            <div className='d-flex justify-content-between items-center mt-3'>
              <p className="mb-0 font-bold text-4a4a6a">{languageUI.Phonenumber}</p>
              {/* <div className="d-flex align-items-center border  px-2  rounded-8px w-224px h-56px"> */}
              {/* <input type="number" placeholder='Enter phone number' className='border-0 focus-visiable-none outline-none text-sm font-medium text-4a4a6a' inputMode="numeric" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} /> */}
              <MaskedInput
                mask={[
                  "(",
                  /[0-9]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                placeholder="(___) ___-____"
                className={`form-control w-224px rounded-8px h-56px ${messError.phoneNumber !== '' ? 'border-1px-red' : 'border-1px-gray'}`}
                value={phoneNumber}
                onChange={onchagePhoneNumber}
              />
              {/* </div> */}

            </div>
            {messError.phoneNumber && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.phoneNumber}</p>)}
            <div className='d-flex justify-content-between items-center mt-3'>
              <p className="mb-0 font-bold text-4a4a6a">{languageUI.Chooseday}</p>
              <div className={`d-flex align-items-center border  px-2  rounded-8px w-224px h-56px ${messError.daySelected !== '' ? 'border-1px-red' : 'border-1px-gray'}`}>
                <img src={calender} alt="" />
                <Select
                  className={`react-select react-select-container w-180px  border-select-none`}
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.Today}
                  isClearable={true}
                  options={listDaySearch.map((item) => {
                    return {
                      value: item.value,
                      label: item.dayName,
                    };
                  })}
                  onChange={(e: any) => handleOnchageDate(e)}
                />
              </div>
            </div>
            {messError.daySelected && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.daySelected}</p>)}
            <div className='d-flex justify-content-between items-center mt-3'>
              <p className="mb-0 font-bold text-4a4a6a">{languageUI.Choosetime}</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']} >
                  <DesktopTimePicker className="border-0 w-224px"
                    value={timeSelected} onChange={(newValue) => {
                      setTimeSelected(newValue ?? '');
                    }} defaultValue={''}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            {messError.timeSelected && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.timeSelected}</p>)}
            <div className='d-flex justify-content-between items-center mt-3'>
              <p className="mb-0 font-bold text-4a4a6a">{languageUI.Seats}</p>
              <div className="d-flex align-items-center border  px-2  rounded-8px w-224px h-56px">
                <input type="number" placeholder={languageUI.Enterseat} className={`border-0 focus-visiable-none outline-none text-sm font-medium text-4a4a6a ${messError.seats !== '' ? 'border-1px-red' : 'border-1px-gray'}`} inputMode="numeric" value={seats} onChange={e => setseats(Number(e.target.value))} />
              </div>
            </div>
            {messError.seats && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messError.seats}</p>)}
            <div className='mt-3'>
              <p className="mb-0 font-bold text-4a4a6a">{languageUI.Note}</p>
              <textarea
                className="text-more-request2 mt-2"
                id="textarea"
                value={note}
                onChange={(e) => setNote(e.target.value ?? '')}
              ></textarea>
            </div>
          </div>
        </div >
        {messFail && (<p className="text-left text-italic mb-0 ms-2 text-danger mt-1">{messFail}</p>)}
      </main>
      {!ShowHistory && (
        <footer className="w-100 p-4 position-fixed bottom-0 right-0 shadow-choose-status-order">
          <div className='bg-primary text-white font-bold rounded-4 d-flex justify-content-center py-4' onClick={() => submitOrder()}>
            {languageUI.OrderTable}
          </div>
        </footer>
      )}

      <ModalCheckedSuccess handleClose={dismisModalOrderSuccess} img={checkedSuccessGif} show={showModalOrderSuccess} title='Order Success' handleSuccess={() => navigate('/online/listReservation')} />
    </>
  )
}
export default BookingOnline