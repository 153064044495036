import React, { useState } from "react";
import {
  useStripe,
  useElements,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";
import {
  StripeElements,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import { PaymentWithLinkStripe } from "../../../../api/apiCheckin/paymentApi";
import { StripeExpressCheckoutElementOptions } from "@stripe/stripe-js";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import socket from "../../../../utils/socket";
import { InfoCart } from "../../../../api/apiCheckin/addCartApi";
import { SideList } from "../../../../api/apiCheckin/orderConfilmApi";
import { addressDefaultCustomer } from "../../../../api/apiCheckin/addressApi";
import { ListPrinterByCatalog } from "../../../../api/apiOwner/SettingPrinterApi";
import { acceptOrderDoordash } from "../../../../api/apiCheckin/doordashApi";
import { URLWeb } from "../../../../api/Request";

interface CheckoutPageProps {
  // handleCreateToken: (card: string | number, token: Token) => void
  totalAmount: number;
  custommerId: string | null;
  table_id: string | null;
  tax: number;
  TipPrice: number;
  feeStripe: boolean;
  setShowLoading: (load: boolean) => void;
  staffId: string;
  voucherCode: string;
  voucherId: string;
  discount: number;
  ownerID: number;
  order_id?: string;
  orderInfo: InfoCart[];
  listSide: SideList[];
  feeShip: number;
  statusMethod: string | null;
  infoAddress?: addressDefaultCustomer;
  orderCode: string;
  requestMore: string;
  totalPriceSides: number;
  listPrinterName: ListPrinterByCatalog[];
  setOrder_id: (od: string) => void;
  setStatusPayVisa: (status: boolean) => void;
  setShowPaymentStatus: (status: boolean) => void;
  setPaymentMethodNameShowBill: (name: string) => void;
}
const CheckoutPage = ({
  totalAmount = 1,
  ownerID,
  TipPrice = 0,
  custommerId = "",
  feeStripe = false,
  setShowLoading,
  table_id = "",
  tax = 0,
  staffId,
  voucherCode = "",
  voucherId = "",
  discount = 0,
  order_id = "",
  listSide = [],
  orderInfo = [],
  feeShip = 0,
  statusMethod = "",
  infoAddress = undefined,
  orderCode = "",
  requestMore = "",
  totalPriceSides = 0,
  listPrinterName = [],
  setOrder_id,
  setStatusPayVisa,
  setShowPaymentStatus,
  setPaymentMethodNameShowBill,
}: CheckoutPageProps) => {
  const stripe = useStripe();
  const elements: StripeElements | null = useElements();
  const [messageError, setMessageError] = useState<string>("");

  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessageError(
        "Make sure to disable form submission until Stripe.js has loaded."
      );
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessageError(submitError?.message ?? "");
      return;
    }
    if (custommerId && ownerID) {
      setShowLoading(true);
      const cart = orderInfo.map((item) => {
        return {
          service_item_id: item.service_item_id,
          price: item.price,
          quantity: item.quantity,
          notefood_id: item.notefood_id,
          side_id: item.side_id,
          type: "2",
          notechef: item.notechef,
        };
      });
      const listSideDish = listSide.map((item) => {
        return {
          side_id: item.id,
          quantity: item.quantity,
        };
      });
      setShowLoading(true);
      var tax_price = tax > 0 ? (totalAmount * tax) / 100 : 0;
      var tip_amount = TipPrice > 0 ? TipPrice : 0;
      var fee = feeStripe ? ((totalAmount + TipPrice) * 3) / 100 : 0;

      const res = await PaymentWithLinkStripe(ownerID, totalAmount);

      const clientSecret = res?.data;
      if (clientSecret) {
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: URLWeb + "/webcheckin/choosemenu/" + custommerId,
          },
          redirect: "if_required",
        });
        if (error) {
          // This point is only reached if there's an immediate error when
          // confirming the payment. Show the error to your customer (for example, payment details incomplete)
          setMessageError(error?.message ?? "");
        } else {
          try {
            // Gọi API sau khi thanh toán thành công
            if (Number(statusMethod) === 2 && infoAddress) {
              const req_doordash = await acceptOrderDoordash(
                infoAddress?.phone,
                orderCode
              );
              if (Number(req_doordash?.status) === 1) {
                const pay = await paymentApi.updatePayOrderOnline(
                  Number(custommerId),
                  Number(ownerID),
                  requestMore,
                  cart,
                  totalAmount,
                  listSideDish,
                  voucherId,
                  voucherCode,
                  tip_amount,
                  staffId,
                  tax_price,
                  fee,
                  orderCode,
                  feeShip,
                  totalPriceSides,
                  paymentIntent?.id
                );
                if (Number(pay.status) === 1) {
                  socket.emit("PushNotificationPayMent", {
                    table_id,
                    ownerID,
                    customer_name: pay?.customer_name,
                    custommerId,
                    message: pay?.message,
                    order_id: pay?.order_id,
                    discount,
                  });
                  socket.emit("PushPrintOrder", {
                    table_id,
                    ownerID,
                    custommerId,
                    customer_name: pay?.customer_name,
                    order_id: pay?.order_id,
                    table_name: "Delivery",
                    requestMealList: requestMore,
                    totalPrice: totalAmount,
                    cartInfo: orderInfo,
                    listPrinterName,
                    listSide,
                  });
                  setPaymentMethodNameShowBill(
                    paymentIntent?.payment_method_types[0] ?? "Apple Pay"
                  );
                  setStatusPayVisa(true);
                  setShowPaymentStatus(true);
                  setOrder_id(pay.order_id ?? "");
                  setShowLoading(false);
                } else {
                  setMessageError(pay?.message ?? "");
                  setStatusPayVisa(false);
                  setShowLoading(false);
                  setShowPaymentStatus(true);
                }
              } else {
                setMessageError(req_doordash?.message ?? "");
                setStatusPayVisa(false);
                setShowLoading(false);
                setShowPaymentStatus(true);
              }
            } else {
              const pay = await paymentApi.updateStatusPayOrderPickUp(
                Number(custommerId),
                Number(ownerID),
                requestMore,
                cart,
                totalAmount,
                listSideDish,
                voucherId,
                voucherCode,
                tip_amount,
                staffId,
                tax_price,
                fee,
                totalPriceSides,
                paymentIntent?.id
              );
              if (Number(pay.status) === 1) {
                socket.emit("PushNotificationPayMent", {
                  table_id,
                  ownerID,
                  customer_name: pay?.customer_name,
                  custommerId,
                  message: pay?.message,
                  order_id: pay?.order_id,
                });
                socket.emit("PushPrintOrder", {
                  table_id,
                  ownerID,
                  custommerId,
                  customer_name: pay?.customer_name,
                  order_id: pay?.order_id,
                  table_name: "Pick up",
                  requestMealList: requestMore,
                  totalPrice: totalAmount,
                  cartInfo: orderInfo,
                  listPrinterName,
                  listSide,
                });
                setPaymentMethodNameShowBill(
                  paymentIntent?.payment_method_types[0] ?? "Apple Pay"
                );
                setStatusPayVisa(true);
                setShowPaymentStatus(true);
                setOrder_id(pay.order_id ?? "");
                setShowLoading(false);
              } else {
                setMessageError(pay?.message ?? "");
                setStatusPayVisa(false);
                setShowLoading(false);
                setShowPaymentStatus(true);
              }
            }
          } catch (error) {
            console.error("Error calling API:", error);
          }
        }
      }
    }
  };
  const options: StripeExpressCheckoutElementOptions = {
    wallets: {
      applePay: "always",
      googlePay: "always",
    },
  };
  return (
    <div id="checkout-page">
      <ExpressCheckoutElement onConfirm={onConfirm} options={options} />
    </div>
  );
};

export default CheckoutPage;
