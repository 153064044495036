import * as Request from "../Request";

export interface Result {
    status: number,
    message?: string,
    role?: string
}
export const PrintOrderPage = async (owner_id: number, printerName: string, contentHtml: string) => {
    
    const url = 'http://localhost:9000';

    // const data = new URLSearchParams();
    // data.append('printerName', 'XP-DF');
    // data.append('contentHtml', '<div>13243</div>');
    const data = {
        printerName: printerName,
        contentHtml: contentHtml
    }
    try {
        const fetchResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',

            },
            body: JSON.stringify(data),
        });


        if (!fetchResponse.ok) {
            // throw new Error(`HTTP error! Status: ${fetchResponse.status}`);
            return {
                message: "Error printing on " + printerName,
                status: false,
                data: [],
            };
        }
        const responseData = await fetchResponse.json();
        // console.log(responseData);
        // const responseData = await fetchResponse.json();
        return {
            message: responseData.status ? 'Success' : ("Error printing on " + printerName),
            status: responseData.status ?? false,
            data: [],
        };
    } catch (error: any) {
        console.error('Error:', error.message);
        return {
            message: "Error printing on " + printerName,
            status: false,
            data: [],
        };
    }

};