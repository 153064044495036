import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles.css"
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { flappyBirdGame, whackAMole } from "../../components/ImgExport";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { gScoreGame, upPointsRewardCustomer } from "../../api/apiCheckin/gameApi";
import { gConfigGame } from "../../api/apiOwner/SettingInformation";
import ModalLoading from "../../components/ModalLoading";
import { getLanguageCheckIn } from "../../Services/languageCheckin";



const RewardPage = () => {
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const languageUI = getLanguageCheckIn();
  const navigate = useNavigate();
  const [activeGame, setActiveGame] = useState<number>(1)
  const [flappyBirdPoint, setFlappyBirdPoint] = useState<number>(0)
  const [whackAMolePoint, setWhackAMolePoint] = useState<number>(0)
  const [poitnFlappyBirdGame, setPoitnFlappyBirdGameRes] = useState<number>(0);
  const [poitnWhackAMoleGame, setPoitnWhackAMoleGameRes] = useState<number>(0);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState('')
  const [redemptionPoints, setRedemptionPoints] = useState<number>(0)//điểm quy đổi
  const [redemptionResult, setRedemptionResult] = useState<number>(0)//kết quả quy đổi

  const getScoreGame = async () => {
    setShowLoading(true)
    const res = await gScoreGame(custommerId ?? '')
    if (Number(res.status) === 1) {
      setFlappyBirdPoint(Number(res.data?.score_flappybird) ?? 0)
      setWhackAMolePoint(Number(res.data?.score_whackamole) ?? 0)
      setShowLoading(false)
    } else {
      setShowLoading(false)
    }
  }

  const getConfigGame = async () => {
    if (ownerID) {
      const res = await gConfigGame(ownerID)
      if (Number(res.status) === 1) {
        setPoitnFlappyBirdGameRes(Number(res.data?.point_flappybird))
        setPoitnWhackAMoleGameRes(Number(res.data?.point_whackamole))
      }
    }
  }

  useEffect(() => {


    if ((flappyBirdPoint > 0 || whackAMolePoint > 0) && poitnFlappyBirdGame > 0 && poitnWhackAMoleGame > 0) {
      if (activeGame === 1) {
        let totalRewards = 0
        totalRewards = whackAMolePoint / poitnWhackAMoleGame
        setRedemptionResult(Math.floor(totalRewards))
        setRedemptionPoints(whackAMolePoint - (whackAMolePoint % poitnWhackAMoleGame))

      }

      if (activeGame === 2) {
        let totalRewards = 0
        totalRewards = flappyBirdPoint / poitnFlappyBirdGame
        setRedemptionResult(Math.floor(totalRewards))
        setRedemptionPoints(flappyBirdPoint - (flappyBirdPoint % poitnFlappyBirdGame))
      }
    }


  }, [activeGame, flappyBirdPoint, whackAMolePoint, poitnFlappyBirdGame, poitnWhackAMoleGame])

  const upPointsReward = async () => {
    if (custommerId && ownerID) {
      setMessageError('')
      setShowLoading(true)
      const req = await upPointsRewardCustomer(ownerID, custommerId, activeGame === 1 ? whackAMolePoint : flappyBirdPoint, activeGame)
      if (Number(req?.status) === 1) {
        setRedemptionResult(0)
        setRedemptionPoints(0)
        getScoreGame()
      } else {
        setShowLoading(false);
        setMessageError(req?.message ?? "Change fail")
      }
    }
  }
  useEffect(() => {
    getConfigGame()
    getScoreGame()
  }, [])
  return (

    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      {/* Menubody */}
      <header className="w-100 custom-header-choose-menu d-flex justify-content-between align-items-center">
        <div className=" px-4 py-1 bg-white d-flex  rounded-4 border" onClick={() => navigate('/webcheckin/listGame')}>
          <ArrowBackRoundedIcon className="text-orange" />
        </div>
        {/* <img src={logoPrijack} alt="" /> */}
        <p className="title-name-store mb-0">{languageUI.Rewards}</p>
        <div className=" px-4 py-1 bg-white d-flex  rounded-4 border opacity-0">
          <ArrowBackRoundedIcon className="text-orange" />
        </div>
      </header>
      <div className="account-pages   custom-pading-header bg-white h-100vh">
        <div className="px-0 px-sm-0 px-md-3 px-lg-4  position-relative pt-0 bg-white  d-flex">
          <div className="px-2 px-sm-2 px-md-3 px-lg-4">
            <div className="box-list-game" >
              <div className={`rounded-4 ${activeGame === 1 ? 'border-orange-4px shadow-button-default' : 'border-white-4px'}`} onClick={() => { setActiveGame(1) }}>
                <img src={whackAMole} alt="" className="w-100 aspect-square rounded-4" />
              </div>
              <div className={`rounded-4 ${activeGame === 2 ? 'border-orange-4px shadow-button-default' : 'border-white-4px'}`} onClick={() => { setActiveGame(2) }}>
                <img src={flappyBirdGame} alt="" className="w-100 aspect-square rounded-4" />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-xl font-bold text-11151f m-0">{activeGame === 1 ? 'Whack A Mole' : 'Flappy Bird'}:</p>
            <p className="text-base font-bold text-11151f m-0">{languageUI.yourPoints}: <p className="text-base font-bold text-orange d-inline m-0">{activeGame === 1 ? whackAMolePoint : flappyBirdPoint} {languageUI.point}</p></p>
          </div>
          <div className="px-4 d-flex gap-3 align-items-center mt-3">
            <div className="w-100 border rounded-4 h-40px font-bold text-4a4a6a d-flex align-items-center px-4">
              {redemptionPoints}
            </div>
            <SwapHorizIcon className="text-2xl" />
            <div className="w-100 border rounded-4 h-40px font-bold text-4a4a6a d-flex align-items-center px-4">
              {redemptionResult}
            </div>
          </div>
          <p className="text-base font-bold text-11151f m-0 mt-2">{languageUI.Remainingbalance}: <p className="text-base font-bold text-orange m-0 d-inline">{activeGame === 1 ? `${whackAMolePoint - redemptionPoints} ${languageUI.point}` : `${flappyBirdPoint - redemptionPoints} ${languageUI.point}`}</p></p>
          <div className="px-6">
            {messageError !== '' && (
              <div className="text-danger text-italic text-center">{messageError}</div>
            )}

            <button className="bg-orange border-none focus-visiable-none rounded-4 w-100 py-2 mt-3 shadow-button-default text-white font-bold text-xl" onClick={upPointsReward} disabled={redemptionPoints === 0}>
              {languageUI.Change}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardPage;