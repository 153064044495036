import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import './styles.css'
import { knifeFork } from "../../../../components/ImgExport";
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { ListSides, gListFreeSide } from "../../../../api/apiOwner/templateMenuApi";
import ModalLoading from "../../../../components/ModalLoading";
import { targetupload } from "../../../../api/urlImage";
import { DelCartSide, addSideToCartOnline, gListCartSideOnline, listSide } from "../../../../api/apiCheckin/addCartApi";
import SideDishItem from "../../../webcheckin/components/SideDishItem/SideDishITem";
import ModalShowSideDish from "../../../webcheckin/components/ModalShowSideDish/ModalShowSideDish";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";
interface ModalAddSideDishProps {
  show: boolean,
  totalSideChoosed: number,
  totalSide: number,
  dissmis: () => void
}
const ModalAddSideDishOnline = ({ show, dissmis, totalSide, totalSideChoosed = 0 }: ModalAddSideDishProps) => {
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const languageUI = getLanguageCheckIn();
  const [modalShowSideDish, setModalShowSideDish] = useState(false)
  const [totalChoosed, setTotalChoosed] = useState<number>(0)
  const [ListFreeSides, setListFreeSides] = useState<ListSides[]>([]);
  const [ListFreeSidesChoosed, setListFreeSidesChoosed] = useState<listSide[]>([]);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const dismisModalShowSideDish = () => {
    setModalShowSideDish(false)
  }
  const gListSideDish = async () => {
    if (custommerId && ownerID) {
      setShowLoading(true);
      const reqMenu = await gListFreeSide(ownerID);
      if (Number(reqMenu?.status) === 1) {
        setListFreeSides(reqMenu?.data ?? []);
      } else {
        setListFreeSides([]);
      }

      setShowLoading(false);
    }
  }

  const AddSideOnline = async (side_id: string) => {

    if (custommerId && ownerID && side_id) {
      setShowLoading(true);
      const req = await addSideToCartOnline(custommerId, ownerID, side_id);
      if (Number(req?.status) !== 0) {
        setShowLoading(false)
        setTotalChoosed(totalChoosed + 1)
      } else {
        setShowLoading(false)
      }

    }

  }

  const gListSideChoosedOnline = async () => {
    if (custommerId && ownerID) {
      setShowLoading(true)
      const req = await gListCartSideOnline(custommerId, ownerID);
      if (Number(req?.status) === 1) {
        setListFreeSidesChoosed(req?.data ?? [])
        setModalShowSideDish(true)
        setShowLoading(false)
      } else {
        setShowLoading(false)
      }
    }
  }
  const DeleteSide = async (id: string, quantitySide: number) => {
    const req = await DelCartSide(id);
    if (Number(req?.status) === 1) {
      setListFreeSidesChoosed(ListFreeSidesChoosed.filter(item => item.id != id))
      setTotalChoosed(totalChoosed - quantitySide)
    }

  }
  useEffect(() => {
    if (show) {
      gListSideDish()
      setTotalChoosed(totalSideChoosed)
    }

  }, [show])
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal show={show} fullscreen={true} onHide={dissmis}>
        <Modal.Header className="shadow-header border-bottom">
          <Modal.Title className="text-center text-414151f font-bold text-20px">{languageUI.SideDish} (x{totalSide})</Modal.Title>
          <div className="opacity-0  px-4 py-2 ">
            <ArrowBack />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="box-add-side-dish">
            {ListFreeSides.map((item, i) => (
              <SideDishItem key={i} id={item?.id} description={item?.description} img={`${targetupload}${item.imageCourse}`} name={item?.name} price={Number(item.price)} AddSide={AddSideOnline} disabled={totalSide === totalChoosed} />
            ))}

          </div>
        </Modal.Body>
        <Modal.Footer className="box-shadow-footer">
          <div className=" d-flex gap-3 align-items-center w-100">
            <div className="position-relative flex-shrink-0" onClick={() => gListSideChoosedOnline()}>
              <FastfoodIcon style={{ fontSize: '36px' }} />
              <div className="number-cart-3">
                {totalChoosed}
              </div>
            </div>
            {/* <a href="/webcheckin/OrderStatus" className="w-100"> */}

            <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={dissmis}>
              <img src={knifeFork} alt="" />
              {languageUI.confirm}
            </button>
            {/* </a> */}
          </div>
        </Modal.Footer>
        <ModalShowSideDish dismis={dismisModalShowSideDish} show={modalShowSideDish} ListFreeSidesChoosed={ListFreeSidesChoosed} DeleteSide={DeleteSide} />
      </Modal>
    </>
  )
}
export default ModalAddSideDishOnline