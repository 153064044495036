import * as Request from "../Request";

export interface Result<T> {
    status: number,
    message: string,
    data: T,
    totalOuTofStock: string,
    totalExpiringSoon: string
}
export interface ListInventoryWarehouse {
    id: string,
    ingredient_id: string,
    Inventory_stock: string,
    reminder_option: string,
    option_value: string,
    material_code: string,
    ingredient_name: string,
    unit_name: string,
    unit_id: string,
    dateadd: string,
    status: string,
    statusName: string
}
export interface InfoMaterial {
    Inventory_stock: string,
    id: string,
    ingredient_id: string,
    ingredient_name: string,
    unit_id: string,
    unit_name: string
}
export const gListInventoryWarehouse = async (owner_id: number) => {
    return await Request.fetchAPI('owner/gListInventoryWarehouse.php', "POST", { owner_id })
        .then((res: Result<ListInventoryWarehouse[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
                totalOuTofStock: '0',
                totalExpiringSoon: '0'
            };
        });
};

export const addMaterialByOwner = async (owner_id: number, ingredient_name: string, unit_id: string) => {
    return await Request.fetchAPI('owner/addMaterial.php', "POST", { owner_id, ingredient_name, unit_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: ''
            };
        });
};
export const gInfoMaterial = async (ingredient_id: string) => {
    return await Request.fetchAPI('owner/gInfoMaterial.php', "POST", { ingredient_id })
        .then((res: Result<InfoMaterial>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};
export const importMaterial = async (owner_id: number, ingredient_id: string, date_add: string, quantity: number, total_price: number, importer: string, reminder_option: number, option_value: number) => {
    return await Request.fetchAPI('owner/importMaterial.php', "POST", { ingredient_id, owner_id, date_add, quantity, total_price, importer, reminder_option, option_value })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};
export const ExportMaterial = async (owner_id: number, ingredient_id: string, date_export: string, quantity: number, exporter: string) => {
    return await Request.fetchAPI('owner/exportMaterial.php', "POST", { ingredient_id, owner_id, date_export, quantity, exporter })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};

export const DelMaterial = async (material_id: string) => {
    return await Request.fetchAPI('owner/delete-material.php', "POST", { material_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined
            };
        });
};