import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../Styles.css"
import { useEffect, useState } from "react";
import { leaf1, leaf2, minusGreen, plusGreen } from "../../../../../components/ImgExport";

interface ModalUpgragePageProps {
    show: boolean;
    handleClose: () => void;
    handlePayment: () => void;
}

const ModalUpgragePage = ({
    show,
    handleClose,
    handlePayment,
}: ModalUpgragePageProps) => {

    return (
        <>
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Header className="py-2 border-bottom" closeButton>
                    <Modal.Title>Upgrade Package</Modal.Title>
                </Modal.Header>
                <Modal.Body className="py-3">
                    <h3 className="text-center">
                        Are you sure you want to change the package ?
                    </h3>
                </Modal.Body>
                <Modal.Footer className="justify-content-end border-top">
                    <Button variant="primary" onClick={() => handlePayment()}>
                        Yes,I want
                    </Button>
                    <Button variant="danger" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
};

export default ModalUpgragePage;
