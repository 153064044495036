import * as Request from "../Request";
import { listSide } from "./addCartApi";

export interface InfoCart {
  id?: string;
  owner_id?: string;
  custommer_id?: string;
  table_id?: string;
  service_item_id?: string;
  price?: string | number;
  quantity?: string;
  notefood_id?: string;
  side_id?: string;
  type?: string;
  notechef?: string;
  delf?: string;
  timestamp?: string;
  name?: string;
  imageCourse?: string;
  donvitinh?: string;
  table_name?: string;
  notefood_name?: string;
  side_name?: string;
  slcon?: string;
  insufficient?: boolean;
  description?: string;
}

export interface orderHistory {
  id: string;
  price: string;
  custommer_id: string;
  timeend: string;
  totalItem: string;
}

export interface foodItem {
  id: number;
  service_item_id: number;
  price: number;
  quantity: number;
  notefood_id: number;
  side_id: number;
  form_order: number;
  note: string;
  name: string;
  imageCourse: string;
  donvitinh: any;
  notefood_name: any;
  side_name: any;
  description: string;
}

export interface RequestOrderHistory {
  timestart: string;
  listFood: foodItem[];
}

export interface Result {
  status: number;
  message?: string;
  data?: [];
  table_name?: string;
  order_id?: string;
  customer_name: string;
}

export interface dataReq<T> {
  status: number;
  message: string;
  data: T;
}

export const CreateOrder = async (
  custommerId: string,
  ownerID: number,
  table_id: string,
  requestMealList: string,
  totalPrice: number,
  cartInfo: InfoCart[],
  sideDish: listSide[] = [],
  priceSides: number = 0
) => {
  return await Request.fetchAPI(`checkin/createOrder.php`, "POST", {
    custommerId,
    ownerID,
    table_id,
    requestMealList: JSON.stringify(requestMealList),
    totalPrice,
    cartInfo: JSON.stringify(cartInfo),
    sideDish: JSON.stringify(sideDish),
    priceSides,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        table_name: "",
        order_id: "",
        customer_name: "",
      };
    });
};
//tạo hóa đơn online khi chọn send order
export const CreateOrderOnline = async (
  custommerId: string,
  ownerID: number,
  requestMealList: string,
  totalPrice: number,
  cartInfo: InfoCart[],
  sideDish: listSide[] = [],
  priceSides: number = 0
) => {
  return await Request.fetchAPI(`checkin/createOrderOnline.php`, "POST", {
    custommerId,
    ownerID,
    requestMealList: JSON.stringify(requestMealList),
    totalPrice,
    cartInfo: JSON.stringify(cartInfo),
    sideDish: JSON.stringify(sideDish),
    priceSides,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
        table_name: "",
        order_id: "",
        customer_name: "",
      };
    });
};
//tạo hóa đơn online khi chọn send order
export const gHistoryOrder = async (customer_id: string, owner_id: number) => {
  return await Request.fetchAPI(
    `checkin/gListHistoryOrderCustomer.php`,
    "POST",
    { customer_id, owner_id }
  )
    .then((res: dataReq<orderHistory[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

//
export const gListHistoryOrderCustomerV2 = async (
  customer_id: string,
  owner_id: number
) => {
  return await Request.fetchAPI(
    `checkin/gListHistoryOrderCustomerV2.php`,
    "POST",
    { customer_id, owner_id }
  )
    .then((res: dataReq<RequestOrderHistory[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
