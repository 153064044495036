import "./Styles.css";
import { useEffect, useState } from "react";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import {
  billTable,
  cartTable,
  clockTable,
  customerTable,
  databaseTable,
  usersTable,
} from "../../components/ImgExport";
import * as paymentApi from "../../api/apiCheckin/paymentApi";
import * as custommerRecieptApi from "../../api/apiOwner/custommerRecieptApi";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment-timezone";
import ModalLoading from "../../components/ModalLoading";
import React from "react";
import ContentTableOfflineMode from "./components/ContentTableOfflineMode/ContentTableOfflineMode";
import * as orderSlice from "../../store/slices/orderSlice";
import { FormatDolla } from "../../utils/format";
import { submitOrderPos_Offline } from "../../api/apiOwner/menuPosApi";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { getLanguageUI } from "../../Services/languageUI";

interface CustomerRecieptsProps {
  listStaff?: paymentApi.TipStaff[];
}
const OfflineBooking: React.FC<CustomerRecieptsProps> = () => {
  const languageUI = getLanguageUI().offlineModePage;
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const listOrderOffline = useSelector(orderSlice.selectorOrder);

  const gListCustomerRecept = async (
    page: number = 1,
    staffId: string = "",
    daySearch: string = "",
    searchKey: string = ""
  ) => {
    if (UserIdStore) {
      setShowLoading(true);
      const glistCustomer = await custommerRecieptApi.gListCustomerReceipts(
        UserIdStore,
        page,
        daySearch,
        staffId,
        searchKey
      );
      if (Number(glistCustomer.status) === 1) {
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const SubmitOrderOff = async () => {
    if (UserIdStore) {
      const listOrder = listOrderOffline.filter(
        (item, i) =>
          (typeof item?.order_id === "string" &&
            item?.order_id?.search("OL") > -1) ||
          item?.is_end === 1
      );
      const req = await submitOrderPos_Offline(UserIdStore, listOrder);
      if (Number(req.status) === 1 && req?.data) {
        const newOrder = listOrderOffline.filter(
          (item, i) => !req?.data?.some((or) => or === item?.order_id)
        );
        dispatch(orderSlice.setOrder([...newOrder]));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Update success!",
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };

  useEffect(() => {
    if (UserIdStore) {
      gListCustomerRecept();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="h-70px mt-header bg-white px-4 d-flex align-items-center border-bottom container-fluid custom-header-search w-100-190px d-flex justify-content-between">
        <p className="fitlter-pos-menu mb-0 d-flex align-items-center">
          {languageUI.offlineMode}
        </p>
        <div className="btn-submit-offline" onClick={SubmitOrderOff}>
          {languageUI.submit}
        </div>
      </div>
      <div className="w-100 d-flex flex-nowrap justify-content-between page-menu-post-table overflow-hidden">
        <div className="w-100 h-100 p-3">
          <div className="w-100 h-100 bg-white rounded-12px custom-h-body-page-customer">
            <div className="table-container">
              <table className="w-100 px-2 table-customer ">
                <tr className="w-100 bg-F6F6F9 header-table-customer d-flex align-items-center">
                  <th className="text-title-table w-10 ps-2  d-flex align-items-center">
                    {languageUI.table}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={cartTable} alt="" className="" />{" "}
                    {languageUI.order}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={billTable} alt="" className="" />{" "}
                    {languageUI.bill}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={databaseTable} alt="" className="" /> Tip
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={usersTable} alt="" className="mb-3px" />{" "}
                    {languageUI.staff}
                  </th>
                  <th className="text-title-table w-16 d-flex align-items-center">
                    {" "}
                    <img src={clockTable} alt="" className="mb-3px" />{" "}
                    {languageUI.timePrint}
                  </th>
                  <th className="text-title-table w-18 d-flex align-items-center">
                    {" "}
                    <img src={customerTable} alt="" className="mb-2px" />{" "}
                    {languageUI.customer}
                  </th>
                </tr>

                <tbody className="offline-mode">
                  {listOrderOffline.map(
                    (item, i) =>
                      ((typeof item?.order_id === "string" &&
                        item?.order_id?.search("OL") > -1) ||
                        item?.is_end === 1) && (
                        <ContentTableOfflineMode
                          key={"td" + i}
                          customer={item?.name ? item?.name : "-"}
                          idOrder={`${item?.order_id}`}
                          numerical={
                            item?.listFood[0].table_name
                              ? item?.listFood[0].table_name
                              : "-"
                          }
                          priceBill={FormatDolla(Number(item?.totalPrice))}
                          staff={item?.staff_name ? item?.staff_name : "-"}
                          timePrint={moment(new Date()).format("DD/MM/YYYY")}
                          tip={
                            Number(item?.tip_amount) > 0
                              ? Number(item?.tip_amount)
                              : 0
                          }
                          UserIdStore={UserIdStore ? UserIdStore : 0}
                          setShowLoading={(e) => setShowLoading(e)}
                        />
                      )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfflineBooking;
