import * as Request from "../Request";

export const GetAccountInfor = async (UserIdStore: any) => {
  return await Request.fetchAPI(
    `/admin/gInfoAdmin.php?id=${UserIdStore}`,
    "GET"
  )
    .then((res) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const updateAccountInfor = async (
  admin_id: any,
  contact_person: string,
  store_name: string,
  address: string,
  email: string,
  city: string
) => {
  return await Request.fetchAPI(`admin/editAdmin.php`, "POST", {
    admin_id: admin_id,
    contact_person: contact_person,
    store_name: store_name,
    address: address,
    email: email,
    city: city,
  })
    .then((res) => {
      return res;
    })
    .catch((err: any) => {

      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
