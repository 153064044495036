import React, { useState } from "react";
import {
  useStripe,
  useElements,
  ExpressCheckoutElement,
} from "@stripe/react-stripe-js";
import {
  StripeElements,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import { PaymentWithLinkStripe } from "../../../../api/apiCheckin/paymentApi";
import { StripeExpressCheckoutElementOptions } from "@stripe/stripe-js";
import * as paymentApi from "../../../../api/apiCheckin/paymentApi";
import socket from "../../../../utils/socket";

interface CheckoutPageProps {
  // handleCreateToken: (card: string | number, token: Token) => void
  totalAmount: number;
  custommerId: string | null;
  table_id: string | null;
  tax: number;
  TipPrice: number;
  feeStripe: boolean;
  setShowLoading: (load: boolean) => void;
  staffId: string;
  voucherCode: string;
  voucherId: string;
  discount: number;
  ownerID: number;
  order_id?: string;
  clearToken: () => void;
  setStatusPayVisa: (status: boolean) => void;
  setShowPaymentStatus: (status: boolean) => void;
  setPaymentMethodNameShowBill: (name: string) => void;
}
const CheckoutPage = ({
  totalAmount = 1,
  ownerID,
  TipPrice = 0,
  custommerId = "",
  feeStripe = false,
  setShowLoading,
  table_id = "",
  tax = 0,
  staffId,
  voucherCode = "",
  voucherId = "",
  discount = 0,
  order_id = "",
  clearToken,
  setStatusPayVisa,
  setShowPaymentStatus,
  setPaymentMethodNameShowBill,
}: CheckoutPageProps) => {
  const stripe = useStripe();
  const elements: StripeElements | null = useElements();
  const [messageError, setMessageError] = useState<string>("");

  const onConfirm = async (event: StripeExpressCheckoutElementConfirmEvent) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMessageError(
        "Make sure to disable form submission until Stripe.js has loaded."
      );
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setMessageError(submitError?.message ?? "");
      return;
    }
    if (custommerId && ownerID && table_id) {
      setShowLoading(true);
      var tax_price = tax > 0 ? (totalAmount * tax) / 100 : 0;
      var tip_amount = TipPrice > 0 ? TipPrice : 0;
      var fee = feeStripe ? ((totalAmount + TipPrice) * 3) / 100 : 0;
      const res = await PaymentWithLinkStripe(ownerID, totalAmount);

      const clientSecret = res?.data;
      if (clientSecret) {
        const { error, paymentIntent } = await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url:
              "http://localhost:3000/webcheckin/choosemenu/" + custommerId,
          },
          redirect: "if_required",
        });
        if (error) {
          // This point is only reached if there's an immediate error when
          // confirming the payment. Show the error to your customer (for example, payment details incomplete)
          setMessageError(error?.message ?? "");
        } else {
          try {
            // Gọi API sau khi thanh toán thành công
            const Payment = await paymentApi.upDateOrderPay(
              order_id,
              ownerID,
              custommerId,
              totalAmount,
              tax_price,
              totalAmount,
              fee,
              staffId,
              tip_amount,
              voucherCode,
              voucherId,
              discount,
              paymentIntent?.id
            );

            if (Number(Payment.status) === 1) {
              socket.emit("PushNotificationPayMent", {
                table_id,
                ownerID,
                customer_name: Payment?.customer_name,
                custommerId,
                message: Payment?.message,
                order_id: Payment?.order_id,
              });
              setPaymentMethodNameShowBill(
                paymentIntent?.payment_method_types[0] ?? "Apple Pay"
              );
              clearToken();
              setShowLoading(false);

              setStatusPayVisa(true);
              setShowPaymentStatus(true);
            } else {
              setMessageError(Payment?.message ?? "");
              setStatusPayVisa(false);
              setShowLoading(false);
              setShowPaymentStatus(true);
            }
          } catch (error) {
            console.error("Error calling API:", error);
          }
        }
      }
    }
  };
  const options: StripeExpressCheckoutElementOptions = {
    wallets: {
      applePay: "always",
      googlePay: "always",
    },
  };
  return (
    <div id="checkout-page">
      <ExpressCheckoutElement onConfirm={onConfirm} options={options} />
    </div>
  );
};

export default CheckoutPage;
