import { Button, Modal } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
interface ModalChangeLastMonthProps {
  show: boolean;
  dismis: () => void;
  handleChangeLastYear: (value: number) => void;
  value: number;
}
const ModalChangeLastYear = ({
  show,
  handleChangeLastYear,
  dismis,
  value,
}: ModalChangeLastMonthProps) => {
  const [valueLastYear, setValueLastYear] = useState<number>();

  useEffect(() => {
    setValueLastYear(value);
  }, [value]);
  return (
    <Modal
      show={show}
      // eslint-disable-next-line no-sequences
      onHide={dismis}
      centered
      className="modal-rounded-16px"
    >
      <Modal.Header className="py-2 border-bottom" closeButton>
        <Modal.Title>Change Last Year Number</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <div>
          <input
            className="input-last-year"
            placeholder="please enter number!"
            type="number"
            value={valueLastYear?.toString()}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                setValueLastYear(undefined);
              } else {
                setValueLastYear(Number(e.target.value));
              }
            }}
          />
        </div>
        {/* <label htmlFor="messLength">Number of message characters: </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={messSendAll.length}
            readOnly
          /> */}
      </Modal.Body>
      <Modal.Footer className="justify-content-end border-top">
        <Button
          variant="primary"
          onClick={() => handleChangeLastYear(valueLastYear ?? 0)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalChangeLastYear;
