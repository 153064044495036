import * as Request from "../Request";

export const ChangePassword = async (UserIdStore: number, OldPass: string, NewPass: string, RetypeNewPass: string ) => {
    return  await Request.fetchAPI('admin/change_password.php', 'post', {
        "id_admin": UserIdStore,
        "old_password": OldPass,
        "new_password": NewPass,
        "re_password": RetypeNewPass
    }).then((res:IChangePassWord) => {
        return res;
    }).catch((err:any) => {
        return  {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};

export interface IChangePassWordData {
    total?: number;
    BM?: number;
    BY?: number;
    B5Y?: number;
    LM?: number;
    LY?: number;
    L5Y?: number;
    SM?: number;
    SY?: number;
    S5Y?: number;
}
export interface IChangePassWord {
    message: string,
    status: number,
    data: []
}