import { useEffect, useState } from "react"
import "./Styles.css"
import { HomeOutlineInventory } from "../../../../components/SVGChangeColor/SVGChangeColor";
import { downloadInventory, plusInventory, printerInventory, uploadInventory } from "../../../../components/ImgExport";
import ModalPreviewInventory from "./component/ModalPreviewInventory";
import ModalCreateMatrial from "./component/ModalCreateMatrial";
import ModalImportInventory from "./component/ModalImportInventory";
import ModalExportInventory from "./component/ModalExportInventory";
import TableInventory from "../TableInventory";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import * as InventoryApi from "../../../../api/apiOwner/InventoryApi";
import ModalLoading from "../../../../components/ModalLoading";
import ModalToast from "../ModalToast/ModalToastErr";
import * as comboApi from "../../../../api/apiOwner/comboApi";
import React from "react";
import { getLanguageUI } from "../../../../Services/languageUI";


const Inventory = () => {
    const languageUI = getLanguageUI().inventoryPage
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [chooseFillTableInventory, setChooseFillTableInventory] = useState<number>(1)
    const [showModalPreviewInventory, setShowModalPreviewInventory] = useState(false);
    const [showModalCreateMaterial, setShowModalCreateMaterial] = useState(false);
    const [ShowLoading, setShowLoading] = useState<boolean>(false);
    const [showModalImportInventory, setShowModalImportInventory] = useState(false);
    const [showModalExportInventory, setShowModalExportInventory] = useState(false);
    const [listInventory, setListInventory] = useState<InventoryApi.ListInventoryWarehouse[]>([])
    const [listInventoryFilter, setListInventoryFilter] = useState<InventoryApi.ListInventoryWarehouse[]>([])
    const [totalAll, setTotalAll] = useState<number>(0)
    const [totalOutStock, setTotalOutStock] = useState<number>(0)
    const [totalExpiring, setTotalExpiring] = useState<number>(0)
    const [showToast, setShowToas] = useState<boolean>(false)
    const [messageSuccess, setMessageSuccess] = useState<string>('')
    const [comboUnit, setComboUnit] = useState<comboApi.ComboUnit[]>([])
    const [comboIngredient, setComboIngredient] = useState<comboApi.ComboIngredient[]>([])


    const dissmisModalImportInventory = () => {
        setShowModalImportInventory(false);
    }

    const dissmisModalExportInventory = () => {
        setShowModalExportInventory(false);
    }
    const dissmisModalShowPreviewInventory = () => {
        setShowModalPreviewInventory(false);
    }
    const dismisModalCreateMaterial = () => {
        setShowModalCreateMaterial(false);
    }

    const handleMaterialSuccess = (message: string) => {
        setShowModalCreateMaterial(false);

        setMessageSuccess(message);
        gComboMaterial()
        setShowToas(true)
        gAllInvenry()
    }
    const handleSuccessImport = (message: string) => {
        setShowModalImportInventory(false);
        setMessageSuccess(message);
        gAllInvenry()
        setShowToas(true)
    }
    const handleSuccessExport = (message: string) => {
        setShowModalExportInventory(false);
        setMessageSuccess(message);
        setShowToas(true)
        gAllInvenry()

    }

    const gAllInvenry = async () => {
        setChooseFillTableInventory(1)
        if (UserIdStore) {
            setShowLoading(true)
            const reqIn = await InventoryApi.gListInventoryWarehouse(UserIdStore);
            if (Number(reqIn?.status) === 1) {
                setListInventory(reqIn?.data ?? [])
                setListInventoryFilter(reqIn?.data ? (reqIn?.data.map((item, i) => { return { ...item, stt: i + 1 } })) : [])
                setTotalAll(reqIn?.data.length)
                setTotalExpiring(Number(reqIn?.totalExpiringSoon))
                setTotalOutStock(Number(reqIn?.totalOuTofStock))
                setShowLoading(false)
            } else {
                setShowLoading(false)
            }
        }
    }
    const HandleFilter = (tabid: number) => {
        setChooseFillTableInventory(tabid)
        if (tabid === 1) {
            setListInventoryFilter([...listInventory]);
        } else if (tabid === 2) {
            setListInventoryFilter([...listInventory.filter(item => Number(item?.status) === 0)])
        } else {
            setListInventoryFilter([...listInventory.filter(item => Number(item?.status) === 1)])
        }
    }

    const gComboUnit = async () => {
        const reqCombo = await comboApi.gComboUnitbyOwner();
        if (Number(reqCombo?.status) === 1) {
            setComboUnit(reqCombo?.data ?? [])
        }
    }
    const gComboMaterial = async () => {
        if (UserIdStore) {
            const req = await comboApi.gComboIngredientbyOwner(UserIdStore);
            if (Number(req?.status) === 1) {
                setComboIngredient(req?.data ?? []);
            }
        }
    }

    const HandelDelSuccess = () => {
        gAllInvenry()
        gComboMaterial()
    }

    useEffect(() => {
        gAllInvenry()
        gComboUnit()
        gComboMaterial()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <ModalToast show={showToast} handleClose={() => setShowToas(false)} content={messageSuccess} />
            <div className="px-3 pt-118px  h-100vh pb-48px  w-100">
                <div className="bg-white  h-100 w-100 rounded-18px box-shadow-inventory">
                    <div className=" w-100 p-6 d-flex align-items-center justify-content-between">
                        <div className="d-flex gap-3 align-items-center">
                            <p className="mb-0 text-inventory">{languageUI.inventory}</p>
                            <div className="bg-F8F9FA rounded-8px d-flex align-items-center gap-1 p-1">
                                <div className={`d-flex gap-2 h-40px px-16px align-items-center ${chooseFillTableInventory === 1 ? "box-choose-fill-table-inventory" : ""}`} onClick={() => HandleFilter(1)}>
                                    <HomeOutlineInventory fill={`${chooseFillTableInventory === 1 ? "#12B064" : "#4A4A6A"}`} />
                                    <p className={`mb-0 text-fill-table-inventory ${chooseFillTableInventory === 1 ? "text-12B064" : "text-4A4A6A"}`}>{languageUI.all} ({totalAll})</p>
                                </div>
                                <div className={`d-flex gap-2 h-40px px-16px align-items-center ${chooseFillTableInventory === 2 ? "box-choose-fill-table-inventory" : ""}`} onClick={() => HandleFilter(2)}>
                                    <HomeOutlineInventory fill={`${chooseFillTableInventory === 2 ? "#12B064" : "#4A4A6A"}`} />
                                    <p className={`mb-0 text-fill-table-inventory ${chooseFillTableInventory === 2 ? "text-12B064" : "text-4A4A6A"}`}>{languageUI.outOfStock} ({totalOutStock})</p>
                                </div>
                                <div className={`d-flex gap-2 h-40px px-16px align-items-center ${chooseFillTableInventory === 3 ? "box-choose-fill-table-inventory" : ""}`} onClick={() => HandleFilter(3)}>
                                    <HomeOutlineInventory fill={`${chooseFillTableInventory === 3 ? "#12B064" : "#4A4A6A"}`} />
                                    <p className={`mb-0 text-fill-table-inventory ${chooseFillTableInventory === 3 ? "text-12B064" : "text-4A4A6A"}`}>{languageUI.expiringSoon} ({totalExpiring})</p>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-12px">
                            <div className="btn-option-table-inventory" onClick={() => { if (listInventoryFilter.length === 0) { return; } setShowModalPreviewInventory(true) }} >
                                <img src={printerInventory} alt="" />
                                <p className="mb-0 text-btn-option-table-inventory">{languageUI.print} ({listInventoryFilter.length})</p>
                            </div>
                            <div className="btn-option-table-inventory" onClick={() => setShowModalCreateMaterial(true)}>
                                <img src={plusInventory} alt="" />
                                <p className="mb-0 text-btn-option-table-inventory">{languageUI.createMaterial}</p>
                            </div>
                            <div className="btn-option-table-inventory" onClick={() => setShowModalImportInventory(true)}>
                                <img src={uploadInventory} alt="" />
                                <p className="mb-0 text-btn-option-table-inventory">{languageUI.import}</p>
                            </div>
                            <div className="btn-option-table-inventory" onClick={() => setShowModalExportInventory(true)}>
                                <img src={downloadInventory} alt="" />
                                <p className="mb-0 text-btn-option-table-inventory" >{languageUI.export}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <TableInventory listInventoryFilter={listInventoryFilter} HandelDelSuccess={HandelDelSuccess} />
                    </div>
                </div>
            </div>
            <ModalPreviewInventory handleClose={dissmisModalShowPreviewInventory} show={showModalPreviewInventory} listInventoryFilter={listInventoryFilter} UserIdStore={UserIdStore} />
            <ModalCreateMatrial handleClose={dismisModalCreateMaterial} show={showModalCreateMaterial} comboUnit={comboUnit} UserIdStore={UserIdStore} handleSuccess={handleMaterialSuccess} setShowLoading={setShowLoading} />
            <ModalImportInventory handleClose={dissmisModalImportInventory} show={showModalImportInventory} comboIngredient={comboIngredient} setShowLoading={setShowLoading} UserIdStore={UserIdStore} handleSuccess={handleSuccessImport} />
            <ModalExportInventory handleClose={dissmisModalExportInventory} show={showModalExportInventory} comboIngredient={comboIngredient} setShowLoading={setShowLoading} UserIdStore={UserIdStore} handleSuccess={handleSuccessExport} />

        </>
    );
};

export default Inventory;
