
import Sheet from 'react-modal-sheet';
import "./Styles.css"
import { iconBill, iconLeft, imgFailPayment, storeWhite, user } from "../../components/Image";
import { getLanguageCheckIn } from '../../Services/languageCheckin';

interface PaymentStatusProps {
  helpText?: string;
  bottomLinks?: any;
  isCombineForm?: boolean;
  children?: any;
  show: boolean;
  status: boolean;
  handelClose: () => void;
  handelCallStaff: () => void;
  NextMyBill: () => void;
  BackHomeHandle: () => void;
}


const PaymentStatus = ({
  helpText,
  bottomLinks,
  children,
  isCombineForm,
  show,
  handelClose,
  handelCallStaff,
  NextMyBill,
  BackHomeHandle,
  status
}: PaymentStatusProps) => {
  const languageUI = getLanguageCheckIn();
  const namestore = localStorage.getItem("namestore");
  const HandleLeft = () => {
    if (status) {
      NextMyBill()
    } else {
      handelClose()
    }
  }
  const HandleRight = () => {
    if (status) {
    } else {
      handelCallStaff()
    }
  }

  const checkClose = () => {
    if (!status) {
      handelClose()
    }
  }
  return (

    <>
      <Sheet
        // ref={ref}
        isOpen={show}
        onClose={handelClose}
        snapPoints={[1000]}
        disableDrag={true}
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <header className="w-100 custom-header-choose-menu d-flex align-items-center">
              {/* <img src={logoPrijack} alt="" /> */}
              <p className="title-name-store mb-0">{namestore}</p>
            </header>

            <div className="account-pages d-flex  custom-pading-header bg-white " >
              <div className="m-auto rounded-3 h-100 overflow-y-scroll" style={{ height: "calc(100vh - 230px)" }}>
                <div>
                  <p className="text-center text-8E8EA9 text-payment">{status ? languageUI.Thanksforyourpayment : languageUI.Yourpaymentwasnotsuccessful}</p>
                  <p className={`text-center text-payment-status ${status ? ' text-24A44B' : 'text-CB0E0E'}`}>{status ? languageUI.PaymentSuccess : languageUI.PaymentFailed}</p>
                  <div hidden={status ? false : true} className='px-5'>
                    <div className='box-payment-success' >
                      <p className='text-center text-notification-payment-success'>{languageUI.Youmightnowclosethiswindow}</p>
                      <p className='text-center text-notification-payment-success font-weight-600'>{languageUI.Ifyouwishtoorderinthefuture} <br /> {languageUI.PleaserescantheQRcode}</p>
                      <p className='text-center text-notification-payment-success'>({languageUI.OrvisitourGoogleMapspagetoaccesstheorderinglink})</p>
                      <p className='text-center text-notification-payment-success'>{languageUI.Thisensuresyourorderis} <br /> {languageUI.processedcorrectly}</p>
                      <p className='text-center mb-0 text-notification-payment-success'>{languageUI.Thankyourforvisitustoday}, <br /> {languageUI.Welookforwardtoservingyouinthefuture}. </p>


                    </div>
                  </div>
                  <img hidden={status ? true : false} src={imgFailPayment} alt=""></img>
                </div>
                <div className="footer-choose-menu d-flex gap-2 align-items-center py-3 w-100 bg-white px-3 border-top">
                  {/* <a href="/webcheckin/mybill" className="w-100"> */}
                  <button className="custom-btn-payment-status  text-8E8EA9 border-1-8E8EA9 w-100 " onClick={HandleLeft}>
                    <img src={status ? iconBill : iconLeft} alt="" />
                    {status ? languageUI.Mybill : languageUI.Changemethod}
                  </button>
                  {/* </a> */}
                  {/* <a href="/webcheckin/cardInformation" className="w-100"> */}

                  <button className="custom-filter-btn border-none w-100 text-ffffff" hidden={status ? true : false} onClick={HandleRight}>
                    <img src={status ? storeWhite : user} alt="" />
                    {status ? languageUI.Home : languageUI.Callstaff}
                  </button>
                  {/* </a> */}
                </div>
              </div>
            </div>

          </Sheet.Content>

        </Sheet.Container>
        <Sheet.Backdrop className="bg-bg-backdrop" onTap={checkClose} />
      </Sheet>
    </>

  );
};

export default PaymentStatus;
