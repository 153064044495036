/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import {
  arrowCircleDownUp,
  chevronRightWhite,
} from "../../../../components/ImgExport";
import "./Styles.css";
import BillORderCounter from "./BillORderCounter";
import { useNavigate } from "react-router-dom";
import * as ketchenApi from "../../../../api/apiOwner/ketchenApi";
import * as userSlice from "../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../components/ModalLoading";
import Swal from "sweetalert2";
import socket from "../../../../utils/socket";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getLanguageUI } from "../../../../Services/languageUI";
import ModalOTPOwnerStaff from "../ModalOTPOwnerStaff/ModalOTPOwnerStaff";
import ModalOTPOwner from "../ModalOTPOwner/ModalOTPOwner";

const KitchenCounter = () => {
  const [hiddenHeader, setHiddenHeader] = useState(false);
  const navigate = useNavigate();
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [listOrderWaiting, setListOrderWaiting] = useState<
    ketchenApi.OrderWaiting[]
  >([]);
  const languageUI = getLanguageUI().kitchenCounter;

  const [showModalOTPOwner, setShowModalOTPOwner] = useState(false);

  const dismisModalOTPOwner = () => {
    setShowModalOTPOwner(false);
  };
  const gOrderWaiting = async () => {
    if (UserIdStore) {
      const req = await ketchenApi.gOrderWaitingKetchen(UserIdStore);
      if (Number(req?.status) === 1) {
        setListOrderWaiting(req?.data ?? []);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };
  const CompleteOrder = async (orderid: string) => {
    setShowLoading(true);
    const req = await ketchenApi.UpdateStatusWaitFood(orderid);
    if (Number(req?.status) === 1) {
      // gOrderWaiting()
      setShowLoading(false);
      Swal.fire({
        position: "center",
        icon: "success",
        title: languageUI.complete,
        showConfirmButton: false,
        timer: 1000,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: req?.message ?? "Update Fail",
        showConfirmButton: false,
        timer: 3000,
      });
      setShowLoading(false);
    }
  };
  const showNotificaton = useCallback(async () => {
    var pathName = window.location.pathname;

    // await socket.on("PushShowNotification" + UserIdStore, data => {
    if (pathName.search("/kitchencounter") > -1) {
      gOrderWaiting();
    }
    // })
  }, [socket]);
  useEffect(() => {
    if (!UserIdStore) {
      return;
    }
    const eventName = "PushShowNotification" + UserIdStore;

    socket.on(eventName, showNotificaton);

    return () => {
      socket.off(eventName, showNotificaton);
    };
  }, [UserIdStore]);

  const HandleOtpOwner = () => {
    localStorage.setItem("is_ownerMenu", "0");
    navigate("/web-owner/tablepos");
  };

  useEffect(() => {
    setShowLoading(true);
    gOrderWaiting();
    showNotificaton();
  }, []);
  return (
    <div className="w-100 bg-F3F5F7">
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="custom-position-fixed">
        <div className="position-relative">
          <div
            className={`header-kitchen-counter ${hiddenHeader ? "d-none" : ""}`}
          >
            <div className="d-flex align-items-center gap-1">
              <button
                onClick={() => setShowModalOTPOwner(true)}
                className="btn-back-kitchen-counter"
              >
                {/* <img src={arrowLeft} alt="" className='me-2' /> */}
                <ArrowBackIcon className="me-1" />
                {languageUI.back}
              </button>
              <span className="mb-0 text-home-header-counter">
                {languageUI.home} /{" "}
                <span className="mb-0 text-request-header-counter">
                  {languageUI.request}
                </span>
              </span>
            </div>
            <div
              className="btn-history-counter"
              onClick={() => navigate("/kitchencounterhistory")}
            >
              {languageUI.history}
              <img src={chevronRightWhite} alt="" />
            </div>
          </div>
          <div className="potiton-absolute-hidden-bar">
            <div
              className="custom-bar-hidden-header"
              onClick={() => {
                setHiddenHeader(!hiddenHeader);
              }}
            >
              <img
                src={arrowCircleDownUp}
                className={`${hiddenHeader ? "rotate-180deg" : ""}`}
                alt=""
              />
              {hiddenHeader ? languageUI.showHeader : languageUI.hideHeader}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`custom-column-counter-food w-100 px-48px w-100  pt-48px w-100 overflow-x-scroll scroll-hidden ${
          hiddenHeader ? "h-100vh" : "mt-76px h-calc-100vh-121px"
        }`}
      >
        {listOrderWaiting.map((item, i) => (
          <BillORderCounter
            listFood={item?.listFood}
            orderId={item?.id}
            tableName={item?.table_name}
            timeOrder={item?.timeProduct}
            key={`order${i}`}
            CompleteOrder={CompleteOrder}
            custommer_name={item?.customer_name}
            statusOrder={Number(item.order_method)}
            arrListSide={item.listSide}
          />
        ))}
      </div>
      <ModalOTPOwner
        handleClose={dismisModalOTPOwner}
        show={showModalOTPOwner}
        UserIdStore={UserIdStore}
        handleSuccess={HandleOtpOwner}
      />
    </div>
  );
};
export default KitchenCounter;
