import './Styles.css';
import { ComboOption } from "../../../../../../api/apiOwner/comboApi";
import { useNavigate } from "react-router-dom";
import React from "react";
import { getLanguageUI } from '../../../../../../Services/languageUI';

interface SalaryProp {
    comboPayroll: ComboOption[]
}
const Salary: React.FC<SalaryProp> = ({ comboPayroll = [] }) => {
    const languageUI = getLanguageUI().staffPage
    const navigate = useNavigate()
    //thay đổi search date


    return (
        <>
            <div className="mt-12 px-12">
                <div className="bg-white radius-18px  w-100 d-flex flex-column gap-4 align-items-center py-40px px-100px">
                    <div className="btn-salary-weeek" onClick={() => navigate('/web-owner/salaryweek')}>
                        {languageUI.caculateSalaryWeek}<br />
                        {languageUI.fromMondayToSunday}
                    </div>
                    <div className="btn-salary-weeek" onClick={() => navigate('/web-owner/salary2week')}>
                        {languageUI.caculateSalary2Week}<br />
                        {languageUI.fromMondayToSunday}
                    </div>
                    <div className="btn-salary-weeek" onClick={() => navigate('/web-owner/salarymonth')}>
                        {languageUI.caculateSalaryMonth}<br />
                        {languageUI.fromJanuarytoDecember}
                    </div>
                    {/* <table className="table">
                        <thead>
                            <tr >
                                <th scope="col" className="w-10 text-18px text-12B064 fw-600 font-urbansist">No.</th>
                                <th scope="col" className="w-35 text-18px text-12B064 fw-600 font-urbansist">Employee </th>
                                <th scope="col" className="w-20 ">
                                    <div className="flex justify-between  items-center">
                                        <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">Total working hour</span>
                                    </div>
                                </th>
                                <th scope="col" className="w-10">

                                    <div className="flex items-center">
                                        <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">TIP</span>
                                        <img src={arrowDouble} />
                                    </div>
                                </th>
                                <th scope="col" className="w-10 ">
                                    <div className="flex items-center">
                                        <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">Salary</span>
                                        <img src={arrowDouble} />
                                    </div>
                                </th>
                                <th scope="col" className="w-15 ">
                                    <div className="flex items-center">
                                        <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">Total Earn</span>
                                        <img src={arrowDouble} />
                                    </div>
                                </th>


                            </tr>
                        </thead>
                        <tbody className="h-body-table-salary">
                            {listSalary.map((item, i) => (
                                <ContentTableSalary no={i + 1} employee={item?.staff_name} totalWorkingHour={item?.working_time} tip={Number(item?.tip)} salary={Number(item?.salary)} totalEarn={Number(item?.tip) + Number(item?.salary)} key={`salary${i}`} id_timesheet={item?.id} staff_id={item?.staff_id} />
                            ))}

                        </tbody>
                    </table> */}

                </div>
            </div>
        </>
    )
}
export default Salary