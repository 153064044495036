import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
// components
import Select from "react-select";
import {
  default_product,
  errImgFeedback,
  usFlag,
} from "../../components/Image";
import PageTitle from "../../components/PageTitle";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as CreateCustomerAdminAPI from "../../api/apiAdmin/CreateCustomerAdmin";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import { ComboOption, gComboTimeZone } from "../../api/apiOwner/comboApi";
import moment from "moment-timezone";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import { gConfigStripeAdmin } from "../../api/apiAdmin/ConfigSetting";
// import PaymentForm from './PaymentForm';
// import { gstripeKeyOwner } from "../../../../api/apiOwner/StripeOwnerApi";
import PlaceIcon from "@mui/icons-material/Place";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import React from "react";
import Pagination from "../../components/Pagination";
import { Paper, Table, TableContainer, TablePagination } from "@mui/material";
import { targetImgadmin } from "../../api/Request";
import { targetImg } from "../../api/urlImage";
import ModalSlideImg from "../webowner/components/ModalSlideImg/ModalSlideImg";
// maker của map

interface FeedbackAdminItemProps {
  FeedbackAdminItem: CreateCustomerAdminAPI.InfoFeedBackItem;
}
const FeedbackAdminItem = ({ FeedbackAdminItem }: FeedbackAdminItemProps) => {
  const [showModalSlideImg, setShowModalSlideImg] = useState<boolean>(false);
  const dismisModalShowSlideImg = () => {
    setShowModalSlideImg(false);
  };
  return (
    <div className="mb-5">
      <p className="time-feedback-admin mb-1 ">
        {FeedbackAdminItem.time_feedback}
      </p>
      <div className="w-full p-5 rounded-3 bg-white feedback-admin-item d-flex  gap-5">
        <div className="d-flex w-30 flex-shrink-0 gap-2">
          <div className="w-50">
            <p className="m-0 feedback-admin-name">
              {FeedbackAdminItem.customer_name}
            </p>
            <p className="m-0 feedback-admin-phone">
              {FeedbackAdminItem.cellphoone}
            </p>
            <p className="m-0 feedback-admin-email">
              {FeedbackAdminItem.email}
            </p>
          </div>
          <div className="w-50">
            <p className="m-0 feedback-admin-name">
              {FeedbackAdminItem.store_name}
            </p>
            <p className="m-0 feedback-admin-phone">
              {FeedbackAdminItem.address +
                (FeedbackAdminItem.suit_of_unit ? ", " : "") +
                FeedbackAdminItem.suit_of_unit +
                (FeedbackAdminItem.store_city ? ", " : "") +
                FeedbackAdminItem.store_city +
                (FeedbackAdminItem.store_state ? ", " : "") +
                FeedbackAdminItem.store_state +
                (FeedbackAdminItem.zipcode ? ", " : "") +
                FeedbackAdminItem.zipcode}
            </p>
            <p className="m-0 feedback-admin-phone">
              {FeedbackAdminItem.store_phone}
            </p>
            {/* <p className="m-0 text-primary">{FeedbackAdminItem.}</p> */}
          </div>
        </div>
        <div className="w-100">
          <p className="m-0 feedback-admin-content">
            {FeedbackAdminItem.content}
          </p>
          <div className="list-img-feedback mt-2">
            {FeedbackAdminItem.listImg.map((item, index) => (
              <img
                src={targetImg + item.filename}
                alt=""
                className="w-160px h-120px object-fit-cover  rounded-4 "
                onError={(e) => {
                  e.currentTarget.src = errImgFeedback;
                }}
                onClick={() => {
                  setShowModalSlideImg(true);
                }}
              />
            ))}
          </div>
        </div>
      </div>
      <ModalSlideImg
        dismisModal={dismisModalShowSlideImg}
        show={showModalSlideImg}
        listImgShow={FeedbackAdminItem.listImg}
      />
    </div>
  );
};
const FeedbackAdmin = () => {
  const [ShowLoading, setShowLoading] = useState(false);
  const [listFeedback, setListFeedback] = useState<
    CreateCustomerAdminAPI.InfoFeedBackItem[]
  >([]);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const getHistoryFeedBack = async (rowsPerPage: number, page: number) => {
    setShowLoading(true);
    const req = await CreateCustomerAdminAPI.gHistoryFeedBackAdmin(
      rowsPerPage,
      page
    );
    if (req?.status === 1) {
      setTotalPage(req.total);
      setListFeedback(req.data ?? []);
      setShowLoading(false);
    } else {
      console.log(req?.message);
      setShowLoading(false);
    }
    setShowLoading(false);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listFeedback.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    getHistoryFeedBack(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getHistoryFeedBack(parseInt(event.target.value, 10), 0);
    setPage(0);
  };

  useEffect(() => {
    getHistoryFeedBack(rowsPerPage, page);
  }, []);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Customer", path: "#" },
          { label: "Create customer", path: "#", active: true },
        ]}
        title={"Create customer"}
      />

      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className="py-3 rounded-3 mb-3 bg-white shadow feedback-admin">
        <div className="content-feedback-admin">
          {listFeedback.map((item, index) => (
            <FeedbackAdminItem FeedbackAdminItem={item} key={index} />
          ))}
        </div>
        {/* <Pagination tableProps={dataTable} sizePerPageList={sizePerPageList} /> */}
        <div className="w-100">
          <Paper>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              ></Table>
            </TableContainer>
            <TablePagination
              className="w-100 d-flex pagination-feedback"
              rowsPerPageOptions={[
                10,
                15,
                25,
                { label: "All", value: totalPage },
              ]}
              count={totalPage}
              rowsPerPage={rowsPerPage}
              page={page}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // ActionsComponent={FeedbackAdmin}
            />
          </Paper>
        </div>
      </div>
    </>
  );
};

export default FeedbackAdmin;
