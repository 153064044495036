import React, { useEffect, useState } from "react";
import "./Styles.css";
import QRCode from "react-qr-code";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  DetailGiftBalance,
  gDetailGiftCardBalance,
  getHistoryTransactionGift,
  TransactionGift,
} from "../../api/apiOwner/giftCodeVoucher";
import { FormatDolla, formatPhoneNumber } from "../../utils/format";
import { getLanguageUI } from "../../Services/languageUI";
const GiftCardBalance = () => {
  let params = useParams();
  const languageUI = getLanguageUI().giftCard;
  let voucherCode = params?.voucherCode;
  const [infoCardVoucher, setinFoCardVoucher] = useState<DetailGiftBalance>();
  const [transactionHistory, setTransactionHistory] = useState<
    TransactionGift[]
  >([]);
  const gDetailGift = async () => {
    if (voucherCode) {
      const res = await gDetailGiftCardBalance(voucherCode);
      if (res?.status === 1) {
        setinFoCardVoucher(res?.data);
      } else {
        setinFoCardVoucher(undefined);
      }
    }
  };
  const gHistoryTransactionGift = async () => {
    if (voucherCode) {
      const res = await getHistoryTransactionGift(voucherCode);
      if (res?.status === 1) {
        setTransactionHistory(res?.data);
      } else {
        setTransactionHistory([]);
      }
    }
  };
  useEffect(() => {
    gDetailGift();
    gHistoryTransactionGift();
  }, []);
  return (
    <div className="h-100vh w-100wv">
      <header className="gift-card-balance-header">
        <p className="mb-1 text-white text-lg font-bold">
          {infoCardVoucher?.store_name}
        </p>
        <p className="mb-1 text-white text-lg font-normal">
          {formatPhoneNumber(infoCardVoucher?.phone)}
        </p>
        <p className="mb-1 text-white text-lg font-normal">
          {infoCardVoucher?.detail_address}
        </p>
      </header>
      <body className="gift-card-balance-content">
        <div className="w-100">
          <p className="text-4a4a6a text-lg font-medium mb-1">
            {languageUI.From}: {infoCardVoucher?.send_from}
          </p>
          <p className="text-4a4a6a text-lg font-medium mb-1">
            {languageUI.to}: {infoCardVoucher?.send_to}
          </p>
          <p className="text-4a4a6a text-lg font-medium mb-1">
            {infoCardVoucher?.voucher_name}
          </p>
          <div className="w-100 d-flex justify-content-center">
            <hr
              className="w-80 m-0"
              style={{ height: "2px", backgroundColor: "#99999980" }}
            />
          </div>
          <div className="d-flex gap-3 align-items-end">
            <p className="text-4a4a6a text-lg font-medium mb-1">
              {languageUI.cardBalance}:
            </p>
            <p
              className="text-lg font-bold mb-1 mt-2"
              style={{ color: "#38b6ff" }}
            >
              ${FormatDolla(infoCardVoucher?.remain)}
            </p>
          </div>
          <div className="w-100 d-flex justify-content-center mt-2">
            <div
              className="w-50 "
              style={{
                border: "solid 8px black",
                padding: "4px",
                borderRadius: "4px ",
              }}
            >
              <QRCode
                style={{
                  width: "100%",
                  height: "fit-content",
                  color: "#4a4a6a",
                }}
                color="#4a4a6a"
                id="qrcode"
                value={infoCardVoucher?.voucher_code ?? ""}
                level={"H"}
              />
              <p className="text-center mt-1 mb-0 font-bold text-4a4a6a text-lg">
                {infoCardVoucher?.voucher_code}
              </p>
            </div>
          </div>
          <Accordion>
            <Accordion.Item
              eventKey="0"
              className="border-none focus-visible-none mt-2"
            >
              <Accordion.Header className="text-order-list">
                {languageUI.viewTransactions}
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex gap-2 w-100">
                  <div className="content-balance-gift font-bold text-4a4a6a">
                    {languageUI.Date}
                  </div>
                  <div className="content-balance-gift text-center font-bold text-4a4a6a">
                    {languageUI.amount}
                  </div>
                  <div className="content-balance-gift text-center font-bold text-4a4a6a">
                    {languageUI.balance}
                  </div>
                </div>
                {transactionHistory.length > 0 &&
                  transactionHistory.map((item, i) => (
                    <div className="d-flex gap-2 w-100" key={i}>
                      <div className="content-balance-gift  text-4a4a6a">
                        {item?.transaction_date}
                      </div>
                      <div className="content-balance-gift text-center  text-4a4a6a">
                        ${FormatDolla(item?.payment_amount)}
                      </div>
                      <div className="content-balance-gift text-center  text-4a4a6a">
                        ${FormatDolla(item?.balance)}
                      </div>
                    </div>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </body>
      <footer className="gift-card-balance-footer">
        <p className="font-medium text-sm text-white mb-1 text-center">
          {languageUI.Thisgiftcardcannotbeexchangedforcashunlesssuch} <br />{" "}
          {languageUI.redemptionisrequiredbyapplicablelaw}
        </p>
        <p className="font-nomal text-lg text-white text-center m-0">
          BriJack POS!
        </p>
      </footer>
    </div>
  );
};
export default GiftCardBalance;
