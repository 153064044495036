

import "./Styles.css"
import { cartBagOrange, storeOrange } from "../../../../components/ImgExport";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi"
import { FormatDolla } from "../../../../utils/format";
interface OrderListITemPrintProps {
  status: boolean;
  textStatus?: string;
  orderInfo: orderConfilmApi.InfoOrder[];
}

const OrderListITemPrint = ({
  status,
  orderInfo = []

}: OrderListITemPrintProps) => {
  return (
    <>
      {
        orderInfo?.length > 0 ? (
          orderInfo.map((item) => (
            <div>
              <div className="d-flex justify-content-between align-items-start gap-1 ">
                <span className="title-infor-bill-customer text-black">{item.name}</span>
                <div className="d-flex align-items-center justify-content-end gap-1 flex-shink-0">
                  <div className="d-flex align-items-end gap-1 flex-shink-0">
                    <div className="d-flex align-items-end gap-2px ">
                      <div className="title-infor-bill-customer text-black  text-black line-height-normal">{item.quantity}x</div>
                    </div>
                    <div className="d-flex align-items-end gap-2px ">
                      <div className="title-infor-bill-customer text-black text-4A4A6A  line-height-normal">{FormatDolla(Number(item.price))}$</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                {item.notefood_name && (
                  <div className="text-black">
                    <p className="mb-0 text-items-order-print text-black line-height-normal">{item.notefood_name}</p>
                  </div>
                )}

                <div className="text-black">
                  <p className="mb-0 text-items-order-print text-black line-height-normal">{item.form_order === '1' ? "Dine in" : "Take away"}</p>
                </div>
              </div>
              {item.side_name && (
                <div className="">
                  <div className=""><p className="mb-0 text-choose-a-side text-black line-height-normal">-{item.side_name}</p></div>
                </div>
              )}
            </div>

          ))
        ) : (<></>)
      }
    </>

  );
};

export default OrderListITemPrint;
