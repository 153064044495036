import { useEffect, useRef, useState } from "react";
import {
  arrow,
  chevronUp,
  closeLineWhite,
  menuSetupTable,
  multiSelect,
  multiSelectGreen,
  plusSetupTable,
  trashRedRemoveFloor,
} from "../../components/ImgExport";
import "./Styles.css";
import * as tableApi from "../../api/apiOwner/tableApi";
import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { withSwal } from "react-sweetalert2";
import SetupTableItem from "./components/SetupTableItem/SetupTableItem";
import ModalAddFloor from "./components/ModalAddFloor/ModalAddFloor";
import ModalAddTable from "./components/ModalAddTable/ModalAddTable";
import ModalOTPDeleteTable from "./components/ModalOTPDeleteTable/ModalOTPDeleteTable";
import ModalToast from "./components/ModalToast/ModalToastErr";
import ModalLoading from "../../components/ModalLoading";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { FormControlLabel, Radio, RadioGroup, Slider } from "@mui/material";
import ModalConfirm from "./components/ModalConfirm/ModalConfirm";
import { getLanguageUI } from "../../Services/languageUI";

interface multiTable {
  id: string;
  tableName: string;
}
const SetupTable: React.FC = withSwal((props: any) => {
  const languageUI = getLanguageUI().setUpTable;
  const { swal } = props;
  const [tableSelected, setTableSelected] = useState<string>("");
  const [nametableSelected, setNameTableSelected] = useState<string>("");
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const role = useSelector(userSlice.selectorRole);
  const [listTable, setListTable] = useState<tableApi.TableInfo[]>([]);
  const [listFloor, setListFloor] = useState<tableApi.FloorInfo[]>([]);
  const [indexFloor, setIndexFloor] = useState<number>(0);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [showModalAddFloor, setShowModalAddFloor] = useState<boolean>(false);
  const [showModalAddTable, setShowModalAddTable] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchCustommer, setSearchCustommer] = useState<string>("");
  const [optionMenu, setOptionMenu] = useState<boolean>(false);
  const [arrayMultiSelectTable, setArrayMultiSelectTable] = useState<
    multiTable[]
  >([]);
  const [showModalOTPDeleteTable, setShowModalOTPDeleteTable] =
    useState<boolean>(false);
  const [showModalClearTable, setShowModalClearTable] =
    useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [contentToast, setContentToast] = useState<string>("");
  const [sizeTable, setSizeTable] = useState<number>(50);
  const [rorateTable, setRorateTable] = useState<number>(0);
  const [shapeTable, setShapeTable] = useState<string>("square");

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 50,
      },
    })
  );
  async function handleDragEnd(event: DragEndEvent) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { active, over, delta } = event;
    let tx = 0;
    let ty = 0;
    const newDragg = listTable.map((item) => {
      if (Number(item.id) === Number(active.id)) {
        tx = delta.x + Number(item.tx);
        ty = delta.y + Number(item.ty);
      }
      return Number(item.id) === Number(active.id)
        ? {
            ...item,
            tx: delta.x + Number(item.tx),
            ty: delta.y + Number(item.ty),
          }
        : item;
    });
    setListTable(newDragg);
    if (UserIdStore) {
      await tableApi.upTransformTableById(
        UserIdStore,
        Number(active?.id),
        tx,
        ty
      );
    }
  }

  const dismisModalOTPDeleteTable = () => {
    setShowModalOTPDeleteTable(false);
  };
  const dismisModalOTPClearTable = () => {
    setShowModalClearTable(false);
  };
  const dismissToast = () => {
    setShowToast(false);
  };

  const addMultiTable = (id: string, tableName: string) => {
    if (!arrayMultiSelectTable.some((item) => item.id === id)) {
      setArrayMultiSelectTable([
        ...arrayMultiSelectTable,
        { id: id, tableName: tableName },
      ]);
    }
  };
  const removeTableMultiSelect = (id: string) => {
    setArrayMultiSelectTable([
      ...arrayMultiSelectTable.filter((item) => item.id !== id),
    ]);
  };
  const dismisModalAddFloor = () => {
    setShowModalAddFloor(false);
  };
  const dismisModalAddTable = () => {
    setShowModalAddTable(false);
  };
  //lấy danh sách tầng
  const gListFloorByOwner = async () => {
    if (UserIdStore) {
      const glistFloor = await tableApi.gListAllFloor(UserIdStore);
      if (Number(glistFloor.status) === 1) {
        setListFloor(glistFloor.data ?? []);
        if (
          glistFloor?.data &&
          glistFloor?.data?.length > 0 &&
          glistFloor?.data[0]?.id
        ) {
          setIndexFloor(0);
          gListTable(glistFloor?.data[0]?.id);
        }
      }
    }
  };

  const dragItem = useRef<any>(null);
  const dragOverItem = useRef<any>(null);
  const handleSort = async () => {
    let _tableAPI = [...listTable];
    if (
      dragItem.current != null &&
      dragOverItem.current != null &&
      dragItem.current !== dragOverItem.current
    ) {
      var listTableUpdate = [];
      var sort1 = _tableAPI[dragOverItem.current].sort;
      var sort2 = _tableAPI[dragItem.current].sort;
      var nametb1 = _tableAPI[dragOverItem.current].table_name;
      var nametb2 = _tableAPI[dragItem.current].table_name;

      // const draggedItemContent = _tableAPI.splice(dragItem.current, 1)[0]
      // const draggedItemContentEnd = _tableAPI.splice(dragOverItem.current, 1)[0]
      // _tableAPI.splice(dragOverItem.current, 0, draggedItemContent);
      // _tableAPI.splice(dragItem.current, 0, draggedItemContentEnd);
      // // _tableAPI.splice(dragItem.current, 0, draggedItemContentEnd);
      _tableAPI[dragItem.current].sort = sort1;
      _tableAPI[dragOverItem.current].sort = sort2;
      _tableAPI[dragItem.current].table_name = nametb1;
      _tableAPI[dragOverItem.current].table_name = nametb2;
      _tableAPI[dragItem.current] = _tableAPI.splice(
        dragOverItem.current,
        1,
        _tableAPI[dragItem.current]
      )[0];
      // console.log(_tableAPI);

      listTableUpdate.push(_tableAPI[dragItem.current]);
      listTableUpdate.push(_tableAPI[dragOverItem.current]);
      setShowLoading(true);
      const reqSwap = await tableApi.updateSortTbale(listTableUpdate);
      if (Number(reqSwap?.status) === 1) {
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }

      dragOverItem.current = null;
      dragItem.current = null;
      console.log(_tableAPI);

      setListTable(_tableAPI);
    }
  };
  //lấy danh sách bàn
  const gListTable = async (floor_id: string) => {
    if (UserIdStore && floor_id !== "") {
      setShowLoading(true);
      const ListTB = await tableApi.gListAllTableByFloor(
        UserIdStore,
        floor_id,
        1
      );
      if (Number(ListTB.status) === 1) {
        setListTable(ListTB.data ?? []);
        setShowLoading(false);
      } else {
        setContentToast(ListTB?.message ?? "");
        setShowToast(true);
        setShowLoading(false);
      }
    }
  };
  const searchTableByCustommer = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      if (searchCustommer !== "") {
        const ListTB = await tableApi.gListTableByCustommer(
          UserIdStore,
          searchCustommer
        );
        if (ListTB.status === 1) {
          setListTable(ListTB.data ?? []);
          setShowLoading(false);
        } else {
          setContentToast(ListTB?.message ?? "");
          setShowToast(true);
          setShowLoading(false);
        }
      } else {
        if (listFloor.length > 0 && listFloor[indexFloor]) {
          gListTable(listFloor[indexFloor].id ?? "");
          setShowLoading(false);
        }
      }
    }
  };

  const handleChaneFloor = (type: number) => {
    //type:1 tăng 0 giảm
    var index = 0;
    if (type === 1) {
      index = indexFloor + 1;
    } else {
      index = indexFloor - 1;
    }
    setIndexFloor(index);
    if (listFloor.length > 0 && listFloor[index]) {
      gListTable(listFloor[index].id ?? "");
    }
  };
  const ClearFormTable = async () => {
    if (UserIdStore) {
      const req = await tableApi.clearTransformTable(
        UserIdStore,
        listFloor[indexFloor].id
      );
      if (req?.status === 1) {
        setShowModalClearTable(false);
        gListTable(listFloor[indexFloor]?.id ?? "");
      }
    }
  };

  const ConfilmResetTable = async (table_id: string, table_name: string) => {
    swal
      .fire({
        title:
          "Are you sure you want to return table " +
          table_name +
          " to Available state?",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#28bb4b",
        cancelButtonColor: "#f34e4e",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then(function (result: { value: any; dismiss: any }) {
        if (result.value) {
          ResetTable(table_id);
        }
      });
  };

  const ResetTable = async (table_id: string) => {
    const upStatus = await tableApi.CleaveTable(table_id);
    if (Number(upStatus.status) === 1) {
      const data = listTable?.map((item) => {
        return item?.id === table_id ? { ...item, status: "0" } : item;
      });
      setListTable(data ?? []);
    } else {
      setContentToast(upStatus?.message ?? "");
      setShowToast(true);
    }
  };

  const removeTable = async () => {
    if (!optionMenu) {
      setShowLoading(true);
      const reqRemove = await tableApi.removeTableSingle(tableSelected);
      if (Number(reqRemove?.status) === 1) {
        setShowLoading(false);
        setTableSelected("");
        setOptionMenu(false);
        if (listFloor.length > 0 && listFloor[indexFloor]) {
          gListTable(listFloor[indexFloor].id ?? "");
        }
      } else {
        setContentToast(reqRemove?.message ?? "");
        setShowToast(true);
        setShowLoading(false);
      }
    } else {
      if (arrayMultiSelectTable.length === 0) {
        setContentToast("Please select the table you want to delete!");
        setShowToast(true);
      } else {
        setShowLoading(true);
        const listTable = arrayMultiSelectTable.map((item) => {
          return item?.id;
        });
        const reqRemove = await tableApi.removeTableMulti(listTable);
        if (Number(reqRemove?.status) === 1) {
          setShowLoading(false);
          setTableSelected("");
          setOptionMenu(false);
          setContentToast(reqRemove?.message ?? "Remove success");
          setShowToast(true);
          setArrayMultiSelectTable([]);
          if (listFloor.length > 0 && listFloor[indexFloor]) {
            gListTable(listFloor[indexFloor].id ?? "");
          }
        } else {
          setContentToast(reqRemove?.message ?? "");
          setShowToast(true);
          setShowLoading(false);
        }
      }
    }
  };

  const ResetTableInfoWhenChange = () => {
    if (tableSelected) {
      const newDragg = listTable.map((item) => {
        return Number(item.id) === Number(tableSelected)
          ? {
              ...item,
              scale: sizeTable,
              rorate: rorateTable,
            }
          : item;
      });
      setListTable(newDragg);
    }
  };

  //cập nhật shape
  const upDateShape = async (shape: string) => {
    if (UserIdStore && tableSelected) {
      const newDragg = listTable.map((item) => {
        return Number(item.id) === Number(tableSelected)
          ? {
              ...item,
              shape,
            }
          : item;
      });
      setListTable(newDragg);
      await tableApi.upShapeTableById(UserIdStore, tableSelected, shape);
    }
  };

  //cập nhật scale
  const upDateScale = async () => {
    if (UserIdStore && tableSelected) {
      ResetTableInfoWhenChange();
      await tableApi.upScaleTableById(UserIdStore, tableSelected, sizeTable);
    }
  };

  //cập nhật rorate
  const upDateRorate = async () => {
    if (UserIdStore && tableSelected) {
      ResetTableInfoWhenChange();
      await tableApi.upRorateTableById(UserIdStore, tableSelected, rorateTable);
    }
  };

  const SaveMultiTable = async () => {
    if (arrayMultiSelectTable.length > 0) {
      setShowLoading(true);
      const listTable = arrayMultiSelectTable.map((item) => {
        return item?.id;
      });
      const req = await tableApi.upRorateMultiTableById(
        listTable,
        rorateTable,
        sizeTable,
        shapeTable
      );
      if (Number(req?.status) === 1) {
        setShowLoading(false);
        setTableSelected("");
        setOptionMenu(false);
        setContentToast(req?.message ?? "Update success");
        setShowToast(true);
        setArrayMultiSelectTable([]);
        if (listFloor.length > 0 && listFloor[indexFloor]) {
          gListTable(listFloor[indexFloor].id ?? "");
        }
      } else {
        setContentToast(req?.message ?? "");
        setShowToast(true);
        setShowLoading(false);
      }
    }
  };
  const handleAddSuccess = () => {
    gListFloorByOwner();
  };

  useEffect(() => {
    gListFloorByOwner();
    console.log("role", role);

    // gListTip();
    // gListStaffTip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="">
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <div className="h-70px mt-header bg-white px-4 d-flex justify-content-between align-items-center border-bottom container-fluid custom-header-search">
        <div className="d-flex align-items-center h-100 gap-2">
          <div className="d-flex align-items-center gap-2">
            <div className="circle-status-table status-table-available"></div>
            <p className="mb-0 name-status-table">{languageUI.available}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="circle-status-table status-table-peserved"></div>
            <p className="mb-0 name-status-table">{languageUI.reserved}</p>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="circle-status-table status-table-paid"></div>
            <p className="mb-0 name-status-table">{languageUI.paid}</p>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2 ">
          <div className="d-flex gap-2 align-items-center">
            <button
              className="btn-choose-floor"
              disabled={indexFloor === 0}
              onClick={() => {
                handleChaneFloor(0);
              }}
            >
              <img src={arrow} alt="" className="rorate-left" />
            </button>
            <div className="w-70px text-floor text-center">
              {listFloor.length > 0 ? listFloor[indexFloor]?.floor_name : ""}
            </div>
            <button
              className="btn-choose-floor"
              disabled={indexFloor === listFloor.length - 1}
              onClick={() => {
                handleChaneFloor(1);
              }}
            >
              <img src={arrow} alt="" className="rorate-right" />
            </button>
          </div>
        </div>
      </div>
      <div
        className="p-4 box-set-up-table scroll-hidden position-relative"
        // className="mt-140px h-fit"
      >
        <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
          {listTable.map((items, index) => {
            return (
              <SetupTableItem
                id={items?.id}
                NameTable={items?.table_name}
                setTableSelected={(
                  tableId: string,
                  tableName: string,
                  scale: number,
                  rorate: number
                ) => {
                  setTableSelected(tableId);
                  setNameTableSelected(tableName);
                  setSizeTable(scale);
                  setRorateTable(rorate);
                  setShapeTable(items?.shape ?? "square");
                }}
                StatusTable={Number(items?.status)}
                checked={
                  optionMenu
                    ? arrayMultiSelectTable.some((item) => item.id === items.id)
                    : tableSelected === items?.id
                }
                key={items.id}
                numberChair={Number(items?.size)}
                ReloadTable={searchTableByCustommer}
                ConfilmResetTable={ConfilmResetTable}
                indexMap={index}
                dragItem={dragItem}
                dragOverItem={dragOverItem}
                handleSort={handleSort}
                addMultiTable={addMultiTable}
                isMultiTable={optionMenu}
                tX={Number(items?.tx)}
                tY={Number(items?.ty)}
                rorate={
                  Number(tableSelected) === Number(items?.id) ||
                  arrayMultiSelectTable.some(
                    (bb) => Number(bb.id) === Number(items?.id)
                  )
                    ? rorateTable
                    : Number(items?.rorate)
                }
                size={
                  Number(tableSelected) === Number(items?.id) ||
                  arrayMultiSelectTable.some(
                    (bb) => Number(bb.id) === Number(items?.id)
                  )
                    ? sizeTable
                    : Number(items?.scale)
                }
                shapeTable={
                  Number(tableSelected) === Number(items?.id) ||
                  arrayMultiSelectTable.some(
                    (bb) => Number(bb.id) === Number(items?.id)
                  )
                    ? shapeTable
                    : items?.shape ?? ""
                }
              />
            );
          })}
        </DndContext>
      </div>
      <div className="position-fixed px-4 bottom-40px w-box-menu-table ">
        <div className={`menu-setup-table position-relative`}>
          <div
            className={`d-flex gap-2 align-items-center pe-2 border-right  ${
              tableSelected === "" ? "" : "d-none"
            } ${optionMenu ? "d-none" : ""}`}
          >
            <img src={menuSetupTable} alt="" />
            <div>
              <p className="text-manage-setup-table mb-1">
                {languageUI.manage}
              </p>
              <p className="text-tool-setup-table mb-0">{languageUI.tool}</p>
            </div>
          </div>
          <div
            className={`d-flex overflow-x-scroll scroll-hidden gap-2 hidden-scroll-bar ${
              tableSelected === "" ? "" : "d-none"
            } ${optionMenu ? "d-none" : ""}`}
          >
            <div
              className="box-btn-function-setup-table gap-1 flex-shink-0"
              onClick={() => {
                setShowModalAddFloor(true);
                setOptionMenu(false);
              }}
            >
              <img src={chevronUp} alt="" />
              <p className="text-function-setup-table mb-0">
                {languageUI.floorManagement}
              </p>
            </div>
            <div
              className="box-btn-function-setup-table gap-1 flex-shink-0"
              onClick={() => {
                if (listFloor.length > 0) {
                  setShowModalAddTable(true);
                  setOptionMenu(false);
                } else {
                  setContentToast("Please add floors first");
                  setShowToast(true);
                }
              }}
            >
              <img src={plusSetupTable} alt="" />
              <p className="text-function-setup-table mb-0">
                {languageUI.addTable}
              </p>
            </div>
            <div
              className={`box-btn-function-setup-table gap-1 flex-shink-0 ${
                optionMenu ? "border-orange" : ""
              }`}
              onClick={() => {
                setSizeTable(1);
                setRorateTable(0);
                setShapeTable("square");
                setOptionMenu(true);
                setTableSelected("muiltiselect");
              }}
            >
              <img src={multiSelect} alt="" />
              <p className="text-function-setup-table mb-0">
                {languageUI.multiSelect}
              </p>
            </div>
            {/* <div className="box-btn-function-setup-table gap-1 flex-shink-0" onClick={() => { setOptionMenu(false) }}>
                            <img src={changePosition} alt="" />
                            <p className="text-function-setup-table mb-0">Change Position</p>
                        </div> */}
            <div
              className="box-btn-function-setup-table gap-1 flex-shink-0"
              onClick={() => setShowModalClearTable(true)}
            >
              <p className="text-function-setup-table mb-0">
                {languageUI.clear}
              </p>
            </div>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center w-100 ${
              tableSelected === "" ? "d-none" : ""
            } ${optionMenu ? "d-none" : ""}`}
          >
            <div className="d-flex gap-2 align-items-center pe-2">
              <img src={menuSetupTable} alt="" />

              <div>
                <p className="text-manage-setup-table mb-1">
                  {nametableSelected}
                </p>
                <p className="text-tool-setup-table mb-0">
                  {languageUI.information}
                </p>
              </div>
            </div>

            <div className="d-flex gap-2 align-items-center gap-2">
              <div className="d-flex gap-2 align-items-center">
                <p className="text-manage-setup-table mb-0">
                  {languageUI.shape}:
                </p>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    setShapeTable(e.target.value);
                    upDateShape(e.target.value);
                  }}
                  value={shapeTable}
                  defaultValue={"square"}
                >
                  <FormControlLabel
                    value="square"
                    control={<Radio value="square" />}
                    label={languageUI.square}
                  />
                  <FormControlLabel
                    value="circle"
                    control={<Radio value="circle" />}
                    label={languageUI.circle}
                  />
                </RadioGroup>
              </div>
              <p className="text-manage-setup-table mb-0">{languageUI.size}</p>
              <Slider
                aria-label="Default"
                min={1}
                value={sizeTable}
                valueLabelDisplay="auto"
                className="w-200px"
                onChange={(e: any) => {
                  setSizeTable(e?.target.value);
                }}
                onChangeCommitted={(e) => upDateScale()}
              />
              <p className="text-manage-setup-table mb-0">
                {languageUI.rorate}
              </p>
              <Slider
                aria-label="Default"
                value={rorateTable}
                valueLabelDisplay="auto"
                className="w-200px ms-2"
                onChange={(e: any) => {
                  setRorateTable(e?.target.value);
                }}
                onChangeCommitted={(e) => upDateRorate()}
              />
              <button
                className="btn-edit-delete-floor"
                onClick={() => setShowModalOTPDeleteTable(true)}
              >
                <img src={trashRedRemoveFloor} alt="" />
              </button>
              {/* <button className="btn-edit-delete-floor">
                                <img src={pencilEdit} alt="" />
                            </button> */}
              {/* <button className="btn-edit-delete-floor">
                                <img src={changePosition} alt="" />
                            </button> */}
            </div>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center w-100 h-100 ${
              optionMenu ? "" : "d-none"
            }`}
          >
            <div className="w-100 h-100">
              <div className="d-flex">
                <div
                  className={`d-flex gap-2 align-items-center pe-2 border-right flex-shink-0 `}
                >
                  <img src={multiSelectGreen} alt="" />
                  <div className="d-flex flex-column flex-shink-0">
                    <p className="text-manage-setup-table mb-1 d-flex">
                      {languageUI.multiSelect}
                    </p>
                    <p className="text-tool-setup-table mb-0 d-flex gap-2px">
                      {languageUI.selected}
                      <p className="text-tool-setup-table mb-0 text-primary">
                        {arrayMultiSelectTable.length}
                      </p>
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-2 align-items-center ps-3 h-100 scroll-hidden overflow-y-scroll w-100 py-3">
                  {arrayMultiSelectTable.map((items, index) => (
                    <div
                      className="table-multi-select"
                      key={items.id + "tableMuiltiSelect"}
                    >
                      {items.tableName}
                      <div
                        className="btn-close-multi-select"
                        onClick={() => {
                          removeTableMultiSelect(items.id);
                        }}
                      >
                        <img src={closeLineWhite} alt="" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-flex gap-4 align-items-center justify-content-between mt-2">
                <div className="d-flex gap-4 align-items-center ">
                  <div className="d-flex gap-2 align-items-center">
                    <p className="text-manage-setup-table mb-0">
                      {languageUI.shape}:
                    </p>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => {
                        setShapeTable(e.target.value);
                        // upDateShape(e.target.value)
                      }}
                      value={shapeTable}
                      defaultValue={"square"}
                    >
                      <FormControlLabel
                        value="square"
                        control={<Radio value="square" />}
                        label={languageUI.square}
                      />
                      <FormControlLabel
                        value="circle"
                        control={<Radio value="circle" />}
                        label={languageUI.circle}
                      />
                    </RadioGroup>
                  </div>
                  <p className="text-manage-setup-table mb-0">
                    {languageUI.size}
                  </p>
                  <Slider
                    aria-label="Default"
                    min={1}
                    value={sizeTable}
                    valueLabelDisplay="auto"
                    className="w-200px"
                    onChange={(e: any) => {
                      setSizeTable(e?.target.value);
                    }}
                  />
                  <p className="text-manage-setup-table mb-0">
                    {languageUI.rorate}
                  </p>
                  <Slider
                    aria-label="Default"
                    value={rorateTable}
                    valueLabelDisplay="auto"
                    className="w-200px ms-2"
                    onChange={(e: any) => {
                      setRorateTable(e?.target.value);
                    }}
                  />
                </div>
                <div className="d-flex gap-4 align-items-center ">
                  <button
                    className="btn-edit-delete-floor"
                    onClick={() => setShowModalOTPDeleteTable(true)}
                  >
                    <img src={trashRedRemoveFloor} alt="" />
                  </button>
                  <button className="btn-save-table" onClick={SaveMultiTable}>
                    {/* <img src={changePosition} alt="" /> */}
                    {/* <SaveIcon className="save-icon-setup-table" /> */}
                    {languageUI.save}
                  </button>
                </div>

                {/* <button className="btn-edit-delete-floor">
                                <img src={changePosition} alt="" />
                            </button> */}
              </div>
            </div>
          </div>
          <div
            className={`custom-btn-close  ${
              tableSelected === "" ? "d-none" : ""
            } `}
            onClick={() => {
              setTableSelected("");
              setOptionMenu(false);
              setArrayMultiSelectTable([]);
            }}
          >
            <img src={closeLineWhite} alt="" />
          </div>
        </div>
      </div>
      <ModalToast
        show={showToast}
        handleClose={dismissToast}
        content={contentToast}
      />
      <ModalOTPDeleteTable
        handleClose={dismisModalOTPDeleteTable}
        show={showModalOTPDeleteTable}
        UserIdStore={UserIdStore}
        handleSuccess={removeTable}
      />
      <ModalConfirm
        handleClose={dismisModalOTPClearTable}
        show={showModalClearTable}
        content={languageUI.youWantToClear}
        messageError=""
        submit={ClearFormTable}
      />
      <ModalAddFloor
        handleClose={dismisModalAddFloor}
        show={showModalAddFloor}
        listFloor={listFloor}
        handleSuccess={handleAddSuccess}
      />
      <ModalAddTable
        handleClose={dismisModalAddTable}
        show={showModalAddTable}
        floor_id={listFloor[indexFloor]?.id}
        handleSuccess={() => gListTable(listFloor[indexFloor]?.id)}
        setShowLoading={setShowLoading}
      />
    </div>
  );
});

export default SetupTable;
