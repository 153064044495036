import { Modal } from "react-bootstrap";
import { leaf1, plusGreen, searchGreen } from "../../../../components/ImgExport";
import "./Styles.css"
import Select from "react-select";
import ItemsChooseRaw from "./ItemsChooseRaw";
import * as comboApi from "../../../../api/apiOwner/comboApi";
import { useEffect, useState } from "react";
import { ListIngredient } from "../../../../api/apiOwner/templateMenuApi";
import React from 'react'
import Select2 from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getLanguageUI } from "../../../../Services/languageUI";
interface ModalRawMaterialProps {
  comboIngredient: comboApi.ComboIngredient[];
  comboUnit: comboApi.ComboUnit[];
  listIngredient: ListIngredient[];
  show: boolean;
  onSave: (listIngredient: ListIngredient[]) => void;
  handleClose: () => void;
}
const ModalRawMaterial = ({
  handleClose,
  listIngredient = [],
  comboIngredient = [],
  comboUnit = [],
  onSave,
  show
}: ModalRawMaterialProps) => {
  const languageUI = getLanguageUI().menuTemplate
  const [listIngredientTmp, setListIngredientTmp] = useState<ListIngredient[]>([])
  const [ingredientName, setIngredientName] = useState<string>('')
  const [ingredientId, setIngredientId] = useState<string>('')
  const [quantity, setQuantity] = useState<number | string>('')
  const [unitId, setUnitId] = useState<string>('')
  const [unitName, setUnitName] = useState<string>('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [messageError, setmessageError] = useState<string>('')
  const [indexUnit, setIndexUnit] = useState<number>()

  const handleOnchageIngredientId = (e: any) => {
    setIngredientId(e?.value ?? "")
    setIngredientName(e?.label ?? "")
  }
  const handleOnchageUnit = (index: number) => {
    setIndexUnit(index)
    setUnitId(comboUnit[index]?.id ?? "")
    setUnitName(comboUnit[index]?.unit_name ?? "")
  }

  const AddItem = () => {
    const item = {
      ingredient_id: ingredientId, ingredient_name: ingredientName, unit_id: unitId, unit_name: unitName, quantity: Number(quantity)
    };
    if (ingredientId !== '' && unitId !== '' && Number(quantity) > 0) {
      if (!listIngredientTmp.some(item => item?.ingredient_id === ingredientId && item?.unit_id === unitId)) {
        setListIngredientTmp([...listIngredientTmp, item])

      }
      resetForm()
    }

  }
  const resetForm = () => {
    setmessageError('')
    setIngredientName('')
    setIngredientId('')
    setUnitId('')
    setUnitName('')
    setQuantity('')
    if (comboUnit.length > 0) {
      setIndexUnit(0)
      setUnitId(comboUnit[0]?.id ?? "")
      setUnitName(comboUnit[0]?.unit_name ?? "")
    }
  }

  const onRemove = async (index: number) => {
    setListIngredientTmp([...listIngredientTmp.filter((item, i) => i !== index)])
  }

  const onSaveRaw = () => {
    handleClose()
    onSave(listIngredientTmp)
  }
  useEffect(() => {
    if (comboUnit.length > 0) {
      setIndexUnit(0)
      setUnitId(comboUnit[0]?.id ?? "")
      setUnitName(comboUnit[0]?.unit_name ?? "")
    }
  }
    , [comboUnit])
  useEffect(() => {
    if (show) {
      setListIngredientTmp(listIngredient);
      resetForm()

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  return (
    <Modal show={show} onHide={handleClose} className="add-raw-materials">
      <Modal.Dialog className="bg-gray-light">
        <Modal.Body>
          <div className="pt-4 px-48px  pb-120px">
            <p className="mb-1 create-new-food text-center">{languageUI.rawMaterialInWarehouse}</p>
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex align-items-center border px-2 rounded-8px h-42px w-fit">
                <img src={searchGreen} alt="" />
                <Select
                  className="react-select react-select-container w-180px  border-select-none"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.searchAndSelect}
                  isClearable={true}
                  value={ingredientId !== '' ? {
                    value: ingredientId,
                    label: ingredientName
                  } : [""]}
                  options={comboIngredient.map((item) => {
                    return {
                      value: item.id,
                      label: item.ingredient_name
                    };
                  })}
                  onChange={(e: any) => handleOnchageIngredientId(e)}
                />
              </div>
              <div className="d-flex align-items-center border px-2 rounded-8px h-42px w-fit justify-content-center">
                <input type="number" className="border-none foscus-visiable-none w-30px input-qlt-raw" placeholder={languageUI.qty} value={quantity} onChange={(e) => {
                  if (e.target.value && Number(e.target.value) < 0) {
                    return;
                  }
                  setQuantity(Number(e?.target?.value))
                }} />
              </div>
              <div className="d-flex align-items-center border rounded-8px h-42px w-fit justify-content-center">
                <Select2
                  className='w-80px h-42px p-0'
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-5"
                  placeholder='Choose voucher ID'
                  value={indexUnit}
                  onChange={(e: any) => handleOnchageUnit(Number(e.target.value))}

                >
                  {comboUnit.map((item, i) => (
                    < MenuItem key={i} value={i} > {item.unit_name}</MenuItem>

                  ))}
                </Select2>
                {/* <Select
                                    className="react-select react-select-container w-64px  border-select-none"
                                    classNamePrefix="react-select"
                                    defaultValue={[""]}
                                    placeholder="unit"
                                    isClearable={true}
                                    value={unitId !== '' ? {
                                        value: unitId,
                                        label: unitName
                                    } : [""]}
                                    options={comboUnit.map((item) => {
                                        return {
                                            value: item.id,
                                            label: item.unit_name,
                                        };
                                    })}
                                    onChange={(e: any) => handleOnchageUnit(e)}
                                /> */}
              </div>
              <div className="d-flex align-items-center border-green px-2 rounded-8px h-42px w-fit justify-content-center" onClick={AddItem}>
                <img src={plusGreen} alt="" />
              </div>
            </div>
            <div className="d-flex gap-2 flex-wrap mt-3">
              {listIngredientTmp.map((item, i) => (
                <ItemsChooseRaw name={item?.ingredient_name} mass={item?.unit_name} unit={item?.quantity} key={`inge${i}`} index={i} onRemove={onRemove} />
              ))}


            </div>
            <img src={leaf1} alt="" className="position-leaf1" />
            <div className="box-btn-add-new-food">
              <div className="d-flex justify-content-between align-items-center px-68px w-100">
                <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>{languageUI.cancel}</div>
                <div className=" btn-save-cancel-floor text-primary bg-white border-green" onClick={onSaveRaw}>{languageUI.saveMaterials}</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal.Dialog>

    </Modal>
  )
}
export default ModalRawMaterial