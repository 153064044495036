import { Form, InputGroup, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "../Styles.css"
import { useEffect, useState } from "react";
import React from "react";
import { calender, minusGreen, plusGreen, searchGreen } from "../../../../../components/ImgExport";
import Select from "react-select";
import { ComboIngredient } from "../../../../../api/apiOwner/comboApi";
import { gInfoMaterial, importMaterial } from "../../../../../api/apiOwner/InventoryApi";
import moment from "moment-timezone";
import { getLanguageUI } from "../../../../../Services/languageUI";
// import ModalDatePicker from "react-mobile-datepicker-ts";



interface ModalImportInventoryProps {
    show: boolean;
    UserIdStore?: number | null;
    comboIngredient: ComboIngredient[];
    setShowLoading: (e: boolean) => void;
    handleClose: () => void;
    handleSuccess: (mess: string) => void;
}

const ModalImportInventory = ({
    show,
    UserIdStore = 0,
    setShowLoading,
    comboIngredient = [],
    handleClose,
    handleSuccess
}: ModalImportInventoryProps) => {
    const languageUI = getLanguageUI().inventoryPage
    const [startDate, setStartDate] = useState<any>(new Date());
    const [tableNumberDay, setTableNumberDay] = useState<number>(0)
    const [tableNumberUnit, setTableNumberUnit] = useState<number>(0)
    const [quantity, setQuantity] = useState<string | number>('')
    const [totalPrice, settotalPrice] = useState<string | number>('')
    const [importer, setimporter] = useState<string>('')
    const [reminder_option, setReminder_option] = useState<number>(1)
    const [ingredient_id, setIngredient_id] = useState('')
    const [unitName, setUnitName] = useState('')
    const [Inventory_stock, setInventory_stock] = useState<number>(0)
    const [messageError, setMessageError] = useState<string>('')

    const ResetForm = () => {
        setStartDate(new Date());
        setTableNumberDay(0)
        setTableNumberUnit(0)
        setQuantity('')
        settotalPrice('')
        setimporter('')
        setReminder_option(1)
        setIngredient_id('')
        setUnitName('')
        setInventory_stock(0)
        setMessageError('')
    }
    const handleOnchageMaterial = async (material_id: string) => {
        if (!material_id) {
            setUnitName('')
            setInventory_stock(0)
            setIngredient_id('')
            return;
        }
        setIngredient_id(material_id)
        const req = await gInfoMaterial(material_id);
        if (Number(req?.status) === 1) {
            setUnitName(req?.data?.unit_name ?? '');
            setInventory_stock(req?.data?.Inventory_stock ? Number(req?.data?.Inventory_stock) : 0);
        }

    }
    const SubmitImport = async () => {

        if (UserIdStore) {
            if (ingredient_id === '') {
                setMessageError(languageUI.pleaseEnterMaterial);
            } else if (!startDate || startDate === '') {
                setMessageError(languageUI.pleaseEnterDate)
            } else if (quantity === '' || Number(quantity) <= 0) {
                setMessageError(languageUI.pleaseEnterImportNumber)
            } else if (!totalPrice || Number(totalPrice) <= 0) {
                setMessageError(languageUI.pleaseEnterTotalPrice)
            } else if (importer === '') {
                setMessageError(languageUI.plesaeEnterImporter)
            } else if (reminder_option === 1 && tableNumberDay <= 0) {
                setMessageError(languageUI.pleaseEnterDayNumber)
            } else if (reminder_option === 2 && tableNumberUnit <= 0) {
                setMessageError(languageUI.pleaseMassNumber)
            } else {
                setMessageError('')
                var reminder_value = reminder_option === 1 ? tableNumberDay : tableNumberUnit;
                var dateAdd = moment.utc(startDate).format("YYYY-MM-DD");
                // console.log({ ingredient_id, startDate: moment(startDate).format("YYYY-MM-DD"), quantity, totalPrice, importer, reminder_option, tableNumberDay, tableNumberUnit });
                setShowLoading(true);
                const req = await importMaterial(UserIdStore, ingredient_id, dateAdd, Number(quantity), Number(totalPrice), importer, reminder_option, reminder_value)
                if (Number(req?.status) === 1) {
                    setShowLoading(false);
                    handleSuccess("Successfully imported raw materials")
                } else {
                    setShowLoading(false);
                    setMessageError(req?.message ?? "Material import failed");
                }
            }
        } else {
            setMessageError('Owner not found');
        }
    }
    useEffect(() => {
        if (!show) {
            ResetForm();
        }
    }, [show])

    return (
        <>
            <Modal show={show} onHide={handleClose} centered className="modal-delete-customer modal-import-inventory">
                <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
                    <Modal.Body>
                        <p className="text-delete-modal-customer mb-0 text-center">{languageUI.importMaterial}</p>
                        <div className="d-flex justify-content-between mt-3 mb-1">
                            <div className="w-48">
                                <p className="text-add-table-name mb-1">{languageUI.materialName}</p>
                                <div className="input-add-table ps-2 pe-1">
                                    <img src={searchGreen} alt="" />
                                    <Select
                                        className="react-select react-select-container w-100 select-unit-new-material border-select-none"
                                        classNamePrefix="react-select"
                                        defaultValue={[""]}
                                        placeholder={languageUI.searchAndSelect}
                                        isClearable={true}
                                        options={comboIngredient.map((item) => {
                                            return {
                                                value: item.id,
                                                label: item.ingredient_name,
                                            };
                                        })}
                                        onChange={(e: any) => handleOnchageMaterial(e?.value)}
                                    />
                                </div>
                            </div>
                            <div className="w-48">
                                <p className="text-add-table-name mb-1">{languageUI.dateAdded}</p>
                                <div className="input-add-table ps-2 pe-1 ">
                                    <img src={calender} alt="" className="me-1" />
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText="To day" className="border-none focus-visiable-none  text-input-add-table" />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2 mt-6">
                            <div>
                                <p className="text-add-table-name mb-1">{languageUI.importNumber}</p>
                                <div className="input-add-table">
                                    <input type="number" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder={languageUI.enterNumber} value={quantity} onChange={(e) => {
                                        if (e.target.value && Number(e.target.value) < 0) {
                                            return;
                                        }
                                        setQuantity(e.target.value)
                                    }
                                    } />
                                </div>
                            </div>
                            <p className="mb-0 mt-30px text-function-inventory">+</p>
                            <div>
                                <p className="text-add-table-name mb-1">{languageUI.totalLeftInStock}</p>
                                <div className="input-add-table bg-fafafa">
                                    <input type="number" disabled={true} className="border-none focus-visiable-none text-input-add-table px-2 w-100 " placeholder="00" value={Inventory_stock} />
                                </div>
                            </div>
                            <p className="mb-0 mt-30px text-function-inventory">=</p>
                            <div>
                                <p className="text-add-table-name mb-1 text-ff7b2c">{languageUI.total}</p>
                                <div className="input-add-table bg-fafafa">
                                    <input type="number" disabled={true} className=" border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder="00" value={Inventory_stock + Number(quantity)} />
                                </div>
                            </div>
                        </div>
                        <div className="mt-6">
                            <p className="text-add-table-name mb-1">{languageUI.totalPrice}</p>
                            <InputGroup className="mb-3  h-42px rounded-8px">
                                <Form.Control
                                    placeholder="Total price"
                                    aria-describedby="basic-addon2"
                                    className="text-input-usd"
                                    type="number"
                                    value={totalPrice}
                                    onChange={(e) => settotalPrice(e.target.value)}
                                />
                                <InputGroup.Text id="basic-addon2" className="text-input-usd" >USD</InputGroup.Text>
                            </InputGroup>
                        </div>
                        <div className="mt-6">
                            <p className="text-add-table-name mb-1">{languageUI.importer}</p>
                            <div className="input-add-table">
                                <input type="text" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder={languageUI.enterName} value={importer} onChange={(e) => setimporter(e.target.value)} />
                            </div>
                        </div>
                        <div className="mt-6">
                            <p className="mb-0 text-note-import-inventory">{languageUI.note}</p>
                            <div className="d-flex ps-1 gap-1">
                                <li className="mb-0 text-title-import-inventory"> Pound, Liter, Bottle:</li>
                                <p className="mb-0 text-content-import-inventory">{languageUI.poundLiterBotter}</p></div>
                            <div className="d-flex ps-1 gap-1">
                                <li className="mb-0 text-title-import-inventory"> Box:</li>
                                <p className="mb-0 text-content-import-inventory">{languageUI.boxSetReninder}</p>
                            </div>
                            <p className="mb-0 text-content-import-inventory" >{languageUI.becauseItCanBe}</p>
                        </div>
                        <div className="mt-6">
                            <p className="text-add-table-name mb-1">{languageUI.chooseOneReminder}</p>
                            <div>
                                <Form defaultValue={"option-inventory-1"}>
                                    <div className="d-flex justify-content-between align-items-center h-50px">
                                        <Form.Check // prettier-ignore
                                            type="radio"
                                            id={`option-inventory-1`}
                                            label={languageUI.timeBase}
                                            name="option-inventory"
                                            className={`d-flex align-items-center w-fit radio-choose-option-inventory ${reminder_option === 1 ? "text-green" : ""}`}
                                            checked={reminder_option === 1}
                                            onClick={() => setReminder_option(1)}
                                        />
                                        <div className={`d-flex align-items-center gap-2 ${reminder_option === 1 ? '' : 'd-none'}`}>
                                            <div className="d-flex gap-2 align-items-center">
                                                <div className="btn-remove-plus-number" onClick={() => {
                                                    if (tableNumberDay > 0) {
                                                        setTableNumberDay(tableNumberDay - 1)
                                                    }
                                                }}>
                                                    <img src={minusGreen} alt="" />
                                                </div>
                                                <div className="input-number-table-multi">
                                                    <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={tableNumberDay} onChange={(e) => {
                                                        if (e.target.value && Number(e.target.value) <= 0) {
                                                            return;
                                                        }
                                                        setTableNumberDay(Number(e.target.value))
                                                    }} />
                                                </div>
                                                <div className="btn-remove-plus-number" onClick={() => {
                                                    setTableNumberDay(tableNumberDay + 1)
                                                }}>
                                                    <img src={plusGreen} alt="" />
                                                </div>
                                            </div>
                                            <p className="mb-0 text-date-unit-inventory">{languageUI.day}</p>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-18px h-50px">
                                        <Form.Check // prettier-ignore
                                            type="radio"
                                            id={`option-inventory-2`}
                                            label={languageUI.lowSetReminder}
                                            className={`d-flex align-items-center w-fit radio-choose-option-inventory ${reminder_option === 1 ? "" : "text-green"}`}
                                            name="option-inventory"
                                            checked={reminder_option === 2}
                                            onClick={() => setReminder_option(2)}
                                        />
                                        <div className={`d-flex align-items-center gap-2 pe-2 ${reminder_option === 1 ? 'd-none' : ''}`}>
                                            <div className="d-flex gap-2 align-items-center">
                                                <div className="btn-remove-plus-number" onClick={() => {
                                                    if (tableNumberUnit > 0) {
                                                        setTableNumberUnit(tableNumberUnit - 1)
                                                    }
                                                }}>
                                                    <img src={minusGreen} alt="" />
                                                </div>
                                                <div className="input-number-table-multi">
                                                    <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={tableNumberUnit} onChange={(e) => {
                                                        if (e.target.value && Number(e.target.value) <= 0) {
                                                            return;
                                                        }
                                                        setTableNumberUnit(Number(e.target.value))
                                                    }} />
                                                </div>
                                                <div className="btn-remove-plus-number" onClick={() => {
                                                    setTableNumberUnit(tableNumberUnit + 1)
                                                }}>
                                                    <img src={plusGreen} alt="" />
                                                </div>
                                            </div>
                                            <p className="mb-0 text-date-unit-inventory">{unitName}</p>
                                        </div>
                                    </div>

                                </Form>
                            </div>

                        </div>
                        <div className="text-danger text-center">{messageError}</div>
                        <div className="d-flex justify-content-between w-100 mt-6">
                            <div className="btn-import-inventory-footer " onClick={handleClose}>{languageUI.back}</div>
                            <div className="btn-import-inventory-footer border-12B064 text-white bg-12B064" onClick={SubmitImport}>{languageUI.save}</div>
                        </div>
                    </Modal.Body>
                </Modal.Dialog>

            </Modal>
        </>

    );
};

export default ModalImportInventory;
