
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Styles.css"
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';


// import Checkbox from '@mui/material/Checkbox';
// import { CheckBox, TableRows } from "@mui/icons-material";

import { ChatIcon } from "../../../../../components/Image";

import ModalSendSMSReview from "./ModalSendSMSReview";
import { ComboVoucher } from "../../../../../api/apiOwner/comboApi";
interface ContentTableReviewProps {
    customer_id: string;
    name?: string;
    point?: string;
    orderId?: string;
    reviewColor?: string;
    reviewName?: string;
    phone?: string;
    message?: string;
    handleChecked?: (e: boolean, customer: string, phone: string, name: string) => void;
    icon?: string;
    checked: boolean;
    comboVoucher?: ComboVoucher[]
}
const ContentTableReview = ({
    name = '',
    customer_id = '',
    orderId = '',
    phone = '',
    point = '',
    reviewColor = "",
    reviewName = '',
    message = '',
    icon = '',
    checked = false,
    handleChecked,
    comboVoucher = []
}: ContentTableReviewProps) => {
    const [showModalSendSMS, setShowModalSendSMS] = useState(false);
    const dismisModalSendSMs = () => {
        setShowModalSendSMS(false)
    }

    return (
        <>
            <TableRow className="d-table align-items-center mb-2 w-100">
                <TableCell className="w-10 border-none"><Checkbox checked={checked} name='chkBox' onClick={(e: any) => handleChecked?.(e.target.checked, customer_id, phone, name)} /></TableCell>
                <TableCell className="text-269AFF text-17px font-urbansist w-10 border-none">{point}</TableCell>
                <TableCell className="text-32324D text-17px font-urbansist w-16 border-none">{name}</TableCell>
                <TableCell align="left" className="text-32324D text-17px font-urbansist w-16 border-none">{orderId}</TableCell>
                <TableCell align="left" className="w-16 border-none">
                    <div className="">
                        <span className={`radius-1  text-17px font-urbansist p-review-inf border-none ${reviewColor}`}>{icon + " " + reviewName}</span>
                    </div>
                </TableCell>
                <TableCell align="left" className="text-269AFF text-17px font-urbansist w-16 border-none" >{message}</TableCell>
                <TableCell align="left" className="text-32324D text-17px font-urbansist w-16 border-none">{phone}</TableCell>

            </TableRow>
            <ModalSendSMSReview onHide={dismisModalSendSMs} show={showModalSendSMS} comboVoucher={comboVoucher} />
        </>
    )
}
export default ContentTableReview