import * as Request from "../Request";
export interface ClockInList {
    id: string,
    staff_id: string,
    clock_in: string,
    clock_out: string,
    working_time: string,
    name: string
}
export interface Result {
    status: number,
    message?: string,
    data?: ClockInList[],
    total?: string
}
export interface InfoStaff {
    staff_name: string,
    payroll_value: string,
    staff_id: string,
    clock_in: string,
    clock_out: string,
    totalTip: string,
    dateCheck: string
}
export interface StaffTicket {
    id: string,
    owner_id: string,
    custommer_id: string,
    table_id: string,
    table_name: string,
    tip_amount: string,
    tip_staff_id: string,
    staff_name: string,
    price: string,
    timeorder: string,
    total_order: string,
    vat_amount: string
}

export interface ResultSticket {
    status: number,
    message?: string,
    data?: StaffTicket[],
    infoStaff: InfoStaff
}
export const gListCustomerReceipts = async (owner_id: number, page: number = 1) => {
    return await Request.fetchAPI(`owner/gListTimeKeepingByDate.php`, "POST", { owner_id, page })
        .then((res: Result) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
                total: "1"
            };
        });
};

export const setTimekeeping = async (owner_id: number, type: number, passCode: string) => {
    return await Request.fetchAPI(`owner/Timekeeping.php`, "POST", { owner_id, type, passCode })
        .then((res: Result) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
            };
        });
};

export const gStaffTicket = async (owner_id: number, staff_id: string, timming_id: string) => {
    return await Request.fetchAPI(`owner/gStaffTicket.php`, "POST", { owner_id, staff_id, timming_id })
        .then((res: ResultSticket) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
                infoStaff: undefined
            };
        });
};