import "./Styles.css";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModalCheckedSuccess from "../../../../../../components/ModalSuccess/ModalCheckedSuccess";
import { checkedSuccessGif } from "../../../../../../components/ImgExport";
import ModalShowTipRule from "../../../ModalShowTipRule/ModalShowTipRule";
import {
  TipPersonRule,
  editTipPercenForPositionByOwner,
  gTipPercenForPosition,
} from "../../../../../../api/apiOwner/StaffApi";
import * as userSlice from "../../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import ModalLoading from "../../../../../../components/ModalLoading";
import { getLanguageUI } from "../../../../../../Services/languageUI";
import HelpIcon from "@mui/icons-material/Help";
import { Button, Popover } from "@mui/material";

interface TipRuleProp {}
const TipRule: React.FC<TipRuleProp> = () => {
  const languageUI = getLanguageUI().staffPage;
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalLoading, setShowModalLoading] = useState(false);

  const [total, setTotal] = useState<number>();
  const [messErr, setMessErr] = useState<string>("");

  const [showModalTipRule, setShowModalTipRule] = useState(false);
  const [listPositionTipRule, setListPositionTipRule] = useState<
    TipPersonRule[]
  >([]);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const getTipPercenForPosition = async () => {
    setShowModalLoading(true);
    if (UserIdStore) {
      const req = await gTipPercenForPosition(UserIdStore);
      if (Number(req.status) === 1) {
        setListPositionTipRule(req.data ?? []);
        setShowModalLoading(false);
      }
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const updateTipPercenForPositionByOwner = async () => {
    setShowModalLoading(true);
    if (messErr !== "") {
      setShowModalLoading(false);
      return;
    }
    if (UserIdStore) {
      const res = await editTipPercenForPositionByOwner(
        UserIdStore,
        listPositionTipRule
      );
      if (Number(res.status) === 1) {
        setShowModalLoading(false);
        setShowModalSuccess(true);
      }
    }
  };

  const dismisModalTipRule = () => {
    setShowModalTipRule(false);
  };
  const changeTotalPosition = (id: string, value: number) => {
    if (Number(value) >= 0) {
      setListPositionTipRule(
        listPositionTipRule.map((item) => {
          return Number(item.position_id) === Number(id)
            ? { ...item, percent_amount: Number(value) }
            : item;
        })
      );
    }
  };
  useEffect(() => {
    getTipPercenForPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let totalPosition = 0;
    listPositionTipRule.map(
      (item) => (totalPosition += Number(item.percent_amount))
    );
    setTotal(totalPosition);
    if (Number(totalPosition) > 100) {
      setMessErr("total cannot be greater than 100");
    } else {
      setMessErr("");
    }
  }, [listPositionTipRule]);
  return (
    <>
      <ModalLoading
        onHide={() => setShowModalLoading(false)}
        show={showModalLoading}
      />
      <div className="mt-2 p-4 w-100 h-100vh-158px">
        <div className="w-100 bg-white rounded-5 p-4 ">
          <div className="d-flex justify-content-between border-bottom pb-2">
            <div className="d-flex align-items-center gap-2">
              <h2 className="m-0 text-4a4a6a">{languageUI.setupTiprule}</h2>
              <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                style={{ backgroundColor: "transparent", boxShadow: "none" }}
              >
                <HelpIcon
                  className="text-primary "
                  style={{ fontSize: "40px" }}
                />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
              >
                <div className="p-2 w-500px">
                  <p className="text-popover-tip">
                    The person receiving the tip will get a percentage of it
                    based on the settings you choose. The remainder will be
                    distributed among other staff members according to your
                    specified settings. For example, if you set the tip
                    distribution to 80% for the primary recipient and 20% for
                    Beverages, and a customer leaves a $10 tip, then the primary
                    recipient will receive 80%, which is $8. The remaining 20%,
                    which amounts to $2, will be allocated to the beverage
                    staff. If there are more than one beverage staff members in
                    the restaurant, this 20% will be evenly split among all of
                    them. For instance, if there are two beverage staff members,
                    each will receive 10% of the total tip, which in this case
                    would be $1 each.
                  </p>
                </div>
              </Popover>
            </div>

            <button
              className="rounded-4 border-none bg-orange shadow-button-default px-5 py-1"
              onClick={() => setShowModalTipRule(true)}
            >
              <InfoOutlinedIcon className="text-white" />
            </button>
          </div>
          <div className="py-2 w-100 border-bottom">
            <p className="font-bold text-11151f m-0">
              <li>{languageUI.pleaseEnSureTheTotal}</li>
            </p>
            {/* <p className='font-bold text-11151f m-0 '><li>{languageUI.allBevarageNeed}</li></p>
            <p className='font-bold text-11151f m-0 '><li>{languageUI.allCleanTableNeed}</li></p> */}
            <div className="p-5">
              {listPositionTipRule.map((item) => (
                <div
                  className="d-flex align-items-center  mt-1"
                  key={`position-tip-rule-${item.position_id}`}
                >
                  <div className="w-70">
                    <p className="text-11151f font-medium">
                      {item.positon_name}:
                    </p>
                  </div>
                  {/* <div className="w-50">
                    <p className="text-11151f font-medium">
                      {languageUI.plusOff}{" "}
                      <p className="text-danger font-medium d-inline">
                        {languageUI.allTotalTip}
                      </p>{" "}
                      {languageUI.enterNumberOnly}
                    </p>
                  </div> */}
                  <div className="w-30 d-flex gap-2 align-items-center">
                    <div className="border rounded-4 py-2 px-4">
                      <input
                        type="number"
                        inputMode="numeric"
                        className="border-none focus-visiable-none  w-150px font-bold"
                        value={item.percent_amount.toString()}
                        onChange={(e) =>
                          changeTotalPosition(
                            item.position_id,
                            Number(e.target.value)
                          )
                        }
                      />
                    </div>
                    <p className="m-0 text-11151f">(%)</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between p-4 w-100">
            <div className="w-70 d-flex justify-content-between align-items-center pe-3">
              <h4 className="font-bold text-11151f">{languageUI.total}:</h4>
              <p className="m-0 text-danger text-italic">{messErr}</p>
            </div>
            <div className="d-flex align-items-center gap-4 w-30">
              <div className="d-flex align-items-center gap-2">
                <div className="w-100px h-40px rounded-4 border text-11151f font-bold d-flex justify-content-center align-items-center">
                  {total}
                </div>
                <p className="m-0 text-11151f">(%)</p>
              </div>
              <button
                className="border-none rounded-4 w-100px h-40px text-white font-bold bg-primary shadow-button-default"
                onClick={updateTipPercenForPositionByOwner}
              >
                {languageUI.save}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalCheckedSuccess
        handleClose={() => {
          setShowModalSuccess(false);
        }}
        img={checkedSuccessGif}
        show={showModalSuccess}
        title="Success"
        handleSuccess={() => {
          setShowModalSuccess(false);
        }}
      />
      <ModalShowTipRule
        show={showModalTipRule}
        handleClose={dismisModalTipRule}
      />
    </>
  );
};
export default TipRule;
