import { Modal } from "react-bootstrap";
import "./Styles.css"
import { uploadImage } from "../../../../components/ImgExport";
import { useEffect, useRef, useState } from "react";
import { foodPlaceholder } from "../../../../components/Image";
import ModalLoading from "../../../../components/ModalLoading";
import React from 'react'
import { editDevicePos, gDevicePosById } from "../../../../api/apiAdmin/ConfigSetting";
import { targetImgadmin } from "../../../../api/Request";
import { getLanguageCheckIn } from "../../../../Services/languageCheckin";

interface ModalEditEquipmentProps {
  show: boolean;
  handleClose: () => void;
  id: string
}

const ModalEditEquipment = ({
  show,
  handleClose,
  id
}: ModalEditEquipmentProps) => {
  const languageUI = getLanguageCheckIn();
  const [EquipmentNameAdd, setEquipmentNameAdd] = useState<string>("");
  const [PhotoTmp, setPhotoTmp] = useState<string>(""); //ảnh tạm tải lên
  const avtRef = useRef<HTMLInputElement>(null);
  const [MessageToast, setMessageToast] = useState<string>("");
  const [File, setFile] = useState<File>(); //ảnh tạm tải lên
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [descriptionFoodEdit, setDescriptionFoodEdit] = useState<string>("");
  const [equipmentPrice, setEquipmentPrice] = useState<number>();

  const ChangeAVT = () => {
    (avtRef as any).current.click();
  };

  const DevicePosById = async (id: string) => {
    setShowLoading(true)
    const res = await gDevicePosById(id)
    if (Number(res.status) === 1) {
      setPhotoTmp(targetImgadmin + res.data?.imgurl ?? '')
      setDescriptionFoodEdit(res.data?.description ?? '')
      setEquipmentPrice(Number(res.data?.price))
      setEquipmentNameAdd(res.data?.title ?? '')
      setShowLoading(false)
    }
  }
  const EditDevice = async () => {
    setShowLoading(true)
    if (EquipmentNameAdd === '') {
      setMessageToast(languageUI.Equipmentnameisrequire)
      setShowLoading(false)
      return;


    }
    if (equipmentPrice === undefined || equipmentPrice === 0) {
      setMessageToast(languageUI.Equipmentpriceisrequire)
      setShowLoading(false)
      return;


    }
    if (equipmentPrice) {
      if (equipmentPrice < 0) {
        setMessageToast(languageUI.pricemustbegreaterthan0)
        setShowLoading(false)
        return;

      }
    }
    const res = await editDevicePos(id, EquipmentNameAdd, descriptionFoodEdit, equipmentPrice, File)
    if (Number(res.status) === 1) {
      handleClose()
      setShowLoading(false)
    }
  }



  const maxLength = 200

  const onSelectFile = async (picture: any) => {
    var file = picture[0];
    if (!file.type.match("image")) {
      setMessageToast(languageUI.Thefileyouselectedisnotinthecorrectformat);
    } else {
      setMessageToast("");
      var picReader = new FileReader();
      picReader.readAsDataURL(file);
      picReader.onload = function () {
        setFile(file);

        setPhotoTmp(picReader.result?.toString() ?? '');
      };
      picReader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }
  };
  useEffect(() => {
    if (show) {
      DevicePosById(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])



  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <Modal show={show} onHide={handleClose} className="add-new-food">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Body>
            <div className="h-100vh position-relative">
              <div className="px-68px pt-48px h-100vh overflow-y-scroll pb-120px">
                <p className="mb-1 create-new-food mb-2">{languageUI.Editequipment}</p>
                <div className="text-danger text-center">{MessageToast}</div >
                <div className="w-100 d-flex justify-content-center">
                  <img
                    className={`img-fluid mx-auto rounded-5 object-cover border ${PhotoTmp === "" ? "d-none" : ""}`}
                    src={PhotoTmp && PhotoTmp !== "" ? PhotoTmp : foodPlaceholder}
                    alt=""
                    style={{ width: 100, height: 100 }}
                    onClick={(e) => {
                      ChangeAVT();
                    }}
                  />
                </div>
                <div className={`box-upload-image d-flex gap-2 justify-content-start position-relative ${PhotoTmp !== "" ? "d-none" : ""}`}>
                  <input
                    type="file"
                    ref={avtRef}
                    accept="image/*"
                    onChange={(e) => {
                      onSelectFile(e.target.files);
                    }}
                    className="position-absolute w-100 h-100px opacity-0 top-0 start-0"
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <img src={uploadImage} alt="" className="h-fit" />
                  <div>
                    <p className="upload-food-image mb-1">{languageUI.UploadequipmentImages}</p>
                    <p className="text-content-upload-image d-flex gap-2px flex-wrap mb-0 h-fit">{languageUI.Recommendedimageformats}: <p className="h-fit mb-0 text-12B064">JPG, PNG</p>, {languageUI.Atleast} <p className="h-fit mb-0 text-12B064">800 pixels</p> {languageUI.foroptimaldisplay}</p>
                  </div>
                </div>
                <div className="mt-2">
                  <p className="text-add-table-name mb-1"><li>{languageUI.EquipmentName}</li></p>
                  <div className="input-add-table bg-white">
                    <input type="text" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder={languageUI.EquipmentName} value={EquipmentNameAdd} onChange={(e) => {
                      setEquipmentNameAdd(e.target.value);
                    }} />
                  </div>
                  <div className="mt-2">
                    <p className="text-add-table-name mb-1">{languageUI.Price}($)</p>
                    <div className="input-add-table bg-white">
                      <input type="number" className=" bg-tranparent w-100 border-none focus-visiable-none text-input-add-table px-2 " placeholder={languageUI.Price} value={equipmentPrice} onChange={(e) => {
                        setEquipmentPrice(Number(e.target.value));
                      }} />
                    </div>
                  </div>
                  <div className="mt-2">
                    <p className="text-add-table-name mb-1">{languageUI.Description}</p>
                    <div>
                      <textarea
                        className="text-more-request"
                        id="textarea"
                        value={descriptionFoodEdit}
                        placeholder="Placeholder Text"
                        onChange={(e) => {
                          setDescriptionFoodEdit(e.target.value);
                        }}
                        maxLength={maxLength}
                      ></textarea>
                      <div className="w-100 d-flex justify-content-end px-3">
                        <small className="text-muted text-right">
                          {descriptionFoodEdit.length}/{maxLength}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="box-btn-add-new-food">
                  <div className="d-flex justify-content-between align-items-center px-68px w-100">
                    <div className="btn-save-cancel-floor text-4a4a6a border" onClick={handleClose}>{languageUI.Cancel}</div>
                    <div className=" btn-save-cancel-floor text-white bg-0FA54A border-0FA54A" onClick={EditDevice}>{languageUI.Save}</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>

      </Modal>

    </>

  );
};

export default ModalEditEquipment;
