import ErrorIcon from '@mui/icons-material/Error';
import { Modal } from "react-bootstrap";
import "./Styles.css"
import { warningIcon } from '../ImgExport';



interface ModalAlertProps {
    show: boolean;
    dismisImg?: boolean;
    title: string;
    img: string;
    handleClose: () => void;
}

const ModalAlert = ({
    show,
    dismisImg = false,
    handleClose,
    img,
    title
}: ModalAlertProps) => {
    return (
        <Modal show={show} onHide={handleClose} centered className="modal-alert">
            <Modal.Dialog className="bg-gray-light">
                <Modal.Body className='abc'>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <img src={warningIcon} alt="" className='width-50px height-50px' />
                        <div className="rounded-5  p-2 d-flex flex-column align-items-center ">

                            <img src={img} alt="" className={`h-140px w-140px aspect-ratio-1 rounded-circle ${dismisImg ? "d-none" : ""}`} />
                            <p className="text-xl mt-1 font-bold mb-0 text-center">{title}</p>
                        </div>
                        <div className=" py-1 mt-2 bg-red text-white text-lg px-3 font-bold rounded-5" onClick={handleClose}>
                            Close
                        </div>
                    </div>

                    {/* <div className="d-flex justify-content-center">
                        <div className="rounded-4 bg-red px-3 py-1 text-lg text-white font-bold w-fit mt-2">
                            Close
                        </div>
                   </div> */}
                </Modal.Body>
            </Modal.Dialog>

        </Modal>
    );
};

export default ModalAlert;
