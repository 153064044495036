import { Form, Modal } from "react-bootstrap";
import "./styles.css";
import FoodBillItem from "./component/FoodBillItem/FoodBillItem";
// import SplitBill from "../ModalSplitBillDragDrop/SplitBill";
import {
  InfoOrder,
  OrderWait,
  splitBill,
} from "../../../../api/apiCheckin/orderConfilmApi";
import { FormatDolla } from "../../../../utils/format";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import { getLanguageUI } from "../../../../Services/languageUI";
interface ModalSplitBillProps {
  show: boolean;
  orderInfo?: OrderWait;
  handleClose: () => void;
  handleSuccess: (id: string) => void;
  setShowLoading: (status: boolean) => void;
}
const ModalSplitBill = ({
  handleClose,
  orderInfo,
  show,
  setShowLoading,
  handleSuccess,
}: ModalSplitBillProps) => {
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const [listFoodSelected, setListFoodSelected] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const LanguageUI = getLanguageUI().posTable;
  const handleChooded = (status: boolean, idorder: string) => {
    if (status && idorder !== "") {
      setListFoodSelected([...listFoodSelected, idorder]);
    } else {
      setListFoodSelected(listFoodSelected.filter((item) => item !== idorder));
    }
  };

  const SubmitSplit = async () => {
    if (listFoodSelected.length === 0) {
      setMessage("Please select the dish you want to split the bill");
    } else {
      setShowLoading(true);
      const req = await splitBill(
        UserIdStore,
        orderInfo?.order_id ?? "",
        listFoodSelected
      );
      if (req?.status === 1) {
        handleSuccess(req?.data ?? "");
      } else {
        setMessage(req?.message);
        setShowLoading(false);
      }
    }
  };
  useEffect(() => {
    if (show) {
      setListFoodSelected([]);
    }
  }, [show]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      className="modal-add-printer"
    >
      <Modal.Body>
        <h1 className="text-center">{LanguageUI.splitbill}</h1>
        <div className="px-10">
          <div className=" w-100 p-4">
            <p className="mb-1 text-bill-pay-bill">
              Bill-#{orderInfo?.order_id}
            </p>
            <div className="px-3">
              <div className="rounded-5 border p-4  ">
                <Form className="d-flex flex-column gap-2">
                  {orderInfo &&
                    orderInfo?.listFood &&
                    orderInfo?.listFood.map(
                      (item, i) =>
                        item?.id && (
                          <Form.Check
                            key={i}
                            className="d-flex p-0"
                            inline
                            label={
                              <div className="d-flex justify-content-between ">
                                <p className="mb-0 font-bold ">{`${i + 1}. ${
                                  item?.name
                                }`}</p>
                                <p className="mb-0 text-black font-bold">{`${
                                  item?.quantity
                                }x ${FormatDolla(Number(item?.price))}`}</p>
                              </div>
                            }
                            name="split-bill"
                            type={"checkbox"}
                            id={`split-bill-${i}`}
                            checked={listFoodSelected.includes(item?.id)}
                            onClick={(e: any) => {
                              if (
                                e?.target?.checked &&
                                listFoodSelected.length >=
                                  orderInfo?.listFood.length - 1
                              ) {
                                return;
                              }
                              handleChooded(e?.target?.checked, item?.id ?? "");
                            }}
                          />
                        )
                    )}
                </Form>
              </div>
            </div>
          </div>
        </div>
        {message && (
          <div className="text-danger text-italic text-center">{message}</div>
        )}
        <div className="mt-2 flex justify-content-between mt-4 px-5">
          <div
            className="text-white font-bold h-40px w-48  w-fit d-flex justify-content-center align-items-center  bg-delete-light rounded-3"
            onClick={handleClose}
          >
            Cancel
          </div>
          <div
            className="text-white font-bold h-40px w-48  w-fit d-flex justify-content-center align-items-center rounded-3 bg-primary"
            onClick={SubmitSplit}
          >
            Ok
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ModalSplitBill;
