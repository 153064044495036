

import "./Styles.css"
import { cartBagOrange, storeOrange } from "../../../../components/ImgExport";
import * as orderConfilmApi from "../../../../api/apiCheckin/orderConfilmApi"
import { FormatDolla } from "../../../../utils/format";
interface OrderListITemPrintPosMenuProps {
  notefood_name: string,
  name: string;
  form_order: string;
  quantity: number;
  price: number;
  side_name?: string;
  note_bill?: string;
}

const OrderListITemPrintPosMenu = ({
  form_order,
  name,
  notefood_name,
  price,
  quantity,
  side_name,
  note_bill,
}: OrderListITemPrintPosMenuProps) => {
  return (
    <>
      <div className="w-100">
        <div className="d-flex justify-content-between align-items-start w-100">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-start gap-2 w-100">
              <p className="title-infor-bill-customer text-black mb-0">{name}</p>
              <div className="d-flex align-items-end gap-1 h-fit flex-shink-0">
                <div className="d-flex align-items-end ">
                  <div className="title-infor-bill-customer line-height-normal text-black text-black m-0">{quantity}x</div>
                </div>
                <div className="d-flex align-items-start gap-2px">
                  <div className="title-infor-bill-customer line-height-normal text-black text-4A4A6A">{FormatDolla(Number(price))}$</div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              {notefood_name && (
                <div className="text-black">
                  <p className="mb-0 text-items-order-print text-black">{notefood_name}</p>
                </div>
              )}
              {side_name && (
                <div className="text-black">
                  <p className="mb-0 text-items-order-print text-black">{side_name}</p>
                </div>
              )}
              <div className="text-black d-flex flex-wrap align-items-center">
                <p className="mb-0 text-items-order-print text-black fs-12 flex-shink-0 me-1">{form_order == '1' ? "Dine in" : "Take away"}</p>
                {note_bill && (
                  <p className="mb-0 text-items-order-print text-black fs-12">{note_bill}</p>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </>

  );
};

export default OrderListITemPrintPosMenu;
