import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
// components
import Select from "react-select";
import { usFlag } from "../../components/Image";
import PageTitle from "../../components/PageTitle";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalLoading from "../../components/ModalLoading";
import * as CreateCustomerAdminAPI from "../../api/apiAdmin/CreateCustomerAdmin";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import { ComboOption, gComboTimeZone } from "../../api/apiOwner/comboApi";
import moment from "moment-timezone";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from "./PaymentForm";
import { gConfigStripeAdmin } from "../../api/apiAdmin/ConfigSetting";
// import PaymentForm from './PaymentForm';
// import { gstripeKeyOwner } from "../../../../api/apiOwner/StripeOwnerApi";
import PlaceIcon from '@mui/icons-material/Place';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import { getLanguageCheckIn } from "../../Services/languageCheckin";
import React from "react";
// maker của map



const CreateCustomerAdmin = () => {
  const location = useLocation()
  const navigate = useNavigate();

  const UserIdStore = useSelector(userSlice.selectorUserId);
  const languageUI = getLanguageCheckIn();
  const [ListPackage, setListPackage] =
    useState<CreateCustomerAdminAPI.IListPackage[]>();
  const [ContactPerson, setContactPerson] = useState<any>("");
  const [StoreName, setStoreName] = useState<any>("");
  const [CellPhone, setCellPhone] = useState<any>("");
  const [Address, setAddress] = useState<any>("");
  const [EmailAddress, setEmailAddress] = useState<any>("");
  const [SuitOfUnit, setSuitOfUnit] = useState<any>("");
  const [City, setCity] = useState<any>("");
  const [States, setStates] = useState<any>("");
  const [OwnerPassCode, setOwnerPassCode] = useState<any>("");
  const [Role, setRole] = useState<number>(0);
  const [Package, setPackage] = useState<number>(0);
  const [PaymentStatus, setPaymentStatus] = useState<number>(0);
  const [IdCard, setIdCard] = useState<any>("");
  const [CVC, setCVC] = useState<any>("");
  const [timeZone, setTimeZone] = useState<number>(0)
  const [listTimeZone, setListTimeZone] = useState<ComboOption[]>([])

  const newday = new Date(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (Z)'));
  const monthNow =
    Number(newday.getMonth()) + 1 < 10
      ? "0" + (Number(newday.getMonth()) + 1)
      : newday.getMonth() + 1;
  const yearNow = newday.getFullYear();
  const startDateFormatRef = useRef<any>(monthNow + "-" + yearNow);
  const [Message, setMessage] = useState<any>("");
  const [MessageSuccess, setMessageSuccess] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [OwnerIdPackage, setOwnerIdPackage] = useState(0);
  const [messPasscodeErr, setMessPasscodeErr] = useState<string>('')
  const [zipCode, setZipCode] = useState<number | string>('')
  const [password, setPassword] = useState<string>('')
  const formRef = useRef<HTMLFormElement>(null);
  const [publishKey, setpublishKey] = useState<string>('');
  //
  // Datepicker
  const [detailAddress, setDetailAddress] = useState<string>("");
  const [locat, setlocat] = useState<{ lat: number, lng: number }>({ lat: 0, lng: 0 }); //tọa độ map địa chỉ
  const [isMarkerShown, setIsMarkerShown] = useState<boolean>(false);


  const ReloadForm = () => {
    setContactPerson("");
    setStoreName("");
    setCellPhone("");
    setAddress("");
    setEmailAddress("");
    setSuitOfUnit("");
    setCity("");
    setStates("");
    setOwnerPassCode("");
    setRole(0);
    setPackage(0);
    setPaymentStatus(0);
    setIdCard("");
    setCVC("");
    setMessage("");
    setMessageSuccess("");
    setShowLoading(false);
    setZipCode('')
  };

  const GetListPackage = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const ListPackage = await CreateCustomerAdminAPI.ListPackage();

      if (Number(ListPackage.status) === 1) {
        setListPackage(ListPackage?.data);
      } else {
        setListPackage([]);
      }
      setShowLoading(false);
    } else {
      navigate("/auth/login");
    }
  };



  const createNewStaff = async () => {
    // if (OwnerPassCode.length !== 4) {
    //   setMessPasscodeErr('Passcode must include 4 digits')
    // } else if (!password || password === '') {
    //   setMessPasscodeErr('')
    //   Swal.fire({
    //     position: "center",
    //     icon: "error",
    //     title: "Password is required.",
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // } else if (password.length < 4) {
    //   setMessPasscodeErr('')
    //   Swal.fire({
    //     position: "center",
    //     icon: "error",
    //     title: "Weak password.",
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // } else
    //   if (!timeZone || timeZone === 0) {
    //     setMessPasscodeErr('')
    //     Swal.fire({
    //       position: "center",
    //       icon: "error",
    //       title: "Please enter time zone",
    //       showConfirmButton: false,
    //       timer: 3000,
    //     });
    //   } else {
    if (Number(PaymentStatus) === 0) {
      SubmitToken()
    } else {
      formRef.current && formRef.current.click();
    }

    // }


  };

  const SubmitToken = async (tokenpayment?: string, lastCart?: string | number) => {

    if (UserIdStore) {
      if (PaymentStatus !== 1 && tokenpayment == '') {
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Please enter card infomation",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      setMessPasscodeErr('')
      setShowLoading(true);
      const AddOwner = await CreateCustomerAdminAPI.AddOwnerinAdmin(
        UserIdStore,
        ContactPerson,
        StoreName,
        CellPhone,
        Address,
        EmailAddress,
        SuitOfUnit,
        City,
        // BusinessType,
        States,
        OwnerPassCode,
        Role,
        Package,
        PaymentStatus,
        IdCard,
        startDateFormatRef.current,
        CVC,
        password,
        detailAddress,
        locat.lat,
        locat.lng,
        timeZone,
        zipCode,
        tokenpayment,
        lastCart
      );
      if (Number(AddOwner.status) === 1) {
        if (Number(AddOwner?.data?.payment_status) === 1) {
          setOwnerIdPackage(AddOwner?.data?.owner_id ?? 0);
          setModalPayment(true);
        } else {
          ReloadForm();
          Swal.fire({
            position: "center",
            icon: "success",
            title: languageUI.Createsuccess,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: AddOwner?.message
            ? AddOwner?.message
            : languageUI.SomethingwentwrongPleasetryagainlater,
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setShowLoading(false);
    } else {
      setShowLoading(false);
      navigate("/web-admin/login");
    }
  }
  const PaymentPackage = async () => {
    const Pay = await CreateCustomerAdminAPI.Payment(OwnerIdPackage, Package);
    if (Number(Pay?.status) === 1) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: languageUI.Createsuccess,
        showConfirmButton: false,
        timer: 1500,
      });
      ReloadForm();
      setModalPayment(false);
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: Pay?.status ? Pay?.status.toString() : languageUI.SomethingwentwrongPleasetryagainlater,
        showConfirmButton: false,
        timer: 3000,
      });
      setModalPayment(false);

      return;
    }
  };



  const onchagePhoneNumber = (e: any) => {
    let phone = e?.target.value;
    const phoneReplace = phone
      ?.replace(/[-]/g, "")
      .replace(/[()]/g, "")
      .replace(/\s+/g, "")
      .replace(/[_]/g, "");
    setCellPhone(phoneReplace);
  };

  let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
  let menuItemElOwner = document?.querySelectorAll(".menu-owner");
  menuItemElAdmin.forEach((item, i) => {
    menuItemElAdmin[i]?.classList.remove("d-none");
  });
  menuItemElOwner.forEach((item, i) => {
    menuItemElOwner[i]?.classList.add("d-none");
  });

  const gComboTime = async () => {
    const req = await gComboTimeZone();
    if (Number(req?.status) === 1) {
      setListTimeZone(req?.data ?? [])
    }
  }
  const gConfigStripe = async () => {
    const req = await gConfigStripeAdmin();
    if (Number(req?.status) === 1) {
      setpublishKey(req?.publishKey ?? '')
    }
  }
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setDetailAddress(value);
    setlocat(latLng);
    setIsMarkerShown(!isMarkerShown);
  }

  useEffect(() => {
    ReloadForm();
    gConfigStripe()
    gComboTime();
    GetListPackage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Customer", path: "#" },
          { label: "Create customer", path: "#", active: true },
        ]}
        title={"Create customer"}
      />

      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

      <Modal show={modalPayment} onHide={() => setModalPayment(false)}>
        <Modal.Header className="py-2 border-bottom" closeButton>
          <Modal.Title>{languageUI.Payment}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-3">
          <h3 className="text-center">{languageUI.Doyouwanttopay}</h3>
        </Modal.Body>
        <Modal.Footer className="justify-content-end border-top">
          <Button variant="primary" onClick={() => PaymentPackage()}>
            {languageUI.YesIwant}
          </Button>
          <Button variant="danger" onClick={() => setModalPayment(false)}>
            {languageUI.Cancel}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="p-3 rounded-3 mb-3 bg-white shadow">
        <h4 className="header-title fs-5 mb-3">{languageUI.Storedetail}</h4>
        <Row className="mx-n2">
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.Contactperson}:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={ContactPerson}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.Contactperson}
                    onChange={(e: any) => {
                      setContactPerson(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.Storename}:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={StoreName}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.Storename}
                    onChange={(e: any) => {
                      setStoreName(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.Cellphone}:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 bg-white">
                    <img src={usFlag} height={21} alt="" />
                  </InputGroup.Text>

                  <MaskedInput
                    mask={[
                      "(",
                      /[0-9]/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      "-",
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    placeholder="(___) ___-____"
                    className="form-control"
                    value={CellPhone}
                    onChange={onchagePhoneNumber}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.Address}:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={Address}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.Address}
                    onChange={(e: any) => {
                      setAddress(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.Emailaddress}:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={EmailAddress}
                    type="email"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.Emailaddress}
                    onChange={(e: any) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.Suitofunit}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={SuitOfUnit}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.Suitofunit}
                    onChange={(e: any) => {
                      setSuitOfUnit(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.City}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={City}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.City}
                    onChange={(e: any) => {
                      setCity(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>

          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.States}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={States}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.States}
                    onChange={(e: any) => {
                      setStates(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">Zip Code:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="Zip Code"
                    value={zipCode}
                    onChange={(e) => { if (e.target.value && (e.target.value.length > 10 || Number(e.target.value) < 0)) { return } setZipCode(e.target.value) }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.OwnerPassCode} ({languageUI.loginOwnerMode} ):<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <MaskedInput
                  mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                  className="form-control"
                  placeholder="(xxxx):number"
                  value={OwnerPassCode}
                  onChange={(e: any) => {
                    setOwnerPassCode(e.target.value);
                  }}
                />
              </div>
              <div className="text-italic text-danger mt-1">{messPasscodeErr}</div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  Time zone:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <Select
                  className="react-select react-select-container mb-3"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder="Time zone"
                  isClearable={true}
                  options={listTimeZone}
                  onChange={(e: any) => {
                    if (e == null) {
                      setTimeZone(0);
                    } else {
                      setTimeZone(e.value);
                    }
                  }}
                ></Select>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className=""> {languageUI.Webpassword} ({languageUI.loginPOS}):<span className="text-danger">*</span></Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder={languageUI.Enterpassword}
                    value={password}
                    onChange={(e) => { setPassword(e.target.value) }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2 pt-2">
                <Form.Label className=""> {languageUI.ShippingAddress}:<span className="text-danger">*</span></Form.Label>
              </div>
              <div className="col-10">
                <div className={`d-flex justify-content-between border-DEE2E6 radius-2 py-2 px-2 mt-2 w-100`}>
                  <PlacesAutocomplete
                    value={detailAddress}
                    onChange={setDetailAddress}
                    onSelect={handleSelect}

                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="w-100 position-relative">
                        <div className="d-flex align-items-center w-100">
                          <input
                            className="focus-visible-none border-none w-100"

                            style={{
                              width: "calc(100% - 25px)",
                            }}
                            {...getInputProps({
                              placeholder: languageUI.Enteryourdetailaddress,
                            })}
                          />
                          {detailAddress !== "" ? (
                            <CloseIcon className="text-dark font-md"
                              onClick={(e) => {
                                setDetailAddress("");
                              }} />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="dropdown-place w-100 position-absolute left-0 right-0 z-index-9 mt-2  px-3">
                          {/* {loading && <div>Loading...</div>} */}
                          {suggestions.map((suggestion) => {
                            const style = suggestion.active
                              ? { backgroundColor: "#a83232", cursor: "pointer" }
                              : { backgroundColor: "#ffffff", cursor: "pointer" };
                            return (
                              <div
                                className="d-flex align-items-center flex-wrap py-1"
                                {...getSuggestionItemProps(suggestion, { style })}
                              >
                                <PlaceIcon />
                                <span className="phone-order-online" style={{ maxWidth: "calc(100% - 45px)" }}>
                                  {suggestion.description}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              </div>
            </div>
          </Col>
        </Row>


        <h4 className="header-title fs-5 mb-3 mt-2">{languageUI.Subscription}</h4>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  Package:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <Select
                  className="react-select react-select-container mb-3"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder="Package"
                  isClearable={true}
                  options={ListPackage}
                  onChange={(e: any) => {
                    if (e == null) {
                      setPackage(0);
                    } else {
                      setPackage(e?.value);
                    }
                  }}
                ></Select>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  {languageUI.Paymentstatus}:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <Select
                  className="react-select react-select-container mb-3"
                  classNamePrefix="react-select"
                  defaultValue={[""]}
                  placeholder={languageUI.Paymentstatus}
                  isClearable={true}
                  options={[
                    { value: "0", label: languageUI.Unpaid },
                    { value: "1", label: languageUI.Paid },
                  ]}
                  onChange={(e: any) => {
                    if (e == null) {
                      setPaymentStatus(0);
                    } else {
                      setPaymentStatus(e.value);
                    }
                  }}
                ></Select>
              </div>
            </div>
          </Col>
        </Row>
        <div className="dropdown-divider mt-0"></div>
        { }
        <h4 className="header-title fs-5 mb-3 mt-2">{languageUI.Cardinformation}</h4>
        <Elements stripe={loadStripe(publishKey)}>
          <PaymentForm formRef={formRef} getToken={SubmitToken} />
        </Elements>
        {/* <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  Id Card:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <MaskedInput
                  mask={[
                    /[0-9]/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  placeholder="____-____-____-____"
                  className="form-control mb-3"
                  value={IdCard}
                  onChange={onchageCardID}
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  Date of Card:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <HyperDatepicker
                  hideAddon={true}
                  value={selectedStartDate}
                  dateFormat="MMMM yyyy"
                  onChange={(date) => {
                    onDateChangeStart(date);
                  }}
                  showMonthYearPicker
                />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">
                  CVC:<span className="text-danger">*</span>
                </Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={CVC}
                    maxLength={4}
                    type="number"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    placeholder="CVC"
                    onChange={(e: any) => {
                      setCVC(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}
        <div className="text-center text-success">{MessageSuccess}</div>
        <div className="text-center text-danger">{Message}</div>
        <div className="text-end">
          <Button
            variant="primary"
            className="bg-opacity-25 mt-3"
            onClick={() => {
              createNewStaff();
            }}
          >
            {languageUI.Create}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateCustomerAdmin;
