import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Styles.css";
import * as userSlice from "../../store/slices/userSlice";
import * as flagSlice from "../../store/slices/FlagSettingSlice";
import { useSelector } from "react-redux";
import ZoomOutMapRoundedIcon from "@mui/icons-material/ZoomOutMapRounded";
import ZoomInMapRoundedIcon from "@mui/icons-material/ZoomInMapRounded";
import LanguageIcon from "@mui/icons-material/Language";
import {
  ClockIconMenu,
  CustomerIconMenu,
  FeedbackIcon,
  InventoryIconMenu,
  KitChenCounter,
  MenuPOS,
  QRCodeIcon,
  ReceipTax,
  ReportIconMenu,
  Reservation,
  SettingIconClock,
  StaffMenuIcon,
  SubscriptionIconMenu,
  TablePOS,
  VoucherIconMenu,
  handheldPOSIcon,
  historySVG,
  offlineOwner,
  orderOwner,
  pickupIcon,
  reviewMenuOwner,
} from "../../components/SVGChangeColor/SVGChangeColor";
import ModalOTPOwnerStaff from "../../pages/webowner/components/ModalOTPOwnerStaff/ModalOTPOwnerStaff";
import React from "react";
import { useDispatch } from "react-redux";
// import reviewMenuOwner from '../../components/SVGChangeColor/SVGChangeColor';
import Swal from "sweetalert2";
import socket from "../../utils/socket";
import { getLanguageUI } from "../../Services/languageUI";
import ModalLogOut from "../../pages/webowner/components/ModalLogOut/ModalLogOut";
import { formatDate, formatPhoneNumber } from "../../utils/format";
import { gSettingUpdatePhone } from "../../api/apiAdmin/SettingUpdatePhoneNumberAdmin";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ModalOTPLogOut from "../../pages/webowner/components/ModalOTPLogOut/ModalOTPLogOut";

interface SidebarProps {
  showFullSideBar: boolean;
  changeFullSideBar: () => void;
}
const Sidebar = ({ showFullSideBar, changeFullSideBar }: SidebarProps) => {
  const languageUI = getLanguageUI().layout;
  const statusSetting = useSelector(flagSlice.selectorSetting);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOwner = localStorage.getItem("isOwner");
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndex2, setActiveIndex2] = useState(0);
  const [stepHeight, setStepHeight] = useState(0);
  const sidebarRef = useRef<any>();
  const location = useLocation();
  const nameStore = useSelector(userSlice.selectorNameStore);
  const stationName = useSelector(userSlice.selectorPosName);
  const [statusTable, setStatusTable] = useState(true);
  const [showMakerReservation, setShowMakerReservation] = useState(false);
  const [showModalLogout, setShowModalLogout] = useState(false);
  const is_ownerMenu = localStorage.getItem("is_ownerMenu");
  const [showModalOTPOwner, setShowModalOTPOwner] = useState(false);
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [phoneSupport, setPhoneSupport] = useState<string>("");
  const [SMSSupport, setSMSSupport] = useState<string>("");
  const [webSupport, setWebSupport] = useState<string>("");
  // const [isOwner, setIsOwner] = useState<boolean>(false)
  const dismisModalOTPOwner = () => {
    setShowModalOTPOwner(false);
  };
  const dismisModallLogout = () => {
    setShowModalLogout(false);
  };
  const handleLogout = () => {
    navigate("/auth/logout");
  };

  const HandleOtpOwner = () => {
    setStatusTable(!statusTable);
    localStorage.setItem("is_ownerMenu", "1");
    navigate("/web-owner/qrcode");
  };

  useEffect(() => {
    if (is_ownerMenu && is_ownerMenu === "1") {
      setStatusTable(false);
    } else {
      setStatusTable(true);
    }
    console.log(statusTable);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sidebarNavStaffItems = [
    {
      display: languageUI.tablePOS,
      icon: TablePOS,
      to: "/web-owner/tablepos",
      section: ["/web-owner/tablepos"],
      is_show: 1,
    },
    {
      display: languageUI.menuPOS,
      icon: MenuPOS,
      to: "/web-owner/posmenu",
      section: ["/web-owner/posmenu"],
      is_show: 0,
    },

    {
      display: languageUI.clockInOut,
      icon: ClockIconMenu,
      to: "/web-owner/clockinclockout",
      section: ["/web-owner/clockinclockout"],
      is_show: 1,
    },
    {
      display: languageUI.order,
      icon: orderOwner,
      to: "/web-owner/order",
      section: ["/web-owner/order"],
      is_show: 1,
    },

    {
      display: languageUI.reservation,
      icon: Reservation,
      to: "/web-owner/reservation",
      section: ["/web-owner/reservation"],
      is_show: 1,
    },

    {
      display: languageUI.history,
      icon: historySVG,
      to: "/web-owner/history",
      section: ["/web-owner/history"],
      is_show: 1,
    },
    {
      display: languageUI.pickup,
      icon: pickupIcon,
      to: "/web-owner/pickup",
      section: ["/web-owner/pickup"],
      is_show: 1,
    },
  ];

  const feebbackPage = {
    display: languageUI.feedback,
    icon: FeedbackIcon,
    to: "/web-owner/feedback",
    section: ["/web-owner/feedback"],
  };

  const sidebarNavOwnerItems =
    isOwner === "1"
      ? [
          {
            display: "QRCode",
            icon: QRCodeIcon,
            to: "/web-owner/qrcode",
            section: ["/web-owner/qrcode"],
          },
          {
            display: languageUI.setting,
            icon: SettingIconClock,
            to: "/web-owner/settingownerinformation",
            section: ["/web-owner/settingownerinformation"],
          },
          {
            display: "Station",
            icon: VoucherIconMenu,
            to: "/web-owner/pos-setting",
            section: ["/web-owner/pos-setting"],
          },
          {
            display: languageUI.setUpTable,
            icon: TablePOS,
            to: "/web-owner/setuptable",
            section: ["/web-owner/setuptable"],
          },
          {
            display: languageUI.menuTemplate,
            icon: MenuPOS,
            to: "/web-owner/menutemplate",
            section: ["/web-owner/menutemplate"],
          },
          {
            display: languageUI.customer,
            icon: CustomerIconMenu,
            to: "/web-owner/customer",
            section: ["/web-owner/customer"],
          },
          {
            display: languageUI.customerReciepts,
            icon: ReceipTax,
            to: "/web-owner/customerreciepts",
            section: ["/web-owner/customerreciepts"],
          },
          {
            display: languageUI.report,
            icon: ReportIconMenu,
            to: "/web-owner/report",
            section: [
              "/web-owner/report",
              "/web-owner/report/incomedetail",
              "/web-owner/report/incomedatedetail",
            ],
          },
          // {
          //   display: languageUI.review,
          //   icon: reviewMenuOwner,
          //   to: "/web-owner/review",
          //   section: ["/web-owner/review", "/web-owner/detailreview"],
          // },
          {
            display: languageUI.inventory,
            icon: InventoryIconMenu,
            to: "/web-owner/inventory",
            section: ["/web-owner/inventory"],
          },
          {
            display: languageUI.staffs,
            icon: StaffMenuIcon,
            to: "/web-owner/staffs",
            section: [
              "/web-owner/staffs",
              "/web-owner/creatstaffs",
              "/web-owner/editstaffs",
              "/web-owner/salary2week,/web-owner/salaryweek",
              "/web-owner/tablesalary2week",
              "/web-owner/tablesalaryweek",
            ],
          },
          {
            display: languageUI.setUpVoucher,
            icon: VoucherIconMenu,
            to: "/web-owner/setupvoucher",
            section: ["/web-owner/setupvoucher"],
          },
          {
            display: languageUI.giftCard,
            icon: VoucherIconMenu,
            to: "/web-owner/giftCard",
            section: [
              "/web-owner/giftCard",
              "/web-owner/giftCardDetail",
              "/web-owner/giftCardHistory",
            ],
          },
          {
            display: "Handheld POS",
            icon: handheldPOSIcon,
            to: "/web-owner/handheldPOS",
            section: ["/web-owner/handheldPOS"],
          },
          {
            display: languageUI.kitchenCounter,
            icon: KitChenCounter,
            to: "/kitchencounter",
            section: ["/kitchencounter"],
            is_show: 1,
          },

          {
            display: languageUI.subscription,
            icon: SubscriptionIconMenu,
            to: "/web-owner/subscription",
            section: ["/web-owner/subscription"],
          },

          {
            display: languageUI.offlineMode,
            icon: offlineOwner,
            to: "/web-owner/offlinebooking",
            section: ["/web-owner/offlinebooking"],
          },
        ]
      : [
          {
            display: languageUI.menuTemplate,
            icon: MenuPOS,
            to: "/web-owner/menutemplate",
            section: ["/web-owner/menutemplate"],
          },
          {
            display: languageUI.customerReciepts,
            icon: ReceipTax,
            to: "/web-owner/customerreciepts",
            section: ["/web-owner/customerreciepts"],
          },
        ];

  const curPathLocation = window.location.pathname;

  // change active index
  useEffect(() => {
    const curPath = window.location.pathname;
    const activeItem = sidebarNavStaffItems.findIndex((item) =>
      item.section.some((index) => index === curPath)
    );
    const activeItem2 = sidebarNavOwnerItems.findIndex((item) =>
      item.section.some((index) => index === curPath)
    );
    setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    setActiveIndex2(curPath.length === 0 ? 0 : activeItem2);
    if (
      sidebarNavOwnerItems.some((item) =>
        item?.section.some((child) => child === curPath)
      )
    ) {
      if (is_ownerMenu && is_ownerMenu !== "1") {
        navigate("/");
      }
    }
    // setActiveIndex(curPath.length === 0 ? 0 : activeItem2);
    // console.log(window.location.pathname.split('/'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const HandleChangeTab = (pathName: string) => {
    if (location.pathname === "/web-owner/reservation") {
      setShowMakerReservation(false);
    }
    if (
      statusSetting &&
      location.pathname === "/web-owner/settingownerinformation"
    ) {
      // const confirmed = window.confirm("You have unsaved changes in your page which will be lost, are you sure you want to proceed?");
      // if (confirmed) {
      //   navigate(pathName); // Chuyển đến liên kết nếu xác nhận
      //   dispatch(flagSlice.clearSetting())
      // }
      Swal.fire({
        title: "Are you sure?",
        text: "You have unsaved changes in your page which will be lost, are you sure you want to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(pathName); // Chuyển đến liên kết nếu xác nhận
          dispatch(flagSlice.clearSetting());
        }
      });
    } else {
      navigate(pathName);
    }
  };

  const checkShowMaker = async () => {
    // await socket.on("PushShowBookingTable" + UserIdStore, (data: any) => {
    setShowMakerReservation(true);
    // });
  };
  useEffect(() => {
    if (!UserIdStore) {
      return;
    }
    const eventName = "PushShowBookingTable" + UserIdStore;

    socket.on(eventName, checkShowMaker);

    return () => {
      socket.off(eventName, checkShowMaker);
    };
  }, [UserIdStore]);

  const formatPhoneNumberString = (phoneNumber: string): string => {
    // Loại bỏ tất cả các ký tự không phải số

    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)} ${cleaned.slice(
        6,
        10
      )}`;
    }
  };

  const GetPhone = async () => {
    const GetPhoneNumber = await gSettingUpdatePhone(2);
    if (Number(GetPhoneNumber.status) === 1) {
      setPhoneSupport(GetPhoneNumber.data?.phone);
      setSMSSupport(GetPhoneNumber.data?.sms_support);
      setWebSupport(GetPhoneNumber.data.web_support);
    }
  };

  useEffect(() => {
    GetPhone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="sidebar positon-relative"
      style={{ width: `${showFullSideBar ? "100px" : "190px"}` }}
    >
      <div className="bth-change-side-bar" onClick={changeFullSideBar}>
        {showFullSideBar ? (
          <KeyboardDoubleArrowRightIcon />
        ) : (
          <KeyboardDoubleArrowLeftIcon />
        )}
      </div>
      <div className="sidebar__logo">
        <p
          className="mb-0 name-brand-store w-fit text-two-line"
          style={{ fontSize: `${showFullSideBar ? "14px" : ""}` }}
        >
          {nameStore}
        </p>
        <p
          className="mb-0  name-brand-store w-fit text-two-line"
          style={{
            color: "#fec54a",
            fontSize: `${showFullSideBar ? "14px" : ""}`,
          }}
        >
          {stationName}
        </p>
      </div>
      <div className="box-menu-sidebar">
        <div
          ref={sidebarRef}
          className="sidebar__menu sroll-menu scroll-bar-style"
        >
          <div
            // ref={indicatorRef}
            className="sidebar__menu__indicator"
            style={{
              transform: `translateX(-50%) translateY(${
                activeIndex * stepHeight
              }px)`,
            }}
          ></div>
          {
            <>
              {statusTable
                ? sidebarNavStaffItems.map(
                    (item, index) =>
                      item?.is_show === 1 && (
                        // <Link to={item.to} key={index}>
                        <div
                          className={`sidebar__menu__item position-relative ${
                            activeIndex === index ? "active" : ""
                          }`}
                          key={index}
                          onClick={() => HandleChangeTab(item.to)}
                        >
                          <div
                            className={`sidebar__menu__item__icon  ${
                              activeIndex === index ? "active" : ""
                            }`}
                          >
                            <item.icon
                              fill={` ${
                                activeIndex === index ? "#FF7B2C" : "white"
                              }`}
                            />
                          </div>
                          <div
                            className={`${
                              showFullSideBar ? "d-none" : ""
                            } sidebar__menu__item__text`}
                          >
                            {item.display}
                          </div>
                          {item.to === "/web-owner/reservation" &&
                          showMakerReservation ? (
                            <div className="notification-reservation"></div>
                          ) : (
                            ""
                          )}
                        </div>
                        // </Link>
                      )
                  )
                : sidebarNavOwnerItems.map((item, index) => (
                    // <Link to={item.to} key={index}>
                    <div
                      className={`sidebar__menu__item ${
                        activeIndex2 === index ? "active" : ""
                      }`}
                      key={index}
                      onClick={() => HandleChangeTab(item.to)}
                    >
                      <div
                        className={`sidebar__menu__item__icon  ${
                          activeIndex2 === index ? "active" : ""
                        }`}
                      >
                        <item.icon
                          fill={` ${
                            activeIndex2 === index ? "#FF7B2C" : "white"
                          }`}
                        />
                      </div>
                      <div
                        className={`${
                          showFullSideBar ? "d-none" : ""
                        } sidebar__menu__item__text`}
                      >
                        {item.display}
                      </div>
                    </div>
                    // </Link>
                  ))}
              {/* <div className='sidebar__menu__item  d-flex align-items-center gap-2 w-100 bg-0fa54a' onClick={() => {
            if (statusTable) {
              setShowModalOTPOwner(true)
            } else {
              setStatusTable(true);
              localStorage.setItem("is_ownerMenu", '0');
            }
          }}>
            <div className='w-50px rounded-12px bg-0C843B h-40px d-flex justify-content-center align-items-center'>
              {statusTable ? <ZoomOutMapRoundedIcon className='h-24px w-24px text-white' /> : <ZoomInMapRoundedIcon className='h-24px w-24px text-white' />}
            </div>
            <p className='mb-0 sidebar__menu__item__text'>{statusTable ? "Owner Mode" : "Staff Mode"}</p>
          </div> */}
            </>
          }
        </div>
        <div className="tab-menu-footer custom-fixed-owner-mode mt-2">
          <div className={`pl-3 py-2 ${statusTable ? "" : "d-none"}`}>
            <div
              className={`sidebar__menu__item  ${
                curPathLocation === feebbackPage.to ? "active" : ""
              }`}
              onClick={() => HandleChangeTab(feebbackPage.to)}
            >
              <div
                className={`sidebar__menu__item__icon  ${
                  curPathLocation === feebbackPage.to ? "active" : ""
                }`}
              >
                <feebbackPage.icon
                  fill={` ${
                    curPathLocation === feebbackPage.to ? "#FF7B2C" : "white"
                  }`}
                />
              </div>
              <div
                className={`${
                  showFullSideBar ? "d-none" : ""
                } sidebar__menu__item__text`}
              >
                {feebbackPage.display}
              </div>
            </div>

            <div
              className={`pe-2 pt-2 ${
                webSupport || webSupport !== null ? "" : "d-none"
              }`}
            >
              <div
                className={`btn-web-support  ${
                  showFullSideBar ? "d-flex justify-content-center" : ""
                }`}
                onClick={() => {
                  window.open(webSupport, "_blank", "noopener,noreferrer");
                }}
              >
                <LanguageIcon />
                <p className={`${showFullSideBar ? "d-none" : ""} m-0`}>
                  {languageUI.webSupport}
                </p>
              </div>
            </div>

            <div
              className={`pt-2 ${
                phoneSupport || phoneSupport !== null ? "" : "d-none"
              }`}
            >
              <p className="sidebar__menu__item__text m-0">
                {languageUI.phoneSupport}
              </p>
              <p className="text-white font-normal m-0">
                {formatPhoneNumberString(phoneSupport)}
              </p>
            </div>
            <div
              className={`pt-2 ${
                SMSSupport || SMSSupport !== null ? "" : "d-none"
              }`}
            >
              <p className={` sidebar__menu__item__text m-0`}>
                {languageUI.SMSSupport}
              </p>
              <p className="text-white font-normal m-0">
                {formatPhoneNumberString(SMSSupport)}
              </p>
            </div>
          </div>
          <div
            className="d-flex align-items-center ps-3 gap-2 custom-fixed-owner-mode py-2 w-100 bg-0fa54a h-96px"
            onClick={() => {
              if (statusTable) {
                setShowModalOTPOwner(true);
              } else {
                setStatusTable(true);
                localStorage.setItem("is_ownerMenu", "0");
                navigate("/web-owner/tablepos");
              }
            }}
          >
            <div className="w-50px rounded-12px bg-0C843B h-40px d-flex justify-content-center align-items-center">
              {statusTable ? (
                <ZoomOutMapRoundedIcon className="h-24px w-24px text-white" />
              ) : (
                <ZoomInMapRoundedIcon className="h-24px w-24px text-white" />
              )}
            </div>
            <p
              className={`${
                showFullSideBar ? "d-none" : ""
              } mb-0 sidebar__menu__item__text`}
            >
              {statusTable ? languageUI.ownerMode : languageUI.staffMode}
            </p>
          </div>
          <div
            className={`d-flex align-items-center ps-3 gap-2 custom-fixed-logout-owner py-2 w-100 `}
            onClick={() => setShowModalLogout(true)}
          >
            <div className="w-50px rounded-12px bg-0C843B h-40px d-flex justify-content-center align-items-center">
              <LogoutIcon className="h-24px w-24px text-white" />
            </div>
            <p
              className={` ${
                showFullSideBar ? "d-none" : ""
              } mb-0 sidebar__menu__item__text`}
            >
              {languageUI.logOut}
            </p>
          </div>
        </div>
      </div>
      <ModalOTPOwnerStaff
        handleClose={dismisModalOTPOwner}
        show={showModalOTPOwner}
        UserIdStore={UserIdStore}
        handleSuccess={HandleOtpOwner}
      />
      {/* <ModalLogOut
        handleClose={dismisModallLogout}
        show={showModalLogout}
        handleLogout={handleLogout}
      /> */}
      <ModalOTPLogOut
        handleClose={dismisModallLogout}
        show={showModalLogout}
        UserIdStore={UserIdStore}
        handleSuccess={handleLogout}
      />
    </div>
  );
};

export default Sidebar;
