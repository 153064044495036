import { Modal } from "react-bootstrap";
import './styles.css';

interface ModalOrderFunctionProps {
  show: boolean;
}
const ModalOrderFunction = ({ show }: ModalOrderFunctionProps) => {
  return (
    <Modal
      show={show}
      centered
      className="modal-order-funtion"
    >
      <Modal.Body>
        <p className="text-title-order-function">Order</p>
        <div className="d-flex justify-content-around">
          <div className="btn-order-function">
            For Here
          </div>
          <div className="btn-order-function">
            To Go
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default ModalOrderFunction