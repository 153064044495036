import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Request from "../../api/Request";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const TeamOfUse = () => {
  let params = useParams();
  let id_owner = params?.owner_id;
  const navigate = useNavigate();
  const [nameStore, setNameStore] = useState<string>('');

  const getInforOwner = async () => {
    await Request.fetchAPI(`checkin/gInfoOwner.php?id=${id_owner}`, "get").then(
      (res) => {
        if (Number(res.status) === 1) {
          setNameStore(res?.data?.name ?? '');
        }
      }
    );
  };

  useEffect(() => {
    getInforOwner();
  }, [])
  return (
    <>
      <div className="account-pages d-flex" style={{ minHeight: "100vh" }}>
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <div className="d-flex  justify-content-between mb-2 mt-4">
                    <div className="box-btn-back-privacy-policy" onClick={() => navigate(-1)}>
                      <KeyboardBackspaceIcon className="btn-back-privacy-policy" />
                    </div>
                    <h4 className="text-center font-bold">Terms of Use</h4>
                    <div className="box-btn-back-privacy-policy opacity-0">
                      <KeyboardBackspaceIcon />
                    </div>
                  </div>
                  <p className="text-center text-gray">Last Updated: 1/1/2024</p>
                  <div>
                    <div>
                      <p className="font-bold mb-0">
                        1. Acceptance of Terms
                      </p>
                      <p className="mb-1">
                        •	By accessing and using BriJack POS and {nameStore}, you agree to be bound by these Terms of Use and all applicable laws and regulations.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        2. Privacy Policy
                      </p>
                      <p className="mb-1">
                        •	Your use of BriJack POS and {nameStore} is also governed by our Privacy Policy, which explains how we collect, use, and share your personal information.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        3. User Registration
                      </p>
                      <p className="mb-1">
                        •	Users may be required to register and provide certain information, including a phone number and credit card details, to access certain features.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        4. Communications Consent
                      </p>
                      <p className="mb-1">
                        •	By providing your phone number, you consent to receive SMS messages from BriJack POS and {nameStore} for marketing and other purposes.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        5. Payment and Billing
                      </p>
                      <p className="mb-1">
                        •	Services on BriJack POS and {nameStore} are paid for via credit card. Users are responsible for all charges associated with their account.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        6. User Responsibilities
                      </p>
                      <p className="mb-1">
                        •	Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        7. Limitation of Liability
                      </p>
                      <p className="mb-1">
                        •	BriJack POS and {nameStore} is not responsible for any damages or losses resulting from unauthorized access to, or use of, user information, including credit card information.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        8. Disclaimer of Warranties
                      </p>
                      <p className="mb-1">
                        •	BriJack POS and {nameStore} is provided "as is". We make no warranties, express or implied, and hereby disclaim and negate all other warranties.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        9. Indemnification
                      </p>
                      <p className="mb-1">
                        •	Users agree to indemnify and hold harmless BriJack POS and {nameStore} from any claims, damages, or expenses arising from their use of the site.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        10. Modifications to Terms
                      </p>
                      <p className="mb-1">
                        •	BriJack POS and {nameStore} reserves the right to modify these Terms of Use at any time. Your continued use of the site after any changes indicates your acceptance of the new Terms.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        11. Governing Law
                      </p>
                      <p className="mb-1">
                        •	These terms and conditions are governed by and construed in accordance with the laws of BriJack POS and {nameStore} and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                      </p>
                    </div>
                    <div>
                      <p className="font-bold mb-0">
                        Contact Information
                      </p>
                      <p className="mb-1">
                        •	For any questions or concerns regarding these Terms of Use, please contact us at support@brijack.com
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TeamOfUse;
