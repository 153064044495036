import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";


// actions
import { resetAuth } from "../../redux/actions";

// store
import { AppDispatch } from "../../redux/store";

// components
import "react-mobile-datepicker-ts/dist/main.css";
import { useParams } from 'react-router';
import * as Request from "../../api/Request";
import Cleave from "cleave.js/react";
import moment from "moment";
import React from "react";
import { getLanguageCheckIn } from "../../Services/languageCheckin";


const EnterBirthday = () => {
  const dispatch = useDispatch<AppDispatch>();
  const languageUI = getLanguageCheckIn();

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  const [valueTime, setValueTime] = useState("");
  const [checkError, setCheckError] = useState(false);
  const [errMess, setErMess] = useState("");
  const navigate = useNavigate();

  const params: any = useParams();
  let id_customer = params.id;
  const [show_customer_point, setShow_customer_point] = useState<number>(0);


  const handleSelect = (nextTime: string) => {
    setValueTime(nextTime);

  };

  // Get info customer
  const gInfoCustomer = async (id_customer: any) => {
    await Request.fetchAPI(
      `checkin/gInfoCustomer.php?id=${id_customer}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        getInforOwner(res?.data?.admin_id);
      }
    })
      .catch((error: any) => {
        console.log(error);
      });
  };


  //Get info owner
  const getInforOwner = async (id_owner: any) => {
    await Request.fetchAPI(
      `checkin/gInfoOwner.php?id=${id_owner}`,
      "get"
    ).then((res) => {
      if (Number(res.status) === 1) {
        setShow_customer_point(res?.data?.show_customer_point);
      }
    });
  };

  const GoPagePoint = (e: any) => {
    e.preventDefault();
    if (valueTime === "") {
      setCheckError(true);
      setErMess(languageUI.Pleaseenteryourbirthday);
      return;
    }
    var birth = moment(valueTime, "MM/DD").format("DD/MM")
    localStorage.setItem("birthday", birth);
    addBirthDay(birth, id_customer);
  };

  const addBirthDay = async (valueTimebirthday: any, id_customer: any) => {
    await Request.fetchAPI(`/checkin/uBirthDayCustomer.php`, "post", {
      birthday: valueTimebirthday,
      customer_id: id_customer,
    })
      .then((res) => {
        if (Number(res.status) === 1) {
          if (Number(show_customer_point) === 0) {
            navigate(`/webcheckin/showpointcheckin/${id_customer}`);
          } else {
            navigate(`/webcheckin/choosemenu/${id_customer}`);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  const location = useLocation();

  let bodyEl = document?.querySelector("body");
  console.log(bodyEl);
  bodyEl?.classList.add("pb-0", "m-0", "birthday-page");

  useEffect(() => {
    gInfoCustomer(id_customer);
  }, [location.pathname, id_customer]);
  return (
    <>
      <div
        className="account-pages birthday-page d-flex"
        style={{ minHeight: "100vh" }}
      >
        <Container className="m-auto">
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="bg-pattern rounded-3 shadow mb-0">
                <Card.Body className="p-4">
                  <h4 className="text-center mb-4 mt-4">{languageUI.Yourbirthday}</h4>
                  <div className="text-start">
                    <Cleave
                      className="w-100 border h-40px px-2 focus-visible-gray"
                      placeholder="MM/DD"
                      inputMode="numeric"
                      options={{
                        date: true,
                        delimiter: "/",
                        datePattern: ["m", "d"],
                      }}
                      onChange={(e) => handleSelect(e.target.value)}
                    />
                  </div>
                  <p style={{ color: "#f1556c", textAlign: "center" }}>
                    {checkError ? errMess : ""}
                  </p>

                  <div className="text-center">
                    <Button
                      onClick={GoPagePoint}
                      variant="outline-primary"
                      className="waves-effect waves-light mb-4 mt-3"
                    >
                      {languageUI.go}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EnterBirthday;
