import { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// components
import PageTitle from '../../components/PageTitle';

import InputGroup from 'react-bootstrap/InputGroup';
import * as userSlice from '../../store/slices/userSlice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalLoading from '../../components/ModalLoading';
import * as SettingChangePasswordAPI from '../../api/apiOwner/SettingChangePassword';

const SettingChangePassword = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation()
    const UserIdStore = useSelector(userSlice.selectorUserId);

    const [OldPass, setOldPass] = useState<any>("");
    const [NewPass, setNewPass] = useState<any>("");
    const [RetypeNewPass, setRetypeNewPass] = useState<any>("");
    const [Showpass, setShowpass] = useState<boolean>(false);
    const [ShowNewPass, setShowNewPass] = useState<boolean>(false);
    const [ShowRetypeNewPass, setShowRetypeNewPass] = useState<boolean>(false);
    const [message, setMessage] = useState<any>("");
    const [messageSuccess, setMessageSuccess] = useState<any>("");
    const [ShowLoading, setShowLoading] = useState<boolean>(false);

    const ReloadForm = () => {
        setOldPass("");
        setNewPass("");
        setRetypeNewPass("");
        setShowpass(false);
        setShowNewPass(false);
        setShowRetypeNewPass(false);
        setMessage("");
        setShowLoading(false);
    };

    const changePassword = async () => {
        if (UserIdStore) {
            setShowLoading(true);
            const ChangePassword = await SettingChangePasswordAPI.ChangePassword(UserIdStore, OldPass, NewPass, RetypeNewPass);
            if (Number(ChangePassword.status) === 1) {
                setMessage("");
                setMessageSuccess("Change password success!");
                setTimeout(() => {
                    setShowLoading(false);
                    window.location.reload();
                }, 2000);
            } else {
                setMessageSuccess("");
                setMessage(ChangePassword?.message ? ChangePassword?.message : "Something went wrong. Please try again later!");
            }
            setShowLoading(false);
        } else {
            console.log("Chưa đăng nhập");
            navigate("/auth/login");
        }

    }

    useEffect(() => {
        ReloadForm();
    }, [location.pathname]);

    useEffect(() => {
        let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
        let menuItemElOwner = document?.querySelectorAll(".menu-owner");
        menuItemElAdmin.forEach((item, i) => {
            menuItemElAdmin[i]?.classList.add("d-none");
        });
        menuItemElOwner.forEach((item, i) => {
            menuItemElOwner[i]?.classList.remove("d-none");
        });
    }, [])
    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Setting', path: '#' },
                    { label: 'Change Password', path: '#', active: true },
                ]}
                title={'Setting Password'}
            />
            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
            <div className="p-3 rounded-3 mb-3">
                <Col xs={12} md={6} lg={4} className='mx-auto change-password-col'>
                    <div className='p-3 bg-white shadow rounded-3'>
                        {/* <FormInput
                            label="Old pass:"
                            type="password"
                            name="text"
                            containerClass={'mb-3'}
                            placeholder="Enter old pass"
                            key="text"
                        /> */}
                        <Form.Label>Old pass:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={OldPass}
                                type={`${Showpass ? "text" : "password"}`}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                placeholder='Enter you old pass'
                                onChange={(e: any) => { setOldPass(e.target.value) }}
                            />
                            <InputGroup.Text className={`input-group-password ${Showpass ? "show-password" : ""}`} id="inputGroup-sizing-sm" onClick={() => { setShowpass(!Showpass) }}>
                                <span className="password-eye"></span>
                            </InputGroup.Text>
                        </InputGroup>

                        <Form.Label>New pass:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={NewPass}
                                type={`${ShowNewPass ? "text" : "password"}`}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                placeholder="Enter your new pass"
                                onChange={(e: any) => { setNewPass(e.target.value) }}
                            />
                            <InputGroup.Text className={`input-group-password ${ShowNewPass ? "show-password" : ""}`} id="inputGroup-sizing-sm" onClick={() => { setShowNewPass(!ShowNewPass) }}>
                                <span className="password-eye"></span>
                            </InputGroup.Text>
                        </InputGroup>

                        <Form.Label>Retype new pass:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={RetypeNewPass}
                                type={`${ShowRetypeNewPass ? "text" : "password"}`}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                placeholder="Re-enter new pass"
                                onChange={(e: any) => { setRetypeNewPass(e.target.value) }}
                            />
                            <InputGroup.Text className={`input-group-password ${ShowRetypeNewPass ? "show-password" : ""}`} id="inputGroup-sizing-sm" onClick={() => { setShowRetypeNewPass(!ShowRetypeNewPass) }}>
                                <span className="password-eye"></span>
                            </InputGroup.Text>
                        </InputGroup>

                        <Form.Label className="text-danger">{message}</Form.Label>
                        <Form.Label className="text-success">{messageSuccess}</Form.Label>

                        <Button variant="primary" className="d-block w-100" onClick={() => { changePassword() }}>
                            {t('Save')}
                        </Button>
                    </div>
                </Col>
            </div>
        </>
    );
};

export default SettingChangePassword;
