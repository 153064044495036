import { Button, Modal } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
import Cleave from "cleave.js/react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import moment from "moment";
import { formatDate } from "../../../../utils/format";
interface ModalChangeLastMonthProps {
  show: boolean;
  dismis: () => void;
  handleChangeGoogleReview: (
    date: string,
    rating: string,
    starts: string
  ) => void;
  dateValue: string;
  ratingValue: number | undefined;
  startsValue: number | undefined;
}
const ModalChangeGoogleReview = ({
  show,
  handleChangeGoogleReview,
  dismis,
  dateValue,
  ratingValue,
  startsValue,
}: ModalChangeLastMonthProps) => {
  const [dateChange, setDateChange] = useState<dayjs.Dayjs | null | any>("");
  const [ratingNumberChange, setRatingNumberChange] = useState<string>();
  const [startsNumberChange, setStartNumberChange] = useState<string>();
  const today = dayjs();
  useEffect(() => {
    if (show) {
      setDateChange(dayjs(dateValue));
      setRatingNumberChange(ratingValue?.toString() ?? "");
      setStartNumberChange(startsValue?.toString() ?? "");
    }
  }, [dateValue, ratingValue, startsValue, show]);

  return (
    <Modal
      show={show}
      // eslint-disable-next-line no-sequences
      onHide={dismis}
      centered
      className="modal-rounded-16px"
    >
      <Modal.Header className="py-2 border-bottom" closeButton>
        <Modal.Title>Change Google Review</Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-3">
        <div className="d-flex align-items-center">
          <p className="m-0 font-bold w-200px flex-shrink-0">Enter Date:</p>
          <div className="w-100">
            {/* <Cleave
              className="input-last-year"
              placeholder="MM-DD-YYY"
              inputMode="numeric"
              value={dateChange}
              options={{
                date: true,
                delimiter: "-",
                datePattern: ["m", "d", "Y"],
                dateMax: "31/12/2022",
              }}
              onChange={(e) => setDateChange(e.target.value)}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                // defaultValue={dayjs("2022-04-17")}
                value={dateChange}
                format="MM-DD-YYYY"
                onChange={(e) => {
                  setDateChange(e);
                }}
                maxDate={today}
              />
            </LocalizationProvider>
          </div>
          {/* <input
            className="input-last-year"
            placeholder="please enter number!"
            type="text"
            value={dateChange}
            onChange={(e) => {
              setDateChange(e.target.value);
              console.log(e.target.value);
            }}
          /> */}
        </div>
        <div className="d-flex align-items-center mt-2">
          <p className="m-0 font-bold w-200px flex-shrink-0">
            Enter rating number:
          </p>
          <input
            className="input-last-year"
            placeholder="please enter rating number!"
            type="number"
            value={ratingNumberChange?.toString()}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                setRatingNumberChange(undefined);
              } else {
                setRatingNumberChange(e.target.value);
              }
            }}
          />
        </div>
        <div className="d-flex align-items-center mt-2">
          <p className="m-0 font-bold w-200px flex-shrink-0">
            Enter starts number:
          </p>
          <input
            className="input-last-year"
            placeholder="please enter starts number!"
            type="number"
            value={startsNumberChange?.toString()}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                setStartNumberChange(undefined);
              } else {
                setStartNumberChange(e.target.value);
              }
            }}
          />
        </div>
        {/* <label htmlFor="messLength">Number of message characters: </label>
          <input
            className="border border-dark rounded mx-2 text-center"
            style={{ width: "40px" }}
            type="text"
            name="messLength"
            value={messSendAll.length}
            readOnly
          /> */}
      </Modal.Body>
      <Modal.Footer className="justify-content-end border-top">
        <Button
          variant="primary"
          onClick={() =>
            handleChangeGoogleReview(
              moment(formatDate(dateChange?.$d)).format("YYYY-MM-DD"),
              ratingNumberChange ?? "",
              startsNumberChange ?? ""
            )
          }
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ModalChangeGoogleReview;
