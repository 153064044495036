import * as Request from "../Request";

export const ChangePassword = async (UserIdStore: number, OldPass: string, NewPass: string, RetypeNewPass: string) => {
    return await Request.fetchAPI('owner/change_password_owner.php', 'post', {
        "id_owner": UserIdStore,
        "old_password": OldPass,
        "new_password": NewPass,
        "re_password": RetypeNewPass
    }).then((res: IListReview<IListReviewData[]>) => {
        return res;
    }).catch((err: any) => {
        return {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};
export const ChangePasscode = async (UserIdStore: number, OldPassCode: string, NewPassCode: string, RetypeNewPassCode: string) => {
    return await Request.fetchAPI('owner/change_passcode_owner.php', 'post', {
        "id_owner": UserIdStore,
        "old_passcode": OldPassCode,
        "new_passcode": NewPassCode,
        "re_passcode": RetypeNewPassCode
    }).then((res: IListReview<IListReviewData[]>) => {
        return res;
    }).catch((err: any) => {
        return {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: []
        }
    });
};
export const gConfigDisplay = async (owner_id: number) => {
    return await Request.fetchAPI('owner/gConfigDisplay.php', 'post', {
        owner_id
    }).then((res: IListReview<DisplayData>) => {
        return res;
    }).catch((err: any) => {
        return {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: undefined
        }
    });
};
export const saveConfigDisplay = async (owner_id: number, type_display: number, minutes_display: number) => {
    return await Request.fetchAPI('owner/saveConfigDisplay.php', 'post', {
        owner_id, type_display, minutes_display
    }).then((res: IListReview<string>) => {
        return res;
    }).catch((err: any) => {
        return {
            message: 'An error has occurred. Please check your internet connection',
            status: 0,
            data: undefined
        }
    });
};
export interface IListReviewData {
    id?: number;
    customer_id?: number;
    owner_id?: number;
    review_type_id?: number;
    name?: string;
    start?: string;
    phone?: string;
    username?: string;
}
export interface DisplayData {
    id?: number;
    type_display: number;
    minutes_display: number;
}
export interface IListReview<T> {
    message: string,
    status: number,
    data: T
}
