import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import { useDispatch } from 'react-redux';

import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as userSlice from "../../store/slices/userSlice";
import * as tableSlice from "../../store/slices/tableSlice";
import * as staffTipSlice from "../../store/slices/ListStaffTipSlice";
import * as listFoodGroupSlice from "../../store/slices/listFoodGroupSlice";
import * as menuFoodSlice from "../../store/slices/menuFoodSlice";
import * as floorSlice from "../../store/slices/floorSlice";
import * as tipSlice from "../../store/slices/tipPercentSlice";
import * as ListDetailFoodSlice from "../../store/slices/ListDetailFoodSlice";
//actions
import { logoutUser, resetAuth } from "../../redux/actions";

import { AppDispatch } from "../../redux/store";

// components
import AuthLayout from "./AuthLayout";
import * as Request from "../../api/Request";

const LogoutIcon = () => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130.2 130.2"
    >
      <circle
        className="path circle"
        fill="none"
        stroke="#4bd396"
        strokeWidth="6"
        strokeMiterlimit="10"
        cx="65.1"
        cy="65.1"
        r="62.1"
      />
      <polyline
        className="path check"
        fill="none"
        stroke="#4bd396"
        strokeWidth="6"
        strokeLinecap="round"
        strokeMiterlimit="10"
        points="100.2,40.2 51.5,88.8 29.8,67.5 "
      />
    </svg>
  );
};

/* bottom link */
const BottomLink = () => {
  const { t } = useTranslation();
  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-white-50">
          {t("Back to")}{" "}
          <Link to={"/auth/login"} className="text-white ms-1">
            <b>{t("Sign In")}</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Logout = () => {
  const userIdStore = useSelector(userSlice.selectorUserId);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatchUser = useDispatch();
  const ResetReduxStoreUser = async () => {
    await Request.fetchAPI("owner/gInfoOwner.php?id=" + userIdStore, "GET", {})
      .then(async (result: any) => {
        if (result?.status == 1) {
          await dispatchUser(tableSlice.clearTable());
          await dispatchUser(staffTipSlice.clearStaffTip());
          await dispatchUser(listFoodGroupSlice.clearFoodCategory());
          await dispatchUser(menuFoodSlice.clearMenuFood());
          await dispatchUser(floorSlice.clearFloor());
          await dispatchUser(tipSlice.clearTipPercent());
          await dispatchUser(ListDetailFoodSlice.clearInfoFood());
          await dispatchUser(userSlice.clearUser());
          if (result?.data?.role == 0 || result?.data?.role == 2) {
            await dispatchUser(userSlice.setUserId(0));
            await dispatchUser(userSlice.setUserName(""));
            setTimeout(() => {
              navigate("/web-admin/login");
            }, 3000);
            localStorage.setItem("is_ownerMenu", "0");
          } else if (result?.data?.role == 1) {
            await dispatchUser(userSlice.setUserId(0));
            await dispatchUser(userSlice.setUserName(""));

            setTimeout(() => {
              navigate("/auth/login");
            }, 3000);
            localStorage.setItem("is_ownerMenu", "0");
          }
          // console.log("Thông tin Owner", result?.data);
        } else {
          console.log(
            result?.message
              ? result?.message
              : "Something went wrong. Please try again later!"
          );
        }
      })
      .catch((err: any) => {
        console.log(
          "An error has occurred. Please check your internet connection"
        );
      });
    // await dispatchUser(userSlice.setUserId(0));
    // await dispatchUser(userSlice.setUserName(""));
    // setTimeout(() => {
    //     navigate("/auth/login");
    // }, 3000);
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, [dispatch]);

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  useEffect(() => {
    ResetReduxStoreUser();
  }, [location.pathname]);

  return (
    <>
      <AuthLayout bottomLinks={<BottomLink />}>
        <div className="text-center">
          <div className="mt-4">
            <div className="logout-checkmark">
              <LogoutIcon />
            </div>
          </div>

          <h3>{t("See you again !")}</h3>

          <p className="text-muted">
            {" "}
            {t("You are now successfully sign out.")}{" "}
          </p>
        </div>
      </AuthLayout>
    </>
  );
};

export default Logout;
