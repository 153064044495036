import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUserSlice {
  userId: number | null;
  name?: string | null;
  nameStore?: string | null;
  permission?: string | null;
  role?: number | null;
  tax?: number | 0;
  address?: string | null;
  phoneNumber?: string | number;
  permissionID?: number | string;
  posName: string | null;
  posStatus: number | null;
  posId: number | null;
  showImgFood: boolean;
  hsn_default: number;
}

const initialUserState: IUserSlice = {
  userId: null,
  name: null,
  nameStore: null,
  permission: null,
  role: null,
  tax: 0,
  address: "",
  phoneNumber: "",
  permissionID: 0,
  posName: "",
  posStatus: null,
  posId: null,
  showImgFood: false,
  hsn_default: 0
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action: PayloadAction<IUserSlice>) => action.payload,
    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },
    setTax: (state, action: PayloadAction<number>) => {
      state.tax = action.payload;
    },
    setPermission: (state, action: PayloadAction<any>) => {
      state.permission = action.payload;
    },
    setPermissionID: (state, action: PayloadAction<any>) => {
      state.permissionID = action.payload;
    },
    setRole: (state, action: PayloadAction<any>) => {
      state.role = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setNameStore: (state, action: PayloadAction<string>) => {
      state.nameStore = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setPosName: (state, action: PayloadAction<string>) => {
      state.posName = action.payload;
    },
    setPosStatus: (state, action: PayloadAction<number>) => {
      state.posStatus = action.payload;
    },
    setPosId: (state, action: PayloadAction<number>) => {
      state.posId = action.payload;
    },
    setShowImgFood: (state, action: PayloadAction<boolean>) => {
      state.showImgFood = action.payload;
    },
    setHsnDefault: (state, action: PayloadAction<number>) => {
      state.hsn_default = action.payload;
    },
    clearUser: (state) => initialUserState,
  },
});

export const selectorUserId = (state: { user: IUserSlice }) =>
  state.user.userId;
export const selectorNameStore = (state: { user: IUserSlice }) =>
  state.user.nameStore;
export const selectorName = (state: { user: IUserSlice }) => state.user.name;
export const selectorTax = (state: { user: IUserSlice }) => state.user.tax;
export const selectorAddress = (state: { user: IUserSlice }) =>
  state.user.address;
export const selectorPhoneNumber = (state: { user: IUserSlice }) =>
  state.user.phoneNumber;
export const selectorPermission = (state: { user: IUserSlice }) =>
  state.user.permission;
export const selectorPermissionId = (state: { user: IUserSlice }) =>
  state.user.permissionID;
export const selectorRole = (state: { user: IUserSlice }) => state.user.role;
export const selectorPosName = (state: { user: IUserSlice }) =>
  state.user.posName;
export const selectorPosStatus = (state: { user: IUserSlice }) =>
  state.user.posStatus;
export const selectorPosId = (state: { user: IUserSlice }) => state.user.posId;
export const selectorHsnDefault = (state: { user: IUserSlice }) => state.user.hsn_default;
export const selectorShowImageFood = (state: { user: IUserSlice }) =>
  state.user.showImgFood;

// export const selectorUser = (state: { user: IUserSlice }) => state.user;
export const {
  setUser,
  setUserId,
  setNameStore,
  setUserName,
  clearUser,
  setPermission,
  setPermissionID,
  setRole,
  setTax,
  setAddress,
  setPhoneNumber,
  setPosName,
  setPosStatus,
  setPosId,
  setHsnDefault,
  setShowImgFood,
} = userSlice.actions;
export default userSlice.reducer;
