import * as Request from "../Request";

export interface ChartReview {
  label: string,
  value: number,
  percent: number,
  color: string
}
// export const ListReview = async (
//   UserIdStore: number,
//   startDate: string,
//   endDate: string,
//   type: number
// ) => {
//   return await Request.fetchAPI(
//     "owner/gListReview.php?owner_id=" +
//       UserIdStore +
//       "&&date_start=" +
//       startDate +
//       "&&date_end=" +
//       endDate +
//       "&&type=" +
//       type,
//     "GET",
//     {}
//   )
//     .then((res: IListReview) => {
//       return res;
//     })
//     .catch((err: any) => {
//       return {
//         message: "An error has occurred. Please check your internet connection",
//         status: 0,
//         data: [],
//       };
//     });
// };
export const ListReview = async (
  owner_id: number,
  // date_start: string,
  // date_end: string,
  dateSearch: string,
  type: number,
  phoneFilter: string = ''
) => {
  return await Request.fetchAPI(`owner/gListReview_v3.php`, 'POST', { owner_id, dateSearch, type, phoneFilter })
    .then((res: IListReview<IListReviewData[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gInfoChartReview = async (
  owner_id: number,
  dateSearch: string
) => {
  return await Request.fetchAPI(`owner/gChartReview.php`, 'POST', { owner_id, dateSearch })
    .then((res: IListReview<ChartReview[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
        totalReview: '0'
      };
    });
};



export const SendSMS = async (
  UserIdStore: number,
  id_customer: number,
  MessageSend: string
) => {
  return await Request.fetchAPI("owner/send_SMS.php", "POST", {
    id_owner: UserIdStore,
    id_customer: id_customer,
    messages: MessageSend,
  })
    .then((res: ISendMessage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const SendAllSMS = async (
  ownerId: number,
  date_start: any,
  date_end: any,
  type: number,
  messages: string
) => {
  return await Request.fetchAPI("owner/send_all_SMS_review.php", "POST", {
    owner_id: ownerId,
    date_start: date_start,
    date_end: date_end,
    messages: messages,
    type: type,
  })
    .then((res: ISendMessage) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        success: [],
        fail: [],
      };
    });
};

export interface ISendMessage {
  message: string;
  status: number;
  success: string[];
  fail: string[];
}

export interface IListReviewData {
  id: string;
  customer_id: string;
  owner_id: string;
  review_type_id: string;
  name: string;
  start: string;
  phone: string;
  username: string;
  timestamp: string;
  message: string;
  points: string;
  icon: string;
  corlor: string
}
export interface IListReview<T> {
  message: string;
  status: number;
  data: T;
  totalReview: string;
}
