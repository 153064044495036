import { Modal } from "react-bootstrap";
import "../Styles.css";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { printOrange } from "../../../../../components/ImgExport";
import { ListInventoryWarehouse } from "../../../../../api/apiOwner/InventoryApi";
import * as SettingPrinterApi from "../../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../../api/apiOwner/printerApi";
import ModalToast from "../../ModalToast/ModalToastErr";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../../store/slices/userSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getLanguageUI } from "../../../../../Services/languageUI";

interface ModalPreviewInventoryProps {
  show: boolean;
  UserIdStore?: number | null;
  listInventoryFilter: ListInventoryWarehouse[];
  handleClose: () => void;
}

const ModalPreviewInventory = ({
  show,
  listInventoryFilter = [],
  UserIdStore,
  handleClose,
}: ModalPreviewInventoryProps) => {
  const languageUI = getLanguageUI().inventoryPage;
  const languagePrinterUI = getLanguageUI().printPage;
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const [listPrinterName, setListPrinterName] = useState<
    SettingPrinterApi.ListPrinterByCatalog[]
  >([]);

  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await SettingPrinterApi.gListNamePrinterElse(
        UserIdStore,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  useEffect(() => {
    gListPrinter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PrintCard = async () => {
    try {
      const rawhtml = `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6, h4 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          "<div id="bill" style="width: 100%; padding-right: 45px; padding-bottom: 80px>
            <h3 style="width: 100%; margin:  auto; text-align: center">${
              languageUI.inventory
            }</h3>
           
            
            
            <div style="display: table; width: 100%">
           <div style = "display: table-row; padding-top: 10px; width: 100%" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <h6  style=" margin: 0; text-align: left; font-weight: 600">${
                  languageUI.materialName
                }</h6>
                </div>
                 <div style="display: table-cell; width: fit-content; text-align: right;  padding-top: 10px">
                <h6  style=" margin: 0; text-align: right; font-weight: 600">${
                  languageUI.InventoryStock
                }</h6>
                </div>
              </div >
           ${listInventoryFilter
             .map(
               (item, i) =>
                 `
           <div style = "display: table-row; padding-top: 10px;  padding-right: 10px; width: 100%" >

                <div style="display: table-cell; width: auto; text-align: left; padding-right: 10px;  padding-top: 10px">
                <p  style=" margin: 0; text-align: start; font-size: 24px">${item?.ingredient_name}</p>
                </div>
                <div style="display: table-cell; text-align: right; flex-shrink: 0; width: fit-content;padding-top:4px;">
                <p class="margin-top: 10px !important ; font-size: 24px" style="font-size: 24px ; ">${item.Inventory_stock}</p>
                </div>
              </div >
              `
             )
             .join(" ")}
              </div >        
           
        
          </div >
          </body >
          </html >
  `;
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const nameStore = useSelector(userSlice.selectorNameStore);
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <Modal show={show} onHide={handleClose} className="right-inventory-modal">
        <Modal.Dialog className="bg-gray-light">
          <Modal.Body>
            <TableContainer>
              <div className="w-100">
                <p className="text-inventory ms-2 mt-3 mb-1">
                  {languageUI.inventory}
                </p>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className="text-12B064 text-4 fw-600 font-urbansist w-50"
                      >
                        {languageUI.materialName}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="text-12B064 text-4 fw-600 font-urbansist w-50"
                      >
                        {languageUI.InventoryStock}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="body-table-inventory-preview">
                    {listInventoryFilter.map((item, i) => (
                      <TableRow className="d-flex align-items-center" key={i}>
                        <TableCell className="text-32324D text-17px font-urbansist w-50 border-none">
                          {item?.ingredient_name}
                        </TableCell>
                        <TableCell className="text-32324D text-17px font-urbansist w-50 border-none">
                          {item?.Inventory_stock}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className="p-3 box-btn-print-inventory">
                  <div className="btn-print-inventory" onClick={PrintCard}>
                    <img src={printOrange} alt="" className="me-2" />
                    {languageUI.print}
                  </div>
                </div>
              </div>
            </TableContainer>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalPreviewInventory;
