import { Modal } from "react-bootstrap";
import "./Styles.css";
import {
  billSumary,
  billTable,
  clockTable,
  databaseTable,
  printOrange,
} from "../../../../components/ImgExport";
import ContentTableSumaryClock from "../ContentTableSumaryClock/ContentTableSumaryClock";
import * as clockinApi from "../../../../api/apiOwner/clockinApi";
import { FormatDolla } from "../../../../utils/format";
import { getLanguageUI } from "../../../../Services/languageUI";
import ContentTableSumaryClockPrint from "../ContentTableSumaryClock/ContentTableSumaryClockPrint";
import * as SettingPrinterApi from "../../../../api/apiOwner/SettingPrinterApi";
import { PrintOrderPage } from "../../../../api/apiOwner/printerApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as userSlice from "../../../../store/slices/userSlice";
import ModalToast from "../ModalToast/ModalToastErr";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React from "react";

interface ModalSumaryClockProps {
  show: boolean;
  listStaffTicket: clockinApi.StaffTicket[];
  infoStaff?: clockinApi.InfoStaff;
  handleClose: () => void;
}

const ModalSumaryClock = ({
  show,
  listStaffTicket = [],
  infoStaff,
  handleClose,
}: ModalSumaryClockProps) => {
  const nameStore = useSelector(userSlice.selectorNameStore);
  const pos_id = useSelector(userSlice.selectorPosId) ?? 0;
  const addressStore = useSelector(userSlice.selectorAddress);
  const phoneOwnerStore = useSelector(userSlice.selectorPhoneNumber);
  const languageUI = getLanguageUI().clockInOut;
  const UserIdStore = useSelector(userSlice.selectorUserId) ?? 0;
  const [listPrinterName, setListPrinterName] = useState<
    SettingPrinterApi.ListPrinterByCatalog[]
  >([]);
  const [showToastError, setShowToastError] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>("");
  const gListPrinter = async () => {
    if (UserIdStore) {
      const req = await SettingPrinterApi.gListNamePrinterElse(
        UserIdStore,
        pos_id
      );
      if (Number(req?.status) === 1) {
        setListPrinterName(req?.data ?? []);
      }
    }
  };
  const PrintCard = async () => {
    try {
      const rawhtml = `
          <!DOCTYPE html>
          <html lang="en">

          <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Hóa đơn</title>
          <style type="text/css">
            .name-food {
              font-weight: 600;
            }

            .quanlity-number {
              font-weight: 600;
            }

            p, h6 {
              margin-bottom: 0;
              margin-top: 0;
              line-height: normal;
            }

            .fw-normal{
              font-weight: 400;
            }
          </style>

          </head>

          <body>

          <div id="bill" style="width: 100%; padding-right: 40px;">
            <h3 style="width: 100%; margin:  auto; text-align: center">${
              languageUI.summary
            }</h3>
            <div style="width: 100%;">
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.name
              }: ${infoStaff?.staff_name}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.date
              }: ${infoStaff?.dateCheck}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.clockIn
              }: ${infoStaff?.clock_in ? infoStaff?.clock_in : " - - -"}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.clockOut
              }: ${infoStaff?.clock_out ? infoStaff?.clock_out : " - - -"}</h6>
              <h6 class="" style="font-weight: 400; margin: 0; text-align: start;">${
                languageUI.tip
              }: $${
        infoStaff?.totalTip ? FormatDolla(Number(infoStaff?.totalTip)) : "0"
      }</h5>
            </div>

              
          <div style="display: table; width: 100%">
            <div style = "display: table-row; padding-top: 10px; width: 100%" >
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p  style=" margin: 0; text-align: start; ">${
                  languageUI.bill
                }</p>
              </div>
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important">Tip</p>
              </div>
               <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important">${
                  languageUI.time
                }</p>
              </div>
            </div>
             ${listStaffTicket
               .map(
                 (item, i) =>
                   // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                   `
           <div style = "display: table-row; padding-top: 10px; width: 100% ;padding-right: 10px;" >
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p  style=" margin: 0; text-align: start; ">#${item.id}</p>
              </div>
              <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important">$${item.tip_amount}</p>
              </div>
               <div style="display: table-cell; width: auto; text-align: center; padding-right: 10px;  padding-top: 10px; ">
                <p class="quanlity-number; margin-top: 10px !important">${item.timeorder}</p>
              </div>
            </div>
         `
               )
               .join(" ")}
          </div>
            <h3 style="width: 100%; margin: 0 auto; vertical-align: middle; text-align: center;">---OoO---</h3>
          </div>
          </body>

          </html>
          `;
      // console.log(rawhtml);
      // console.log(listCartFood);

      if (listPrinterName.length > 0) {
        listPrinterName.map(async (item) => {
          const req = await PrintOrderPage(
            UserIdStore ?? 0,
            item.printer_name,
            rawhtml.toString()
          );
          if (!req?.status) {
            // setMessageError(req?.message ?? ("Error printing on " + item.printer_name))
            // setShowToastError(true)
            // toast.warning(req?.message ?? ("Error printing on " + item.printer_name))
          }
        });
      } else {
        setMessageError("Please configure the printer in settings!");
        setShowToastError(true);
      }
      // console.log(text);
      // console.log(rawhtml);

      // await printJS({

      //   printable: rawhtml,
      //   targetStyles: ["*"],
      //   targetStyle: ["*"],
      //   frameId: "cardprint",
      //   type: "raw-html",
      //   showModal: false,
      //   css: ["./Styles.css"],
      //   style: ".abc {font-size: 30px; color: red;}",
      //   header: rawhtml,
      //   onError: async (error: any) => {
      //     console.error("Error Printing: ", error);
      //   },
      //   // silent: false,3
      //   onPrintDialogClose: async () => {
      //     console.info("Print Window Closed");

      //   }
      // });
      // setTimeout(() => {
      //   const arr = listCartFood ?? [];
      //   printCardInfo(arr, arr.length - 1, arr[arr.length - 1]?.food_id, arr[arr.length - 2]?.food_id ?? '', arr[arr.length - 1], Number(table_id) > 0 ? NameTable : '', orderid ?? '', '')
      // }, 1000);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    gListPrinter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalToast
        show={showToastError}
        handleClose={() => setShowToastError(false)}
        content={messageError}
      />
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="modal-delete-customer modal-clock-in-out Modal-sumary"
      >
        <Modal.Dialog className="w-100">
          <Modal.Body className="w-100">
            <div className="px-3 w-100">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <img src={billSumary} alt="" />
                  <p className="text-delete-modal-customer mb-1">
                    {languageUI.summary}
                  </p>
                </div>
                <div
                  className="d-flex align-items-center gap-2 px-3 py-1 border-FF7B2C bg-FFF2EA rounded-8px"
                  onClick={PrintCard}
                >
                  <img src={printOrange} alt="" />
                  <p className="text-print-sumary mb-1">{languageUI.print}</p>
                </div>
              </div>

              <div className="mb-2 mt-2 d-flex">
                <div className="w-50 ">
                  <div className="w-100 d-flex mb-1  align-items-end">
                    <p className="w-20 text-title-sumary mb-0">
                      {languageUI.date}
                    </p>
                    <p className="w-70 text-value-sumary mb-0">
                      {infoStaff?.dateCheck}
                    </p>
                  </div>
                  <div className="w-100 d-flex mb-1  align-items-end">
                    <p className="w-20 text-title-sumary mb-0">
                      {languageUI.clockIn}
                    </p>
                    <p className="w-70 text-value-sumary mb-0">
                      {infoStaff?.clock_in}
                    </p>
                  </div>
                  <div className="w-100 d-flex mb-1  align-items-end">
                    <p className="w-20 text-title-sumary mb-0">
                      {languageUI.clockOut}
                    </p>
                    <p className="w-70 text-value-sumary mb-0">
                      {infoStaff?.clock_out ? infoStaff?.clock_out : "-"}
                    </p>
                  </div>
                </div>
                <div className="w-50 ">
                  <div className="w-100 d-flex mb-1  align-items-end">
                    <p className="w-20 text-title-sumary mb-0">
                      {languageUI.name}
                    </p>
                    <p className="w-70 text-value-sumary mb-0">
                      {infoStaff?.staff_name}
                    </p>
                  </div>
                  {/* <div className="w-100 d-flex mb-1  align-items-end">
                                        <p className="w-20 text-title-sumary mb-0">Salary</p>
                                        <p className="w-70 text-value-sumary mb-0">${infoStaff?.payroll_value ? FormatDolla(Number(infoStaff?.payroll_value)) : 0}</p>
                                    </div> */}
                  <div className="w-100 d-flex mb-1  align-items-end">
                    <p className="w-20 text-title-sumary mb-0">Tip</p>
                    <p className="w-70 text-value-sumary mb-0">
                      $
                      {infoStaff?.totalTip
                        ? FormatDolla(Number(infoStaff?.totalTip))
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3 ">
              <div className="table-container overflow-hidden rounded-18px border">
                <table className="w-100 px-2 table-customer  ">
                  <tr className="w-100 bg-F6F6F9 header-table-customer h-54px d-flex align-items-center border-bottom">
                    <th className="text-title-table w-12 ps-2  d-flex align-items-center">
                      {languageUI.table}
                    </th>
                    <th className="text-title-table w-22 d-flex align-items-center">
                      {languageUI.Order}
                    </th>
                    <th className="text-title-table w-22 d-flex align-items-center gap-2">
                      <img src={billTable} alt="" />
                      {languageUI.bill}
                    </th>
                    <th className="text-title-table w-22 d-flex align-items-center gap-2">
                      <img src={databaseTable} alt="" />
                      Tip
                    </th>
                    <th className="text-title-table w-22 d-flex align-items-center gap-2">
                      <img src={clockTable} alt="" />
                      {languageUI.time}
                    </th>
                  </tr>

                  <tbody className="clock-in-clock-out">
                    {listStaffTicket.map((item, i) => (
                      <ContentTableSumaryClock
                        key={"stafftitcket" + i}
                        order_id={item?.id}
                        price={Number(item?.price)}
                        table_name={item?.table_name}
                        timeOrder={item?.timeorder}
                        tipPrice={Number(item?.tip_amount)}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-none ">
              <div id="print-table-sumary">
                <div className="w-100">
                  <div className="w-100">
                    <div className="w-100">
                      <p className="w-20 text-title-sumary text-black mb-0">
                        SUMARY:
                      </p>
                      <div className="w-100 d-flex text-black gap-1 flex-wrap mb-1">
                        <p className="text-title-sumary mb-0">Name</p>
                        <p className="text-value-sumary mb-0">
                          {infoStaff?.staff_name}
                        </p>
                      </div>
                      <div className="w-100 d-flex text-black gap-1 flex-wrap mb-1">
                        <p className="text-title-sumary mb-0">Date</p>
                        <p className="text-value-sumary mb-0">
                          {infoStaff?.dateCheck}
                        </p>
                      </div>
                      <div className="w-100 d-flex text-black gap-1 flex-wrap mb-1">
                        <p className="text-title-sumary mb-0">Clock in</p>
                        <p className="text-value-sumary mb-0">
                          {infoStaff?.clock_in}
                        </p>
                      </div>
                      <div className="w-100 d-flex text-black gap-1 flex-wrap mb-1">
                        <p className="text-title-sumary mb-0">Clock out</p>
                        <p className="text-value-sumary mb-0">
                          {infoStaff?.clock_out ? infoStaff?.clock_out : "-"}
                        </p>
                      </div>
                      <div className="w-100 d-flex text-black gap-1 flex-wrap mb-1">
                        <p className="text-title-sumary mb-0">Tip</p>
                        <p className="text-value-sumary mb-0">
                          $
                          {infoStaff?.totalTip
                            ? FormatDolla(Number(infoStaff?.totalTip))
                            : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="table-container">
                    <table className="w-100 px-2 table-customer  ">
                      <tr className="d-flex text-black">
                        <th className="text-title-table w-33 d-flex text-black align-items-center gap-2">
                          Bill
                        </th>
                        <th className="text-title-table w-33 d-flex text-black align-items-center gap-2">
                          Tip
                        </th>
                        <th className="text-title-table w-33 d-flex text-black align-items-center gap-2">
                          Time
                        </th>
                      </tr>

                      <tbody className="">
                        {listStaffTicket.map((item, i) => (
                          <ContentTableSumaryClockPrint
                            key={"stafftitcket" + i}
                            order_id={item?.id}
                            price={Number(item?.price)}
                            table_name={item?.table_name}
                            timeOrder={item?.timeorder}
                            tipPrice={Number(item?.tip_amount)}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default ModalSumaryClock;
