import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
} from "../../../../../components/Image";
import Topbar from "../../../../../layouts/Topbar";
import "../Styles.css";
import * as userSlice from "../../../../../store/slices/userSlice";
import { useSelector } from "react-redux";
import DrawerOrderDetail from "./Drawer/DrawerOderDetail";
import * as reportApi from "../../../../../api/apiOwner/reportApi";
import ModalLoading from "../../../../../components/ModalLoading";
import { FormatDolla } from "../../../../../utils/format";
import * as custommerRecieptApi from "../../../../../api/apiOwner/custommerRecieptApi";
import { getLanguageUI } from "../../../../../Services/languageUI";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const IncomeDateDetail: React.FC = () => {
  const languageUI = getLanguageUI().reportPage;
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = location.state;
  const dayValue = params?.dayValue;
  const dayName = params?.dayName;
  const selectMonth = params?.selectMonth;
  const countYear = params?.countYear;

  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [listBill, setListBill] = useState<reportApi.ReportBill[]>([]);
  const [listSaleBill, setListSalBill] = useState<reportApi.SaleBill>()
  const [serverTotal, setServerTotal] = useState<reportApi.Servertotal>()
  const [detailOrder, setDetailOrder] =
    useState<custommerRecieptApi.DetailBill>();
  const gAllBillByDate = async () => {
    if (UserIdStore) {
      setShowLoading(true);
      const req = await reportApi.gReportBillbyDay(UserIdStore, dayValue);
      if (Number(req?.status) === 1) {
        const SaleBill = await reportApi.gReportSaleBillByDate(UserIdStore, dayValue)
        if (SaleBill?.status === 1) {
          setListSalBill(SaleBill?.data)
        }
        setListBill(req?.data ?? []);
        setServerTotal(req?.server_total)
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    }
  };

  const gDetailOrder = async (idOrder: string) => {
    if (UserIdStore) {
      setShowLoading?.(true);
      const gDetail = await custommerRecieptApi.gDetailOrderCustomer(
        UserIdStore,
        idOrder
      );
      if (Number(gDetail?.status) === 1) {
        setDetailOrder(gDetail?.data ?? undefined);
        setShowLoading?.(false);
        setShowDrawer(true);
      } else {
        setShowLoading?.(false);
      }
    }
  };
  const handleGenerate = () => {
    let totalPages = 0;
    const doc = new jsPDF();

    const header = (data: any) => {
      totalPages += 1;
      doc.setFontSize(12)
      const textHeader = "RE-PRINT SALES POSTING REPORT";
      const storeName = "Hoang Huu Nghia";
      const pageWidth = doc.internal.pageSize.getWidth();
      // Lấy chiều rộng của đoạn text
      const textHeaderWidth = doc.getTextWidth(textHeader);
      const storeNameWidth = doc.getTextWidth(storeName);

      const textHeaderCenter = (pageWidth - textHeaderWidth) / 2;
      const storeNameCenter = (pageWidth - storeNameWidth) / 2;
      doc.text(textHeader, textHeaderCenter, 10);
      const textPage = `Page: ${doc.getNumberOfPages()}`;
      doc.text(textPage, 15, 10); // Đặt số trang ở vị trí dưới cùng
      doc.text(storeName, storeNameCenter, 14); // Đặt số trang ở vị trí dưới cùng
      doc.setFontSize(12);

      const dateTimeStart = "START DATE: 10/10/24 START TIME: 00:00";
      const dateTimeEnd = "END DATE: 10/10/24 END TIME: 00:00";
      const dateStartWidth = doc.getTextWidth(dateTimeStart);
      const TimeEndWidth = doc.getTextWidth(dateTimeEnd);

      const dateCenter = (pageWidth - dateStartWidth) / 2;
      const timeCenter = (pageWidth - TimeEndWidth) / 2;

      doc.text(dateTimeStart, dateCenter, 19); // Đặt số trang ở vị trí dưới cùng
      doc.text(dateTimeEnd, timeCenter, 23); // Đặt số trang ở vị trí dưới cùng

      doc.text("10-10-2024", 170, 10);
    };


    doc.setFont("Times", "bold");


    let finalY = 30; // Bắt đầu từ 20 đơn vị (khoảng cách từ đầu trang)

    // Tên bảng trước mỗi bảng
    doc.setFontSize(10);
    doc.text("AAAAAA", 14, finalY + 8); // Đặt tên bảng ngay sau bảng trước đó

    // Vẽ bảng và cập nhật vị trí Y dựa trên doc.lastAutoTable.finalY

    autoTable(doc, {
      head: [["TRANS #", "TABLE", "GUEST CNT", "SUBTOTAL", "TAX", "TIP", "TIME IN", "TIME OUT", "TBL TIME"]],
      body: listBill.map((val, i) => [
        val.id,
        val.table_name,
        val.guestNumber,
        FormatDolla(val.subtotal),
        FormatDolla(val.vat_amount),
        FormatDolla(val.tip_amount),
        val.time_in,
        val.time_out,
        val.time_difference,
      ]),
      margin: { top: 26 },
      startY: finalY, // Đảm bảo bảng tiếp theo không bị chồng lên tên bảng
      styles: {
        halign: 'center', // Căn giữa toàn bộ nội dung trong bảng
      },
      headStyles: { fillColor: "#00A15A", textColor: "white" },
      didDrawPage: header,
    });

    let lastYPosition = (doc as any).lastAutoTable.finalY || 30;
    const lineHeight = 10
    const marginLeft = 15

    // Hàm kiểm tra xem có cần thêm trang không
    const checkPageOverflow = (yPosition: number) => {
      const pageHeight = doc.internal.pageSize.height;
      if (yPosition + lineHeight > pageHeight - 10) {
        doc.addPage();
        return 10; // Bắt đầu vị trí Y ở trang mới
      }
      return yPosition;
    };

    // Bắt đầu thêm các dòng chữ sau bảng
    lastYPosition += lineHeight;
    lastYPosition = checkPageOverflow(lastYPosition);

    doc.setLineWidth(0.3); // Độ dày của đường line
    doc.line(
      marginLeft,
      lastYPosition + 5,
      130,
      lastYPosition + 5
    );
    doc.text(
      "SERVER TOTAL:",
      marginLeft,
      lastYPosition + 10
    );
    doc.text(String(serverTotal?.total_guest ?? 0), marginLeft + 55, lastYPosition + 10);
    doc.text(FormatDolla(serverTotal?.total_sub ?? 0), marginLeft + 76, lastYPosition + 10);
    doc.text(FormatDolla(serverTotal?.total_tax ?? 0), marginLeft + 97, lastYPosition + 10);
    doc.text(FormatDolla(serverTotal?.total_tip ?? 0), marginLeft + 112, lastYPosition + 10);


    doc.text(
      "SALES POSTED:",
      marginLeft,
      lastYPosition + 20,
      {
        align: "left",
      }
    );
    doc.text(
      FormatDolla(listSaleBill?.total_sale_posted ?? 0),
      marginLeft + 80,
      lastYPosition + 20,
      {
        align: "right",
      }
    );

    doc.text(
      "BANK POSTED (CHECK/CASH):",
      marginLeft,
      lastYPosition + 25,
      {
        align: "left",
      }
    );
    doc.text(
      FormatDolla(listSaleBill?.total_cash ?? 0),
      marginLeft + 80,
      lastYPosition + 25,
      {
        align: "right",
      }
    );

    doc.text(
      "TAX POSTED (C/CARD):",
      marginLeft,
      lastYPosition + 30,
      {
        align: "left",
      }
    );
    doc.text(
      FormatDolla(listSaleBill?.total_card ?? 0),
      marginLeft + 80,
      lastYPosition + 30,
      {
        align: "right",
      }
    );

    doc.text(
      "TAX POSTED:",
      marginLeft,
      lastYPosition + 35,
      {
        align: "left",
      }
    );
    doc.text(
      FormatDolla(listSaleBill?.total_tax ?? 0),
      marginLeft + 80,
      lastYPosition + 35,
      {
        align: "right",
      }
    );

    doc.text(
      "TOTAL # TABLE:",
      marginLeft + 100,
      lastYPosition + 20,
      {
        align: "left",
      }
    );
    doc.text(String(listSaleBill?.total_table), marginLeft + 170, lastYPosition + 20, {
      align: "right",
    });

    doc.text(
      "AVG TICKET:",
      marginLeft + 100,
      lastYPosition + 25,
      {
        align: "left",
      }
    );
    doc.text(FormatDolla(listSaleBill?.avg_ticket ?? 0), marginLeft + 170, lastYPosition + 25, {
      align: "right",
    });

    doc.text(
      "SALE/GUEST:",
      marginLeft + 100,
      lastYPosition + 30,
      {
        align: "left",
      }
    );
    doc.text(FormatDolla(listSaleBill?.total_guest_server ?? 0), marginLeft + 170, lastYPosition + 30, {
      align: "right",
    });

    doc.text(
      "TICKETS/SERVER:",
      marginLeft + 100,
      lastYPosition + 35,
      {
        align: "left",
      }
    );
    doc.text(FormatDolla(listSaleBill?.total_tickets_server ?? 0), marginLeft + 170, lastYPosition + 35, {
      align: "right",
    });

    doc.text(
      "GUESTS/SERVER:",
      marginLeft + 100,
      lastYPosition + 40,
      {
        align: "left",
      }
    );
    doc.text(FormatDolla(listSaleBill?.total_guest_server ?? 0), marginLeft + 170, lastYPosition + 40, {
      align: "right",
    });

    doc.text(
      "GUESTS/TABLE:",
      marginLeft + 100,
      lastYPosition + 45,
      {
        align: "left",
      }
    );
    doc.text(FormatDolla(listSaleBill?.total_guest_table ?? 0), marginLeft + 170, lastYPosition + 45, {
      align: "right",
    });

    doc.text(
      "TABLE TIME (MINS):",
      marginLeft + 100,
      lastYPosition + 50,
      {
        align: "left",
      }
    );
    doc.text(FormatDolla(listSaleBill?.avg_minutes_guest ?? 0), marginLeft + 170, lastYPosition + 50, {
      align: "right",
    });
    // doc.text(
    //   Total Rows: ${data.table.body.length},
    //   data.settings.margin.left,
    //   lastYPosition + 10
    // );
    // doc.text(
    //   "Some footer information here...",
    //   data.settings.margin.left,
    //   lastYPosition + 20
    // );

    // Cập nhật vị trí Y cho bảng tiếp theo
    finalY = (doc as any).lastAutoTable.finalY;
    doc.setFontSize(12);

    // doc.text(
    //   "SERVER TOTAL:       18     522.72      36.6     0.0",
    //   20,
    //   finalY + 20
    // );

    doc.save("report.pdf");
  };
  useEffect(() => {
    if (params?.dayValue && UserIdStore) {
      gAllBillByDate();
    } else {
      navigate("/web-owner/report/incomedetail");
    }
  }, []);
  return (
    <>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <DrawerOrderDetail
        openDrawer={showDrawer}
        dismisDrawer={() => setShowDrawer(!showDrawer)}
        detailOrder={detailOrder}
        UserIdStore={UserIdStore}
      />
      <div className="">{/* <Topbar pageName="Report" /> */}</div>
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex justify-between  ml-12 pt-3">
            <div
              className="w-100 flex items-center py-12px"
              onClick={() =>
                navigate("/web-owner/report/incomedetail", {
                  state: { countYear, selectMonth },
                })
              }
            >
              <div className="bg-white border-DCDCE4 radius-6px p-2 flex justify-center mr-18px">
                <KeyboardArrowLeftIcon />
              </div>
              <span className="text-8 fw-600 font-urbansist mr-6 text-212134">
                {languageUI.income} {dayName}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-38px">
        <div className="w-95 bg-white  mx-12 radius-4 ">
          <table className="table">
            <thead>
              <tr>
                <th
                  scope="col"
                  align="center" style={{ textAlign: 'center' }}
                  className="w-10 text-18px text-12B064 fw-600 font-urbansist text-center"
                >
                  TRANS #
                </th>
                <th scope="col" align="center" style={{ textAlign: 'center' }} className="w-10">
                  <span className="text-18px text-12B064 fw-600 text-center font-urbansist">
                    TABLE
                  </span>
                </th>

                <th
                  scope="col" align="center" style={{ textAlign: 'center' }}
                  className="w-10 text-18px text-12B064 fw-600 text-center font-urbansist"
                >
                  GUEST CNT
                </th>
                <th
                  scope="col" align="center" style={{ textAlign: 'center' }}
                  className="w-10 text-18px text-12B064 fw-600 text-center font-urbansist"
                >
                  SUBTOTAL
                </th>
                <th scope="col" align="center" style={{ textAlign: 'center' }} className="w-10">
                  <span className="text-18px text-12B064 fw-600 text-center font-urbansist">
                    TAX
                  </span>
                </th>
                <th scope="col" align="center" style={{ textAlign: 'center' }} className="w-10">
                  <span className="text-18px text-12B064 fw-600 text-center font-urbansist">
                    TIP
                  </span>
                </th>
                <th scope="col" align="center" style={{ textAlign: 'center' }} className="w-10">
                  <span className="text-18px text-12B064 fw-600 text-center font-urbansist">
                    TIME IN
                  </span>
                </th>
                <th scope="col" align="center" style={{ textAlign: 'center' }} className="w-10">
                  <span className="text-18px text-12B064 fw-600 text-center font-urbansist">
                    TIME OUT
                  </span>
                </th>
                <th
                  scope="col"
                  className="w-20 text-18px text-12B064 fw-600  font-urbansist"
                >
                  <div className="d-flex justify-content-between pr-2 align-items-center">
                    TBL TIME
                    <button className="px-4 rounded-3 font-medium text-white h-30px bg-primary border-none" onClick={handleGenerate}>{languageUI.download}</button>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="h-body-table-report">
              {listBill.map((item, i) => (
                <tr key={`item${i}`} className="flex">
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-269AFF w-10">
                    #{item?.id}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-4A4A6A w-10">
                    {item?.table_name}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-4A4A6A w-10">
                    {item?.guestNumber}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-4A4A6A w-10">
                    ${FormatDolla(Number(item?.subtotal))}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-4A4A6A w-10">
                    ${FormatDolla(Number(item?.vat_amount))}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-18px text-4A4A6A w-10">
                    ${FormatDolla(Number(item?.tip_amount))}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-4A4A6A w-10">
                    {item?.time_in}
                  </td>
                  <td style={{ textAlign: 'center' }} className="border-bottom-none text-17px text-center text-4A4A6A w-10">
                    {item?.time_out}
                  </td>
                  <td
                    onClick={() => gDetailOrder(item?.id)}
                    className="w-20 border-bottom-none"
                  >
                    <div className="flex items-center justify-between ">
                      <span className="text-17px text-4A4A6A">
                        {item?.time_difference
                          ? item?.time_difference
                          : "-"}
                      </span>
                      <KeyboardArrowRightIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="w-100 mt-2">
            <div className="d-flex w-100 p-table-cell">
              <div className="w-20 border-top-gray py-2 font-bold text-lg">SERVER TOTAL:</div>
              <div className="w-10 text-center border-top-gray py-2 font-bold text-lg">{serverTotal?.total_guest}</div>
              <div className="w-10 text-center border-top-gray py-2 font-bold text-lg" >{FormatDolla(serverTotal?.total_sub ?? 0)}</div>
              <div className="w-10 text-center border-top-gray py-2 font-bold text-lg">{FormatDolla(serverTotal?.total_tax ?? 0)}</div>
              <div className="w-10 text-center border-top-gray py-2 font-bold text-lg">{FormatDolla(serverTotal?.total_tip ?? 0)}</div>
            </div>
            <div className="w-100 d-flex p-table-cell">
              <div className="w-100 pr-5 border-right-gray">
                <div className="d-flex justify-content-between w-100">
                  <div className="font-bold text-lg">
                    SALES POSTED:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_sale_posted ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    BANK POSTED (CHECK/CASH):
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_cash ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    BANK POSTED (C/CARD):
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_card ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    TAX POSTED:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_tax ?? 0)}
                  </div>
                </div>
              </div>
              <div className="w-100 border-right-gray px-5">
                <div className="d-flex justify-content-between w-100">
                  <div className="font-bold text-lg">
                    TOTAL # TABLES:
                  </div>
                  <div className="font-bold text-lg">
                    {listSaleBill?.total_table}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    AVG TICKET:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.avg_ticket ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    SALES/GUEST:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.avg_price_per_guest ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    TICKET/SERVER:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_tickets_server ?? 0)}
                  </div>
                </div>
              </div>
              <div className="w-100 px-5">
                <div className="d-flex justify-content-between w-100">
                  <div className="font-bold text-lg">
                    GUESTS/SERVER:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_guest_server ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    GUESTS/TABLE:
                  </div>
                  <div className="font-bold text-lg">
                    {FormatDolla(listSaleBill?.total_guest_table ?? 0)}
                  </div>
                </div>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <div className="font-bold text-lg">
                    TABLE TIME(MINS):
                  </div>
                  <div className="font-bold text-lg">
                    {listSaleBill?.avg_minutes_guest ?? 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeDateDetail;
