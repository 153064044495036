// OrderStatus

import { Accordion } from "react-bootstrap";
import "./styles.css"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FormatDolla } from "../../../utils/format";
import { like3d, plusOrange, wallet } from "../../../components/ImgExport";
import * as orderConfilmApi from "../../../api/apiCheckin/orderConfilmApi"
import * as paymentApi from "../../../api/apiCheckin/paymentApi";
import React from "react";
import OrderListITem from "../../webcheckin/components/OrderListITem/OrderListITem";



const OrderStatusOnline: React.FC = () => {
  const navigate = useNavigate()
  const [listSide, setListSide] = useState<orderConfilmApi.SideList[]>([])
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const custommerId = localStorage.getItem("custommerId");
  const ownerID = Number(localStorage.getItem("ownerID"));
  const [tax, setTax] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [orderInfo, setOrrderInfo] = useState<orderConfilmApi.InfoOrder[]>([])
  // const [requestOrder, setRequestOrder] = useState<orderConfilmApi.RequestOrder[]>([])
  const [totalFood, setTotalFood] = useState<number>(0)
  const [dNone, setDNone] = useState(false);

  const gInfoOrderConfilm = async () => {
    if (custommerId && ownerID) {
      setShowLoading(true)
      const Info = await orderConfilmApi.gOrderConfilmPayOnline(custommerId, ownerID);
      if (Info.status == 1) {
        setOrrderInfo(Info?.data ?? []);
        setTotalFood(Number(Info?.totalFood))
        setTotalPrice(Number(Info?.totalPrice))
        setListSide(Info.SideList ?? [])

        // setRequestOrder(Info?.requestList ?? [])
        setShowLoading(false)
      } else {
        setShowLoading(false);
      }
    }
  }


  // const gListTip = async () => {
  //     const Tip = await paymentApi.getListTip(ownerID);
  //     if (Tip.status == 1) {
  //         // setTipList(Tip?.data ?? []);
  //         navigate('/webcheckin/Payment')
  //     }
  // }


  const gConfig = async () => {
    const config = await paymentApi.getConfig(ownerID);
    if (config.status == 1) {
      setTax(Number(config?.data?.tax) ?? 0);
    }
  }
  useEffect(() => {
    gConfig()
    gInfoOrderConfilm()
  }, [])
  return (
    <div className="bg-white w-100 min-h-100vh">
      <header className="header-imformation-order px-3 d-flex justify-content-between align-items-start">
        <div>
          <p className="mb-0 text-review-imformation">Review</p>
          <p className="mb-0 text-number-order">Your order ({totalFood})</p>
        </div>
        <div className="d-flex align-items-center gap-1" onClick={() => navigate("/online/menu/" + custommerId)}>
          <img src={plusOrange} alt="" />
          <p className="mb-0 text-order-more">Order more</p>
        </div>
      </header>
      <div className="padding-top-header-information">
        <div className="pt-3 px-3">
          <div className={`${dNone === true ? "d-none" : ""} transition-0.5 d-flex flex-column align-items-center`}>
            <p className="we-got-you-order">Your order will be ready in</p>
            <p className="we-start-to">We start to processing your order now!</p>
            <img src={like3d} alt="" />
          </div>
          <Accordion >
            <Accordion.Item eventKey="0" className="border-none focus-visible-none">
              <Accordion.Header className="text-order-list" onClick={() => { setDNone(!dNone) }} >Order list and prices</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column gap-2">
                  <OrderListITem orderInfo={orderInfo} status={true} textStatus="Well done" />
                </div>
                <div>
                  {listSide.length > 0 && (
                    <>
                      <p className="choose-a-side mb-0">Side Dish</p>
                      {listSide.map((item, index) => (
                        <div>
                          <p className="text-side-dish mb-0">{index + 1}. {item.name} (x{item.quantity})</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <div className="my-2 py-2 border-top d-flex flex-column gap-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">Items Total</div>
                    <div className="d-flex gap-2px">
                      <div className="text-price-food-3">{FormatDolla(Number(totalPrice))}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="name-title-price">Tax</div>
                    <div className="d-flex gap-2px">
                      <div className="text-dollar-3">$</div>
                      <div className="text-price-food-3">{tax > 0 ? FormatDolla(totalPrice * tax / 100) : 0}</div>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="footer-choose-menu py-3 w-100 bg-white px-3 border-top">
        <div className="d-flex justify-content-between ">
          <p className="text-total-information-card">Total</p>
          <div className="d-flex gap-2px">
            <div className="text-dollar">$</div>
            <div className="text-price-information-card">{FormatDolla(totalPrice + (totalPrice * tax / 100))}</div>
          </div>
        </div>
        <div className=" d-flex gap-2 align-items-center">
          {/* <div className="position-relative" onClick={()=>{navigate(-1)}}>
                        <img src={arrowLeft} alt="" />
                    </div> */}



          {/* <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={gListTip}>
                        <img src={wallet} alt="" />
                        Pay now
                    </button> */}

          <button className="custom-filter-btn border-none w-100 text-ffffff" onClick={() => navigate('/online/payment')}>
            <img src={wallet} alt="" />
            Pay now
          </button>


        </div>

      </div>
    </div>

  );
};

export default OrderStatusOnline;
