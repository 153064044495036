// import { EastIcon, arrowDouble } from "../../../../../../components/Image"
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Form } from "react-bootstrap";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";

interface ContentTablePOSSystemProps {
  no: number;
  namePOS: string;
  status: number;
  dateCreated: string;
  idPOS: number;

  handleEditPOS: () => void;
  handleDeletePOS: () => void;
}
const ContentTablePOSSystem = ({
  no,
  idPOS,
  dateCreated,
  namePOS,
  status,
  handleDeletePOS,
  handleEditPOS,
}: ContentTablePOSSystemProps) => {
  const navigate = useNavigate();

  return (
    <>
      <TableRow className="">
        <TableCell
          align="center"
          className="text-269AFF text-14px fw-500  font-urbansist w-10 border-none"
        >
          {no}
        </TableCell>
        <TableCell className="text-4a4a6a text-14px fw-500  font-urbansist  border-none">
          <span className={`${status === 1 ? "text-12B064 " : ""} `}>
            {namePOS}
          </span>

          {status === 1 && (
            <div className="text-13px">
              The main station, which receives online orders and forwards them
              to the printer, should always be{" "}
              <span className=" text-cus-kept">kept on</span> to avoid
              disrupting the order printing process.
            </div>
          )}
        </TableCell>
        <TableCell className="text-4a4a6a text-14px fw-500  font-urbansist  border-none">
          {dateCreated}
        </TableCell>
        <TableCell
          align="center"
          className="text-4a4a6a text-14px fw-500  font-urbansist  border-none"
        >
          <div className="d-flex gap-2 align-items-center justify-content-center">
            {/* <div
              className="btn-action-possystem"
              onClick={() => handleEditPOS()}
            >
              <ModeEditOutlineIcon style={{ color: "#12B064" }} />
            </div> */}
            <div
              className={`btn-action-possystem ${status === 1 ? "d-none" : ""}`}
              onClick={() => handleDeletePOS()}
            >
              <DeleteForeverIcon style={{ color: "#FF4545" }} />
            </div>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
export default ContentTablePOSSystem;
