import * as Request from "../Request";

export interface Result<T> {
  status: number;
  message?: string;
  data: T;
}
export interface ListAccountStripe {
  id: string;
  owner_id: string;
  secretKey: string;
  is_default: string;
  timestamp: string;
  account_name: string;
}
export interface StripeKey {
  id: string;
  secretKey: string;
  publishKey: string;
}
//lấy key của stripe

export const gstripeKeyOwner = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gstripeKeyOwner.php`, "POST", {
    owner_id,
  })
    .then((res: Result<StripeKey>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const gListAccountStripeByOwner = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListAccountStripe.php`, "POST", {
    owner_id,
  })
    .then((res: Result<ListAccountStripe[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const DeleteAccountStripe = async (account_id: string) => {
  return await Request.fetchAPI(`owner/DeleteAccountStripe.php`, "POST", {
    account_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const setDefaultAccountStripe = async (
  owner_id: number,
  account_id: string
) => {
  return await Request.fetchAPI(`owner/setAccountStripeDefault.php`, "POST", {
    owner_id,
    account_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};

export const addAccountStripe = async (
  owner_id: number,
  secretKey: string,
  publishKey: string,
  account_name: string
) => {
  return await Request.fetchAPI(`owner/addAccountStripe.php`, "POST", {
    owner_id,
    secretKey,
    publishKey,
    account_name,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
