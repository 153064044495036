import * as Request from "../Request";
export interface GiftVoucher {
  id: string;
  voucher_name: string;
  createat: string;
  enable: string;
  startdate: string;
  enddate: string;
  is_gifvoucher: string;
  is_end: string;
  discount_value: string;
  description: string;
  type: string;
  owner_id: string;
  quantity: string;
  quantity_used: string;
  is_never_expire: string;
  remain: number;
  send_to: string;
  staff_name: string;
}
export interface GiftVoucherPosSale {
  id: string;
  voucher_name: string;
  createat: string;
  is_gifvoucher: string;
  discount_value: string;
  send_from: string;
  send_to: string;
  staff_name: string;
  contact: string;
}
export interface DetailGiftVoucher {
  id: number;
  voucher_id: number;
  voucher_code: string;
  is_used: number;
  is_download: number;
  createat: string;
  voucher_name: string;
  expry_date: number;
  enable: number;
  startdate: any;
  enddate: any;
  is_gifvoucher: number;
  is_end: number;
  discount_value: number;
  type: number;
  owner_id: number;
  quantity: number;
  owner_phone: string;
  name: string;
  owner_address: string;
}

export interface DetailGiftBalance {
  store_name: string;
  phone: string;
  detail_address: string;
  send_from: string;
  send_to: string;
  voucher_name: string;
  remain: number;
  type: number;
  voucher_code: string;
}
export interface TransactionGift {
  id: number;
  gift_id: number;
  payment_amount: number;
  balance: number;
  transaction_date: string;
}

export interface Result<T> {
  status: number;
  message: string;
  data: T;
}
export const gListGiftVoucher = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListGiftVoucher.php`, "POST", {
    owner_id,
  })
    .then((res: Result<GiftVoucher[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gDetailGiftCardBalance = async (voucherCode: string) => {
  return await Request.fetchAPI(`owner/gDetailGiftCardBalance.php`, "POST", {
    voucherCode,
  })
    .then((res: Result<DetailGiftBalance>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: undefined,
      };
    });
};
export const getHistoryTransactionGift = async (voucherCode: string) => {
  return await Request.fetchAPI(`owner/gHistoryTransactionGift.php`, "POST", {
    voucherCode,
  })
    .then((res: Result<TransactionGift[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const getHistoryGiftSale = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListHistoryGiftVoucherSell.php`, "POST", {
    owner_id,
  })
    .then((res: Result<GiftVoucherPosSale[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
// export const addGiftVoucher = async (
//   owner_id: number,
//   voucher_name: string,
//   type: number,
//   discount_value: number,
//   startdate: string,
//   enddate: string,
//   quantity: number
// ) => {
//   return await Request.fetchAPI(`owner/create-gift-voucher.php`, "POST", {
//     owner_id,
//     voucher_name,
//     type,
//     discount_value,
//     startdate,
//     enddate,
//     quantity,
//   })
//     .then((res: Result<string>) => {
//       return res;
//     })
//     .catch((err: any) => {
//       return {
//         message: "An error has occurred. Please check your internet connection",
//         status: 0,
//         data: "",
//       };
//     });
// };
export const addGiftVoucher = async (
  owner_id: number,
  voucher_name: string,
  type: number,
  discount_value: number,
  startdate: string,
  enddate: string,
  quantity: number,
  is_never_expire: number
) => {
  return await Request.fetchAPI(`owner/create-gift-voucher.php`, "POST", {
    owner_id,
    voucher_name,
    type,
    discount_value,
    startdate,
    enddate,
    quantity,
    is_never_expire,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
export const gListCodeGiftVoucher = async (
  owner_id: number,
  voucher_id: string,
  limit = 10
) => {
  return await Request.fetchAPI(`owner/gListCodeGiftVoucher.php`, "POST", {
    owner_id,
    voucher_id,
    limit,
  })
    .then((res: Result<DetailGiftVoucher[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const upDownloadGift = async (gift_id: number) => {
  return await Request.fetchAPI(`owner/upDownloadGift.php`, "POST", { gift_id })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
