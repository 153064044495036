import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
// components
import { usFlag } from "../../components/Image";
import PageTitle from "../../components/PageTitle";

import * as userSlice from "../../store/slices/userSlice";
import { useSelector } from "react-redux";
import * as AccountInformation from "../../api/apiAdmin/AccountInfor";
import Swal from "sweetalert2";
import { getLanguageCheckIn } from "../../Services/languageCheckin";

export default function AccountInforDetail() {
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const languageUI = getLanguageCheckIn();
  const [ContactPerson, setContactPerson] = useState<any>("");
  const [Name, setName] = useState<any>("");
  const [CellPhone, setCellPhone] = useState<any>("");
  const [Address, setAddress] = useState<any>("");
  const [EmailAddress, setEmailAddress] = useState<any>("");
  const [City, setCity] = useState<any>("");

  const GetAccountInfor = async (UserIdStore: any) => {
    const AccountInfor = await AccountInformation.GetAccountInfor(UserIdStore);
    if (Number(AccountInfor.status) === 1) {
      setContactPerson(AccountInfor?.data?.contact_person);
      setName(AccountInfor?.data?.name);
      setCellPhone(AccountInfor?.data?.phone);
      setAddress(AccountInfor?.data?.address);
      setEmailAddress(AccountInfor?.data?.email);
      setCity(AccountInfor?.data?.store_city);
    }
  };

  const EditAccountInfor = async () => {

    const AccountInfor = await AccountInformation.updateAccountInfor(
      UserIdStore,
      ContactPerson,
      Name,
      Address,
      EmailAddress,
      City
    );
    if (Number(AccountInfor.status) === 1) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: languageUI.UpdateSuccess,
        showConfirmButton: false,
        timer: 3500,
      });
    } else if (
      AccountInfor.message === "Email already exists on the system !"
    ) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: languageUI.UpdateErrorEmailalreadyexistsonthesystem,
        showConfirmButton: false,
        timer: 3500,
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: languageUI.UpdateErrorPleasetryagain,
        showConfirmButton: false,
        timer: 3500,
      });
    }
  };

  useEffect(() => {
    GetAccountInfor(UserIdStore);
  }, [UserIdStore]);
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Admin", path: "#" },
          { label: "Edit account", path: "#", active: true },
        ]}
        title={"Account Information"}
      />

      <div
        className="p-3 rounded-3 mb-3 bg-white shadow "
        style={{ width: "60%", margin: " 0 auto" }}
      >
        <Row className="mx-n2">
          <Col xs={12} sm={12} md={12} lg={12} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.Contactperson}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={ContactPerson}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={(e: any) => {
                      setContactPerson(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.Name}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={Name}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={(e: any) => {
                      setName(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.Cellphone}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <InputGroup.Text className="border-end-0 bg-white">
                    <img src={usFlag} alt="USA" height={21} />
                  </InputGroup.Text>
                  <Form.Control
                    value={CellPhone}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    className="border-start-0 ps-0"
                    readOnly
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.Address}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={Address}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={(e: any) => {
                      setAddress(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.Emailaddress}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={EmailAddress}
                    type="email"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={(e: any) => {
                      setEmailAddress(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} className="px-2">
            <div className="row">
              <div className="col-2">
                <Form.Label className="">{languageUI.City}:</Form.Label>
              </div>
              <div className="col-10">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={City}
                    type="text"
                    aria-label="Small"
                    aria-describedby="inputGroup-sizing-sm"
                    onChange={(e: any) => {
                      setCity(e.target.value);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <div className="text-end">
          <Button
            variant="primary"
            className="bg-opacity-25"
            onClick={() => {
              EditAccountInfor();
            }}
          >
            {languageUI.Save}
          </Button>
        </div>
      </div>
    </>
  );
}
