import React, { useState } from "react";
import ModalShowBillOrderOwner from "../../ModalShowBillOrderOwner/ModalShowBillOrderOwner";
import * as customerApi from "../../../../../api/apiOwner/customerApi";
import { ListPrinterByCatalog } from "../../../../../api/apiOwner/SettingPrinterApi";
interface ContentTableReportProps {
  phone: string;
  totalItem: string;
  customer_id?: string;
  customer_name?: string;
  order_id: string;
  timeOrder: string;
  tableId: string;
  tableName: string;
  resetListOrder: () => void;
  UserIdStore: number | null;
  listPrinterName: ListPrinterByCatalog[];
  order_method_name: string;
}
const ContentTableOrder = ({
  totalItem,
  phone = "",
  order_id = "",
  timeOrder = "",
  customer_id = "",
  customer_name = "",
  tableId = "",
  tableName = "",
  resetListOrder,
  order_method_name = "",
  UserIdStore,
  listPrinterName,
}: ContentTableReportProps) => {
  const [modalBillOrderOwner, setmodalBillOrderOwner] =
    useState<boolean>(false);
  const dismisModalBillOrderOwner = () => {
    setmodalBillOrderOwner(false);
  };
  const handleDeleteBillSucces = async () => {
    if (UserIdStore && order_id) {
      const req = await customerApi.gDeleteBillOrder(
        Number(UserIdStore),
        Number(order_id)
      );
      if (req.status) {
        setmodalBillOrderOwner(false);
        dismisModalBillOrderOwner();
        resetListOrder();
      } else {
        console.log(req.message);
      }
    }
  };
  return (
    <>
      <ModalShowBillOrderOwner
        listPrinterName={listPrinterName}
        handleClose={dismisModalBillOrderOwner}
        show={modalBillOrderOwner}
        CustomerName={customer_name}
        orderId={order_id}
        phone={phone}
        timeOrder={timeOrder}
        tableId={tableId}
        tableName={tableName}
        handleDeleteBillSucces={handleDeleteBillSucces}
      />

      <tr
        className="d-flex align-items-center mb-2 "
        onClick={() => setmodalBillOrderOwner(true)}
      >
        <td className="border-bottom-none text-17px text-4A4A6A w-15">
          {timeOrder}
        </td>
        <td className="border-bottom-none text-17px text-4A4A6A w-10">
          {tableName}
        </td>
        <td className="border-bottom-none text-17px text-269AFF w-10">
          {order_id}
        </td>
        <td className="border-bottom-none text-17px text-269AFF w-10">
          {totalItem}
        </td>
        <td className="border-bottom-none text-17px text-4A4A6A w-15">
          {phone ? phone : "-"}
        </td>
        <td className="border-bottom-none text-18px text-4A4A6A w-20">
          {customer_name ? customer_name : "-"}
        </td>
        <td className="border-bottom-none text-18px text-4A4A6A w-20">
          {order_method_name ? order_method_name : ""}
        </td>
      </tr>
    </>
  );
};
export default ContentTableOrder;
