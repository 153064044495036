
import React, { useEffect, useState } from "react";
import "./Styles.css"
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import Swal from "sweetalert2";
import ModalInfoReservation from "./components/ModalInfoReservation/ModalInfoReservation";
import { useSelector } from "react-redux";
import * as userSlice from "../../store/slices/userSlice";
import { ListReservation, UpSettingSeatOwner, UpStatusRevervation, gRevervationListPOS, gSettingSeatOwner } from "../../api/apiOwner/Reservation";
import ModalLoading from "../../components/ModalLoading";
import socket from "../../utils/socket";
import { cartEmpty } from "../../components/Image";
import { getLanguageUI } from "../../Services/languageUI";


const Reservation: React.FC = () => {
  const languageUI = getLanguageUI().reservation
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const [showModalInfoReservation, setShowModalInfoReservation] = useState(false)
  const [arrayListReservation, setArrayListReservation] = useState<ListReservation[]>([]);
  const [seatOwner, setSeatOwner] = useState('')
  const [seatOwnerOld, setSeatOwnerOld] = useState('')
  const [idReservation, setIdReservation] = useState('')
  const [messErrSeatOwner, setmessErrSeatOwnerSeatOwner] = useState('')

  const [showModalLoading, setShowModalLoading] = useState(false)




  const dismisModalInfoReservation = () => {
    setShowModalInfoReservation(false)
    ListReservation()
  }

  const handleCanleReservation = (id: string, status: number, custommerId: string) => {
    Swal.fire({
      title: languageUI.wantCancel,
      text: "",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#f66b6b",
      confirmButtonColor: "#28bb4b",
      cancelButtonText: languageUI.cancel,
      confirmButtonText: languageUI.confirm,
    })
      .then((result) => {
        if (result.isConfirmed) {
          UpdateStatusReservation(id, status, custommerId)
        }
      });
  }
  const handleConfirmReservation = (id: string, status: number, custommerId: string) => {
    Swal
      .fire({
        title: languageUI.wantConfirm,
        text: "",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#f66b6b",
        confirmButtonColor: "#28bb4b",
        cancelButtonText: languageUI.cancel,
        confirmButtonText: languageUI.confirm,

      })
      .then((result) => {
        if (result.isConfirmed) {
          UpdateStatusReservation(id, status, custommerId)
        }
      });
    //       .then((result) => {
    //         if (result.isConfirmed) {
    //           navigate(pathName); // Chuyển đến liên kết nếu xác nhận
    //           dispatch(flagSlice.clearSetting())
    //         }
    //       });
    //   } else {
    //     navigate(pathName)
    // }
  }
  const handleFinishReservation = (id: string, status: number, custommerId: string) => {
    Swal
      .fire({
        title: languageUI.wantFinish,
        text: "",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#f66b6b",
        confirmButtonColor: "#28bb4b",
        cancelButtonText: languageUI.cancel,
        confirmButtonText: languageUI.confirm,

      })
      .then((result) => {
        if (result.isConfirmed) {
          UpdateStatusReservation(id, status, custommerId)
        }
      });
    //       .then((result) => {
    //         if (result.isConfirmed) {
    //           navigate(pathName); // Chuyển đến liên kết nếu xác nhận
    //           dispatch(flagSlice.clearSetting())
    //         }
    //       });
    //   } else {
    //     navigate(pathName)
    // }
  }

  const ListReservation = async () => {
    setShowModalLoading(true)

    if (UserIdStore) {
      const req = await gRevervationListPOS(UserIdStore.toString())
      if (Number(req.status) === 1) {
        setArrayListReservation(req.data)
        setShowModalLoading(false)
      }
    }
  }
  const ListRevervationDetailCustomer = (id: string) => {
    setShowModalInfoReservation(true)
    setIdReservation(id)
  }
  const GetSeatOwner = async () => {
    setShowModalLoading(true)

    if (UserIdStore) {
      const res = await gSettingSeatOwner(UserIdStore.toString())
      if (Number(res.status) === 1) {
        setSeatOwner(res.data?.seat_reservation ?? '')
        setSeatOwnerOld(res.data?.seat_reservation ?? '')
        setShowModalLoading(false)
      }
    }
  }
  const UpdateSeatOwner = async () => {

    if (Number(seatOwnerOld) === Number(seatOwner)) {
      setShowModalLoading(false)
      return;
    }

    if (seatOwner === '') {
      setmessErrSeatOwnerSeatOwner('The total number of reservations cannot be empty')
      return;
    }
    if (Number(seatOwner) < 0) {
      setmessErrSeatOwnerSeatOwner('The total number of reservations cannot be less than 0')
      return;
    }
    setShowModalLoading(true)
    if (UserIdStore) {
      const req = await UpSettingSeatOwner(UserIdStore.toString(), seatOwner)
      if (Number(req.status) === 1) {
        setShowModalLoading(false)
      }
    }
  }
  const UpdateStatusReservation = async (id: string, status: number, custommerId: string) => {
    setShowModalLoading(true)
    let message = status === 1 ? 'Table reservations confirmed' : status === 3 ? 'Table reservations have been cancelled' : "";
    if (UserIdStore) {
      const res = await UpStatusRevervation(UserIdStore.toString(), id, status)
      if (Number(res.status === 1)) {
        socket.emit('ReplyBookingTable', { ownerID: UserIdStore, customer_id: custommerId, message, status })
        ListReservation()
        setShowModalLoading(false)
      }
    }
  }
  useEffect(() => {
    ListReservation()
    GetSeatOwner()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <div className="w-100 h-100vh  pt-header">
        <div className="box-enter-total-reservation">
          <div>
            <p className="m-0 font-medium text-4a4a6a text-right">{languageUI.enterReservation}:</p>
            {messErrSeatOwner ? (
              <p className="text-xs m-0 text-danger text-italic text-right">{messErrSeatOwner}</p>
            ) : ''}
          </div>
          <div className="px-4 py-1 border rounded-4 bg-white w-fit">
            <input type="number" className="focus-visiable-none border-none font-bold  w-40px" value={seatOwner} onChange={(e) => { setSeatOwner(e.target.value); setmessErrSeatOwnerSeatOwner('') }} inputMode={'numeric'} />
          </div>
          <button className="px-4 py-1 bg-primary font-bold text-white rounded-4 border-none shadow-btn-default" onClick={UpdateSeatOwner}>
            {languageUI.save}
          </button>
        </div>
        {arrayListReservation.length > 0 ? (
          <div className="p-4 box-reservation">
            {arrayListReservation.filter(filterItem3 => Number(filterItem3.status) === 3).map((item) => (
              <div className="rounded-4 shadow-button-default p-4 bg-white border-danger" key={`reservation-item-${item.id}`}>
                <div className="d-flex justify-content-between align-items-center h-33px">
                  <div className="d-inline">
                    <p className=" d-inline m-0 font-bold text-4a4a6a text-sm me-1">Order:</p>
                    <p className=" d-inline m-0 font-bold text-orange text-sm">#{item.id}</p>
                  </div>
                  {Number(item.status) === 3 ? ('') : (
                    <button className="px-4 py-1 rounded-5 border-none bg-orange shadow-button-default" onClick={() => ListRevervationDetailCustomer(item.id)}>
                      <BorderColorRoundedIcon className="text-white text-base" />
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-between gap-3 mt-2">
                  <p className="m-0 font-bold text-orange text-xs">{languageUI.dateTime}</p>
                  <p className="m-0 font-bold text-orange text-xs">{item.booking_time} {item.booking_date}</p>
                </div>
                <div className="d-flex justify-content-between gap-3 mt-1">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.customer}</p>
                  <p className="m-0 font-bold text-4a4a6a text-xs">{item.fullname}</p>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.phoneNumber}</p>
                  <p className="m-0 font-bold text-4a4a6a text-xs">{item.phone}</p>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.seatsNumber}</p>
                  <p className="m-0 font-bold text-4a4a6a text-xs">{item.seats} seats</p>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.note}</p>
                  <p className="m-0 font-medium text-4a4a6a text-xs text-right text-three-line-note">{item.note}</p>
                </div>
                {Number(item.status) === 0 ? (
                  <div className="mt-2 px-4 d-flex justify-content-between">
                    <button className="py-2 w-45 border-none bg-red shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 3, item?.customer_id)}>
                      {languageUI.cancel}
                    </button>
                    <button className="py-2 w-45 border-none bg-primary shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 1, item?.customer_id)}>
                      {languageUI.confirm}
                    </button>
                  </div>
                ) : Number(item.status) === 1 ? (
                  <div className="mt-2 px-4 d-flex justify-content-between">
                    <button className="py-2 w-45 border-none bg-red shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 3, item?.customer_id)}>
                      {languageUI.cancel}
                    </button>
                    <button className="py-2 w-45 border-none bg-primary shadow-button-default rounded-4 text-white font-bold" onClick={() => handleFinishReservation(item.id, 2, item?.customer_id)}>
                      {languageUI.finish}
                    </button>
                  </div>
                ) : (
                  <div className="mt-2 px-4 d-flex justify-content-between">
                    <button className="py-2 w-100 border-none bg-red shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 3, item?.customer_id)}>
                      {languageUI.delete}
                    </button>
                  </div>
                )}
              </div>
            ))}
            {arrayListReservation.filter(filterItem => Number(filterItem.status) !== 3).map((item) => (
              <div className="rounded-4 shadow-button-default p-4 bg-white" key={`reservation-item-${item.id}`}>
                <div className="d-flex justify-content-between align-items-center h-33px">
                  <div className="d-inline">
                    <p className=" d-inline m-0 font-bold text-4a4a6a text-sm me-1">Order:</p>
                    <p className=" d-inline m-0 font-bold text-orange text-sm">#{item.id}</p>
                  </div>
                  {Number(item.status) === 3 ? ('') : (
                    <button className="px-4 py-1 rounded-5 border-none bg-orange shadow-button-default" onClick={() => ListRevervationDetailCustomer(item.id)}>
                      <BorderColorRoundedIcon className="text-white text-base" />
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-between gap-3 mt-2">
                  <p className="m-0 font-bold text-orange text-xs">{languageUI.dateTime}</p>
                  <p className="m-0 font-bold text-orange text-xs">{item.booking_time} {item.booking_date}</p>
                </div>
                <div className="d-flex justify-content-between gap-3 mt-1">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.customer}</p>
                  <p className="m-0 font-bold text-4a4a6a text-xs">{item.fullname}</p>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.phoneNumber}</p>
                  <p className="m-0 font-bold text-4a4a6a text-xs">{item.phone}</p>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.seatsNumber}</p>
                  <p className="m-0 font-bold text-4a4a6a text-xs">{item.seats} seats</p>
                </div>
                <div className="d-flex justify-content-between gap-3">
                  <p className="m-0 font-bold text-4a4a6a text-xs">{languageUI.note}</p>
                  <p className="m-0 font-medium text-4a4a6a text-xs text-right text-three-line-note">{item.note}</p>
                </div>
                {Number(item.status) === 0 ? (
                  <div className="mt-2 px-4 d-flex justify-content-between">
                    <button className="py-2 w-45 border-none bg-red shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 3, item?.customer_id)}>
                      {languageUI.cancel}
                    </button>
                    <button className="py-2 w-45 border-none bg-primary shadow-button-default rounded-4 text-white font-bold" onClick={() => handleConfirmReservation(item.id, 1, item?.customer_id)}>
                      {languageUI.confirm}
                    </button>
                  </div>
                ) : Number(item.status) === 1 ? (
                  <div className="mt-2 px-4 d-flex justify-content-between">
                    <button className="py-2 w-45 border-none bg-red shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 3, item?.customer_id)}
                    >
                      {languageUI.cancel}
                    </button>
                    <button className="py-2 w-45 border-none bg-primary shadow-button-default rounded-4 text-white font-bold" onClick={() => handleFinishReservation(item.id, 2, item?.customer_id)}
                    >
                      {languageUI.finish}
                    </button>
                  </div>
                ) : (
                  <div className="mt-2 px-4 d-flex justify-content-between">
                    <button className="py-2 w-100 border-none bg-red shadow-button-default rounded-4 text-white font-bold" onClick={() => handleCanleReservation(item.id, 3, item?.customer_id)}>
                      {languageUI.delete}
                    </button>
                  </div>
                )}
              </div>
            ))}

          </div>
        ) : (
          <div className="box-img-cart-empty">
            <img src={cartEmpty} alt="" className="w-60" />
          </div>
        )}

      </div>
      <ModalLoading show={showModalLoading} onHide={() => setShowModalLoading(false)} />
      <ModalInfoReservation id={idReservation} handleClose={dismisModalInfoReservation} show={showModalInfoReservation} />
    </>
  )
}
export default Reservation