import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// components
import PageTitle from '../../components/PageTitle';
import * as userSlice from '../../store/slices/userSlice';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalLoading from '../../components/ModalLoading';
import * as SettingChangePasswordAdminAPI from '../../api/apiAdmin/SettingChangePasswordAdmin';
import { getLanguageCheckIn } from '../../Services/languageCheckin';

const SettingChangePasswordAdmin = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const languageUI = getLanguageCheckIn();
    const UserIdStore = useSelector(userSlice.selectorUserId);
    const [OldPass, setOldPass] = useState<any>("");
    const [NewPass, setNewPass] = useState<any>("");
    const [RetypeNewPass, setRetypeNewPass] = useState<any>("");
    const [Message, setMessage] = useState<any>("");
    const [MessageSuccess, setMessageSuccess] = useState<any>("");
    const [ShowLoading, setShowLoading] = useState(false);
    const [Showpass, setShowpass] = useState(false);
    const [ShowNewPass, setShowNewPass] = useState(false);
    const [ShowRetypeNewPass, setShowRetypeNewPass] = useState(false);

    const { t } = useTranslation();
    let menuItemElAdmin = document?.querySelectorAll(".menu-admin");
    let menuItemElOwner = document?.querySelectorAll(".menu-owner");
    menuItemElAdmin.forEach((item, i) => {
        menuItemElAdmin[i]?.classList.remove("d-none");
    });
    menuItemElOwner.forEach((item, i) => {
        menuItemElOwner[i]?.classList.add("d-none");
    });

    const changePassword = async () => {
        if (UserIdStore) {
            setShowLoading(true);
            const ChangePassword = await SettingChangePasswordAdminAPI.ChangePassword(UserIdStore, OldPass, NewPass, RetypeNewPass);
            if (Number(ChangePassword.status) === 1) {
                setMessage("");
                setMessageSuccess(languageUI.Updatepasswordsuccessful);
                setTimeout(() => {
                    setShowLoading(false);
                    window.location.reload();
                }, 2000);
            } else {
                setShowLoading(false);
                setMessage(ChangePassword?.message ? ChangePassword?.message : languageUI.SomethingwentwrongPleasetryagainlater);
                setMessageSuccess("");
            }
            setShowLoading(false);
        } else {
            navigate("/web-admin/login");
        }

    }

    const ReloadForm = () => {
        setOldPass("");
        setNewPass("");
        setRetypeNewPass("");
        setShowpass(false);
        setShowNewPass(false);
        setShowRetypeNewPass(false);
        setMessage("");
        setMessageSuccess("");
        setShowLoading(false);
    };

    useEffect(() => {
        ReloadForm();
    }, [location.pathname]);

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Setting', path: '#' },
                    { label: 'Change Password', path: '#', active: true },
                ]}
                title={'Setting Password'}
            />

            <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />

            <div className="py-3 px-2 rounded-3 mb-3">
                <Col xs={12} md={6} lg={4} className="mx-auto change-password-col">
                    <div className='p-3 bg-white shadow rounded-3'>
                        <Form.Label>{languageUI.Oldpass}:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={OldPass}
                                type={`${Showpass ? "text" : "password"}`}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                placeholder={languageUI.Enteryouoldpass}
                                onChange={(e: any) => { setOldPass(e.target.value) }}
                            />
                            <InputGroup.Text className={`input-group-password ${Showpass ? "show-password" : ""}`} id="inputGroup-sizing-sm" onClick={() => { setShowpass(!Showpass) }}>
                                <span className="password-eye"></span>
                            </InputGroup.Text>
                        </InputGroup>

                        <Form.Label>{languageUI.NewPassword}:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={NewPass}
                                type={`${ShowNewPass ? "text" : "password"}`}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                placeholder={languageUI.Enternewpassword}
                                onChange={(e: any) => { setNewPass(e.target.value) }}
                            />
                            <InputGroup.Text className={`input-group-password ${ShowNewPass ? "show-password" : ""}`} id="inputGroup-sizing-sm" onClick={() => { setShowNewPass(!ShowNewPass) }}>
                                <span className="password-eye"></span>
                            </InputGroup.Text>
                        </InputGroup>

                        <Form.Label>{languageUI.Retypenewpass}:</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                value={RetypeNewPass}
                                type={`${ShowRetypeNewPass ? "text" : "password"}`}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                placeholder={languageUI.Retypenewpass}
                                onChange={(e: any) => { setRetypeNewPass(e.target.value) }}
                            />
                            <InputGroup.Text className={`input-group-password ${ShowRetypeNewPass ? "show-password" : ""}`} id="inputGroup-sizing-sm" onClick={() => { setShowRetypeNewPass(!ShowRetypeNewPass) }}>
                                <span className="password-eye"></span>
                            </InputGroup.Text>
                        </InputGroup>

                        <Form.Label className="text-danger">{Message}</Form.Label>
                        <Form.Label className="text-success">{MessageSuccess}</Form.Label>
                        <Button variant="primary" className="d-block w-100" onClick={() => { changePassword(); }}>
                            {t(languageUI.Save)}
                        </Button>
                    </div>
                </Col>
            </div>
        </>
    );
};

export default SettingChangePasswordAdmin;
