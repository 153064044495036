import Modal from 'react-bootstrap/Modal';
import "./styles.css"
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { addSalaryBonus } from '../../../../api/apiOwner/StaffApi';
import moment from "moment-timezone";
import dayjs from 'dayjs';
import * as userSlice from '../../../../store/slices/userSlice';
import { useSelector } from 'react-redux';
import ModalLoading from '../../../../components/ModalLoading';
import { formatDate } from '../../../../utils/format';
import React from 'react';
import { getLanguageUI } from '../../../../Services/languageUI';

interface ModalSalaryBonusProps {
  show: boolean;
  handleClose: (load?: boolean) => void;
  id_staff: number;
  nameStaff: string,
}
const ModalSalaryBonus = ({
  show,
  handleClose,
  id_staff,
  nameStaff
}: ModalSalaryBonusProps) => {
  const languageUI = getLanguageUI().staffPage
  const [alignment, setAlignment] = useState('1');
  const [messErr, setMessErr] = useState('');
  const [amount, seAmount] = useState<number>(0);
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [chooseDate, setChooseDate] = useState<dayjs.Dayjs | null | string | any>('')
  const UserIdStore = useSelector(userSlice.selectorUserId);



  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
    setMessErr('')
  };
  const CreateSalaryBonus = async () => {
    const dateBonus = moment(formatDate(chooseDate.$d)).format("YYYY-MM-DD");



    if (dateBonus === "Invalid date") {
      setMessErr('Date is not in correct format')
      return
    }
    if (amount < 1) {
      setMessErr('Amount must be greater than 0')
      return
    }
    if (alignment === null) {
      setMessErr('Bonus or Deduct is required')
      return
    }
    setShowLoading(true)
    if (UserIdStore) {
      const req = await addSalaryBonus(UserIdStore, id_staff, dateBonus, alignment, amount)
      if (Number(req.status) === 1) {
        handleClose()
        setShowLoading(false)
      }
    }
  }

  return (

    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-add-food-order"
      >
        <Modal.Body>
          <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
          <Modal.Header className='p-0 w-100'>
            <h3 className='font-bold m-0 text-11151f text-center'>{languageUI.employeeIncentives}</h3>
          </Modal.Header>
          <div className='box-add-another-dish '>
            <div className='container-box-add-dish-group p-4'>
              <div className='d-flex align-items-center'>
                <div className='w-50'>
                  <p className='font-bold text-11151f d-inline m-0'>{languageUI.name}: <p className='text-4a4a6a d-inline m-0'>{nameStaff}</p></p>
                </div>
                <div className='w-50 d-flex gap-2 align-items-center justify-content-between'>
                  <p className='font-bold text-11151f d-inline m-0'>{languageUI.date}:</p>
                  <div className='w-70 flex-shrink-0'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker className='w-100' format='YYYY/MM/DD' onChange={(date) => {
                          setChooseDate(date); setMessErr('');
                        }} />
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                </div>
              </div>
              <div className='d-flex  mt-2'>
                <div className='w-50'>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton className='btn-status-bonus-deduct' value="1">{languageUI.bonus}</ToggleButton>
                    <ToggleButton className='btn-status-bonus-deduct' value="2">{languageUI.deduct}</ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <div className='w-50 d-flex items-center justify-content-between gap-2'>
                  <p className='font-bold text-11151f d-inline m-0'>{languageUI.amount}:</p>
                  <div className='w-70 flex-shrink-0'>
                    <TextField id="outlined-basic" type="number" inputMode='numeric' placeholder={languageUI.enterAmount} className='w-100' variant="outlined" onChange={(e) => { seAmount(Number(e.target.value)); setMessErr('') }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal.Footer className='p-0'>
            {messErr !== "" ? (
              <p className='text-center text-danger text-italic w-100'>{messErr}</p>
            ) : ''}
            <div className='w-100 d-flex justify-content-end gap-3'>
              <button className='w-100px py-2 px-6 rounded-4 bg-red text-white border-none font-bold' onClick={() => handleClose()}>
                {languageUI.close}
              </button>
              <button className='w-100px py-2 px-6 rounded-4 bg-primary border-none text-white font-bold' onClick={() => CreateSalaryBonus()}>
                {languageUI.save}
              </button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ModalSalaryBonus;