import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Menu {
  description?: string,
  imageCourse?: string,
  menu_id?: string,
  menu_name?: string,
  parent_id?: string,
  price?: string,
  side_id?: string,
}
interface MenuCategory {
  foodgroup_id?: string,
  foodgroup_name?: string,
  owner_id?: string,
  parent_id?: string,
  list_menu?: Menu[]
}

const initialMenuFoodState: MenuCategory[] = [];

const MenuFoodSlice = createSlice({
  name: "menufood",
  initialState: initialMenuFoodState,
  reducers: {
    setListMenuFood: (state, action: PayloadAction<MenuCategory[]>) => action.payload,

    clearMenuFood: (state) => initialMenuFoodState,
  },
});
export const selectorListMenuFood = (state: { menufood: MenuCategory[] }) =>
  state.menufood;

export const {
  setListMenuFood,
  clearMenuFood
} = MenuFoodSlice.actions;
export default MenuFoodSlice.reducer;
