import * as Request from "../Request";

export interface Result<T> {
  status: number;
  message: string;
  data: T;
}
export interface printype {
  print_type: string;
}
export interface ListPrinterConnect {
  id: string;
  printer_name: string;
  owner_id: string;
  printType: number[];
  listType: printype[];
}
export interface ListPrinterByCatalog {
  printer_id: string;
  printer_name: string;
  printType: number[];
}
export interface ListPrinter {
  id: string;
  owner_id: string;
  printer_catalog_id: string;
  printer_id: string;
  printer_name: string;
}
export interface ListPrinterCatalog {
  id: string;
  catalog_name: string;
  listPrinter: ListPrinter[];
}
//danh sách máy in đang connect
export const gListPrinterConnectingWindow = async () => {
  return await Request.fetchAPI(`owner/gAllPrinterNameWindow.php`, "POST", {})
    .then((res: Result<string[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//danh sách máy in đang connect
export const gListPrinterConnecting = async (
  owner_id: number,
  pos_id: number
) => {
  return await Request.fetchAPI(`owner/gListPrinterByOwner.php`, "POST", {
    owner_id,
    pos_id,
  })
    .then((res: Result<ListPrinterConnect[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//danh sách máy in theo danh mục cấu hình
export const gListPrinterConnectingByOwner = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListPrinterByCatalog.php`, "POST", {
    owner_id,
  })
    .then((res: Result<ListPrinterCatalog[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//thêm máy in connect trong hệ thống
export const addPrinterConnect = async (
  owner_id: number,
  printerName: string,
  type_option: number[],
  pos_id: number
) => {
  return await Request.fetchAPI(`owner/addPrinterConnect.php`, "POST", {
    owner_id,
    printerName,
    type_option: JSON.stringify(type_option),
    pos_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
//thêm nhiều máy in connect trong hệ thống
export const addMultipPrinterConnect = async (
  owner_id: number,
  printerName: string[]
) => {
  return await Request.fetchAPI(`owner/addMultipPrinterConnect.php`, "POST", {
    owner_id,
    printerName: JSON.stringify(printerName),
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
//lấy danh sách máy in chưa add vào danh mục
export const gListPrinterWait = async (owner_id: number, catalogId: string) => {
  return await Request.fetchAPI(`owner/gListPrinterWaitByCatalog.php`, "POST", {
    owner_id,
    catalogId,
  })
    .then((res: Result<ListPrinterConnect[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//thêm danh sách máy in chưa add vào danh mục
export const AddListPrinterWaitToCatalog = async (
  owner_id: number,
  printer_catalog_id: string,
  ListPrinterId: number[]
) => {
  return await Request.fetchAPI(`owner/addPrinterToCatalog.php`, "POST", {
    owner_id,
    printer_catalog_id,
    ListPrinterId: JSON.stringify(ListPrinterId),
  })
    .then((res: Result<ListPrinterConnect[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//xóa máy in khỏi danh mục
export const DelPrinterToCatalog = async (
  owner_id: number,
  idConfig: string
) => {
  return await Request.fetchAPI(`owner/deletePrinterCatalog.php`, "POST", {
    owner_id,
    idConfig,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
//xóa máy in khỏi hệ thống
export const DelPrinterConnect = async (
  owner_id: number,
  printer_id: string
) => {
  return await Request.fetchAPI(`owner/deletePrinterConnect.php`, "POST", {
    owner_id,
    printer_id,
  })
    .then((res: Result<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
//lấy tên máy in theo id danh mục
export const gNamePrinterByCatalog = async (
  owner_id: number,
  catalogId: number,
  pos_id: number
) => {
  return await Request.fetchAPI(`owner/gListNamePrinterbyCatalogPos.php`, "POST", {
    owner_id,
    catalogId,
    pos_id,
  })
    .then((res: Result<ListPrinterByCatalog[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//lấy tên máy in chính theo owner
export const gNamePrinterMainByCatalog = async (
  owner_id: number,
  catalogId: number
) => {
  return await Request.fetchAPI(
    `owner/gListNamePrinterMainbyCatalog.php`,
    "POST",
    {
      owner_id,
      catalogId,
    }
  )
    .then((res: Result<ListPrinterByCatalog[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//lấy tên máy in else
export const gListNamePrinterElse = async (
  owner_id: number,
  pos_id: number
) => {
  return await Request.fetchAPI(`owner/gListNamePrinterElse.php`, "POST", {
    owner_id,
    pos_id,
  })
    .then((res: Result<ListPrinterByCatalog[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
//Kiểm tra máy in có kết nối theo id danh mục
export const checkConnectPrinterByCatalog = async (
  owner_id: number,
  pos_id: number
) => {
  return await Request.fetchAPI(
    `owner/checkConnectPrinterByCatalog.php`,
    "POST",
    { owner_id, pos_id }
  )
    .then((res: Result<string[]>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
