import * as Request from "../Request";

export interface TableInfo {
  id?: string;
  table_name?: string;
  sort?: string;
  owner_id?: string;
  status?: string;
  status_name?: string;
  floor_id?: string;
  floor_name?: string;
  size?: string;
  tx?: number;
  ty?: number;
  scale?: number;
  rorate?: number;
  shape?: string;
}

export interface FloorInfo {
  id: string;
  owner_id: string;
  floor_name: string;
  sort: string;
  num_table: string;
  color: string;
}
export interface Result {
  status: number;
  message?: string;
  data?: TableInfo[];
}
export interface ResultRemoveTable<T> {
  status: number;
  message?: string;
  data?: T;
}
export interface ResultFloor {
  status: number;
  message?: string;
  data?: FloorInfo[];
}
export interface ResultCheckPayment {
  status: number;
  message?: string;
  is_visa?: boolean;
  is_cash?: boolean;
}
export interface ResultTable {
  status: number;
  message?: string;
  tableName?: string;
}
export interface listOrderRemove {
  order_id: number;
}
export const gListAllFloor = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gfloorByOwner.php`, "POST", { owner_id })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gListAllTable = async (owner_id: number, page: number) => {
  return await Request.fetchAPI(`owner/gListTable.php`, "POST", {
    owner_id,
    page,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      if (err.request && err.request.status === 0) {
        return {
          message: "network error",
          status: 2,
          data: [],
        };
      } else {
        return {
          message:
            "An error has occurred. Please check your internet connection",
          status: 0,
          data: [],
        };
      }
    });
};
export const gListTableByCustommer = async (
  owner_id: number,
  searchKey: string
) => {
  return await Request.fetchAPI(`owner/gTableByCustommer.php`, "POST", {
    owner_id,
    searchKey,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gListAllTableByFloor = async (
  owner_id: number,
  floor_id: string,
  page: number
) => {
  return await Request.fetchAPI(`owner/gListTable.php`, "POST", {
    owner_id,
    floor_id,
    page,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const gListAllTablenotFloor = async (table_id: string) => {
  return await Request.fetchAPI(`owner/gListTableNotFloor.php`, "POST", {
    table_id,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const gListTableOffline = async (owner_id: number) => {
  return await Request.fetchAPI(`owner/gListTableOffline.php`, "POST", {
    owner_id,
  })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const CleaveTable = async (table_id: string) => {
  return await Request.fetchAPI(`owner/clearTable.php`, "POST", { table_id })
    .then((res: Result) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export const CheckPaymentTable = async (owner_id: number, table_id: string) => {
  return await Request.fetchAPI(`owner/checkPaymentInOrder.php`, "POST", {
    owner_id,
    table_id,
  })
    .then((res: ResultCheckPayment) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        is_visa: false,
        is_cash: false,
      };
    });
};

export const gDefaultTableNameAdd = async (
  owner_id: number,
  floor_id: string
) => {
  return await Request.fetchAPI(`owner/gDefaultTableName.php`, "POST", {
    owner_id,
    floor_id,
  })
    .then((res: ResultTable) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        tableName: "",
      };
    });
};

export const addTableSingle = async (
  owner_id: number,
  floor_id: string,
  size: number
) => {
  return await Request.fetchAPI(`owner/add-table-signle.php`, "POST", {
    owner_id,
    floor_id,
    size,
  })
    .then((res: ResultTable) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const addTableMulti = async (
  owner_id: number,
  floor_id: string,
  table_number: number,
  size: number
) => {
  return await Request.fetchAPI(`owner/add-table-multi.php`, "POST", {
    owner_id,
    floor_id,
    table_number,
    size,
  })
    .then((res: ResultTable) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};
export const removeTableSingle = async (table_id: string) => {
  return await Request.fetchAPI(`owner/delete-table.php`, "POST", { table_id })
    .then((res: ResultTable) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const removeTableMulti = async (arrTable: string[]) => {
  return await Request.fetchAPI(`owner/delete-table-multi.php`, "POST", {
    arrTable: JSON.stringify(arrTable),
  })
    .then((res: ResultTable) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const updateSortTbale = async (table_array: TableInfo[]) => {
  return await Request.fetchAPI(`owner/resortTable.php`, "POST", {
    table_array: JSON.stringify(table_array),
  })
    .then((res: ResultTable) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
      };
    });
};

export const clearTransformTable = async (
  owner_id: number,
  floor_id: string
) => {
  return await Request.fetchAPI(`owner/clearTransformTable.php`, "POST", {
    owner_id,
    floor_id,
  })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const upTransformTableById = async (
  owner_id: number,
  table_id: number,
  tx: number,
  ty: number
) => {
  return await Request.fetchAPI(`owner/updateTranformById.php`, "POST", {
    owner_id,
    table_id,
    tx,
    ty,
  })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const upShapeTableById = async (
  owner_id: number,
  table_id: string,
  shape: string
) => {
  return await Request.fetchAPI(`owner/upShapeTable.php`, "POST", {
    owner_id,
    table_id,
    shape,
  })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const upScaleTableById = async (
  owner_id: number,
  table_id: string,
  scale: number
) => {
  return await Request.fetchAPI(`owner/upScaleTable.php`, "POST", {
    owner_id,
    table_id,
    scale,
  })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const upRorateTableById = async (
  owner_id: number,
  table_id: string,
  rorate: number
) => {
  return await Request.fetchAPI(`owner/upRorateTable.php`, "POST", {
    owner_id,
    table_id,
    rorate,
  })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};
export const upRorateMultiTableById = async (
  arrTable: string[],
  rorate: number,
  scale: number,
  shape: string
) => {
  return await Request.fetchAPI(`owner/update-table-multi.php`, "POST", {
    arrTable: JSON.stringify(arrTable),
    rorate,
    scale,
    shape,
  })
    .then((res: ResultFloor) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: [],
      };
    });
};

export interface listOrderWaitPay {
  custommer_id: number;
  name: string;
  order_id: number;
  totalPrice: number;
  tip_amount: number;
  tip_staff_id: number;
  staff_name: string;
  discount: number;
  listFood: ListFood[];
  listSide: any[];
}

export interface ListFood {
  id: number;
  owner_id: number;
  custommer_id: number;
  table_id: number;
  service_item_id: number;
  price: number;
  quantity: number;
  notefood_id: number;
  side_id: number;
  form_order: number;
  note: string;
  name: string;
  imageCourse: string;
  donvitinh: any;
  table_name: string;
  notefood_name: string;
  side_name: any;
  description: string;
}
export const swapOrderTable = async (
  owner_id: number,
  table_id_old: number,
  table_id_new: number,
  listOrder: listOrderRemove[]
) => {
  return await Request.fetchAPI(`owner/swapOrderTable.php`, "POST", {
    owner_id,
    table_id_old,
    table_id_new,
    listOrder: JSON.stringify(listOrder),
  })
    .then((res: ResultRemoveTable<string>) => {
      return res;
    })
    .catch((err: any) => {
      return {
        message: "An error has occurred. Please check your internet connection",
        status: 0,
        data: "",
      };
    });
};
