
import { getLanguageUI } from "../../../../../../Services/languageUI";
import { StaffActive } from "../../../../../../api/apiOwner/StaffApi";
import { GroupIcon } from "../../../../../../components/Image"
import ContentActiveEmployee from "../ContentTable/ContentTableEmployee/ContentActiveEmployee"

interface EmployeeInActiveProp {
    listEmployeeInActive: StaffActive[];
    HandleActiveSuccess: () => void
}
const EmployeeInactive: React.FC<EmployeeInActiveProp> = ({ listEmployeeInActive = [], HandleActiveSuccess }) => {
    const languageUI = getLanguageUI().staffPage
    return (
        <>
            <table className="table">
                <thead>
                    <tr >
                        <th scope="col" className="w-10 text-18px text-12B064 fw-600 font-urbansist">{languageUI.noNumber}</th>
                        <th scope="col" className="w-20 text-18px text-12B064 fw-600 font-urbansist"><GroupIcon className="mr-3" />{languageUI.employee}</th>
                        <th scope="col" className="w-20 ">
                            <div className="flex justify-between  items-center">
                                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">{languageUI.position}</span>
                            </div>
                        </th>
                        <th scope="col" className="w-20">

                            <div className="flex items-center">
                                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">{languageUI.dateJoined}</span>
                            </div>
                        </th>
                        <th scope="col" className="w-20 ">
                            <div className="flex items-center">
                                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3">{languageUI.workingTime}</span>
                            </div>
                        </th>
                        <th scope="col" className="w-10 ">
                            <div className="flex items-center">
                                <span className="text-18px text-12B064 fw-600 font-urbansist mr-3"></span>
                            </div>
                        </th>


                    </tr>
                </thead>
                <tbody className="h-body-table-employee">
                    {listEmployeeInActive.map((item, i) => (
                        <ContentActiveEmployee key={`active${i}`} active={false} no={i + 1} employee={item?.name} position={item?.positon_name} dateJoined={item?.startdate} workingTime={item?.working_time} staff_id={item?.id} status={false} HandleActiveSuccess={HandleActiveSuccess} tabOptionEmployee={3} />
                    ))}
                </tbody>
            </table>

        </>
    )
}

export default EmployeeInactive