import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import * as userSlice from "../store/slices/userSlice";

// actions
import { showRightSidebar, changeSidebarType } from "../redux/actions";

// store
import { RootState, AppDispatch } from "../redux/store";

//constants
import { LayoutTypes, SideBarTypes } from "../constants/layout";

// components
import TopbarSearch from "../components/TopbarSearch";
import MaximizeScreen from "../components/MaximizeScreen";
import AppsDropdown from "../components/AppsDropdown";
import SearchDropdown from "../components/SearchDropdown";
import LanguageDropdown from "../components/LanguageDropdown";
import NotificationDropdown from "../components/NotificationDropdown";
import ProfileDropdown from "../components/ProfileDropdown";
import CreateNew from "../components/CreateNew";
import MegaMenu from "../components/MegaMenu";
import socket from '../utils/socket';
import profilePic from "../assets/images/users/user-1.jpg";
import avatar4 from "../assets/images/users/user-4.jpg";
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoDark2 from "../assets/images/logo-dark-2.png";
import logoLight from "../assets/images/logo-light.png";
import logoLight2 from "../assets/images/logo-light-2.png";
import { logoFull, logoOnly, user } from "../components/Image";
import * as Request from "../api/Request";
import ModalLoading from "../components/ModalLoading";
import "./Styles.css"
import { checksNotification, notifitionHeader } from "../components/ImgExport";
import { Popover } from "@mui/material"
import { CardPaymentNotification, DishesOrderNotification, SupportNotification } from "../components/SVGChangeColor/SVGChangeColor";
import ItemsNotification from "../components/ItemsNotification";
import * as notificationApi from "../api/apiOwner/notificationApi";

export interface NotificationItem {
  id: number;
  text: string;
  subText: string;
  icon?: string;
  avatar?: string;
  bgColor?: string;
}

// get the notifications
const Notifications: NotificationItem[] = [
  {
    id: 1,
    text: "Cristina Pride",
    subText: "Hi, How are you? What about our next meeting",
    avatar: profilePic,
  },
  {
    id: 2,
    text: "Caleb Flakelar commented on Admin",
    subText: "1 min ago",
    icon: "mdi mdi-comment-account-outline",
    bgColor: "primary",
  },
  {
    id: 3,
    text: "Karen Robinson",
    subText: "Wow ! this admin looks good and awesome design",
    avatar: avatar4,
  },
  {
    id: 4,
    text: "New user registered.",
    subText: "5 hours ago",
    icon: "mdi mdi-account-plus",
    bgColor: "warning",
  },
  {
    id: 5,
    text: "Caleb Flakelar commented on Admin",
    subText: "1 min ago",
    icon: "mdi mdi-comment-account-outline",
    bgColor: "info",
  },
  {
    id: 6,
    text: "Carlos Crouch liked Admin",
    subText: "13 days ago",
    icon: "mdi mdi-heart",
    bgColor: "secondary",
  },
];

// get the profilemenu
const ProfileAdminMenus = [
  {
    label: "Account Info",
    icon: "fe-user",
    redirectTo: "/web-admin/acccount-infor",
  },
  {
    label: "Setting",
    icon: "fe-settings",
    redirectTo: "/web-admin/setting-change-password",
  },
  // {
  //     label: 'Lock Screen',
  //     icon: 'fe-lock',
  //     redirectTo: '/auth/lock-screen',
  // },
  {
    label: "Log out",
    icon: "fe-log-out",
    redirectTo: "/auth/logout",
  },
];
const ProfileOwnerMenus = [
  {
    label: "Account Infor",
    icon: "fe-user",
    redirectTo: "/web-owner/owner-infor",
  },
  {
    label: "Setting",
    icon: "fe-settings",
    redirectTo: "/web-owner/setting-information",
  },
  // {
  //     label: 'Lock Screen',
  //     icon: 'fe-lock',
  //     redirectTo: '/auth/lock-screen',
  // },
  {
    label: "Log out",
    icon: "fe-log-out",
    redirectTo: "/auth/logout",
  },
];

// dummy search results
const SearchResults = [
  {
    id: 1,
    title: "Analytics Report",
    icon: "uil-notes",
    redirectTo: "#",
  },
  {
    id: 2,
    title: "How can I help you?",
    icon: "uil-life-ring",
    redirectTo: "#",
  },
  {
    id: 3,
    icon: "uil-cog",
    title: "User profile settings",
    redirectTo: "#",
  },
];

const otherOptions = [
  {
    id: 1,
    label: "New Projects",
    icon: "fe-briefcase",
  },
  {
    id: 2,
    label: "Create Users",
    icon: "fe-user",
  },
  {
    id: 3,
    label: "Revenue Report",
    icon: "fe-bar-chart-line-",
  },
  {
    id: 4,
    label: "Settings",
    icon: "fe-settings",
  },
  {
    id: 4,
    label: "Help & Support",
    icon: "fe-headphones",
  },
];

// get mega-menu options
const MegaMenuOptions = [
  {
    id: 1,
    title: "UI Components",
    menuItems: [
      "Widgets",
      "Nestable List",
      "Range Sliders",
      "Masonry Items",
      "Sweet Alerts",
      "Treeview Page",
      "Tour Page",
    ],
  },
  {
    id: 2,
    title: "Applications",
    menuItems: [
      "eCommerce Pages",
      "CRM Pages",
      "Email",
      "Calendar",
      "Team Contacts",
      "Task Board",
      "Email Templates",
    ],
  },
  {
    id: 3,
    title: "Extra Pages",
    menuItems: [
      "Left Sidebar with User",
      "Menu Collapsed",
      "Small Left Sidebar",
      "New Header Style",
      "Search Result",
      "Gallery Pages",
      "Maintenance & Coming Soon",
    ],
  },
];

interface TopbarProps {
  hideLogo?: boolean;
  navCssClasses?: string;
  openLeftMenuCallBack?: () => void;
  topbarDark?: boolean;
  pageName?: string;
}

const TopbarAdmin = ({
  hideLogo,
  navCssClasses,
  pageName,
  openLeftMenuCallBack,
  topbarDark,
}: TopbarProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isopen, setIsopen] = useState<boolean>(false);

  const navbarCssClasses: string = navCssClasses || "";
  const containerCssClasses: string = !hideLogo ? "container-fluid" : "";

  // const { layoutType, leftSideBarType } = useSelector((state: RootState) => ({
  // const { layoutType } = useSelector((state: RootState) => ({
  //     layoutType: state.Layout.layoutType,
  //     leftSideBarType: state.Layout.leftSideBarType,
  // }));

  /**
   * Toggle the leftmenu when having mobile screen
   */
  const handleLeftMenuCallBack = () => {
    setIsopen(!isopen);
    if (openLeftMenuCallBack) openLeftMenuCallBack();
  };

  /**
   * Toggles the right sidebar
   */
  const handleRightSideBar = () => {
    dispatch(showRightSidebar());
  };

  /**
   * Toggles the left sidebar width
   */
  const toggleLeftSidebarWidth = () => {
    // if (leftSideBarType === 'default' || leftSideBarType === 'compact')
    //     dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_CONDENSED));
    // if (leftSideBarType === 'condensed') dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_DEFAULT));
    let bodyEl = document.querySelector("body");
    bodyEl?.hasAttribute("data-leftbar-size")
      ? bodyEl?.removeAttribute("data-leftbar-size")
      : bodyEl?.setAttribute("data-leftbar-size", "condensed");
  };
  const UserIdStore = useSelector(userSlice.selectorUserId);
  const nameOwner = useSelector(userSlice.selectorName);
  const [UserName, setUserName] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [ShowLoading, setShowLoading] = useState<boolean>(false);
  const [openPopoverNotification, setOpenPopoverNotification] = useState<boolean>(false);
  const [changeOptionNotification, setChangeOptionNotification] = useState<number>(1);
  const [numNotification, setNumNotification] = useState<number>(0)
  const [listNotification, setListNotification] = useState<notificationApi.Notification[]>([])

  const [Hotline, setHotline] = useState<any>("");
  const [role, setRole] = useState<Number>(0);
  const navigate = useNavigate();
  const location = useLocation();
  const closePopoverNotification = () => {
    setOpenPopoverNotification(false)
  }
  const GetInfoOwner = async () => {
    setShowLoading(true);
    await Request.fetchAPI("owner/gInfoOwner.php?id=" + UserIdStore, "GET", {})
      .then((result: any) => {
        if (result?.status == 1) {
          setUserName(result?.data?.email);
          setRole(result?.data?.role);
          if (
            location.pathname.indexOf("/web-admin") > -1 &&
            result?.data?.role != 0 &&
            result?.data?.role != 2
          ) {
            console.log("Chưa đăng nhập");
            ResetReduxStoreUser();
            navigate("/web-admin/login");
          }

          if (
            location.pathname.indexOf("/web-owner") > -1 &&
            result?.data?.role != 1
          ) {
            console.log("Chưa đăng nhập");
            ResetReduxStoreUser();
            navigate("/auth/login");
          }
          setMessage("");
          // console.log("Thông tin Owner", result?.data);
        } else {
          setMessage(
            result?.message
              ? result?.message
              : "Something went wrong. Please try again later!"
          );
        }
      })
      .catch((err: any) => {
        setMessage(
          "An error has occurred. Please check your internet connection"
        );
      });

    setShowLoading(false);
  };

  // console.log("UserIdStore", UserIdStore);
  // console.log("role", role);
  // console.log("history", location.pathname.indexOf("/web-admin"));

  const dispatchUser = useDispatch();
  const ResetReduxStoreUser = async () => {
    await dispatchUser(userSlice.setUserId(0));
    await dispatchUser(userSlice.setUserName(""));
  };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    upNotificationtoSee()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const checkNotificaton = useCallback(async () => {
    var pathName = window.location.pathname;
    await socket.on("PushShowNotification" + UserIdStore, data => {
      if (pathName.search('/web-owner/') > -1) {
        countNotificationByOwner()
        gListNotificationByOwner()
      }
    })
  }, [socket])

  const countNotificationByOwner = async () => {
    if (UserIdStore) {
      const req = await notificationApi.countNotification(UserIdStore);
      if (Number(req?.status) === 1) {
        setNumNotification(req?.data ? Number(req?.data) : 0)
      }
    }
  }
  const gListNotificationByOwner = async () => {
    if (UserIdStore) {
      const req = await notificationApi.gListNotification(UserIdStore);
      if (Number(req?.status) === 1) {
        setListNotification(req?.data ?? []);
      }
    }
  }
  const upNotificationtoSee = async () => {
    setNumNotification(0)
    if (UserIdStore) {
      const req = await notificationApi.upIsreadNotificationtoSee(UserIdStore);

    }
  }
  const MarkReadAll = async () => {
    setNumNotification(0)
    if (UserIdStore) {
      const req = await notificationApi.upAllIsreadtoseen(UserIdStore);
      if (Number(req?.status) === 1) {
        setListNotification(listNotification.map(item => { return { ...item, is_read: '2' } }))
      }
    }
  }
  useEffect(() => {
    checkNotificaton()
    if (listNotification.length === 0) {
      countNotificationByOwner()
      gListNotificationByOwner()
    }

  }, [socket])

  // useEffect(() => {
  //   if (UserIdStore) {
  //     setShowLoading(false);
  //     GetInfoOwner();
  //     countNotificationByOwner()
  //     gListNotificationByOwner()

  //   }
  // }, []);

  return (
    <React.Fragment>
      <ModalLoading show={ShowLoading} onHide={() => setShowLoading(false)} />
      <div className={`navbar-custom ${navbarCssClasses} bg-white`}>
        <div className={`${containerCssClasses} h-100 d-flex align-items-center justify-content-between pe-2`}>
          {!hideLogo && (
            <div className="d-flex align-items-center px-4">
              <div className="d-flex">
                <a href="" className="mb-0 link-home me-1">
                  <p className="mb-0">Home /</p>
                </a>
                <p className="mb-0 text-home-child">{pageName}</p>
              </div>
            </div>
          )}


          <div className="d-flex align-items-center gap-2">
            {/* <button className="position-relative bg-white border-none " aria-describedby={id} onClick={handleClick}>
              <img src={notifitionHeader} alt="" />
              <div className="box-number-notifition">
                {numNotification < 100 ? numNotification : 99}
              </div>
            </button> */}
            <ul className="list-unstyled topnav-menu float-end mb-0 h-100">
              <li className="dropdown notification-list topbar-dropdown h-100">
                <ProfileDropdown
                  profilePic={profilePic}
                  menuItems={
                    role == 0 || role == 2 ? ProfileAdminMenus : ProfileOwnerMenus
                  }
                  username={nameOwner ?? ''}
                  userTitle={"Founder"}
                />
              </li>
            </ul>
          </div>
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className=""
          >
            <div className="box-notification">
              <div className="p-6">
                <div className="box-options-notification">
                  <div className={`${changeOptionNotification === 1 ? "option-notification-2" : "option-notification"}`} onClick={() => setChangeOptionNotification(1)}>
                    <DishesOrderNotification fill={changeOptionNotification === 1 ? "#0FA54A" : "#4a4a6a"} />
                    <p className={`mb-0 text-option-notification ${changeOptionNotification === 1 ? "text-0FA54A" : "text-4a4a6a"}`}>Order</p>
                  </div>
                  <div className={`${changeOptionNotification === 2 ? "option-notification-2" : "option-notification"}`} onClick={() => setChangeOptionNotification(2)}>
                    <CardPaymentNotification fill={changeOptionNotification === 2 ? "#0FA54A" : "#4a4a6a"} />
                    <p className={`mb-0 text-option-notification ${changeOptionNotification === 2 ? "text-0FA54A" : "text-4a4a6a"}`}>Payment</p>
                  </div>
                  <div className={`${changeOptionNotification === 3 ? "option-notification-2" : "option-notification"}`} onClick={() => setChangeOptionNotification(3)}>
                    <SupportNotification fill={changeOptionNotification === 3 ? "#0FA54A" : "#4a4a6a"} />
                    <p className={`mb-0 text-option-notification ${changeOptionNotification === 3 ? "text-0FA54A" : "text-4a4a6a"}`}>Suport</p>
                  </div>
                </div>
              </div>
              <div className="w-100s">
                <p className="mb-0 text-center text-notification-delete-2day">Notification will be delete after 2 days</p>
                <div className="px-6 py-3">
                  <div className={`d-flex flex-column gap-3 height-notification ${changeOptionNotification === 1 ? "" : "d-none"}`}>
                    {listNotification.map((item, i) => Number(item?.type) === 3 && (
                      <ItemsNotification key={`order${i}`} table_name={item?.table_name} order_id={item?.order_id} status="order" body={item?.body} tableId={item?.table_id} floorId={item?.floor_id} is_read={item?.is_read} notificationId={item?.id} />
                    ))}

                  </div>
                  <div className={`d-flex flex-column gap-3 height-notification ${changeOptionNotification === 2 ? "" : "d-none"}`}>
                    {listNotification.map((item, i) => Number(item?.type) === 1 && (
                      <ItemsNotification key={`order${i}`} table_name={item?.table_name} order_id={item?.order_id} status="payment" body={item?.body} tableId={item?.table_id} floorId={item?.floor_id} is_read={item?.is_read} notificationId={item?.id} />
                    ))}
                  </div>
                  <div className={`d-flex flex-column gap-3 height-notification ${changeOptionNotification === 3 ? "" : "d-none"}`}>
                    {listNotification.map((item, i) => Number(item?.type) === 2 && (
                      <ItemsNotification key={`order${i}`} table_name={item?.table_name} order_id={item?.order_id} status="support" body={item?.body} tableId={item?.table_id} floorId={item?.floor_id} is_read={item?.is_read} notificationId={item?.id} />
                    ))}

                  </div>
                </div>
              </div>
              <div className="footter-box-notification position-relative" onClick={MarkReadAll}>
                <img src={checksNotification} alt="" />
                <p className="mb-0 text-footer-nofitication">Mark read all notification</p>
              </div>
            </div>
          </Popover> */}

          {/* <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
            <li>
              <button
                className="button-menu-mobile waves-effect waves-light d-none d-lg-block"
                onClick={toggleLeftSidebarWidth}
              >
                <i className="fe-menu"></i>
              </button>
            </li>
            <li>
              <button
                className="button-menu-mobile open-left d-lg-none d-bolck waves-effect waves-light"
                onClick={handleLeftMenuCallBack}
              >
                <i className="fe-menu" />
              </button>
            </li>

            <li>
              <Link
                to="#"
                className={classNames("navbar-toggle nav-link", {
                  open: isopen,
                })}
                onClick={handleLeftMenuCallBack}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(TopbarAdmin);
