import { Form, InputGroup, Modal } from "react-bootstrap";
import "../Styles.css"
import { useEffect, useState } from "react";
import { leaf1, leaf2, minusGreen, plusGreen } from "../../../../../components/ImgExport";
import { EditVoucherByOwner, VoucherDetail, gDetailVoucher } from "../../../../../api/apiOwner/voucherApi";
import { getLanguageUI } from "../../../../../Services/languageUI";



interface ModalEditVoucherProps {
  show: boolean;
  voucherID: string;
  setShowLoading: (e: boolean) => void;
  handleClose: () => void;
  HandleEditSuccess: () => void
}

const ModalEditVoucher = ({
  show,
  voucherID = '',
  setShowLoading,
  HandleEditSuccess,
  handleClose,
}: ModalEditVoucherProps) => {
  const languageUI = getLanguageUI().voucherPage
  const [voucherName, setVoucherName] = useState<string>('')
  const [voucherType, setVoucherType] = useState<number>(1)
  const [DiscountPercent, setDiscountPercent] = useState<number>(0)
  const [discountAmount, setDiscountAmount] = useState<number>(0)
  const [monthNumber, setMonthNumber] = useState<number | string>('')
  const [messageError, setMessageError] = useState<string>('')

  const gInfoVoucher = async () => {
    setShowLoading(true)
    const req = await gDetailVoucher(voucherID);
    if (Number(req?.status) === 1) {
      const detail: VoucherDetail = req?.data;
      setVoucherName(detail?.voucher_name ?? '');
      setVoucherType(detail?.type ? Number(detail?.type) : 1);
      setDiscountPercent(Number(detail?.type) === 1 ? Number(detail?.discount_value) : 0);
      setDiscountAmount(Number(detail?.type) === 2 ? Number(detail?.discount_value) : 0);
      setMonthNumber(detail?.expry_date ? detail?.expry_date : '')
      setShowLoading(false)
    } else {
      setShowLoading(false)
    }
  }
  const EditVoucher = async () => {
    var discount_value = voucherType === 1 ? DiscountPercent : discountAmount;

    if (voucherName === '') {
      setMessageError(languageUI.errVoucherName);
    } else if (discount_value <= 0) {
      setMessageError(languageUI.errCoupon)
    }
    //  else if (Number(monthNumber) <= 0) {
    //   setMessageError(languageUI.pleaseEnterExpire)
    // }
     else {
      setMessageError('')
      setShowLoading(true)
      var expiryMonth = Number(monthNumber) <= 0?0:Number(monthNumber);
      const req = await EditVoucherByOwner(voucherID, voucherName, voucherType, discount_value, expiryMonth)
      if (Number(req?.status) === 1) {
        handleClose()
        HandleEditSuccess();
      } else {
        setShowLoading(false);
        setMessageError(req?.message ?? "Fail")
      }
    }
  }
  useEffect(() => {
    if (show) {
      gInfoVoucher()
    }
  }, [show])

  return (
    <>
      <Modal show={show} onHide={handleClose} centered className="modal-delete-customer modal-import-inventory">
        <Modal.Dialog className="w-100 h-100 m-0 rounded-5">
          <Modal.Body>
            <div className="px-48px">
              <p className="text-delete-modal-customer mb-0 text-center">{languageUI.editVoucher}</p>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">{languageUI.voucherName}</p>
                <div className="input-add-table">
                  <input type="text" className="border-none focus-visiable-none text-input-add-table px-2 w-100" placeholder={languageUI.enterVoucherName} value={voucherName} onChange={(e) => setVoucherName(e.target.value)} />
                </div>
              </div>
              <div>
                <div className="mt-6">
                  <p className="text-add-table-name mb-1">{languageUI.discountValue}</p>
                  <div>
                    <Form defaultValue={"option-inventory-1"}>
                      <div className="d-flex justify-content-between align-items-center h-50px">
                        <Form.Check // prettier-ignore
                          type="radio"
                          id={`option-voucher-1`}
                          label={languageUI.percentGiftCard}
                          name="option-voucher"
                          className={`d-flex align-items-center w-fit radio-choose-option-inventory ${voucherType === 1 ? "text-green" : ""}`}
                          checked={voucherType === 1}
                          onClick={() => setVoucherType(1)}
                        />
                        <div className={`d-flex align-items-center gap-2 ${voucherType === 1 ? '' : 'd-none'}`}>
                          <div className="d-flex gap-2 align-items-center">
                            <div className="btn-remove-plus-number" onClick={() => {
                              if (DiscountPercent > 0) {
                                setDiscountPercent(DiscountPercent - 1)
                              }
                            }}>
                              <img src={minusGreen} alt="" />
                            </div>
                            <div className="input-number-table-multi">
                              <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={DiscountPercent} onChange={(e) => {
                                if (e.target.value && Number(e.target.value) <= 0) {
                                  return;
                                }
                                setDiscountPercent(Number(e.target.value))
                              }} />
                            </div>
                            <div className="btn-remove-plus-number" onClick={() => {
                              setDiscountPercent(DiscountPercent + 1)
                            }}>
                              <img src={plusGreen} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center h-50px">
                        <Form.Check // prettier-ignore
                          type="radio"
                          id={`option-voucher-2`}
                          label={languageUI.amountGiftCard}
                          className={`d-flex align-items-center w-fit radio-choose-option-inventory ${voucherType === 1 ? "" : "text-green"}`}
                          name="option-voucher"
                          checked={voucherType === 2}
                          onClick={() => setVoucherType(2)}
                        />
                        <div className={`d-flex align-items-center gap-2  ${voucherType === 1 ? 'd-none' : ''}`}>
                          <div className="d-flex gap-2 align-items-center">
                            <div className="btn-remove-plus-number" onClick={() => {
                              if (discountAmount > 0) {
                                setDiscountAmount(discountAmount - 1)
                              }
                            }}>
                              <img src={minusGreen} alt="" />
                            </div>
                            <div className="input-number-table-multi">
                              <input type="number" className="input-number-table-multi w-fit border-none focus-visiable-none w-fit" maxLength={3} value={discountAmount} onChange={(e) => {
                                if (e.target.value && Number(e.target.value) <= 0) {
                                  return;
                                }
                                setDiscountAmount(Number(e.target.value))
                              }} />
                            </div>
                            <div className="btn-remove-plus-number" onClick={() => {
                              setDiscountAmount(discountAmount + 1)
                            }}>
                              <img src={plusGreen} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>

                    </Form>
                  </div>

                </div>
              </div>
              <div className="mt-6">
                <p className="text-add-table-name mb-1">{languageUI.voucherExpiresIn}</p>
                <InputGroup className="mb-3  h-52px rounded-8px">
                  <Form.Control
                    placeholder={languageUI.voucherExpiresIn}
                    aria-describedby="basic-addon2"
                    className="text-input-usd"
                    type="number"
                    value={monthNumber}
                    onChange={(e) => {
                      if (e.target.value && (Number(e.target.value) <= 0 || Number(e.target.value) % 1 != 0)) {
                        return;
                      }
                      setMonthNumber(e.target.value)
                    }}
                  />
                  <InputGroup.Text id="basic-addon2" className="text-input-usd">{languageUI.month}</InputGroup.Text>
                </InputGroup>
              </div>
              <div className="text-center text-danger text-italic">{messageError}</div>
              <div className="d-flex justify-content-between w-100 mt-6">
                <div className="btn-import-inventory-footer " onClick={handleClose}>Back</div>
                <div className="btn-import-inventory-footer border-12B064 text-white bg-12B064" onClick={EditVoucher}>{languageUI.save}</div>
              </div>
            </div>
            <img src={leaf1} alt="" className="position-leaf1-voucher" />
            <img src={leaf2} alt="" className="position-leaf2-voucher" />
          </Modal.Body>
        </Modal.Dialog>

      </Modal>
    </>

  );
};

export default ModalEditVoucher;
