import React, { useState } from 'react';
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Token } from '@stripe/stripe-js';

interface PaymentFormProps {
    formRef: any,
    getToken: (tokenId: string, lastCart: string | number) => void
    // handleCreateToken: (card: string | number, token: Token) => void,
}
const PaymentForm = ({ formRef, getToken }: PaymentFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const [messageError, setMessageError] = useState<string>('')
    const handleSubmit = async (e: any) => {
        e.preventDefault();


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            setMessageError('Make sure to disable form submission until Stripe.js has loaded.')
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);

        if (cardElement) {
            const { token, error } = await stripe.createToken(cardElement);
            if (error) {
                // Handle errors with the card element
                setMessageError(error?.message ?? '')
                console.error(error);
            } else {
                if (token) {
                    setMessageError('')

                    getToken(token?.id, token?.card?.last4 ?? '');

                    // Send the token to your server for processing
                    // handleCreateToken(token?.card?.last4 ?? '', token);
                } else {
                    setMessageError('Connect fail!')
                }


            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className='m-0 p-0' >
            {messageError != '' && (
                <div className="text-danger text-center ">{messageError}</div>
            )}
            <div className="row m-0 p-0">
                {/* <CardElement /> */}
                <div className="col-12 mt-2 m-0 px-0">
                    <div className='col-6 row m-0 p-0'>
                        <label className='col-12 d-flex align-tiems-center row m-0 p-0'>
                            <div className='col-2 m-0 p-0'>
                                Card Number:
                                <span className='text-danger'>*</span>
                            </div>
                            <div className="border  rounded-02rem  px-2 py-10px border col-10">
                                <CardNumberElement />
                            </div>
                        </label>
                    </div>

                </div>
                <div className="col-6 mt-2 m-0 px-0" >
                    <label className='w-100 d-flex align-tiems-center '>
                        <div className='col-2'>
                            Expiry Date:
                            <span className='text-danger'>*</span>

                        </div>

                        <div className=" border  rounded-02rem px-2 py-10px  border col-10">
                            <CardExpiryElement />
                        </div>
                    </label>
                </div>
                <div className="col-6 mt-2 m-0 pe-0">
                    <label className='w-100 d-flex align-tiems-center  row m-0 px-0' >
                        <div className='col-2'>
                            CVC:
                            <span className='text-danger'>*</span>
                        </div>
                        <div className=" border  rounded-02rem px-2 py-10px  border col-10">
                            <CardCvcElement />
                        </div>
                    </label>
                </div>
                <button className='d-none' type='submit' ref={formRef}>12323</button>
            </div >
        </form >
    );
};

export default PaymentForm;