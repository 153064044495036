import React, { useEffect, useState } from "react";
import {
  dollarGreen,
  PeopleAltOutlinedIcon,
  dollar,
} from "../../../../components/Image";
import Employee from "./Component/Content/Employee";
import Salary from "./Component/Content/Salary";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { ComboOption, gComboPayroll } from "../../../../api/apiOwner/comboApi";
import { useLocation } from "react-router-dom";
import TipRule from "./Component/Content/TipRule";
import StaffReview from "./Component/Content/StaffReview";
import { getLanguageUI } from "../../../../Services/languageUI";

const Staff: React.FC = () => {
  const languageUI = getLanguageUI().staffPage;
  const location = useLocation();
  const params: any = location.state;
  const tabSelect = params?.tabSelect;
  const tabOptionEmployee = params?.tabOptionEmployee;
  const [selectBtn, setSelectBtn] = React.useState<number>(0);
  const [comboPayroll, setComboPayroll] = useState<ComboOption[]>([]);
  const handleSelectBtn = (index: number) => {
    setSelectBtn(index);
  };
  const gComboPayrollOwner = async () => {
    const req = await gComboPayroll();
    if (Number(req?.status) === 1) {
      setComboPayroll(req?.data ?? []);
    }
  };

  useEffect(() => {
    if (tabSelect) {
      setSelectBtn(tabSelect);
    }
    gComboPayrollOwner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className=" bg-white">
        <div className=" mt-18 ">
          <div className="d-flex  ml-12 pt-3">
            <div className="flex bg-#EFF3F1 custom-radius-table p-1 bg-EFF3F1">
              <div
                className={`pr-2 ${
                  selectBtn === 0 ? "shadow-btn-group bg-white radius-2" : ""
                }`}
                onClick={() => handleSelectBtn(0)}
              >
                <button
                  className={`h-100 flex items-center  border-none  fw-600 text-18px px-2 ${
                    selectBtn === 0
                      ? "text-0FA54A bg-white radius-2 p-3"
                      : "text-4A4A6A"
                  }`}
                >
                  <PeopleAltOutlinedIcon className="mr-1" />
                  {languageUI.employee}
                </button>
              </div>
              <div
                onClick={() => handleSelectBtn(1)}
                className={`${
                  selectBtn === 1 ? "shadow-btn-group bg-white radius-2" : ""
                }`}
              >
                <button
                  className={`h-100 flex items-center  border-none  fw-600 text-18px px-2 ${
                    selectBtn === 1
                      ? "text-0FA54A bg-white radius-2 shadow-btn-group p-3"
                      : "text-4A4A6A"
                  }`}
                >
                  {selectBtn === 1 ? (
                    <img src={dollarGreen} className="mr-1" alt=""></img>
                  ) : (
                    <img src={dollar} alt="" className="mr-1" />
                  )}
                  {languageUI.salary}
                </button>
              </div>
              <div
                className={`${
                  selectBtn === 2 ? "shadow-btn-group bg-white radius-2" : ""
                }`}
                onClick={() => handleSelectBtn(2)}
              >
                <button
                  className={`h-100 flex items-center  border-none  fw-600 text-18px px-2 ${
                    selectBtn === 2
                      ? "text-0FA54A bg-white radius-2 p-3"
                      : "text-4A4A6A"
                  }`}
                >
                  <EuroRoundedIcon className="mr-1" />
                  {languageUI.tipRule}
                </button>
              </div>
              <div
                className={`${
                  selectBtn === 3 ? "shadow-btn-group bg-white radius-2" : ""
                }`}
                onClick={() => handleSelectBtn(3)}
              >
                <button
                  className={`h-100 flex items-center  border-none  fw-600 text-18px px-2 ${
                    selectBtn === 3
                      ? "text-0FA54A bg-white radius-2 p-3"
                      : "text-4A4A6A"
                  }`}
                >
                  <RateReviewIcon className="mr-1" />
                  {languageUI.staffReview}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {selectBtn === 0 ? (
          <Employee tabOptionEmployee={tabOptionEmployee} />
        ) : selectBtn === 1 ? (
          <Salary comboPayroll={comboPayroll} />
        ) : selectBtn === 2 ? (
          <TipRule />
        ) : (
          <StaffReview />
        )}
      </div>
    </>
  );
};

export default Staff;
