import { Type } from "react-toastify/dist/utils";
import * as Request from "../Request";
export interface ComboIngredient {
    id: string,
    ingredient_name: string,
    owner_id: string
}
export interface ComboSideDish {
    id: string,
    service_id: string,
    service_item_code: string,
    name: string,
    short_des: string,
    description: string,
    price: string
}
export interface ComboUnit {
    id: string,
    unit_name: string,
    timestamp: string
}

export interface ComboVoucher {
    id: string,
    voucher_name: string,
    discount_value: string,
    type: string
}
export interface ComboOption {
    value: number,
    label: string,
}
export interface ComboReview {
    id: string,
    name: string,
    start: string,
    icon: string
}
export interface Result<T> {
    status: number,
    message?: string,
    data?: T,
}
export interface IListFoodRank {
    id: number
    name: string
    order_num: string
}

export interface IListFeedbackFoodByOwner {
    review_id: number
    customer_id: number
    service_item_id: number
    content: string
    rate: number
    order_id: number
    date_feedback: string
    customer_name: string
    food_name: string
}
export interface IAllFeedbackByFood {
    review_id: number
    customer_id: number
    service_item_id: number
    content: string
    rate: number
    order_id: number
    date_feedback: string
    hour_feedback: string
    customer_name: string
    food_name: string
}
export interface IAllFoodFeedbackByOwner {
    food_id: number
    food_name: string
    total_feedback: number
}
export const gAllFeedbackByFood = async (owner_id: number, food_id: number) => {
    return await Request.fetchAPI(`owner/gAllFeedbackByFood.php`, "POST", { owner_id, food_id })
        .then((res: Result<IAllFeedbackByFood[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};
export const gAllFoodFeedbackByOwner = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gAllFoodFeedbackByOwner.php`, "POST", { owner_id })
        .then((res: Result<IAllFoodFeedbackByOwner[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

export const gListFeedbackFoodByOwner = async (owner_id: number, type: number) => {
    return await Request.fetchAPI(`owner/gListFeedbackFoodByOwner.php`, "POST", { owner_id, type })
        .then((res: Result<IListFeedbackFoodByOwner[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

export const gListFoodRank = async (owner_id: number, type_food: number) => {
    return await Request.fetchAPI(`owner/gListFoodRankByTypeFood.php`, "POST", { owner_id, type_food })
        .then((res: Result<IListFoodRank[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};
export const gComboIngredientbyOwner = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gComboIngredient.php`, "POST", { owner_id })
        .then((res: Result<ComboIngredient[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};
export const gComboSideDishbyOwner = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gComboSideDish.php`, "POST", { owner_id })
        .then((res: Result<ComboSideDish[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

export const gComboUnitbyOwner = async () => {
    return await Request.fetchAPI(`owner/gComboUnit.php`, "GET", {})
        .then((res: Result<ComboUnit[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

export const gComboMonthLongSeebyOwner = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gComboOptionCustomerLongtoSee.php`, "POST", { owner_id })
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

export const gComboReviewReport = async (owner_id: number, type: number, monthCheck: number = 0, date_start: string = '', date_end: string = '') => {
    return await Request.fetchAPI(`owner/gComboOptionReview.php`, "POST", { owner_id, monthCheck, type, date_end, date_start })
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};
//lấy combo option journey(khách hàng)
export const gComboJourney = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gComboOptionJourney.php`, "POST", { owner_id })
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};
//Lấy côbo loại đánh giá
export const gComboReviewType = async (
) => {
    return await Request.fetchAPI(`owner/gComboReview.php`, 'POST', {})
        .then((res: Result<ComboReview[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: [],
            };
        });
};

//lấy combo giới tính
export const gComboGender = async () => {
    return await Request.fetchAPI(`owner/gComboGender.php`, "GET", {})
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

//lấy combo vị trí nhân sự
export const gComboPosition = async () => {
    return await Request.fetchAPI(`owner/gComboPosition.php`, "GET", {})
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

//lấy combo payroll(hình thức tính lương)
export const gComboPayroll = async () => {
    return await Request.fetchAPI(`owner/gComboPayroll.php`, "GET", {})
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

//lấy combo voucher
export const gComboVoucher = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gComboVoucher.php`, "POST", { owner_id })
        .then((res: Result<ComboVoucher[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};

//lấy combo time zone
export const gComboTimeZone = async () => {
    return await Request.fetchAPI(`owner/gComboTimeZone.php`, "GET", {})
        .then((res: Result<ComboOption[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: []
            };
        });
};