import * as Request from "../Request";
export interface Result<T> {
    status: number,
    message: string,
    data: T,
}

export interface ScoreGame {
    id: string,
    admin_id: string,
    score_flappybird: string,
    score_whackamole: string
}
export interface ConfigGame {
    id: string,
    point_flappybird: string,
    point_whackamole: string,
    is_show_redemption_game: string

}

export interface GameRoundCustomer {
    plays_flappybird: string,
    plays_whackamole: string,
}
export const updateScoreGame = async (customer_id: string, game_id: number, score: number) => {
    return await Request.fetchAPI(`checkin/updateScoreGameCustomer.php`, "POST", { customer_id, game_id, score })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};
export const gScoreGame = async (customer_id: string) => {
    return await Request.fetchAPI(`checkin/gcoreGameCustomer.php`, "POST", { customer_id })
        .then((res: Result<ScoreGame>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};
export const gConfigGame = async (owner_id: string) => {
    return await Request.fetchAPI(`owner/gConfigGame.php`, "POST", { owner_id })
        .then((res: Result<ConfigGame>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};

export const upPointsRewardCustomer = async (owner_id: number, customer_id: string, points: number, game_id: number) => {
    return await Request.fetchAPI(`owner/upPointsRewardCustomer.php`, "POST", { owner_id, customer_id, points, game_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: '',
            };
        });
};

export const resetRoundGameCustomer = async (owner_id: string, customer_id: string) => {
    return await Request.fetchAPI(`checkin/resetRoundGameCustomer.php`, "POST", { owner_id, customer_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};

export const gGameRoundCustomer = async (customer_id: string) => {
    return await Request.fetchAPI(`checkin/gGameRoundCustomer.php`, "POST", { customer_id })
        .then((res: Result<GameRoundCustomer>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: 0,
                data: undefined,
            };
        });
};
