import * as Request from "../Request";
export interface listSide {
    id: string,
    imageCourse: string,
    name: string,
    price: string,
    quantity: string,
    side_id: string,
    table_id: string,
    typeFood: string,
}
export interface OrderWaiting {
    id: string,
    custommer_id: string,
    table_id: string,
    table_name: string,
    timeProduct: string,
    customer_name: string,
    productWait: string,
    listFood: ListFoodWaiting[],
    listSide: listSide[],
    order_method: string,
    order_method_name: string
}
export interface ListFoodWaiting {
    id: string,
    owner_id: string,
    custommer_id: string,
    table_id: string,
    service_item_id: string,
    price: string,
    quantity: string,
    notefood_id: string,
    side_id: string,
    form_order: string,
    note: string,
    name: string,
    imageCourse: string,
    donvitinh: string,
    notefood_name: string,
    side_name: string,
    description: string
}
export interface Result<T> {
    status: string,
    message: string,
    data: T,
}
export const gOrderKetchenCompleted = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gOrderkitchenComplete.php`, "POST", { owner_id })
        .then((res: Result<OrderWaiting[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: [],
            };
        });
};
export const gOrderWaitingKetchen = async (owner_id: number) => {
    return await Request.fetchAPI(`owner/gOrderkitchencounter.php`, "POST", { owner_id })
        .then((res: Result<OrderWaiting[]>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: [],
            };
        });
};
export const UpdateStatusWaitFood = async (order_id: string) => {
    return await Request.fetchAPI(`owner/completeOrderKetchen.php`, "POST", { order_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: '',
            };
        });
};
export const RestoreStatusOrder = async (order_id: string) => {
    return await Request.fetchAPI(`owner/restoreOrderKetchen.php`, "POST", { order_id })
        .then((res: Result<string>) => {
            return res;
        })
        .catch((err: any) => {
            return {
                message: "An error has occurred. Please check your internet connection",
                status: '0',
                data: '',
            };
        });
};