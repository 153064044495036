import { Modal } from "react-bootstrap";
import "./Styles.css";
import { useEffect, useState } from "react";
import { TableInfo } from "../../../../api/apiOwner/tableApi";
import { getLanguageUI } from "../../../../Services/languageUI";

interface ModalRemoveBillToTableProps {
  show: boolean;
  handleClose: () => void;
  listTable: TableInfo[];
  tableID: string;
  handleSwapOrderTable: (tableRemove: number) => void;
  messErrAPI: string;
}

const ModalRemoveBillToTable = ({
  show,
  handleClose,
  listTable,
  handleSwapOrderTable,
  messErrAPI,
}: ModalRemoveBillToTableProps) => {
  const LanguageUI = getLanguageUI().posTable;
  const [tableActive, setTableActive] = useState<number>();
  const [listTableRemove, setListTableRemove] = useState<TableInfo[]>([]);
  const [messErr, setMessErr] = useState<string>("");
  useEffect(() => {
    if (show) {
      setListTableRemove(listTable);
      setTableActive(undefined);
      setMessErr("");
    }
  }, [show]);

  const handleSubmit = () => {
    if (tableActive) {
      handleSwapOrderTable(tableActive);
    } else {
      setMessErr("Plase choose table!");
    }
  };
  return (
    <Modal show={show} onHide={handleClose} className="right-remove-bill">
      <Modal.Dialog className="bg-gray-light w-100">
        <Modal.Header className="position-relative">
          <div className="d-flex align-items-center pt-2 gap-2">
            <p className="remove-bill-title mb-0 ">
              {LanguageUI.moveBillToTable}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="h-modal">
          <div className="p-4">
            <div className=" p-4 w-100  box-remove-bill scroll-hidden position-relative">
              {listTableRemove &&
                listTableRemove.map((item, index) => (
                  <div
                    key={index}
                    className={`w-fit ${
                      tableActive === item.id ? " z-index-20" : ""
                    } aspect-square  my-2 `}
                    style={{
                      transform: `translate3d(${item.tx}px, ${item.ty}px, 0)`,
                      minWidth: "50px",
                      width: `${Number(item.scale) * 10}px`,
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      minHeight: "50px",
                      height: `${Number(item.scale) * 10}px`,
                    }}
                    onClick={() => {
                      handleSwapOrderTable(Number(item.id));
                    }}
                  >
                    <div
                      className={`table-pos d-flex justify-content-center align-items-center h-100 w-100 box-shadow-table ${
                        tableActive === item.id
                          ? "border-1px-FF7B2C z-index-20"
                          : ""
                      }  ${
                        item.shape === "circle"
                          ? "aspect-square border-circle"
                          : "border-12px"
                      }`}
                      style={{ rotate: `${item.rorate}deg` }}
                    >
                      <div
                        className={`circle-name-table ${
                          Number(item.status) === 0
                            ? "available"
                            : Number(item.status) === 1
                            ? "reserved"
                            : "paid"
                        }`}
                        style={{ rotate: `-${item.rorate ?? 0}deg` }}
                      >
                        <p className="mb-0 text-name-table">
                          {item.table_name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="text-center text-red text-italic">{messErrAPI}</div>
          {/* <div className="w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="mess-err">{messErr}</div>
            <div
              className="text-white font-bold h-50px w-300px  w-fit d-flex justify-content-center align-items-center  bg-primary rounded-3"
              onClick={handleSubmit}
            >
              Confirm
            </div>
          </div> */}
        </Modal.Body>
      </Modal.Dialog>
    </Modal>
  );
};

export default ModalRemoveBillToTable;
