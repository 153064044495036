interface Dripicon {
    name: string;
}

export const DRIPICONS_LIST: Dripicon[] = [
    {
        name: 'dripicons-alarm',
    },

    {
        name: 'dripicons-align-center',
    },

    {
        name: 'dripicons-align-justify',
    },

    {
        name: 'dripicons-align-left',
    },

    {
        name: 'dripicons-align-right',
    },

    {
        name: 'dripicons-anchor',
    },

    {
        name: 'dripicons-archive',
    },

    {
        name: 'dripicons-arrow-down',
    },

    {
        name: 'dripicons-arrow-left',
    },

    {
        name: 'dripicons-arrow-right',
    },

    {
        name: 'dripicons-arrow-thin-down',
    },

    {
        name: 'dripicons-arrow-thin-left',
    },

    {
        name: 'dripicons-arrow-thin-right',
    },

    {
        name: 'dripicons-arrow-thin-up',
    },

    {
        name: 'dripicons-arrow-up',
    },

    {
        name: 'dripicons-article',
    },

    {
        name: 'dripicons-backspace',
    },

    {
        name: 'dripicons-basket',
    },

    {
        name: 'dripicons-basketball',
    },

    {
        name: 'dripicons-battery-empty',
    },

    {
        name: 'dripicons-battery-full',
    },

    {
        name: 'dripicons-battery-low',
    },

    {
        name: 'dripicons-battery-medium',
    },

    {
        name: 'dripicons-bell',
    },

    {
        name: 'dripicons-blog',
    },

    {
        name: 'dripicons-bluetooth',
    },

    {
        name: 'dripicons-bold',
    },

    {
        name: 'dripicons-bookmark',
    },

    {
        name: 'dripicons-bookmarks',
    },

    {
        name: 'dripicons-box',
    },

    {
        name: 'dripicons-briefcase',
    },

    {
        name: 'dripicons-brightness-low',
    },

    {
        name: 'dripicons-brightness-max',
    },

    {
        name: 'dripicons-brightness-medium',
    },

    {
        name: 'dripicons-broadcast',
    },

    {
        name: 'dripicons-browser',
    },

    {
        name: 'dripicons-browser-upload',
    },

    {
        name: 'dripicons-brush',
    },

    {
        name: 'dripicons-calendar',
    },

    {
        name: 'dripicons-camcorder',
    },

    {
        name: 'dripicons-camera',
    },

    {
        name: 'dripicons-card',
    },

    {
        name: 'dripicons-cart',
    },

    {
        name: 'dripicons-checklist',
    },

    {
        name: 'dripicons-checkmark',
    },

    {
        name: 'dripicons-chevron-down',
    },

    {
        name: 'dripicons-chevron-left',
    },

    {
        name: 'dripicons-chevron-right',
    },

    {
        name: 'dripicons-chevron-up',
    },

    {
        name: 'dripicons-clipboard',
    },

    {
        name: 'dripicons-clock',
    },

    {
        name: 'dripicons-clockwise',
    },

    {
        name: 'dripicons-cloud',
    },

    {
        name: 'dripicons-cloud-download',
    },

    {
        name: 'dripicons-cloud-upload',
    },

    {
        name: 'dripicons-code',
    },

    {
        name: 'dripicons-contract',
    },

    {
        name: 'dripicons-contract-2',
    },

    {
        name: 'dripicons-conversation',
    },

    {
        name: 'dripicons-copy',
    },

    {
        name: 'dripicons-crop',
    },

    {
        name: 'dripicons-cross',
    },

    {
        name: 'dripicons-crosshair',
    },

    {
        name: 'dripicons-cutlery',
    },

    {
        name: 'dripicons-device-desktop',
    },

    {
        name: 'dripicons-device-mobile',
    },

    {
        name: 'dripicons-device-tablet',
    },

    {
        name: 'dripicons-direction',
    },

    {
        name: 'dripicons-disc',
    },

    {
        name: 'dripicons-document',
    },

    {
        name: 'dripicons-document-delete',
    },

    {
        name: 'dripicons-document-edit',
    },

    {
        name: 'dripicons-document-new',
    },

    {
        name: 'dripicons-document-remove',
    },

    {
        name: 'dripicons-dot',
    },

    {
        name: 'dripicons-dots-2',
    },

    {
        name: 'dripicons-dots-3',
    },

    {
        name: 'dripicons-download',
    },

    {
        name: 'dripicons-duplicate',
    },

    {
        name: 'dripicons-enter',
    },

    {
        name: 'dripicons-exit',
    },

    {
        name: 'dripicons-expand',
    },

    {
        name: 'dripicons-expand-2',
    },

    {
        name: 'dripicons-experiment',
    },

    {
        name: 'dripicons-export',
    },

    {
        name: 'dripicons-feed',
    },

    {
        name: 'dripicons-flag',
    },

    {
        name: 'dripicons-flashlight',
    },

    {
        name: 'dripicons-folder',
    },

    {
        name: 'dripicons-folder-open',
    },

    {
        name: 'dripicons-forward',
    },

    {
        name: 'dripicons-gaming',
    },

    {
        name: 'dripicons-gear',
    },

    {
        name: 'dripicons-graduation',
    },

    {
        name: 'dripicons-graph-bar',
    },

    {
        name: 'dripicons-graph-line',
    },

    {
        name: 'dripicons-graph-pie',
    },

    {
        name: 'dripicons-headset',
    },

    {
        name: 'dripicons-heart',
    },

    {
        name: 'dripicons-help',
    },

    {
        name: 'dripicons-home',
    },

    {
        name: 'dripicons-hourglass',
    },

    {
        name: 'dripicons-inbox',
    },

    {
        name: 'dripicons-information',
    },

    {
        name: 'dripicons-italic',
    },

    {
        name: 'dripicons-jewel',
    },

    {
        name: 'dripicons-lifting',
    },

    {
        name: 'dripicons-lightbulb',
    },

    {
        name: 'dripicons-link',
    },

    {
        name: 'dripicons-link-broken',
    },

    {
        name: 'dripicons-list',
    },

    {
        name: 'dripicons-loading',
    },

    {
        name: 'dripicons-location',
    },

    {
        name: 'dripicons-lock',
    },

    {
        name: 'dripicons-lock-open',
    },

    {
        name: 'dripicons-mail',
    },

    {
        name: 'dripicons-map',
    },

    {
        name: 'dripicons-media-loop',
    },

    {
        name: 'dripicons-media-next',
    },

    {
        name: 'dripicons-media-pause',
    },

    {
        name: 'dripicons-media-play',
    },

    {
        name: 'dripicons-media-previous',
    },

    {
        name: 'dripicons-media-record',
    },

    {
        name: 'dripicons-media-shuffle',
    },

    {
        name: 'dripicons-media-stop',
    },

    {
        name: 'dripicons-medical',
    },

    {
        name: 'dripicons-menu',
    },

    {
        name: 'dripicons-message',
    },

    {
        name: 'dripicons-meter',
    },

    {
        name: 'dripicons-microphone',
    },

    {
        name: 'dripicons-minus',
    },

    {
        name: 'dripicons-monitor',
    },

    {
        name: 'dripicons-move',
    },

    {
        name: 'dripicons-music',
    },

    {
        name: 'dripicons-network-1',
    },

    {
        name: 'dripicons-network-2',
    },

    {
        name: 'dripicons-network-3',
    },

    {
        name: 'dripicons-network-4',
    },

    {
        name: 'dripicons-network-5',
    },

    {
        name: 'dripicons-pamphlet',
    },

    {
        name: 'dripicons-paperclip',
    },

    {
        name: 'dripicons-pencil',
    },

    {
        name: 'dripicons-phone',
    },

    {
        name: 'dripicons-photo',
    },

    {
        name: 'dripicons-photo-group',
    },

    {
        name: 'dripicons-pill',
    },

    {
        name: 'dripicons-pin',
    },

    {
        name: 'dripicons-plus',
    },

    {
        name: 'dripicons-power',
    },

    {
        name: 'dripicons-preview',
    },

    {
        name: 'dripicons-print',
    },

    {
        name: 'dripicons-pulse',
    },

    {
        name: 'dripicons-question',
    },

    {
        name: 'dripicons-reply',
    },

    {
        name: 'dripicons-reply-all',
    },

    {
        name: 'dripicons-return',
    },

    {
        name: 'dripicons-retweet',
    },

    {
        name: 'dripicons-rocket',
    },

    {
        name: 'dripicons-scale',
    },

    {
        name: 'dripicons-search',
    },

    {
        name: 'dripicons-shopping-bag',
    },

    {
        name: 'dripicons-skip',
    },

    {
        name: 'dripicons-stack',
    },

    {
        name: 'dripicons-star',
    },

    {
        name: 'dripicons-stopwatch',
    },

    {
        name: 'dripicons-store',
    },

    {
        name: 'dripicons-suitcase',
    },

    {
        name: 'dripicons-swap',
    },

    {
        name: 'dripicons-tag',
    },

    {
        name: 'dripicons-tag-delete',
    },

    {
        name: 'dripicons-tags',
    },

    {
        name: 'dripicons-thumbs-down',
    },

    {
        name: 'dripicons-thumbs-up',
    },

    {
        name: 'dripicons-ticket',
    },

    {
        name: 'dripicons-time-reverse',
    },

    {
        name: 'dripicons-to-do',
    },

    {
        name: 'dripicons-toggles',
    },

    {
        name: 'dripicons-trash',
    },

    {
        name: 'dripicons-trophy',
    },

    {
        name: 'dripicons-upload',
    },

    {
        name: 'dripicons-user',
    },

    {
        name: 'dripicons-user-group',
    },

    {
        name: 'dripicons-user-id',
    },

    {
        name: 'dripicons-vibrate',
    },

    {
        name: 'dripicons-view-apps',
    },

    {
        name: 'dripicons-view-list',
    },

    {
        name: 'dripicons-view-list-large',
    },

    {
        name: 'dripicons-view-thumb',
    },

    {
        name: 'dripicons-volume-full',
    },

    {
        name: 'dripicons-volume-low',
    },

    {
        name: 'dripicons-volume-medium',
    },

    {
        name: 'dripicons-volume-off',
    },

    {
        name: 'dripicons-wallet',
    },

    {
        name: 'dripicons-warning',
    },

    {
        name: 'dripicons-web',
    },

    {
        name: 'dripicons-weight',
    },

    {
        name: 'dripicons-wifi',
    },

    {
        name: 'dripicons-wrong',
    },

    {
        name: 'dripicons-zoom-in',
    },

    {
        name: 'dripicons-zoom-out',
    },
];
