import { Modal } from "react-bootstrap";
import "../../Styles.css"
import { leaf1, leaf2, turnOffEmployee } from "../../../../../../components/ImgExport";
import { getLanguageUI } from "../../../../../../Services/languageUI";
interface ModalActiveEmployeeProps {
    show: boolean;
    onHide: () => void;
    status: boolean;
    namePloyee: string;
    ActiveHanle: () => void
}


const ModalActiveEmployee: React.FC<ModalActiveEmployeeProps> = ({
    show,
    onHide,
    status,
    namePloyee,
    ActiveHanle

}) => {
    const languageUI = getLanguageUI().staffPage

    return (
        <Modal show={show} onHide={onHide} centered className="modal-delete-customer modal-delete-voucher modal-active-employee">
            <Modal.Dialog className="w-100">
                <Modal.Body>
                    <div className="position-relative px-3">
                        <div className="d-flex align-items-center gap-2px">
                            <img src={turnOffEmployee} alt="" />
                            <p className="text-delete-modal-customer mb-0">{status ? languageUI.Inactive : languageUI.active}</p>
                        </div>
                        <p className="text-content-modal-delete-customer mt-2 d-flex flex-wrap gap-1">{status ? languageUI.areYouSureInactive : languageUI.areYouSureActive} <p className="mb-0 text-content-modal-delete-customer text-269AFF">{namePloyee}</p>?</p>
                        <div className="d-flex justify-content-between">
                            <div className="btn-cancel-modal-delete-customer bg-white" onClick={onHide}>
                                {languageUI.noIChangeMyMind}
                            </div>
                            <div className="btn-cancel-modal-delete-customer border-delete bg-white" onClick={ActiveHanle}>
                                <p className="mb-0 text-delete-modal-customer-btn">{languageUI.yes}</p>
                            </div>
                        </div>
                        <img src={leaf1} alt="" className="img-leaf1" />
                        <img src={leaf2} alt="" className="img-leaf2" />
                    </div>
                </Modal.Body>
            </Modal.Dialog>

        </Modal>
    );
};

export default ModalActiveEmployee