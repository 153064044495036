import "./styles.css";
import { checkedCircle } from "../components/Image";

interface ReviewSuccessProps {}

const ReviewSuccess = ({}: ReviewSuccessProps) => {
  return (
    <>
      <div
        className="review-restaurant-page align-items-center d-flex"
        style={{ minHeight: "100vh" }}
      >
        <div className="review-introduce">
          <div className="d-flex gap-2 align-items-center">
            <img src={checkedCircle} alt="" className="w-80px" />
            <p className="text-primary text-successs-rv mb-0">Submit Success</p>
          </div>
          <p className="text-center text-notification-payment-success">
            Thank you so much for your feedback.
          </p>
          <p className="text-center text-notification-payment-success font-weight-600">
            This means everything to us and helps us identify areas where we can
            improve our business to serve you better.
          </p>
          <p className="text-center text-notification-payment-success">
            Once again, we appreciate your time to write us feedback. Thank you.
          </p>
        </div>
      </div>
    </>
  );
};

export default ReviewSuccess;
